import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/operators';
import { accessorialRequestConfirmationModalZIndex } from '../../../constants';
import {
  CarrierAccessorialRequestActionData,
  CarrierAccessorialRequestData,
  CarrierAccessorialRequestStatusEnum,
  LoadAccessorialDetailData,
  LoadDocumentMetadata,
  LoadDocumentUpload,
  UserModel,
} from '../../../models';
import { SecurityAppActionType } from '../../../models/security-app-action-type';
import { AccessorialRequestService } from '../../../services';
import { BaseComponent } from '../../base-component';
import { ConfirmActionModalComponent } from '../../confirm-action-modal';

@Component({
  selector: 'kbxl-accessorial-requests-modal',
  templateUrl: './accessorial-requests-modal.component.html',
  styleUrls: ['./accessorial-requests-modal.component.scss'],
  providers: [DialogService],
})
export class AccessorialRequestsModalComponent extends BaseComponent implements OnInit {
  user: UserModel;
  loading = false;
  shipperAccessorials: LoadAccessorialDetailData[];
  loadId: string;
  carrierRequests: CarrierAccessorialRequestData[] = [];
  carrierRequestActiveIndex: number;
  errorMsg = '';
  availableActions: SelectItem[] = [];
  refreshAvailableAccessorials = false;
  pendingChange = false;
  pendingAddChange = false;
  refreshGrid = false;
  loadLocked = false;
  requestAccessorial: boolean;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private accessorialRequestService: AccessorialRequestService,
    private confirmationService: ConfirmationService,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private dialogService: DialogService,
    private currencyPipe: CurrencyPipe
  ) {
    super();

    this.loadId = config.data.loadId;
    if (config.data.loadLocked) {
      this.loadLocked = config.data.loadLocked;
    }

    this.user = new UserModel(config.data.user);
  }
  ngOnInit() {
    this.loading = true;

    this.accessorialRequestService.get(this.loadId).subscribe((x) => {
      this.loading = false;
      this.shipperAccessorials = x.loadAccessorials;
      this.carrierRequests = x.carrierAccessorialRequests;

      if (this.user.isShipper) {
        // open first tab where action is request
        this.openActionItem();
      }
      this.requestAccessorial = this.allowAccessorialRequest(this.user, this.loadLocked);
    });
  }

  shouldShowAfterInitialHours(request: CarrierAccessorialRequestData): boolean {
    return (
      request &&
      request.loadAccessorial &&
      Number.isInteger(request.loadAccessorial.afterInitialHours) &&
      request.loadAccessorial.afterInitialHours > 0
    );
  }

  shouldLockedOutMessage(): boolean {
    // check is user is a carrier and view load that is booked or delivered
    if (this.shipperAccessorials && this.shipperAccessorials.length > 0 && this.loadLocked && this.user.isCarrier) {
      return true;
    }
    return false;
  }

  cancel(): void {
    if (!this.pendingChange && !this.pendingAddChange) {
      this.ref.close({
        refreshGrid: this.refreshGrid,
      });
      return;
    }

    let header = 'Accessorial Cancel Confirmation';
    let message = 'Changes will not be saved and sent to shipper for approval.  Are you sure you want to proceed?';
    if (this.user.isShipper) {
      header = 'Cancel Confirmation';
      message = 'Changes will not be saved.  Are you sure you want to proceed?';
    }

    this.confirmationService.confirm({
      header: header,
      message: message,
      accept: () => {
        this.ref.close({
          refreshGrid: this.refreshGrid,
        });
      },
    });
  }

  saveAccessorials(): void {
    if (!this.pendingAddChange) {
      return;
    }

    this.confirmationService.confirm({
      header: 'Accessorial Approval Confirmation',
      message: "Are you sure you want to submit these accessorials for the shipper's approval?",
      accept: () => {
        this.errorMsg = '';
        this.loading = true;

        const newRequests = this.carrierRequests.filter((x) => x.status === 1);
        newRequests.forEach((x) => (x.hasError = null));

        this.accessorialRequestService.saveRequests(newRequests, this.loadId).subscribe(
          (x) => {
            this.carrierRequests = x;
            this.loading = false;
            this.messageService.add({
              detail: 'Accessorial requests saved',
              severity: 'success',
            });
            this.ref.close({
              refreshGrid: this.refreshGrid,
            });
            // TODO: update this to check for updated request when carrier feature is added
            this.pendingAddChange = this.carrierRequests.some((y) => y.status === CarrierAccessorialRequestStatusEnum.New);
            // flag to refresh grid on modal close
            this.refreshGrid = true;
          },
          (err) => {
            this.loading = false;
            if (err && err.error && err.error.title && err.error.detail) {
              this.messageService.add({
                summary: err.error.title,
                detail: err.error.detail,
                severity: 'error',
              });
              this.decodeProblemDetails(newRequests, err.error);
              this.changeDetectorRef.detectChanges();
            }
          }
        );
      },
    });
    this.changeDetectorRef.detectChanges();
  }

  isDocumentReadOnly(doc: LoadDocumentMetadata): boolean {
    switch (doc?.loadDocumentType?.description?.toLowerCase()) {
      case 'accessorial':
        return false;
      default:
        return true;
    }
  }
  addNewAccessorial(newRequest: CarrierAccessorialRequestData): void {
    this.carrierRequests.push(newRequest);
    this.pendingAddChange = true;
  }
  removeAccessorialRequest(request: CarrierAccessorialRequestData, index: number): void {
    this.confirmationService.confirm({
      header: 'Remove Accessorial',
      message: 'Are you sure you want to delete this accessorial?',
      accept: () => {
        this.errorMsg = '';

        if (request.carrierAccessorialRequestId === 0) {
          this.carrierRequests.splice(index, 1);
          // hack because ng accordion doesn't like when you remove items from the array and try to set the selected index
          const temp = this.carrierRequests;
          this.carrierRequests = [];
          setTimeout(() => {
            this.carrierRequests = temp;
            this.pendingAddChange = this.carrierRequests.some((x) => x.status === CarrierAccessorialRequestStatusEnum.New);
            this.carrierRequestActiveIndex = null;
            this.changeDetectorRef.detectChanges();
          }, 1);
        } else {
          this.accessorialRequestService.deleteRequest(request.carrierAccessorialRequestId, this.loadId).subscribe(() => {
            this.carrierRequests.splice(index, 1);
          });
          this.pendingAddChange = this.carrierRequests.some((x) => x.status === CarrierAccessorialRequestStatusEnum.New);
        }

        this.refreshAvailableAccessorials = true;
      },
    });
  }

  getBadgeColor(request: CarrierAccessorialRequestData) {
    if (request && request.status) {
      switch (request.status as CarrierAccessorialRequestStatusEnum) {
        case CarrierAccessorialRequestStatusEnum.New:
          return {
            'badge-secondary': true,
          };
        case CarrierAccessorialRequestStatusEnum.Pending:
          return {
            'badge-primary': true,
          };
        case CarrierAccessorialRequestStatusEnum.Pending_AdditionalInfoRequired:
          return {
            'badge-warning': true,
          };
        case CarrierAccessorialRequestStatusEnum.Denied:
          return {
            'badge-danger': true,
          };
        case CarrierAccessorialRequestStatusEnum.Approved:
          return {
            'badge-success': true,
          };
      }
    }

    return;
  }

  areDocumentUploadsAllowed(request: CarrierAccessorialRequestData): boolean {
    if (this.user.isCarrier) {
      return (
        request &&
        request.status !== CarrierAccessorialRequestStatusEnum.Approved &&
        request.status !== CarrierAccessorialRequestStatusEnum.Denied
      );
    }
    return false;
  }

  updateActions(index: number): void {
    this.carrierRequestActiveIndex = index;
    this.availableActions = [];
    const request = this.carrierRequests[index];
    // check if they are a shipper user or admin
    if (this.user.isShipper && this.user.hasSecurityAction(SecurityAppActionType.ShipperAccessorialRequestsApprove)) {
      switch (request.status as CarrierAccessorialRequestStatusEnum) {
        case CarrierAccessorialRequestStatusEnum.Pending:
          this.availableActions = [
            {
              label: 'Approve',
              value: CarrierAccessorialRequestStatusEnum.Approved,
            },
            {
              label: 'Deny',
              value: CarrierAccessorialRequestStatusEnum.Denied,
            },
            {
              label: 'Additional Information Required',
              value: CarrierAccessorialRequestStatusEnum.Pending_AdditionalInfoRequired,
            },
          ];
          break;
        default:
          break;
      }
    } else {
      switch (request.status as CarrierAccessorialRequestStatusEnum) {
        case CarrierAccessorialRequestStatusEnum.Pending_AdditionalInfoRequired:
          this.availableActions = [
            {
              label: 'Reply',
              value: CarrierAccessorialRequestStatusEnum.Pending,
            },
          ];
          break;
        case CarrierAccessorialRequestStatusEnum.Denied: // this will be completed in a future story
        default:
          break;
      }
    }
  }

  clearActions(): void {
    this.availableActions = [];
  }
  takeAction(dropdownEvent: any, request: CarrierAccessorialRequestData): void {
    request.enableApply = true;
    this.pendingChange = true;
  }

  cancelAction(request: CarrierAccessorialRequestData): void {
    request.actionTaken = null;
    request.enableApply = false;

    this.pendingChange = this.carrierRequests.some((x) => !!x.actionTaken);
  }

  applyAction(request: CarrierAccessorialRequestData, index: number): void {
    // confirm the action via modal
    let commentRequired = false;
    let confirmationText = '';
    let header = 'Accessorial Approval Confirmation';
    if (this.user.isShipper) {
      switch (request.actionTaken) {
        case CarrierAccessorialRequestStatusEnum.Approved:
          commentRequired = false;
          confirmationText = `Are you sure want to approve ${
            request.loadAccessorial.accessorialDescription
          } for ${this.currencyPipe.transform(request.rate)}?`;
          header = 'Accessorial Approval Confirmation';
          break;
        case CarrierAccessorialRequestStatusEnum.Pending_AdditionalInfoRequired:
          commentRequired = true;
          confirmationText = `Are you sure want to request additional information for ${
            request.loadAccessorial.accessorialDescription
          } for ${this.currencyPipe.transform(request.rate)}?  Comments are required for the reason for requesting additional information.`;
          header = 'Accessorial Additional Information Confirmation';
          break;
        case CarrierAccessorialRequestStatusEnum.Denied:
          commentRequired = true;
          confirmationText = `Are you sure want to deny ${request.loadAccessorial.accessorialDescription} for ${this.currencyPipe.transform(
            request.rate
          )}?  Comments are required for the reason for denial.`;
          header = 'Accessorial Denied Confirmation';
          break;
      }
    } else {
      // Carrier dialog rules
      switch (request.actionTaken) {
        case CarrierAccessorialRequestStatusEnum.Pending:
          commentRequired = true;
          confirmationText = `Are you sure want to set Pending for ${
            request.loadAccessorial.accessorialDescription
          } for ${this.currencyPipe.transform(request.rate)}?  Comments are required for Pending to provide more information.`;
          header = 'Accessorial Pending Confirmation';
          break;
      }
    }

    // display accessorials modal
    const ref = this.dialogService.open(ConfirmActionModalComponent, {
      data: {
        commentRequired: commentRequired,
        request: request,
        user: this.user,
        confirmationText: confirmationText,
      },
      header: header,
      baseZIndex: accessorialRequestConfirmationModalZIndex,
    });
    // open the modal to confirm
    ref.onClose.pipe(takeUntil(this.destroyed$)).subscribe((result: { confirmed: boolean; comment: string }) => {
      if (result && result.confirmed) {
        this.loading = true;
        const action: CarrierAccessorialRequestActionData = {
          comment: result.comment as string,
          userName: this.user.name,
          createDtTm: new Date(),
          status: request.actionTaken,
          userId: this.user.userId,
        };
        // save the action and update request
        this.accessorialRequestService.saveAction(request.carrierAccessorialRequestId, action, true).subscribe(
          (updatedRequest) => {
            this.loading = false;
            // show toast
            this.messageService.add({
              detail: 'Accessorial request updated',
              severity: 'success',
            });
            // flag to refresh grid on modal close
            this.refreshGrid = true;
            // wipe all pending changes
            this.pendingChange = this.carrierRequests.some((x) => !!x.actionTaken);
            // replace request to see updates
            this.carrierRequests[index] = updatedRequest;
            // close the accordion tab
            this.availableActions = [];
            if (!this.openActionItem()) {
              // close the modal as there is no more action items
              this.ref.close({
                refreshGrid: true,
              });
            }
          },
          (error) => {
            this.loading = false;
          }
        );
      }
    });
  }
  allowAccessorialRequest(user: UserModel, isLoadLocked: boolean): boolean {
    if (user && user.isCarrier && !isLoadLocked && !user.hasSecurityAction(SecurityAppActionType.AccessorialRequestLock)) {
      return true;
    }
    return false;
  }

  deleteDocument(doc: LoadDocumentMetadata, request: CarrierAccessorialRequestData, index: number): void {
    this.confirmationService.confirm({
      header: 'Remove Accessorial',
      message: 'Are you sure you want to delete this document?',
      accept: () => {
        this.loading = true;
        // fire API to remove document
        this.accessorialRequestService
          .deleteAccessorialDocument(request.carrierAccessorialRequestId, doc.loadDocumentId)
          .subscribe((updatedAccessorial) => {
            this.messageService.add({
              detail: 'Accessorial document removed',
              severity: 'success',
            });
            this.loading = false;
            this.refreshGrid = true;
            // update the accessorial request
            this.carrierRequests[index] = updatedAccessorial;
            this.changeDetectorRef.detectChanges();
          });
      },
    });
  }
  getDocumentActionHeader(request: CarrierAccessorialRequestData): string {
    if (request.documents) {
      return `Documents (${request.documents.length})`;
    }
    return 'Documents';
  }
  handleDocumentChange(payload: LoadDocumentUpload, request: CarrierAccessorialRequestData, index: number): void {
    // save the file name as it will be null when sending to service
    const fileName = payload.file.name;

    this.loading = true;
    // upload document
    const comment: CarrierAccessorialRequestActionData = {
      comment: '',
      userName: this.user.name,
      createDtTm: new Date(),
      userId: this.user.userId,
      documentToUpload: payload,
    };
    this.accessorialRequestService.saveAction(request.carrierAccessorialRequestId, comment).subscribe(
      (x) => {
        // show toast
        this.messageService.add({
          detail: `Accessorial document uploaded: ${fileName}`,
          severity: 'success',
        });
        this.loading = false;

        this.refreshGrid = true;

        // refresh request
        this.carrierRequests[index] = x;
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.loading = false;
      }
    );
  }
  canDeleteAccessorial(request: CarrierAccessorialRequestData): boolean {
    return this.user && this.user.isCarrier && request.status === CarrierAccessorialRequestStatusEnum.New;
  }
  private openActionItem(): boolean {
    // open first tab where action is request
    const index = this.carrierRequests.findIndex((x) => x.status === CarrierAccessorialRequestStatusEnum.Pending);

    if (index > -1) {
      this.carrierRequestActiveIndex = index;
      this.updateActions(index);
      this.changeDetectorRef.detectChanges();
    }

    return index > -1;
  }

  private decodeProblemDetails(carrierRequests: CarrierAccessorialRequestData[], error: any) {
    if (!error || !error.errors) {
      return;
    }

    for (let index = 0; index < carrierRequests.length; index++) {
      const element = carrierRequests[index];
      const groupErrors = error.errors[`urn:CarrierAccessorialRequest:${index}`];
      if (groupErrors && Array.isArray(groupErrors)) {
        this.errorMsg += groupErrors.join('\n');
        element.hasError = true;
      } else if (groupErrors && typeof groupErrors === 'string') {
        this.errorMsg += groupErrors;
        element.hasError = true;
      }
    }

    return;
  }
}
