import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../shared/components';
import {
  AccessorialData,
  AccessorialUnitOfMeasureData,
  defaulShipperAccessorial,
  ShipperAccessorialData,
  ValidationProblemDetails,
} from '../../../../shared/models';
import {
  AdminState,
  getAccessorials,
  getAccessorialUnitOfMeasure,
  getGlobalAccessorials,
  getLoadingShipperAccessorials,
  getSaveShipperAccessorialProblemDetails,
  getShipperAccessorials,
  ShipperAccessorialAddAction,
  ShipperAccessorialLoadAccessorialsAction,
  ShipperAccessorialLoadAccessorialUoMAction,
  ShipperAccessorialLoadAction,
} from '../../../store';

@Component({
  selector: 'kbxl-accessorial-detail-container',
  templateUrl: './accessorial-detail-container.component.html',
  styleUrls: ['./accessorial-detail-container.component.scss'],
})
export class AccessorialDetailContainerComponent extends BaseComponent implements OnInit {
  loading$: Observable<boolean>;
  globalAccessorials$: Observable<ShipperAccessorialData[]>;
  shipperAccessorials$: Observable<ShipperAccessorialData[]>;
  accessorials$: Observable<AccessorialData[]>;
  accessorialUoM$: Observable<AccessorialUnitOfMeasureData[]>;
  saveErrors$: Observable<ValidationProblemDetails>;
  accessorial$: Observable<ShipperAccessorialData>;
  enableUoM = false;

  customerId = '';
  constructor(private adminStore: Store<AdminState>, private route: ActivatedRoute, private router: Router) {
    super();
    this.route.data.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x.enableUoM) {
        this.enableUoM = x.enableUoM;
      }
    });

    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x.customerId) {
        this.customerId = x.customerId;
        // load global accessorials
        this.adminStore.dispatch(new ShipperAccessorialLoadAction(null, true));
        this.adminStore.dispatch(new ShipperAccessorialLoadAction(x.customerId));
      } else {
        // load global accessorials
        this.adminStore.dispatch(new ShipperAccessorialLoadAction(null));
      }
    });
  }

  ngOnInit() {
    this.loading$ = this.adminStore.pipe(select(getLoadingShipperAccessorials));
    this.globalAccessorials$ = this.adminStore.pipe(select(getGlobalAccessorials));
    this.shipperAccessorials$ = this.adminStore.pipe(select(getShipperAccessorials));
    this.accessorials$ = this.adminStore.pipe(select(getAccessorials));
    this.accessorialUoM$ = this.adminStore.pipe(select(getAccessorialUnitOfMeasure));
    this.saveErrors$ = this.adminStore.pipe(select(getSaveShipperAccessorialProblemDetails));

    // currently only support add
    this.accessorial$ = of(this.deepClone(defaulShipperAccessorial) as ShipperAccessorialData);

    // load uom and accessorials
    this.adminStore.dispatch(new ShipperAccessorialLoadAccessorialsAction());
    this.adminStore.dispatch(new ShipperAccessorialLoadAccessorialUoMAction());
  }

  saveAccessorial(accessorial: ShipperAccessorialData): void {
    if (this.customerId && this.customerId.length > 0) {
      accessorial.customerId = this.customerId;
    }
    this.adminStore.dispatch(new ShipperAccessorialAddAction(accessorial));
    // reset copy default
    this.accessorial$ = of(this.deepClone(defaulShipperAccessorial) as ShipperAccessorialData);
  }
  cancel(args: boolean): void {
    if (this.customerId === '') {
      this.router.navigate(['/maint/configurations/']);
    } else {
      this.router.navigate(['/maint/accessorials/']);
    }
  }
}
