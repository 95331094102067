import { createSelector } from '@ngrx/store';
import { getSharedFeatureState, SharedState } from '../reducers';
import { getDocumentDownload, getLoaded, getLoading, getProcessedDocument } from '../reducers/load-document.reducer';

const getLoadDocumentState = createSelector(getSharedFeatureState, (state: SharedState) => state.loadDocumentTypes);

export const getLoadDocumentLoading = createSelector(getLoadDocumentState, getLoading);
export const getLoadDocumentLoaded = createSelector(getLoadDocumentState, getLoaded);
export const getLoadDocumentDownload = createSelector(getLoadDocumentState, getDocumentDownload);
export const getLoadDocumentProcessed = createSelector(getLoadDocumentState, getProcessedDocument);
