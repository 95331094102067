import { createReducer } from '../../../shared/utilities';
import { ConversationHubActions, ConversationHubActionTypes } from '../actions';

export interface ConversationHubState {
  loading: boolean;
  ready: boolean;
  error: string;
}

const initialState: ConversationHubState = {
  loading: false,
  ready: false,
  error: null,
};

const _conversationHubReducer = createReducer(
  initialState,
  function (state: ConversationHubState = initialState, action: ConversationHubActions): ConversationHubState {
    switch (action.type) {
      case ConversationHubActionTypes.Loading: {
        return { ...state, loading: action.payload };
      }
      case ConversationHubActionTypes.Ready: {
        let loading_Update = state.loading;

        if (action.payload) {
          // only set to false if hub is ready
          loading_Update = false;
        }

        return { ...state, ready: action.payload, loading: loading_Update };
      }
      case ConversationHubActionTypes.Error: {
        return { ...state, error: action.payload };
      }
      default:
        return state;
    }
  }
);
/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function conversationHubReducer(state: ConversationHubState, action: ConversationHubActions): ConversationHubState {
  return _conversationHubReducer(state, action);
}
