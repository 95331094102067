import {
  AppointmentSchedulerConfirmationType,
  Commodity,
  Customer,
  CustomerLoadType,
  DropdownOption,
  Equipment,
  LoadDocumentType,
  LoadshopSetting,
  ServiceType,
  State,
  TransportationMode,
  UnitOfMeasure,
} from '../../../shared/models';
import { createReducer } from '../../../shared/utilities';
import { StartupActions, StartupActionTypes } from '../actions';

export interface StartupState {
  loading: boolean;
  commodities: Commodity[];
  appointmentSchedulerConfirmationTypes: AppointmentSchedulerConfirmationType[];
  customerLoadTypes: CustomerLoadType[];
  unitsOfMeasure: UnitOfMeasure[];
  transportationModes: TransportationMode[];
  serviceTypes: ServiceType[];
  states: State[];
  dropdownOptions: DropdownOption[];
  equipment: Equipment[];
  loadshopSettings: LoadshopSetting[];
  documentTypes: LoadDocumentType[];
  carrierAuthorizedShippers: Customer[];
}

const initialState: StartupState = {
  loading: false,
  commodities: null,
  appointmentSchedulerConfirmationTypes: null,
  customerLoadTypes: null,
  unitsOfMeasure: null,
  transportationModes: null,
  serviceTypes: null,
  states: null,
  dropdownOptions: null,
  equipment: null,
  loadshopSettings: null,
  documentTypes: null,
  carrierAuthorizedShippers: null,
};

const _startupReducer = createReducer(initialState, function (state: StartupState = initialState, action: StartupActions): StartupState {
  switch (action.type) {
    case StartupActionTypes.Load: {
      return { ...state, loading: true };
    }
    case StartupActionTypes.Load_Success: {
      return {
        ...state,
        loading: false,
        commodities: action.payload.commodities,
        appointmentSchedulerConfirmationTypes: action.payload.appointmentSchedulerConfirmationTypes,
        customerLoadTypes: action.payload.customerLoadTypes,
        unitsOfMeasure: action.payload.unitsOfMeasure,
        transportationModes: action.payload.transportationModes,
        serviceTypes: action.payload.serviceTypes,
        states: action.payload.states,
        dropdownOptions: action.payload.dropDownOptions,
        equipment: action.payload.equipment,
        loadshopSettings: action.payload.loadshopSettings,
        documentTypes: action.payload.documentTypes,
        carrierAuthorizedShippers: action.payload.carrierAuthorizedShippers,
      };
    }
    case StartupActionTypes.Load_Failure: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
});

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function startupReducer(state: StartupState, action: StartupActions): StartupState {
  return _startupReducer(state, action);
}

export const getLoading = (state: StartupState) => state.loading;
export const getCommodityEntities = (state: StartupState) => state.commodities;
export const getAppointmentSchedulerConfirmationTypeEntities = (state: StartupState) => state.appointmentSchedulerConfirmationTypes;
export const getCustomerLoadTypeEntities = (state: StartupState) => state.customerLoadTypes;
export const getUnitOfMeasureEntities = (state: StartupState) => state.unitsOfMeasure;
export const getTransportationModeEntities = (state: StartupState) => state.transportationModes;
export const getServiceTypeEntities = (state: StartupState) => state.serviceTypes;
export const getServiceTypeManualLoadEntities = (state: StartupState) => state.serviceTypes?.filter((x) => !x.excludeFromManualLoads);
export const getStateEntities = (state: StartupState) => state.states;
export const getDropDownEntities = (state: StartupState) => state.dropdownOptions;
export const getEquipmentEntities = (state: StartupState) => state.equipment;
export const getLoadshopSettingsEntities = (state: StartupState) => state.loadshopSettings;
export const getDocumentTypeEntities = (state: StartupState) => state.documentTypes;
export const getCarrierAuthorizedShipperEntities = (state: StartupState) => state.carrierAuthorizedShippers;
