<kbxl-admin-header></kbxl-admin-header>

<div class="d-flex flex-column position-relative">
  <div class="progress-indicator" *ngIf="isProcessing">
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <div *ngIf="errorSummary">
    <div class="error-container">{{ errorSummary }}</div>
  </div>

<div class="d-flex">

  <!-- Cache Management flex column -->
  <div class="column">
    <div class="p-4">
      <p-panel styleClass="no-padding">
        <p-header>
          <div class="d-flex justify-content-between pr-2">
            <span>Cache Management</span>
          </div>
        </p-header>
      </p-panel>
      <div class="d-flex flex-column flex-lg-row ui-widget">
        <div class="d-flex">
          <div>
            <div class="p-field-checkbox">
              <p-checkbox [(ngModel)]="all" binary="true" inputId="binary" (ngModelChange)="allChanged()"></p-checkbox>
              <label>All</label>
            </div>
            <div *ngFor="let cache of caches" class="p-field-checkbox">
              <p-checkbox name="cacheboxen" value="cache" [value]="cache" [(ngModel)]="selectedCaches" [inputId]="cache.value" (ngModelChange)="selectedCachesChanged()"></p-checkbox>
              <label [for]="cache.value">{{cache.name}}</label>
            </div>
            <button pButton type="button" (click)="clear()" class="mt-2" label="Clear"></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Logging level flex column -->
  <div class="column justify-content-start">
    <div class="py-4">
      <p-panel styleClass="no-padding">
        <p-header>
          <div class="d-flex justify-content-between pr-2">
            <span>Logging Level</span>
          </div>
        </p-header>
      </p-panel>
    </div>
    <div class="pb-2">Select the minimum logging level below:</div>
    <kbxl-logging-config-container></kbxl-logging-config-container>
  </div>

  </div>
</div>
