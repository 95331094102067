<div class="progress-indicator" *ngIf="loading">
  <div class="progressspinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div class="d-flex flex-column wrapper">
  <!-- view current requests accessorial -->
  <div class="error-container my-2" *ngIf="errorMsg && errorMsg.length > 0">
    <p>{{ errorMsg }}</p>
  </div>
  <div class="d-flex flex-column mt-4" *ngIf="carrierRequests && carrierRequests.length === 0 && !loading">
    <h5 class="text-center text-muted">You have no accessorial requests</h5>
  </div>
  <div class="d-flex flex-column" *ngIf="carrierRequests && carrierRequests && carrierRequests.length > 0">
    <h6>Requests</h6>
    <p-accordion [activeIndex]="carrierRequestActiveIndex" (onOpen)="updateActions($event.index)" (onClose)="clearActions()">
      <p-accordionTab *ngFor="let request of carrierRequests; index as i">
        <p-header [ngClass]="{ error: request.hasError }">
          <div class="d-inline-flex">
            <span>{{ request.loadAccessorial.accessorial.description }}</span>
            <span class="badge ml-4" [ngClass]="getBadgeColor(request)">{{ request.statusDescription }}</span>
          </div>
        </p-header>
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center">
            <h6 class="d-inline-block">
              {{ request.loadAccessorial.accessorial.description }}
            </h6>
            <button
              *ngIf="canDeleteAccessorial(request)"
              class="p-button-danger ml-auto"
              pButton
              icon="pi pi-trash"
              (click)="removeAccessorialRequest(request, i)"
              title="Delete Request"
            ></button>
          </div>
          <div class="d-flex flex-column flex-md-row">
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span *ngIf="shouldShowAfterInitialHours(request)" class="load-detail-label">After Initial:</span>
                <span class="load-detail-label">Unit of Measure:</span>
                <span *ngIf="request.stop && request.stop > 0" class="load-detail-label">Stop:</span>
                <span *ngIf="request.quantity && request.quantity > 0" class="load-detail-label">Quantity:</span>
                <span *ngIf="request.ratePerUom && request.ratePerUom > 0" class="load-detail-label">Rate Per Unit of Measure:</span>
                <span class="load-detail-label">Rate:</span>
                <span class="load-detail-label">Status:</span>
                <span class="load-detail-label" [ngClass]="{ 'd-none': !request.apAuthNbr || request.apAuthNbr.length === 0 }"
                  >Authorization Number:</span
                >
              </div>
              <div class="d-flex flex-column">
                <div *ngIf="shouldShowAfterInitialHours(request)" class="load-detail-value">
                  {{ request.loadAccessorial.afterInitialHours }} hr(s)
                </div>
                <div class="load-detail-value">{{ request.loadAccessorial.accessorialUnitOfMeasure.description }}</div>
                <div class="load-detail-value" *ngIf="request.stop && request.stop > 0">{{ request.stop }}</div>
                <div class="load-detail-value" *ngIf="request.quantity && request.quantity > 0">{{ request.quantity }}</div>
                <div class="load-detail-value" *ngIf="request.ratePerUom && request.ratePerUom > 0">
                  {{ request.ratePerUom | currency }}
                </div>
                <div class="load-detail-value">{{ request.rate | currency }}</div>
                <div class="load-detail-value">{{ request.statusDescription }}</div>
                <div class="load-detail-value" [ngClass]="{ 'd-none': !request.apAuthNbr || request.apAuthNbr.length === 0 }">
                  {{ request.apAuthNbr }}
                </div>
              </div>
            </div>
            <div class="ml-md-auto action-dropdown" *ngIf="availableActions && availableActions.length > 0">
              <div class="kbxl-inputgroup-container">
                <div class="kbxl-field-label">
                  <label for="name" class="control-label">Action</label>
                </div>
                <p-dropdown
                  [options]="availableActions"
                  placeholder="Select Action"
                  [styleClass]="'p-dropdown-primary'"
                  dropdownIcon="pi pi-caret-down"
                  baseZIndex="10000"
                  [(ngModel)]="request.actionTaken"
                  (onChange)="takeAction($event, request)"
                ></p-dropdown>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between mt-2" *ngIf="availableActions.length > 0 && request.enableApply">
            <button
              pButton
              type="button"
              class="p-button-outlined p-button-secondary"
              label="Cancel"
              [disabled]="!request.enableApply"
              (click)="cancelAction(request)"
            ></button>
            <button pButton type="button" label="Apply" [disabled]="!request.enableApply" (click)="applyAction(request, i)"></button>
          </div>
          <div *ngIf="user && user.isShipper && request.requesterUserData">
            <hr />
            <h6>Carrier Contact Information</h6>
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="load-detail-label">Name:</span>
                <span class="load-detail-label" *ngIf="request.requesterUserData?.email">Email: </span>
                <span class="load-detail-label" *ngFor="let phone of request.requesterUserData?.cellPhoneNumbers">Cell:</span>
                <span class="load-detail-label" *ngFor="let phone of request.requesterUserData?.phoneNumbers">Phone:</span>
              </div>
              <div class="d-flex flex-column">
                <div class="load-detail-value">{{ request.requesterUserData.fullName | titlecase }}</div>
                <div class="booked-email d-flex" *ngIf="request.requesterUserData?.email">
                  <a class="text-truncate" href="mailto: {{ request.requesterUserData?.email }}" [title]="request.requesterUserData?.email"
                    >{{ request.requesterUserData?.email | lowercase }}
                  </a>
                  <kbxl-copy-to-clipboard class="ml-1" [text]="request.requesterUserData?.email | lowercase"></kbxl-copy-to-clipboard>
                </div>
                <div class="text-nowrap" *ngFor="let phone of request.requesterUserData?.cellPhoneNumbers">
                  <a href="tel:{{ phone }}">{{ phone }}</a>
                </div>
                <div class="text-nowrap" *ngFor="let phone of request.requesterUserData?.phoneNumbers">
                  <a href="tel:{{ phone }}">{{ phone }}</a>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="request.actions">
            <hr />
            <kbxl-accessorial-request-comments
              [user]="user"
              [carrierRequest]="request"
              [(loading)]="loading"
            ></kbxl-accessorial-request-comments>
          </div>
          <div>
            <hr />
            <p-panel [toggleable]="true" [collapsed]="true" styleClass=" no-padding">
              <p-header class="mr-2">
                {{ getDocumentActionHeader(request) }}
              </p-header>
              <div *ngIf="areDocumentUploadsAllowed(request)">
                <kbxl-upload-document-modal
                  [loadId]="loadId"
                  (close)="handleDocumentChange($event, request, i)"
                  [customDocumentUpload]="true"
                  [basicFileSelect]="true"
                >
                </kbxl-upload-document-modal>
              </div>
              <div *ngIf="request && request.documents && request.documents.length > 0">
                <div class="mt-2" *ngFor="let doc of request.documents">
                  <kbxl-document-list-item [document]="doc" [isReadOnly]="isDocumentReadOnly(doc)" (removeDocument)="deleteDocument($event, request, i)"></kbxl-document-list-item>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
  <!-- add new accessorial -->
  <div class="mt-3 alert alert-warning" role="alert" *ngIf="shouldLockedOutMessage()">
    <span>
      You may no longer submit new accessorial requests due to being 48 hours after delivery. To request a new accessorial, please contact
      the shipper to unlock the load.</span
    >
  </div>
  <div class="mt-3" *ngIf="requestAccessorial">
    <kbxl-add-accessorial-request
      [user]="user"
      [shipperAccessorials]="shipperAccessorials"
      [carrierRequests]="carrierRequests"
      [(refreshAvailableAccessorials)]="refreshAvailableAccessorials"
      (addAccessorialRequest)="addNewAccessorial($event)"
    >
    </kbxl-add-accessorial-request>
  </div>

  <div class="d-flex justify-content-between mt-4 mb-2">
    <button pButton type="button" class="p-button-outlined p-button-secondary" label="Cancel" (click)="cancel()"></button>
    <button
      *ngIf="user && user.isCarrier && !loadLocked"
      pButton
      type="button"
      class="ml-3"
      label="Request Accessorials"
      [disabled]="!pendingAddChange"
      (click)="saveAccessorials()"
    ></button>
  </div>
</div>
