import { CustomerLaneData } from './customer-lane-data';

export class CustomerLaneGroupData {
  customerLaneGroupId: number;
  customerId: string;
  name: string;
  description: string;
  loadCarrierGroupTypeId: string;
  specialInstructions: string;
  rank: number;

  customerLanes: CustomerLaneData[];

  hasRateSeeker: boolean;
  hasSpecialInstructions: boolean;
  hasExclusionCarrierGroup: boolean;
  hasInclusionCarrierGroup: boolean;
  hasShipperContacts: boolean;
  hasRequiredServiceTypes: boolean;
  allowCounterOffer: boolean;
  serviceTypeIds: number[];
  // brokered land group settings
  quoteRequiredServiceTypeIds: number[];
  brokeredLaneGroup: boolean;
  eligibleForQuote: boolean;
  marginAdjustment?: number;
}

export const defaultCustomerLaneGroupData: CustomerLaneGroupData = {
  customerLaneGroupId: null,
  customerId: null,
  name: null,
  description: null,
  loadCarrierGroupTypeId: null,
  specialInstructions: null,
  rank: 99999,
  customerLanes: [],

  hasRateSeeker: null,
  hasSpecialInstructions: null,
  hasExclusionCarrierGroup: null,
  hasInclusionCarrierGroup: null,
  hasShipperContacts: null,
  hasRequiredServiceTypes: null,
  allowCounterOffer: false,
  serviceTypeIds: null,
  quoteRequiredServiceTypeIds: null,
  brokeredLaneGroup: false,
  eligibleForQuote: false,
  marginAdjustment: null,
};
