import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActionItemLoadView, ActionItemsFilterCriteriaData, PageableResult, ServiceResponse } from '../models';
import { mapResponse } from '../operators/map-response';
import { PageableQueryHelper } from '../utilities';

@Injectable()
export class AccessorialActionItemService {
  constructor(private http: HttpClient) {}

  getFilterCriteria(): Observable<ActionItemsFilterCriteriaData> {
    return this.http
      .get<ServiceResponse<ActionItemsFilterCriteriaData>>(`${environment.apiUrl}/api/action-items/accessorials/filters`)
      .pipe(mapResponse());
  }

  getPageableLoadsBySearchType(
    searchType: string,
    queryHelper: PageableQueryHelper = null
  ): Observable<PageableResult<ActionItemLoadView>> {
    let query = '';
    if (queryHelper) {
      query = queryHelper.generateQuery();
    }

    if (queryHelper && queryHelper.filter) {
      return this.http
        .post<ServiceResponse<PageableResult<ActionItemLoadView>>>(
          environment.apiUrl + '/api/action-items/accessorials/search' + query,
          queryHelper.filter
        )
        .pipe(mapResponse());
    } else {
      return this.http
        .get<ServiceResponse<PageableResult<ActionItemLoadView>>>(environment.apiUrl + '/api/action-items/accessorials/search' + query)
        .pipe(mapResponse());
    }
  }
}
