<div class="row" *ngIf="load.loadAccessorialRequests">
  <div class="col-12">
    <div class="d-flex">
      <label class="load-detail-header"> Accessorials </label>
    </div>
  </div>
  <div
    class="col-12"
    *ngIf="load.loadAccessorialRequests.canAccessorialsBeLocked && isShippingDetail && primaryCustomer.useLoadshopAccessorials"
    kbxlActionCheck="loadshop.ui.shipper.accessorial.requests.lock"
  >
    <div class="d-flex align-items-center justify-content-end">
      <div>
        <label class="load-detail-label">Accessorial Requests {{ loadAccessorialRequestsLocked ? 'Locked' : 'Unlocked' }}</label>
        <label class="load-detail-label d-block" *ngIf="!loadAccessorialRequestsLocked && load.loadAccessorialRequests.unlockedUser">
          By: {{ load.loadAccessorialRequests?.unlockedUser?.fullName | titlecase }} -
          {{ load.loadAccessorialRequests?.unlockedUser?.userName }}
        </label>
      </div>

      <p-inputSwitch
        class="ml-3"
        [(ngModel)]="loadAccessorialRequestsLocked"
        (onChange)="lockLoadAccessorialRequests($event)"
        [readonly]="!load.loadAccessorialRequests.canAccessorialsBeLocked"
      ></p-inputSwitch>
    </div>
  </div>
  <div class="col-12">
    <div class="d-flex">
      <button
        type="text"
        pButton
        class="p-button-outlined p-button-secondary"
        label="View Accessorials"
        *ngIf="load.loadAccessorials?.length > 0"
        (click)="showAccessorialDialog = true"
        kbxlActionCheck="loadshop.ui.carrier.myloads.documents.view"
      ></button>
      <button
        *ngIf="shouldDisplayAccessorial()"
        pButton
        [icon]="getRequestIcon()"
        type="button"
        class="p-button-teal ml-auto"
        [label]="getRequestLabel()"
        (click)="viewAccessorials()"
      ></button>
    </div>
  </div>
  <div class="col-12 mt-2" *ngIf="shouldLockedOutMessage()">
    <span class="text-muted">
      You may no longer submit new accessorial requests due to being 48 hours after delivery. To request a new accessorial, please contact
      the shipper to unlock the load.</span
    >
  </div>
  <div class="col-12">
    <span *ngIf="!load.loadAccessorials || load.loadAccessorials.length === 0" class="font-weight-bold teal-text">
      Accessorials based on contract with Shipper.
    </span>
  </div>
</div>
<p-dialog
  [style]="{ width: '600px' }"
  [appendTo]="'body'"
  [baseZIndex]="51"
  header="Load Accessorials"
  [modal]="true"
  [closeOnEscape]="true"
  [contentStyle]="{ padding: 0 }"
  [(visible)]="showAccessorialDialog"
>
  <kbxl-load-accessorial-view [accessorials]="load?.loadAccessorials" [height]="300"></kbxl-load-accessorial-view>
</p-dialog>
