import { Action } from '@ngrx/store';
import { LoadshopApplicationActions, LoadshopApplicationActionTypes, LoadshopApplicationUserEntityResetAction } from '../store/actions';

export function createReducer<TState, TAction extends Action>(
  initialState: TState,
  reducer: (state: TState, action: TAction | LoadshopApplicationActions) => TState,
  options: ResetActionsToListen = null
): (state: TState, action: TAction) => TState {
  return function (state: TState = initialState, action: TAction | LoadshopApplicationActions | LoadshopApplicationUserEntityResetAction) {
    if (action.type === LoadshopApplicationActionTypes.LoadshopReset) {
      return initialState;
    }
    if (options && options.userEntityReset && action.type === LoadshopApplicationActionTypes.User_Entity_Reset) {
      return initialState;
    }
    return reducer(state, action as TAction);
  };
}

export class ResetActionsToListen {
  // Listens for the LoadshopApplicationActionTypes.User_Entity_Reset action
  userEntityReset = false;
}
