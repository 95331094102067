export interface LinehaulRateData {
  linehaulRate: number;
  fuelRate: number;
  loadshopFee: number;
  usesAllInRates: boolean;
  isEstimatedFSC: boolean;
  isShipper: boolean;
  isCounterOffer: boolean;
  isBrokeredLoad: boolean;
}

export const defaultLinehaulRateData: LinehaulRateData = {
  linehaulRate: null,
  fuelRate: null,
  loadshopFee: null,
  usesAllInRates: false,
  isEstimatedFSC: false,
  isShipper: false,
  isCounterOffer: false,
  isBrokeredLoad: false,
};
