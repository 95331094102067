<div>
  <p-dropdown
            id="logLevelDropdown"
            [options]="loggingLevels"
            [(ngModel)]="selectedLoggingLevel"
            dataKey="Id"
            optionLabel="Label"
            (onChange)="updateLoggingLevel()"
            [styleClass]="'p-dropdown-primary'"
            dropdownIcon="pi pi-caret-down"
            placeholder="Loading..."
          >
  </p-dropdown>
</div>
