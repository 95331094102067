import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ShippingLoadDetail } from 'src/app/shared/models';

@Component({
  selector: 'kbxl-shipping-load-rate-increase-confirm-action',
  templateUrl: './shipping-load-rate-increase-confirm-action.component.html',
  styleUrls: ['./shipping-load-rate-increase-confirm-action.component.scss'],
})
export class ShippingLoadRateIncreaseConfirmActionComponent implements OnInit {
  loads: ShippingLoadDetail[];
  showBreakEven: boolean;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.loads = this.config.data.loads;
  }

  ngOnInit(): void {
    this.showBreakEven = false;
  }
  no(): void {
    this.ref.close({
      confirmed: false,
    });
  }
  yes(): void {
    this.ref.close({
      confirmed: true,
    });
  }
}
