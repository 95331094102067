import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { firstBy } from 'thenby';
import {
  CarrierGetUsersInCompanyAction,
  CoreState,
  CustomerGetUsersInCompanyAction,
  getBrowserIsMobile,
  getCarrierCompanyUsers,
  getCustomerCompanyUsers,
  getStates,
} from '../../../../core/store';
import { getUserProfileModel, UserState } from '../../../../user/store';
import { ActionItemSearch, ActionItemStatusData, CounterOfferActionItemLoadView, State, UserContactData, UserModel } from '../../../models';
import {
  ActionItemLoadCountAction,
  CounterOfferActionItemLoadFiltersAction,
  CounterOfferActionItemsLoadAction,
  CounterOfferActionItemsUpdateQueryAction,
  getCounterOfferActionItemFilterCriteriaLoading,
  getCounterOfferActionItemQueryHelper,
  getCounterOfferActionItems,
  getCounterOfferActionItemsLoading,
  getCounterOfferActionItemStatuses,
  getCounterOfferActionItemsTotalRecords,
  SharedState,
} from '../../../store';
import { PageableQueryData, PageableQueryHelper, PageableUiHelper, reloadDataOnNavigationBackToRoute } from '../../../utilities';
import { BaseComponent } from '../../base-component';
import { CounterOfferActionItemGridComponent } from '../counter-offer-action-item-grid';

@Component({
  selector: 'kbxl-counter-offer-action-item-container',
  templateUrl: './counter-offer-action-item-container.component.html',
  styleUrls: ['./counter-offer-action-item-container.component.scss'],
  providers: [DialogService],
})
export class CounterOfferActionItemContainerComponent extends BaseComponent implements OnInit, OnDestroy {
  actionItems$: Observable<CounterOfferActionItemLoadView[]>;
  loadingFilter$: Observable<boolean>;
  loading$: Observable<boolean>;
  actionItemStatuses$: Observable<ActionItemStatusData>;
  user: UserModel;
  states$: Observable<State[]>;
  isMobile$: Observable<boolean>;
  totalRecords$: Observable<number>;
  companyUsers$: Observable<UserContactData[]>;
  // Paging
  @ViewChild(CounterOfferActionItemGridComponent, { static: true }) actionItemsGrid: CounterOfferActionItemGridComponent;
  pagingUiHelper: PageableUiHelper<CounterOfferActionItemsUpdateQueryAction, CounterOfferActionItemsLoadAction, SharedState>;

  pageReady = false;
  constructor(
    private store: Store<SharedState>,
    private coreState: Store<CoreState>,
    private userStore: Store<UserState>,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.userStore.pipe(select(getUserProfileModel), takeUntil(this.destroyed$)).subscribe((x) => {
      this.user = x;

      if (!this.user) {
        return;
      }

      // go fetch other users that are in this users company / organization
      if (this.user.isShipper) {
        this.store.dispatch(new CustomerGetUsersInCompanyAction(this.user.primaryCustomerId));
      } else {
        this.store.dispatch(new CarrierGetUsersInCompanyAction(this.user.carrierScac));
      }

      // get the init query
      this.userStore.pipe(select(getCounterOfferActionItemQueryHelper), take(1)).subscribe((currentQuery) => {
        const defaultFilter: ActionItemSearch = new ActionItemSearch();
        defaultFilter.userIds = [this.user.userId];

        const newQuery = new PageableQueryHelper(
          currentQuery.pageSize,
          currentQuery.pageNumber,
          currentQuery.filter,
          currentQuery.sortField,
          currentQuery.descending
        );

        newQuery.filter = defaultFilter;

        this.store.dispatch(new CounterOfferActionItemsUpdateQueryAction(newQuery));
        this.pageReady = true;
      });
    });

    this.actionItems$ = this.store.pipe(
      select(getCounterOfferActionItems),
      map((x) => this.deepClone(x))
    );
    this.loadingFilter$ = this.store.pipe(select(getCounterOfferActionItemFilterCriteriaLoading));
    this.actionItemStatuses$ = this.store.pipe(select(getCounterOfferActionItemStatuses));
    this.states$ = this.coreState.pipe(select(getStates));
    this.isMobile$ = this.coreState.pipe(select(getBrowserIsMobile));
    this.loading$ = this.store.pipe(select(getCounterOfferActionItemsLoading));
    this.totalRecords$ = this.store.pipe(select(getCounterOfferActionItemsTotalRecords));
    const carrierUsers$ = this.coreState.pipe(
      select(getCarrierCompanyUsers),
      map((x) => {
        if (x) {
          const ary = this.deepClone(x) as UserContactData[];
          return ary.sort(firstBy((y) => y.fullName));
        }
        return null;
      })
    );
    const customerUsers$ = this.coreState.pipe(
      select(getCustomerCompanyUsers),
      map((x) => {
        if (x) {
          const ary = this.deepClone(x) as UserContactData[];
          return ary.sort(firstBy((y) => y.fullName));
        }
        return null;
      })
    );

    // we should only receive users from one selector
    this.companyUsers$ = merge(carrierUsers$, customerUsers$);

    // fetch the action items and status for this customer
    this.store.dispatch(new CounterOfferActionItemLoadFiltersAction());

    this.pagingUiHelper = new PageableUiHelper({
      filterBehavior: new BehaviorSubject<ActionItemSearch>(null),
      pageableQueryHelper$: this.store.pipe(
        select(getCounterOfferActionItemQueryHelper),
        map((_) => this.deepClone(_))
      ),
      pagingBehavior: new BehaviorSubject<PageableQueryData>(null),
      store: this.store,
      pageableComponent: this.actionItemsGrid,
      getQueryUpdateAction: (currentQuery: PageableQueryHelper) => new CounterOfferActionItemsUpdateQueryAction(currentQuery),
      getLoadAction: (currentQuery: PageableQueryHelper) =>
        new CounterOfferActionItemsLoadAction({
          searchType: '',
          queryHelper: currentQuery,
        }),
    });

    reloadDataOnNavigationBackToRoute(this.router, '/loads/action-items/counter-offers', this.destroyed$, this.pagingUiHelper);
  }

  ngOnDestroy(): void {
    if (this.pagingUiHelper) {
      this.pagingUiHelper.ngOnDestroy();
    }
    super.ngOnDestroy();
  }

  search(criteria: ActionItemSearch): void {
    this.pagingUiHelper.pageableUiData.filterBehavior.next(criteria);
  }

  clear() {
    this.pagingUiHelper.pageableUiData.filterBehavior.next(null);
  }

  onLazyLoadEvent(pagingData: PageableQueryData) {
    this.pagingUiHelper.pageableUiData.pagingBehavior.next(pagingData);
  }

  refreshData(event: boolean): void {
    this.pagingUiHelper.reloadData();
    // update action item counter
    this.store.dispatch(new ActionItemLoadCountAction());
  }
  selected(loadId: string) {
    this.router.navigate(['detail', loadId], { relativeTo: this.route });
  }
}
