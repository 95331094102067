import { Action } from '@ngrx/store';
import { UserSelectItem } from '../../../shared/models';

export enum ShipperUserActionTypes {
  Load = '[ShipperUser] LOAD',
  Load_Success = '[ShipperUser] LOAD_SUCCESS',
  Load_Failure = '[ShipperUser] LOAD_FAILURE',
}

export class ShipperUserLoadAction implements Action {
  readonly type = ShipperUserActionTypes.Load;

  constructor(public payload: string) {}
}

export class ShipperUserLoadSuccessAction implements Action {
  readonly type = ShipperUserActionTypes.Load_Success;

  constructor(public payload: UserSelectItem[]) {}
}

export class ShipperUserLoadFailureAction implements Action {
  readonly type = ShipperUserActionTypes.Load_Failure;

  constructor(public payload: Error) {}
}

export type ShipperUserActions = ShipperUserLoadAction | ShipperUserLoadSuccessAction | ShipperUserLoadFailureAction;
