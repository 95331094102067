import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ConfirmationService, MessageService } from 'primeng/api';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  Carrier,
  CustomerLaneGroup,
  CustomerLaneGroupDetailData,
  defaultCustomerLaneData,
  DropdownOption,
  Equipment,
  LoadCarrierGroupType,
  ResponseError,
  ServiceType,
  State,
} from 'src/app/shared/models';
import { getUserProfileEntity, UserState } from 'src/app/user/store';
import {
  CarrierLoadAction,
  CoreState,
  DropDownOptionTypeIds,
  getBookingEligibleCarriers,
  getDropDowns,
  getEquipment,
  getLoadingEquipment,
  getLoadingStates,
  getStates,
} from '../../../../core/store';
import { BaseComponent } from '../../../../shared/components';
import {
  AdminState,
  getLoadCustomerLaneGroupTypes,
  getLoadCustomerLaneGroupTypesLoading,
  getLoadingSelectedLoadCustomerLaneGroup,
  getLoadingSelectedShipperServiceTypes,
  getSaveLoadCustomerLaneGroupProblemDetails,
  getSaveLoadCustomerLaneGroupSucceeded,
  getSavingLoadCustomerLaneGroup,
  getSelectedLoadCustomerLaneGroup,
  getSelectedShipperRequiredServiceTypes,
  getSelectedShipperServiceTypes,
  LaneManagementAddAction,
  LaneManagementClearSaveSucceededAction,
  LaneManagementDeleteAction,
  LaneManagementLoadCarrierGroupTypesAction,
  LaneManagementLoadGroupAction,
  LaneManagementLoadGroupSuccessAction,
  LaneManagementUpdateAction,
  ShipperProfileLoadAction,
} from '../../../store';

@Component({
  selector: 'kbxl-lane-group-detail-container',
  templateUrl: './lane-group-detail-container.component.html',
  styleUrls: ['./lane-group-detail-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaneGroupDetailContainerComponent extends BaseComponent implements OnInit, OnDestroy {
  group$: Observable<CustomerLaneGroupDetailData>;
  states$: Observable<State[]>;
  processing$: Observable<boolean>;
  errors$: Observable<ResponseError[]>;
  allCarriers$: Observable<Carrier[]>;
  loadCarrierGroupTypes$: Observable<LoadCarrierGroupType[]>;
  percentOptions$: Observable<DropdownOption[]>;
  frequencyOptions$: Observable<DropdownOption[]>;
  intervalOptions$: Observable<DropdownOption[]>;
  equipment$: Observable<Equipment[]>;
  customerServiceTypes$: Observable<ServiceType[]>;

  constructor(
    private adminStore: Store<AdminState>,
    private coreStore: Store<CoreState>,
    private userStore: Store<UserState>,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    super();
  }

  ngOnInit() {
    this.route.params
      .pipe(
        map((p) => (p.id ? parseInt(p.id, 0) : null)),
        takeUntil(this.destroyed$)
      )
      .subscribe((id) => {
        if (isNaN(id)) {
          this.messageService.add({ detail: 'Invalid lane group ID provided.', severity: 'error' });
        } else {
          this.userStore.pipe(select(getUserProfileEntity), takeUntil(this.destroyed$)).subscribe((user) => {
            if (user && user.primaryCustomerId) {
              this.adminStore.dispatch(new ShipperProfileLoadAction({ customerId: user.primaryCustomerId }));

              if (id > 0) {
                this.adminStore.dispatch(new LaneManagementLoadGroupAction({ LaneManagementId: id }));
              } else {
                // CREATE A NEW LANE GROUP
                this.adminStore
                  .pipe(select(getSelectedShipperRequiredServiceTypes), takeUntil(this.destroyed$))
                  .subscribe((requiredServiceTypeIds) => {
                    this.adminStore.dispatch(
                      new LaneManagementLoadGroupSuccessAction(
                        new CustomerLaneGroup({
                          customerId: user.primaryCustomerId,
                          customerLanes: [{ ...defaultCustomerLaneData }],
                          customerLaneGroupId: 0,
                          carrierIds: [],
                          customerLaneGroupContacts: [],
                          customerLaneGroupPricingRule: null,
                          serviceTypeIds: requiredServiceTypeIds,
                          brokeredLaneGroup: false,
                          eligibleForQuote: false,
                          quoteRequiredServiceTypeIds: [],
                        })
                      )
                    );
                  });
              }
            }
          });
        }
      });

    this.loadCarrierGroupTypes$ = this.adminStore.pipe(select(getLoadCustomerLaneGroupTypes));
    this.adminStore.dispatch(new LaneManagementLoadCarrierGroupTypesAction());

    this.equipment$ = this.coreStore.pipe(select(getEquipment));
    this.customerServiceTypes$ = this.adminStore.pipe(select(getSelectedShipperServiceTypes));

    this.coreStore.dispatch(new CarrierLoadAction());

    this.allCarriers$ = this.coreStore.pipe(select(getBookingEligibleCarriers));

    this.states$ = this.coreStore.pipe(select(getStates));
    this.group$ = this.adminStore.pipe(
      select(getSelectedLoadCustomerLaneGroup),
      map((x) => this.deepClone(x))
    );
    this.processing$ = combineLatest([
      this.adminStore.pipe(select(getLoadingSelectedLoadCustomerLaneGroup)),
      this.adminStore.pipe(select(getSavingLoadCustomerLaneGroup)),
      this.coreStore.pipe(select(getLoadingEquipment)),
      this.coreStore.pipe(select(getLoadingStates)),
      this.adminStore.pipe(select(getLoadCustomerLaneGroupTypesLoading)),
      this.adminStore.pipe(select(getLoadingSelectedShipperServiceTypes)),
    ]).pipe(map((args) => args[0] || args[1] || args[2] || args[3] || args[4] || args[5]));

    this.errors$ = this.adminStore.pipe(select(getSaveLoadCustomerLaneGroupProblemDetails));

    this.adminStore.pipe(select(getSaveLoadCustomerLaneGroupSucceeded), takeUntil(this.destroyed$)).subscribe((saveSucceeded) => {
      if (saveSucceeded) {
        this.adminStore.dispatch(new LaneManagementClearSaveSucceededAction());
        this.router.navigate(['maint/lane-management']);
      }
    });

    this.percentOptions$ = this.coreStore.pipe(select(getDropDowns, DropDownOptionTypeIds.DPPercentage));
    this.frequencyOptions$ = this.coreStore.pipe(select(getDropDowns, DropDownOptionTypeIds.DPFrequency));
    this.intervalOptions$ = this.coreStore.pipe(select(getDropDowns, DropDownOptionTypeIds.DPInterval));
  }

  save(customerLaneGroup: CustomerLaneGroupDetailData) {
    if (customerLaneGroup.customerLaneGroupId > 0) {
      this.adminStore.dispatch(new LaneManagementUpdateAction(customerLaneGroup));
    } else {
      this.adminStore.dispatch(new LaneManagementAddAction(customerLaneGroup));
    }
  }

  delete(customerLaneGroup: CustomerLaneGroupDetailData) {
    this.adminStore.dispatch(new LaneManagementDeleteAction(customerLaneGroup));
  }

  cancel(isDirty: boolean) {
    if (isDirty) {
      this.confirmationService.confirm({
        message: 'Changes will not be saved. Are you sure you want to proceed?',
        accept: () => {
          this.router.navigate(['maint/lane-management']);
        },
      });
    }
  }
}
