import { Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api';
import {
  AccessorialData,
  AccessorialUnitOfMeasureData,
  Carrier,
  Commodity,
  CustomerFavorite,
  CustomerLoadType,
  DropdownOption,
  Equipment,
  isAccessorialArray,
  isAccessorialUnitOfMeasureArray,
  isCarrierArray,
  isCommodityArray,
  isCustomerFavoriteArray,
  isCustomerLoadTypeArray,
  isDropDownOptionArray,
  isEquipmentArray,
  isRatingQuestionArray,
  isUserAdminDataArray,
  isUserFavoriteArray,
  RatingQuestion,
  UserAdminData,
  UserFavorite,
} from '../models';
import { FuelProgramTypeOptions, isFuelProgramTypeArray } from '../models/FuelProgramType';

@Pipe({ name: 'toSelectItem' })
export class ToSelectItemPipe implements PipeTransform {
  public transform(
    list:
      | Equipment[]
      | Carrier[]
      | Commodity[]
      | UserAdminData[]
      | CustomerLoadType[]
      | RatingQuestion[]
      | DropdownOption[]
      | UserFavorite[]
      | AccessorialUnitOfMeasureData[]
      | AccessorialData[]
      | CustomerFavorite[]
      | FuelProgramTypeOptions[]
  ): SelectItem[] {
    if (!list || !list.length) {
      return undefined;
    }
    if (isEquipmentArray(list)) {
      return list.map((p) => ({
        label: p.equipmentDesc,
        value: p.equipmentId,
      }));
    } else if (isCarrierArray(list)) {
      return list.map((p) => ({ label: p.carrierName, value: p.carrierId }));
    } else if (isCommodityArray(list)) {
      return list.map((p) => ({
        label: p.commodityName,
        value: p.commodityName,
      }));
    } else if (isUserAdminDataArray(list)) {
      return list.map((p) => ({
        label: `${p.firstName} ${p.lastName}`,
        value: p.userId,
      }));
    } else if (isCustomerLoadTypeArray(list)) {
      return list.map((p) => ({
        label: p.name,
        value: p.customerLoadTypeId,
      }));
    } else if (isRatingQuestionArray(list)) {
      return list.map((p) => ({
        label: p.question,
        value: p.ratingQuestionId,
      }));
    } else if (isDropDownOptionArray(list)) {
      return list.map((p) => ({
        label: p.display,
        value: p.value,
      }));
    } else if (isUserFavoriteArray(list)) {
      return list.map((p) => ({
        label: p.data.name,
        value: p.userFavoriteId,
      }));
    } else if (isAccessorialArray(list)) {
      return list.map((p) => ({
        label: p.description,
        value: p.accessorialId,
      }));
    } else if (isAccessorialUnitOfMeasureArray(list)) {
      return list.map((p) => ({
        label: p.description,
        value: p.accessorialUnitOfMeasureId,
      }));
    } else if (isCustomerFavoriteArray(list)) {
      return list.map((p) => ({
        label: p.data.name,
        value: p.customerFavoriteId,
      }));
    } else if (isFuelProgramTypeArray(list)) {
      return list.map((p) => ({
        label: p.description,
        value: p.id,
      }));
    }
  }
}
