import { Action } from '@ngrx/store';
import { LoadView, LoadClaim, PageableResult, UpdateVisibilityRequest } from '../../../shared/models';
import { PageableQueryHelper } from 'src/app/shared/utilities';

export enum LoadBoardBookedActionTypes {
  Load = '[LoadBoardBooked] LOAD',
  Load_Success = '[LoadBoardBooked] LOAD_SUCCESS',
  Load_Failure = '[LoadBoardBooked] LOAD_FAILURE',
  Selected = '[LoadBoardBooked] SELECTED',
  Update_Visibility_Success = '[LoadBoardBooked] UPDATE_VISIBILITY_SUCCESS',
  Update_Visibility_Failure = '[LoadBoardBooked] UPDATE_VISIBILITY_FAILURE',
  Update_Query = '[LoadBoardBooked] UPDATE_QUERY',
  Update_Visibility = '[LoadBoardBooked] UPDATE_VISIBILITY',
}

export class LoadBoardBookedLoadAction implements Action {
  readonly type = LoadBoardBookedActionTypes.Load;
  constructor(public payload: { queryHelper: PageableQueryHelper }) {}
}

export class LoadBoardBookedLoadSuccessAction implements Action {
  readonly type = LoadBoardBookedActionTypes.Load_Success;

  constructor(public payload: PageableResult<LoadView>) {}
}

export class LoadBoardBookedLoadFailureAction implements Action {
  readonly type = LoadBoardBookedActionTypes.Load_Failure;

  constructor(public payload: Error) {}
}

export class LoadBoardBookedUpdateVisibility implements Action {
  readonly type = LoadBoardBookedActionTypes.Update_Visibility;

  constructor(public payload: UpdateVisibilityRequest) {}
}

export class LoadBoardBookedUpdateVisibilitySuccessAction implements Action {
  readonly type = LoadBoardBookedActionTypes.Update_Visibility_Success;

  constructor(public payload: LoadClaim) {}
}

export class LoadBoardBookedUpdateVisibilityFailureAction implements Action {
  readonly type = LoadBoardBookedActionTypes.Update_Visibility_Failure;

  constructor(public payload: Error) {}
}

export class LoadBoardBookedUpdateQueryAction implements Action {
  readonly type = LoadBoardBookedActionTypes.Update_Query;

  constructor(public payload: PageableQueryHelper) {}
}

export type LoadBoardBookedActions =
  | LoadBoardBookedLoadAction
  | LoadBoardBookedLoadSuccessAction
  | LoadBoardBookedLoadFailureAction
  | LoadBoardBookedUpdateVisibilitySuccessAction
  | LoadBoardBookedUpdateVisibilityFailureAction
  | LoadBoardBookedUpdateQueryAction
  | LoadBoardBookedUpdateVisibility;
