import { CarrierCarrierScacGroup, CarrierProfile, UserContactData } from '../../../shared/models';
import { createReducer } from '../../../shared/utilities';
import { CarrierActions, CarrierActionTypes } from '../actions';

export interface CarrierState {
  loading: boolean;
  entities: CarrierProfile[];
  allCarriers: CarrierCarrierScacGroup[];
  allCarriersLoading: boolean;
  companyUsers: UserContactData[];
}

const initialState: CarrierState = {
  loading: false,
  entities: null,
  allCarriers: null,
  allCarriersLoading: false,
  companyUsers: null,
};

const _carrierReducer = createReducer(initialState, function (state: CarrierState = initialState, action: CarrierActions): CarrierState {
  switch (action.type) {
    case CarrierActionTypes.Load: {
      return { ...state, loading: true };
    }
    case CarrierActionTypes.Load_Success: {
      return { ...state, entities: action.payload, loading: false };
    }
    case CarrierActionTypes.Load_Failure: {
      return { ...state, loading: false };
    }
    case CarrierActionTypes.CarrierCarrierScacLoad: {
      return { ...state, allCarriersLoading: true };
    }
    case CarrierActionTypes.CarrierCarrierScacLoad_Success: {
      return { ...state, allCarriers: action.payload, allCarriersLoading: false };
    }
    case CarrierActionTypes.CarrierCarrierScacLoad_Failure: {
      return { ...state, allCarriersLoading: false };
    }
    case CarrierActionTypes.GetUsersInCompany_Success: {
      return { ...state, companyUsers: action.payload };
    }
    default:
      return state;
  }
});

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function carrierReducer(state: CarrierState, action: CarrierActions): CarrierState {
  return _carrierReducer(state, action);
}

export const getLoading = (state: CarrierState) => state.loading;
export const getEntities = (state: CarrierState) => state.entities;

export const getAllCarriersLoading = (state: CarrierState) => state.allCarriersLoading;
export const getAllCarriers = (state: CarrierState) => state.allCarriers;
