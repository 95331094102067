<div id="filter-header" class="d-lg-none" (click)="collapsed = !collapsed">
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center">
      <button
        *ngIf="!collapsed"
        pButton
        type="button"
        (click)="clearClick($event)"
        class="p-button-outlined p-button-secondary w-100"
        label="Clear"
      ></button>
      <div class="flex-grow-1 pl-2 pr-2 text-center">
        <fa-icon *ngIf="!collapsed" [icon]="['fas', 'caret-up']" size="2x"></fa-icon>
        <fa-icon *ngIf="collapsed" [icon]="['fas', 'caret-down']" size="2x"></fa-icon>
        <span class="kbxl-field-label">Filter Loads</span>
      </div>
      <button *ngIf="!collapsed" pButton type="button" (click)="applyClick($event)" class="w-100" label="Search"></button>
    </div>
  </div>
</div>
<div class="mobile-overlay">
  <div class="page-container pt-3 p-lg-0 d-lg-block background" [class.d-none]="collapsed">
    <div class="d-flex justify-content-end">
      <div class="kbxl-inputgroup-container mb-0">
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="filter-box"
            placeholder="Quick Search Criteria"
            [(ngModel)]="quickSearch"
            (keyup.enter)="runSearch()"
          />
          <button
            pButton
            type="button"
            icon="pi pi-times-circle"
            (click)="clearFilter('quickSearch')"
            class="clearQuickFilter p-inputgroup-addon"
          ></button>
          <button pButton type="button" icon="pi pi-filter" class="p-button-secondary p-inputgroup-addon" (click)="runSearch()"></button>
        </div>
      </div>
      <button class="icon-button" (click)="exportData()" *ngIf="showExport" kbxlActionCheck action="loadshop.ui.myloads.exportbooked">
        <fa-icon class="file-csv-icon" [icon]="['fas', 'file-csv']" pTooltip="Export Data" size="2x"></fa-icon>
      </button>
    </div>
    <p-panel [toggleable]="true" [collapsed]="!isMobile" styleClass="advanced-search-panel">
      <p-header class="p-0 marketplace-panel-header">
        <span>Advanced Search</span>
      </p-header>
      <div class="row pt-lg-2">
        <div class="col-12 col-lg-4">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Origin</label>
              </div>
              <kbxl-address-autocomplete
                [(place)]="origin"
                [includeAddress]="false"
                [placeholder]="'Search by City/State/Province'"
              ></kbxl-address-autocomplete>
            </div>
            <div class="col-12 col-lg-6 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label id="origin-deadhead-miles-label" class="control-label">Max Origin Radius Miles</label>
              </div>
              <div class="p-inputgroup">
                <p-slider [(ngModel)]="crit.origDH" [min]="10" [max]="200" [step]="10"></p-slider>
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="crit.origDH"
                  class="p-inputgroup-addon sliderInput"
                  min="10"
                  max="200"
                  kbxlInputBound
                />
              </div>
            </div>
            <div class="col-12 col-lg-6 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Pickup Date Range</label>
              </div>
              <div class="p-inputgroup">
                <p-calendar
                  #pickupDate
                  [showIcon]="true"
                  selectionMode="range"
                  showButtonBar="true"
                  [placeholder]="'Select a Date'"
                  [readonlyInput]="true"
                  [selectOtherMonths]="true"
                  [(ngModel)]="origDate"
                  todayButtonStyleClass="d-none"
                  panelStyleClass="range-calendar"
                >
                  <p-footer>
                    <button
                      style="width: 75px"
                      pButton
                      type="button"
                      (click)="pickupDate.toggle()"
                      class="p-button-outlined p-button-secondary"
                      label="OK"
                    ></button>
                  </p-footer>
                </p-calendar>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Destination</label>
              </div>
              <kbxl-address-autocomplete
                [(place)]="dest"
                [includeAddress]="false"
                [placeholder]="'Search by City/State/Province'"
              ></kbxl-address-autocomplete>
            </div>
            <div class="col-12 col-lg-6 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label id="destination-deadhead-miles-label" class="control-label">Max Destination Radius Miles</label>
              </div>
              <div class="p-inputgroup">
                <p-slider [(ngModel)]="crit.destDH" [min]="10" [max]="200" [step]="10"></p-slider>
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="crit.destDH"
                  class="p-inputgroup-addon sliderInput"
                  kbxlInputBound
                  min="10"
                  max="200"
                />
              </div>
            </div>
            <div class="col-12 col-lg-6 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Delivery Date Range</label>
              </div>
              <div class="p-inputgroup">
                <p-calendar
                  #deliveryDate
                  [showIcon]="true"
                  selectionMode="range"
                  showButtonBar="true"
                  [placeholder]="'Select a Date'"
                  [readonlyInput]="true"
                  [selectOtherMonths]="true"
                  [(ngModel)]="destDate"
                  todayButtonStyleClass="d-none"
                  panelStyleClass="range-calendar"
                >
                  <p-footer>
                    <button
                      style="width: 75px"
                      pButton
                      type="button"
                      (click)="deliveryDate.toggle()"
                      class="p-button-outlined p-button-secondary"
                      label="OK"
                    ></button>
                  </p-footer>
                </p-calendar>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Equipment Type</label>
              </div>
              <div class="p-inputgroup">
                <kbxl-tree-drop-down
                  [treeNodes]="equipment"
                  [(selectedNodes)]="selectedEquipment"
                  placeHolder="Select Equipment Type(s)"
                ></kbxl-tree-drop-down>
              </div>
            </div>
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Required Services</label>
              </div>
              <p-multiSelect
                [options]="serviceTypes"
                placeholder="Select All Services"
                [(ngModel)]="selectedServiceTypes"
                name="services"
                dataKey="serviceTypeId"
                optionLabel="name"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                appendTo="body"
                maxSelectedLabels="10"
                kbxlCloseOnScroll
                hideTransitionOptions="0ms"
              >
              </p-multiSelect>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center justify-content-md-end">
        <div class="d-flex flex-column flex-md-row align-items-end align-items-md-center">
          <button pButton type="button" (click)="clearClick($event)" class="p-button-outlined p-button-secondary" label="Clear"></button>
          <button pButton type="button" (click)="applyClick($event)" class="ml-2 my-2 my-md-0" [label]="'Search'"></button>
        </div>
      </div>
    </p-panel>
  </div>
</div>
