export interface FuelProgramTypeOptions {
  id: number;
  description: string;
}

export function isFuelProgramTypeArray(x: any): x is FuelProgramTypeOptions[] {
  return x.every((obj) => {
    return typeof obj.id === 'number' && typeof obj.description === 'string';
  });
}

export enum FuelProgramTypeEnum {
  None,
  BTF,
  DOE,
  Both,
}

export function getFuelProgramTypeOptions(): FuelProgramTypeOptions[] {
  const options = new Array<FuelProgramTypeOptions>();
  options.push({ id: FuelProgramTypeEnum.None, description: 'None' });
  options.push({ id: FuelProgramTypeEnum.BTF, description: 'BTF' });
  // options.push({ id: FuelProgramTypeEnum.DOE, description: 'DOE' });
  options.push({ id: FuelProgramTypeEnum.Both, description: 'Both' });
  return options;
}
