import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GoogleMapService } from 'src/app/core/services';
import { GoogleAddressPlaceFilterTypes } from '../../../core/models';
import { Place, PlaceTypeEnum, setPlaceDescription } from '../../models';

@Component({
  selector: 'kbxl-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => AddressAutocompleteComponent),
      multi: true,
    },
  ],
})
export class AddressAutocompleteComponent implements ControlValueAccessor {
  @Input() place: Place;
  @Input() includeAddress = true;
  @Input() takeFirstZip = false;
  @Input() placeholder = 'Search by Address/City/PostalCode/State/Province';

  @Output() placeChange: EventEmitter<Place> = new EventEmitter<Place>();
  @Output() select: EventEmitter<Place> = new EventEmitter<Place>();

  public placeSuggestions: Place[] = [];
  private onChange = (value: Place) => {};

  constructor(private googleService: GoogleMapService, private cdr: ChangeDetectorRef) {}

  /*
   ** ControlValueAccessor
   */
  writeValue(value: Place): void {
    this.place = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}

  // get accessor
  get value(): Place {
    return this.place;
  }

  // set accessor including call the onchange callback
  set value(v: Place) {
    if (v !== this.place) {
      this.updateValue(v);
    }
  }

  private updateValue(v: Place) {
    this.place = v;
    this.onChange(v);
    this.select.emit(v);
    this.placeChange.emit(v);
  }

  public citySearch(event: any) {
    const autoCompleteCall = this.includeAddress
      ? this.googleService.addressAutoComplete(event.query)
      : this.googleService.autoComplete(event.query);

    autoCompleteCall.then((x) => {
      this.placeSuggestions = x;
      // detect the new suggestions to prevent a double dropdown list
      this.cdr.detectChanges();
    });
  }

  public clearValue() {
    this.value = null;
    this.placeSuggestions = [];
  }

  public populateValue(place: Place) {
    this.populatePlace(place).then((x) => {
      this.updateValue(x);
    });
  }

  private async populatePlace(place: Place): Promise<Place> {
    let geocodeCall = this.googleService.geocodePlace(place.description);

    if (place.placeId) {
      geocodeCall = this.googleService.geocodePlaceById(place.placeId);
    }

    const x = await geocodeCall;
    if (x != null) {
      if (x.placeType === PlaceTypeEnum.Establistment) {
        x.placeType = PlaceTypeEnum.Address;
      }
      if (x.placeType === PlaceTypeEnum.Route) {
        if (x.address) {
          x.placeType = PlaceTypeEnum.Address;
        } else {
          x.address = null;
          x.placeType = PlaceTypeEnum.City;
        }
      }

      if (this.takeFirstZip && !x.postalCode && x.placeType !== PlaceTypeEnum.State && x.placeType !== PlaceTypeEnum.Country) {
        const zipResult = await this.googleService.reverseGeocode(x.latitude, x.longitude, {
          placeType: GoogleAddressPlaceFilterTypes.PostalCode,
        });
        x.postalCode = zipResult.postalCode;
      }

      setPlaceDescription(x);
    }
    return x;
  }
}
