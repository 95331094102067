import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { mapToPayload } from '@tms-ng/shared';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CustomerLaneGroupDetailData } from '../../../shared/models';
import { LaneManagementService } from '../../services';
import {
  LaneManagementActionTypes,
  LaneManagementAddAction,
  LaneManagementAddFailureAction,
  LaneManagementAddSuccessAction,
  //   LaneManagementCarrierLoadAction,
  //   LaneManagementCopyCarriersFailureAction,
  //   LaneManagementCopyCarriersSuccessAction,
  LaneManagementDeleteAction,
  LaneManagementDeleteFailureAction,
  LaneManagementDeleteSuccessAction,
  LaneManagementLoadAction,
  LaneManagementLoadCarrierGroupTypesAction,
  LaneManagementLoadCarrierGroupTypesFailure,
  LaneManagementLoadCarrierGroupTypesSuccessAction,
  LaneManagementLoadFailureAction,
  LaneManagementLoadGroupAction,
  LaneManagementLoadGroupFailureAction,
  LaneManagementLoadGroupSuccessAction,
  LaneManagementLoadSuccessAction,
  LaneManagementUpdateAction,
  LaneManagementUpdateFailureAction,
  LaneManagementUpdateSuccessAction,
} from '../actions';

@Injectable()
export class LaneManagementEffects {
  @Effect()
  $load: Observable<Action> = this.actions$.pipe(
    ofType<LaneManagementLoadAction>(LaneManagementActionTypes.Load),
    mapToPayload<{ customerId: string }>(),
    switchMap((payload: { customerId: string }) =>
      this.laneManagementService.getGroups(payload.customerId).pipe(
        map((data) => new LaneManagementLoadSuccessAction(data)),
        catchError((err) => of(new LaneManagementLoadFailureAction(err)))
      )
    )
  );

  @Effect()
  $loadGroup: Observable<Action> = this.actions$.pipe(
    ofType<LaneManagementLoadGroupAction>(LaneManagementActionTypes.Load_Lane),
    mapToPayload<{ LaneManagementId: number }>(),
    switchMap((payload: { LaneManagementId: number }) =>
      this.laneManagementService.getGroup(payload.LaneManagementId).pipe(
        map((data) => new LaneManagementLoadGroupSuccessAction(data)),
        catchError((err) => of(new LaneManagementLoadGroupFailureAction(err)))
      )
    )
  );

  @Effect()
  $LaneManagementTypes: Observable<Action> = this.actions$.pipe(
    ofType<LaneManagementLoadCarrierGroupTypesAction>(LaneManagementActionTypes.Load_Carrier_Group_Types),
    switchMap(() =>
      this.laneManagementService.getLoadCarrierGroupTypes().pipe(
        map((data) => new LaneManagementLoadCarrierGroupTypesSuccessAction(data)),
        catchError((err) => of(new LaneManagementLoadCarrierGroupTypesFailure(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  $groupAdd: Observable<Action> = this.actions$.pipe(
    ofType<LaneManagementAddSuccessAction>(LaneManagementActionTypes.Add_Success),
    tap((_) => this.messageService.add({ id: 0, detail: 'Lane Group Added', severity: 'success' }))
  );

  @Effect({ dispatch: false })
  $groupUpdate: Observable<Action> = this.actions$.pipe(
    ofType<LaneManagementUpdateSuccessAction>(LaneManagementActionTypes.Update_Success),
    tap((_) => this.messageService.add({ id: 0, detail: 'Lane Group Updated', severity: 'success' }))
  );

  @Effect({ dispatch: false })
  $groupDelete: Observable<Action> = this.actions$.pipe(
    ofType<LaneManagementDeleteSuccessAction>(LaneManagementActionTypes.Delete_Success),
    tap((_) => this.messageService.add({ id: 0, detail: 'Lane Group Deleted', severity: 'success' }))
  );

  @Effect()
  $update: Observable<Action> = this.actions$.pipe(
    ofType<LaneManagementUpdateAction>(LaneManagementActionTypes.Update),
    mapToPayload<CustomerLaneGroupDetailData>(),
    switchMap((group: CustomerLaneGroupDetailData) =>
      this.laneManagementService.updateGroup(group).pipe(
        map((data) => new LaneManagementUpdateSuccessAction(data)),
        catchError((err) => {
          if (err && err.error && err.error.title && err.error.detail) {
            this.messageService.add({ summary: err.error.title, detail: err.error.detail, severity: 'error' });
          }
          return of(new LaneManagementUpdateFailureAction(err));
        })
      )
    )
  );

  @Effect()
  $add: Observable<Action> = this.actions$.pipe(
    ofType<LaneManagementAddAction>(LaneManagementActionTypes.Add),
    mapToPayload<CustomerLaneGroupDetailData>(),
    switchMap((group: CustomerLaneGroupDetailData) =>
      this.laneManagementService.addGroup(group).pipe(
        map((data) => new LaneManagementAddSuccessAction(data)),
        catchError((err) => {
          if (err && err.error && err.error.title && err.error.detail) {
            this.messageService.add({ summary: err.error.title, detail: err.error.detail, severity: 'error' });
          }
          return of(new LaneManagementAddFailureAction(err));
        })
      )
    )
  );

  @Effect()
  $delete: Observable<Action> = this.actions$.pipe(
    ofType<LaneManagementDeleteAction>(LaneManagementActionTypes.Delete),
    mapToPayload<CustomerLaneGroupDetailData>(),
    switchMap((group: CustomerLaneGroupDetailData) =>
      this.laneManagementService.deleteGroup(group).pipe(
        map((data) => new LaneManagementDeleteSuccessAction(group)),
        catchError((err) => of(new LaneManagementDeleteFailureAction(err)))
      )
    )
  );

  //   @Effect()
  //   $copyCarriers: Observable<Action> = this.actions$.pipe(
  //     ofType<LaneManagementCarrierLoadAction>(LaneManagementActionTypes.Copy_Carriers_Load),
  //     mapToPayload<{ LaneManagementId: number }>(),
  //     switchMap((payload: { LaneManagementId: number }) => {
  //       return this.laneManagementService.getGroup(payload.LaneManagementId).pipe(
  //         map((group: LaneManagement) => {
  //           const carriers = group.carriers.map((x) => ({
  //             LaneManagementCarrierId: 0,
  //             LaneManagementId: 0,
  //             carrierId: x.carrierId,
  //           }));

  //           return new LaneManagementCopyCarriersSuccessAction(carriers);
  //         }),
  //         catchError((err) => of(new LaneManagementCopyCarriersFailureAction(err)))
  //       );
  //     })
  //   );

  constructor(private actions$: Actions, private laneManagementService: LaneManagementService, private messageService: MessageService) {}
}
