import { createSelector } from '@ngrx/store';
import { CoreState } from '../reducers';
import { CustomerFavoriteState, selectAll } from '../reducers/customer-favorite.reducer';
import { AllCustomerFavoriteTypes } from 'src/app/shared/models';

export const getCustomerFavoriteState = (state: CoreState) => state.customerFavorite;
export const getCustomerFavorites = createSelector(getCustomerFavoriteState, selectAll);
export const getCustomerFavoritesByType = createSelector(
  getCustomerFavoriteState,
  (state: CustomerFavoriteState, type: AllCustomerFavoriteTypes) => selectAll(state).filter((x) => x.type === type)
);
export const getLoadingCustomerFavorites = createSelector(getCustomerFavoriteState, (x: CustomerFavoriteState) => x.loading);
