import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, timer, EMPTY } from 'rxjs';
import { delayWhen, retryWhen, switchMap } from 'rxjs/operators';
import { GoogleMapService } from '../../services/google-map.service';
import { LoadshopApplicationActionTypes } from 'src/app/shared/store';

declare let google: any;

@Injectable()
export class GoogleMapEffects {
  @Effect({ dispatch: false })
  $startup: Observable<Action> = this.actions$.pipe(
    ofType(LoadshopApplicationActionTypes.LoadshopStart),
    switchMap(() => {
      if (google.maps) {
        this.googleMapService.initialize();
      } else {
        throw new Error('google.maps not loaded yet');
      }

      return EMPTY;
    }),
    retryWhen((errors) => errors.pipe(delayWhen(() => timer(10))))
  );

  constructor(private googleMapService: GoogleMapService, private actions$: Actions) {}
}
