import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { OrderEntryForm, OrderEntryFormDisplay } from '../../../shared/models/order-entry-form';

export enum OrderEntryActionTypes {
  Get_Load = '[OrderEntry] GET_LOAD',
  Get_Load_Success = '[OrderEntry] GET_LOAD_SUCCESS',
  Get_Load_Failure = '[OrderEntry] GET_LOAD_FAILURE',
  Create_Load = '[OrderEntry] CREATE_LOAD',
  Create_Load_Success = '[OrderEntry] CREATE_LOAD_SUCCESS',
  Create_Load_Failure = '[OrderEntry] CREATE_LOAD_FAILURE',
  Update_Load = '[OrderEntry] UPDATE_LOAD',
  Update_Load_Success = '[OrderEntry] UPDATE_LOAD_SUCCESS',
  Update_Load_Failure = '[OrderEntry] UPDATE_LOAD_FAILURE',
  Clear_Errors = '[OrderEntry] CLEAR_ERRORS',
  Reset_Saved = '[OrderEntry] RESET_SAVED',
  Copy_Load = '[OrderEntry] COPY_LOAD',
  Copy_Load_Success = '[OrderEntry] COPY_LOAD_SUCCESS',
  Copy_Load_Failure = '[OrderEntry] COPY_LOAD_FAILURE',
}

export class OrderEntryGetLoadAction implements Action {
  readonly type = OrderEntryActionTypes.Get_Load;
  constructor(public payload: { loadId: string; smartSpotQuoteId: string }) {}
}

export class OrderEntryGetLoadSuccessAction implements Action {
  readonly type = OrderEntryActionTypes.Get_Load_Success;
  constructor(public payload: OrderEntryFormDisplay) {}
}

export class OrderEntryGetLoadFailureAction implements Action {
  readonly type = OrderEntryActionTypes.Get_Load_Failure;
  constructor(public payload: HttpErrorResponse) {}
}

export class OrderEntryCreateLoadAction implements Action {
  readonly type = OrderEntryActionTypes.Create_Load;
  constructor(public payload: OrderEntryForm) {}
}

export class OrderEntryCreateLoadSuccessAction implements Action {
  readonly type = OrderEntryActionTypes.Create_Load_Success;

  constructor(public payload: OrderEntryFormDisplay) {}
}

export class OrderEntryCreateLoadFailureAction implements Action {
  readonly type = OrderEntryActionTypes.Create_Load_Failure;
  constructor(public payload: HttpErrorResponse) {}
}

export class OrderEntryUpdateLoadAction implements Action {
  readonly type = OrderEntryActionTypes.Update_Load;
  constructor(public payload: OrderEntryForm, public resetState: boolean) {}
}

export class OrderEntryUpdateLoadSuccessAction implements Action {
  readonly type = OrderEntryActionTypes.Update_Load_Success;

  constructor(public payload: OrderEntryFormDisplay, public resetState: boolean) {}
}

export class OrderEntryUpdateLoadFailureAction implements Action {
  readonly type = OrderEntryActionTypes.Update_Load_Failure;
  constructor(public payload: HttpErrorResponse) {}
}

export class OrderEntryClearErrorsAction implements Action {
  readonly type = OrderEntryActionTypes.Clear_Errors;
  constructor() {}
}

export class OrderEntryResetSavedAction implements Action {
  readonly type = OrderEntryActionTypes.Reset_Saved;
  constructor() {}
}

export class OrderEntryCopyLoadAction implements Action {
  readonly type = OrderEntryActionTypes.Copy_Load;
  constructor(public payload: { loadId: string; smartSpotQuoteId: string }) {}
}

export class OrderEntryCopyLoadSuccessAction implements Action {
  readonly type = OrderEntryActionTypes.Copy_Load_Success;
  constructor(public payload: OrderEntryFormDisplay) {}
}

export class OrderEntryCopyLoadFailureAction implements Action {
  readonly type = OrderEntryActionTypes.Copy_Load_Failure;
  constructor(public payload: HttpErrorResponse) {}
}

export type OrderEntryActions =
  | OrderEntryGetLoadAction
  | OrderEntryGetLoadSuccessAction
  | OrderEntryGetLoadFailureAction
  | OrderEntryCreateLoadAction
  | OrderEntryCreateLoadSuccessAction
  | OrderEntryCreateLoadFailureAction
  | OrderEntryUpdateLoadAction
  | OrderEntryUpdateLoadSuccessAction
  | OrderEntryUpdateLoadFailureAction
  | OrderEntryClearErrorsAction
  | OrderEntryResetSavedAction
  | OrderEntryCopyLoadAction
  | OrderEntryCopyLoadSuccessAction
  | OrderEntryCopyLoadFailureAction;
