import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { mapToPayload } from '@tms-ng/shared';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CommonService } from '../../services/common.service';
import {
  CustomerActionTypes,
  CustomerGetUsersInCompanyAction,
  CustomerGetUsersInCompanyFailureAction,
  CustomerGetUsersInCompanySuccessAction,
  CustomerLoadFailureAction,
  CustomerLoadSuccessAction,
} from '../actions';

@Injectable()
export class CustomerEffects {
  @Effect()
  $load: Observable<Action> = this.actions$.pipe(
    ofType(CustomerActionTypes.Load),
    mapToPayload<{ customerId: string }>(),
    switchMap((payload: { customerId: string }) =>
      this.commonService.getCustomer(payload.customerId).pipe(
        map((data) => new CustomerLoadSuccessAction(data)),
        catchError((err) => of(new CustomerLoadFailureAction(err)))
      )
    )
  );
  @Effect()
  $loadCompanyUsers: Observable<Action> = this.actions$.pipe(
    ofType<CustomerGetUsersInCompanyAction>(CustomerActionTypes.GetUsersInCompany),
    switchMap((action) => {
      if (!action.payload || action.payload.length === 0) {
        return EMPTY;
      }
      return this.commonService.getShipperCompanyUsers(action.payload).pipe(
        map((data) => new CustomerGetUsersInCompanySuccessAction(data)),
        catchError((err) => of(new CustomerGetUsersInCompanyFailureAction(err)))
      );
    })
  );
  constructor(private commonService: CommonService, private actions$: Actions) {}
}
