import { createSelector } from '@ngrx/store';
import { CoreState } from '../reducers';
import { getAllCarriers, getAllCarriersLoading, getEntities, getLoading } from '../reducers/carrier.reducer';

export const getCarrierState = (state: CoreState) => state.carriers;

export const getBookingEligibleCarriers = createSelector(getCarrierState, getEntities);
export const getLoadingBookingEligibleCarriers = createSelector(getCarrierState, getLoading);

export const getAllCarrierGroups = createSelector(getCarrierState, getAllCarriers);
export const getLoadingAllCarrierGroups = createSelector(getCarrierState, getAllCarriersLoading);
export const getCarrierCompanyUsers = createSelector(getCarrierState, (state) => state.companyUsers);
