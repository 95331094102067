import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import {
  ResponseError,
  SmartSpotPrice,
  SmartSpotPriceRequest,
  SmartSpotQuoteCreateRequest,
  SmartSpotQuoteRequest,
  SpotPriceQuoteRequest,
  SpotPriceQuoteResponse,
} from '../../../shared/models';

export enum SmartSpotPriceActionTypes {
  Load = '[SmartSpotPrice] LOAD',
  LoadSuccess = '[SmartSpotPrice] LOAD_SUCCESS',
  LoadFailure = '[SmartSpotPrice] LOAD_FAILURE',
  LoadQuote = '[SmartSpotPrice] LOAD_QUOTE',
  LoadQuoteSuccess = '[SmartSpotPrice] LOAD_QUOTE_SUCCESS',
  LoadQuoteFailure = '[SmartSpotPrice] LOAD_QUOTE_FAILURE',
  CreateOrderFromQuote = '[SmartSpotPrice] CREATE_ORDER_FROM_QUOTE',
  ClearCreateOrderFromQuote = '[SmartSpotPrice] CLEAR_CREATE_ORDER_FROM_QUOTE',
  LoadSpotQuote = '[SmartSpotPrice] LOAD_SPOT_QUOTE',
  LoadSpotQuoteSuccess = '[SmartSpotPrice] LOAD_SPOT_QUOTE_SUCCESS',
  LoadSpotQuoteFailure = '[SmartSpotPrice] LOAD_SPOT_QUOTE_FAILURE',

  Reset = '[SmartSpotPrice] RESET',
}

export class SmartSpotPriceLoadAction implements Action {
  readonly type = SmartSpotPriceActionTypes.Load;

  constructor(public payload: SmartSpotPriceRequest[]) {}
}

export class SmartSpotPriceResetAction implements Action {
  readonly type = SmartSpotPriceActionTypes.Reset;
}

export class SmartSpotPriceLoadSuccessAction implements Action {
  readonly type = SmartSpotPriceActionTypes.LoadSuccess;

  constructor(public payload: SmartSpotPrice[]) {}
}

export class SmartSpotPriceLoadFailureAction implements Action {
  readonly type = SmartSpotPriceActionTypes.LoadFailure;

  constructor(public payload: ResponseError) {}
}

export class SmartSpotPriceLoadQuoteAction implements Action {
  readonly type = SmartSpotPriceActionTypes.LoadQuote;

  constructor(public payload: SmartSpotQuoteRequest) {}
}

export class SmartSpotPriceLoadQuoteSuccessAction implements Action {
  readonly type = SmartSpotPriceActionTypes.LoadQuoteSuccess;

  constructor(public payload: number) {}
}

export class SmartSpotPriceLoadQuoteFailureAction implements Action {
  readonly type = SmartSpotPriceActionTypes.LoadQuoteFailure;

  constructor(public payload: HttpErrorResponse) {}
}

export class SmartSpotCreateOrderFromQuoteAction implements Action {
  readonly type = SmartSpotPriceActionTypes.CreateOrderFromQuote;

  constructor(public payload: SmartSpotQuoteCreateRequest) {}
}

export class SmartSpotClearCreateOrderFromQuote implements Action {
  readonly type = SmartSpotPriceActionTypes.ClearCreateOrderFromQuote;
}

export class SmartSpotPriceLoadSpotQuoteAction implements Action {
  readonly type = SmartSpotPriceActionTypes.LoadSpotQuote;

  constructor(public payload: SpotPriceQuoteRequest) {}
}

export class SmartSpotPriceLoadSpotQuoteSuccessAction implements Action {
  readonly type = SmartSpotPriceActionTypes.LoadSpotQuoteSuccess;

  constructor(public payload: SpotPriceQuoteResponse, public loadId: string) {}
}
export class SmartSpotPriceLoadSpotQuoteFailureAction implements Action {
  readonly type = SmartSpotPriceActionTypes.LoadSpotQuoteFailure;

  constructor(public payload: ResponseError) {}
}

export type SmartSpotPriceActions =
  | SmartSpotPriceLoadAction
  | SmartSpotPriceLoadSuccessAction
  | SmartSpotPriceLoadFailureAction
  | SmartSpotPriceLoadQuoteAction
  | SmartSpotPriceLoadQuoteSuccessAction
  | SmartSpotPriceLoadQuoteFailureAction
  | SmartSpotCreateOrderFromQuoteAction
  | SmartSpotClearCreateOrderFromQuote
  | SmartSpotPriceResetAction
  | SmartSpotPriceLoadSpotQuoteAction
  | SmartSpotPriceLoadSpotQuoteSuccessAction
  | SmartSpotPriceLoadSpotQuoteFailureAction;
