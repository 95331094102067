<div class="d-flex flex-column">
  <div class="progress-indicator" *ngIf="loading$ | async">
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <kbxl-shipping-load-home-header
    [loadingAnySmartSpotPrices]="loadingAnySmartSpotPrices$ | async"
    [allowManualLoadCreation]="user?.features?.allowManualLoadCreation"
    [loadingLatestTransaction]="loadingLatestTransaction$ | async"
    [allowPostLoads]="allowPostLoads | async"
    [selectedLoadCount]="selectedLoads?.length"
    (postLoads)="postLoads()"
    (createLoad)="createLoad()"
  >
  </kbxl-shipping-load-home-header>

  <kbxl-color-bar></kbxl-color-bar>

  <div class="d-flex justify-content-center home-container p-1 p-xl-4">
    <div class="card-container">
      <div class="mb-2">
        <div class="d-flex justify-content-between">
          <div class="kbxl-field-label">
            <label id="filter-loads-string" class="control-label">Quick Filter</label>
          </div>
          <div class="kbxl-field-label" *ngIf="filterCriteria.favoriteName">
            <label id="filter-loads-string" class="control-label">Filter: {{ filterCriteria.favoriteName }}</label>
          </div>
        </div>
        <div class="p-inputgroup">
          <input
            pInputText
            type="text"
            [(ngModel)]="filterCriteria.quickFilter"
            class="filter-box w-100"
            placeholder="Search Criteria"
            (keyup)="onFilterChange(filterCriteria)"
          />
          <button
            pButton
            type="button"
            icon="pi pi-times-circle"
            class="clearQuickFilter p-inputgroup-addon"
            (click)="clearFilter('quickFilter')"
          ></button>
          <button
            pButton
            type="button"
            icon="pi pi-filter"
            class="p-button-secondary p-inputgroup-addon"
            (click)="viewFilterCriteria()"
          ></button>
        </div>
      </div>
      <div class="card-scroll">
        <kbxl-shipping-load-cards
          [loads]="filteredLoads$ | async"
          [isBrokeredShipper]="isBrokeredShipper"
          (loadSelected)="loadSelected($event)"
          (loadUnselected)="loadUnselected($event)"
          (removeLoad)="removeLoad($event)"
          (deleteLoad)="deleteLoad($event)"
        ></kbxl-shipping-load-cards>
      </div>
    </div>
    <div *ngIf="selectedLoads && selectedLoads.length > 0; else elseNoLoadsSelected" class="detail-container flex-grow-1 ml-2 ml-xl-4">
      <div *ngFor="let detail of selectedLoads; trackBy: trackByFn">
        <kbxl-shipping-load-detail
          #sldcmp
          [details]="detail"
          [errors]="postValidationProblemDetails$ | async"
          [carriers]="carriers$ | async"
          [commodities]="commodities$ | async"
          [serviceTypes]="serviceTypes$ | async"
          [user]="user"
          [manualLoadServiceTypes]="manualLoadServiceTypes$ | async"
          (close)="loadUnselected($event)"
        >
        </kbxl-shipping-load-detail>
      </div>
    </div>
    <ng-template #elseNoLoadsSelected>
      <div class="d-flex justify-content-center align-items-center ml-2 ml-xl-4 w-100 watermark-container">
        <img src="../assets/Loadshop-poweredbykbx-logo-white-RGB.svg" alt="logo" class="img-fluid watermark-logo w-50" />
      </div>
    </ng-template>
  </div>
</div>

<p-dialog
  header="Filter Criteria"
  [(visible)]="displayFilterCriteriaDialog"
  [modal]="true"
  [closeOnEscape]="true"
  (onHide)="onFilterChange(filterCriteria)"
  [focusOnShow]="false"
  class="postQuickFilter"
>
  <kbxl-shipping-load-filter
    [(filterCriteria)]="filterCriteria"
    [equipment]="equipment$ | async | toTreeNodes"
    [(visible)]="displayFilterCriteriaDialog"
  >
  </kbxl-shipping-load-filter>
</p-dialog>
