<fa-icon
  *ngIf="hoverWarning; else withoutHover"
  [icon]="['fas', 'exclamation-triangle']"
  class="icon-style pointer"
  pTooltip="{{ autoApproveWarningMessage }}"
  size="2x"
></fa-icon>

<ng-template #withoutHover>
  <div class="alert alert-danger d-flex align-items-center" role="alert">
    <fa-icon [icon]="['fas', 'exclamation-triangle']" class="icon-style mr-2" size="2x"></fa-icon>
    {{ autoApproveWarningMessage }}
  </div>
</ng-template>
