import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/operators';
import { accessorialRequestModalZIndex } from '../../../constants';
import { CustomerData, LoadDetail, TransactionType, User } from '../../../models';
import { AccessorialRequestUnlockLoadAction, LoadBoardLoadDetailLoadAction, SharedState } from '../../../store';
import { BaseComponent } from '../../base-component';
import { AccessorialRequestsModalComponent } from '../accessorial-requests-modal';

@Component({
  selector: 'kbxl-load-accessorial-requests',
  templateUrl: './load-accessorial-requests.component.html',
  styleUrls: ['./load-accessorial-requests.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class LoadAccessorialRequestsComponent extends BaseComponent implements OnChanges {
  @Input() load: LoadDetail;
  @Input() user: User;
  @Input() isReadOnly: boolean;
  @Input() isShippingDetail: boolean;
  showAccessorialDialog = false;
  loadAccessorialRequestsLocked = false;
  primaryCustomer: CustomerData;
  constructor(private dialogService: DialogService, private sharedStore: Store<SharedState>) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.load) {
      if (this.load.loadAccessorialRequests && this.load.loadAccessorialRequests.canAccessorialsBeLocked) {
        this.loadAccessorialRequestsLocked = this.load.loadAccessorialRequests.accessorialRequestsLocked;
      }
    }
    if (changes && changes.user) {
      this.primaryCustomer = this.user.authorizedShippers.find((x) => x.customerId === this.user.primaryCustomerId) as CustomerData;
    }
  }

  shouldDisplayAccessorial(): boolean {
    // check is user is a carrier and view load that is booked or delivered
    if (
      this.load &&
      !this.isReadOnly &&
      !this.isShippingDetail &&
      // https://kbxltrans.visualstudio.com/Suite/_workitems/edit/56532
      // Do not allow view-only users to request accessorials
      !this.load.viewOnly &&
      this.load.loadAccessorials &&
      this.load.loadAccessorials.length > 0 &&
      (this.load.loadTransaction.transactionType === TransactionType.PreTender ||
        this.load.loadTransaction.transactionType === TransactionType.Pending ||
        this.load.loadTransaction.transactionType === TransactionType.SentToShipperTender ||
        this.load.loadTransaction.transactionType === TransactionType.Accepted ||
        this.load.loadTransaction.transactionType === TransactionType.Delivered)
    ) {
      if (
        this.user.isCarrier &&
        this.load.loadAccessorialRequests.accessorialRequestsLocked &&
        !this.load.loadAccessorialRequests.hasCarrierRequestedAccessorials
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  shouldLockedOutMessage(): boolean {
    // check is user is a carrier and view load that is booked or delivered
    if (
      this.load &&
      !this.isReadOnly &&
      !this.isShippingDetail &&
      this.load.loadAccessorials &&
      this.load.loadAccessorials.length > 0 &&
      (this.load.loadTransaction.transactionType === TransactionType.PreTender ||
        this.load.loadTransaction.transactionType === TransactionType.Pending ||
        this.load.loadTransaction.transactionType === TransactionType.SentToShipperTender ||
        this.load.loadTransaction.transactionType === TransactionType.Accepted ||
        this.load.loadTransaction.transactionType === TransactionType.Delivered)
    ) {
      if (
        this.user.isCarrier &&
        this.load.loadAccessorialRequests.accessorialRequestsLocked &&
        !this.load.loadAccessorialRequests.hasCarrierRequestedAccessorials
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  viewAccessorials(): void {
    const header = this.load.loadAccessorialRequests.hasCarrierRequestedAccessorials ? 'Requested Accessorials' : 'Request Accessorials';

    const ref = this.dialogService.open(AccessorialRequestsModalComponent, {
      data: {
        loadId: this.load.loadId,
        user: this.user,
        loadLocked: this.load.loadAccessorialRequests.accessorialRequestsLocked,
      },
      header: header,
      baseZIndex: accessorialRequestModalZIndex,
      closable: false,
    });

    ref.onClose.pipe(takeUntil(this.destroyed$)).subscribe((x: { refreshGrid: boolean }) => {
      // refresh detail card
      if (x && x.refreshGrid) {
        this.sharedStore.dispatch(new LoadBoardLoadDetailLoadAction(this.load.loadId));
      }
    });
  }

  getRequestIcon(): string {
    if (!this.load || !this.load.loadAccessorialRequests) {
      return '';
    }
    return this.load.loadAccessorialRequests.hasCarrierRequestedAccessorials ? '' : 'pi pi-plus';
  }
  getRequestLabel(): string {
    return this.load.loadAccessorialRequests.hasCarrierRequestedAccessorials ? 'Requested Accessorials' : 'Request Accessorials';
  }
  lockLoadAccessorialRequests(event: any): void {
    if (!event) {
      return;
    }
    this.sharedStore.dispatch(new AccessorialRequestUnlockLoadAction(this.load.loadId, !event.checked));
  }
}
