import { Action } from '@ngrx/store';
import { UserFavorite } from '../../../shared/models';

export enum FavoriteActionTypes {
  Load = '[Favorite] LOAD',
  Load_Success = '[Favorite] LOAD_SUCCESS',
  Load_Failure = '[Favorite] LOAD_FAILURE',
  Add_Favorite = '[Favorite] ADD',
  Add_Favorite_Success = '[Favorite] ADD_SUCCESS',
  Add_Favorite_Failure = '[Favorite] ADD_FAILURE',
  Update_Favorite = '[Favorite] UPDATE',
  Update_Favorite_Success = '[Favorite] UPDATE_SUCCESS',
  Update_Favorite_Failure = '[Favorite] UPDATE_FAILURE',
  Delete_Favorite = '[Favorite] DELETE',
  Delete_Favorite_Success = '[Favorite] DELETE_SUCCESS',
  Delete_Favorite_Failure = '[Favorite] DELETE_FAILURE',
}

export class FavoriteLoadAction implements Action {
  readonly type = FavoriteActionTypes.Load;
  constructor() {}
}
export class FavoriteLoadSuccessAction implements Action {
  readonly type = FavoriteActionTypes.Load_Success;
  constructor(public payload: UserFavorite[]) {}
}
export class FavoriteLoadFailureAction implements Action {
  readonly type = FavoriteActionTypes.Load_Failure;
  constructor(public payload: Error) {}
}

export class FavoriteAddAction implements Action {
  readonly type = FavoriteActionTypes.Add_Favorite;
  constructor(public payload: UserFavorite) {}
}
export class FavoriteAddSuccessAction implements Action {
  readonly type = FavoriteActionTypes.Add_Favorite_Success;
  constructor(public payload: UserFavorite[], public favoriteType: string, public addedUserFavoriteId: number) {}
}
export class FavoriteAddFailureAction implements Action {
  readonly type = FavoriteActionTypes.Add_Favorite_Failure;
  constructor(public payload: Error) {}
}

export class FavoriteUpdateAction implements Action {
  readonly type = FavoriteActionTypes.Update_Favorite;
  constructor(public payload: UserFavorite) {}
}
export class FavoriteUpdateSuccessAction implements Action {
  readonly type = FavoriteActionTypes.Update_Favorite_Success;
  constructor(public payload: UserFavorite[], public favoriteType: string, public updatedUserFavoriteId: number) {}
}
export class FavoriteUpdateFailureAction implements Action {
  readonly type = FavoriteActionTypes.Update_Favorite_Failure;
  constructor(public payload: Error) {}
}

export class FavoriteDeleteAction implements Action {
  readonly type = FavoriteActionTypes.Delete_Favorite;
  constructor(public payload: UserFavorite) {}
}
export class FavoriteDeleteSuccessAction implements Action {
  readonly type = FavoriteActionTypes.Delete_Favorite_Success;
  constructor(public payload: number, public favoriteType: string) {}
}
export class FavoriteDeleteFailureAction implements Action {
  readonly type = FavoriteActionTypes.Delete_Favorite_Failure;
  constructor(public payload: Error) {}
}

export type FavoriteActions =
  | FavoriteLoadAction
  | FavoriteLoadSuccessAction
  | FavoriteLoadFailureAction
  | FavoriteAddAction
  | FavoriteAddSuccessAction
  | FavoriteAddFailureAction
  | FavoriteUpdateAction
  | FavoriteUpdateSuccessAction
  | FavoriteUpdateFailureAction
  | FavoriteDeleteAction
  | FavoriteDeleteSuccessAction
  | FavoriteDeleteFailureAction;
