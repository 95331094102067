import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Chart } from 'chart.js';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { RateSeekerBrandMarkup } from 'src/app/core/utilities/constants';
import { DynamicPricingScheduleService } from '../../../core/services';
import {
  defaultDynamicPricing,
  DropdownOption,
  DynamicPricingRuleBuildScheduleRequest,
  DynamicPricingRuleData,
  DynamicPricingScheduleChangedData,
  DynamicPricingScheduleData,
} from '../../models';
import { BaseComponent } from '../base-component';
@Component({
  selector: 'kbxl-dynamic-pricing-rule-form',
  templateUrl: './dynamic-pricing-rule-form.component.html',
  styleUrls: ['./dynamic-pricing-rule-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicPricingRuleFormComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() pricingRule: DynamicPricingRuleData;
  @Input() percentOptions: DropdownOption[];
  @Input() frequencyOptions: DropdownOption[];
  @Input() intervalOptions: DropdownOption[];
  @Input() showSchedule = false;
  @Input() validate = false;
  @Input() marketplaceView = false;
  @Input() demolineHaul = 1000;
  @Input() loadPricingSchedule: DynamicPricingScheduleData;
  @Input() urnPath: string;
  @Input() urnIndex: string;
  @Input() errors: string;
  @Input() showAutoApproveWarning: boolean;
  @Input() brokeredLoad: boolean;
  @Input() brokeredTotalPrice: number;
  @Input() pickUpDate: Date;
  @Input() useReservedMargin: boolean;
  @Input() actualLineHaul: number;
  @Output() scheduleChanged: EventEmitter<DynamicPricingScheduleChangedData> = new EventEmitter<DynamicPricingScheduleChangedData>();
  @Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  loadingDynamicPricing = false;
  pricingForm: FormGroup;
  pricingSchedule: DynamicPricingScheduleData;
  calculationTypes: { label: string; value: string }[];
  graphData: any;
  graphPlugIns: any;
  rateSeekerLabel = RateSeekerBrandMarkup;
  errorMsg = '';
  lineHaulChanged = false;
  doe_changes_temporary_readonly = true;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dynamicPriceScheduleService: DynamicPricingScheduleService,

    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
    super();
    this.createForm();
  }

  public resetForm(pricingRule: DynamicPricingRuleData): void {
    const incomingChange = { ...defaultDynamicPricing };

    if (pricingRule) {
      incomingChange.floorAmt = pricingRule.floorAmt;
      incomingChange.floorPct = pricingRule.floorPct;
      incomingChange.nbrAdjustments = pricingRule.nbrAdjustments;
      incomingChange.adjustmentFrequency = pricingRule.adjustmentFrequency;
      incomingChange.reservedMargin = pricingRule.reservedMargin;
      incomingChange.startBeforePickUpDateHours = pricingRule.startBeforePickUpDateHours;
      incomingChange.stopBeforePickUpDateHours = pricingRule.stopBeforePickUpDateHours;
      this.lineHaulChanged = true;
    } else {
      this.updateForm();
    }
    this.updateAmount(incomingChange);
  }
  ngOnInit(): void {
    // this is needed for the chart.js and plug in package reference, otherwise the AOT compiler drops these
    // packages even though we bring the in via the angular.json
    // if you remove these lines and the imports above the "green line" will no longer work :(
    const namedChartAnnotation = ChartAnnotation;
    namedChartAnnotation['id'] = 'annotation';
    Chart.pluginService.register(namedChartAnnotation);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.pricingRule && this.pricingRule) {
      if (changes.demolineHaul && changes.demolineHaul.previousValue && changes.demolineHaul.previousValue !== this.demolineHaul) {
        this.lineHaulChanged = true;
      } else if (changes.demolineHaul && changes.loadPricingSchedule && changes.loadPricingSchedule.currentValue) {
        const max = changes.loadPricingSchedule.currentValue.schedule.length;
        const lastSchedule = changes.loadPricingSchedule.currentValue.schedule[max - 1];

        if (lastSchedule.adjustmentPrice !== this.demolineHaul) {
          this.lineHaulChanged = true;
        }
      }
      this.updateForm();
    } else if (changes && changes.demolineHaul) {
      const form = this.pricingForm.value;
      const incomingChange = { ...defaultDynamicPricing };

      incomingChange.floorAmt = form.floorAmount;
      incomingChange.floorPct = form.floorPercent;
      incomingChange.nbrAdjustments = form.interval;
      incomingChange.adjustmentFrequency = form.frequency;
      incomingChange.reservedMargin = form.reservedMarginControl;
      incomingChange.startBeforePickUpDateHours = form.startBeforePickUpControl;
      incomingChange.stopBeforePickUpDateHours = form.stopBeforePickUpControl;
      this.lineHaulChanged = true;
      this.updateAmount(incomingChange);
    }
  }

  createForm(): void {
    this.calculationTypes = [
      {
        label: 'Flat Amount',
        value: 'flat',
      },
      {
        label: 'Percentage',
        value: 'percent',
      },
    ];

    this.pricingForm = this.formBuilder.group({
      calculation: new FormControl('flat'),
      floorAmount: new FormControl(''),
      floorPercent: new FormControl(''),
      interval: new FormControl(null),
      frequency: new FormControl(null),
      startBeforePickUpControl: new FormControl(null),
      stopBeforePickUpControl: new FormControl(null),
      reservedMarginControl: new FormControl(null),
    });

    // capture the form changes to recalc the schedule
    this.pricingForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(350)).subscribe((x) => {
      // send the incoming change
      const incomingChange = { ...defaultDynamicPricing };

      incomingChange.floorAmt = x.floorAmount;
      incomingChange.floorPct = x.floorPercent;
      incomingChange.nbrAdjustments = x.interval;
      incomingChange.adjustmentFrequency = x.frequency;
      incomingChange.reservedMargin = x.reservedMarginControl;
      incomingChange.startBeforePickUpDateHours = x.startBeforePickUpControl;
      incomingChange.stopBeforePickUpDateHours = x.stopBeforePickUpControl;
      this.updateAmount(incomingChange);
    });
  }
  updateForm(): void {
    let interval = '';
    let amount = null;
    let percent = null;
    let calculationType = 'flat';
    if (this.pricingRule) {
      if (this.pricingRule.nbrAdjustments) {
        interval = this.pricingRule.nbrAdjustments.toString();
      }

      if (this.pricingRule.floorPct && this.pricingRule.floorPct > 0) {
        percent = this.pricingRule.floorPct.toString();
        calculationType = 'percent';
      }

      if (this.pricingRule.floorAmt && this.pricingRule.floorAmt > 0) {
        amount = this.pricingRule.floorAmt;
      }
    }
    this.pricingForm.patchValue({
      calculation: calculationType,
      floorAmount: amount,
      floorPercent: percent,
      interval: interval,
      frequency: this.pricingRule?.adjustmentFrequency,
      reservedMarginControl: this.pricingRule?.reservedMargin,
      startBeforePickUpControl: this.pricingRule?.startBeforePickUpDateHours,
      stopBeforePickUpControl: this.pricingRule?.stopBeforePickUpDateHours,
    });
  }

  resetFloor(event: any): void {
    this.pricingForm.patchValue({
      floorAmount: null,
      floorPercent: null,
    });
  }

  updatePricingRule(): void {
    const form = this.pricingForm.value;

    let pricingRule = this.deepClone(this.pricingRule) as DynamicPricingRuleData;
    if (!pricingRule) {
      pricingRule = { ...defaultDynamicPricing };
    }

    pricingRule.floorAmt = form.floorAmount;
    pricingRule.floorPct = form.floorPercent;
    pricingRule.nbrAdjustments = form.interval;
    pricingRule.adjustmentFrequency = form.frequency;
    pricingRule.startBeforePickUpDateHours = form.startBeforePickUpControl;
    pricingRule.stopBeforePickUpDateHours = form.stopBeforePickUpControl;
    pricingRule.reservedMargin = form.reservedMarginControl;

    if (this.pricingSchedule) {
      // attach the schedule that was display so the users can see floor and ceilings
      pricingRule.pricingSchedule = this.deepClone(this.pricingSchedule);
    }

    const payload: DynamicPricingScheduleChangedData = {
      valid: true,
      error: null,
      pricingRule: pricingRule,
    };

    this.scheduleChanged.emit(payload);
  }

  shouldUseLoadPricingSchedule(currentFormValue: DynamicPricingRuleData): boolean {
    let form = currentFormValue;
    if (!form) {
      form = this.pricingForm.value;
    }

    if (this.pricingRule) {
      // user has modified the form selection and hasn't inputted a value yet from the default load rule
      if (!form.floorAmt && !form.floorPct) {
        return;
      }

      if (this.pricingRule.floorAmt && form.floorAmt) {
        if (this.pricingRule.floorAmt.toString() !== form.floorAmt.toString()) {
          return false;
        }
        // if the lane management rule was set and the line haul is less than the floor, then let it hit validation and not use this rule
        if (this.demolineHaul && form.floorAmt > this.demolineHaul) {
          return false;
        }
      }
      if (this.pricingRule.floorPct && form.floorPct && this.pricingRule.floorPct.toString() !== form.floorPct.toString()) {
        return false;
      }
      if (
        this.pricingRule.nbrAdjustments &&
        form.nbrAdjustments &&
        this.pricingRule.nbrAdjustments.toString() !== form.nbrAdjustments.toString()
      ) {
        return false;
      }
      if (this.pricingRule.adjustmentFrequency !== form.adjustmentFrequency) {
        return false;
      }
      if (this.lineHaulChanged) {
        this.lineHaulChanged = false;
        return false;
      }
      if (this.brokeredLoad) {
        if (this.pricingRule.startBeforePickUpDateHours !== form.startBeforePickUpDateHours) {
          return false;
        }
        if (this.pricingRule.stopBeforePickUpDateHours !== form.stopBeforePickUpDateHours) {
          return false;
        }
        if (this.pricingRule.reservedMargin !== form.reservedMargin) {
          return false;
        }
      }
    }

    // Use the load pricing schedule if it exists and the form has not changed values
    return this.loadPricingSchedule && !this.pricingForm.touched;
  }

  updateAmount(currentFormValue: DynamicPricingRuleData): void {
    this.formValid.emit(true);
    /*
     * Only ask the API for a new generated schedule if we don't have a load pricing schedule from
     * the database, unless we're overriding the stored pricing schedule with new values changed
     * via the UI.
     */
    if (this.shouldUseLoadPricingSchedule(currentFormValue)) {
      this.pricingSchedule = this.loadPricingSchedule;
      this.updatePricingRule();
      this.buildGraph();
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.pricingSchedule = null;
    this.graphData = null;
    this.graphPlugIns = null;
    this.changeDetectorRef.detectChanges();
    // check if all inputs are populated
    if (
      !(this.pricingForm.value.floorAmount || this.pricingForm.value.floorPercent) ||
      !this.pricingForm.value.interval ||
      (!this.pricingForm.value.frequency && !this.brokeredLoad) ||
      this.demolineHaul <= 0
    ) {
      return;
    }

    // if flat amount is used, make sure its valid
    if (this.pricingForm.value.calculation === 'flat') {
      if (!this.pricingForm.value.floorAmount || this.pricingForm.value.floorAmount < 1) {
        return;
      }
      if (this.validate && this.demolineHaul <= this.pricingForm.value.floorAmount) {
        this.errorMsg = 'Rate Seeker flat amount is greater than or equal to line haul amount.';

        this.emitScheduleChangeError();
        return;
      }
      // only change the amount if this isn't on the marketplace
      if (!this.marketplaceView) {
        // for demo line haul we use 1000, if the flat amount is over that, just double it to make the chart pretty :)
        if (this.pricingForm.value.floorAmount >= this.demolineHaul) {
          this.demolineHaul = this.pricingForm.value.floorAmount * 2;
        }
      }
    }

    // if percent is used, make sure its valid
    if (
      this.pricingForm.value.calculation === 'percent' &&
      (!this.pricingForm.value.floorPercent || this.pricingForm.value.floorPercent.length < 1)
    ) {
      return;
    }

    if (
      this.brokeredLoad &&
      this.validate &&
      (this.pricingForm.value.stopBeforePickUpControl !== 0 || this.pricingForm.value.startBeforePickUpControl !== 0) &&
      this.pricingForm.value.stopBeforePickUpControl >= this.pricingForm.value.startBeforePickUpControl
    ) {
      this.errorMsg = 'Stop date/time is before Start.';
      this.emitScheduleChangeError();
      this.formValid.emit(false);
      return;
    }

    this.loadingDynamicPricing = true;
    this.errorMsg = '';

    const request: DynamicPricingRuleBuildScheduleRequest = {
      floorAmt: this.pricingForm.value.floorAmount,
      floorPct: this.pricingForm.value.floorPercent,
      nbrAdjustments: this.pricingForm.value.interval,
      adjustmentFrequency: this.pricingForm.value.frequency,
      maxLineHaul: this.demolineHaul,
      scheduleStartTime: null, // this will be defaulted on the server
      brokeredLoad: this.brokeredLoad,
      pickUpDate: this.pickUpDate,
      reservedMargin: this.pricingForm.value.reservedMarginControl,
      startBeforePickUpDateHours: this.pricingForm.value.startBeforePickUpControl,
      stopBeforePickUpDateHours: this.pricingForm.value.stopBeforePickUpControl,
      totalPrice: this.brokeredTotalPrice == null ? 0 : this.brokeredTotalPrice,
    };
    if (this.brokeredLoad) {
      request.maxLineHaul = this.actualLineHaul;
      if (!this.useReservedMargin) {
        request.reservedMargin = 0;
      }
      if (!request.adjustmentFrequency) {
        request.adjustmentFrequency = '00:00:00';
      }
    }

    this.dynamicPriceScheduleService.getDynamicPricingSchedule(request).subscribe(
      (x) => {
        this.loadingDynamicPricing = false;
        this.pricingSchedule = x;
        if (this.brokeredLoad) {
          this.pricingRule.adjustmentFrequency = x.adjustmentFrequency;
          this.pricingForm.patchValue(
            {
              frequency: this.pricingRule.adjustmentFrequency,
            },
            { emitEvent: false, onlySelf: true }
          );
          if (this.useReservedMargin) {
            this.demolineHaul = this.actualLineHaul - this.pricingForm.value.reservedMarginControl;
          }
        }
        // apply amount update to pricingRule and signal change
        this.updatePricingRule();

        this.buildGraph();

        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.loadingDynamicPricing = false;
      }
    );
  }

  buildGraph(): void {
    if (!this.showSchedule) {
      return;
    }

    let currentPosition = 0;
    let currentPositionX = 0;
    const now = new Date();
    const displayRealTimeIndicator = this.pricingSchedule.schedule.some((x) => x.activePrice);

    const dateTimeFormat = 'M/d/yy h:mm a';

    // calc the percent completed with this schedule
    const end = new Date(this.pricingSchedule.schedule[this.pricingSchedule.schedule.length - 1].adjustmentStartTime);
    if (now > end) {
      currentPositionX = this.pricingSchedule.schedule.length; // 100% but let it drift somewhere in the 'remaining' field
    } else {
      const start = new Date(this.pricingSchedule.schedule[0].adjustmentStartTime);
      // take end - begin and find the relative percent to the diff.
      const nowDiff = end.getTime() - start.getTime();
      currentPosition = (now.getTime() - start.getTime()) / nowDiff;
      currentPositionX = (this.pricingSchedule.schedule.length - 1) * currentPosition;
    }

    if (currentPositionX === this.pricingSchedule.schedule.length) {
      // move over slightly to make better visual
      currentPositionX = currentPositionX - 0.01;
    } else if (currentPositionX <= 0) {
      // move over slightly to make better visual
      currentPositionX = currentPositionX + 0.01;
    }
    const labels = this.pricingSchedule.schedule.map((x) => this.datePipe.transform(x.adjustmentStartTime, dateTimeFormat));

    labels.push('Remaining');

    const dataPoints = [];
    for (let index = 0; index < this.pricingSchedule.schedule.length; index++) {
      const element = this.pricingSchedule.schedule[index];

      dataPoints.push({
        x: index, // element.adjustmentStartTime,
        y: element.adjustmentPrice,
      });
    }

    // simulate a plateau so readd the last data point
    dataPoints.push({
      x: dataPoints.length + 1,
      y: dataPoints[dataPoints.length - 1].y,
    });

    this.graphData = {
      labels: labels,
      datasets: [
        {
          label: 'Marketplace price',
          data: dataPoints,
          fill: false,
          borderColor: '#31c2ae',
          steppedLine: true,
        },
      ],
    };

    if (displayRealTimeIndicator) {
      this.graphPlugIns = {
        annotation: {
          annotations: [
            {
              drawTime: 'afterDraw',
              type: 'line',
              mode: 'vertical',
              scaleID: 'x-axis-0',
              value: currentPositionX.toString(),
              borderColor: '#ACE171',
              borderWidth: 2,
            },
          ],
        },
      };
    }
  }

  getStartingLineHaul(): number {
    if (this.pricingSchedule) {
      return this.pricingSchedule.schedule[0].adjustmentPrice;
    }

    return 0;
  }

  getUrnPath(property: string) {
    if (this.urnPath) {
      property = this.urnPath + ':' + property;
    }
    if (this.urnIndex) {
      property = property + ':' + this.urnIndex;
    }

    return property;
  }

  private emitScheduleChangeError(): void {
    const payload: DynamicPricingScheduleChangedData = {
      valid: false,
      error: this.errorMsg,
      pricingRule: null,
    };

    this.scheduleChanged.emit(payload);
  }
}
