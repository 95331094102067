import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AccessorialAllocationLevelEnum,
  CarrierAccessorialRequestData,
  LoadAccessorialDetailData,
  LoadDocumentMetadata,
  LoadDocumentUpload,
  User,
} from '../../../models';

@Component({
  selector: 'kbxl-add-accessorial-request',
  templateUrl: './add-accessorial-request.component.html',
  styleUrls: ['./add-accessorial-request.component.scss'],
})
export class AddAccessorialRequestComponent implements OnChanges {
  @Input() user: User;
  @Input() shipperAccessorials: LoadAccessorialDetailData[];
  @Input() carrierRequests: CarrierAccessorialRequestData[] = [];
  @Input() refreshAvailableAccessorials: boolean;
  @Output() refreshAvailableAccessorialsChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addAccessorialRequest: EventEmitter<CarrierAccessorialRequestData> = new EventEmitter<CarrierAccessorialRequestData>();

  documents: LoadDocumentUpload[] = [];
  addAccessorialRequestForm: FormGroup;
  addRequestActiveIndex: number;
  availableAccessorials: LoadAccessorialDetailData[];
  constructor(private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    this.createAddRequestForm();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.shipperAccessorials || this.carrierRequests) {
      this.buildAvailableAccessorials();
    }
  }

  shouldShowAfterInitialHours(accessorial: LoadAccessorialDetailData): boolean {
    return accessorial && Number.isInteger(accessorial.afterInitialHours) && accessorial.afterInitialHours > 0;
  }

  createAddRequestForm(): void {
    this.addAccessorialRequestForm = this.formBuilder.group(
      {
        accessorial: new FormControl(null, [Validators.required]),
        stop: new FormControl(null, [Validators.max(100)]),
        showStop: new FormControl(false),
        quantity: new FormControl(1, [Validators.required, Validators.max(100000)]),
        ratePerUom: new FormControl(null, [Validators.required]),
        rate: new FormControl(null, [Validators.required]),
        comment: new FormControl(null, [Validators.maxLength(4000)]),
      },
      {
        validators: [this.stopValidator, this.commentValidator],
      }
    );
  }

  stopValidator(group: FormGroup): { [key: string]: any } | null {
    if (group.value.accessorial && group.value.accessorial.accessorial.allocationLevel === AccessorialAllocationLevelEnum.StopLevel) {
      group.controls.stop.setValidators(Validators.required);
    } else {
      group.controls.stop.clearValidators();
    }
    return null;
  }
  commentValidator(group: FormGroup): { [key: string]: any } | null {
    if (group.value.accessorial && group.value.accessorial.accessorialDescription === 'Misc Charge') {
      group.controls.comment.setValidators([Validators.required, Validators.maxLength(4000)]);
    } else {
      group.controls.comment.clearValidators();
      group.controls.comment.setValidators([Validators.maxLength(4000)]);
    }
    group.controls.comment.updateValueAndValidity({
      onlySelf: true,
    });

    return null;
  }

  updateAddFormValues(): void {
    if (!this.addAccessorialRequestForm.value.accessorial) {
      return;
    }
    let rate = 0;
    let stop = this.addAccessorialRequestForm.value.stop;
    let showStop = false;
    let quantity = this.addAccessorialRequestForm.value.quantity;
    let ratePerUom = this.addAccessorialRequestForm.value.ratePerUom;

    // if load level accessorial, require stop level
    if (this.addAccessorialRequestForm.value.accessorial.accessorial.allocationLevel === AccessorialAllocationLevelEnum.StopLevel) {
      showStop = true;
    } else {
      stop = null;
    }
    // enable rate per uom if the accessorial is variable
    if (this.addAccessorialRequestForm.value.accessorial.variableRate) {
      this.addAccessorialRequestForm.controls['ratePerUom'].enable();
    } else {
      if (this.addAccessorialRequestForm.value.accessorial.accessorialUnitOfMeasureDescription === 'Flat Charge') {
        ratePerUom = this.addAccessorialRequestForm.value.accessorial.maxRate;
        this.addAccessorialRequestForm.controls['ratePerUom'].disable();
      } else {
        this.addAccessorialRequestForm.controls['ratePerUom'].enable();
      }
    }

    // if flat charge they cannot input quantity
    if (
      this.addAccessorialRequestForm.value.accessorial &&
      this.addAccessorialRequestForm.value.accessorial.accessorialUnitOfMeasureDescription === 'Flat Charge'
    ) {
      quantity = 1;
      this.addAccessorialRequestForm.controls['quantity'].disable();
    } else {
      this.addAccessorialRequestForm.controls['quantity'].enable();
    }

    // update the rate for the accessorial
    rate = quantity * ratePerUom;

    this.addAccessorialRequestForm.patchValue({
      rate: rate,
      stop: stop,
      showStop: showStop,
      quantity: quantity,
      ratePerUom: ratePerUom,
    });
  }

  addNewAccessorial(): void {
    // use getRawValue to ensure any disabled control values get included
    const form = this.addAccessorialRequestForm.getRawValue();

    const newRequest: CarrierAccessorialRequestData = {
      carrierAccessorialRequestId: 0,
      loadAccessorialId: form.accessorial.loadAccessorialId as number,
      loadClaimId: null,
      stop: form.stop as number,
      quantity: form.quantity as number,
      ratePerUom: form.ratePerUom as number,
      rate: form.rate as number,
      status: 1,
      statusDescription: 'New',
      actions: [],
      loadAccessorial: form.accessorial,
      documentsToUpload: this.documents,
    };

    if (form.comment && form.comment.length > 0) {
      newRequest.actions.push({
        comment: form.comment as string,
        userName: this.user.name,
        createDtTm: new Date(),
        userId: this.user.userId,
      });
    }

    this.addAccessorialRequest.emit(newRequest);

    // this.carrierRequests.push(newRequest);
    // this.pendingChange = true;
    // switch to the new tab
    this.addRequestActiveIndex = -1;
    // this.carrierRequestActiveIndex = this.carrierRequests.length - 1;

    // clear form
    this.addAccessorialRequestForm.reset({
      onlySelf: true,
      emitEvent: false,
    });
    this.documents = [];
    // update the available accessorials so we don't get dups
    this.buildAvailableAccessorials();
    this.changeDetectorRef.detectChanges();
  }

  handleDocumentChange(payload: LoadDocumentUpload): void {
    if (payload) {
      // save for add request
      this.documents.push(payload);
    }
  }
  removeDocument(doc: LoadDocumentMetadata, index: number): void {
    this.documents.splice(index, 1);
  }
  private buildAvailableAccessorials(): void {
    if (!this.shipperAccessorials || this.shipperAccessorials.length < 1) {
      return;
    }
    const availableAccessorials = [];

    for (let index = 0; index < this.shipperAccessorials.length; index++) {
      const element = this.shipperAccessorials[index];

      const exists = this.carrierRequests.find((x) => x.loadAccessorialId === element.loadAccessorialId);

      // if an accessorial exists, check if its a stop level accessorial; if doesn't exists, add it too
      if ((exists && exists.loadAccessorial.accessorial.allocationLevel === AccessorialAllocationLevelEnum.StopLevel) || !exists) {
        availableAccessorials.push({
          label: element.accessorialDescription,
          value: element,
        });
      }
    }

    this.availableAccessorials = availableAccessorials;
  }
}
