import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map, takeUntil } from 'rxjs/operators';
import { CreateDirectMessageData } from 'src/app/chat/models';
import { ChatState, JoinDirectMessageAction } from 'src/app/chat/store';
import { CoreState, CustomerLoadAction, getCustomer } from 'src/app/core/store';
import { counterOfferRequestModalZIndex } from '../../../constants';
import {
  BookLoadRequest,
  BookLoadType,
  CounterOfferRequestStatusEnum,
  Customer,
  LoadCounterOfferActionData,
  LoadCounterOfferData,
  LoadCounterOfferGroupData,
  LoadCounterOfferRequestData,
  LoadDetail,
  ResponseError,
  ShipperGeneratedCounterOffer,
  UserContactData,
  UserModel,
} from '../../../models';
import { LoadCounterOfferService } from '../../../services';
import {
  getLoadBoardBookingShowCounterOfferModal,
  getLoadBoardLoadDetailErrors,
  getLoadBoardSelectedLoad,
  LoadBoardLoadBookAction,
  LoadBoardLoadDetailLoadAction,
  SharedState,
} from '../../../store';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'kbxl-counter-offer-requests-modal',
  templateUrl: './counter-offer-requests-modal.component.html',
  styleUrls: ['./counter-offer-requests-modal.component.scss'],
  providers: [DialogService],
})
export class CounterOfferRequestsModalComponent extends BaseComponent implements OnInit, OnChanges {
  user: UserModel;
  load: LoadDetail;
  get loadDisplay(): string {
    let result = '';
    if (this.load) {
      result = `for ${this.load.referenceLoadDisplay}`;
      if (this.load.loadStops && this.load.loadStops.length >= 2) {
        const origin = this.load.loadStops[0];
        const destination = this.load.loadStops[1];
        if (origin && destination) {
          result += ` (${origin.city}, ${origin.state} to ${destination.city}, ${destination.state})`;
        }
      }
    }
    return result;
  }
  fetchLoad = true;
  loadId: string;
  loadingCounter = 0;
  get loading(): boolean {
    return this.loadingCounter > 0;
  }
  counterOffers: LoadCounterOfferGroupData[] = [];
  counterOfferActiveIndex: number;
  errorMsg = '';
  loadDetailErrors: ResponseError[];
  pendingChange = false;
  addRequestActiveIndex: number;
  showBookedLoadModal = false;
  bookingModalZIndex = counterOfferRequestModalZIndex + 1;
  allowNewCounterOffers = false;
  shipperUserData: UserContactData;
  shipper: Customer;
  pendingApplyAction: {
    index: number;
    action: LoadCounterOfferActionData;
  } = null;
  // shipper originated counter offer properties
  shipperOfferOptions: ShipperGeneratedCounterOffer;
  private bookingActionTaken = false;
  private actionTaken = false;
  constructor(
    private store: Store<SharedState>,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private confirmationService: ConfirmationService,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private counterOfferService: LoadCounterOfferService,
    private chatStore: Store<ChatState>,
    private coreStore: Store<CoreState>
  ) {
    super();
    this.loadId = config.data.loadId;
    this.fetchLoad = config.data.fetchLoad;
    this.user = new UserModel(config.data.user);
    this.shipper = config.data.shipper;
    this.shipperOfferOptions = config.data.shipperOfferOptions;
  }
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit() {
    this.loadingCounter++;
    // fetch all offers for this load
    this.counterOfferService.get(this.loadId, this.shipperOfferOptions?.scac).subscribe((x) => {
      this.loadingCounter--;
      this.allowNewCounterOffers = x.allowNewCounterOffer;
      this.shipperUserData = x.shipperUserData;

      if (x.counterOffers) {
        this.counterOffers = x.counterOffers;
      } else {
        this.counterOffers = [];
      }

      if (this.user.isShipper) {
        if (this.shipperOfferOptions) {
          // open the new counter offer accordion
          this.addRequestActiveIndex = 0;
        } else {
          // open first tab where action is request
          this.openActionItem();
        }
      }
    });
    if (this.loadId && this.fetchLoad) {
      // load wasn't passed in so we need to go fetch it
      this.store.dispatch(new LoadBoardLoadDetailLoadAction(this.loadId, this.shipperOfferOptions?.scac));
    }

    this.loadingCounter++;
    this.store
      .pipe(
        takeUntil(this.destroyed$),
        select(getLoadBoardSelectedLoad),
        map((x) => this.deepClone(x))
      )
      .subscribe((detail) => {
        if (detail) {
          this.load = detail;
          this.loadingCounter--;
          // Only try to load the customer if the caller didn't pass it in
          // We do not have a shipper/load context on action items grid
          if (!this.shipper) {
            this.coreStore.dispatch(new CustomerLoadAction({ customerId: this.load.customerId })); // Get load's shipper for DM purposes
          }
        }
      });

    // Only try to load the customer if the caller didn't pass it in
    // We do not have a shipper/load context on action items grid
    if (!this.shipper) {
      this.coreStore.pipe(select(getCustomer), takeUntil(this.destroyed$)).subscribe((x) => (this.shipper = x));
    }

    this.store.pipe(takeUntil(this.destroyed$), select(getLoadBoardLoadDetailErrors)).subscribe((errors) => {
      if (errors && errors.find((x) => x.code === -1)) {
        // this error is thrown if the load didn't load correctly such as if the user click something they shouldn't
        // have or if the load was booked
        this.loadingCounter = 0;
      }

      this.loadDetailErrors = errors?.filter(
        (x) =>
          (typeof x.data === 'string' && (x.data as string).toLocaleLowerCase().indexOf('linehaul') !== -1) ||
          (typeof x.data === 'string' && (x.data as string).toLocaleLowerCase().indexOf('counteroffer') !== -1)
      );
      if (this.loadDetailErrors && this.loadDetailErrors.length > 0) {
        this.loadingCounter = 0;
        // open the accordion
        this.addRequestActiveIndex = 0;
      }
    });

    this.store.pipe(takeUntil(this.destroyed$), select(getLoadBoardBookingShowCounterOfferModal)).subscribe((show) => {
      if (!show && this.bookingActionTaken) {
        this.ref.close({
          refreshGrid: true,
        });
      }
    });
  }
  toggleTab(index: number): void {
    this.counterOfferActiveIndex = this.counterOfferActiveIndex === index ? null : index;
  }

  cancel(): void {
    this.confirmAndExecute(() =>
      this.ref.close({
        refreshGrid: this.actionTaken,
      })
    );
  }

  /**
   * Takes care of checking for pending changes, and if found, prompting
   * the user if it's okay to continue with closing the counter offer modal
   * and discarding any pending changes.
   */
  confirmAndExecute(accept: Function) {
    if (!this.pendingChange) {
      accept();
      return;
    }

    let header = 'Cancel Confirmation';
    let message = 'Changes will not be saved.  Are you sure you want to proceed?';

    if (this.user && this.user.isCarrier && this.allowNewCounterOffers) {
      // the user is canceling creating a new offer
      header = 'Counter Offer Cancel Confirmation';
      message = 'Counter Offer will not be saved and sent to shipper for approval.  Are you sure you want to proceed?';
    }

    this.confirmationService.confirm({
      header: header,
      message: message,
      accept: () => accept(),
    });
  }

  applyAction(action: LoadCounterOfferActionData, index: number, forceSave: boolean = false): void {
    if (this.user.isCarrier && action.status === CounterOfferRequestStatusEnum.Approved && action.contractedCarrierOffer && !forceSave) {
      // show the booking confirmation with all agreements
      this.showBookedLoadModal = true;

      // save this action as pending for when the user books the load
      this.pendingApplyAction = {
        index: index,
        action: action,
      };
      return;
    }
    this.loadingCounter++;
    // save the action and update request
    this.counterOfferService.saveAction(this.loadId, action.loadCounterOfferId, action).subscribe(
      (updatedRequestGroup) => {
        this.actionTaken = true;
        this.loadingCounter--;
        let msg = 'Counter offer updated';

        const updatedRequest = this.getActiveCounterOffer(updatedRequestGroup);
        if (updatedRequest.status === CounterOfferRequestStatusEnum.Approved) {
          msg = 'Counter offer accepted. Load has been booked.';
        } else if (updatedRequest.status === CounterOfferRequestStatusEnum.Denied) {
          msg = 'Counter offer denied';
        }
        // show toast
        this.messageService.add({
          detail: msg,
          severity: 'success',
        });
        // replace request to see updates
        this.counterOffers[index] = updatedRequestGroup;
        // wipe all pending changes
        this.pendingChange = this.counterOffers.some((x) => x.counterOffers.find((y) => !!y.actionTaken));
        // close the accordion tab
        if (!this.openActionItem() || updatedRequestGroup.currentStatus === CounterOfferRequestStatusEnum.Approved) {
          // close the modal as there is no more action items or it was an approval
          this.ref.close({
            refreshGrid: this.actionTaken,
          });
        } else {
          this.changeDetectorRef.detectChanges();
        }
      },
      (error) => {
        this.loadingCounter = 0;
      }
    );
  }

  submitCounterOffer(counterOffer: LoadCounterOfferRequestData, index?: number): void {
    this.pendingChange = true;

    // shipper flow counter offer flow - either rebuttal or new request
    // carrier counter rebuttal / counter a counter offer flow
    if ((counterOffer.parentLoadCounterOfferId && counterOffer.parentLoadCounterOfferId > 0) || counterOffer.shipperOriginatedRequest) {
      this.loadingCounter++;
      counterOffer.loadId = this.loadId;
      // call api to save new counter offer
      this.counterOfferService.saveNewCounterOffer(this.loadId, counterOffer).subscribe((updatedCounterOffer) => {
        this.messageService.add({ id: 0, detail: 'Counter offer submitted', severity: 'success' });
        this.actionTaken = true;
        // replace request to see updates
        this.counterOffers[index] = updatedCounterOffer;
        this.loadingCounter--;
        // wipe all pending changes
        this.pendingChange = this.counterOffers.some((x) => x.counterOffers.find((y) => !!y.actionTaken));
        this.openActionItem();

        if (
          counterOffer.shipperOriginatedRequest ||
          !this.openActionItem() ||
          updatedCounterOffer.currentStatus === CounterOfferRequestStatusEnum.Approved
        ) {
          // close the modal as there is no more action items or it was an approval
          this.ref.close({
            refreshGrid: true,
          });
        }
      });
    } else {
      // carrier flow functionality
      if (!this.load.counterOffer) {
        this.load.counterOffer = {
          allowCounterOffer: true,
          hasPendingCounterOffer: false,
          request: null,
          disableBookButton: false,
        };
      }
      this.load.counterOffer.request = counterOffer;
      // show the booking confirmation
      this.showBookedLoadModal = true;
    }
  }

  bookLoad(load: LoadDetail): void {
    this.bookingActionTaken = true; // flag to close modal if booking is successful
    this.showBookedLoadModal = false; // hide booking agreements

    if (this.pendingApplyAction) {
      // apply the counter offer action
      this.applyAction(this.pendingApplyAction.action, this.pendingApplyAction.index, true);
    } else {
      // book the load the normal way
      this.loadingCounter++;
      const request: BookLoadRequest = {
        bookingType: BookLoadType.CounterOfferBooked,
        loadData: load,
      };

      this.store.dispatch(new LoadBoardLoadBookAction(request));
    }
  }

  private openActionItem(): boolean {
    let index = -1;
    if (this.user && this.user.isShipper) {
      // open first tab where action is request
      index = this.counterOffers.findIndex((x) => x.currentStatus === CounterOfferRequestStatusEnum.PendingShipper);
    } else {
      // open first tab where action is request
      index = this.counterOffers.findIndex((x) => x.currentStatus === CounterOfferRequestStatusEnum.PendingCarrier);
    }

    if (index > -1) {
      this.counterOfferActiveIndex = index;
      this.changeDetectorRef.detectChanges();
    }

    return index > -1;
  }
  private getActiveCounterOffer(counterOfferGroup: LoadCounterOfferGroupData): LoadCounterOfferData {
    const carrierOffer = counterOfferGroup.counterOffers.find((x) => x.loadCounterOfferId === counterOfferGroup.currentCarrierOfferId);
    const shipperOffer = counterOfferGroup.counterOffers.find((x) => x.loadCounterOfferId === counterOfferGroup.currentShipperOfferId);
    // if the shipper initiated the counter offer, use that. Ex shipper sent offer to contracted carrier
    if (!carrierOffer && shipperOffer) {
      return shipperOffer;
    } else if (carrierOffer && carrierOffer.status === CounterOfferRequestStatusEnum.Approved) {
      return carrierOffer;
    } else if (shipperOffer && shipperOffer.status === CounterOfferRequestStatusEnum.Approved) {
      return shipperOffer;
    }
    return shipperOffer && new Date(shipperOffer?.created) > new Date(carrierOffer?.created) ? shipperOffer : carrierOffer;
  }

  chatNow(dmData: CreateDirectMessageData): void {
    const openChatAndClose = () => {
      this.chatStore.dispatch(new JoinDirectMessageAction(dmData));
      this.ref.close({
        refreshGrid: this.actionTaken,
      });
    };
    // If Brian decides he wants the confirmation to check if it's okay to close
    // the dialog before launching chat
    // this.confirmAndExecute(openChatAndClose);
    openChatAndClose();
  }
}
