import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CoreState, getBrowserIsMobile } from 'src/app/core/store';
import { UserCommunication } from '../../../../shared/models';
import { getUserCommunications, getUserCommunicatonAcknowledgementPosting, SharedState } from '../../../store';

@Component({
  selector: 'kbxl-user-communication-display-container',
  templateUrl: './user-communication-display-container.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCommunicationDisplayContainerComponent implements OnInit {
  userCommunications$: Observable<UserCommunication[]>;
  processing$: Observable<boolean>;
  isMobile$: Observable<boolean>;

  constructor(private sharedStore: Store<SharedState>, private coreStore: Store<CoreState>) {}
  ngOnInit(): void {
    this.isMobile$ = this.coreStore.pipe(select(getBrowserIsMobile));
    this.processing$ = this.sharedStore.pipe(select(getUserCommunicatonAcknowledgementPosting));
    this.userCommunications$ = this.sharedStore.pipe(select(getUserCommunications));
  }
}
