export interface ServiceType {
  serviceTypeId: number;
  name: string;
  excludeFromManualLoads: boolean;
  sortOrder: number;
}

export const defaultServiceType: ServiceType = {
  serviceTypeId: null,
  name: null,
  excludeFromManualLoads: false,
  sortOrder: 0,
};
