import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from '../../../../shared/components';

@Component({
  selector: 'kbxl-configuration-container',
  templateUrl: './configuration-container.component.html',
  styleUrls: ['./configuration-container.component.scss'],
})
export class ConfigurationContainerComponent extends BaseComponent {
  loadingGlobalSettings$: Observable<boolean>;

  constructor() {
    super();
  }
}
