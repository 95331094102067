import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import {
  CarrierAccessorialRequestActionData,
  CarrierAccessorialRequestData,
  CarrierAccessorialRequestStatusEnum,
  UserModel,
} from '../../../models';
import { SecurityAppActionType } from '../../../models/security-app-action-type';
import { AccessorialRequestService } from '../../../services';

@Component({
  selector: 'kbxl-accessorial-request-comments',
  templateUrl: './accessorial-request-comments.component.html',
  styleUrls: ['./accessorial-request-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessorialRequestCommentsComponent {
  @Input() carrierRequest: CarrierAccessorialRequestData;
  @Input() user: UserModel;
  @Input() loading: boolean;
  @Output() loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private messageService: MessageService, private accessorialRequestService: AccessorialRequestService) {}
  getActionHeader(request: CarrierAccessorialRequestData): string {
    return `Actions (${request.actions.length})`;
  }

  isUserComment(comment: CarrierAccessorialRequestActionData, request: CarrierAccessorialRequestData) {
    if (comment && this.user) {
      if (comment.userId === request.actions[0].userId) {
        return {
          'user-comment': true,
        };
      } else {
        return {
          'other-user-comment': true,
        };
      }
    }
    return '';
  }

  addCommentClick(textArea: HTMLTextAreaElement, request: CarrierAccessorialRequestData): void {
    this.loadingChange.emit(true);
    const comment: CarrierAccessorialRequestActionData = {
      comment: textArea.value as string,
      userName: this.user.name,
      createDtTm: new Date(),
      userId: this.user.userId,
    };
    this.accessorialRequestService.saveAction(request.carrierAccessorialRequestId, comment).subscribe(
      (x) => {
        // show toast
        this.messageService.add({
          detail: 'Accessorial request updated',
          severity: 'success',
        });
        this.loadingChange.emit(false);
        request.actions = x.actions;
        // reset text area
        textArea.value = '';

        // scroll to new comment
        document.getElementById(`comment-end-${request.carrierAccessorialRequestId}`).scrollIntoView({ behavior: 'smooth' });
      },
      (error) => {
        this.loadingChange.emit(false);
      }
    );
  }

  areCommentsAllowed(request: CarrierAccessorialRequestData): boolean {
    if (this.user.isShipper && this.user.hasSecurityAction(SecurityAppActionType.ShipperAccessorialRequestsApprove)) {
      return (
        request &&
        request.status !== CarrierAccessorialRequestStatusEnum.Approved &&
        request.status !== CarrierAccessorialRequestStatusEnum.Denied
      );
    } else if (this.user.isCarrier) {
      return (
        request &&
        request.status !== CarrierAccessorialRequestStatusEnum.Approved &&
        request.status !== CarrierAccessorialRequestStatusEnum.Denied
      );
    }
    return false;
  }
}
