import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/operators';
import { UserModel } from 'src/app/shared/models';
import { defaultNotesData, NoteData } from 'src/app/shared/models/note-data';
import { SecurityAppActionType } from 'src/app/shared/models/security-app-action-type';
import { NoteService } from 'src/app/shared/services';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'kbxl-notes-display-container',
  templateUrl: './notes-display-container.component.html',
  styleUrls: ['./notes-display-container.component.scss'],
})
export class NotesDisplayContainerComponent extends BaseComponent implements OnInit {
  @Input() notes: NoteData[];
  @Input() user: UserModel;
  @Input() loadId: string;
  note: string;
  loading: boolean;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private noteService: NoteService) {
    super();
    this.notes = config.data.notes;
    this.loadId = config.data.loadId;
    this.user = config.data.user;
  }

  ngOnInit(): void {}

  addNoteClick() {
    if (this.user.hasSecurityAction(SecurityAppActionType.NotesAddEdit)) {
      this.loading = true;
      const noteData: NoteData = {
        ...defaultNotesData,
        loadId: this.loadId,
        note: this.note,
      };
      this.noteService
        .createNote(noteData)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (data) => {
            this.loading = false;
            this.ref.close(true);
          },
          (err) => {
            this.loading = false;
          }
        );
    }
  }
}
