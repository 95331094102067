<div>
  <div class="p-inputgroup ui-tree-dropdown-toggle">
    <button pButton class="p-button-secondary ui-tree-button" (click)="toggle()">
      <span [hidden]="hidePlaceholder()">{{ placeHolder }}</span>
      <span [hidden]="!hidePlaceholder()">{{ getSelectedNodesDisplay() }}</span>
    </button>
    <button
      pButton
      [icon]="show ? 'pi  pi-caret-up' : 'pi pi-caret-down'"
      class="p-button-secondary p-inputgroup-addon p-dropdown-trigger"
      (click)="toggle()"
      [disabled]="disable"
    ></button>
  </div>
  <div [hidden]="!show" class="ui-tree-wrapper">
    <p-tree
      *ngIf="singleSelection"
      [value]="treeNodes"
      selectionMode="single"
      [(selection)]="selectedNode"
      appendTo="body"
      [filter]="true"
      (onNodeSelect)="selectionChanged()"
      (onNodeUnselect)="selectionChanged()"
    ></p-tree>
    <p-tree
      *ngIf="!singleSelection"
      [value]="treeNodes"
      selectionMode="checkbox"
      [(selection)]="selectedNodes"
      appendTo="body"
      [filter]="true"
      (onNodeSelect)="selectionChanged()"
      (onNodeUnselect)="selectionChanged()"
    ></p-tree>
  </div>
</div>
