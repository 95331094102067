import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import {
  LoadDetail,
  LoadStatusDetail,
  LoadStatusInTransitData,
  LoadStatusStopData,
  LoadStatusStopEventData,
  LoadStatusTypes,
  LoadStop,
  Place,
  State,
  StopEventTypes,
  TransactionType,
  UserModel,
  ValidationProblemDetails,
} from 'src/app/shared/models';
import { SecurityAppActionType } from 'src/app/shared/models/security-app-action-type';

@Component({
  selector: 'kbxl-load-status',
  templateUrl: './load-status.component.html',
  styleUrls: ['./load-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadStatusComponent implements OnDestroy {
  private _loadDetail: LoadDetail;
  @Input() set loadDetail(value: LoadDetail) {
    this._loadDetail = value;
    this.buildStopForm();
    this.buildInTransitForm();
  }
  get loadDetail() {
    return this._loadDetail;
  }

  private _loadStatus: LoadStatusDetail;
  @Input() set loadStatus(value: LoadStatusDetail) {
    this._loadStatus = value;
    this.buildStopForm();
    this.buildInTransitForm();
  }
  get loadStatus() {
    return this._loadStatus;
  }

  @Input() set loadStatusErrors(value: ValidationProblemDetails) {
    this.setErrors(value ? value.errors || {} : {});
  }

  @Input() loadingStatus: boolean;
  @Input() savingStatus: boolean;
  @Input() states: State[];
  @Input() user: UserModel;

  @Output() saveStopStatuses = new EventEmitter<LoadStatusStopData>();
  @Output() saveInTransitStatus = new EventEmitter<LoadStatusInTransitData>();

  get accepted() {
    return (
      this._loadDetail && this._loadDetail.loadTransaction && this._loadDetail.loadTransaction.transactionType === TransactionType.Accepted
    );
  }

  private _stopPanelCollapsed = false;
  public get stopPanelCollapsed() {
    return this._stopPanelCollapsed;
  }
  public set stopPanelCollapsed(value: boolean) {
    this._stopPanelCollapsed = value;
  }
  public get inTransitPanelCollapsed() {
    return !this._stopPanelCollapsed;
  }
  public set inTransitPanelCollapsed(value: boolean) {
    this._stopPanelCollapsed = !value;
  }

  public get maxDate() {
    return new Date();
  }

  public get displayStatusForms() {
    return (
      !this.loadingStatus &&
      this.loadStatus &&
      !this.loadStatus.processingUpdates &&
      this.loadStatus.status !== LoadStatusTypes.Delivered &&
      this.user &&
      this.user.hasSecurityAction(SecurityAppActionType.CarrierUpdateStatus) &&
      this.accepted
    );
  }

  public loadStatusTypes = LoadStatusTypes;
  public stopForm: FormGroup;
  public inTransitForm: FormGroup;
  public suggestions: Place[];

  public inTransitErrorSummary: string;
  public inTransitErrorCount: number;
  public stopStatusesErrorSummary: string;
  public stopStatusesErrorCount: number;

  private stopFormValueChangesSub: Subscription;

  public canSubmitInTransit = false;
  public availableInTransitStops: LoadStop[] = [];

  private inTransitMap = [
    { urn: '', formControlName: '' },
    { urn: 'LocationTime', formControlName: 'statusTime' },
    { urn: 'Latitude', formControlName: 'location' },
    { urn: 'Longitude', formControlName: 'location' },
  ];

  private stopStatusTimeMap = [
    { urn: '', formControlName: '' },
    { urn: 'EventTime', formControlName: 'statusTime' },
  ];

  private invalidStatusDescriptionRegex: RegExp = /n[\/\\]a/i;

  private stopStatusReasons: SelectItem[] = [
    { value: 'NS', label: 'Normal Status' },
    { value: 'AF', label: 'Accident' },
    { value: 'AG', label: 'Consignee Related' },
    { value: 'AK', label: 'Damaged, Rewrapped in Hub' },
    { value: 'AH', label: 'Driver Related' },
    { value: 'AV', label: 'Exceeds Service Limitations' },
    { value: 'AS', label: 'Hold Due to Customs Documentation Problems' },
    { value: 'AN', label: 'Holiday/Closed' },
    { value: 'AI', label: 'Mechanical Breakdown' },
    { value: 'AJ', label: 'Other Carrier Related' },
    { value: 'RC', label: 'Reconsigned' },
    { value: 'AM', label: 'Shipper Related' },
    { value: 'AO', label: 'Weather/Natural Disaster' },
  ];

  public constructor(private fb: FormBuilder, private datePipe: DatePipe) {
    this.stopForm = this.fb.group({ stops: this.fb.array([]) });
    this.inTransitForm = this.fb.group({
      stopNumber: null,
      location: null,
      statusTime: null,
      timeInput: null,
    });
  }

  ngOnDestroy() {
    if (this.stopFormValueChangesSub) {
      this.stopFormValueChangesSub.unsubscribe();
    }
  }
  buildStopTitle(stopTitle: string, stopDateTime: Date): string {
    return stopTitle + ' - ' + this.datePipe.transform(stopDateTime, 'short');
  }
  private getAvailableStops() {
    if (this.accepted && this.loadStatus) {
      return this.loadDetail.loadStops.filter(
        (stop) =>
          stop.stopNbr > this.loadStatus.stopNumber ||
          (stop.stopNbr === this.loadStatus.stopNumber && this.loadStatus.status !== LoadStatusTypes.Departed)
      );
    }
    return [];
  }

  private getAvailableInTransitStops() {
    const hasArrivedAtPickup =
      (this.accepted && !this.loadStatus) ||
      (this.loadStatus && this.loadStatus.status === LoadStatusTypes.Arrived && this.loadStatus.stopNumber === 1);
    const hasDepartedPickup =
      this.accepted &&
      this.loadStatus &&
      ((this.loadStatus.stopNumber === 1 && this.loadStatus.status === LoadStatusTypes.Departed) || this.loadStatus.stopNumber > 1);

    if (!hasArrivedAtPickup && !hasDepartedPickup) {
      // Only show the first stop (pick-up) In-Transit, so the carrier can signal they're in-transit to the pickup stop
      // Keep showing In-Transit to pickup, even if they're already in-transit to pickup, thus allowing multiple
      // In-Transit statuses to be entered for the pickup stop.
      if (this.loadDetail && this.loadDetail.loadStops) {
        return this.loadDetail.loadStops.filter((stop) => stop.stopNbr === 1);
      }
    } else if (hasDepartedPickup) {
      // Only show In-Transit Stops after the pickup stop
      if (this.loadDetail && this.loadDetail.loadStops) {
        return this.loadDetail.loadStops.filter(
          (stop) =>
            stop.stopNbr > this.loadStatus.stopNumber ||
            (stop.stopNbr === this.loadStatus.stopNumber && this.loadStatus.status === LoadStatusTypes.InTransit)
        );
      }
    }
    return [];
  }

  private buildStopForm() {
    if (this.stopFormValueChangesSub) {
      this.stopFormValueChangesSub.unsubscribe();
      this.stopFormValueChangesSub = null;
    }

    const stopGroups = this.getAvailableStops().map((_) => this.buildStopGroup(_));
    this.stopForm = this.fb.group({ stops: this.fb.array(stopGroups) });
    this.updateEnabledTimes();

    this.stopFormValueChangesSub = this.stopForm.valueChanges.subscribe(() => {
      this.updateEnabledTimes();
    });
  }

  private buildStopGroup(stop: LoadStop) {
    let loadStatusStopNumber = -1;
    let showStatusTimesControls = true;

    // For some locations / loads, auto accept statuses are sent on tender
    // these 'blank' or 'n\a' statuses should not be shown to the carrier and allow the user to enter stop statuses
    if (this.loadStatus && !isNaN(this.loadStatus.stopNumber) && !this.invalidStatusDescriptionRegex.test(this.loadStatus.description)) {
      loadStatusStopNumber = this.loadStatus.stopNumber;
    }

    if (this.loadStatus && this.loadStatus.status) {
      showStatusTimesControls = false;
    }

    const statusTimes = [];

    const isFirstStop = this.loadDetail.loadStops[0] === stop;
    const isLastStop = this.loadDetail.loadStops[this.loadDetail.loadStops.length - 1] === stop;

    let statusReasonDisabled = true;
    let defaultReason = this.stopStatusReasons.find((x) => x.value === 'NS')?.value;

    const stopLate = moment(stop.lateDtTm);
    let arrivalStatusEnabled = false;

    if (moment().isAfter(stopLate)) {
      statusReasonDisabled = false;
      defaultReason = null;
    }
    if (
      (showStatusTimesControls && stop.stopNbr > loadStatusStopNumber) ||
      (stop.stopNbr === this.loadStatus.stopNumber && this.loadStatus.status === LoadStatusTypes.InTransit)
    ) {
      statusTimes.push(
        this.fb.group({
          status: LoadStatusTypes.Appointment,
          statusTime: new FormControl({ value: null, disabled: !isFirstStop }),
          timeInput: new FormControl({ value: null, disabled: !isFirstStop }),
        })
      );
      statusTimes.push(
        this.fb.group({
          status: LoadStatusTypes.Estimate,
          statusTime: new FormControl({ value: null, disabled: !isFirstStop }),
          timeInput: new FormControl({ value: null, disabled: !isFirstStop }),
        })
      );
      statusTimes.push(
        this.fb.group({
          status: LoadStatusTypes.Arrived,
          statusTime: new FormControl({ value: null, disabled: !isFirstStop }),
          timeInput: new FormControl({ value: null, disabled: !isFirstStop }),
          statusReason: new FormControl({ value: defaultReason, disabled: statusReasonDisabled }),
        })
      );
      arrivalStatusEnabled = true;
    }
    if (
      stop.stopNbr > loadStatusStopNumber ||
      (stop.stopNbr === loadStatusStopNumber && this.loadStatus.status !== LoadStatusTypes.Departed)
    ) {
      if (!arrivalStatusEnabled) {
        statusTimes.push(
          this.fb.group({
            status: isLastStop ? LoadStatusTypes.Delivered : LoadStatusTypes.Departed,
            statusTime: new FormControl({ value: null, disabled: !isFirstStop || statusTimes.length > 0 }),
            timeInput: new FormControl({ value: null, disabled: !isFirstStop || statusTimes.length > 0 }),
            // if the arrival status is enabled, then the delivered status reason should be disabled
            statusReason: new FormControl({ value: defaultReason, disabled: statusReasonDisabled }),
          })
        );
      } else {
        statusTimes.push(
          this.fb.group({
            status: isLastStop ? LoadStatusTypes.Delivered : LoadStatusTypes.Departed,
            statusTime: new FormControl({ value: null, disabled: !isFirstStop || statusTimes.length > 0 }),
            timeInput: new FormControl({ value: null, disabled: !isFirstStop || statusTimes.length > 0 }),
          })
        );
      }
    }
    // ensure the values of the dropdown are properly set
    this.updateStopStatusReasonValues(statusReasonDisabled);

    return this.fb.group({
      stopTitle: isFirstStop ? 'Pickup Stop' : isLastStop ? 'Final Stop' : `Stop ${stop.stopNbr}`,
      stopDateTime: stop.lateDtTm,
      stopNumber: stop.stopNbr,
      statusTimes: this.fb.array(statusTimes),
    });
  }

  private updateEnabledTimes() {
    let enabled = true;
    (this.stopForm.controls['stops'] as FormArray).controls.forEach((stop: FormGroup, stopIndex: number) => {
      (stop.controls['statusTimes'] as FormArray).controls.forEach((statusTime: FormGroup) => {
        // capture if the statusReason is disabled
        let statusReasonDisabled = false;
        if (statusTime.controls['statusReason']) {
          statusReasonDisabled = statusTime.controls['statusReason'].disabled;
        }

        const isEstimateOrAppt =
          statusTime.controls['status'].value === LoadStatusTypes.Estimate ||
          statusTime.controls['status'].value === LoadStatusTypes.Appointment;
        if (isEstimateOrAppt) {
          // Always enable estimate or appt times, no matter what else has been entered
          statusTime.enable({ emitEvent: false });
        } else if (enabled) {
          statusTime.enable({ emitEvent: false });
        } else {
          statusTime.disable({ emitEvent: false });
          statusTime.patchValue({ statusTime: null }, { emitEvent: false });
          statusTime.patchValue({ timeInput: null }, { emitEvent: false });
        }
        if (statusTime.value.statusTime == null && !isEstimateOrAppt) {
          // Don't consider estimates or appts if the next time slot should be disabled
          enabled = false;
        }

        if (statusTime.controls['statusReason']) {
          // copy the initial value of the statusReason when the form was built
          if (statusReasonDisabled) {
            statusTime.controls['statusReason'].disable({ emitEvent: false });
          }

          // update the control if the user has inputted a time
          if (statusTime.value.statusTime !== null) {
            const userDateInput = statusTime.value.statusTime as Date;
            const timeInput = statusTime.value.timeInput;

            const parsedDateTime = this.validateTimeInput(timeInput);

            if (!parsedDateTime) {
              userDateInput.setHours(0, 0, 0);
            } else {
              userDateInput.setHours(parsedDateTime[0], parsedDateTime[1], 0);
            }
            const userInputMomemnt = moment(userDateInput);

            const stopLate = moment(this.getAvailableStops()[stopIndex].lateDtTm);
            if (userInputMomemnt.isSameOrBefore(stopLate)) {
              statusTime.controls['statusReason'].disable({ emitEvent: false });
              statusTime.controls['statusReason'].patchValue('NS', { emitEvent: false });
              this.updateStopStatusReasonValues(true);
            } else {
              statusTime.controls['statusReason'].enable({ emitEvent: false });

              // remove user input if the statusReason is set to NS
              if (statusTime.controls['statusReason'].value === 'NS') {
                statusTime.controls['statusReason'].patchValue(null, { emitEvent: false });
              }

              this.updateStopStatusReasonValues(false);
            }
          }
        }
      });
    });
  }

  private buildInTransitForm() {
    this.availableInTransitStops = this.getAvailableInTransitStops();
    if (this.availableInTransitStops.length > 0) {
      this.inTransitForm.patchValue({
        stopNumber: this.availableInTransitStops[0].stopNbr,
        location: null,
        statusTime: null,
        timeInput: null,
      });
    }
  }

  public submitStopStatuses() {
    const stopEvents = [];
    // get form raw value because the "reasonCode" can be disabled and not included in the form value
    const stops = this.stopForm.getRawValue().stops;

    let eventCounter = 0;
    for (let i = 0; i < stops.length; i++) {
      const stop = stops[i];
      for (let j = 0; j < (stop.statusTimes || []).length; j++) {
        const status = stop.statusTimes[j];
        if (status.statusTime != null) {
          const eventType =
            status.status === LoadStatusTypes.Arrived
              ? StopEventTypes.Arrival
              : status.status === LoadStatusTypes.Departed
              ? StopEventTypes.Departure
              : status.status === LoadStatusTypes.Delivered
              ? StopEventTypes.Actual
              : status.status === LoadStatusTypes.Estimate
              ? StopEventTypes.Estimate
              : status.status === LoadStatusTypes.Appointment
              ? StopEventTypes.Appointment
              : null;

          const statusDate = status.statusTime as Date;
          statusDate.setHours(0, 0, 0);

          if (status.timeInput) {
            const timeParts = status.timeInput.split(':');

            const parsedDateTime = this.validateTimeInput(status.timeInput);

            if (timeParts.length === 2) {
              if (!parsedDateTime) {
                const error = {};
                error[`urn:root:Events:${eventCounter}:EventTime`] = ['Time is invalid'];
                this.setErrors(error);
                return;
              }
              statusDate.setHours(parsedDateTime[0], parsedDateTime[1], 0);
            }
          }

          let reasonCode = status.statusReason;

          if (status.status === LoadStatusTypes.Departed || status.status === LoadStatusTypes.Delivered) {
            // check if the user inputted the arrival step  and if so use the reason code from that
            const arrivalStatus = stop.statusTimes.find((x) => x.status === LoadStatusTypes.Arrived);

            if (arrivalStatus && arrivalStatus.statusReason) {
              reasonCode = arrivalStatus.statusReason;
            }
          }

          stopEvents.push({
            stopNumber: stop.stopNumber,
            eventType: eventType,
            eventTime: moment(statusDate).milliseconds(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
            reasonCode: reasonCode,
          } as LoadStatusStopEventData);
        }

        eventCounter++;
      }
    }

    const stopStatuses = {
      loadId: this._loadDetail.loadId,
      events: stopEvents,
    } as LoadStatusStopData;

    this.saveStopStatuses.emit(stopStatuses);
  }

  public submitInTransit() {
    let statusDate = null;
    if (this.inTransitForm.value.statusTime) {
      statusDate = this.inTransitForm.value.statusTime as Date;
      statusDate.setHours(0, 0, 0);

      if (this.inTransitForm.value.timeInput) {
        const timeParts = this.inTransitForm.value.timeInput.split(':');

        const parsedDateTime = this.validateTimeInput(this.inTransitForm.value.timeInput);

        if (timeParts.length === 2) {
          if (!parsedDateTime) {
            this.setErrors({
              'urn:root:LocationTime': ['Time is invalid'],
            });
            return;
          }
          statusDate.setHours(parsedDateTime[0], parsedDateTime[1], 0);
        }
      }
    }

    const inTransitStatus = {
      loadId: this._loadDetail.loadId,
      locationTime: statusDate ? moment(statusDate).milliseconds(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss') : null,
      latitude: this.inTransitForm.value.location ? this.inTransitForm.value.location.latitude : null,
      longitude: this.inTransitForm.value.location ? this.inTransitForm.value.location.longitude : null,
    } as LoadStatusInTransitData;

    this.saveInTransitStatus.emit(inTransitStatus);
  }

  private setErrors(errors: object) {
    const urnRoot = 'urn:root';

    const messages = this.setFormGroupErrors(this.inTransitForm, urnRoot, this.inTransitMap, errors);
    this.inTransitErrorSummary = messages ? messages.join('\n') : '';
    this.inTransitErrorCount = messages ? messages.length : 0;

    this.setStopErrors(urnRoot, errors);
  }

  private setFormGroupErrors(
    formObject: FormGroup | FormArray,
    urnPrefix: string,
    errorMap: { urn: string; formControlName: string }[],
    errors
  ): string[] {
    const errorList: string[] = [];
    formObject.setErrors(null);
    Object.keys(formObject.controls).forEach((key) => {
      formObject.controls[key].setErrors(null);
    });

    for (const entry of errorMap) {
      const currentUrn = urnPrefix + (entry.urn && entry.urn.length ? ':' + entry.urn : '');
      const name = entry.formControlName;
      const controlErrors = errors ? errors[currentUrn] : null;

      // Track the full list of errors
      if (controlErrors) {
        for (const error of controlErrors) {
          if (error) {
            errorList.push(error.trim());
          }
        }

        if (name != null) {
          if (name.length === 0) {
            formObject.setErrors(controlErrors);
          } else {
            formObject.get(name).setErrors(controlErrors);
          }
        }
      }
    }
    return errorList;
  }

  private setStopErrors(urnRoot: string, errors: object) {
    let eventIndex = 0;
    const stops = this.stopForm.controls['stops'] as FormArray;

    let messages: string[] = [];

    for (let i = 0; i < stops.length; i++) {
      const stopGroup = stops.controls[i] as FormGroup;
      const statusTimes = stopGroup.controls['statusTimes'] as FormArray;
      for (let j = 0; j < statusTimes.length; j++) {
        const eventUrnPrefix = `${urnRoot}:Events:${eventIndex}`;
        const groupMessages = this.setFormGroupErrors(statusTimes.controls[j] as FormGroup, eventUrnPrefix, this.stopStatusTimeMap, errors);
        messages = messages.concat(groupMessages);
        eventIndex++;
      }
    }

    this.stopStatusesErrorSummary = messages ? messages.join('\n') : '';
    this.stopStatusesErrorCount = messages ? messages.length : 0;
  }

  sendStopOnCalendarFocus(event, stopIndex, timeIndex) {
    const stops = this.stopForm.controls['stops'] as FormArray;

    if (stops && stops.length >= stopIndex) {
      const stopGroup = stops.controls[stopIndex] as FormGroup;
      const statusTimes = stopGroup.controls['statusTimes'] as FormArray;

      if (statusTimes && statusTimes.length >= timeIndex) {
        const statusTime = statusTimes.controls[timeIndex] as FormGroup;
        if (statusTime.value.statusTime == null) {
          statusTime.patchValue({ statusTime: new Date() }, { emitEvent: false });
          this.updateEnabledTimes();
        }
      }
    }
  }

  inTransitOnCalendarFocus() {
    const form = this.inTransitForm as FormGroup;
    if (form.value.statusTime == null) {
      form.patchValue({ statusTime: new Date() }, { emitEvent: false });
    }
  }

  /*
   ** Updates the status reason value to be enabled / disabled depending on if the
   ** status time is before or after the stop late date time
   */
  private updateStopStatusReasonValues(enabled: boolean) {
    this.stopStatusReasons[0].disabled = !enabled;
  }

  private validateTimeInput(timeInput: string): number[] {
    if (!timeInput) {
      return null;
    }

    const timeParts = timeInput.split(':');
    if (timeParts.length === 2) {
      const hours = parseInt(timeParts[0], 10),
        minutes = parseInt(timeParts[1], 10);
      if (isNaN(hours) || isNaN(minutes)) {
        return null;
      }
      if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
        return null;
      }

      return [hours, minutes];
    }
  }
}
