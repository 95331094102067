<kbxl-admin-header></kbxl-admin-header>
<div class="pt-2 pl-4 pr-4 pb-2">
  <kbxl-lane-group-detail
    [group]="group$ | async"
    [states]="states$ | async"
    [processing]="processing$ | async"
    [errors]="errors$ | async"
    [allCarriers]="allCarriers$ | async"
    [groupedEquipment]="equipment$ | async | toTreeNodes"
    [loadCarrierGroupTypes]="loadCarrierGroupTypes$ | async"
    [allCustomerServiceTypes]="customerServiceTypes$ | async"
    [percentOptions]="percentOptions$ | async"
    [frequencyOptions]="frequencyOptions$ | async"
    [intervalOptions]="intervalOptions$ | async"
    (saveClick)="save($event)"
    (deleteClick)="delete($event)"
    (cancelClick)="cancel($event)"
  >
  </kbxl-lane-group-detail>
</div>
