import { Component } from '@angular/core';

@Component({
  template: `
    <div>
      Your company is not fully set up to ‘Book Loads’ on loadshop&trade;. Please contact KBXL at the below contact info to resolve.
      <div><a href="mailto:KBXLOpportunities@kbxlogistics.com">KBXLOpportunities@kbxlogistics.com</a></div>
    </div>
  `,
})
export class NoCarrierContainerComponent {}
