export interface NotificationContact {
  name: string;
  to: string;
  contactType: NotificationContactType;
}

export enum NotificationContactType {
  email = 'email',
  sms = 'sms',
}

export const defaultEmailContact: NotificationContact = {
  name: null,
  to: null,
  contactType: NotificationContactType.email,
};

export const defaultSmsContact: NotificationContact = {
  name: null,
  to: null,
  contactType: NotificationContactType.sms,
};
