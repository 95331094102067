export class MileageRequest {
  originCity: string;
  originState: string;
  originPostalCode: string;
  originCountry: string;
  destinationCity: string;
  destinationState: string;
  destinationPostalCode: string;
  destinationCountry: string;
}
