import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CoreState, getLoadshopSettings } from 'src/app/core/store';
import { LoadshopSetting } from '../../models';

export enum FeatureEnforcementType {
  disable = 'disable',
  hide = 'hide',
}

@Directive({
  selector: '[kbxlFeature]',
  exportAs: 'kbxlFeature',
})
export class FeatureCheckDirective implements OnDestroy, OnInit {
  @Input() enforcementType = 'hide';
  @Input() kbxlFeature: string;
  @Output() isDisabledChange = new EventEmitter<boolean>();

  loadshopSettingSub: Subscription;
  loadshopSettings: LoadshopSetting[];
  hasInit = false;
  isDisabled = false;
  featurePrefix = 'feature_';

  constructor(private el: ElementRef, coreState: Store<CoreState>) {
    this.loadshopSettingSub = coreState.pipe(select(getLoadshopSettings)).subscribe((settings) => {
      this.loadshopSettings = settings;
      if (this.hasInit) {
        this.checkFeature();
      }
    });
  }

  ngOnInit(): void {
    this.hasInit = true;
    this.checkFeature();
  }

  private checkFeature() {
    if (this.loadshopSettings && this.kbxlFeature) {
      const feature = this.featurePrefix + this.kbxlFeature.toLocaleLowerCase();
      const featureFlag = this.loadshopSettings.find((setting) => setting.key.toLocaleLowerCase() === feature);

      if (featureFlag) {
        const featureOn = featureFlag.value === 'true';
        if (!featureOn) {
          this.hideFeature();
        } else {
          this.showElement();
        }
      } else {
        this.hideFeature();
      }
    }
  }

  private hideFeature() {
    const et = FeatureEnforcementType[this.enforcementType];
    this.showElement();

    switch (et) {
      case FeatureEnforcementType.hide:
        this.el.nativeElement.classList.add('feature-disabled');
        this.el.nativeElement.style.display = 'none !important';
        break;
      case FeatureEnforcementType.disable:
        this.el.nativeElement.setAttribute('disabled', true);
        break;
    }

    this.isDisabled = true;
    this.isDisabledChange.emit(true);
  }

  private showElement() {
    this.el.nativeElement.classList.remove('feature-disabled');
    this.el.nativeElement.style.display = null;
    this.el.nativeElement.removeAttribute('disabled');
    this.isDisabled = false;
    this.isDisabledChange.emit(false);
  }

  ngOnDestroy(): void {
    this.loadshopSettingSub.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    if (this.isDisabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
