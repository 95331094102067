import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { LoadBoardService } from '../../../load-board/services';
import { LoadBoardLoadDetailActionTypes, LoadBoardLoadDetailClosed } from '../../../shared/store';
import { ChatActiveLoadsSelectAction } from '../actions';
import { ChatState } from '../reducers';
import { getSelectedLoadId } from '../selectors';

@Injectable()
export class ChatActiveLoadsEffects {
  @Effect()
  $deselectLoadAfterClose: Observable<Action> = this.actions$.pipe(
    ofType<LoadBoardLoadDetailClosed>(LoadBoardLoadDetailActionTypes.Load_Detail_Closed),
    withLatestFrom(this.store$.pipe(select(getSelectedLoadId))),
    switchMap(([action, selectedLoadId]) => {
      if (selectedLoadId === action.loadId) {
        return of(new ChatActiveLoadsSelectAction(null));
      } else {
        // action fired a different load id that what was selected on chat, ignore
        return EMPTY;
      }
    })
  );

  constructor(private actions$: Actions, private store$: Store<ChatState>, private loadBoardService: LoadBoardService) {}
}
