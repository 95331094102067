import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { TabMenu } from 'primeng/tabmenu';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SecurityAppActionType } from 'src/app/shared/models/security-app-action-type';
import { getActionItemMenuCounts, SharedState } from 'src/app/shared/store';
import { getUserProfileModel, UserState } from '../../../../user/store';
import { UserModel } from '../../../models';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'kbxl-action-items-container',
  templateUrl: './action-items-container.component.html',
  styleUrls: ['./action-items-container.component.scss'],
})
export class ActionItemsContainerComponent extends BaseComponent implements OnInit {
  @ViewChild(TabMenu, { static: true }) menu: TabMenu;
  lastActiveItem: MenuItem;
  menuItems: MenuItem[];
  activeRoute$: Observable<string>;
  user: UserModel;
  constructor(
    private store: Store<SharedState>,
    private userStore: Store<UserState>,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    super();
    //
    // HACK: https://github.com/primefaces/primeng/issues/4197
    // Because the guard on any route can cancel the navigation, the active nav item might show the current
    // and selected one, even though the guard issued a cancel
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.lastActiveItem = this.menu.activeItem;
      }
      if (e instanceof NavigationCancel) {
        this.menu.activeItem = this.lastActiveItem;
      }
    });
  }

  ngOnInit(): void {
    this.userStore.pipe(select(getUserProfileModel), takeUntil(this.destroyed$)).subscribe((user) => {
      this.menuItems = [
        {
          label: 'Accessorials',
          routerLink: 'accessorials',
          badgeStyleClass: 'badge badge-danger',
        },
      ];
      if (user) {
        this.user = user;

        if (
          (this.user.isCarrier && this.user.hasSecurityAction(SecurityAppActionType.CarrierCounterOfferRequest)) ||
          (this.user.isShipper &&
            this.user.features.isCounterOfferEnabled &&
            this.user.hasSecurityAction(SecurityAppActionType.ShipperCounterOfferAccept))
        ) {
          this.menuItems.push({
            label: 'Counter Offers',
            routerLink: 'counter-offers',
            badgeStyleClass: 'badge badge-danger',
          });

          this.cdr.detectChanges();
        }
      }

      this.store.pipe(select(getActionItemMenuCounts), takeUntil(this.destroyed$)).subscribe((menuItems) => {
        menuItems.forEach((item) => {
          if (item) {
            let badgeIndicator = null;
            if (item.count > 0) {
              badgeIndicator = item.count.toString();
            }

            this.updateMenuItemBadge(item.actionItemName, badgeIndicator);
          }
        });
      });
    });
  }

  updateMenuItemBadge(menuLabel: string, badge: string) {
    const index = this.menuItems.findIndex((x) => x.label === menuLabel);
    if (index >= 0 && index < this.menuItems.length) {
      if (badge && badge !== '') {
        this.menuItems[index].badge = badge;
      } else {
        this.menuItems[index].badge = null;
      }
    }
  }
}
