import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services';
import {
  CoreState,
  SmartSpotCreateOrderFromQuoteAction,
  SmartSpotPriceLoadQuoteSuccessAction,
  getAppointmentSchedulerConfirmationTypes,
  getCommodities,
  getEquipment,
  getLoadshopSettings,
  getServiceTypes,
  getSmartSpotQuickQuoteErrors,
  getStates,
} from 'src/app/core/store';
import {
  AppointmentSchedulerConfirmationType,
  Commodity,
  Equipment,
  ServiceType,
  SmartSpotQuoteCreateRequest,
  SpotPriceQuickQuoteRequest,
  SpotPriceQuoteResponse,
  State,
  User,
  ValidationProblemDetails,
} from 'src/app/shared/models';
import { UserState, getUserProfileEntity } from 'src/app/user/store';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'kbxl-quick-quote-modal',
  templateUrl: './quick-quote-modal.component.html',
  styleUrls: ['./quick-quote-modal.component.scss'],
})
export class QuickQuoteModalComponent extends BaseComponent implements OnInit {
  @Input() public visible: boolean;
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public states$: Observable<State[]>;
  public equipment$: Observable<Equipment[]>;
  public loading$: BehaviorSubject<boolean>;
  public quoteValue$: BehaviorSubject<SpotPriceQuoteResponse>;
  public quoteErrors$: Observable<ValidationProblemDetails>;
  public userProfile$: Observable<User>;
  public commodities$: Observable<Commodity[]>;
  public serviceTypes$: Observable<ServiceType[]>;
  public schedulerConfirmationTypes$: Observable<AppointmentSchedulerConfirmationType[]>;
  showSpotQuoteModal = false;

  constructor(
    private store: Store<CoreState>,
    private userStore: Store<UserState>,
    private coreStore: Store<CoreState>,
    private _commonService: CommonService,
    private userState: Store<UserState>
  ) {
    super();
    //
    // TODO: remove this once the new spot quote is available for everyone
    //
    combineLatest([coreStore.pipe(select(getLoadshopSettings)), userState.pipe(select(getUserProfileEntity))])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([loadshopSettings, userProfile]) => {
        if (loadshopSettings) {
          const featureFlag = loadshopSettings.find((setting) => setting.key.toLocaleLowerCase() === 'feature_spotpricev2');

          if (featureFlag) {
            const featureOn = featureFlag.value === 'true';
            if (featureOn && userProfile && userProfile.securityAccessRoles) {
              const securityRoles = ['System Admin', 'LS Admin'];
              this.showSpotQuoteModal = userProfile.securityAccessRoles.filter((x) => securityRoles.includes(x.accessRoleName)).length > 0;
            }
          }
        }
      });
  }

  ngOnInit() {
    this.states$ = this.store.pipe(select(getStates));
    this.equipment$ = this.store.pipe(select(getEquipment));
    this.loading$ = new BehaviorSubject<boolean>(false);
    // this.store.pipe(select(getSmartSpotLoadingQuote));
    this.quoteValue$ = new BehaviorSubject<SpotPriceQuoteResponse>(null);
    // this.store.pipe(select(getSmartSpotQuickQuoteValue));
    this.quoteErrors$ = this.store.pipe(select(getSmartSpotQuickQuoteErrors));
    this.userProfile$ = this.userStore.pipe(select(getUserProfileEntity));
    this.commodities$ = this.store.pipe(select(getCommodities));
    this.serviceTypes$ = this.store.pipe(select(getServiceTypes));
    this.schedulerConfirmationTypes$ = this.store.pipe(select(getAppointmentSchedulerConfirmationTypes));
  }

  onHide() {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  clear() {
    this.store.dispatch(new SmartSpotPriceLoadQuoteSuccessAction(null));
  }

  getQuote(request: SpotPriceQuickQuoteRequest) {
    this.loading$.next(true);
    this._commonService.getQuickSpotQuote(request).subscribe(
      (data: SpotPriceQuoteResponse) => {
        this.loading$.next(false);
        this.quoteValue$.next(data);
      },
      (err) => {
        this.loading$.next(false);
        console.log(err);
      }
    );
    // this.store.dispatch(new SmartSpotPriceLoadQuoteAction(request));
  }

  createOrder(request: SmartSpotQuoteCreateRequest) {
    this.visible = false;
    this.visibleChange.emit(false);
    this.store.dispatch(new SmartSpotCreateOrderFromQuoteAction(request));
  }
}
