import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../shared/components';
import { UserAdminData } from '../../../../shared/models';

@Component({
  selector: 'kbxl-carrier-users',
  templateUrl: './carrier-users.component.html',
  styleUrls: ['./carrier-users.component.scss'],
})
export class CarrierUsersComponent extends BaseComponent implements OnInit {
  @Input() public loadingCarrierUsers: boolean;
  @Input() public carrierUsers: UserAdminData[];
  selectedCarrier: string;
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
