<div *ngIf="spotPriceQuote">
  <div class="mb-2">Click on a rate outlined below to override the Total Rate on the Post tab</div>
  <div class="d-flex align-items-start">
    <div class="d-flex flex-column text-center mr-4 mt-3">
      <div class="algorithm my-2">DAT Calculated Rate</div>
      <div class="price price-button" (click)="close(spotPriceQuote.loadshopDatGuardRate)">
        {{ spotPriceQuote.loadshopDatGuardRate | currency }}
      </div>
      <div class="">
        <small>(current <label class="m-0" [innerHtml]="smartSpotLabel"></label>)</small>
      </div>
    </div>
    <!-- In case we want a side by side of Legacy vs Platform rates -->
    <!-- <div class="d-flex flex-column text-center mr-4 mt-3">
      <div class="algorithm my-2">Platform DAT Rate</div>
      <div class="price price-button" (click)="close(spotPriceQuote.platformDatGuardRate)">
        {{ spotPriceQuote.platformDatGuardRate | currency }}
      </div>
      <div class="">
        <small [class.positive]="platformDiff > 0" [class.negative]="platformDiff < 0">{{ platformDiff | currency }}</small>
      </div>
    </div> -->
    <div class="d-flex flex-column text-center new-spotprice-card" *ngIf="spotPriceQuote.spotPrice">
      <div class="algorithm my-2"><label class="m-0" [innerHtml]="smartSpotLabel"></label></div>
      <div class="price d-flex align-items-center justify-content-between">
        <div class="price-small price-button" (click)="close(spotPriceQuote.spotPrice.estimate.low + fuel)">
          <div class="d-flex flex-column">
            <div>
              {{ spotPriceQuote.spotPrice.estimate.low + fuel | currency }}
            </div>
            <small>Low</small>
          </div>
        </div>
        <div class="mx-3 price-button" (click)="close(spotPriceQuote.spotPrice.estimate.point + fuel)">
          <div class="d-flex flex-column">
            <div>
              {{ spotPriceQuote.spotPrice.estimate.point + fuel | currency }}
            </div>
            <small>Mid</small>
          </div>
        </div>
        <div class="price-small price-button" (click)="close(spotPriceQuote.spotPrice.estimate.high + fuel)">
          <div class="d-flex flex-column">
            <div>
              {{ spotPriceQuote.spotPrice.estimate.high + fuel | currency }}
            </div>
            <small>High</small>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex align-items-center justify-content-between mt-1">
          <small class="" [class.positive]="i360LowDiff > 0" [class.negative]="i360LowDiff < 0">{{ i360LowDiff | currency }}</small>
          <small class="mx-3" [class.positive]="i360PointDiff > 0" [class.negative]="i360PointDiff < 0">{{
            i360PointDiff | currency
          }}</small>
          <small class="" [class.positive]="i360HighDiff > 0" [class.negative]="i360HighDiff < 0">{{ i360HighDiff | currency }}</small>
        </div>
      </div>
      <div class="mt-4">
        <h6>Factors Impacting Base Rate</h6>
        <div class="warning-container m-2" *ngIf="!fuel || fuel === 0">No fuel is included</div>
        <table class="pricing-table">
          <tbody>
            <tr *ngIf="fuel && fuel > 0">
              <td>Fuel</td>
              <td>{{ fuel | currency }}</td>
            </tr>
            <tr *ngFor="let header of getVariableImportanceKeys()" class="no-border">
              <td>
                <div class="mr-2">
                  {{ header }}
                </div>
              </td>
              <td>{{ getVariableImportanceValue(header) | currency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
