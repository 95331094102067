export class ChatNotification {
  constructor(init?: Partial<ChatNotification>) {
    Object.assign(this, init);
  }

  notificationEntityId: string;
  notificationEntityName: string;
  notificationsByConversation: ChatConversationNotification[];
  contextEntityId: string;
  contextEntityName: string;

  get notificationCount(): number {
    if (!this.notificationsByConversation || this.notificationsByConversation.length === 0) {
      return 0;
    }

    const count = this.notificationsByConversation.map((x) => x.count).reduce((a, b) => a + b);

    return count;
  }

  updateNotificationCount(conversationId: number, count: number): void {
    const notification = this.notificationsByConversation.find((x) => x.conversationId === conversationId);

    if (!notification) {
      this.notificationsByConversation.push({
        conversationId: conversationId,
        count: count,
      });
    } else {
      notification.count += count;
    }
  }
}

export interface ChatConversationNotification {
  conversationId: number;
  conversationAttributes?: { [key: string]: Array<string>; };
  count: number;
}
