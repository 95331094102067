<div class="p-inputgroup">
  <p-autoComplete
    id="value"
    appendTo="body"
    [(ngModel)]="place"
    [suggestions]="placeSuggestions"
    (completeMethod)="citySearch($event)"
    (onSelect)="populateValue(place)"
    [placeholder]="placeholder"
    [forceSelection]="true"
    field="description"
    baseZIndex="10000"
  ></p-autoComplete>
  <button pButton icon="pi pi-times-circle" class="p-button-secondary p-inputgroup-addon" (click)="clearValue()"></button>
</div>
