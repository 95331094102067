export interface CustomerLaneData {
  customerLaneId: number;
  customerLaneGroupId: number;
  origAddr1: string;
  origCity: string;
  origState: string;
  origZip: string;
  origCountry: string;
  destAddr1: string;
  destCity: string;
  destState: string;
  destZip: string;
  destCountry: string;
  equipmentIds: string[];
  allOrigins: boolean;
  allDestinations: boolean;
  origLat?: number;
  origLng?: number;
  origRadius?: number;
  destLat?: number;
  destLng?: number;
  destRadius?: number;
}

export const defaultCustomerLaneData: CustomerLaneData = {
  customerLaneId: 0,
  customerLaneGroupId: 0,
  origAddr1: '',
  origCity: '',
  origState: '',
  origZip: '',
  origCountry: '',
  destAddr1: '',
  destCity: '',
  destState: '',
  destZip: '',
  destCountry: '',
  equipmentIds: [],
  allOrigins: false,
  allDestinations: false,
  origLat: null,
  origLng: null,
  origRadius: 0,
  destLat: null,
  destLng: null,
  destRadius: 0,
};
