import { Injectable, OnDestroy } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';

@Injectable()
export abstract class BaseComponent implements OnDestroy {
  destroyed$ = new Subject<boolean>();
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  deepClone(_: any): any {
    if (!_) {
      return _;
    }

    return cloneDeep(_);
  }
}
