export abstract class ChatAttributes {
  public static userMessageEntityId = 'urn:message:userEntityId';

  public static dmShipper = 'urn:dm:shipper';
  public static shipperName = 'urn:shipperName';
  public static dmShipperUser = 'urn:dm:shipperUser';

  public static dmCarrier = 'urn:dm:carrier';
  public static carrierName = 'urn:carrierName';
  public static dmCarrierUser = 'urn:dm:carrierUser';

  public static dmGroup = 'urn:group:dm';
}

export abstract class ChatConstants {
  public static nbrOfMessagesToRetrieve = 50;
}
