import { AfterViewInit, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CoreState, BrowserSetIsInternetExplorerAction } from '../../store';

@Component({
  selector: 'kbxl-ie-detection',
  template: '<div class="d-block d-lg-none"></div>',
})
export class IEDetectionComponent implements AfterViewInit {
  constructor(private store: Store<CoreState>) {}

  ngAfterViewInit() {
    this.detectInternetExplorer();
  }

  private detectInternetExplorer() {
    const isIE11OrEarlier = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
    this.store.dispatch(new BrowserSetIsInternetExplorerAction({ isInternetExplorer: isIE11OrEarlier }));
  }
}
