import { CustomerLaneGroupShipperData } from './customer-lane-group-shipper-data';
import { LaneHistoryData } from './lane-history-data';
import { LoadCarrierScacData } from './load-carrier-scac-data';
import { LoadCarrierScacRestrictionData } from './load-carrier-scac-restriction-data';

export interface ShippingLoadSupplementalData {
  carrierGroups: CustomerLaneGroupShipperData[];
  isLoadCarrierScacPrefetchProcessing: boolean;
  carrierScacs: LoadCarrierScacData[];
  carrierScacRestrictions: LoadCarrierScacRestrictionData[];
  laneHistory: LaneHistoryData[];
  latestTransactionTypeId: string;
}

export const defaultShippingLoadSupplementalData: ShippingLoadSupplementalData = {
  carrierGroups: [],
  isLoadCarrierScacPrefetchProcessing: false,
  carrierScacs: [],
  carrierScacRestrictions: [],
  laneHistory: [],
  latestTransactionTypeId: null,
};
