<div class="progress-indicator" *ngIf="loading">
  <div class="progressspinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div class="d-flex flex-column">
  <div class="alert alert-warning">
    <strong>Warning</strong> You are accessing an administrative function to impersonate a user. All actions will be recorded as the
    impersonating user. Please be careful :)
  </div>
  <div class="kbxl-inputgroup-container">
    <div for="Quantity" class="kbxl-field-label">
      <label for="Quantity" class="control-label">User Search</label>
    </div>
    <p-autoComplete
      [(ngModel)]="selectedUser"
      [suggestions]="userResults"
      (completeMethod)="searchUsers($event)"
      field="username"
      placeholder="Search for User"
    >
      <ng-template let-item pTemplate="item">
        <span
          ><b>{{ item.username }}</b
          >-{{ item.firstName | titlecase }} {{ item.lastName | titlecase }}</span
        >
      </ng-template>
    </p-autoComplete>
    <div *ngIf="selectedUser && selectedUser?.userId?.length > 0" class="mt-4 selectedUser">
      <h6 class="d-inline-block">{{ selectedUser.firstName | titlecase }} {{ selectedUser.lastName | titlecase }}</h6>
      <div class="d-flex">
        <div class="d-flex flex-column">
          <span class="load-detail-label">User Name:</span>
          <span class="load-detail-label">Created:</span>
          <span class="load-detail-label" *ngIf="selectedUser.primaryScac && selectedUser.primaryScac.length > 0">Primary SCAC:</span>
        </div>
        <div class="d-flex flex-column">
          <div class="load-detail-value">{{ selectedUser.username | uppercase }}</div>
          <div class="load-detail-value">{{ selectedUser.createDtTm | date: 'shortDate' }}</div>
          <div class="load-detail-value" *ngIf="selectedUser.primaryScac && selectedUser.primaryScac.length > 0">
            {{ selectedUser.primaryScac }}
          </div>
        </div>
        <button
          pButton
          type="button"
          class="p-button-teal ml-auto"
          label="Impersonate"
          (click)="impersonate()"
          [disabled]="loading"
        ></button>
      </div>
    </div>
  </div>
  <div class="d-flex mb-2">
    <button pButton type="button" class="p-button-outlined p-button-secondary ml-auto" label="Close" (click)="close()"></button>
  </div>
</div>
