import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { mapToPayload } from '@tms-ng/shared';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoadStatusInTransitData, LoadStatusStopData } from 'src/app/shared/models';
import { LoadStatusService } from '../../services';
import {
  LoadshopLoadStatusLoadFailureAction,
  LoadshopLoadStatusLoadSuccessAction,
  LoadshopLoadStatusSaveFailureAction,
  LoadshopLoadStatusSaveSuccessAction,
  LoadStatusActionTypes,
  Tops2GoLoadStatusLoadFailureAction,
  Tops2GoLoadStatusLoadSuccessAction,
} from '../actions';

@Injectable()
export class LoadStatusEffects {
  @Effect()
  $loadT2GStatus: Observable<Action> = this.actions$.pipe(
    ofType(LoadStatusActionTypes.Load),
    mapToPayload<{ loadId: string; referenceLoadId: string }>(),
    switchMap((payload: { loadId: string; referenceLoadId: string }) =>
      this.loadStatusService.getLatestTopsToGoLoadStatus(payload.referenceLoadId).pipe(
        map((data) => new Tops2GoLoadStatusLoadSuccessAction(data)),
        catchError((err) => of(new Tops2GoLoadStatusLoadFailureAction(err)))
      )
    )
  );

  @Effect()
  $loadLoadshopStatus: Observable<Action> = this.actions$.pipe(
    ofType(LoadStatusActionTypes.Load),
    mapToPayload<{ loadId: string; referenceLoadId: string }>(),
    switchMap((payload: { loadId: string; referenceLoadId: string }) =>
      this.loadStatusService.getLatestLoadshopLoadStatus(payload.loadId).pipe(
        map((data) => new LoadshopLoadStatusLoadSuccessAction(data)),
        catchError((err) => of(new LoadshopLoadStatusLoadFailureAction(err)))
      )
    )
  );

  @Effect()
  $saveInTransit: Observable<Action> = this.actions$.pipe(
    ofType(LoadStatusActionTypes.LoadshopInTransitStatusSave),
    mapToPayload<LoadStatusInTransitData>(),
    switchMap((payload: LoadStatusInTransitData) =>
      this.loadStatusService.saveInTransit(payload).pipe(
        map((data) => new LoadshopLoadStatusSaveSuccessAction(data)),
        catchError((err) => {
          if (err.error && err.error.errors) {
            this.messageService.add({ summary: err.error.title, detail: err.error.detail, severity: 'error' });
          }
          return of(new LoadshopLoadStatusSaveFailureAction(err));
        })
      )
    )
  );

  @Effect()
  $saveStopStatus: Observable<Action> = this.actions$.pipe(
    ofType(LoadStatusActionTypes.LoadshopStopStatusSave),
    mapToPayload<LoadStatusStopData>(),
    switchMap((payload: LoadStatusStopData) =>
      this.loadStatusService.saveStopData(payload).pipe(
        map((data) => new LoadshopLoadStatusSaveSuccessAction(data)),
        catchError((err) => {
          if (err.error && err.error.errors) {
            this.messageService.add({ summary: err.error.title, detail: err.error.detail, severity: 'error' });
          }
          return of(new LoadshopLoadStatusSaveFailureAction(err));
        })
      )
    )
  );

  constructor(private loadStatusService: LoadStatusService, private actions$: Actions, private messageService: MessageService) {}
}
