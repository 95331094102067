import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NoteData } from '../models/note-data';
import { environment } from '../../../environments/environment';
import { ServiceResponse } from '../models';
import { mapResponse } from '../operators/map-response';

@Injectable()
export class NoteService {
  constructor(private http: HttpClient) {}

  createNote(payload: NoteData): Observable<NoteData> {
    const url = `${environment.apiUrl}/api/Note`;
    return this.http.post<ServiceResponse<NoteData>>(url, payload).pipe(mapResponse());
  }

  getNotes(loadId: string): Observable<NoteData[]> {
    const url = `${environment.apiUrl}/api/Note/${loadId}`;
    return this.http.get<ServiceResponse<NoteData[]>>(url).pipe(mapResponse());
  }
}
