import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ServiceResponse } from '../models';
import { mapResponse } from '../operators/map-response';
import { SendLinkRequest } from '../models/send-link-request';

@Injectable()
export class ShareLinkService {
  constructor(private http: HttpClient) {}

  sendLinkRequest(sendLinkRequest: SendLinkRequest): Observable<boolean> {
    return this.http.post<ServiceResponse<boolean>>(`${environment.apiUrl}/api/loads/ShareLink`, sendLinkRequest).pipe(mapResponse());
  }
}
