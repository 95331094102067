import { LoadStatusTransaction, T2GLoadStatus, ValidationProblemDetails } from 'src/app/shared/models';
import { createReducer } from '../../../shared/utilities';
import { LoadStatusActions, LoadStatusActionTypes } from '../actions';

export interface LoadStatusState {
  loadingCount: number;
  tops2GoStatus: T2GLoadStatus;
  loadshopStatus: LoadStatusTransaction;
  saving: boolean;
  problemDetails: ValidationProblemDetails;
}

const initialState: LoadStatusState = {
  loadingCount: 0,
  tops2GoStatus: null,
  loadshopStatus: null,
  saving: false,
  problemDetails: null,
};

const _loadStatusReducer = createReducer(
  initialState,
  function (state: LoadStatusState = initialState, action: LoadStatusActions): LoadStatusState {
    switch (action.type) {
      case LoadStatusActionTypes.Load: {
        return { ...state, tops2GoStatus: null, loadshopStatus: null, loadingCount: 2, problemDetails: null };
      }
      case LoadStatusActionTypes.Tops2GoStatusLoadSuccess: {
        return { ...state, tops2GoStatus: action.payload, loadingCount: state.loadingCount - 1 };
      }
      case LoadStatusActionTypes.Tops2GoStatusLoadFailure: {
        return { ...state, loadingCount: state.loadingCount - 1 };
      }
      case LoadStatusActionTypes.LoadshopStatusLoadSuccess: {
        return { ...state, loadshopStatus: action.payload, loadingCount: state.loadingCount - 1 };
      }
      case LoadStatusActionTypes.LoadshopStatusLoadFailure: {
        return { ...state, loadingCount: state.loadingCount - 1 };
      }
      case LoadStatusActionTypes.LoadshopInTransitStatusSave:
      case LoadStatusActionTypes.LoadshopStopStatusSave: {
        return { ...state, saving: true, problemDetails: null };
      }
      case LoadStatusActionTypes.LoadshopLoadStatusSaveSuccess: {
        return { ...state, saving: false, loadshopStatus: action.payload, problemDetails: null };
      }
      case LoadStatusActionTypes.LoadshopLoadStatusSaveFailure: {
        return { ...state, saving: false, problemDetails: action.payload.error };
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function loadStatusReducer(state: LoadStatusState, action: LoadStatusActions): LoadStatusState {
  return _loadStatusReducer(state, action);
}
export const getLoading = (state: LoadStatusState) => state.loadingCount > 0;
export const getSaving = (state: LoadStatusState) => state.saving;
