export enum SecurityAppActionType {
  // Admin Section
  ShipperAdminTabVisible = 'loadshop.ui.admin.tab.shippers.visible',
  CarrierAdminTabVisible = 'loadshop.ui.admin.tab.carriers.visible',
  ShipperCarrierGroupsAddEdit = 'loadshop.ui.system.shipper.carriergroups.addedit',
  ShipperCustomerLaneGroupsAddEdit = 'loadshop.ui.system.shipper.customerlanegroups.addedit',
  ShipperCustomerLaneGroupsBrokered = 'loadshop.ui.system.shipper.customerlanegroups.brokeredloads',
  ShipperAddEdit = 'loadshop.ui.system.shipper.addedit',
  CarrierAddEdit = 'loadshop.ui.system.carrier.addedit',
  SpecialInstructionsAddEdit = 'loadshop.ui.shipper.comments.addedit',
  CarrierUserAddEdit = 'loadshop.ui.system.carrier.user.addedit',
  ShipperUserAddEdit = 'loadshop.ui.system.shipper.user.addedit',
  AdminTabVisible = 'loadshop.ui.admin.tab.visible',
  UserCommunicationAddEdit = 'loadshop.ui.system.usercommunication.addedit',
  DynamicPricingAddEdit = 'loadshop.ui.shipper.dynamicpricing.addedit',
  ConfigurationsAddEdit = 'loadshop.ui.system.configurations.addedit',
  ShipperAccessorialsAddEdit = 'loadshop.ui.shipper.accessorials.addedit',
  ShipperAccessorialRequestsApprove = 'loadshop.ui.shipper.accessorial.requests.approve',
  ShipperAccessorialRequestsLock = 'loadshop.ui.shipper.accessorial.requests.lock',
  LoadCarrierViews = 'loadshop.ui.load.carrierviews',
  ShipperCounterOfferAccept = 'loadshop.ui.shipper.counteroffer.requests.approve',
  AdminClearCache = 'loadshop.ui.maintenance.cache.clear',
  ShipperCounterOfferRequest = 'loadshop.ui.shipper.counteroffer.request',
  SystemCounterOfferUpdate = 'loadshop.ui.system.user.counteroffer.update',
  ShowAllLoads = 'loadshop.ui.marketplace.showallloads',
  ShowBrokeredLoads = 'loadshop.ui.marketplace.showbrokeredloads',
  SearchManage = 'loadshop.ui.search.manage',

  // Carrier actions
  ViewFavorites = 'loadshop.ui.profile.favorites.view',
  AddEditFavorites = 'loadshop.ui.profile.favorites.addedit',
  CarrierMarketPlaceView = 'loadshop.ui.marketplace.loads.view',
  CarrierMyLoadsView = 'loadshop.ui.myloads.viewbooked',
  CarrierViewStatus = 'loadshop.ui.myloads.status.view',
  CarrierUpdateStatus = 'loadshop.ui.myloads.status.update',
  ViewDetail = 'loadshop.ui.marketplace.loads.viewdetail',
  CarrierViewDelivered = 'loadshop.ui.carrier.myloads.viewdelivered',
  CarrierViewDeliveredDetail = 'loadshop.ui.carrier.myloads.viewdelivereddetail',
  CarrierCounterOfferRequest = 'loadshop.ui.carrier.counteroffer.request',
  CarrierCanViewShipper = 'loadshop.ui.carrier.shipper.view',
  CarrierViewUsers = 'loadshop.ui.carrier.users.view',
  TenderToCarrier = 'loadshop.ui.load.tendertocarrier',
  // Shipper actions
  CreateManualLoad = 'loadshop.ui.shopit.load.manual.create',
  CopyManualLoad = 'loadshop.ui.shopit.load.manual.copy',
  EditManualLoad = 'loadshop.ui.shopit.load.manual.edit',
  ShipperViewNewLoads = 'loadshop.ui.shopit.load.viewnew',
  ShipperViewBookedLoads = 'loadshop.ui.shopit.load.viewbooked',
  ShipperViewBookedDetail = 'loadshop.ui.shopit.load.viewbookeddetail',
  ShipperViewPostedLoads = 'loadshop.ui.shopit.load.viewposted',
  ShipperViewPostedDetail = 'loadshop.ui.shopit.load.viewposteddetail',
  ShipperViewDeliveredLoads = 'loadshop.ui.shopit.load.viewdelivered',
  ShipperViewDeliveredDetail = 'loadshop.ui.shopit.load.viewdelivereddetial',
  ShipperViewActiveLoads = 'loadshop.ui.shopit.load.viewactive',
  ShipperViewSmartSpotPriceQuote = 'loadshop.ui.shopit.smartspotprice.quote',
  ShipperProfileCustomerMapping = 'loadshop.ui.shipperprofile.customermapping',
  // Shared
  ViewLoadDVIRDocuments = 'loadshop.ui.load.dvir.view',
  LoadshopUIShoptLoadEdit = 'loadshop.ui.shopit.load.edit',
  AccessorialRequestLock = 'loadshop.ui.role.accessorials.requests.lock',
  // User Profile
  LoadStatusNotifications = 'loadshop.ui.profile.loadstatusnotifications',

  // Impersonate
  Impersonate = 'loadshop.impersonate',

  // Fake
  Jabroni = 'loadshop.ui.jabroni',

  // Brokered Loads
  BreakEvenViewAction = 'loadshop.ui.breakeven.view',

  // Notes
  NotesAddEdit = 'loadshop.ui.notes.addedit',
  NotesView = 'loadshop.ui.notes.view',
}
