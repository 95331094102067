<kbxl-admin-header></kbxl-admin-header>
<div class="d-flex flex-column position-relative p-4">
  <div class="progress-indicator" *ngIf="processing$ | async">
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <p-panel styleClass="no-padding">
    <p-header>
      <div class="d-flex justify-content-between pr-2">
        <span>Shipper Profile</span>
      </div>
    </p-header>

    <div class="d-flex">
      <div class="left-tab">
        <div class="kbxl-inputgroup-container">
          <div class="p-inputgroup">
            <p-dropdown
              [options]="shippers$ | async"
              placeholder="Select Shipper"
              [(ngModel)]="selectedShipper"
              dataKey="customerId"
              optionLabel="name"
              [filter]="true"
              (onChange)="loadShipper()"
              filterBy="value.name"
              [showClear]="false"
              [virtualScroll]="true"
              itemSize="30"
              [styleClass]="'p-dropdown-primary no-border-radius-right'"
              dropdownIcon="pi pi-caret-down"
              class="w-100"
            >
              <ng-template let-item pTemplate="selectedItem">
                <span class="selected-item-label">{{ selectedShipper?.name }}</span>
              </ng-template>
            </p-dropdown>
            <button
              pButton
              type="button"
              class="p-button-secondary p-inputgroup-addon"
              icon="pi pi-plus"
              pToolTip="Setup New Shipper"
              (click)="newShipper()"
            ></button>
          </div>
        </div>
      </div>
      <div class="right-tab flex-grow-1" *ngIf="shipper$ | async as s">
        <form (ngSubmit)="saveChanges(s)" #form="ngForm">
          <div class="row">
            <div class="col-md-3 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label for="name" class="control-label">Name</label>
              </div>
              <div class="p-inputgroup">
                <input pInputText type="text" [(ngModel)]="s.name" name="name" class="filter-box" maxlength="100" required />
              </div>
            </div>

            <div class="col-md-3 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label for="ownerId" class="control-label">Owner Id</label>
              </div>
              <div class="p-inputgroup">
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="s.topsOwnerId"
                  name="ownerId"
                  maxlength="20"
                  class="filter-box"
                  [disabled]="isShipperMappingEligable(s)"
                />
              </div>
            </div>

            <div class="col-md-6 col-xl-3 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label for="successManagerUserId" class="control-label">Shipper Success Manager</label>
              </div>
              <div>
                <p-dropdown
                  [options]="adminUsers$ | async | toSelectItem"
                  placeholder="Select User"
                  [(ngModel)]="s.successManagerUserId"
                  name="successManagerUserId"
                  [filter]="true"
                  required
                  [styleClass]="'p-dropdown-primary'"
                  dropdownIcon="pi pi-caret-down"
                >
                  <ng-template let-item pTemplate="item">
                    <span>{{ item?.label | titlecase }}</span>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>

            <div class="col-md-6 col-xl-3 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label for="successSpecialistUserId" class="control-label">Shipper Success Specialist</label>
              </div>
              <div>
                <p-dropdown
                  [options]="adminUsers$ | async | toSelectItem"
                  placeholder="Select User"
                  [(ngModel)]="s.successSpecialistUserId"
                  name="successSpecialistUserId"
                  [filter]="true"
                  required
                  [styleClass]="'p-dropdown-primary'"
                  dropdownIcon="pi pi-caret-down"
                >
                  <ng-template let-item pTemplate="item">
                    <span>{{ item?.label | titlecase }}</span>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 kbxl-inputgroup-container" [class.invisible]="s.brokeredLoads">
              <div class="kbxl-field-label">
                <label for="customerCarrierScacs" class="control-label">
                  {{ s.brokeredLoads ? 'Shippers Contracted Carriers' : 'Platform Carriers' }}
                </label>
              </div>
              <div>
                <p-multiSelect
                  [disabled]="s.brokeredLoads"
                  [options]="carriers"
                  placeholder="Select Carrier/SCACS"
                  [(ngModel)]="s.customerCarrierScacs"
                  name="customerCarrierScacs"
                  [filter]="true"
                  filterBy="label"
                  resetFilterOnHide="true"
                  [virtualScroll]="true"
                  itemSize="30"
                  [styleClass]="'p-dropdown-primary'"
                  dropdownIcon="pi pi-caret-down"
                  (onChange)="carrierChange(s)"
                  [disabled]="contractedCarrierAssignment.isDisabled"
                  #contractedCarrierAssignment="kbxlActionCheck"
                  kbxlActionCheck="loadshop.ui.system.shipper.contractedcarriers.addedit"
                  enforcementType="disable"
                >
                  <ng-template let-item pTemplate="item">
                    <span>{{ item?.label | titlecase }}</span>
                  </ng-template>
                </p-multiSelect>
              </div>
            </div>

            <div class="col-md-6 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label for="customerCarrierScacExcluded" class="control-label">Excluded Carriers</label>
              </div>
              <div>
                <p-multiSelect
                  [options]="excludedCarriers"
                  placeholder="Select Carrier/SCACS"
                  [(ngModel)]="s.customerCarrierScacExcluded"
                  name="customerCarrierScacExcluded"
                  [filter]="true"
                  filterBy="label"
                  resetFilterOnHide="true"
                  [virtualScroll]="true"
                  itemSize="30"
                  [styleClass]="'p-dropdown-primary'"
                  dropdownIcon="pi pi-caret-down"
                  (onChange)="excludedCarrierChange(s)"
                  [disabled]="contractedCarrierAssignment.isDisabled"
                  #contractedCarrierAssignment="kbxlActionCheck"
                  kbxlActionCheck="loadshop.ui.system.shipper.contractedcarriers.addedit"
                  enforcementType="disable"
                >
                  <ng-template let-item pTemplate="item">
                    <span>{{ item?.label | titlecase }}</span>
                  </ng-template>
                </p-multiSelect>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-3 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label for="defaultCommodity" class="control-label">Default Commodity</label>
              </div>
              <div>
                <p-dropdown
                  [options]="commodities$ | async | toSelectItem"
                  placeholder="Default Commodity"
                  [(ngModel)]="s.defaultCommodity"
                  name="defaultCommodity"
                  [filter]="true"
                  [showClear]="false"
                  [virtualScroll]="true"
                  itemSize="30"
                  [styleClass]="'p-dropdown-primary'"
                  dropdownIcon="pi pi-caret-down"
                >
                  <ng-template let-item pTemplate="selectedItem">
                    <span class="selected-item-label">{{ s.defaultCommodity }}</span>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>

            <div class="col-md-3 kbxl-inputgroup-container" *ngIf="s.allowManualLoadCreation">
              <div class="kbxl-field-label">
                <label for="newLoadDaysInPast" class="control-label">Manual Load Days in Past</label>
              </div>
              <div class="p-inputgroup">
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="s.newLoadDaysInPast"
                  name="newLoadDaysInPast"
                  maxlength="20"
                  class="filter-box"
                />
              </div>
            </div>

            <div class="col-md-6 col-xl-3 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label for="customerLoadTypeId" class="control-label">Customer Type</label>
              </div>
              <div>
                <p-dropdown
                  [options]="customerLoadTypes$ | async | toSelectItem"
                  placeholder="Select Customer Type"
                  [(ngModel)]="s.customerLoadTypeId"
                  name="customerLoadTypeId"
                  [filter]="true"
                  [showClear]="true"
                  [styleClass]="'p-dropdown-primary'"
                  dropdownIcon="pi pi-caret-down"
                >
                </p-dropdown>
              </div>
            </div>

            <div class="col-md-6 col-xl-3 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label for="customerLoadTypeExpirationDate" class="control-label">Expiration Date</label>
              </div>
              <div>
                <p-calendar
                  [(ngModel)]="s.customerLoadTypeExpirationDate"
                  name="customerLoadTypeExpirationDate"
                  [showIcon]="true"
                  showButtonBar="true"
                  [selectOtherMonths]="true"
                ></p-calendar>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-xl-6 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label for="availableServiceTypes" class="control-label mr-2">Available Service Types</label>
                <fa-icon [icon]="['fas', 'info-circle']" size="lg" pTooltip="Selected service types are available for all loads."></fa-icon>
              </div>
              <div>
                <p-multiSelect
                  [options]="s.availableServiceTypes | toSelectItemByKey: 'name':'serviceTypeId'"
                  placeholder="Select Service Type(s)"
                  [(ngModel)]="s.selectedServiceTypeIds"
                  name="selectedServiceTypes"
                  [filter]="true"
                  [styleClass]="'p-dropdown-primary'"
                  dropdownIcon="pi pi-caret-down"
                >
                  <ng-template let-item pTemplate="item">
                    <span>{{ item?.label | titlecase }}</span>
                  </ng-template>
                </p-multiSelect>
              </div>
            </div>
            <div class="col-md-12 col-xl-6 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label for="requiredServiceTypes" class="control-label mr-2">Required Service Types</label>
                <fa-icon [icon]="['fas', 'info-circle']" size="lg" pTooltip="Selected service types are required for all loads."></fa-icon>
              </div>
              <div>
                <p-multiSelect
                  [options]="serviceTypes$ | async | toSelectItemByKey: 'name':'serviceTypeId'"
                  placeholder="Select Service Type(s)"
                  [(ngModel)]="s.requiredServiceTypeIds"
                  name="requiredServiceTypes"
                  [filter]="true"
                  [styleClass]="'p-dropdown-primary'"
                  dropdownIcon="pi pi-caret-down"
                >
                  <ng-template let-item pTemplate="item">
                    <span>{{ item?.label | titlecase }}</span>
                  </ng-template>
                </p-multiSelect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label for="externalCustomerId" class="control-label">External CustomerId</label>
              </div>
              <div class="p-inputgroup">
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="s.externalCustomerId"
                  name="externalCustomerId"
                  class="filter-box"
                  maxlength="100"
                />
              </div>
            </div>
          </div>
          <div class="d-flex mt-2 flex-wrap">
            <p-fieldset legend="Fuel" class="mr-4 flex-fill">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-column">
                  <div class="kbxl-field-label">
                    <label for="allowZeroFuel" class="control-label mr-2">Quotes Total Rate</label>
                  </div>
                  <div class="p-inputgroup">
                    <p-checkbox
                      name="allowZeroFuel"
                      (onChange)="onAllowZeroFuelChange($event, s)"
                      value="enabled"
                      [(ngModel)]="s.allowZeroFuel"
                      binary="true"
                    >
                    </p-checkbox>
                  </div>
                </div>
                <div class="d-flex flex-column" *ngIf="!s.allowZeroFuel">
                  <div class="kbxl-field-label">
                    <label for="allowEditingFuel" class="control-label mr-2">Allow Editing Fuel</label>
                  </div>
                  <div class="p-inputgroup">
                    <p-checkbox name="allowEditingFuel" value="enabled" [(ngModel)]="s.allowEditingFuel" binary="true"> </p-checkbox>
                  </div>
                </div>
                <div class="d-flex flex-column" *ngIf="!s.allowZeroFuel">
                  <div class="kbxl-field-label">
                    <label for="fuelprogramtype" class="control-label">Fuel Program</label>
                  </div>
                  <div class="fuel-program-type-drop-down">
                    <p-dropdown
                      [options]="fuelProgramTypeOptions$ | async | toSelectItem"
                      placeholder="Select Type"
                      [(ngModel)]="s.fuelProgramType"
                      name="fuelprogramtype"
                      [showClear]="true"
                      [styleClass]="'p-dropdown-primary'"
                      dropdownIcon="pi pi-caret-down"
                      (onChange)="onFuelProgramTypeChange($event, s)"
                    >
                    </p-dropdown>
                  </div>
                </div>
                <div *ngIf="s.fuelProgramType !== 0" class="d-flex flex-column">
                  <div class="kbxl-field-label">
                    <label for="useFuelRerating" class="control-label">Re-Calculate Fuel</label>
                  </div>
                  <div class="d-flex p-inputgroup">
                    <div class="col-4">
                      <p-checkbox name="useFuelRerating" value="enabled" [(ngModel)]="s.useFuelRerating" binary="true"> </p-checkbox>
                    </div>
                    <div *ngIf="s.useFuelRerating && s.fuelProgramType !== 0">
                      <input
                        pInputText
                        type="text"
                        [(ngModel)]="s.fuelReratingNumberOfDays"
                        name="rerateDays"
                        placeholder="Days"
                        pInputText
                        pToolTip="Days to Re-Calculate Fuel"
                        tooltipPosition="top"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex  justify-content-start" *ngIf="s.fuelProgramType === 3">
                <div class="d-flex flex-column">
                  <div class="kbxl-field-label">
                    <label for="exemptfuelprograms" class="control-label">BTF Exempt Carriers</label>
                  </div>
                  <div>
                    <p-dropdown
                      [options]="s.customerScacFuelPrograms"
                      placeholder="View Carrier Scacs"
                      name="exemptfuelprograms"
                      [filter]="true"
                      filterBy="carrierName,scac"
                      [showClear]="true"
                      [styleClass]="'p-dropdown-primary'"
                      dropdownIcon="pi pi-caret-down"
                    >
                      <ng-template let-fuelprogramscac pTemplate="item">
                        <div class="d-flex">
                          <div>{{ fuelprogramscac.carrierName }}</div>
                          <div class="mx-1">-</div>
                          <div>{{ fuelprogramscac.scac }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
              </div>
            </p-fieldset>
            <p-fieldset legend="Rate Adjustment" class="mr-4">
              <div class="d-flex flex-column  justify-content-between">
                <div class="kbxl-field-label">
                  <label class="control-label">Total Rate Adjustment +/- %</label>
                  <fa-icon
                    [icon]="['fas', 'info-circle']"
                    size="lg"
                    class="ml-1"
                    pTooltip="Percentage adjustment applied to the calculated total rate."
                  >
                  </fa-icon>
                </div>
                <div class="p-inputgroup">
                  <input
                    type="number"
                    [maxlength]="8"
                    name="sspTotalRateAdjustmentPercentage"
                    [(ngModel)]="s.sspTotalRateAdjustmentPercentage"
                    pInputText
                  />
                  <span class="align-self-center ml-2">%</span>
                </div>
              </div>
            </p-fieldset>
          </div>
          <p-panel>
            <p-header>
              <div class="ui-helper-clearfix">
                <span class="p-panel-title pl-0">Features</span>
              </div>
            </p-header>
            <div class="d-flex flex-column">
              <div class="d-flex flex-wrap">
                <p-fieldset legend="Shipper Attributes" class="mr-4 flex-fill">
                  <div class="d-flex kbxl-inputgroup-container justify-content-between">
                    <div class="d-flex flex-column align-items-center">
                      <div class="kbxl-field-label">
                        <label for="isActive" class="control-label mr-2">Active</label>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox name="isActive" value="enabled" [(ngModel)]="s.isActive" binary="true"> </p-checkbox>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                      <div class="kbxl-field-label">
                        <label for="useDefaultContact" class="control-label mr-2">Use Default Contact</label>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox name="useDefaultContact" value="enabled" [(ngModel)]="s.useDefaultContact" binary="true"> </p-checkbox>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                      <div class="kbxl-field-label">
                        <label for="allowManualLoadCreation" class="control-label mr-2">Order Entry</label>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox name="allowManualLoadCreation" value="enabled" [(ngModel)]="s.allowManualLoadCreation" binary="true">
                        </p-checkbox>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                      <div class="kbxl-field-label">
                        <label for="requireMarginalAnalysis" class="control-label mr-2">Marginal Analysis</label>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox name="requireMarginalAnalysis" value="enabled" [(ngModel)]="s.requireMarginalAnalysis" binary="true">
                        </p-checkbox>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                      <div class="kbxl-field-label">
                        <label for="deleteLoadCreatedOnTops" class="control-label mr-2">Delete Load on TOPS</label>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox name="deleteLoadCreatedOnTops" value="enabled" [(ngModel)]="s.deleteLoadCreatedOnTops" binary="true">
                        </p-checkbox>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                      <div class="kbxl-field-label">
                        <label for="contractCarriersBookAtPostedRate" class="control-label mr-2">Book Over Contracted Rates</label>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox
                          name="contractCarriersBookAtPostedRate"
                          value="enabled"
                          [(ngModel)]="s.contractCarriersBookAtPostedRate"
                          binary="true"
                        >
                        </p-checkbox>
                      </div>
                    </div>
                  </div>
                </p-fieldset>
                <p-fieldset legend="Loadshop Additional Features" class="mr-4 flex-fill">
                  <div class="d-flex kbxl-inputgroup-container justify-content-between">
                    <div class="d-flex flex-column align-items-center" kbxlFeature="chat">
                      <div class="kbxl-field-label">
                        <label for="isChatEnabled" class="control-label mr-2">Enable Chat</label>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox name="isChatEnabled" value="enabled" [(ngModel)]="s.isChatEnabled" binary="true"> </p-checkbox>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                      <div class="kbxl-field-label">
                        <label for="useLoadshopAccessorials" class="control-label mr-2">Use Loadshop Accessorials</label>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox name="useLoadshopAccessorials" value="enabled" [(ngModel)]="s.useLoadshopAccessorials" binary="true">
                        </p-checkbox>
                      </div>
                    </div>
                  </div>
                </p-fieldset>
              </div>
              <div class="d-flex mt-2 flex-wrap">
                <p-fieldset legend="Load Attributes" class="mr-4 flex-fill">
                  <div class="d-flex kbxl-inputgroup-container justify-content-between">
                    <div class="d-flex flex-column align-items-center">
                      <div class="kbxl-field-label">
                        <label for="allowAutoPostLoad" class="control-label mr-2">Auto Post to Marketplace</label>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox name="allowAutoPostLoad" value="enabled" [(ngModel)]="s.autoPostLoad" binary="true"> </p-checkbox>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                      <div class="kbxl-field-label">
                        <label for="validateUniqueReferenceLoadIds" class="control-label mr-2">Unique Load Numbers</label>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox
                          name="validateUniqueReferenceLoadIds"
                          value="enabled"
                          [(ngModel)]="s.validateUniqueReferenceLoadIds"
                          binary="true"
                        >
                        </p-checkbox>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                      <div class="kbxl-field-label">
                        <label for="prefetchContractRates" class="control-label mr-2">Prefetch Contract Rates</label>
                        <fa-icon
                          [icon]="['fas', 'info-circle']"
                          size="lg"
                          pTooltip="Contract rates will be retrieved from the shipper when a load is created."
                        >
                        </fa-icon>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox name="prefetchContractRates" value="enabled" [(ngModel)]="s.prefetchContractRates" binary="true">
                        </p-checkbox>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                      <div class="kbxl-field-label">
                        <label for="allowEditingBookedManualLoads" class="control-label mr-2">Allow Editing Manual Booked Load</label>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox
                          name="allowEditingBookedManualLoads"
                          value="enabled"
                          [(ngModel)]="s.allowEditingBookedManualLoads"
                          binary="true"
                        >
                        </p-checkbox>
                      </div>
                    </div>
                  </div>
                </p-fieldset>
                <p-fieldset legend="Counter Offer" class="mr-4 flex-fill">
                  <div class="d-flex kbxl-inputgroup-container justify-content-between">
                    <div class="d-flex flex-column align-items-center">
                      <div class="kbxl-field-label">
                        <label for="allowCounterOffer" class="control-label mr-2">Allow Counter Offer</label>
                        <fa-icon
                          [icon]="['fas', 'info-circle']"
                          size="lg"
                          pTooltip="Selecting the checkbox activates the overall counter offer for the shipper."
                        ></fa-icon>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox name="allowCounterOffer" value="enabled" [(ngModel)]="s.allowCounterOffer" binary="true"> </p-checkbox>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-center ml-3">
                      <div class="kbxl-field-label">
                        <label for="applyCounterOfferToAllLoads" class="control-label mr-2">Apply to all Loads</label>
                        <fa-icon
                          [icon]="['fas', 'info-circle']"
                          size="lg"
                          pTooltip="When checked, counter offer will be turned on for all loads. When unchecked counter offer can be manually activated for each load."
                        ></fa-icon>
                      </div>
                      <div class="p-inputgroup">
                        <p-checkbox
                          name="applyCounterOfferToAllLoads"
                          value="enabled"
                          [(ngModel)]="s.applyCounterOfferToAllLoads"
                          binary="true"
                        >
                        </p-checkbox>
                      </div>
                    </div>
                  </div>
                </p-fieldset>
              </div>
              <div class="d-flex mt-2">
              </div>
            </div>
          </p-panel>
          <p-panel>
            <p-header>
              <div class="ui-helper-clearfix">
                <span class="p-panel-title pl-0">Contacts</span>
              </div>
            </p-header>

            <div class="row" *ngIf="!s.customerContacts || s.customerContacts.length == 0">
              <div class="col-md-3">
                <button pButton type="button" class="p-button-teal" icon="pi pi-plus" label="Add Contact" (click)="addContact(s)"></button>
              </div>
            </div>

            <div class="row" *ngFor="let contact of s.customerContacts; index as i; last as isLast">
              <div class="col-xl-1 kbxl-inputgroup-container">
                <div class="p-inputgroup" *ngIf="isLast">
                  <button
                    pButton
                    type="button"
                    class="add-button contact-button p-button-teal"
                    icon="pi pi-plus"
                    (click)="addContact(s)"
                  ></button>
                </div>
              </div>

              <div class="col-xl-2 kbxl-inputgroup-container">
                <div class="kbxl-field-label">
                  <label for="firstname-{{ i }}" class="control-label">First Name</label>
                </div>
                <div class="p-inputgroup">
                  <input
                    pInputText
                    type="text"
                    [(ngModel)]="contact.firstName"
                    name="firstname-{{ i }}"
                    maxlength="100"
                    required
                    class="filter-box"
                  />
                </div>
              </div>

              <div class="col-xl-2 kbxl-inputgroup-container">
                <div class="kbxl-field-label">
                  <label for="lastname-{{ i }}" class="control-label">Last Name</label>
                </div>
                <div class="p-inputgroup">
                  <input
                    pInputText
                    type="text"
                    [(ngModel)]="contact.lastName"
                    name="lastname-{{ i }}"
                    maxlength="100"
                    required
                    class="filter-box"
                  />
                </div>
              </div>

              <div class="col-xl-2 kbxl-inputgroup-container">
                <div class="kbxl-field-label">
                  <label for="position-{{ i }}" class="control-label">Position</label>
                </div>
                <div class="p-inputgroup">
                  <input pInputText type="text" [(ngModel)]="contact.position" name="position-{{ i }}" maxlength="100" class="filter-box" />
                </div>
              </div>

              <div class="col-xl-2 kbxl-inputgroup-container">
                <div class="kbxl-field-label">
                  <label for="phone-{{ i }}" class="control-label">Phone</label>
                </div>
                <div class="p-inputgroup">
                  <input pInputText type="text" [(ngModel)]="contact.phoneNumber" name="phone-{{ i }}" maxlength="20" class="filter-box" />
                </div>
              </div>

              <div class="col-xl-2 kbxl-inputgroup-container">
                <div class="kbxl-field-label">
                  <label for="email-{{ i }}" class="control-label">Email</label>
                </div>
                <div class="p-inputgroup">
                  <input pInputText type="text" [(ngModel)]="contact.email" name="email-{{ i }}" maxlength="256" class="filter-box" />
                </div>
              </div>

              <div class="col-xl-1 kbxl-inputgroup-container">
                <div class="kbxl-field-label">
                  <label for="default-{{ i }}" class="control-label">Default</label>
                </div>
                <div class="d-flex">
                  <p-checkbox binary="true" [(ngModel)]="contact.isDefault" name="default-{{ i }}" value="enabled"> </p-checkbox>
                  <button
                    pButton
                    type="button"
                    class="p-button-danger contact-button ml-3"
                    icon="pi pi-trash"
                    (click)="deleteContact(s, i)"
                  ></button>
                </div>
              </div>
            </div>
          </p-panel>

          <p-panel>
            <p-header>
              <div class="ui-helper-clearfix">
                <span class="p-panel-title pl-0">Fee Structure</span>
              </div>
            </p-header>

            <div class="row d-none d-xl-flex">
              <div class="col-xl-2"></div>
              <div class="col-xl-2">
                <div class="kbxl-field-label decorated-input-group-header">
                  <label class="control-label">Flat Fee</label>
                </div>
              </div>

              <div class="col-xl-1 text-center">
                <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
              </div>

              <div class="col-xl-2">
                <div class="kbxl-field-label decorated-input-group-header">
                  <label class="control-label">Percentage</label>
                </div>
              </div>

              <!-- <div class="col-xl-2">
                <div class="kbxl-field-label">
                  <label class="control-label">Carrier LineHaul</label>
                </div>
              </div> -->
            </div>

            <div class="row">
              <div class="col-xl-2 kbxl-inputgroup-container d-none d-xl-block">
                <label class="control-label pull-right inline-text">In-Network</label>
              </div>

              <div class="col-6 d-xl-none">
                <label class="control-label inline-text">In-Network Fee</label>
              </div>
              <div class="col-6 col-xl-2 kbxl-inputgroup-container">
                <div class="decorated-input-group">
                  <label class="control-label text-center">$</label>
                  <input pInputText type="number" step="0.01" [(ngModel)]="s.inNetworkFlatFee" name="inNetworkFlatFee" />
                  <label></label>
                </div>
              </div>

              <div class="col-5 d-xl-none">
                <label class="control-label inline-text">In-Network Percent</label>
              </div>
              <div class="col-1 kbxl-inputgroup-container text-center inline-text">
                <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
              </div>
              <div class="col-6 col-xl-2 kbxl-inputgroup-container">
                <div class="decorated-input-group">
                  <label></label>
                  <kbxl-percentage [(percentage)]="s.inNetworkPercentFee" inputName="inNetworkPercentFee"></kbxl-percentage>
                  <label class="control-label text-center">%</label>
                </div>
              </div>

              <!-- <div class="col-6 d-xl-none">
                <label class="control-label inline-text">In-Network Carrier LineHaul</label>
              </div>
              <div class="col-6 col-xl-3 kbxl-inputgroup-container">
                <div>
                  <p-radioButton name="inNetworkFeeAdd" [value]="false" label="Subtract From" class="mr-3" [(ngModel)]="s.inNetworkFeeAdd">
                  </p-radioButton>
                </div>
                <div>
                  <p-radioButton name="inNetworkFeeAdd" [value]="true" label="Add To" [(ngModel)]="s.inNetworkFeeAdd">
                  </p-radioButton>
                </div>
              </div> -->
            </div>

            <div class="row">
              <div class="col-xl-2 kbxl-inputgroup-container d-none d-xl-block">
                <label class="control-label pull-right inline-text">Out-Network</label>
              </div>

              <div class="col-6 d-xl-none">
                <label class="control-label inline-text">Out-Network Fee</label>
              </div>
              <div class="col-6 col-xl-2 kbxl-inputgroup-container">
                <div class="decorated-input-group">
                  <label class="control-label text-center">$</label>
                  <input pInputText type="number" step="0.01" [(ngModel)]="s.outNetworkFlatFee" name="outNetworkFlatFee" />
                  <label></label>
                </div>
              </div>

              <div class="col-5 d-xl-none">
                <label class="control-label inline-text">Out-Network Percent</label>
              </div>
              <div class="col-1 kbxl-inputgroup-container text-center inline-text">
                <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
              </div>
              <div class="col-6 col-xl-2 kbxl-inputgroup-container">
                <div class="decorated-input-group">
                  <label></label>
                  <kbxl-percentage [(percentage)]="s.outNetworkPercentFee" inputName="outNetworkPercentFee"></kbxl-percentage>
                  <label class="control-label text-center">%</label>
                </div>
              </div>
            </div>
          </p-panel>

          <div class="row">
            <div class="col-md-7 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label for="comments" class="control-label">Comments</label>
              </div>
              <div>
                <textarea [rows]="5" [cols]="50" pInputTextarea [(ngModel)]="s.comments" name="comments"></textarea>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button type="button" pButton label="Shipper Endpoints" class="p-button-teal m-2" (click)="viewShipperEndpoints()"></button>
            <button
              kbxlActionCheck="loadshop.ui.shipperprofile.customermapping"
              type="button"
              pButton
              label="Shipper Mappings"
              class="p-button-teal m-2"
              (click)="viewShipperMappings()"
              *ngIf="isShipperMappingEligable(s)"
            ></button>
            <button
              kbxlActionCheck="loadshop.ui.shipperprofile.customermapping"
              type="button"
              pButton
              label="Enable API Processing"
              class="p-button-teal m-2"
              (click)="enableShipperApi(s)"
              *ngIf="isShipperApiEligable(s)"
            ></button>

            <button type="button" pButton label="Cancel" class="p-button-outlined p-button-secondary m-2" (click)="cancel(s)"></button>
            <button type="submit" pButton label="Save Shipper" class="m-2" [disabled]="!form.valid"></button>
          </div>
        </form>

        <p-dialog
          header="Loadshop Shipper Mappings"
          [(visible)]="displayShipperMappingsDialog"
          [style]="{ left: '100px', 'max-width': 'calc(100vw - 200px)' }"
          [modal]="true"
          [closeOnEscape]="true"
          *ngIf="isShipperMappingEligable(s)"
        >
          <kbxl-shipper-mapping-modal
            [ownerId]="s.topsOwnerId"
            [customerId]="s.customerId"
            [(visible)]="displayShipperMappingsDialog"
          ></kbxl-shipper-mapping-modal>
        </p-dialog>
        <p-dialog header="Loadshop Shipper Endpoints" [(visible)]="displayShipperEndpointsDialog" [modal]="true" [closeOnEscape]="true">
          <kbxl-shipper-endpoints-modal [customerId]="s.customerId"></kbxl-shipper-endpoints-modal>
        </p-dialog>
      </div>
    </div>
  </p-panel>
</div>
