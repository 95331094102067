import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BulkOperationData, BulkOperationTypeEnum, ShipperAccessorialData, ValidationProblemDetails } from '../../../../shared/models';

@Component({
  selector: 'kbxl-accessorial-grid',
  templateUrl: './accessorial-grid.component.html',
  styleUrls: ['./accessorial-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessorialGridComponent implements OnChanges {
  @Input() loading: boolean;
  @Input() headerTitle = 'Accessorials';
  @Input() enableAdd = false;
  @Input() enableDelete = false;
  @Input() shipperAccessorials: ShipperAccessorialData[];
  @Input() error: ValidationProblemDetails;
  @Output() save: EventEmitter<BulkOperationData<ShipperAccessorialData>[]> = new EventEmitter<
    BulkOperationData<ShipperAccessorialData>[]
  >();
  @Output() formDirty: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() displayDetail = new EventEmitter<ShipperAccessorialData>();
  pendingChanges: {
    rowIndex: number;
    data: BulkOperationData<ShipperAccessorialData>;
  }[];

  pendingChange = false;

  private initShipperAccessorials: ShipperAccessorialData[];

  afterInitialHoursValues = [
    { label: '0 hrs', value: 0 },
    { label: '1 hr', value: 1 },
    { label: '2 hrs', value: 2 },
    { label: '3 hrs', value: 3 },
    { label: '4 hrs', value: 4 },
    { label: '5 hrs', value: 5 },
  ];

  constructor() {
    this.pendingChanges = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.shipperAccessorials) {
      // clone init value to track changes
      this.initShipperAccessorials = JSON.parse(JSON.stringify(changes.shipperAccessorials.currentValue)) as ShipperAccessorialData[];
      this.pendingChanges = [];
      this.pendingChange = false;
      this.formDirty.emit(false);

      if (this.enableDelete && this.initShipperAccessorials.every((x) => !x.customerId || x.customerId.length === 0)) {
        // user can save the global accessorials
        for (let index = 0; index < this.shipperAccessorials.length; index++) {
          const element = this.shipperAccessorials[index];
          element.customerAccessorialId = 0;
          // add latest
          this.pendingChanges.push({
            rowIndex: index,
            data: {
              data: element,
              operationType: BulkOperationTypeEnum.Create,
            },
          });
        }
        this.checkIfFormDirty();
      }
    }
  }

  shouldShowAfterInitialHours(accessorial: ShipperAccessorialData) {
    return accessorial && Number.isInteger(accessorial.afterInitialHours);
  }

  setAfterInitialHours(rowIndex: number) {
    const accessorial = this.shipperAccessorials[rowIndex];
    if (accessorial && Number.isInteger(accessorial.afterInitialHours)) {
      this.addChange(accessorial, rowIndex);
    }
  }

  addClick() {
    this.displayDetail.emit(null);
  }
  saveChanges(): void {
    // format data for api
    this.shipperAccessorials.forEach((x) => {
      if (x.variableRate) {
        // null out max and uom rate for api
        x.maxDollarRatePerUOM = null;
        x.maxRate = null;
      }
    });

    this.save.emit(this.pendingChanges.map((x) => x.data));
  }
  addChange(accessorial: ShipperAccessorialData, rowIndex: number): void {
    let opt = BulkOperationTypeEnum.Create;

    if (accessorial.customerAccessorialId && accessorial.customerAccessorialId > 0) {
      opt = BulkOperationTypeEnum.Update;
    }

    // check if exists
    this.pendingChanges = this.pendingChanges.filter((x) => x.rowIndex !== rowIndex);

    // add latest
    this.pendingChanges.push({
      rowIndex: rowIndex,
      data: {
        data: accessorial,
        operationType: opt,
      },
    });

    this.checkIfFormDirty();

    if (!this.pendingChange) {
      // reset form
      this.pendingChanges = [];
      this.error = null;
    }
  }

  removeAccessorial(accessorial: ShipperAccessorialData, rowIndex: number): void {
    // mark as removed to trigger ngClass
    accessorial.isRemoved = true;

    // add latest
    this.pendingChanges.push({
      rowIndex: rowIndex,
      data: {
        data: accessorial,
        operationType: BulkOperationTypeEnum.Delete,
      },
    });
    this.checkIfFormDirty();
  }
  checkIfFormDirty(): void {
    this.pendingChange = JSON.stringify(this.shipperAccessorials) !== JSON.stringify(this.initShipperAccessorials);

    this.formDirty.emit(this.pendingChange);
  }
  decodeProblemDetails(): string {
    if (!this.error || !this.error.errors) {
      return;
    }
    const groupErrors = this.error.errors as any[];

    if (groupErrors) {
      // concat errors together to show on top
      return groupErrors.map((x) => x.message).join('\n');
    }
  }

  hasError(errorField: string, rowIndex: number): boolean {
    if (!this.error || !this.error.errors) {
      return false;
    }

    // see if the pending change has a record for this row
    const pendingChangeRow = this.pendingChanges.find((x) => x.rowIndex === rowIndex);

    if (!pendingChangeRow) {
      return;
    }

    rowIndex = this.pendingChanges.indexOf(pendingChangeRow);
    const groupErrors = this.error.errors as any[];

    const index = `urn:root:${errorField}:${rowIndex}`;
    const rowError = groupErrors.find((x) => x.data === index);

    if (rowError) {
      return true;
    }
  }
}
