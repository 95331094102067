<kbxl-admin-header></kbxl-admin-header>
<div class="px-4">
  <kbxl-user-communication-grid
    [allUserCommunications]="allUserCommunications$ | async"
    (userCommunicationSelected)="userCommunicationSelected($event)"
    (createNotification)="createNotification()"
    [processing]="processing$ | async"
  >
  </kbxl-user-communication-grid>
</div>
