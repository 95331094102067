/* eslint-disable @typescript-eslint/camelcase */
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { LoadCarrierGroupType, CustomerLaneGroupDetailData, CustomerLaneGroupData, LaneSearch } from '../../../shared/models';

export enum LaneManagementActionTypes {
  Load = '[LaneManagement] LOAD',
  Load_Success = '[LaneManagement] LOAD_SUCCESS',
  Load_Failure = '[LaneManagement] LOAD_FAILURE',
  Load_Lane = '[LaneManagement] LOAD_LANE',
  Load_Lane_Success = '[LaneManagement] LOAD_LANE_SUCCESS',
  Load_Lane_Failure = '[LaneManagement] LOAD_LANE_FAILURE',
  Add = '[LaneManagement] ADD',
  Add_Success = '[LaneManagement] ADD_SUCCESS',
  Add_Failure = '[LaneManagement] ADD_FAILURE',
  Update = '[LaneManagement] UPDATE',
  Update_Success = '[LaneManagement] UPDATE_SUCCESS',
  Update_Failure = '[LaneManagement] UPDATE_FAILURE',
  Delete = '[LaneManagement] DELETE',
  Delete_Success = '[LaneManagement] DELETE_SUCCESS',
  Delete_Failure = '[LaneManagement] DELETE_FAILURE',
  Clear_Save_Succeeded = '[LaneManagement] CLEAR_SAVE_SUCCEEDED',
  //   Copy_Carriers_Load = '[LaneManagement] COPY_CARRIERS_LOAD',
  //   Copy_Carriers_Load_Success = '[LaneManagement] COPY_CARRIERS_LOAD_SUCCESS',
  //   Copy_Carriers_Load_Fail = '[LaneManagement] COPY_CARRIERS_LOAD_FAILURE',
  Load_Carrier_Group_Types = '[LaneManagement] LOAD_CARRIER_GROUP_TYPES',
  Load_Carrier_Group_Types_Success = '[LaneManagement] LOAD_CARRIER_GROUP_TYPES_SUCCESS',
  Load_Carrier_Group_Types_Failure = '[LaneManagement] LOAD_CARRIER_GROUP_TYPES_FAILURE',
  Page_Change = '[LaneManagement] PAGE_CHANGE',
  Search_Change = '[LaneManagement] SEARCH_CHANGE',
}

export class LaneManagementLoadAction implements Action {
  readonly type = LaneManagementActionTypes.Load;
  constructor(public payload: { customerId: string }) {}
}

export class LaneManagementLoadSuccessAction implements Action {
  readonly type = LaneManagementActionTypes.Load_Success;

  constructor(public payload: CustomerLaneGroupData[]) {}
}

export class LaneManagementLoadFailureAction implements Action {
  readonly type = LaneManagementActionTypes.Load_Failure;

  constructor(public payload: Error) {}
}

export class LaneManagementLoadGroupAction implements Action {
  readonly type = LaneManagementActionTypes.Load_Lane;
  constructor(public payload: { LaneManagementId: number }) {}
}

export class LaneManagementLoadGroupSuccessAction implements Action {
  readonly type = LaneManagementActionTypes.Load_Lane_Success;

  constructor(public payload: CustomerLaneGroupDetailData) {}
}

export class LaneManagementLoadGroupFailureAction implements Action {
  readonly type = LaneManagementActionTypes.Load_Lane_Failure;

  constructor(public payload: Error) {}
}

export class LaneManagementAddAction implements Action {
  readonly type = LaneManagementActionTypes.Add;

  constructor(public payload: CustomerLaneGroupDetailData) {}
}

export class LaneManagementAddSuccessAction implements Action {
  readonly type = LaneManagementActionTypes.Add_Success;

  constructor(public payload: CustomerLaneGroupDetailData) {}
}

export class LaneManagementAddFailureAction implements Action {
  readonly type = LaneManagementActionTypes.Add_Failure;

  constructor(public payload: HttpErrorResponse) {}
}

export class LaneManagementUpdateAction implements Action {
  readonly type = LaneManagementActionTypes.Update;

  constructor(public payload: CustomerLaneGroupDetailData) {}
}

export class LaneManagementUpdateSuccessAction implements Action {
  readonly type = LaneManagementActionTypes.Update_Success;

  constructor(public payload: CustomerLaneGroupDetailData) {}
}

export class LaneManagementUpdateFailureAction implements Action {
  readonly type = LaneManagementActionTypes.Update_Failure;

  constructor(public payload: HttpErrorResponse) {}
}

export class LaneManagementDeleteAction implements Action {
  readonly type = LaneManagementActionTypes.Delete;

  constructor(public payload: CustomerLaneGroupDetailData) {}
}

export class LaneManagementDeleteSuccessAction implements Action {
  readonly type = LaneManagementActionTypes.Delete_Success;

  constructor(public payload: CustomerLaneGroupDetailData) {}
}

export class LaneManagementDeleteFailureAction implements Action {
  readonly type = LaneManagementActionTypes.Delete_Failure;

  constructor(public payload: Error) {}
}

export class LaneManagementClearSaveSucceededAction implements Action {
  readonly type = LaneManagementActionTypes.Clear_Save_Succeeded;
}

// export class LaneManagementCopyCarriersAction implements Action {
//   readonly type = LaneManagementActionTypes.Copy_Carriers_Load;
//   constructor(public payload: { LaneManagementId: number }) {}
// }
// export class LaneManagementCopyCarriersSuccessAction implements Action {
//   readonly type = LaneManagementActionTypes.Copy_Carriers_Load_Success;

//   constructor(public payload: LaneManagementCarrierData[]) {}
// }

// export class LaneManagementCopyCarriersFailureAction implements Action {
//   readonly type = LaneManagementActionTypes.Copy_Carriers_Load_Fail;

//   constructor(public payload: Error) {}
// }

export class LaneManagementLoadCarrierGroupTypesAction implements Action {
  readonly type = LaneManagementActionTypes.Load_Carrier_Group_Types;
}

export class LaneManagementLoadCarrierGroupTypesSuccessAction implements Action {
  readonly type = LaneManagementActionTypes.Load_Carrier_Group_Types_Success;

  constructor(public payload: LoadCarrierGroupType[]) {}
}

export class LaneManagementLoadCarrierGroupTypesFailure implements Action {
  readonly type = LaneManagementActionTypes.Load_Carrier_Group_Types_Failure;

  constructor(public payload: Error) {}
}

export class LaneManagementPageNumberChange implements Action {
  readonly type = LaneManagementActionTypes.Page_Change;

  constructor(public payload: number) {}
}

export class LaneManagementSearchChange implements Action {
  readonly type = LaneManagementActionTypes.Search_Change;

  constructor(public payload: LaneSearch) {}
}

export type LaneManagementActions =
  | LaneManagementLoadAction
  | LaneManagementLoadSuccessAction
  | LaneManagementLoadFailureAction
  | LaneManagementLoadGroupAction
  | LaneManagementLoadGroupSuccessAction
  | LaneManagementLoadGroupFailureAction
  | LaneManagementAddAction
  | LaneManagementAddSuccessAction
  | LaneManagementAddFailureAction
  | LaneManagementUpdateAction
  | LaneManagementUpdateSuccessAction
  | LaneManagementUpdateFailureAction
  | LaneManagementDeleteAction
  | LaneManagementDeleteSuccessAction
  | LaneManagementDeleteFailureAction
  | LaneManagementClearSaveSucceededAction
  //   | LaneManagementCopyCarriersAction
  //   | LaneManagementCopyCarriersSuccessAction
  //   | LaneManagementCopyCarriersFailureAction
  | LaneManagementLoadCarrierGroupTypesAction
  | LaneManagementLoadCarrierGroupTypesSuccessAction
  | LaneManagementLoadCarrierGroupTypesFailure
  | LaneManagementPageNumberChange
  | LaneManagementSearchChange;
