import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BookLoadRequest, BookLoadType, LoadDetail, LoadStop, MarginalAnalysisWorksheet, Place, ResponseError, User } from '../../models';
import { MarginalAnalysisService } from '../../services';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'kbxl-marginal-analysis-form',
  templateUrl: './marginal-analysis-form.component.html',
  styleUrls: ['./marginal-analysis-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarginalAnalysisFormComponent extends BaseComponent implements OnChanges, OnDestroy {
  @Input() userProfile: User;
  @Input() load: LoadDetail;
  @Input() loadDetailErrors: ResponseError[];
  @Input() visible: boolean;
  @Input() processing: boolean;
  @Output() book: EventEmitter<BookLoadRequest> = new EventEmitter<BookLoadRequest>();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  origin: LoadStop;
  dest: LoadStop;

  driverLocation: Place;
  nextKbxlOpportunityLocation: Place;
  nextLoadLocation: Place;
  worksheetForm: FormGroup;

  worksheet: MarginalAnalysisWorksheet;
  loadingCalculation = false;
  loadingHHGMiles = false;
  loadingLineHaulRate = false;
  noLineHaulRate = false;
  isFlatRate = false;
  canBookLoad = false;
  errorMsg = '';

  constructor(
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private marginalAnalysisService: MarginalAnalysisService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    super();
    this.createForm();
    this.worksheet = new MarginalAnalysisWorksheet();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.load) {
      this.origin = this.load.loadStops[0];
      this.dest = this.load.loadStops[this.load.loadStops.length - 1];
      this.worksheet = { ...this.worksheet, loadId: this.load.loadId, loadMiles: this.load.miles };

      if (this.visible) {
        this.setWorksheetLoadMiles();
        this.setWorksheetLineHaulRate();
      }
    } else {
      this.origin = null;
      this.dest = null;
    }
  }

  createForm(): void {
    this.worksheetForm = this.formBuilder.group({
      driverLocation: new FormControl(null, Validators.required),
      nextKbxlOpportunityLocation: new FormControl(null, Validators.required),
      nextLoadLocation: new FormControl(null, Validators.required),
      lineHaulRate: new FormControl(null, { updateOn: 'blur', validators: [Validators.required] }),
    });

    // capture the form changes to recalc the schedule
    this.worksheetForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .pipe(debounceTime(250))
      .subscribe((x) => {
        this.updateAmount();
      });
  }

  setWorksheetLoadMiles() {
    if (!this.loadingHHGMiles) {
      this.loadingHHGMiles = true;

      this.marginalAnalysisService
        .getHHGMiles({
          originCity: this.origin.city,
          originState: this.origin.state,
          originPostalCode: this.origin.postalCode,
          originCountry: this.origin.country,
          destinationCity: this.dest.city,
          destinationState: this.dest.state,
          destinationPostalCode: this.dest.postalCode,
          destinationCountry: this.dest.country,
        })
        .subscribe((x) => {
          if (x > 0) {
            this.worksheet.loadMiles = x;
          }
          this.loadingHHGMiles = false;
          // call last
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  setWorksheetLineHaulRate() {
    if (this.load && this.load.loadId) {
      this.marginalAnalysisService.getLineHaulRate(this.load.loadId).subscribe(
        (_) => {
          this.worksheet.lineHaulRate = _.lineHaulRate;
          this.worksheetForm.patchValue({
            lineHaulRate: _.lineHaulRate,
          });

          this.noLineHaulRate = !(_.lineHaulRate > 0);
          this.isFlatRate = _.isFlatRate;

          this.changeDetectorRef.detectChanges();
        },
        () => {
          this.noLineHaulRate = true;
          this.changeDetectorRef.detectChanges();
        }
      );
    }
  }

  updateAmount(): void {
    if (!this.worksheetForm.valid) {
      return;
    }
    // clear out calc'd fields
    this.worksheet.dedicatedBackhaulExpense = 0;
    this.worksheet.negotiatingStartingPoint = 0;
    this.worksheet.counterOffer = 0;
    this.worksheet.minimumNeededRate = 0;

    this.getInputFromForm();

    // call API
    this.errorMsg = '';
    this.loadingCalculation = true;
    this.canBookLoad = false;
    this.changeDetectorRef.detectChanges();
    this.marginalAnalysisService.calculateWorksheet(this.worksheet).subscribe(
      (x) => {
        this.worksheet = this.deepClone(x);
        this.canBookLoad = x.canBookLoad;
        this.loadingCalculation = false;

        if (x.error && x.error.length > 0) {
          this.errorMsg = x.error;
        }

        // call last
        this.changeDetectorRef.detectChanges();
      },
      (err) => {
        if (err && err.error && err.error.title && err.error.detail) {
          this.messageService.add({
            summary: err.error.title,
            detail: err.error.detail,
            severity: 'error',
          });
          this.decodeProblemDetails(err.error);
        }
        this.loadingCalculation = false;
        // call last
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  private getInputFromForm(): void {
    const formValue = this.worksheetForm.value;

    const driversLocation = formValue.driverLocation as Place;
    if (driversLocation) {
      this.worksheet.driverAddr1 = driversLocation.address;
      this.worksheet.driverCity = driversLocation.city;
      this.worksheet.driverCountry = driversLocation.country;
      this.worksheet.driverState = driversLocation.stateAbbrev;
      this.worksheet.driverZip = driversLocation.postalCode;
    }

    const nextKbxlOpportunityLocation = formValue.nextKbxlOpportunityLocation as Place;
    if (nextKbxlOpportunityLocation) {
      this.worksheet.nextKbxlOpportunityAddr1 = nextKbxlOpportunityLocation.address;
      this.worksheet.nextKbxlOpportunityCity = nextKbxlOpportunityLocation.city;
      this.worksheet.nextKbxlOpportunityCountry = nextKbxlOpportunityLocation.country;
      this.worksheet.nextKbxlOpportunityState = nextKbxlOpportunityLocation.stateAbbrev;
      this.worksheet.nextKbxlOpportunityZip = nextKbxlOpportunityLocation.postalCode;
    }

    const nextLoadLocation = formValue.nextLoadLocation as Place;
    if (nextLoadLocation) {
      this.worksheet.nextLoadAddr1 = nextLoadLocation.address;
      this.worksheet.nextLoadCity = nextLoadLocation.city;
      this.worksheet.nextLoadCountry = nextLoadLocation.country;
      this.worksheet.nextLoadState = nextLoadLocation.stateAbbrev;
      this.worksheet.nextLoadZip = nextLoadLocation.postalCode;
    }

    this.worksheet.lineHaulRate = formValue.lineHaulRate as number;
    this.worksheet.dedicatedFuel = formValue.dedicatedFuel as number;
  }

  bookLoad() {
    if (!this.worksheet.canBookLoad) {
      return;
    }
    const message = `Are you sure you want to book load for ${this.userProfile.carrierScac} - ${this.userProfile.carrierName}?`;
    this.confirmationService.confirm({
      message: message,
      accept: () => {
        this.load.marginalAnalysisWorksheet = this.worksheet;
        this.book.emit({ loadData: this.load, bookingType: BookLoadType.CarrierBooked });
      },
    });
  }

  cancel() {
    const message = 'Are you sure you want to cancel booking this load and return to the marketplace? All changes will not be saved.';
    this.confirmationService.confirm({
      message: message,
      accept: () => {
        // wipe form
        this.worksheetForm.patchValue({
          driverLocation: null,
          nextKbxlOpportunityLocation: null,
          nextLoadLocation: null,
          lineHaulRate: null,
        });

        this.worksheetForm.reset();

        this.visibleChange.emit(false);
      },
    });
  }

  decodeProblemDetails(error: any) {
    if (!error || !error.errors) {
      return;
    }

    const groupErrors = error.errors['urn:MarginalAnalysisWorksheet'];
    if (groupErrors && Array.isArray(groupErrors)) {
      this.errorMsg = groupErrors.join('\n');
    } else if (groupErrors && typeof groupErrors === 'string') {
      this.errorMsg = groupErrors;
    }
    return;
  }
}
