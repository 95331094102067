import { Router, NavigationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { PagingHandler } from './paging';

export function reloadDataOnNavigationBackToRoute(
  router: Router,
  route: string,
  destroyed$: Observable<boolean>,
  handler: PagingHandler
): Subscription {
  return router.events.pipe(takeUntil(destroyed$)).subscribe((event) => {
    if (event instanceof NavigationEnd) {
      if (router.isActive(route, true)) {
        handler.reloadData();
      }
    }
  });
}
