import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ResponseError } from '../../models';

@Component({
  selector: 'kbxl-error-list',
  template: ` <div [class]="getErrorCss()" *ngIf="filteredErrors && filteredErrors.length > 0">
    <ul class="list-unstyled">
      <li *ngFor="let error of filteredErrors">{{ error.message }}</li>
    </ul>
  </div>`,
})
export class ErrorListDisplayComponent implements OnChanges {
  @Input() errors: ResponseError[];
  @Input() urnPath: string;
  @Input() errorType: string;

  filteredErrors: ResponseError[];

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.errors || changes.urnPath) && this.errors) {
      if (this.urnPath) {
        this.filteredErrors = this.errors.filter((_) => _.data.toString() === this.urnPath);
      }
    } else {
      this.filteredErrors = this.errors;
    }
  }

  getErrorCss() {
    switch (this.errorType) {
      case 'field':
        return 'error';
      default:
        return 'error-container';
    }
  }
}
