import { Action } from '@ngrx/store';
import { CustomerFavorite } from '../../../shared/models';

export enum CustomerFavoriteActionTypes {
  Load = '[Customer Favorite] LOAD',
  Load_Success = '[Customer Favorite] LOAD_SUCCESS',
  Load_Failure = '[Customer Favorite] LOAD_FAILURE',
  Add_Favorite = '[Customer Favorite] ADD',
  Add_Favorite_Success = '[Customer Favorite] ADD_SUCCESS',
  Add_Favorite_Failure = '[Customer Favorite] ADD_FAILURE',
  Update_Favorite = '[Customer Favorite] UPDATE',
  Update_Favorite_Success = '[Customer Favorite] UPDATE_SUCCESS',
  Update_Favorite_Failure = '[Customer Favorite] UPDATE_FAILURE',
  Delete_Favorite = '[Customer Favorite] DELETE',
  Delete_Favorite_Success = '[Customer Favorite] DELETE_SUCCESS',
  Delete_Favorite_Failure = '[Customer Favorite] DELETE_FAILURE',
}

export class CustomerFavoriteLoadAction implements Action {
  readonly type = CustomerFavoriteActionTypes.Load;
}
export class CustomerFavoriteLoadSuccessAction implements Action {
  readonly type = CustomerFavoriteActionTypes.Load_Success;
  constructor(public payload: CustomerFavorite[]) {}
}
export class CustomerFavoriteLoadFailureAction implements Action {
  readonly type = CustomerFavoriteActionTypes.Load_Failure;
  constructor(public payload: Error) {}
}

export class CustomerFavoriteAddAction implements Action {
  readonly type = CustomerFavoriteActionTypes.Add_Favorite;
  constructor(public payload: CustomerFavorite) {}
}
export class CustomerFavoriteAddSuccessAction implements Action {
  readonly type = CustomerFavoriteActionTypes.Add_Favorite_Success;
  constructor(public payload: CustomerFavorite) {}
}
export class CustomerFavoriteAddFailureAction implements Action {
  readonly type = CustomerFavoriteActionTypes.Add_Favorite_Failure;
  constructor(public payload: Error) {}
}

export class CustomerFavoriteUpdateAction implements Action {
  readonly type = CustomerFavoriteActionTypes.Update_Favorite;
  constructor(public payload: CustomerFavorite) {}
}
export class CustomerFavoriteUpdateSuccessAction implements Action {
  readonly type = CustomerFavoriteActionTypes.Update_Favorite_Success;
  constructor(public payload: CustomerFavorite) {}
}
export class CustomerFavoriteUpdateFailureAction implements Action {
  readonly type = CustomerFavoriteActionTypes.Update_Favorite_Failure;
  constructor(public payload: Error) {}
}

export class CustomerFavoriteDeleteAction implements Action {
  readonly type = CustomerFavoriteActionTypes.Delete_Favorite;
  constructor(public payload: CustomerFavorite) {}
}
export class CustomerFavoriteDeleteSuccessAction implements Action {
  readonly type = CustomerFavoriteActionTypes.Delete_Favorite_Success;
  constructor(public payload: CustomerFavorite) {}
}
export class CustomerFavoriteDeleteFailureAction implements Action {
  readonly type = CustomerFavoriteActionTypes.Delete_Favorite_Failure;
  constructor(public payload: Error) {}
}

export type CustomerFavoriteActions =
  | CustomerFavoriteLoadAction
  | CustomerFavoriteLoadSuccessAction
  | CustomerFavoriteLoadFailureAction
  | CustomerFavoriteAddAction
  | CustomerFavoriteAddSuccessAction
  | CustomerFavoriteAddFailureAction
  | CustomerFavoriteUpdateAction
  | CustomerFavoriteUpdateSuccessAction
  | CustomerFavoriteUpdateFailureAction
  | CustomerFavoriteDeleteAction
  | CustomerFavoriteDeleteSuccessAction
  | CustomerFavoriteDeleteFailureAction;
