import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { cloneDeep } from 'lodash';
import { Application } from '../../../conversation-api';
import { createReducer } from '../../../shared/utilities';
import { ChatNotification, ExtendedMessage, UserConversationEntity } from '../../models';
import { ExtendedConversation } from '../../models/extended-conversation';
import { ConversationActions, ConversationActionTypes } from '../actions';

function reduceMessages(conversation: ExtendedConversation, messages: ExtendedMessage[]): ExtendedMessage[] {
  const msgs = conversation.messages.concat(messages);
  const msgIds = [...new Set(msgs.map((x) => x.id))];

  return msgIds.map((x) => msgs.find((y) => y.id === x));
}

export interface ConversationState extends EntityState<ExtendedConversation> {
  firstLoad: boolean;
  loading: boolean;
  loadingSelectedConversation: boolean;
  notificationsDisabled: boolean;
  selectedConversationId: number;
  notificationCount: number;
  userContextNotifications: ChatNotification[];
  serviceHubApplication: Application;
  userConversationEntities: UserConversationEntity[];
}

export const adapter: EntityAdapter<ExtendedConversation> = createEntityAdapter<ExtendedConversation>({
  selectId: (x) => x.id,
});

const initialState: ConversationState = adapter.getInitialState({
  firstLoad: false,
  loading: false,
  loadingSelectedConversation: false,
  notificationsDisabled: false,
  selectedConversationId: null,
  notificationCount: 0,
  serviceHubApplication: null,
  userConversationEntities: [],
  userContextNotifications: [],
});

const _conversationReducer = createReducer(
  initialState,
  function (state: ConversationState = initialState, action: ConversationActions): ConversationState {
    switch (action.type) {
      case ConversationActionTypes.Reset: {
        // remove conversations, conversationEntity, selected conversation
        return adapter.removeAll({
          ...state,
          loading: false,
          notificationCount: 0,
          userConversationEntities: [],
          userContextNotifications: [],
          selectedConversationId: null,
        });
      }
      case ConversationActionTypes.LoadServiceHubApplication_Success: {
        return { ...state, serviceHubApplication: action.payload };
      }
      case ConversationActionTypes.UpdateUserConversationEntities: {
        return { ...state, userConversationEntities: action.payload };
      }
      case ConversationActionTypes.Search:
      case ConversationActionTypes.Load: {
        return { ...state, loading: true };
      }
      case ConversationActionTypes.Search_Failure:
      case ConversationActionTypes.Load_Failure: {
        return { ...state, loading: false };
      }
      case ConversationActionTypes.Load_Success:
      case ConversationActionTypes.Search_Success: {
        const entities = adapter.getSelectors().selectAll(state);
        // if we reload the conversations, ensure we mark if any are selected
        const conversations = cloneDeep(action.payload);
        if (state.selectedConversationId) {
          conversations.forEach((conversation) => {
            conversation.selected = state.selectedConversationId === conversation.id;

            // merge messages, this can happen if we deeplink and the GetMessages action returns first
            const selectedConversation = entities.find((x) => x.id === conversation.id);
            if (selectedConversation) {
              conversation.messages = reduceMessages(conversation, selectedConversation.messages);
            }
          });
        }

        return adapter.upsertMany(conversations, { ...state, loading: false, firstLoad: true });
      }
      case ConversationActionTypes.JoinNewDM: {
        return { ...state, loadingSelectedConversation: true, loading: true };
      }
      case ConversationActionTypes.JoinNewDM_Success: {
        return adapter.upsertOne(action.payload, { ...state, loadingSelectedConversation: false, loading: false });
      }
      case ConversationActionTypes.JoinNewDM_Failure: {
        return { ...state, loading: false };
      }
      case ConversationActionTypes.GetOlderMessages:
      case ConversationActionTypes.GetMessages: {
        let loading = false;
        // we can load other conversation's messages
        if (action.payload.conversationId === state.selectedConversationId) {
          loading = true;
        }

        return { ...state, loadingSelectedConversation: loading };
      }
      case ConversationActionTypes.GetOlderMessages_Success:
      case ConversationActionTypes.GetMessages_Success: {
        const entities = adapter.getSelectors().selectAll(state);
        let selectedConversation = cloneDeep(entities.find((x) => x.id === action.conversationId));
        if (!selectedConversation) {
          selectedConversation = new ExtendedConversation();
        }
        // merge messages in and then sort by created
        if (!selectedConversation.messages) {
          selectedConversation.messages = [];
        }

        selectedConversation.messages = reduceMessages(selectedConversation, action.messages);

        let loading = state.loadingSelectedConversation;
        // we can load other conversation's messages
        if (action.conversationId === state.selectedConversationId) {
          loading = false;
        }

        return adapter.updateOne(
          {
            id: action.conversationId,
            changes: { messages: selectedConversation.messages, hasMoreMessages: action.hasOlderMessages },
          },
          { ...state, loadingSelectedConversation: loading }
        );
      }
      case ConversationActionTypes.Select_Conversation_Success: {
        const updates = adapter.getSelectors().selectAll(state);
        return adapter.updateMany(
          updates.map((x) => ({
            id: x.id,
            changes: { ...x, selected: action.conversationId === x.id },
          })),
          { ...state, selectedConversationId: action.conversationId }
        );
      }
      case ConversationActionTypes.Close_Selected_Conversation_Success: {
        const updates = adapter.getSelectors().selectAll(state);
        return adapter.updateMany(
          updates.map((x) => ({
            id: x.id,
            changes: { ...x, selected: false },
          })),
          { ...state, selectedConversationId: null }
        );
      }
      case ConversationActionTypes.AddIncomingMessages: {
        const selectedConversation = cloneDeep(
          adapter
            .getSelectors()
            .selectAll(state)
            .find((x) => x.id === action.conversationId)
        );
        if (!selectedConversation) {
          throw new Error('No conversation selected');
        }
        // merge messages in and then sort by created
        if (!selectedConversation.messages) {
          selectedConversation.messages = [];
        }

        // update the id of the message in case we don't have it
        action.messages.forEach((x) => {
          if (!x.correlationId) {
            return;
          }
          const exists = selectedConversation.messages.find((y) =>
            y && y.correlationId ? y.correlationId.toLocaleLowerCase() === x.correlationId.toLocaleLowerCase() : false
          );

          if (exists && !exists.id) {
            // signalr message came in for new message before we got a response back from CreateMessage
            // so update the id and add it, it will get ignored on the CreateMessage result action
            exists.id = x.id;
          }
        });

        // select only unique messages
        selectedConversation.messages = reduceMessages(selectedConversation, action.messages);

        // update the date on the conversation so it shows correctly on the UI
        selectedConversation.lastChangeDate = new Date(Date.now());
        return adapter.updateOne({ id: action.conversationId, changes: selectedConversation }, state);
      }

      case ConversationActionTypes.DismissNotifications_Success: {
        // update message to show as read
        const update = cloneDeep(
          adapter
            .getSelectors()
            .selectAll(state)
            .find((x) => x.id === state.selectedConversationId)
        );

        update.messages.forEach((x) => (x.isRead = true));

        return adapter.updateOne(
          { id: state.selectedConversationId, changes: update },
          { ...state, notificationCount: action.notificationCount, userContextNotifications: action.userContextNotifications }
        );
      }
      case ConversationActionTypes.UpdateNotificationCount_Success: {
        return { ...state, notificationCount: action.notificationCount, userContextNotifications: action.userContextNotifications };
      }
      case ConversationActionTypes.AddMessage: {
        const update = cloneDeep(
          adapter
            .getSelectors()
            .selectAll(state)
            .find((x) => x.id === action.message.conversationId)
        );
        update.messages.push(action.message);

        // update the date on the conversation so it shows correctly on the UI
        update.lastChangeDate = new Date(Date.now());

        return adapter.updateOne({ id: action.message.conversationId, changes: update }, state);
      }
      case ConversationActionTypes.AddMessage_Success: {
        const update = cloneDeep(
          adapter
            .getSelectors()
            .selectAll(state)
            .find((x) => x.id === action.message.conversationId)
        );

        // check and see if we already got an incoming message from signalr
        let msg = update.messages.find((x) => x.id === action.message.id);

        if (!msg && action.message.correlationId) {
          // update the message with the message id returned from signalr,
          msg = update.messages.find(
            (x) => x.correlationId && x.correlationId.toLocaleLowerCase() === action.message.correlationId.toLocaleLowerCase()
          );
          msg.id = action.message.id;
        }

        return adapter.updateOne({ id: action.message.conversationId, changes: update }, state);
      }
      case ConversationActionTypes.ReadReceipt_Success: {
        // update message to show as read
        const update = cloneDeep(
          adapter
            .getSelectors()
            .selectAll(state)
            .find((x) => x.id === action.payload.conversationId)
        );

        const msg = update.messages.find((x) => x.id === action.payload.messageId);

        msg.isRead = true;

        return adapter.updateOne({ id: action.payload.conversationId, changes: update }, state);
      }
      case ConversationActionTypes.DisableNotifications: {
        return { ...state, notificationsDisabled: action.disableNotifications };
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function conversationReducer(state: ConversationState, action: ConversationActions): ConversationState {
  return _conversationReducer(state, action);
}
export const selectors = adapter.getSelectors();
