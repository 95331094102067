<p-overlayPanel #linehaulOverlay showTransitionOptions="0ms" hideTransitionOptions="0ms">
  <table *ngIf="linehaulRateData" class="pricing-table">
    <thead>
      <tr>
        <th></th>
        <th>{{ linehaulRateTitle }}</th>
        <th *ngIf="fuelRate">
          <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
        </th>
        <th *ngIf="fuelRate">
          <span *ngIf="!isEstimatedFSC">FSC</span>
          <span *ngIf="isEstimatedFSC"><i>* Est FSC </i></span>
        </th>
        <th *ngIf="loadshopFee">
          <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
        </th>
        <th *ngIf="loadshopFee">Fee</th>
        <th>
          <fa-icon [icon]="['fas', 'equals']" size="md"></fa-icon>
        </th>
        <th>Total Rate</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td></td>
        <td>
          {{ linehaulRate | currency }}
        </td>
        <td *ngIf="fuelRate">
          <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
        </td>
        <td *ngIf="fuelRate">
          {{ fuelRate | currency }}
        </td>
        <td *ngIf="loadshopFee">
          <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
        </td>
        <td *ngIf="loadshopFee">
          {{ loadshopFee | currency }}
        </td>
        <td>
          <fa-icon [icon]="['fas', 'equals']" size="md"></fa-icon>
        </td>
        <td class="pricing-detail" [ngClass]="getCalculatedRateFontStyles()">
          {{ totalRate | currency }}
        </td>
        <td></td>
      </tr>
      <tr kbxlActionCheck action="loadshop.ui.breakeven.view" *ngIf="isBrokeredLoad && !linehaulRateData?.isCounterOffer">
        <td></td>
        <td></td>
        <td colspan="2" *ngIf="fuelRate"></td>
        <td colspan="2" *ngIf="loadshopFee"></td>
        <td></td>
        <td>
          <div>
            <label class="breakeven-text"> Break-even </label>
            <div class="breakeven-value" [ngClass]="getCalculatedRateFontStyles()">
              {{ totalQuotePrice | currency }}
            </div>
          </div>
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
</p-overlayPanel>
