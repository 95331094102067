import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { firstBy } from 'thenby';
import { BaseComponent } from '../../../shared/components';
import { UserModel } from '../../../shared/models';
import { ExtendedConversation } from '../../models/extended-conversation';

@Component({
  selector: 'kbxl-conversation-list',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationListComponent extends BaseComponent implements OnChanges {
  @Input() conversations: ExtendedConversation[] = [];
  visibleConversations: ExtendedConversation[] = [];

  @Input() user: UserModel;
  @Input() loading = true;
  @Input() loaded = false;
  @Input() isMobile = false;
  @Input() urlConversationId: number;
  @Output() conversationSelected: EventEmitter<ExtendedConversation> = new EventEmitter<ExtendedConversation>();
  @Output() closeSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() {
    super();
    this.setVisibleConversations();
  }

  ngOnChanges(): void {
    this.setVisibleConversations();
  }

  setVisibleConversations() {
    /**
     * Do not show conversations without messages, unless it's the selected convo in the URL
     */
    if (this.conversations && this.conversations.length > 0) {
      this.visibleConversations = this.conversations.filter(x =>
        (x.messages && x.messages.length > 0)
        || (
          x.id && Number.isInteger(x.id)
          && this.urlConversationId && Number.isInteger(this.urlConversationId)
          && x.id === this.urlConversationId
        )
      );
    }
  }

  selectConversation(conversation: ExtendedConversation): void {
    if (conversation) {
      this.conversationSelected.emit(conversation);
    }
    // used to indicate a new conversation is being joined and loading, but for mobile, close the sidebar
    if (this.isMobile) {
      this.closeSidebar.emit(true);
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  getUser(conversation: ExtendedConversation): string {
    if (this.user && this.user.isShipper && conversation) {
      return conversation.getCarrierUserFullName();
    } else if (conversation) {
      return conversation.getShipperUserFullName();
    } else {
      return 'Unknown';
    }
  }

  getRecipient(conversation: ExtendedConversation): string {
    if (this.user && this.user.isShipper && conversation) {
      return conversation.getCarrierName();
    } else if (conversation) {
      return conversation.getShipperName();
    } else {
      return 'Unknown';
    }
  }

  getMostRecentMessage(conversation: ExtendedConversation): string {
    if (!conversation.messages || conversation.messages.length === 0) {
      return 'No messages';
    }
    return conversation.messages.sort(firstBy((x) => x.createDate, 'desc'))[0].value;
  }

  hasUnreadOrNotification(conversation: ExtendedConversation): boolean {
    if (!conversation || !conversation.messages) {
      return false;
    }

    return conversation.messages.find((x) => !x.isRead) != null;
  }
}
