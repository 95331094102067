<div>
  <p-selectButton
    id="days"
    multiple="true"
    styleClass="daysButton"
    [(ngModel)]="days"
    [options]="possibleDays"
    (onChange)="onChange()"
  ></p-selectButton>
</div>
