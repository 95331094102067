<div class="d-flex flex-column wrapper">
  <div>
    <div class="kbxl-field-label">
      <label class="control-label">{{ getLabel() }}</label>
    </div>
    <p-autoComplete
      [(ngModel)]="selectedEntity"
      [suggestions]="userResults"
      (completeMethod)="searchUsers($event)"
      (onSelect)="createChat($event)"
      minLength="3"
      field="userDisplayName"
      placeholder="Search by name"
      emptyMessage="No results found"
    >
      <ng-template let-item pTemplate="item">
        <div class="d-flex align-items-center" *ngIf="item.isShipper; else carrierView">
          <div class="d-flex flex-column">
            <b>{{ item.userDisplayName }}</b>
            <span>{{ item.entityName }}</span>
          </div>

          <button pButton type="button" class="p-button-teal ml-auto btn-sm" label="Chat" [disabled]="loading"></button>
        </div>
        <ng-template #carrierView>
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column">
              <b>{{ item.userDisplayName }}</b>
              <span>{{ item.entityId }} - {{ item.entityName }}</span>
            </div>

            <button pButton type="button" class="p-button-teal ml-auto btn-sm" label="Chat" [disabled]="loading"></button>
          </div>
        </ng-template>
      </ng-template>
    </p-autoComplete>
  </div>
</div>
