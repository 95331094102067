<div class="d-flex flex-column wrapper">
  <div class="my-3">
    <p>
      {{ confirmationText }}
      <span *ngIf="breakEvenRate && breakEvenRate !=''" class="breakEven-warning">{{ breakEvenRate }}</span>
    </p>
  </div>
  <div class="kbxl-inputgroup-container mb-0 mr-2" *ngIf="commentRequired">
    <div for="comment" class="kbxl-field-label">
      <label for="comment" class="control-label">Comment</label>
    </div>
    <textarea
      placeholder="Comment is required"
      rows="3"
      cols="62"
      pInputTextarea
      name="comments"
      maxlength="4000"
      [(ngModel)]="comment"
    ></textarea>
  </div>
  <div class="d-flex justify-content-between mt-3">
    <button pButton type="button" class="p-button-outlined p-button-secondary" label="No" (click)="no()"></button>
    <button pButton type="button" label="Yes" (click)="yes()" [disabled]="commentRequired && (!comment || comment.length < 1)"></button>
  </div>
</div>
