import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LoadBoardState } from '../../../../load-board/store';
import { BaseComponent } from '../../../../shared/components';
import { CarrierAgreementData, UserAdminData, UserModel } from '../../../../shared/models';
import { SecurityAppActionType } from '../../../../shared/models/security-app-action-type';
import { getUserProfileModel } from '../../../../user/store';
import {
  AdminState,
  getAllAuthorizedCarriers,
  getCarrierAgreements,
  getCarriersLoading,
  getCarrierUsers,
  getLoadingCarrierUsers,
  UserAdminClearSelectedUserAction,
  UserAdminLoadAuthorizedEntitiesAction,
  UserAdminLoadCarrierUsersDataAction,
} from '../../../store';

@Component({
  selector: 'kbxl-carrier-container',
  templateUrl: './carrier-container.component.html',
  styleUrls: ['./carrier-container.component.scss'],
})
export class CarrierContainerComponent extends BaseComponent implements OnInit {
  loadingCarriers$: Observable<boolean>;
  authorizedCarriers$: Observable<SelectItem[]>;
  loadingCarrierUsers$: Observable<boolean>;
  carrierUsers$: Observable<UserAdminData[]>;
  carrierAgreements$: Observable<CarrierAgreementData[]>;
  user: UserModel;

  carriers: SelectItem[];
  selectedCarrier: string;
  showCarrierSelector = false;
  constructor(private store: Store<LoadBoardState>, private adminStore: Store<AdminState>) {
    super();
  }

  ngOnInit(): void {
    // Populate Dropdown Data
    this.adminStore.dispatch(new UserAdminLoadAuthorizedEntitiesAction());

    this.loadingCarriers$ = this.adminStore.pipe(select(getCarriersLoading));
    this.carrierUsers$ = this.adminStore.pipe(select(getCarrierUsers));
    this.authorizedCarriers$ = this.adminStore.pipe(
      select(getAllAuthorizedCarriers),
      map((items) =>
        this.toSelectItems(
          items,
          (x) => x.carrierName,
          (x) => x.carrierId
        )
      )
    );
    this.loadingCarrierUsers$ = this.adminStore.pipe(select(getLoadingCarrierUsers));
    this.carrierAgreements$ = this.adminStore.pipe(select(getCarrierAgreements));

    this.store.pipe(select(getUserProfileModel), takeUntil(this.destroyed$)).subscribe((x) => {
      this.user = x;
      this.showCarrierSelector = false;
      if (this.user) {
        this.showCarrierSelector = this.user.hasSecurityAction(SecurityAppActionType.CarrierUserAddEdit);

        // if the user is not an admin, automatically load their primary scac as the selected scac
        if (this.user.primaryCarrierId && this.user.primaryCarrierId.length > 0) {
          this.selectedCarrier = this.user.primaryCarrierId;
          this.carrierSelected();
        }
      }
    });
  }

  carrierSelected() {
    this.adminStore.dispatch(new UserAdminLoadCarrierUsersDataAction(this.selectedCarrier));
    this.adminStore.dispatch(new UserAdminClearSelectedUserAction());
  }

  toSelectItems<T>(items: T[], getLabel: (item: T) => any, getValue: (item: T) => any): SelectItem[] {
    return items.map((item) => ({ label: getLabel(item), value: getValue(item) }));
  }
}
