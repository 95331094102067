import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import {
  CarrierLoadAction,
  CoreState,
  SmartSpotClearCreateOrderFromQuote,
  SmartSpotPriceResetAction,
  getAllFuelRateEstimates,
  getAnySmartSpotsLoading,
  getBookingEligibleCarriers,
  getCommodities,
  getEquipment,
  getLoadingBookingEligibleCarriers,
  getLoadingCommodities,
  getLoadingServiceTypes,
  getLoadshopSettings,
  getManualLoadServiceTypes,
  getServiceTypes,
} from 'src/app/core/store';
import { SmartSpotPriceState } from 'src/app/core/store/reducers/smart-spot-price.reducer';
import { BaseComponent } from 'src/app/shared/components';
import { MarketplacePostWarningXTimes } from 'src/app/shared/constants';
import {
  CarrierProfile,
  Commodity,
  CustomerData,
  Equipment,
  IShippingLoadDetail,
  LoadStop,
  LoadshopSetting,
  ServiceType,
  ShippingLoadDetail,
  TransactionType,
  UserModel,
} from 'src/app/shared/models';
import { ShippingLoadFilter } from 'src/app/shared/models/shipping-load-filter';
import { ValidationProblemDetails } from 'src/app/shared/models/validation-problem-details';
import { TransactionTypeService } from 'src/app/shared/services';
import { UserState, getUserProfileModel } from 'src/app/user/store';
import { ShippingLoadDetailComponent } from '../..';
import {
  ShippingLoadDetailContractRatesCancelAllPollingAction,
  ShippingLoadDetailDeleteLoadAction,
  ShippingLoadDetailDiscardChanges,
  ShippingLoadDetailLoadAllAction,
  ShippingLoadDetailPostLoadsAction,
  ShippingLoadDetailRemoveLoadAction,
  ShippingLoadDetailSelectLoadAction,
  ShippingLoadDetailUnselectLoadAction,
  ShippingPostedFilterCriteria,
  ShippingState,
  getLatestLoadTransactionLoading,
  getLoadingShippingLoadDetails,
  getShippingHomeLoads,
  getShippingHomeSelectedLoads,
  getShippingLoadsLoading,
  getShippingPostValidationProblemDetails,
  getShippingPostedFilterCriteria,
  getShippingSuccessfullyPostedLoads,
} from '../../../store';
import { ShippingLoadRateIncreaseConfirmActionComponent } from '../shipping-load-rate-increase-confirm-action';

@Component({
  selector: 'kbxl-shipping-load-home-container',
  templateUrl: './shipping-load-home-container.component.html',
  styleUrls: ['./shipping-load-home-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class ShippingLoadHomeContainerComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChildren('sldcmp') components: QueryList<ShippingLoadDetailComponent>;
  // all loads available for viewing
  loads$: Observable<ShippingLoadDetail[]>;
  // loads that are filtered based on the search criteria
  filteredLoads$: Observable<ShippingLoadDetail[]>;
  // availableLoads: ShippingLoadDetail[];
  selectedLoads: ShippingLoadDetail[];
  loads: ShippingLoadDetail[];
  loading$: Observable<boolean>;
  equipment$: Observable<Equipment[]>;
  carriers$: Observable<CarrierProfile[]>;
  commodities$: Observable<Commodity[]>;
  serviceTypes$: Observable<ServiceType[]>;
  manualLoadServiceTypes$: Observable<ServiceType[]>;
  postValidationProblemDetails$: Observable<ValidationProblemDetails>;
  loadingAnySmartSpotPrices$: Observable<boolean>;
  loadingLatestTransaction$: Observable<boolean>;
  loadshopSettings: LoadshopSetting[];
  filterCriteria: ShippingLoadFilter = new ShippingLoadFilter();
  displayFilterCriteriaDialog = false;

  allowPostLoads: Observable<boolean>;
  user: UserModel;
  isBrokeredShipper = false;

  private searchSubject = new BehaviorSubject<ShippingLoadFilter>(null);
  private queryReferenceLoadIds: string[] = [];
  private initialSelectionsMade = false;
  private sortInitialLoadsToTop = false;
  private validPostTransactionTypes: TransactionType[];

  constructor(
    private shippingStore: Store<ShippingState>,
    private smartSpotPriceStore: Store<SmartSpotPriceState>,
    private coreStore: Store<CoreState>,
    private confirmationService: ConfirmationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private transactionTypeService: TransactionTypeService,
    private dialogService: DialogService,
    private userStore: Store<UserState>
  ) {
    super();

    this.validPostTransactionTypes = this.transactionTypeService.getTransactionTypesForPost();
  }

  ngOnInit() {
    // dispatch load actions
    this.coreStore.dispatch(new CarrierLoadAction());

    // Get global data used for all detail views
    this.equipment$ = this.coreStore.pipe(select(getEquipment));
    this.carriers$ = this.coreStore.pipe(select(getBookingEligibleCarriers));
    this.commodities$ = this.coreStore.pipe(select(getCommodities));
    this.serviceTypes$ = this.coreStore.pipe(select(getServiceTypes));
    this.manualLoadServiceTypes$ = this.coreStore.pipe(select(getManualLoadServiceTypes));
    this.loadingAnySmartSpotPrices$ = this.coreStore.pipe(select(getAnySmartSpotsLoading));
    this.loadingLatestTransaction$ = this.shippingStore.pipe(select(getLatestLoadTransactionLoading));
    this.postValidationProblemDetails$ = this.shippingStore.pipe(select(getShippingPostValidationProblemDetails));
    this.coreStore
      .pipe(select(getLoadshopSettings), takeUntil(this.destroyed$))
      .subscribe((settings) => (this.loadshopSettings = settings));

    this.loads$ = this.shippingStore.pipe(select(getShippingHomeLoads));
    const queryParams$ = this.activatedRoute.queryParams;

    const loadsLoading$ = this.shippingStore.pipe(select(getShippingLoadsLoading));

    // when the loads are finished loading, then check the query params
    // note that the shipping store will get updated each call for supplemental data (smart spot, carriers, etc)
    // so we listen for query param changes only to determine which loads to select
    combineLatest([loadsLoading$, queryParams$])
      .pipe(
        filter(([loading, params]) => !loading),
        map(([loaded, params]) => params),
        withLatestFrom(this.loads$),
        takeUntil(this.destroyed$)
      )
      .subscribe(([params, loads]) => {
        if (!loads || loads.length === 0) {
          return;
        }
        this.loads = loads;

        const sep = params.sep;
        const loadIds = params.loadids;
        if (sep && loadIds && loadIds.length > 0) {
          const tempIds = (params.loadids as string).split(params.sep).map((_) => _.toLowerCase());

          // select only unique
          const setLoadIds = new Set(tempIds);
          this.queryReferenceLoadIds = [...setLoadIds];
          this.sortInitialLoadsToTop = !this.initialSelectionsMade;
        } else if (this.initialSelectionsMade) {
          this.queryReferenceLoadIds = [];
        }
        this.initialSelectionsMade = true;

        if (this.queryReferenceLoadIds && this.queryReferenceLoadIds.length > 0) {
          this.selectLoads();
        }
      });

    this.filteredLoads$ = combineLatest([
      this.loads$.pipe(map((_) => this.sortQueryLoadsToTop(_))),
      this.searchSubject.pipe(debounceTime(350)),
    ]).pipe(map(([loads, searchFilter]) => this.filterLoadsOnQuickSearch(loads, searchFilter)));

    const selectedLoads$ = this.shippingStore.pipe(
      select(getShippingHomeSelectedLoads),
      map((_) => this.deepClone(_))
    );

    const allFuelRateSuccess$ = this.coreStore.pipe(
      select(getAllFuelRateEstimates),
      takeUntil(this.destroyed$),
      map((_) => this.deepClone(_))
    );

    this.allowPostLoads = combineLatest([selectedLoads$, allFuelRateSuccess$]).pipe(
      map(([selectedLoads, fuelRateSuccess]) => {
        this.selectedLoads = selectedLoads.map((y) => new ShippingLoadDetail(y));
        return (
          this.selectedLoads.every(
            (_) => this.validPostTransactionTypes.includes(TransactionType[_.latestTransactionTypeId]) && !_.hasRateSeekerError
          ) && fuelRateSuccess.every((_) => !_.fuelError)
        );
      })
    );

    this.loading$ = combineLatest([
      this.shippingStore.pipe(select(getLoadingShippingLoadDetails)),
      this.coreStore.pipe(select(getLoadingServiceTypes)),
      this.coreStore.pipe(select(getLoadingCommodities)),
      this.coreStore.pipe(select(getLoadingBookingEligibleCarriers)),
    ]).pipe(map(([a, b, c, d]) => a || b || c || d));

    this.shippingStore
      .pipe(
        select(getShippingPostedFilterCriteria),
        takeUntil(this.destroyed$),
        map((_) => this.deepClone(_))
      )
      .subscribe((updateFilter) => {
        if (updateFilter) {
          this.filterCriteria = updateFilter;
          this.searchSubject.next(updateFilter);
        }
      });

    this.shippingStore
      .select(getShippingSuccessfullyPostedLoads)
      .pipe(
        tap((_) => this.clearQueryParams(_)),
        takeUntil(this.destroyed$)
      )
      .subscribe((postedLoads: ShippingLoadDetail[]) => {
        this.handlePostedLoads(postedLoads);
      });

    this.userStore.pipe(select(getUserProfileModel), takeUntil(this.destroyed$)).subscribe((userProfile) => {
      this.user = userProfile;

      if (userProfile) {
        const shipper = userProfile.authorizedShippers.find((x) => x.customerId === userProfile.primaryCustomerId) as CustomerData;
        this.isBrokeredShipper = shipper && shipper.brokeredLoads;

        // load the available loads when we have a user profile, or refresh if its changes (impersonation)
        this.shippingStore.dispatch(new ShippingLoadDetailLoadAllAction());
      }
    });
  }

  ngOnDestroy(): void {
    this.shippingStore.dispatch(new ShippingLoadDetailContractRatesCancelAllPollingAction());
    if (this.selectedLoads) {
      this.shippingStore.dispatch(new ShippingLoadDetailUnselectLoadAction(this.selectedLoads.map((x) => x.loadId)));

      this.selectedLoads.forEach((load) => {
        this.shippingStore.dispatch(new ShippingLoadDetailDiscardChanges(load));
      });
    }
    // reset to ensure loads get appropriate smart spot
    this.smartSpotPriceStore.dispatch(new SmartSpotPriceResetAction());
    return super.ngOnDestroy();
  }

  selectLoads(): void {
    if (!this.queryReferenceLoadIds || this.queryReferenceLoadIds.length === 0) {
      return;
    }

    const selectLoadIds = [];
    this.queryReferenceLoadIds.forEach((x) => {
      const load = this.loads.find((y) => y.referenceLoadId.toLocaleLowerCase() === x.toLocaleLowerCase());
      if (load && !load.selected) {
        selectLoadIds.push(load.loadId);
      }
    });
    if (selectLoadIds.length > 0) {
      // only select the loads we have, depending on what junk gets passed in we might not have the loadId
      this.shippingStore.dispatch(new ShippingLoadDetailSelectLoadAction(selectLoadIds));
    }
  }

  deselectLoad(loadId: string): void {
    const remainingSelectedLoads = this.selectedLoads.filter((x) => x.loadId !== loadId).map((x) => x.referenceLoadId);

    let queryParmsObj = { loadids: null, sep: null };
    if (remainingSelectedLoads.length > 0) {
      queryParmsObj = {
        loadids: remainingSelectedLoads.join(';'),
        sep: ';',
      };
    }
    this.router.navigate([], {
      queryParams: queryParmsObj,
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
    });
    this.shippingStore.dispatch(new ShippingLoadDetailUnselectLoadAction([loadId]));
  }

  private includesIgnoreCase(string1: string, string2: string): boolean {
    if (string1 === string2) {
      return true;
    }
    if (!string1 || !string2) {
      return false;
    }
    return string1.toLocaleLowerCase().includes(string2.toLocaleLowerCase());
  }

  clearQueryParams(postedLoads: IShippingLoadDetail[]) {
    if (postedLoads) {
      // everything posted, clear the query params
      this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { loadids: null, sep: null }, replaceUrl: true });
    }
  }

  handlePostedLoads(postedLoads: ShippingLoadDetail[]) {
    if (!postedLoads) {
      return;
    }

    this.shippingStore.dispatch(new ShippingLoadDetailUnselectLoadAction(postedLoads.map((_) => _.loadId)));
  }

  loadSelected(load: ShippingLoadDetail) {
    const loadIds = [...this.queryReferenceLoadIds, load.referenceLoadId];

    this.router.navigate([], {
      queryParams: {
        loadids: loadIds.join(';'),
        sep: ';',
      },
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
    });
  }

  loadUnselected(load: ShippingLoadDetail) {
    if (load.hasChanges) {
      this.confirmationService.confirm({
        message:
          'There are unsaved changes on the load. Are you sure you want to close the load and discard the changes?' +
          '<br/><br/>' +
          'To save changes to the load select No and then click POST.',
        accept: () => {
          this.deselectLoad(load.loadId);
          this.shippingStore.dispatch(new ShippingLoadDetailDiscardChanges(load));
        },
      });
    } else {
      this.deselectLoad(load.loadId);
    }
  }

  postLoads() {
    const loadsToPost = this.components.map((x) => x.postLoad());
    const loadsWithMoreThanBreakEven: ShippingLoadDetail[] = null;
    let loadsForRateConfirmation: ShippingLoadDetail[] = null;
    let loadsWithTotalRateXTimeSmartSpot: ShippingLoadDetail[] = null;
    loadsWithTotalRateXTimeSmartSpot = this.getLoadsWithXTimesSmartSpot(loadsToPost);
    if (loadsWithTotalRateXTimeSmartSpot != null && loadsWithTotalRateXTimeSmartSpot.length > 0) {
      const newLoads = loadsWithTotalRateXTimeSmartSpot.filter((item) => {
        return !loadsWithMoreThanBreakEven?.find((rm) => rm.loadId === item.loadId);
      });
      if (loadsWithMoreThanBreakEven != null) {
        loadsForRateConfirmation = loadsWithMoreThanBreakEven.concat(newLoads);
      } else {
        loadsForRateConfirmation = newLoads;
      }
    } else {
      loadsForRateConfirmation = loadsWithMoreThanBreakEven;
    }
    if (loadsForRateConfirmation != null && loadsForRateConfirmation.length > 0) {
      const ref = this.dialogService.open(ShippingLoadRateIncreaseConfirmActionComponent, {
        data: {
          loads: loadsForRateConfirmation,
        },
        width: '50%',
        style: { 'max-width': 'calc(100vw - 200px)', overflow: 'auto', 'max-height': '70%' },
        header: 'Marketplace Rate Confirmation',
      });

      ref.onClose.pipe(takeUntil(this.destroyed$)).subscribe((result) => {
        if (result && result.confirmed) {
          this.shippingStore.dispatch(new ShippingLoadDetailPostLoadsAction(loadsToPost));
        }
      });
    } else {
      this.shippingStore.dispatch(new ShippingLoadDetailPostLoadsAction(loadsToPost));
    }
  }
  private getLoadsWithXTimesSmartSpot(loads: ShippingLoadDetail[]) {
    if (loads != null && loads.length > 0) {
      const xTimes = Number(this.loadshopSettings.find((i) => i.key === MarketplacePostWarningXTimes).value);
      const loadsWithXTimes = loads.filter(
        (i) => i.smartSpotRate && i.lineHaulRate + i.shippersFSC >= Number((Number(i.smartSpotRate.toFixed(2)) * xTimes).toFixed(2))
      );
      return loadsWithXTimes;
    }
  }
  createLoad() {
    this.coreStore.dispatch(new SmartSpotClearCreateOrderFromQuote());

    this.router.navigate(['/shipping/home/create']);
  }

  removeLoad(load: ShippingLoadDetail) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove this load from the Marketplace?  It will remain in Post.',
      accept: () => {
        const card = this.components.find((x) => x.details.loadId === load.loadId);
        if (card) {
          card.removedFromMarketplace();
        }

        this.shippingStore.dispatch(new ShippingLoadDetailRemoveLoadAction(load.loadId));
      },
    });
  }

  deleteLoad(load: ShippingLoadDetail) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to completely delete this load from Loadshop?',
      accept: () => {
        this.shippingStore.dispatch(new ShippingLoadDetailDeleteLoadAction(load.loadId));
      },
    });
  }

  /*
   ** On initial load, if an application deep links into this page, we should sort the loads to the top
   */
  sortQueryLoadsToTop(loads: ShippingLoadDetail[]): ShippingLoadDetail[] {
    if (this.sortInitialLoadsToTop && loads && this.queryReferenceLoadIds && this.queryReferenceLoadIds.length > 0) {
      const queryLoads: ShippingLoadDetail[] = [];
      loads = loads.filter((_) => {
        const queryLoad = this.queryReferenceLoadIds.includes(_.referenceLoadId.toLowerCase());
        if (queryLoad) {
          queryLoads.push(_);
        }
        return !queryLoad;
      });
      loads = queryLoads.concat(loads);
    }
    return loads;
  }

  onFilterChange(filterCriteria: ShippingLoadFilter) {
    if (filterCriteria && filterCriteria.quickFilter) {
      filterCriteria.quickFilter = filterCriteria.quickFilter.trim();
    }
    this.shippingStore.dispatch(new ShippingPostedFilterCriteria(filterCriteria));
  }

  viewFilterCriteria() {
    this.displayFilterCriteriaDialog = true;
  }

  clearFilter(prop: any) {
    this.filterCriteria = new ShippingLoadFilter();
    this.onFilterChange(this.filterCriteria);
  }

  trackByFn(idx: number, item: ShippingLoadDetail) {
    return item.loadId;
  }

  private filterLoadsOnQuickSearch(loads: ShippingLoadDetail[], searchFilter: ShippingLoadFilter): ShippingLoadDetail[] {
    if (!searchFilter) {
      return loads;
    }
    const quickFilter = searchFilter.quickFilter || '';
    return loads.filter(
      (x) =>
        (!searchFilter.equipment ||
          searchFilter.equipment.length === 0 ||
          searchFilter.equipment.map((equip) => equip.equipmentId).some((equip) => equip === x.equipmentId)) &&
        (!searchFilter.customerLoadTypes ||
          searchFilter.customerLoadTypes.length === 0 ||
          searchFilter.customerLoadTypes.map((type) => type.customerLoadTypeId).some((type) => type === x.customerLoadTypeId)) &&
        (!searchFilter.origin ||
          x.loadStops.some(
            (y: LoadStop, i: number, stops: LoadStop[]) =>
              stops[0].stopNbr === y.stopNbr &&
              (!searchFilter.origin.city || this.includesIgnoreCase(y.city, searchFilter.origin.city)) &&
              (!searchFilter.origin.state || this.includesIgnoreCase(y.state, searchFilter.origin.stateAbbrev)) &&
              (!searchFilter.origin.country || this.includesIgnoreCase(y.country, searchFilter.origin.country))
          )) &&
        (!searchFilter.dest ||
          x.loadStops.some(
            (y: LoadStop, i: number, stops: LoadStop[]) =>
              stops[stops.length - 1].stopNbr === y.stopNbr &&
              (!searchFilter.dest.city || this.includesIgnoreCase(y.city, searchFilter.dest.city)) &&
              (!searchFilter.dest.state || this.includesIgnoreCase(y.state, searchFilter.dest.stateAbbrev)) &&
              (!searchFilter.dest.country || this.includesIgnoreCase(y.country, searchFilter.dest.country))
          )) &&
        (!searchFilter.shipperUsers ||
          searchFilter.shipperUsers.length === 0 ||
          searchFilter.shipperUsers
            .filter((user) => user && user.value && user.value.trim().length > 0)
            .map((user) => user.value)
            .some((username) => username.toLowerCase() === x.createBy.toLowerCase())) &&
        // quick filter below
        (quickFilter === '' ||
          this.includesIgnoreCase(x.referenceLoadDisplay, quickFilter) ||
          this.includesIgnoreCase(x.equipmentTypeDisplay, quickFilter) ||
          x.loadStops.some((y) => this.includesIgnoreCase(y.city, quickFilter) || this.includesIgnoreCase(y.state, quickFilter)))
    );
  }
}
