import { Component, Input, OnInit } from '@angular/core';
import { autoApproveCounterOfferMessage } from '../../constants';

@Component({
  selector: 'kbxl-auto-approve-warning',
  templateUrl: './auto-approve-warning.component.html',
  styleUrls: ['./auto-approve-warning.component.scss'],
})
export class AutoApproveWarningComponent implements OnInit {
  autoApproveWarningMessage: string = autoApproveCounterOfferMessage;

  @Input() hoverWarning: boolean;
  constructor() {}

  ngOnInit(): void {}
}
