import { LoadStop } from '.';
import { defaultDeliveryStop, defaultPickupStop } from './load-stop';

export interface OrderEntryLoadStop extends LoadStop {
  earlyDate: any;
  earlyTime: any;
  lateDate: any;
  lateTime: any;
  stateName: any;
}

export const defaultOrderEntryPickupStop: OrderEntryLoadStop = {
  ...defaultPickupStop,
  earlyDate: '',
  earlyTime: '',
  lateDate: '',
  lateTime: '',
  stateName: '',
};

export const defaultOrderEntryDeliveryStop: OrderEntryLoadStop = {
  ...defaultDeliveryStop,
  earlyDate: '',
  earlyTime: '',
  lateDate: '',
  lateTime: '',
  stateName: '',
};

export function fromLoadStops(loadStops: LoadStop[]): OrderEntryLoadStop[] {
  const oeStops: OrderEntryLoadStop[] = [];
  loadStops.forEach((stop) => {
    oeStops.push({ ...defaultOrderEntryDeliveryStop, ...stop });
  });

  return oeStops;
}
