<kbxl-admin-header></kbxl-admin-header>
<div class="d-flex flex-column position-relative">
  <div class="p-4">
    <div class="mb-3">
      <p-panel [toggler]="'header'" [toggleable]="true" [collapsed]="true" styleClass="">
        <p-header class="mr-2">Global Accessorials</p-header>
        <kbxl-accessorial-container></kbxl-accessorial-container>
      </p-panel>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
