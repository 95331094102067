<div class="d-flex flex-column">
  <div class="overflow-auto">
    <p>Would you like to go to the Post tab, create a new load, or copy the load just created?</p>
  </div>
  <div class="text-right mt-4">
    <button
      pButton
      type="button"
      label="Go to Post tab"
      (click)="goToPostTab_Click()"
      class="p-button-outlined p-button-secondary mr-2"
    ></button>
    <button pButton type="button" label="Create New Load" (click)="createAnotherLoad_Click()" class="ml-2"></button>
    <button pButton type="button" label="Copy Load" (click)="copyLoad_Click()" class="ml-2"></button>
  </div>
</div>
