<div class="page-container">
  <div class="d-flex flex-column align-items-center py-4">
    <div class="agreement-container p-4">
      <h1 id="terms">TERMS OF SERVICES AGREEMENT</h1>
      <p>
        This Terms of Services Agreement (the &quot;Agreement&quot;) describes the terms and conditions by which KBX Logistics, LLC
        (&quot;KBXL&quot;) offers to you access to its website <span class="text-primary">https://loadshop.kbxl.com</span> (the
        &quot;Site&quot;) and the associated services provided therein. BY ACCESSING THE SITE AND CLICKING THE &quot;I ACCEPT&quot; BOX
        BELOW, YOU ARE ACCEPTING THIS AGREEMENT AND THE PRIVACY POLICY FOUND HERE
        <a href="https://privacypolicy.kochind.com/" target="_blank">https://privacypolicy.kochind.com/</a> (WHICH PRIVACY POLICY PROVIDES
        INFORMATION ON HOW KBXL COLLECTS, USES AND DISCLOSES INFORMATION ABOUT USERS OF SERVICES), ON BEHALF OF YOURSELF AND THE COMPANY AND
        ITS USERS (AS THOSE TERMS ARE DEFINED BELOW). ALL REFERENCES TO &quot;YOU&quot; INCLUDE THE COMPANY AND ITS USERS. YOU REPRESENT AND
        WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THIS AGREEMENT, ON BEHALF OF YOURSELF AND THE COMPANY. Please
        read this Agreement carefully before using the Services. Use of the Services is conditioned on your agreement to all of the terms
        and conditions contained in the Agreement, including the policies and terms linked to or otherwise referenced in this Agreement, all
        of which are hereby incorporated into this Agreement. In the event any separate contract or like document governs or otherwise
        impacts the parties' rights, obligations or relationship, this Agreement shall govern to the extent it does not conflict with the
        terms of any such other contract or like document. IF YOU DO NOT SO AGREE, YOU SHOULD DECLINE THIS AGREEMENT AND NOT CLICK THE
        &quot;I ACCEPT&quot; BOX, IN WHICH CASE YOU ARE PROHIBITED FROM ACCESSING OR USING THE SERVICES.
      </p>
      <ol type="1">
        1. DEFINED TERMS.
        <ol type="a">
          <li><strong>&quot;Account&quot;</strong>&nbsp;<span>has the meaning set forth in Section 3 below.</span></li>
          <li>
            <strong>&quot;Ancillary Agreements&quot;</strong>&nbsp;<span
              >means, as applicable, the Broker Agreement and the Motor Carrier Agreement.</span
            >
          </li>
          <li>
            <strong>&quot;Broker Agreement&quot;</strong>&nbsp;<span
              >means the agreement located below (<a class="link text-primary" (click)="scrollToTarget('broker')">click here</a>) or, if the
              Company and KBXL are parties to an existing separate agreement for Broker Services that is in effect, such separate
              agreement.</span
            >
          </li>
          <li>
            <strong>&quot;Broker Services&quot;</strong>&nbsp;<span
              >means KBXL will arrange for transportation of Shipper's freight using Motor Carriers, as more fully described in the Broker
              Agreement.</span
            >
          </li>
          <li>
            <strong>&quot;Company&quot;</strong>&nbsp;<span
              >means the entity you represent, as well as each employee, director and owners thereof.</span
            >
          </li>
          <li>
            <strong>&quot;Company Content&quot;</strong>&nbsp;<span
              >means any and all information, data, textual, audio, and/or visual content, commentary and feedback, ratings, reviews and
              other content that a User submits to the Services. Company Content includes the information provided in a Shipment request or
              acceptance.
            </span>
          </li>
          <li>
            <strong>&quot;Direct Services&quot;</strong>&nbsp;<span
              >means transportation Services provided directly by a Motor Carrier to a Shipper (other than any Broker Services).</span
            >
          </li>
          <li><strong>&quot;KBXL&quot;</strong>&nbsp;<span>has the meaning set forth in the opening paragraph. </span></li>
          <li>
            <strong>&quot;Motor Carrier&quot;</strong>&nbsp;<span
              >means any Company providing Motor Carrier Services or Direct Services.
            </span>
          </li>
          <li>
            <strong>&quot;Motor Carrier Agreement&quot;</strong>&nbsp;<span
              >means the existing, separate agreement for Motor Carrier Services that is in effect between KBXL and Motor Carrier.</span
            >
          </li>
          <li>
            <strong>&quot;Motor Carrier Services&quot;</strong>&nbsp;<span
              >means intra-provincial, extra-provincial, interstate and/or intrastate transportation of goods for compensation, as more
              fully described in the Motor Carrier Agreement.</span
            >
          </li>
          <li>
            <strong>&quot;Services&quot;</strong>&nbsp;<span
              >means, as applicable, Motor Carrier Services, Broker Services and/or Direct Services.
            </span>
          </li>
          <li><strong>&quot;Shipper&quot;</strong>&nbsp;<span>means any Company receiving Broker Services or Direct Services.</span></li>
          <li><strong>&quot;Site&quot;</strong>&nbsp;<span>has the meaning set forth in the opening paragraph.</span></li>
          <li>
            <strong>&quot;User&quot;</strong>&nbsp;<span>is any individual who accesses or uses the Services on behalf of Company.</span>
          </li>
        </ol>

        <li>
          <strong>DESCRIPTION OF SERVICES.</strong>
          <ol type="a">
            <li>
              <strong>Shipper.</strong>&nbsp;<span>If you are a Shipper, you may receive Services in two ways:</span>
              <ol type="1">
                <li>
                  You may receive Broker Services. Broker Services are provided by KBXL. All Broker Services are subject to the terms and
                  conditions contained in the Broker Agreement which are hereby incorporated herein.
                </li>
                <li>
                  You may receive Direct Services. Direct Services are provided to you via a separate agreement between you and the Motor
                  Carrier.
                </li>
              </ol>
            </li>
            <li>
              <strong>Motor Carrier.</strong>&nbsp;<span>If you are a Motor Carrier, you may provide Services in two ways:</span>
              <ol type="1">
                <li>
                  You may provide Motor Carrier Services. Motor Carrier Services are provided to KBXL. All Motor Carrier Services are
                  subject to the terms and conditions contained in the Motor Carrier Agreement which are hereby incorporated herein.
                </li>
                <li>
                  You may provide Direct Services. Direct Services are provided by you via a separate agreement between you and the Shipper.
                </li>
              </ol>
            </li>

            <li>
              <strong>Payment for Direct Services.</strong>&nbsp;<span
                >The Company shall pay KBXL the applicable fees set forth in the fee schedule found at [insert embed link to Loadshop fee
                schedule] or as otherwise agreed upon by you and KBXL.</span
              >
            </li>
            <li>
              <strong>Indemnity for Direct Services.</strong>&nbsp;<span
                >KBXL has no liability for any Direct Services. TO THE FULLEST EXTENT PERMITTED BY LAW, SHIPPER AND MOTOR CARRIER WILL
                DEFEND, PROTECT, INDEMNIFY AND HOLD KBXL AND ITS AFFILIATED ENTITIES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS, MANAGERS,
                PARTNERS, EMPLOYEES, AND REPRESENTATIVES HARMLESS FROM AND AGAINST ALL CLAIMS, LIABILITY, DAMAGES, DEMANDS, LAWSUITS, CAUSES
                OF ACTION, STRICT LIABILITY CLAIMS, PENALTIES, FINES, ADMINISTRATIVE LAW ACTIONS AND ORDERS, EXPENSES (INCLUDING, BUT NOT
                LIMITED TO, REASONABLE ATTORNEYS' FEES) AND COSTS OF EVERY KIND AND CHARACTER ARISING OUT OF OR INCIDENT TO DIRECT
                SERVICES.</span
              >
            </li>
          </ol>
        </li>
        <li>
          <strong>LICENSES.</strong>
          <ol type="a">
            <li>
              <strong>Accounts.</strong>&nbsp;<span
                >In order to use the Services, you must register for an account with KBXL (&quot;Account&quot;) and provide certain
                information as prompted. You represent and warrant that: (a) all required registration information you submit is truthful
                and accurate; and (b) you will maintain the accuracy of such information. You may cancel your Account at any time, for any
                reason, by contacting KBXL or, if applicable, following the instructions on the Site. You are responsible for maintaining
                the confidentiality of your Account login credentials and are fully responsible for all activities that occur under your
                Account. You agree to immediately notify KBXL of any unauthorized use, or suspected unauthorized use of your Account or any
                other breach of security. You acknowledge and agree that you and the Company are solely responsible for the actions of any
                user that gains access to your Account and that KBXL is not liable for any losses or damages to Company caused by you, any
                user gaining access to an Account, or any failure to comply with the terms and conditions of this Agreement.</span
              >
            </li>
            <li>
              <strong>Site/License.</strong>&nbsp;<span
                >KBXL owns the Site and all intellectual property therein. Subject to the terms of this Agreement, KBXL grants you a
                limited, non-transferable, non-exclusive, revocable license to use the Site for your internal business use only during the
                term of this Agreement.
              </span>
            </li>
            <li>
              <strong>User Conduct.</strong>&nbsp;<span>While using the Site, you agree not to:</span>
              <ol type="1">
                <li>restrict or inhibit any other user from using and enjoying the Site;</li>
                <li>
                  post or transmit any fraudulent, libelous, defamatory, obscene, pornographic, abusive, offensive, or otherwise unlawful
                  material or information of any kind;
                </li>
                <li>
                  post or transmit any advertisements, solicitations, chain letters, pyramid schemes, or other unsolicited commercial
                  communication (except as otherwise expressly permitted by KBXL) or engage in spamming or flooding;
                </li>
                <li>post or transmit any information or software which contains a virus, trojan horse, worm or other harmful component;</li>
                <li>
                  publish, transmit, reproduce, or distribute in any way, information, software or other material obtained through the Site
                  which is protected by copyright, or other proprietary right, without obtaining permission of the copyright owner or right
                  holder;
                </li>
                <li>
                  post, publish, reproduce, transmit, distribute, or create derivative works based on the Site or any information or content
                  provided by the Site other than in connection with your Company's internal business purposes; or
                </li>
                <li>
                  reproduce, duplicate, copy, sell, or resell any content or portion of the Site, design elements or layout of the Site, use
                  of the Site, or access to the Site.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>OWNERSHIP.</strong>&nbsp;<span
            >The Site contains the proprietary, confidential and other information of KBXL and others that is protected by copyright,
            trademark, service mark, patent, and other proprietary rights and laws. You have no license or other right to use any trademark,
            service mark, or other copyrightable material or intellectual property of KBXL or its affiliates without the prior written
            consent of KBXL.
          </span>
        </li>

        <li>
          <strong>MODIFICATION OF THE SERVICE.</strong>&nbsp;<span
            >KBXL reserves the right, at any time, to modify, suspend, or discontinue the Services or any part thereof with or without
            notice. You agree that KBXL will not be liable to you or to any third party for any modification, suspension, or discontinuance
            of the Services or any part thereof.
          </span>
        </li>

        <li>
          <strong>MONITORING.</strong>&nbsp;<span
            >KBXL reserves the right, but has no obligation, to electronically monitor activities by you on the Site, including the review
            of all Company Content, and to disclose any information as necessary or appropriate to satisfy any law, regulation or other
            governmental request, to operate the Site properly, or to protect the Site and its sponsors and users, or KBXL or any of its
            affiliates. KBXL reserves the right at any time to refuse to post, to remove, or to block or limit access by any party to any
            information or posted or uploaded materials at any time.</span
          >
        </li>

        <li>
          <strong>USER CONTENT </strong>
          <ol type="a">
            <li>
              <strong>Company Content.</strong>&nbsp;<span
                >You are solely responsible for your Company Content. You assume all risks associated with use of your Company Content,
                including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your Company Content that
                makes you or any third party personally identifiable. You hereby represent and warrant that your Company Content does not
                violate any provision of this Agreement. For the avoidance of doubt, Company Content may include third party content you
                submit. You agree not to submit third party content unless you have the consent of the applicable third party owner of such
                content. You may not state or imply that your Company Content is in any way provided, sponsored or endorsed by KBXL. You
                acknowledge and agree that KBXL is not responsible for any loss or damage resulting from anyone's use or reliance on Company
                Content and KBXL makes no guarantees regarding the accuracy, completeness, usefulness currency, suitability, or quality of
                any Company Content, and assumes no responsibility for any Company Content.
              </span>
            </li>
            <li>
              <strong>License.</strong>&nbsp;<span
                >You hereby grant, and represent and warrant that you have the right to grant, to KBXL an irrevocable, nonexclusive,
                royalty-free and fully paid, sublicenseable, worldwide license, to use, copy, modify, create derivative works of,
                distribute, publicly display, publicly perform, and otherwise exploit in any manner such Company Content in all formats and
                distribution channels, now known or hereafter devised (including, without limitation, in connection with the Services and
                KBXL's business and on third-party sites and services), without further notice to or consent from you, and without the
                requirement of payment to you or any other person or entity. All rights in and to the Company Content not expressly granted
                to KBXL in this Agreement are reserved by Users.
              </span>
            </li>
            <li>
              <strong>Anonymous Data.</strong>&nbsp;<span
                >KBXL may create anonymous data records (&quot;Anonymous Data&quot;) from your Company Content by using commercially
                reasonable efforts to exclude any and all information (such as company name) that makes the data identifiable to you. KBXL
                may use and disclose Anonymous Data for any purpose, including improving the Services.
              </span>
            </li>
            <li>
              <strong>Disclosure.</strong>&nbsp;<span
                >KBXL may share your Company Content (a) with third party service providers; (b) if another company acquires KBXL; and/or
                (c) to comply with relevant laws, to respond to subpoenas or warrants or assist in preventing any violation or potential
                violation of the law or this Agreement.
              </span>
            </li>
          </ol>
        </li>
        <li>
          <strong>THIRD PARTY SITES AND LOCATION INFORMATION </strong>
          <ol type="a">
            <li>
              <strong>Third Party Sites.</strong>&nbsp;<span
                >Services might contain links to third party websites, services, and advertisements for third parties (collectively,
                &quot;Third Party Sites&quot;). Such Third Party Sites are not under the control of KBXL and KBXL is not responsible for any
                Third Party Sites. KBXL does not review, approve, monitor, endorse, warrant, or make any representations with respect to
                Third Party Sites. You use all Third Party Sites at your own risk. You should make whatever investigation you feel necessary
                or appropriate before proceeding with any transaction in connection with such Third Party Sites.</span
              >
            </li>
            <li>
              <strong>Location Information.</strong>
              <p>
                data provided by the Services is for basic location purposes only and is not intended to be relied upon in situations where
                precise location information is needed or where erroneous, inaccurate or incomplete location data may lead to death,
                personal injury, property or environmental damage, or other loss. Neither KBXL, nor any of its content providers, guarantees
                the availability, accuracy, completeness, reliability, or timeliness of location data displayed by the Services, whether
                provided by KBXL, third party content providers, or Users. Geolocational data that you upload, provide, or post on the
                Services may be accessible to certain Users of the Services. You assume any and all risk of providing such data to other
                Users of the Services.
              </p>
            </li>
            <li>
              <strong>Google Maps.</strong>
              <p>
                The Services use Google Maps features and content. The use of Google Maps features and content is subject to the
                then-current versions of the:
              </p>
              <ul style="list-style-type: none">
                <li>
                  (1) Google Maps/Google Earth Additional Terms of Service at: <br />
                  <a href="https://maps.google.com/help/terms_maps.html" target="_blank">https://maps.google.com/help/terms_maps.html</a>;
                  and
                </li>
                <li>
                  (2) Google Privacy Policy at: <br />
                  <a href="https://www.google.com/policies/privacy/" target="_blank">https://www.google.com/policies/privacy/</a>.
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <strong>INDEMNITY.</strong>&nbsp;<span
            >You agree to defend, indemnify and hold KBXL (and its officers, employees, and agents) harmless, including costs and attorneys'
            fees, from any claim or demand made by any third party due to or arising out of your (i) use of the Services, (ii) Company
            Content, (iii) interaction with any other User, (iv) violation of this Agreement; (v) violation of applicable laws or
            regulations; or (vi) your shipment. KBXL reserves the right, at your expense, to assume the exclusive defense and control of any
            matter for which you are required to indemnify KBXL and you agree to cooperate with KBXL's defense of these claims. You agree
            not to settle any matter without KBXL's prior written consent. KBXL will use reasonable efforts to notify you of any such claim,
            action or proceeding upon becoming aware of it. This Section 9 shall survive the expiration or termination of this
            Agreement.</span
          >
        </li>
        <li>
          <strong>TERM OF AGREEMENT.</strong>&nbsp;<span
            >This Agreement will remain in full force and effect while you use the Services. KBXL may at any time terminate this Agreement
            in its sole discretion for any or no reason. Upon termination of this Agreement, your Account and right to access and use the
            Services will terminate immediately.</span
          >/li>
        </li>

        <li>
          <strong>GENERAL PROVISIONS</strong>
          <ol type="a">
            <li>
              <strong>Governing Law.</strong>&nbsp;<span
                >This Agreement and all disputes, claims or causes of action arising out of or relating to this Agreement
                (&quot;Disputes&quot;) will be governed by the laws of the State of Delaware, without regard to that State's conflict of law
                principles. The parties agree that the appropriate, exclusive and convenient forum (the &quot;Forum&quot;) for any Disputes
                will be in the Court of Chancery in the City of Wilmington, New Castle County, Delaware, except where such court lacks
                subject matter jurisdiction. In such event, the Forum will be in the federal district court sitting in Wilmington, New
                Castle County, Delaware or, in the event such federal district court lacks subject matter jurisdiction, then in the Superior
                Court in the City of Wilmington, New Castle County, Delaware. The parties hereby irrevocably and unconditionally consent to
                submit to the exclusive jurisdiction of such courts for any Disputes and agree not to commence any action, suit or
                proceeding relating thereto except in such courts, and further agree that service of any process, summons, notice or
                document by U.S. registered mail to the address identified below will be effective service of process for any action, suit
                or proceeding brought against a party in any such court. The parties hereby irrevocably and unconditionally waive any
                objection which they may now or hereafter have to the laying of venue of any action, suit or proceeding arising out of this
                Agreement or the transactions contemplated hereby in the Forum, and hereby further irrevocably and unconditionally waive and
                agree not to plead or claim in any such court that any such action, suit or proceeding brought in any such court has been
                brought in an inconvenient forum. THE PARTIES EACH WAIVE, TO THE FULLEST EXTENT PERMITTED BY LAW, ANY RIGHT IT MAY HAVE TO A
                TRIAL BY JURY IN RESPECT OF ANY SUIT, ACTION, CLAIM OR PROCEEDING ARISING OUT OF OR RELATING TO THIS AGREEMENT. For purposes
                of this section, the address for Company will be such address provided to KBXL during the registration process for accessing
                the Site and the address for KBXL will be 1919 S. Broadway, Green Bay, Wisconsin 54304, Attn: President.</span
              >
            </li>
            <li>
              <strong>Independent Contractors.</strong>&nbsp;<span
                >KBXL and Company are independent contractors, and except to the extent required by law, no agency, partnership, joint
                venture, employee-employer or franchisor-franchisee relationship is intended or created by this Agreement.
              </span>
            </li>
            <li>
              <strong>Entire Agreement.</strong>&nbsp;<span
                >This Agreement, together with the Ancillary Agreements, constitutes the entire agreement and understanding of the parties
                with respect to the subject matter hereof and may not be modified, altered or amended except in writing and executed by both
                parties to this Agreement. Any additional or inconsistent terms or conditions on any purchase order or other documentation
                provided by Company, whether oral or written, will have no effect and such terms and conditions are expressly disclaimed and
                excluded.</span
              >
            </li>
            <li>
              <strong>Headings.</strong>&nbsp;<span
                >Headings in this Agreement are for convenience only and will not be considered in the interpretation of this
                Agreement.</span
              >
            </li>
            <li>
              <strong>Notices.</strong>&nbsp;<span
                >Any notice or written communication required or permitted to be given by a party hereunder will be made by email, hand
                delivery, facsimile transmission (promptly confirmed by another permitted method of delivery) or overnight delivery at the
                address(s) (i) for KBXL, specified on the Site, and (ii) for Shipper or Motor Carrier, specified in the Account. Any such
                notice, written communication or e-mail message will be considered to have been received on the date of hand delivery or
                transmission by facsimile or e-mail or the next business day after sent by overnight delivery service.</span
              >
            </li>
            <li>
              <strong>Severability.</strong>&nbsp;<span
                >If any provision of this Agreement is declared invalid or unenforceable by a court of competent jurisdiction, then such
                provision will be deemed automatically modified to conform to the requirements for validity and enforceability as declared
                at that time, and as so modified, will be deemed a provision of this Agreement as though originally included herein.</span
              >
            </li>
            <li>
              <strong>No Third Party Beneficiaries.</strong>&nbsp;<span
                >Except in respect of rights granted to KBXL's affiliates herein, this Agreement is solely for the benefit of, and will
                inure to the benefit of, KBXL and Company, and will not otherwise be deemed to confer upon or give to any third party any
                right, claim, cause of action or other interest herein.</span
              >
            </li>
            <li>
              <strong>Force Majeure.</strong>&nbsp;<span
                >Neither party will be liable for its delay or failure to perform any of its obligations hereunder during any period in
                which performance is delayed by any cause beyond the reasonable control of such party, including, without limitation, by
                fire, flood, war, embargo, riot, acts of terrorism, government act or failure or delay cause by any Internet or other
                telecommunications service provider (&quot;Force Majeure&quot;), provided that the party suffering such delay did not cause
                such Force Majeure event and promptly notifies the other party of the delay, and uses all commercially feasible efforts to
                mitigate its effects, including developing an immediate action plan to continue performance of its obligations under this
                Agreement.
              </span>
            </li>
            <li>
              <strong>Assignment.</strong>&nbsp;<span
                >Company may not assign this Agreement or any rights or obligations hereunder (including, but not limited to, entering into
                any subcontract) to any party without the prior written consent of KBXL.</span
              >
            </li>
            <li>
              <strong>Changes to Agreement.</strong>&nbsp;<span
                >This Agreement is subject to occasional revision by KBXL. Any changes to this Agreement will be effective upon the earlier
                of (a) the date you accept the new terms or (b) thirty (30) calendar days following your receipt of the notice of the
                changes. These changes will be effective immediately for new Users of the Services. Continued use of the Services following
                notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and
                conditions of such changes.
              </span>
            </li>
            <li>
              <strong>Publication of Information.</strong>&nbsp;<span
                >KBXL has your permission to identify you as a customer of KBXL or user of our Services and to reproduce your name and logo
                on the Site and in any other marketing materials. If you provide KBXL any feedback, suggestions, or other information or
                ideas regarding the Services (&quot;Feedback&quot;), you hereby assign to KBXL all rights in the Feedback and agree that
                KBXL has the right to use such Feedback and related information in any manner it deems appropriate. KBXL will treat any
                Feedback you provide as non-confidential and non-proprietary. You agree that you will not submit to KBXL any information or
                ideas that you consider to be confidential or proprietary.
              </span>
            </li>
            <li>
              <strong>Disclaimers.</strong>
              <p>
                THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." KBXL EXPRESSLY DISCLAIMS ANY WARRANTIES AND CONDITIONS OF ANY KIND NOT
                SPECIFICALLY PROVIDED IN THE ANCILLARY AGREEMENTS, WHETHER EXPRESS OR IMPLIED OR STATUTORY, INCLUDING THE WARRANTIES OR
                CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON- INFRINGEMENT. KBXL MAKES NO WARRANTY THAT THE
                SERVICES: (A) WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS; (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR
                ERROR-FREE BASIS; (C) WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE; OR (D)
                RESULT IN ANY REVENUE, PROFITS, OR COST REDUCTION. THE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS
                INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. KBXL IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES
                OR OTHER DAMAGES RESULTING FROM SUCH PROBLEMS.
              </p>
              <p>
                KBXL IS NOT A MOTOR CARRIER. NO INTERPRETATION OF WRITTEN OR ORAL REMARKS IN ANY AGREEMENT OR DOCUMENT SHALL BE CONSTRUED TO
                IMPLY KBXL IS A MOTOR CARRIER, OR THAT KBXL IS SUBJECT TO THE REGULATORY OR LEGAL REQUIREMENTS OR LIABILITIES OF A MOTOR
                CARRIER. KBXL HAS NO RESPONSIBILITY OR LIABILITY FOR ANY MOTOR CARRIER SERVICES EXCEPT AS SPECIFICALLY LISTED IN THE BROKER
                AGREEMENT.
              </p>
              <p>
                KBXL RESERVES THE RIGHT, BUT HAS NO OBLIGATION, TO MONITOR DISPUTES BETWEEN YOU AND OTHER USERS. PLEASE CAREFULLY SELECT THE
                TYPE OF INFORMATION THAT YOU POST ON THE SITE OR THROUGH THE SERVICES OR RELEASE TO OTHERS. KBXL DISCLAIMS ALL LIABILITY,
                REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR OMISSIONS OF OTHER USERS (INCLUDING UNAUTHORIZED USERS, OR
                &quot;HACKERS&quot;).
              </p>
              <p>
                THE SERVICES MAY BE TEMPORARILY UNAVAILABLE FROM TIME TO TIME FOR MAINTENANCE OR OTHER REASONS. KBXL ASSUMES NO
                RESPONSIBILITY FOR ANY ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMMUNICATIONS
                LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, OR ALTERATION OF, USER COMMUNICATIONS. KBXL IS NOT RESPONSIBLE
                FOR ANY TECHNICAL MALFUNCTION OR OTHER PROBLEMS OF ANY TELEPHONE NETWORK OR SERVICE, COMPUTER SYSTEMS, SERVERS OR PROVIDERS,
                COMPUTER OR MOBILE PHONE EQUIPMENT, SOFTWARE, FAILURE OF EMAIL OR PLAYERS ON ACCOUNT OF TECHNICAL PROBLEMS OR TRAFFIC
                CONGESTION ON THE INTERNET OR AT ANY SITE OR COMBINATION THEREOF, INCLUDING INJURY OR DAMAGE TO A USER'S OR TO ANY OTHER
                PERSON'S COMPUTER, MOBILE PHONE, OR OTHER HARDWARE OR SOFTWARE, RELATED TO OR RESULTING FROM USING OR DOWNLOADING MATERIALS
                IN CONNECTION WITH THE WEB AND/OR IN CONNECTION WITH THE SERVICES.
              </p>
            </li>
            <li>
              <strong>Limitation of Liability.</strong>
              <p>
                IN NO EVENT WILL EITHER PARTY BE LIABLE TO EACH OTHER OR TO ANY THIRD PARTY FOR THE COST OF PROCURING SUBSTITUTE GOODS OR
                SERVICES, LOST PROFITS, LOSS OF USE, LOSS OF DATA OR ANY INCIDENTAL, CONSEQUENTIAL, INDIRECT, OR SPECIAL DAMAGES, WHETHER
                UNDER CONTRACT, TORT, WARRANTY OR OTHERWISE, ARISING IN ANY WAY OUT OF THIS OR ANY OTHER RELATED AGREEMENT, WHETHER OR NOT
                SUCH PARTY HAD ADVANCE NOTICE OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
              <p>
                IN NO EVENT WILL THE CUMULATIVE LIABILITY OF KBXL AND ITS AFFILIATES BE IN EXCESS OF THE LESSER OF $100,000 AND THE AMOUNTS
                PAID TO KBXL BY YOU IN THE SIX MONTH PERIOD IMMEDIATELY PRECEDING THE FIRST CLAIM BROUGHT BY COMPANY UNDER THIS AND/OR
                RELATED AGREEMENTS, WHETHER ARISING UNDER WARRANTY/GUARANTEE, CONTRACT, NEGLIGENCE, STRICT LIABILITY, INDEMNIFICATION,
                DEFENSE OR ANY OTHER CAUSE OR COMBINATION OF CAUSES WHATSOEVER. ALL INSURANCE, BOND AND BANK GUARANTEE OR LETTER OF CREDIT
                PROCEEDS WHICH MAY BE PAID TO USER BY THE INSURERS, SURETIES OR BANKS OF KBXL OR ITS AFFILIATES WILL BE CREDITED AGAINST THE
                LIMITATION STATED ABOVE AND REDUCE THE AMOUNT OF THE CUMULATIVE LIABILITY OF KBXL AND ITS AFFILIATES.
              </p>
              <p>
                KBXL'S FEES HAVE BEEN NEGOTIATED IN CONSIDERATION OF THE ALLOCATION OF RISKS AND ESTABLISHMENT OF LIMITATIONS OF LIABILITY
                STATED IN THIS AGREEMENT, BUT FOR WHICH KBXL WOULD NOT HAVE ENTERED INTO THIS AGREEMENT.
              </p>
              <p>
                YOUR REMEDIES ARE LIMITED TO THOSE REMEDIES EXPRESSLY STATED IN THIS AGREEMENT; AND, THESE REMEDIES WILL NOT FAIL THEIR
                ESSENTIAL PURPOSE BECAUSE COMPANY IS LIMITED TO THE EXCLUSIVE REMEDIES AS STATED HEREIN.
              </p>
              <p>
                THESE LIMITATIONS WILL APPLY NOTWITHSTANDING ANY FUNDAMENTAL BREACH OR FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
              </p>
            </li>
          </ol>
        </li>
      </ol>

      <br /><br /><br />
      <h1 id="privacy">PRIVACY POLICY</h1>
      <a href="https://privacypolicy.kochind.com/" target="_blank">Click here</a>
      <br /><br /><br />
      <a id="broker"></a>
      <h1>BROKER AGREEMENT</h1>
      <ol type="1">
        <li>
          <strong>Service.</strong>&nbsp;<span
            >As requested by Shipper and subject to the provisions herein, KBXL, as a licensed broker by the Federal Motor Carrier Safety
            Administration (MC-899150-B), shall arrange for transportation of Shipper's freight, that KBXL elects to accept, and shall
            employ Motor Carriers (defined below), at KBXL's cost, which will provide the equipment and personnel necessary to transport
            such freight to and from those locations designated by Shipper, in compliance in all material respects with applicable federal,
            state and local laws and regulations. Shipper acknowledges KBXL is an independent entity in selling, negotiating, providing and
            arranging for transportation for compensation and the actual transportation of freight tendered to KBXL shall be performed by
            third-party motor carriers (the &quot;Motor Carrier(s)&quot;). KBXL shall be free to determine the means and methods of the
            performance of the transportation services provided hereunder, including, without limitation, the selection of the Motor
            Carriers.</span
          >
        </li>
        <li>
          <strong>Receipts/Bills of Lading.</strong>&nbsp;<span
            >If requested by Shipper in writing, KBXL shall provide Shipper with proof of acceptance and delivery of freight brokered under
            this Agreement in the form of a signed bill of lading or other freight documentation. Shipper's insertion of KBXL's name on the
            freight documentation shall be for Shipper's convenience only and shall not change KBXL's status as a property broker. The
            freight documentation shall act as a receipt only and in no event shall the terms and conditions of the freight documentation be
            binding upon the Parties nor supersede any provision in this Agreement.</span
          >
        </li>
        <li>
          <strong>Freight Charges.</strong>
          <ol type="a">
            <li>
              Unless otherwise agreed upon by KBXL and Shipper as described in subsections (b) and (c) below, for KBXL's services hereunder,
              Shipper shall pay KBXL the rates and other charges set forth in each rate schedule signed, and agreed upon, by the Parties (a
              &quot;Rate Schedule&quot;). Each Rate Schedule may be supplemented or revised only by written agreement signed by both Parties
              (such rates and charges as may be supplemented or revised, &quot;Freight Charges&quot;). Shipper shall pay KBXL such Freight
              Charges within fifteen (15) days after the date of KBXL's invoice for such Freight Charges, without deduction or setoff, with
              interest accruing monthly, on past due payments, at a rate equal to the lesser of one percent (1%) or the maximum rate allowed
              by applicable law.
            </li>
            <li>
              If, for certain services, KBXL and Shipper agree to a rate not listed on a Rate Schedule, such agreement shall be in writing
              (which such writing may be an email) before such services commence (a &quot;Spot Agreement&quot;). If KBXL proposes a Spot
              Agreement to Shipper and Shipper does not object to it before it accepts the commencement of services, Shipper shall be deemed
              to have agreed to the rate(s) in such Spot Agreement.
            </li>
            <li>
              Notwithstanding subsections (a) and (b) above, if, pursuant to KBXL's electronic online platform (the &quot;KBXL
              Platform&quot;), KBXL and Shipper agree KBXL will provide specific services under this Agreement for a specific rate (such
              rate so agreed via the KBXL Platform, the &quot;Online Rate&quot;) (which such Online Rate may be different than the rate set
              forth in a Rate Schedule applicable to the same services), KBXL shall invoice, and Shipper shall pay, such Online Rate for the
              specific services so agreed upon by KBXL and Shipper via the Online Platform. Such Online Rate shall be inclusive of all
              charges for the services and shall apply only to the specific services as described on the KBXL Platform. KBXL and Shipper
              agree that any Online Rate shall not apply to any future services (whether agreed upon by KBXL and Shipper via the KBXL
              Platform or otherwise), including, without limitation, services for a similar shipment with the same route.
            </li>
            <li>
              If KBXL provides services hereunder and the Parties did not agree upon Freight Charges for such services, the Freight Charges
              invoiced by KBXL shall be deemed the agreed upon Freight Charges for the services provided unless Shipper notifies KBXL, in
              writing, within ten (10) days after the invoice date, of Shipper's objection to such Freight Charges.
            </li>
            <li>
              Shipper shall reimburse KBXL for any expenses, including attorneys' fees, incurred in collecting past due amounts due under
              this Agreement.
            </li>
          </ol>
        </li>
        <li>
          <strong>Claims/Liability Limitations.</strong>
          <ol type="a">
            <li>
              <strong style="text-decoration: underline">Freight Claims.</strong>
              <ol type="i">
                <li>
                  Shipper shall (A) immediately notify KBXL, in writing, of any concealed damage to the freight, with such notification
                  being no later than five (5) days after delivery, and (B) file a written claim with KBXL for freight loss, damage or
                  destruction (&quot;Freight Claims&quot;) within one hundred eighty (180) days after the date of actual delivery or, in the
                  case of lost freight, the scheduled delivery date; provided, however, Shipper shall not file any Freight Claim unless such
                  claim exceeds US$300.00.
                </li>
                <li>
                  If payment of a Freight Claim is made by KBXL to Shipper, Shipper hereby automatically assigns to KBXL all of Shipper's
                  rights and interest in the full amount of its Freight Claim so as to allow KBXL to subrogate its loss and pursue the
                  Freight Claim with the applicable Motor Carrier.
                </li>
                <li>
                  KBXL shall use commercially reasonable efforts in the filing and/or processing of Freight Claims with the Motor Carrier on
                  the behalf of Shipper. KBXL may charge Shipper a $150 administrative fee for processing each Freight Claim submitted by
                  Shipper.
                </li>
                <li>
                  If a Motor Carrier denies a Freight Claim, in whole or in part, Shipper shall initiate a lawsuit for such Freight Claim
                  within two (2) years from the date of the denial notification from the Motor Carrier or from KBXL or its right to bring
                  such a lawsuit shall be waived.
                </li>
                <li>
                  For any Freight Claim, (A) the liability of Motor Carrier, KBXL and its affiliates shall not exceed, in the aggregate, the
                  lesser of US$100,000.00 per occurrence or the full value of the freight so lost, damaged or destroyed, and (B) neither
                  KBXL nor its affiliates shall be liable for any loss, damage, or destruction in the transportation of Shipper's freight
                  except to the extent such loss, damage, or destruction is directly caused by the negligence or willful misconduct of KBXL
                  in its performance of this Agreement. All insurance proceeds which may be paid to Shipper by the insurers or sureties of
                  KBXL, its affiliates or Motor Carrier shall be credited against the limitations stated above and reduce the amount of
                  KBXL's cumulative liability.
                </li>
              </ol>
            </li>
            <li>
              <strong style="text-decoration: underline">Non-Freight Claims.</strong>
              <ol type="i">
                <li>
                  For any claim other than a Freight Claim (&quot;Non-Freight Claims&quot;), Shipper shall (A) notify KBXL of such
                  Non-Freight Claim within sixty (60) days after the date of the event giving rise to such Non-Freight Claim and (B) file a
                  written claim with KBXL for such Non-Freight Claim within one hundred eighty (180) days after the date of delivery of the
                  applicable shipment.
                </li>
                <li>
                  Shipper shall initiate a lawsuit (including one against the Motor Carrier) for any Non-Freight Claim within eighteen (18)
                  months after the date of delivery of the applicable shipment after the date or its right to bring such a lawsuit shall be
                  waived.
                </li>
                <li>
                  For any Non-Freight Claim, (A) the liability of Motor Carrier, KBXL and its affiliates shall not exceed, in the aggregate,
                  the amount paid by Shipper to KBXL for the shipment giving rise to the Non-Freight Claim, and (B) neither KBXL nor its
                  affiliates shall be liable for any Non-Freight Claim except to the extent such claim is directly caused by the negligence
                  or willful misconduct of KBXL in its performance of this Agreement. All insurance proceeds which may be paid to Shipper by
                  the insurers or sureties of KBXL, its affiliates or Motor Carriers shall be credited against the limitations stated above
                  and reduce the amount of KBXL's cumulative liability.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>Bonding/Insurance.</strong>&nbsp;<span
            >KBXL shall comply with bonding requirements required by applicable law, including its obligation to maintain a surety bond for
            the benefit of Shipper. In addition, KBXL shall maintain the following insurance:
          </span>
          <ol type="a">
            <li>Worker's Compensation and Employer's Liability Insurance, as prescribed by applicable law.</li>
            <li>
              Commercial General Liability Insurance with a combined single limit of $1,000,000 per occurrence and in the aggregate for
              Bodily Injury and Property Damage.
            </li>
          </ol>
        </li>
        <li>
          <strong>Motor Carriers.</strong>&nbsp;<span
            >KBXL shall exercise due diligence in its selection and hiring of Motor Carriers for the performance of this Agreement, which
            includes, but is not limited to:
          </span>
          <ol type="a">
            <li>Verifying the Motor Carrier's operating authority;</li>
            <li>
              Obtaining proof of the Motor Carrier's insurance coverage, with coverage not less than:
              <ol type="i">
                <li>Commercial General Liability: US$1,000,000.00 per occurrence and in the aggregate;</li>
                <li>Auto Liability: US$1,000,000.00 per occurrence and in the aggregate;</li>
                <li>Cargo Liability: US$100,000.00 per occurrence; and</li>
                <li>Worker's Compensation: As required by law.</li>
              </ol>
              <p>
                To the extent allowed by law and with KBXL's prior written consent, a Motor Carrier may be allowed to self-insure for any of
                the above insurance requirements.
              </p>
            </li>
            <li>Verifying the Motor Carrier does not have an "Unsatisfactory" safety rating with the FMCSA; and</li>
            <li>Executing a written contract with the Motor Carriers.</li>
          </ol>
        </li>
        <li>
          <strong>Indemnification. </strong>
          <ol type="a">
            <li>
              Subject to the limitations set forth herein (including, without limitation, Section 4 above), KBXL shall indemnify and hold
              harmless Shipper from and against any and all liabilities, claims, losses, fines, penalties, costs, and expenses (including
              reasonable attorney's fees) (collectively, &quot;Losses&quot;) Shipper may suffer, incur or pay as a result of (i) any third
              party claim for damages resulting from personal injury, death or damage to property (other than damage to the freight), to the
              extent such Losses are caused by KBXL's breach of this Agreement, negligence or willful misconduct in its performance of the
              services hereunder; and (ii) any claim against Shipper by a Motor Carrier for non-payment of freight charges for specific
              transportation performed hereunder provided Shipper made payment to KBXL of amounts due hereunder, in accordance with this
              Agreement, for the applicable transportation.
            </li>
            <li>
              Shipper shall indemnify and hold harmless KBXL from and against any and all Losses KBXL may suffer, incur or pay as a result
              of any third party claim to the extent such Losses are caused by Shipper's breach of this Agreement, negligence or willful
              misconduct.
            </li>
            <li>
              Notwithstanding anything herein, in no event shall KBXL or a Motor Carrier be liable for any special, indirect, incidental or
              consequential damages arising out of the services to be provided under this Agreement including, without limitation, damages
              that relate to loss, damage, destruction or delay to a shipment.
            </li>
          </ol>
        </li>
        <li>
          <strong>Confidentiality. </strong>&nbsp;<span
            >Neither Party shall disclose the terms of this Agreement or any Rate Schedule, Spot Agreement rate, Online Rate or any proposal
            regarding the same to a third party (other than such Party's affiliates) without the prior written consent of the other Party,
            except to the extent disclosure is (a) required by applicable law; or (b) is required or necessary for KBXL's performance under
            this Agreement. In addition, Shipper shall not use KBXL's name or identifying information in any advertising or promotional
            communications without prior written consent of KBXL. Shipper shall not publicly disclose or use, except for its own internal
            business purposes, any rate and/or performance evaluation data or metrics provided by KBXL.
          </span>
        </li>
        <li>
          <strong>Assignment. </strong>&nbsp;<span
            >Neither Party may assign or transfer this Agreement, in whole or in part, without the prior written consent of the other Party;
            provided, however, KBXL may assign this Agreement to an affiliate of KBXL, without the prior written consent of Shipper.
          </span>
        </li>
        <li>
          <strong>Independent Contractor. </strong>&nbsp;<span
            >KBXL is an independent contractor and neither this Agreement nor the performance of any of the obligations hereunder shall
            create a partnership or joint venture between Shipper, on the one hand, and KBXL, on the other hand, nor shall any agency or
            employment relationship be established.
          </span>
        </li>
        <li>
          <strong>Waiver/Amendment. </strong>&nbsp;<span
            >No provision in this Agreement can be waived, modified or amended except by written consent of both Parties which consent must
            specifically refer to the provision to be waived, modified or amended and must explicitly make such waiver, modification or
            amendment. Failure of a Party to insist upon the other Party's performance under this Agreement or to exercise any right or
            privilege arising hereunder shall not be a waiver of any such rights or privileges herein.
          </span>
        </li>
        <li>
          <strong>Force Majeure. </strong>&nbsp;<span
            >If a Party is prevented from performing its obligations under this Agreement (except for payments due hereunder) by any cause
            beyond the reasonable control of such Party and arising without its fault or negligence (&quot;Force Majeure Event&quot;),
            including without limitation, fire, labor strife, riot, war, weather conditions, acts of the public enemy, acts of God, acts of
            terrorism, local or national disruptions to transportation networks or operations, fuel shortages, governmental regulations, or
            governmental request or requisition for national defense, such failures to perform shall be excused for the duration of the
            occurrence of the Force Majeure Event.
          </span>
        </li>
      </ol>
    </div>
  </div>
</div>
