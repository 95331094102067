<div class="d-flex flex-column">
  <span>{{ referenceLoadDisplay }}</span>
  <span *ngIf="(isBrokeredLoad || customerLoadTypeId) && showIcon">
    <span *ngIf="customerLoadTypeId === CustomerLoadTypes.HighPriority">
      <strong class="badge badge-pill badge-danger customer-type-high-priority mr-2" pTooltip="High Priority">H</strong>
    </span>
    <span *ngIf="customerLoadTypeId === CustomerLoadTypes.NewShipper">
      <strong class="badge badge-pill badge-warning customer-type-new-shipper mr-2" pTooltip="New Shipper">N</strong>
    </span>
    <span *ngIf="isBrokeredLoad">
      <strong class="badge badge-pill badge-success brokered-load" pTooltip="Brokered Load">B</strong>
    </span>
  </span>
</div>
