import { createReducer } from '../../../shared/utilities';
import { AgreementDocumentActions, AgreementDocumentActionTypes } from '../actions';

export interface AgreementDocumentState {
  loading: boolean;
  loaded: boolean;
}

const initialState: AgreementDocumentState = {
  loading: false,
  loaded: false,
};

const _agreementDocumentReducer = createReducer(
  initialState,
  function (state: AgreementDocumentState = initialState, action: AgreementDocumentActions): AgreementDocumentState {
    switch (action.type) {
      case AgreementDocumentActionTypes.AcceptAgreement_Failure: {
        return {
          ...state,
          loading: false,
          loaded: true,
        };
      }
      case AgreementDocumentActionTypes.AcceptAgreement: {
        return {
          ...state,
          loading: true,
          loaded: false,
        };
      }
      case AgreementDocumentActionTypes.AcceptAgreement_Success: {
        return {
          ...state,
          loading: false,
          loaded: true,
        };
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function agreementDocumentReducer(state: AgreementDocumentState, action: AgreementDocumentActions): AgreementDocumentState {
  return _agreementDocumentReducer(state, action);
}

export const getLoading = (state: AgreementDocumentState) => state.loading;
export const getLoaded = (state: AgreementDocumentState) => state.loaded;
