import { SpotPriceQuoteResponse } from './spot-price-quote-response';

export interface SmartSpotPrice {
  // deprecated fields
  price: number;
  loadId: string;
  datGuardRate: number;
  machineLearningRate: number;
  loading: boolean;
  // This should contain the new spot price data
  spotPriceQuote?: SpotPriceQuoteResponse;
}

export const defaultSmartSpotPrice: SmartSpotPrice = {
  price: 0.0,
  loadId: null,
  datGuardRate: null,
  machineLearningRate: null,
  loading: false,
  spotPriceQuote: null,
};
