import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserFavorite } from 'src/app/shared/models';
import { createReducer } from '../../../shared/utilities';
import { FavoriteActions, FavoriteActionTypes } from '../actions/favorite.actions';

export interface FavoriteState extends EntityState<UserFavorite> {
  loading: boolean;
}

export const adapter: EntityAdapter<UserFavorite> = createEntityAdapter<UserFavorite>({
  selectId: (x) => x.userFavoriteId,
});

const initialState: FavoriteState = adapter.getInitialState({
  loading: false,
});

const _favoriteReducer = createReducer(
  initialState,
  function (state: FavoriteState = initialState, action: FavoriteActions): FavoriteState {
    switch (action.type) {
      case FavoriteActionTypes.Add_Favorite:
      case FavoriteActionTypes.Update_Favorite:
      case FavoriteActionTypes.Delete_Favorite:
      case FavoriteActionTypes.Load: {
        return { ...state, loading: true };
      }

      case FavoriteActionTypes.Add_Favorite_Failure:
      case FavoriteActionTypes.Update_Favorite_Failure:
      case FavoriteActionTypes.Delete_Favorite_Failure:
      case FavoriteActionTypes.Load_Failure: {
        return { ...state, loading: false };
      }

      case FavoriteActionTypes.Load_Success: {
        return adapter.setAll(action.payload, { ...state, loading: false });
      }
      case FavoriteActionTypes.Add_Favorite_Success: {
        return adapter.upsertMany(action.payload, { ...state, loading: false });
      }
      case FavoriteActionTypes.Update_Favorite_Success: {
        return adapter.upsertMany(action.payload, { ...state, loading: false });
      }
      case FavoriteActionTypes.Delete_Favorite_Success: {
        return adapter.removeOne(action.payload, { ...state, loading: false });
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function favoriteReducer(state: FavoriteState, action: FavoriteActions): FavoriteState {
  return _favoriteReducer(state, action);
}

export const getLoading = (state: FavoriteState) => state.loading;
export const getEntities = (state: FavoriteState) => state.entities;
export const selectAll = adapter.getSelectors().selectAll;
