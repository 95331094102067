import { createSelector } from '@ngrx/store';
import { ExtendedConversation } from '../../models';
import { ChatState, getChatFeatureState } from '../reducers';
import { selectors } from '../reducers/conversation.reducer';

const getCoversationState = createSelector(getChatFeatureState, (state: ChatState) => state.conversation);

export const getConversations = createSelector(getCoversationState, (state) => {
  const entities = selectors.selectAll(state);

  return entities.map((x) => new ExtendedConversation(x));
});
export const getConversationsFirstLoad = createSelector(getCoversationState, (state) => state.firstLoad);
export const getConversationsLoading = createSelector(getCoversationState, (state) => state.loading);
export const getSelectedConversationLoading = createSelector(getCoversationState, (state) => state.loadingSelectedConversation);

export const getSelectedConversation = createSelector(getCoversationState, (state) => {
  const entity = selectors.selectAll(state).find((x) => x.id === state.selectedConversationId);

  if (entity) {
    return new ExtendedConversation(entity);
  }
  return null;
});
export const getSelectedConversationId = createSelector(getCoversationState, (state) => state.selectedConversationId);
export const getNotificationCount = createSelector(getCoversationState, (state) => state.notificationCount);
export const getUserContextNotifications = createSelector(getCoversationState, (state) => state.userContextNotifications);
export const getServiceHubApplication = createSelector(getCoversationState, (state) => state.serviceHubApplication);
export const getUserConversationEntities = createSelector(getCoversationState, (state) => state.userConversationEntities);
export const getNotificationsDisabled = createSelector(getCoversationState, (state) => state.notificationsDisabled);
