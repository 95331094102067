import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ServiceResponse } from '../../shared/models';
import { mapResponse } from '../../shared/operators/map-response';
import { CreateChatEntityData } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private http: HttpClient) {}

  searchDirectMessageUsers(query: string): Observable<CreateChatEntityData[]> {
    return this.http
      .get<ServiceResponse<CreateChatEntityData[]>>(`${environment.apiUrl}/api/chat/users?query=${query}`)
      .pipe(mapResponse());
  }
  getUserChatNotificationPreferences(userId: string): Observable<boolean> {
    return this.http
      .get<ServiceResponse<boolean>>(`${environment.apiUrl}/api/chat/users/notification-preferences?userId=${userId}`)
      .pipe(mapResponse());
  }
}
