<div *ngIf="!conversation" class="d-lg-none">
  <h6 class="text-muted text-center my-4">Please select a conversation or start a new.</h6>
</div>
<div class="d-flex flex-column" *ngIf="conversation">
  <div class="left-separator">
    <div class="mb-2 quick-filter">
      <div class="kbxl-field-label">
        <label class="control-label">Quick Filter</label>
      </div>
      <div class="p-inputgroup">
        <input
          pInputText
          type="text"
          class="filter-box w-100"
          placeholder="Quick Search Criteria"
          [(ngModel)]="quickSearch"
          (ngModelChange)="updateSearch()"
        />
        <button
          pButton
          type="button"
          icon="pi pi-times-circle"
          class="p-button-secondary p-inputgroup-addon"
          (click)="clearSearch()"
        ></button>
      </div>
    </div>
    <div>
      <div class="position-relative text-center" *ngIf="loading">
        <div class="progress-indicator">
          <div class="small-spinner-container">
            <p-progressSpinner></p-progressSpinner>
            <div>Loading active loads</div>
          </div>
        </div>
      </div>
    </div>
    <p-virtualScroller
      *ngIf="active"
      [value]="virtualLoads"
      scrollHeight="50vh"
      [itemSize]="101"
      [rows]="pageSize"
      [lazy]="true"
      (onLazyLoad)="loadActiveLoadsLazy($event)"
      #virtualScroller
    >
      <ng-template pTemplate="item" let-load>
        <div class="active-load list-group-item list-group-item-action" [class.active]="selectedLoadId === load?.loadId">
          <div class="d-flex w-100 align-items-center justify-content-between mb-1">
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-window-maximize"
              class="p-button-rounded p-button-text p-0 expand-button"
              (click)="selectLoad(load)"
            ></button>
            <span class="font-weight-bold">
              {{ load.referenceLoadDisplay }}
            </span>
            <small class="ml-2 border-left pl-2 text-truncate" *ngIf="getContactDisplay(load).length > 0">
              <fa-icon [icon]="['fas', 'user']" class=""></fa-icon>
              {{ getContactDisplay(load) | titlecase }}
            </small>
            <small class="badge ml-auto text-white" [ngClass]="getBadgeStyleClass(load)">{{ getBadgeDisplay(load) }}</small>
          </div>
          <div class="d-flex trip-display">
            <div class="d-flex flex-column">
              <div class="w-100 text-truncate">
                {{ load.originDisplay }}
              </div>
              <div class="w-100">
                {{ getPickUpDate(load) | date: 'shortDate' }}
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <div class="d-flex align-items-center text-nowrap justify-content-center">
                <div class="origin-marker"></div>
                <div class="line"></div>
                <div class="destination-marker"></div>
              </div>
              <div></div>
            </div>
            <div class="d-flex flex-column text-right">
              <div class="w-100 text-truncate">
                {{ load.destinationDisplay }}
              </div>
              <div class="w-100">
                {{ getDeliveryDate(load) | date: 'shortDate' }}
              </div>
            </div>
          </div>
          <div class="d-flex mt-1">
            <small>{{ load.equipmentCategoryId }} - {{ load.equipmentTypeDisplay }}</small>
            <small class="ml-2 border-left pl-2">{{ load.lineHaulRate + load.fuelRate | currency }} </small>
            <small *ngIf="load.billingLoadDisplay && load.billingLoadDisplay.length > 0" class="ml-2 border-left pl-2"
              >{{ load.billingLoadDisplay }}
            </small>
          </div>
        </div>
      </ng-template>
    </p-virtualScroller>
  </div>
</div>
