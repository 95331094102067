export interface CustomerLaneGroupContactData {
  customerlaneGroupContactId: number;
  customerLaneGroupId: number;
  display: string;
  email: string;
  phone: string;
  sortOrder: number;
}

export const defaultCustomerLaneGroupContactData: CustomerLaneGroupContactData = {
  customerlaneGroupContactId: null,
  customerLaneGroupId: null,
  display: null,
  email: null,
  phone: null,
  sortOrder: null,
};
