import { Message } from '../../conversation-api';

export class ExtendedMessage implements Message {
  constructor(init?: Partial<ExtendedMessage>) {
    Object.assign(this, init);

    // ensure we are using the date object everywhere
    if (this.createDate && typeof this.createDate === 'string') {
      this.createDate = new Date(this.createDate);
    }
  }

  id?: number;
  userId?: string;
  userName?: string;
  conversationId?: number;
  value?: string;
  isRead?: boolean;
  createDate?: Date;
  attributes?: { [key: string]: string };
  hashCode?: string;
  applicationCode?: string;
  correlationId?: string;

  //
  // Client side fields
  //
  isUserMessage?: boolean;
  entityName?: string;
  hasLoadId?: boolean;
  loadId?: string;
}
