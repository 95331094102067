export interface AccessorialUnitOfMeasureData {
  accessorialUnitOfMeasureId: number;
  description: string;
}

export function isAccessorialUnitOfMeasure(x: any): x is AccessorialUnitOfMeasureData {
  return typeof x.accessorialUnitOfMeasureId === 'number';
}

export function isAccessorialUnitOfMeasureArray(x: any): x is AccessorialUnitOfMeasureData[] {
  return x.every(isAccessorialUnitOfMeasure);
}
