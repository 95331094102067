import { createSelector } from '@ngrx/store';
import { FuelRateEstimateByProgramData } from '../../../shared/models';
import { CoreState } from '../reducers';
import { FuelRateState, getAllFuelRates, getEntities } from '../reducers/fuel-rate.reducer';

export const getFuelRateState = (state: CoreState) => state.fuelRateState;
export const getFuelRateEstimates = createSelector(getFuelRateState, getEntities);
export const getFuelRateEstimate = createSelector(
  getFuelRateState,
  (state: FuelRateState, props: { loadId: string }): FuelRateEstimateByProgramData => {
    const entity = state.entities[props.loadId];
    return entity?.fuelProgramData;
  }
);
export const getFuelRateEstimateLoading = createSelector(getFuelRateState, (state: FuelRateState, props: { loadId: string }) =>
  state.entities[props.loadId] ? state.entities[props.loadId].loading : false
);
export const getFuelRateEstimateError = createSelector(getFuelRateState, (state: FuelRateState, props: { loadId: string }) =>
  state.entities[props.loadId] ? state.entities[props.loadId].fuelError : false
);

export const getAllFuelRateEstimates = createSelector(getFuelRateState, getAllFuelRates);
