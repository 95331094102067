import { UserCommunication } from '../../models';
import { createReducer } from '../../utilities';
import { UserCommunicationDisplayActions, UserCommunicationDisplayActionTypes } from '../actions';

export interface UserCommuncationDisplayState {
  userCommunicationsLoading: boolean;
  userCommunications: UserCommunication[];
  acknowledgePosting: boolean;
}
const initialState: UserCommuncationDisplayState = {
  userCommunicationsLoading: false,
  userCommunications: [],
  acknowledgePosting: false,
};

const _UserCommunicationDisplayReducer = createReducer(
  initialState,
  function (state: UserCommuncationDisplayState = initialState, action: UserCommunicationDisplayActions): UserCommuncationDisplayState {
    switch (action.type) {
      case UserCommunicationDisplayActionTypes.Load: {
        return { ...state, userCommunicationsLoading: true };
      }
      case UserCommunicationDisplayActionTypes.Load_Success: {
        return { ...state, userCommunicationsLoading: false, userCommunications: action.payload };
      }
      case UserCommunicationDisplayActionTypes.Load_Failure: {
        return { ...state, userCommunicationsLoading: false, userCommunications: [] };
      }

      case UserCommunicationDisplayActionTypes.Acknowledge: {
        return { ...state, acknowledgePosting: true };
      }
      case UserCommunicationDisplayActionTypes.Acknowledge_Success: {
        return { ...state, acknowledgePosting: false, userCommunications: action.payload };
      }
      case UserCommunicationDisplayActionTypes.Acknowledge_Failure: {
        return { ...state, acknowledgePosting: false };
      }
      default:
        return state;
    }
  }
);
/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function UserCommunicationDisplayReducer(
  state: UserCommuncationDisplayState,
  action: UserCommunicationDisplayActions
): UserCommuncationDisplayState {
  return _UserCommunicationDisplayReducer(state, action);
}
