import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { mapToPayload } from '@tms-ng/shared';
import { MessageService } from 'primeng/api';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AllUserFavoriteTypes, UserFavorite } from 'src/app/shared/models/user-favorite';
import { LoadshopApplicationActionTypes } from '../../../shared/store';
import { CommonService } from '../../services/common.service';
import {
  FavoriteActionTypes,
  FavoriteAddFailureAction,
  FavoriteAddSuccessAction,
  FavoriteDeleteAction,
  FavoriteDeleteFailureAction,
  FavoriteDeleteSuccessAction,
  FavoriteLoadAction,
  FavoriteLoadFailureAction,
  FavoriteLoadSuccessAction,
  FavoriteUpdateAction,
  FavoriteUpdateFailureAction,
  FavoriteUpdateSuccessAction,
} from '../actions';

@Injectable()
export class FavoriteEffects {
  @Effect()
  $load: Observable<Action> = this.actions$.pipe(
    ofType(FavoriteActionTypes.Load),
    switchMap(() =>
      this.commonService.getFavorites().pipe(
        map((data) => new FavoriteLoadSuccessAction(data)),
        catchError((err) => of(new FavoriteLoadFailureAction(err)))
      )
    )
  );

  @Effect()
  $postFavorite: Observable<Action> = this.actions$.pipe(
    ofType(FavoriteActionTypes.Add_Favorite),
    mapToPayload<UserFavorite>(),
    switchMap((payload) =>
      this.commonService.addFavorite(payload).pipe(
        map((data) => new FavoriteAddSuccessAction(data.userFavorites, payload.type, data.updatedUserFavoriteId)),
        catchError((err) => of(new FavoriteAddFailureAction(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  $addFavoriteSuccess: Observable<Action> = this.actions$.pipe(
    ofType<FavoriteAddSuccessAction>(FavoriteActionTypes.Add_Favorite_Success),
    switchMap((action) => {
      if (action.favoriteType === AllUserFavoriteTypes.UserPostTabFavorite) {
        this.messageService.add({ id: 0, detail: 'Favorite Added', severity: 'success' });
      } else if (action.favoriteType === AllUserFavoriteTypes.MarketplaceSavedSearch) {
        this.messageService.add({ id: 0, detail: 'Search has been saved', severity: 'success' });
      }

      return EMPTY;
    })
  );

  @Effect()
  $updateFavorite: Observable<Action> = this.actions$.pipe(
    ofType(FavoriteActionTypes.Update_Favorite),
    switchMap((action: FavoriteUpdateAction) =>
      this.commonService.updateFavorite(action.payload).pipe(
        map((data) => new FavoriteUpdateSuccessAction(data.userFavorites, action.payload.type, data.updatedUserFavoriteId)),
        catchError((err) => of(new FavoriteUpdateFailureAction(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  $updateFavoriteSuccess: Observable<Action> = this.actions$.pipe(
    ofType<FavoriteUpdateSuccessAction>(FavoriteActionTypes.Update_Favorite_Success),
    switchMap((action) => {
      if (action.favoriteType === AllUserFavoriteTypes.UserPostTabFavorite) {
        this.messageService.add({ id: 0, detail: 'Favorite Updated', severity: 'success' });
      } else if (action.favoriteType === AllUserFavoriteTypes.MarketplaceSavedSearch) {
        this.messageService.add({ id: 0, detail: 'Search has be updated', severity: 'success' });
      }
      return EMPTY;
    })
  );

  @Effect()
  $deleteFavorite: Observable<Action> = this.actions$.pipe(
    ofType(FavoriteActionTypes.Delete_Favorite),
    switchMap((action: FavoriteDeleteAction) =>
      this.commonService.deleteFavorite(action.payload).pipe(
        map(() => new FavoriteDeleteSuccessAction(action.payload.userFavoriteId, action.payload.type)),
        catchError((err) => of(new FavoriteDeleteFailureAction(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  $deleteFavoriteSuccess: Observable<Action> = this.actions$.pipe(
    ofType<FavoriteDeleteSuccessAction>(FavoriteActionTypes.Delete_Favorite_Success),
    switchMap((action) => {
      if (action.favoriteType === AllUserFavoriteTypes.UserPostTabFavorite) {
        this.messageService.add({ id: 0, detail: 'Favorite Deleted', severity: 'success' });
      } else if (action.favoriteType === AllUserFavoriteTypes.MarketplaceSavedSearch) {
        this.messageService.add({ id: 0, detail: 'Search Deleted', severity: 'success' });
      }

      return EMPTY;
    })
  );

  @Effect()
  $startup: Observable<Action> = this.actions$.pipe(
    ofType(LoadshopApplicationActionTypes.LoadshopStart),
    map(() => new FavoriteLoadAction())
  );

  constructor(private commonService: CommonService, private actions$: Actions, private messageService: MessageService) {}
}
