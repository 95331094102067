<div class="d-flex flex-column position-relative">
  <div class="progress-indicator" *ngIf="processing">
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <div *ngIf="userProfileForm.errors && submitted" class="p-2">
    <div class="error-container">
      <p class="m-0 pl-2 text-white" *ngIf="userProfileForm.errors['noPhoneContacts']">Contact Phone Number Must Be Provided</p>
    </div>
  </div>
  <div class="d-flex flex-column flex-lg-row p-4">
    <div class="user-selector left-tab" #userSelector>
      <div class="p-inputgroup">
        <p-autoComplete
          [(ngModel)]="selectedUser"
          [suggestions]="userResults"
          (completeMethod)="searchUsers($event)"
          (onSelect)="select()"
          field="username"
          placeholder="Search for User"
          class="w-100"
        >
          <ng-template let-item pTemplate="item">
            <span
              ><b>{{ item.username }}</b
              >-{{ item.firstName | titlecase }} {{ item.lastName | titlecase }}</span
            >
          </ng-template>
        </p-autoComplete>
        <button
          pButton
          type="button"
          icon="pi pi-plus"
          pToolTip="Setup New User"
          class="p-button-secondary p-inputgroup-addon"
          (click)="setupNewUser.toggle($event)"
        ></button>
      </div>

      <p-overlayPanel [dismissable]="true" [showCloseIcon]="true" #setupNewUser appendTo="body">
        <h3>Setup User</h3>
        <p class="mb-1">Enter the registered <b>user name</b> of the user you want to setup</p>
        <div class="kbxl-field-label">
          <label class="control-label">User Name</label>
        </div>
        <input pInputText type="text" [(ngModel)]="newUser" class="ui-user-setup-input mb-2" />

        <div class="d-flex justify-content-end">
          <button
            pButton
            type="button"
            icon="pi pi-plus"
            pToolTip="Setup New User"
            class="p-button-teal m-2"
            (click)="add(setupNewUser)"
            label="&nbsp;Add User"
          ></button>
        </div>
        <p>*If the user has not registered, they must <a [href]="registrationUrl" target="_blank">Create an Account</a></p>
      </p-overlayPanel>
    </div>
    <div class="flex-fill">
      <div *ngIf="!updatingUser">Please Select a User</div>
      <div *ngIf="updatingUser">
        <form [formGroup]="userProfileForm" (ngSubmit)="saveUser()">
          <h3>{{ updatingUser.firstName | titlecase }} {{ updatingUser.lastName | titlecase }}</h3>
          <div *ngIf="updatingUser && updatingUser.isDeleted">
            <div class="warning-container m-2">
              <div>This user has been deleted and must be manually be re-added to Loadshop to regain access.</div>
            </div>
          </div>
          <div class="d-flex flex-column flex-lg-row mb-4">
            <div class="width-3">
              <label class="user-admin-detail-label">User Name</label>
              {{ updatingUser.username }}
            </div>
            <div class="width-3">
              <label class="user-admin-detail-label"> Company Name </label>
              {{ updatingUser.companyName }}
            </div>
            <div class="width-3">
              <label class="user-admin-detail-label"> Email </label>
              <input pInputText type="email" formControlName="email" />
            </div>
          </div>
          <div class="d-flex flex-column flex-lg-row">
            <div class="width-3">
              <!--           <div class="kbxl-field-label">
                        <label class="control-label"
                            [ngClass]="{'field-error': detailErrors && detailErrors.commodity}">Commodity
                            {{(detailErrors && detailErrors.commodity) ? '*' : ''}}</label>
                        <div class="small-spinner-container ml-2">
                            <p-progressSpinner *ngIf="loadingCommodities"></p-progressSpinner>
                        </div>
                    </div> -->
              <div class="kbxl-field-label">
                <label class="control-label">Security Role(s)</label>
                <div class="small-spinner-container ml-2">
                  <p-progressSpinner *ngIf="loadingSecurityRoles"></p-progressSpinner>
                </div>
              </div>
              <div>
                <p-multiSelect
                  [options]="allAuthorizedSecurityRoles"
                  formControlName="securityRoleIds"
                  placeholder="Select Users Security Role"
                  [styleClass]="'p-dropdown-primary'"
                  resetFilterOnHide="true"
                  name="securityRoleIds"
                  (onChange)="updateDropdowns()"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="width-3" *ngIf="displayShippers()">
              <div kbxlActionCheck="loadshop.ui.system.shipper.user.addedit">
                <div class="kbxl-field-label">
                  <label class="control-label">Shipper(s)</label>
                  <div class="small-spinner-container ml-2">
                    <p-progressSpinner *ngIf="loadingAuthorizedShippers"></p-progressSpinner>
                  </div>
                </div>
                <div>
                  <p-multiSelect
                    [options]="allAuthorizedShippers"
                    formControlName="shipperIds"
                    placeholder="Select Authorized Shippers"
                    [styleClass]="'p-dropdown-primary'"
                    resetFilterOnHide="true"
                    name="shipperIds"
                  >
                  </p-multiSelect>
                </div>
              </div>
            </div>
            <div class="width-3" *ngIf="displayCarrierScacs()">
              <div kbxlActionCheck="loadshop.ui.system.carrier.user.addedit">
                <div class="kbxl-field-label">
                  <label class="control-label">Carrier/Carrier Scac(s)</label>
                  <div class="small-spinner-container ml-2">
                    <p-progressSpinner *ngIf="loadingAuthorizedCarrierScacs"> </p-progressSpinner>
                  </div>
                </div>
                <div>
                  <p-multiSelect
                    [options]="filteredCarrierScacs"
                    formControlName="carrierScacs"
                    placeholder="Select User's Select Carrier/SCACS"
                    [virtualScroll]="true"
                    (onChange)="carrierScacsChanged()"
                    itemSize="30"
                    filterBy="label"
                    filterPlaceHolder="Search Carrier/SCACS"
                    maxSelectedLabels="2"
                    [styleClass]="'p-dropdown-primary'"
                    resetFilterOnHide="true"
                    name="carrierScacs"
                  >
                    <ng-template let-item pTemplate="item">
                      <span>{{ item?.label | titlecase }}</span>
                    </ng-template>
                  </p-multiSelect>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column mt-3">
            <p-checkbox
              name="isNotificationsEnabled"
              formControlName="isNotificationsEnabled"
              label="Notifications Enabled"
              binary="true"
            ></p-checkbox>
          </div>
          <div kbxlFeature="chat" *ngIf="allNotificationsEnabled" class="d-flex flex-column mt-3">
            <p-checkbox
              name="isChatNotificationsEnabled"
              formControlName="isChatNotificationsEnabled"
              label="Chat Notifications Enabled"
              binary="true"
            ></p-checkbox>
          </div>
          <div kbxlActionCheck="loadshop.ui.system.user.counteroffer.update" class="d-flex flex-column mt-3">
            <p-checkbox name="allowCounterOffer" formControlName="allowCounterOffer" label="Allow Counter Offer" binary="true"></p-checkbox>
          </div>
          <div class="d-flex flex-column mt-3">
            <div class="kbxl-field-label">
              <label
                class="control-label"
                [ngClass]="{ error: userProfileForm.errors && submitted && userProfileForm.errors['noPhoneContacts'] }"
                >Contact Numbers</label
              >
            </div>
            <div class="flex-fill pr-4" *ngFor="let itemFormGroup of contactNumbers; let i = index">
              <div class="row kbxl-inputgroup-container contact-numbers" formArrayName="contactNumbers">
                <div [formGroupName]="i" class="col d-flex">
                  <div class="d-flex align-items-center">
                    <p-dropdown
                      class="mr-2 w-auto"
                      [options]="messageTypes"
                      placeholder="Contact Number"
                      formControlName="messageTypeId"
                      [styleClass]="'p-dropdown-primary'"
                    >
                    </p-dropdown>
                    <p-inputMask mask="(999) 999-9999" formControlName="notificationValue" placeholder="(999) 999-9999"></p-inputMask>
                    <div class="mr-2 hidden" [ngClass]="{ visible: itemFormGroup?.value?.messageTypeId === 'Phone' }">
                      <span class="mx-2">Ext.</span>
                      <input
                        pInputText
                        class="phone-extension"
                        type="tel"
                        formControlName="additionalValue"
                        placeholder="999999"
                        maxlength="6"
                      />
                    </div>
                    <button
                      *ngIf="i > 0"
                      class="p-button-danger"
                      pButton
                      icon="pi pi-trash"
                      (click)="removeContact(i)"
                      title="Delete Contact"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-fill pr-4">
              <button pButton type="button" class="p-button-teal" icon="pi pi-plus" label="Add New" (click)="addContact()"></button>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button
              pButton
              label="Delete User"
              type="button"
              class="p-button-danger m-2"
              (click)="_deleteUser()"
              *ngIf="updatingUser && !updatingUser.isDeleted"
            ></button>
            <button pButton label="Save User" type="submit" class="m-2"></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
