import { createSelector } from '@ngrx/store';
import { getLoadBoardFeatureState, LoadBoardState } from '../reducers';
import {
  getDeliveredQueryHelper,
  getDeliveredTotalRecords,
  getErrorLoadId,
  getLoaded,
  getLoading,
  getPhoneError,
  getSavingLoadId,
  getTruckError,
  selectors,
} from '../reducers/load-board-delivered.reducer';

const getLoadBoardDeliveredState = createSelector(getLoadBoardFeatureState, (state: LoadBoardState) => state.delivered);

export const getLoadBoardDeliveredLoads = createSelector(getLoadBoardDeliveredState, selectors.selectAll);
export const getLoadBoardDeliveredLoading = createSelector(getLoadBoardDeliveredState, getLoading);
export const getLoadBoardDeliveredLoaded = createSelector(getLoadBoardDeliveredState, getLoaded);
export const getLoadBoardDeliveredSavingLoadId = createSelector(getLoadBoardDeliveredState, getSavingLoadId);
export const getLoadBoardDeliveredErrorLoadId = createSelector(getLoadBoardDeliveredState, getErrorLoadId);
export const getLoadBoardDeliveredPhoneError = createSelector(getLoadBoardDeliveredState, getPhoneError);
export const getLoadBoardDeliveredTruckError = createSelector(getLoadBoardDeliveredState, getTruckError);
export const getLoadBoardDeliveredTotalRecords = createSelector(getLoadBoardDeliveredState, getDeliveredTotalRecords);
export const getLoadBoardDeliveredQueryHelper = createSelector(getLoadBoardDeliveredState, getDeliveredQueryHelper);
