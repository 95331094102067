import { Customer } from '.';
import { AppointmentSchedulerConfirmationType } from './appointment-scheduler-confirmation-type';
import { Commodity } from './commodity';
import { CustomerLoadType } from './customer-load-type';
import { DropdownOption } from './dropdown-option';
import { Equipment } from './equipment';
import { LoadDocumentType } from './load-document-type-data';
import { LoadshopSetting } from './loadshop-setting';
import { ServiceType } from './service-type';
import { State } from './state';
import { TransportationMode } from './transportation-mode';
import { UnitOfMeasure } from './unit-of-measure';

export interface StartupData {
  carrierAuthorizedShippers: Customer[];
  commodities: Commodity[];
  appointmentSchedulerConfirmationTypes: AppointmentSchedulerConfirmationType[];
  customerLoadTypes: CustomerLoadType[];
  unitsOfMeasure: UnitOfMeasure[];
  transportationModes: TransportationMode[];
  serviceTypes: ServiceType[];
  states: State[];
  dropDownOptions: DropdownOption[];
  equipment: Equipment[];
  loadshopSettings: LoadshopSetting[];
  documentTypes: LoadDocumentType[];
}

export const defaultStartupData: StartupData = {
  commodities: [],
  appointmentSchedulerConfirmationTypes: [],
  customerLoadTypes: [],
  unitsOfMeasure: [],
  transportationModes: [],
  serviceTypes: [],
  states: [],
  dropDownOptions: [],
  equipment: [],
  loadshopSettings: [],
  documentTypes: [],
  carrierAuthorizedShippers: [],
};
