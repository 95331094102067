<div class="progress-indicator" *ngIf="loading">
  <div class="progressspinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div *ngIf="groups" class="px-4">
  <div class="d-none d-lg-block">
    <p-panel styleClass="no-padding">
      <p-header class="w-100">
        <div class="d-flex align-items-center">
          <span>Lane Groups</span>
          <button class="icon-button ml-auto mr-3 text-white" (click)="exportData()">
            <fa-icon class="file-csv-icon" [icon]="['fas', 'file-csv']" pTooltip="Export Data" size="2x"></fa-icon>
          </button>
          <button pButton (click)="addClick()" icon="pi pi-plus" label="Add New" type="button" class="p-button-teal"></button>
        </div>
      </p-header>
      <p-table
        [rowHover]="true"
        [value]="groups"
        selectionMode="single"
        styleClass="p-datatable-no-border"
        [paginator]="true"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50, 100]"
        [first]="first"
        (onPage)="onPage($event)"
      >
        <ng-template pTemplate="header">
          <tr>
            <th [pSortableColumn]="'name'">
              Name
              <p-sortIcon [field]="'name'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'description'">
              Description
              <p-sortIcon [field]="'description'"></p-sortIcon>
            </th>
            <th [field]="'lanes'">Lanes</th>
            <th [field]="'laneAttributes'">Lane Attributes</th>
            <!-- <th>
              Contacts
            </th>
            <th>
              Special Instructions
            </th> -->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-group>
          <tr (click)="onRowSelect(group)" [pSelectableRow]="group">
            <td>{{ group.name }}</td>
            <td>{{ group.description }}</td>
            <td [innerHtml]="getLanesDisplayMarkup(group)"></td>
            <td
              (mouseenter)="showLaneAttributeOverlay(group, laneAttributesOverlay, $event)"
              (mouseleave)="hideLaneAttributeOverlay(laneAttributesOverlay)"
            >
              <fa-icon [icon]="['fas', 'user-plus']" size="lg" class="mr-1" *ngIf="group.hasInclusionCarrierGroup"></fa-icon>
              <fa-icon [icon]="['fas', 'user-minus']" size="lg" class="mr-1" *ngIf="group.hasExclusionCarrierGroup"></fa-icon>
              <fa-icon [icon]="['fas', 'binoculars']" size="lg" class="mr-1" *ngIf="group.hasRateSeeker"></fa-icon>
              <fa-icon [icon]="['fas', 'address-card']" size="lg" class="mr-1" *ngIf="group.hasShipperContacts"></fa-icon>
              <fa-icon [icon]="['fas', 'tasks']" size="lg" class="mr-1" *ngIf="group.hasSpecialInstructions"></fa-icon>
              <fa-icon [icon]="['fas', 'toolbox']" size="lg" class="mr-1" *ngIf="group.hasRequiredServiceTypes"></fa-icon>
              <fa-icon [icon]="['fas', 'handshake']" size="lg" class="mr-1" *ngIf="group.eligibleForQuote"></fa-icon>
            </td>
            <!-- <td [innerHtml]="getContactsDisplayMarkup(group)"></td>
            <td [innerHtml]="group.specialInstructions"></td> -->
          </tr>
        </ng-template>
      </p-table>
    </p-panel>
  </div>
  <div class="d-lg-none pt-2">
    <div class="title-bar">
      <span>Lane Groups</span>
    </div>
    <div class="group-item d-flex flex-column" *ngFor="let group of groups">
      <p-card styleClass="group-grid-card" (click)="onRowSelect(group)">
        <p-header>
          <div>
            {{ group.name }}
          </div>
        </p-header>
        <div class="d-flex flex-column group-item-detail">
          <div class="d-flex flex-wrap align-items-center">
            <div class="flex-grow-1">
              <span>Description</span>
            </div>
            <div>
              <span>{{ group.description }}</span>
            </div>
          </div>
          <div class="d-flex flex-wrap align-items-center">
            <div class="flex-grow-1">
              <span>Lanes</span>
            </div>
            <div>
              <span [innerHtml]="getLanesDisplayMarkup(group)"></span>
            </div>
          </div>
          <div class="d-flex flex-wrap align-items-center">
            <div class="flex-grow-1">
              <span>Contacts</span>
            </div>
            <div>
              <span [innerHtml]="getContactsDisplayMarkup(group)"></span>
            </div>
          </div>
          <div class="d-flex flex-wrap align-items-center">
            <div class="flex-grow-1">
              <span>Lane Attributes</span>
            </div>
            <div>
              <span>
                <ul class="list-unstyled mt-1">
                  <li *ngIf="group.hasInclusionCarrierGroup" class="mb-1">
                    <fa-icon [icon]="['fas', 'user-plus']" size="md" class="mr-1"></fa-icon>
                    Inclusion Carrier Group
                  </li>
                  <li *ngIf="group.hasExclusionCarrierGroup" class="mb-1">
                    <fa-icon [icon]="['fas', 'user-minus']" size="md" class="mr-1"></fa-icon>
                    Exclusion Carrier Group
                  </li>
                  <li *ngIf="group.hasRateSeeker" class="mb-1">
                    <fa-icon [icon]="['fas', 'binoculars']" size="md" class="mr-1"></fa-icon>
                    Rate Seeker
                  </li>
                  <li *ngIf="group.hasShipperContacts" class="mb-1">
                    <fa-icon [icon]="['fas', 'address-card']" size="md" class="mr-1"></fa-icon>
                    Contacts
                  </li>
                  <li *ngIf="group.hasSpecialInstructions" class="mb-1">
                    <fa-icon [icon]="['fas', 'tasks']" size="md" class="mr-1"></fa-icon>
                    Special Instructions
                  </li>
                  <li *ngIf="group.hasRequiredServiceTypes" class="mb-1">
                    <fa-icon [icon]="['fas', 'toolbox']" size="md" class="mr-1"></fa-icon>
                    Required Service Types
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <!-- <div class="d-flex flex-wrap align-items-center">
            <div class="flex-grow-1">
              <span>Special Instructions</span>
            </div>
            <div>
              <span [innerHtml]="group.specialInstructions"></span>
            </div>
          </div> -->
        </div>
      </p-card>
    </div>
    <div>
      <button pButton (click)="addClick()" label="Add New" icon="pi pi-plus" type="button" class="mobile-add-button p-button-teal"></button>
    </div>
  </div>
</div>
<p-overlayPanel
  #laneAttributesOverlay
  showTransitionOptions="0ms"
  hideTransitionOptions="0ms"
  [showCloseIcon]="false"
  [dismissable]="false"
  appendTo="body"
>
  <ul class="list-unstyled" *ngIf="selectedCustomerLaneGroupAttribute">
    <li *ngIf="selectedCustomerLaneGroupAttribute.hasInclusionCarrierGroup" class="mb-1">
      <fa-icon [icon]="['fas', 'user-plus']" size="lg" class="mr-1"></fa-icon>
      Inclusion Carrier Group
    </li>
    <li *ngIf="selectedCustomerLaneGroupAttribute.hasExclusionCarrierGroup" class="mb-1">
      <fa-icon [icon]="['fas', 'user-minus']" size="lg" class="mr-1"></fa-icon>
      Exclusion Carrier Group
    </li>
    <li *ngIf="selectedCustomerLaneGroupAttribute.hasRateSeeker" class="mb-1">
      <fa-icon [icon]="['fas', 'binoculars']" size="lg" class="mr-1"></fa-icon>
      Rate Seeker
    </li>
    <li *ngIf="selectedCustomerLaneGroupAttribute.hasShipperContacts" class="mb-1">
      <fa-icon [icon]="['fas', 'address-card']" size="lg" class="mr-1"></fa-icon>
      Contacts
    </li>
    <li *ngIf="selectedCustomerLaneGroupAttribute.hasSpecialInstructions" class="mb-1">
      <fa-icon [icon]="['fas', 'tasks']" size="lg" class="mr-1"></fa-icon>
      Special Instructions
    </li>
    <li *ngIf="selectedCustomerLaneGroupAttribute.hasRequiredServiceTypes" class="mb-1">
      <fa-icon [icon]="['fas', 'toolbox']" size="lg" class="mr-1"></fa-icon>
      Required Service Types
    </li>
    <li *ngIf="selectedCustomerLaneGroupAttribute.eligibleForQuote" class="mb-1">
      <fa-icon [icon]="['fas', 'handshake']" size="lg" class="mr-1"></fa-icon>
      Eligible for Quote
    </li>
  </ul>
</p-overlayPanel>
