<div class="d-flex align-items-center">
  <kbxl-chat-presence-indicator [userId]="userId" class="mr-1" (userActiveChange)="userActive = $event"></kbxl-chat-presence-indicator>
  <div class="chat-now">
    {{ userName | titlecase }}
  </div>
  <button
    pButton
    pRipple
    type="button"
    label="Chat now"
    class="p-button-text"
    [class.active]="userActive"
    icon="far fa-comments"
    (click)="chatNowClick()"
  ></button>
</div>
