import { Carrier } from './carrier';
import { CarrierScac } from './carrier-scac';
import { Customer } from './customer';
import { ISecurityAccessRoleData } from './security-access-role-data';

export interface MyAuthorizedEntities {
  customers: Customer[];
  carriers: Carrier[];
  carrierScacs: CarrierScac[];
  availableSecurityRoles: ISecurityAccessRoleData[];
}

export const defaultMyAuthorizedEntities: MyAuthorizedEntities = {
  customers: [],
  carriers: [],
  carrierScacs: [],
  availableSecurityRoles: [],
};
