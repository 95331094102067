import { AccessorialAllocationLevelEnum } from './accessorial-allocation-level-enum';

export interface AccessorialData {
  accessorialId: number;
  description: string;
  code: string;
  allocationLevel: AccessorialAllocationLevelEnum;
  afterInitialHoursAllowed: boolean;
}

export function isAccessorial(x: any): x is AccessorialData {
  return typeof x.accessorialId === 'number';
}

export function isAccessorialArray(x: any): x is AccessorialData[] {
  return x.every(isAccessorial);
}
