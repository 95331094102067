<div class="wrapper">
  <div class="progress-indicator" *ngIf="loading">
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <div class="d-flex flex-column">
    <div class="kbxl-inputgroup-container" *ngIf="loadCarrierScacs">
      <div class="kbxl-field-label">
        <label for="loadCarrierScacs" class="control-label"> Carrier / SCAC </label>
      </div>
      <div>
        <p-dropdown
          name="loadCarrierScacs"
          [options]="loadCarrierScacs"
          [(ngModel)]="selectedLoadCarrierScac"
          placeholder="Select Carrier / SCAC"
          dropdownIcon="pi pi-caret-down"
          filter="true"
          [virtualScroll]="true"
          itemSize="30"
          [styleClass]="'p-dropdown-primary'"
          (onChange)="selectCarrierScacChange($event?.value)"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="mt-3" *ngIf="showIneligibleError">
      <div class="error px-4 font-weight-bold" *ngFor="let error of ineligibleErrors">{{ error }}</div>
    </div>
    <div class="rate-section d-flex justify-content-center mt-3" *ngIf="selectedLoadCarrierScac && carrierUsers && carrierUsers.length > 0">
      <div class="d-flex flex-column">
        <div class="kbxl-field-label">
          <label class="control-label"> Line Haul: </label>
        </div>
        <div class="rate-label" *ngIf="!load.isBrokeredLoad; else editableLinehaul">
          {{ lineHaulRate | currency }}
        </div>
        <ng-template #editableLinehaul>
          <input
            type="text"
            maxlength="10"
            class="rate-label"
            [(ngModel)]="lineHaulRate"
            (ngModelChange)="calculatedRateFontStyles()"
            pInputText
            kbxlCurrencyInput
          />
        </ng-template>
      </div>
      <div class="d-flex flex-column mx-3" *ngIf="fuelRate && fuelRate > 0">
        <div class="kbxl-field-label">
          <label class="control-label"> &nbsp;</label>
        </div>
        <div class="rate-label">+</div>
      </div>
      <div class="d-flex flex-column" *ngIf="fuelRate && fuelRate > 0">
        <div class="kbxl-field-label">
          <label class="control-label"> FSC: </label>
        </div>
        <div class="rate-label">{{ fuelRate | currency }}</div>
      </div>
      <div class="d-flex flex-column mx-3">
        <div class="kbxl-field-label">
          <label class="control-label"> &nbsp;</label>
        </div>
        <div class="rate-label">=</div>
      </div>
      <div class="d-flex flex-column total-rate">
        <div class="kbxl-field-label" [ngClass]="totalRateStyleClass">
          <label class="control-label"> Total Rate: </label>
        </div>
        <div class="rate-label" [ngClass]="totalRateStyleClass">{{ lineHaulRate + fuelRate | currency }}</div>
      </div>
      <div class="d-flex flex-column ml-3" *ngIf="load.isBrokeredLoad">
        <div class="kbxl-field-label" [ngClass]="totalRateStyleClass">
          <label class="control-label"> Break-even: </label>
        </div>
        <div class="rate-label" [ngClass]="totalRateStyleClass">
          {{ breakevenRate | currency }}
        </div>
      </div>
      <div class="d-flex flex-column ml-3" *ngIf="load.isBrokeredLoad">
        <div class="kbxl-field-label" [ngClass]="totalRateStyleClass">
          <label class="control-label"> Margin: </label>
        </div>
        <div class="rate-label" [ngClass]="totalRateStyleClass">
          {{ breakevenRate - (lineHaulRate + fuelRate) | currency }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-column mt-3" *ngIf="selectedLoadCarrierScac && carrierUsers && carrierUsers.length > 0">
      <div class="kbxl-field-label">
        <label class="control-label"> Carrier User Contact </label>
      </div>
      <div>
        <p-dropdown
          [options]="carrierUsers"
          [(ngModel)]="selectedCarrierUser"
          optionLabel="fullName"
          placeholder="Select User"
          dropdownIcon="pi pi-caret-down"
          [styleClass]="'p-dropdown-primary'"
          (onChange)="selectCarrierUser($event?.value)"
        >
          <ng-template let-user pTemplate="item">
            <div class="d-flex">
              <span>{{ user.value.fullName | titlecase }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="d-flex mt-3" *ngIf="selectedCarrierUser">
        <div class="d-flex flex-column">
          <div class="kbxl-field-label">
            <label class="control-label"> Name: </label>
          </div>
          <div>{{ selectedCarrierUser.fullName | titlecase }}</div>
        </div>
        <div class="d-flex flex-column mx-3">
          <div class="kbxl-field-label">
            <label class="control-label"> Email: </label>
          </div>
          <div>
            <a class="text-truncate" href="mailto: {{ selectedCarrierUser.email }}" [title]="selectedCarrierUser.email"
              >{{ selectedCarrierUser.email | lowercase }}
            </a>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="kbxl-field-label">
            <label class="control-label"> Phone: </label>
          </div>
          <div *ngFor="let phoneNumber of selectedCarrierUser.phoneNumbers">
            <a href="tel:{{ phoneNumber }}">{{ phoneNumber }}</a>
          </div>
          <div *ngFor="let cellPhoneNumber of selectedCarrierUser.cellPhoneNumbers">
            <span>Cell</span><a href="tel:{{ cellPhoneNumber }}">{{ cellPhoneNumber }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mt-4">
      <button pButton type="button" class="p-button-outlined p-button-secondary" label="Cancel" (click)="cancelClick()"></button>
      <button pButton type="button" label="Tender to Carrier" (click)="tenderToCarrierClick()" [disabled]="!enableTenderButton"></button>
    </div>
  </div>
</div>

<p-dialog
  *ngIf="!load?.restrictiveViewOnly"
  [appendTo]="'body'"
  [baseZIndex]="5001"
  header="Book Load Confirmation"
  [(visible)]="showBookedLoadModal"
  [modal]="true"
  [closeOnEscape]="true"
>
  <kbxl-book-load-modal
    [load]="load"
    (book)="bookLoad($event)"
    (cancelBook)="showBookedLoadModal = false"
    [processing]="loading"
  ></kbxl-book-load-modal>
</p-dialog>
