import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AuthLogoutAction } from '@tms-ng/core';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ChatState, getNotificationCount } from '../../../chat';
import { CoreState, getAdminMenuEntities } from '../../../core/store';
import { BaseComponent } from '../../../shared/components';
import { userImpersonationModalZIndex } from '../../../shared/constants';
import { UserModel } from '../../../shared/models';
import { SecurityAppActionType } from '../../../shared/models/security-app-action-type';
import {
  ActionItemTogglePollingAction,
  SharedState,
  getActionItemNotificationCount,
  loadshopApplicationReady,
} from '../../../shared/store';
import { UserState, getAuthorizedFocusEntitiesSelector, getUserProfileModel } from '../../store';
import { UserFocusEntitySelectorModalComponent } from '../user-focus-entity-selector-modal';
import { UserImpersonationModalComponent } from '../user-impersonation-modal';

@Component({
  selector: 'kbxl-user-profile-menu',
  templateUrl: './user-profile-menu.component.html',
  styleUrls: ['./user-profile-menu.component.scss'],
  providers: [DialogService],
})
export class UserProfileMenuComponent extends BaseComponent implements OnInit {
  applicationReady$: Observable<boolean>;
  public items: MenuItem[];
  public menuActive = false;
  public showQuickQuote = false;
  public showActionItems = false;
  public actionItemLink = '';
  public actionItemCount = 0;
  public chatNotificationCount = 0;
  public displayQuickQuoteDialog = false;
  public userDisplayText: string;
  public displayChangeEntity = false;

  public enableChat = false;
  user: UserModel;

  constructor(
    private sharedStore: Store<SharedState>,
    private coreStore: Store<CoreState>,
    private userStore: Store<UserState>,
    private chatStore: Store<ChatState>,
    private dialogService: DialogService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.applicationReady$ = this.sharedStore.pipe(select(loadshopApplicationReady));

    this.userStore
      .pipe(
        takeUntil(this.destroyed$),
        select(getAuthorizedFocusEntitiesSelector),
        map((userFocusEntityResult) => userFocusEntityResult && userFocusEntityResult.focusEntites)
      )
      .subscribe((x) => {
        this.displayChangeEntity = x.length > 0;
        if (this.items) {
          const menuItem = this.items.find((item) => item.label === 'Change Entity');
          menuItem.visible = this.displayChangeEntity;
        }
      });

    const userAdminMenuItems$ = this.coreStore.pipe(select(getAdminMenuEntities));

    const userProfile$ = this.userStore.pipe(
      takeUntil(this.destroyed$),
      select(getUserProfileModel),
      filter((x) => x != null)
    );

    combineLatest([userProfile$, userAdminMenuItems$]).subscribe(([user, adminMenuItems]) => {
      this.items = [
        {
          label: 'User Profile',
          icon: 'pi pi-cog',
          routerLink: ['/user/profile'],
        },
      ];
      this.user = user;
      if (user) {
        this.userDisplayText = user.firstName.toLocaleLowerCase();

        // Disable polling for the indicator
        // Always do this to insure we aren't double polling
        this.sharedStore.dispatch(new ActionItemTogglePollingAction(false));

        this.showQuickQuote = user.hasSecurityAction(SecurityAppActionType.ShipperViewSmartSpotPriceQuote);

        if (user.isCarrier && user.hasSecurityAction(SecurityAppActionType.CarrierMyLoadsView)) {
          this.showActionItems = true;
          if (user.hasSecurityAction(SecurityAppActionType.CarrierCounterOfferRequest)) {
            this.actionItemLink = '/loads/action-items';
          } else {
            this.actionItemLink = '/loads/action-items/accessorials';
          }
          // enable polling for the indicator
          this.sharedStore.dispatch(new ActionItemTogglePollingAction(true));
          this.userDisplayText += ' | ' + user.focusEntity.id;
        } else if (user.isShipper && user.hasSecurityAction(SecurityAppActionType.ShipperViewActiveLoads)) {
          this.showActionItems = true;
          if (user.hasSecurityAction(SecurityAppActionType.ShipperCounterOfferAccept)) {
            this.actionItemLink = '/loads/action-items';
          } else {
            this.actionItemLink = '/loads/action-items/accessorials';
          }
          // enable polling for the indicator
          this.sharedStore.dispatch(new ActionItemTogglePollingAction(true));
          this.userDisplayText += ' | ' + user.focusEntity.name.toLocaleLowerCase();
        }

        this.items.push({
          label: 'Change Entity',
          icon: 'fas fa-people-arrows',
          command: () => {
            this.showEntitySelectorModal();
          },
          visible: this.displayChangeEntity,
        });

        if (user.hasSecurityAction(SecurityAppActionType.Impersonate) && !user.isImpersonating) {
          this.items.push({
            label: 'Impersonate',
            icon: 'pi pi-users',
            command: () => {
              this.showImpersonateModal();
            },
          });
        }

        if (user.hasSecurityAction(SecurityAppActionType.AdminTabVisible) && adminMenuItems && adminMenuItems.length > 0) {
          this.items.push({
            label: 'Maintenance',
            icon: 'pi pi-cog',
            routerLink: ['maint'],
          });
        }
      }

      this.items.push({
        separator: true,
      });
      this.items.push({
        label: 'Log Out',
        icon: 'pi pi-sign-out',
        command: () => {
          this.coreStore.dispatch(new AuthLogoutAction());
        },
      });

      // check if chat is enabled for this user
      if (user.isShipper && user.authorizedShippers) {
        const shipper = user.authorizedShippers.find((x) => x.customerId === user.focusEntity.id);
        this.enableChat = shipper && shipper.isChatEnabled;
      } else if (user.isCarrier) {
        this.enableChat = true;
      }
    });

    this.sharedStore
      .pipe(takeUntil(this.destroyed$), select(getActionItemNotificationCount))
      .subscribe((count) => (this.actionItemCount = count));
    this.chatStore.pipe(takeUntil(this.destroyed$), select(getNotificationCount)).subscribe((count) => {
      this.chatNotificationCount = count;
    });
  }

  showImpersonateModal(): void {
    this.dialogService.open(UserImpersonationModalComponent, {
      header: 'Impersonate a User',
      baseZIndex: userImpersonationModalZIndex,
    });
  }

  showEntitySelectorModal(): void {
    this.dialogService.open(UserFocusEntitySelectorModalComponent, {
      header: 'Change Entity',
      baseZIndex: 5000,
    });
  }

  loadQuickQuote(event): void {
    // show the non brokered customer quote modal
    this.displayQuickQuoteDialog = true;
  }

  showMenu(menuEnabled: boolean) {
    this.menuActive = menuEnabled;
  }
}
