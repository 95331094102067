// angular libs
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForbiddenMessageService, SignedInMessageService, TmsCoreModule, UnauthorizedMessageService } from '@tms-ng/core';
import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
// main component
import { AppComponent } from './app.component';
// routing
import { AppRoutingModule } from './app.routing.module';
import { ChatModule } from './chat/chat.module';
import { CONVERSATION_WEBSOCKET_PATH } from './chat/services';
// swagger generated modules
import { ApiModule as ConversationApiModule, BASE_PATH as CONVERSATION_BASE_PATH } from './conversation-api';
// app  modules
import { CoreModule } from './core/core.module';
import { LoadshopForbiddenMessageService, LoadshopSignedInMessageService, LoadshopUnauthorizedMessageService } from './core/services';
import { LoadBoardModule } from './load-board/load-board.module';
import { SharedModule } from './shared/shared.module';
import { ShippingModule } from './shipping/shipping.module';
import { UserModule } from './user/user.module';

// services and guards

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Core modules
    BrowserModule,
    BrowserAnimationsModule,

    // Reactive Forms
    ReactiveFormsModule,

    // App Modules
    CoreModule,
    SharedModule,
    ChatModule,
    UserModule,
    LoadBoardModule,
    AdminModule,
    ShippingModule,
    ConversationApiModule,

    // routing modules
    AppRoutingModule,
    TmsCoreModule,
  ],
  providers: [
    { provide: ForbiddenMessageService, useClass: LoadshopForbiddenMessageService },
    { provide: UnauthorizedMessageService, useClass: LoadshopUnauthorizedMessageService },
    { provide: SignedInMessageService, useClass: LoadshopSignedInMessageService },
    {
      provide: CONVERSATION_BASE_PATH,
      useValue: environment.conversationApiUrl,
    },
    {
      provide: CONVERSATION_WEBSOCKET_PATH,
      useValue: environment.conversationWebSocketUrl,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
