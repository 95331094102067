import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../shared/components';
import { getUserProfileEntity } from '../../../../user/store';
import { AcceptAgreementDocumentAction, CoreState, getAgreementDocumentLoading } from '../../../store';
@Component({
  selector: 'kbxl-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.scss'],
})
export class UserAgreementComponent extends BaseComponent implements OnInit, OnDestroy {
  userAgreement = false;
  loading = false;
  displayModal = false;

  constructor(private store: Store<CoreState>, private router: Router) {
    super();
  }

  ngOnInit() {
    this.store.pipe(takeUntil(this.destroyed$), select(getAgreementDocumentLoading)).subscribe((x) => (this.loading = x));

    this.store.pipe(select(getUserProfileEntity), takeUntil(this.destroyed$)).subscribe((user) => {
      if (!user) {
        return;
      }
      // show the privacy policy first, then the terms
      if (user.hasAgreedToTerms) {
        this.router.navigate(['/']);
      } else {
        this.displayModal = true;
      }
    });
  }
  agree(): void {
    this.loading = true;
    this.displayModal = false;
    this.store.dispatch(new AcceptAgreementDocumentAction());
  }
}
