import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicPricingRuleBuildScheduleRequest, DynamicPricingScheduleData, ServiceResponse } from 'src/app/shared/models';
import { environment } from '../../../environments/environment';
import { mapResponse } from '../../shared/operators/map-response';

@Injectable()
export class DynamicPricingScheduleService {
  constructor(private http: HttpClient) {}

  getDynamicPricingSchedule(payload: DynamicPricingRuleBuildScheduleRequest): Observable<DynamicPricingScheduleData> {
    return this.http
      .post<ServiceResponse<DynamicPricingScheduleData>>(`${environment.apiUrl}/api/DynamicPricingSchedule/BuildSchedule`, payload)
      .pipe(mapResponse());
  }
}
