import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { AccessorialData, AccessorialUnitOfMeasureData, ShipperAccessorialData, ValidationProblemDetails } from '../../../../shared/models';

@Component({
  selector: 'kbxl-accessorial-detail',
  templateUrl: './accessorial-detail.component.html',
  styleUrls: ['./accessorial-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessorialDetailComponent implements OnChanges {
  @Input() loading: boolean;
  @Input() enableUoM = false;
  @Input() shipperAccessorials: ShipperAccessorialData[];
  @Input() globalAccessorials: ShipperAccessorialData[];

  @Input() accessorialUoM: AccessorialUnitOfMeasureData[];
  @Input() accessorials: AccessorialData[];
  @Input() accessorial: ShipperAccessorialData;
  @Input() error: ValidationProblemDetails;

  @Output() saveAccessorial = new EventEmitter<ShipperAccessorialData>();
  @Output() cancel = new EventEmitter<boolean>();

  afterInitialHoursValues = [
    { label: '0 hrs', value: 0 },
    { label: '1 hr', value: 1 },
    { label: '2 hrs', value: 2 },
    { label: '3 hrs', value: 3 },
    { label: '4 hrs', value: 4 },
    { label: '5 hrs', value: 5 },
  ];

  availableAccessorials: SelectItem[] = [];
  ngOnChanges(changes: SimpleChanges): void {
    this.buildAvailableAccessorials();
  }
  saveClick(): void {
    this.error = null;

    if (!this.accessorial.accessorialId || this.accessorial.accessorialId < 1) {
      this.addError('Invalid accessorial');
    }
    if (!this.accessorial.accessorialUnitOfMeasureId || this.accessorial.accessorialUnitOfMeasureId < 1) {
      this.addError('Invalid accessorial unit of measure');
    }

    if (this.accessorial.variableRate) {
      // null out max and uom rate for api
      this.accessorial.maxDollarRatePerUOM = null;
      this.accessorial.maxRate = null;
    } else {
      if (this.accessorial.accessorialUnitOfMeasureDescription === 'Flat Charge') {
        this.accessorial.maxDollarRatePerUOM = null;
      } else {
        if (!this.accessorial.maxDollarRatePerUOM || this.accessorial.maxDollarRatePerUOM < 1) {
          this.addError('Invalid max dollar rate per UOM');
        }
      }

      if (!this.accessorial.maxRate || this.accessorial.maxRate < 1) {
        this.addError('Invalid max rate');
      }
      if (
        this.accessorial.maxDollarRatePerUOM &&
        this.accessorial.maxRate &&
        this.accessorial.maxDollarRatePerUOM > this.accessorial.maxRate
      ) {
        this.addError('Max dollar rate per UOM cannot be greater than max rate');
      }
    }

    if (this.shipperAccessorials && this.shipperAccessorials.length > 0) {
      // check to see if one exists
      const exists = this.shipperAccessorials.find(
        (x) =>
          x.accessorialId === this.accessorial.accessorialId && x.accessorialUnitOfMeasureId === this.accessorial.accessorialUnitOfMeasureId
      );

      if (exists) {
        this.addError('Accessorial already exists for this unit of measure');
      }
    }

    if (!this.error) {
      // save
      this.saveAccessorial.emit(this.accessorial);
    }
  }
  setAccessorialDescription(): void {
    // when adding global, we bind the drop down directly to the accessorialId, however there is logic tied to 'Flat Charge'
    if (this.accessorial && this.accessorial.accessorialUnitOfMeasureId) {
      const a = this.accessorialUoM.find((x) => x.accessorialUnitOfMeasureId === this.accessorial.accessorialUnitOfMeasureId);
      if (a) {
        this.accessorial.accessorialUnitOfMeasureDescription = a.description;
      }
    }
  }
  private addError(error: string): void {
    if (!this.error) {
      this.error = {
        title: '',
        status: 1,
        detail: '',
        instance: '',
        errors: {},
      };
    }

    if (!this.error.errors['urn:root']) {
      this.error.errors['urn:root'] = new Array<string>(error);
    } else {
      this.error.errors['urn:root'].push(error);
    }
  }

  decodeProblemDetails() {
    if (!this.error || !this.error.errors) {
      return;
    }
    const groupErrors = this.error.errors['urn:root'];
    if (groupErrors && typeof groupErrors === 'string') {
      return groupErrors;
    } else {
      const arrayErrors = this.error.errors as any[];

      if (arrayErrors) {
        // concat errors together to show on top
        if (arrayErrors['urn:root'] != null) {
          return arrayErrors['urn:root'].map((x) => x).join('\n');
        } else {
          return arrayErrors.map((x) => x.message).join('\n');
        }
      }
    }
    return;
  }

  updateSelectedAccessorial(event: any): void {
    const a = event.value as ShipperAccessorialData;

    if (a) {
      this.accessorial.accessorialId = a.accessorialId;
      this.accessorial.accessorialUnitOfMeasureDescription = a.accessorialUnitOfMeasureDescription;
      this.accessorial.accessorialUnitOfMeasureId = a.accessorialUnitOfMeasureId;
      this.accessorial.accessorialDescription = a.accessorialDescription;
      if (this.shouldShowAfterInitialHours() && !this.accessorial.afterInitialHours) {
        // Automatically set to zero if it should have a value
        this.accessorial.afterInitialHours = 0;
      }
    }
  }

  buildAvailableAccessorials(): void {
    if (
      !this.globalAccessorials ||
      !this.shipperAccessorials ||
      this.globalAccessorials.length === 0 ||
      this.shipperAccessorials.length === 0
    ) {
      return;
    }

    const availableAccessorials = [];

    for (let index = 0; index < this.globalAccessorials.length; index++) {
      const element = this.globalAccessorials[index];

      const exists = this.shipperAccessorials.find(
        (x) => x.accessorialId === element.accessorialId && x.accessorialUnitOfMeasureId === element.accessorialUnitOfMeasureId
      );
      if (!exists) {
        availableAccessorials.push({
          label: element.accessorialDescription,
          value: element,
        });
      }
    }

    this.availableAccessorials = availableAccessorials;
  }

  cancelAction(): void {
    this.cancel.emit(true);
  }

  shouldShowAfterInitialHours(): boolean {
    if (this.accessorial && this.accessorial.accessorialId) {
      const acc = this.accessorials.find((x) => x.accessorialId === this.accessorial.accessorialId);
      return acc && acc.afterInitialHoursAllowed;
    }

    return false;
  }
}
