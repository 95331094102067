<div class="progress-indicator" *ngIf="loading">
  <div class="progressspinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div class="d-flex flex-column wrapper">
  <!-- view current counter offers -->
  <div class="error-container my-2" *ngIf="errorMsg && errorMsg.length > 0">
    <p>{{ errorMsg }}</p>
  </div>
  <div class="d-flex flex-column mt-4" *ngIf="counterOffers.length === 0 && !loading">
    <h5 class="text-center text-muted">You have no counter offers</h5>
  </div>
  <div class="d-flex flex-column" *ngIf="counterOffers && counterOffers.length > 0">
    <h6>Counter Offers {{ loadDisplay }}</h6>
    <p-accordion [activeIndex]="counterOfferActiveIndex">
      <p-accordionTab *ngFor="let requestGroup of counterOffers; index as i">
        <ng-template pTemplate="header">
          <kbxl-counter-offer-group-accordion-tab-header [counterOffer]="requestGroup" [user]="user">
          </kbxl-counter-offer-group-accordion-tab-header>
        </ng-template>

        <kbxl-counter-offer-group-accordion-tab
          [loadId]="loadId"
          [user]="user"
          [counterOffer]="requestGroup"
          [load]="load"
          [shipper]="shipper"
          (chatNow)="chatNow($event)"
          (pendingChange)="pendingChange = $event"
          (saveAction)="applyAction($event, i)"
          (submitOffer)="submitCounterOffer($event, i)"
        ></kbxl-counter-offer-group-accordion-tab>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div *ngIf="allowNewCounterOffers && load && !loading" class="mt-4">
    <p-accordion [(activeIndex)]="addRequestActiveIndex" (onOpen)="addRequestActiveIndex = 0" (onClose)="addRequestActiveIndex = -1">
      <p-accordionTab header="Create Counter Offer">
        <kbxl-add-counter-offer-request
          [user]="user"
          [load]="load"
          [shipper]="shipper"
          [shipperUserData]="shipperUserData"
          [loadDetailErrors]="loadDetailErrors"
          [shipperOfferOptions]="shipperOfferOptions"
          (chatNow)="chatNow($event)"
          (pendingChange)="pendingChange = $event"
          (submitOffer)="submitCounterOffer($event)"
        ></kbxl-add-counter-offer-request>
      </p-accordionTab>
    </p-accordion>
  </div>

  <div class="d-flex justify-content-between mt-4 mb-2">
    <button pButton type="button" class="p-button-outlined p-button-secondary" label="Cancel" (click)="cancel()"></button>
  </div>
</div>

<p-dialog
  [appendTo]="'body'"
  header="Counter Offer Confirmation"
  [(visible)]="showBookedLoadModal"
  [modal]="true"
  [closeOnEscape]="true"
  [baseZIndex]="bookingModalZIndex"
>
  <kbxl-book-load-modal
    [load]="load"
    [isCounterOffer]="true"
    (book)="bookLoad($event)"
    (cancelBook)="showBookedLoadModal = false"
    [processing]="loading"
  ></kbxl-book-load-modal>
</p-dialog>
