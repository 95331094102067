import { Customer, UserContactData } from '../../../shared/models';
import { createReducer } from '../../../shared/utilities';
import { CustomerActions, CustomerActionTypes } from '../actions';

export interface CustomerState {
  loading: boolean;
  entity: Customer;
  companyUsers: UserContactData[];
}

const initialState: CustomerState = {
  loading: false,
  entity: null,
  companyUsers: null
};

const _customerReducer = createReducer(
  initialState,
  function (state: CustomerState = initialState, action: CustomerActions): CustomerState {
    switch (action.type) {
      case CustomerActionTypes.Load: {
        return { ...state, entity: null, loading: true };
      }
      case CustomerActionTypes.Load_Success: {
        return { ...state, entity: action.payload, loading: false };
      }
      case CustomerActionTypes.Load_Failure: {
        return { ...state, entity: null, loading: false };
      }
      case CustomerActionTypes.GetUsersInCompany_Success: {
        return { ...state, companyUsers: action.payload };
      }
      default:
        return state;
    }
  }
);
/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function customerReducer(state: CustomerState, action: CustomerActions): CustomerState {
  return _customerReducer(state, action);
}

export const getLoading = (state: CustomerState) => state.loading;
export const getEntity = (state: CustomerState) => state.entity;
