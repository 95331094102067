<div class="d-flex justify-content-center indicator-container" *ngIf="applicationReady$ && user && !user.isActive">
  <div class="indicator">
    <div class="d-flex justify-content-center">
      <strong>{{ entityName }} is currently inactive</strong>
    </div>
    <div class="d-flex justify-content-center">
      For questions, please contact:&nbsp;<a href="mailto:Shipper@Loadshop.com" style="color: white; text-decoration: underline;">Shipper@Loadshop.com</a>
    </div>
  </div>
</div>
