import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NavigationGoAction } from '@tms-ng/core';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LoadBoardService } from '../../../load-board/services';
import { BookLoadType } from '../../models';
import {
  LoadBoardLoadBookAction,
  LoadBoardLoadBookActionTypes,
  LoadBoardLoadBookFailureAction,
  LoadBoardLoadBookSuccessAction,
  LoadBoardLoadDetailLoadBookedFailureAction,
} from '../actions';

@Injectable()
export class LoadBoardLoadBookEffects {
  @Effect()
  $load: Observable<Action> = this.actions$.pipe(
    ofType<LoadBoardLoadBookAction>(LoadBoardLoadBookActionTypes.Book),
    // mapToPayload<Load>(),
    switchMap((action) =>
      this.loadBoardService.bookLoad(action.payload).pipe(
        switchMap((data) => [
          new LoadBoardLoadBookSuccessAction(data, action.payload.bookingType, action.payload.tenderingToOptions),
          new NavigationGoAction({ path: ['../..'] }),
        ]),
        catchError((result) => {
          if (result.error) {
            return of(
              new LoadBoardLoadBookFailureAction(result),
              new LoadBoardLoadDetailLoadBookedFailureAction({ load: result.error.data, errors: result.error.errors })
            );
          }
          return of(new LoadBoardLoadBookFailureAction(result));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  $booked: Observable<Action> = this.actions$.pipe(
    ofType<LoadBoardLoadBookSuccessAction>(LoadBoardLoadBookActionTypes.Book_Success),
    tap((action: LoadBoardLoadBookSuccessAction) => {
      if (
        action.bookingType === BookLoadType.CounterOfferBooked &&
        action.payload.counterOffer &&
        action.payload.counterOffer.request &&
        action.payload.counterOffer.request.loadCounterOfferId > 0
      ) {
        this.messageService.add({ id: 0, detail: 'Counter offer submitted', severity: 'success' });
      } else if (action.bookingType === BookLoadType.TenderedTo && action.tenderingToOptions) {
        this.messageService.add({
          id: 0,
          detail: `Load booked to ${action.tenderingToOptions.scac.toLocaleUpperCase()}`,
          severity: 'success',
        });
      } else {
        this.messageService.add({ id: 0, detail: 'Load Booked', severity: 'success' });
      }
    })
  );

  constructor(private actions$: Actions, private loadBoardService: LoadBoardService, private messageService: MessageService) {}
}
