import { createSelector } from '@ngrx/store';
import { getSharedFeatureState, SharedState } from '../reducers';
import { getErrors, getLoaded, getLoading, getSelectedEntity } from '../reducers/load-board-load-detail.reducer';

const getLoadBoardLoadDetailState = createSelector(getSharedFeatureState, (state: SharedState) => state.loadDetail);

export const getLoadBoardLoadDetailLoading = createSelector(getLoadBoardLoadDetailState, getLoading);
export const getLoadBoardLoadDetailLoaded = createSelector(getLoadBoardLoadDetailState, getLoaded);
export const getLoadBoardSelectedLoad = createSelector(getLoadBoardLoadDetailState, getSelectedEntity);
export const getLoadBoardLoadDetailErrors = createSelector(getLoadBoardLoadDetailState, getErrors);
