import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { SelectItem, TreeNode } from 'primeng/api';
import { map, takeUntil } from 'rxjs/operators';
import { RateSeekerBrandMarkup } from 'src/app/core/utilities/constants';
import { BaseComponent, DynamicPricingRuleFormComponent } from 'src/app/shared/components';
import {
  Carrier,
  CustomerLaneData,
  CustomerLaneGroupContactData,
  CustomerLaneGroupDetailData,
  CustomerProfile,
  DropdownOption,
  DynamicPricingRuleData,
  DynamicPricingScheduleChangedData,
  Equipment,
  LoadCarrierGroupType,
  ResponseError,
  ServiceType,
  State,
  defaultCustomerLaneData,
  defaultCustomerLaneGroupContactData,
  defaultDynamicPricing,
} from '../../../../shared/models';
import { AdminState, getSelectedShipper } from '../../../store';
import { LaneDetailComponent } from '../lane-detail/lane-detail.component';

@Component({
  selector: 'kbxl-lane-group-detail',
  templateUrl: './lane-group-detail.component.html',
  styleUrls: ['./lane-group-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO WIRE UP CONTACT IMPLEMENT ON CHANGES
export class LaneGroupDetailComponent extends BaseComponent implements OnInit, OnChanges {
  @Output() saveClick = new EventEmitter<CustomerLaneGroupDetailData>();
  @Output() deleteClick: EventEmitter<CustomerLaneGroupDetailData> = new EventEmitter<CustomerLaneGroupDetailData>();
  @Output() cancelClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() processing: boolean;
  @Input() errors: ResponseError[];
  @Input() loadCarrierGroupTypes: LoadCarrierGroupType[];
  @Input() allEquipment: Equipment[];
  @Input() group: CustomerLaneGroupDetailData;
  @Input() percentOptions: DropdownOption[];
  @Input() frequencyOptions: DropdownOption[];
  @Input() intervalOptions: DropdownOption[];
  @Input() set groupedEquipment(value: TreeNode[]) {
    this._groupedEquipment = value;
    // this.updateSelectedEquipment();
  }
  @Input() set states(value: State[]) {
    this._states = value;
  }
  @Input() set allCarriers(value: Carrier[]) {
    this._allCarriers = value;

    this.carriers = this.toSelectItem(value);
    if (value) {
      this.carrierChange();
    }
  }
  get groupedEquipment(): TreeNode[] {
    return this._groupedEquipment;
  }
  get states(): State[] {
    return this._states;
  }
  get allCarriers(): Carrier[] {
    return this._allCarriers;
  }

  carriers: SelectItem[] = [];
  private _allCarriers: Carrier[] = null;
  private _groupedEquipment: TreeNode[];
  private _states: State[];

  @Input() set allCustomerServiceTypes(value: ServiceType[]) {
    this._allCustomerServiceTypes = value;
    this.customerServiceTypes = this.serviceTypeToSelectItem(value);
  }
  customerServiceTypes: SelectItem[] = [];
  private _allCustomerServiceTypes: ServiceType[] = null;

  @ViewChildren(LaneDetailComponent) lanes: QueryList<LaneDetailComponent>;
  @ViewChild(DynamicPricingRuleFormComponent, { static: true }) pricingForm: DynamicPricingRuleFormComponent;

  pricingRule: DynamicPricingRuleData = { ...defaultDynamicPricing };
  contact: CustomerLaneGroupContactData = { ...defaultCustomerLaneGroupContactData };

  showCarrierGroupTypeWarning = false;
  rateSeekerLabel = RateSeekerBrandMarkup;

  specialInstructionsCollapsed = true;
  requiredServiceTypesCollapsed = true;
  carrierGroupCollapsed = true;
  rateSeekerCollapsed = true;
  contactsCollapsed = true;
  initHasCarrierGroup = false;

  quoteOnlyLane = false;

  shipper: CustomerProfile;

  get adding(): boolean {
    return this.group && !(this.group.customerLaneGroupId > 0);
  }

  constructor(private adminStore: Store<AdminState>) {
    super();
  }
  ngOnInit(): void {
    this.adminStore
      .pipe(
        select(getSelectedShipper),
        takeUntil(this.destroyed$),
        map((_) => this.deepClone(_))
      )
      .subscribe((x) => {
        this.shipper = x;

        if (this.shipper && this.group && this.group.customerLaneGroupId === 0) {
          this.group.brokeredLaneGroup = this.shipper.brokeredLoads;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group && this.group) {
      this.initHasCarrierGroup = this.group.loadCarrierGroupTypeId ? true : false;
    }

    if (changes.group && this.group && this.group.customerLaneGroupContacts && this.group.customerLaneGroupContacts.length > 0) {
      this.contact = this.group.customerLaneGroupContacts[0];
    } else if (changes.group) {
      this.contact = { ...defaultCustomerLaneGroupContactData };
    }

    if (changes.group && this.group && this.group.customerLaneGroupPricingRule) {
      this.pricingRule = this.group.customerLaneGroupPricingRule;
    } else if (changes.group) {
      this.pricingRule = { ...defaultDynamicPricing };
    }

    if (changes.errors && this.errors && this.errors.length > 0) {
      this.expandAllForms();
    }

    if (this.group && this.group.customerLaneGroupId === 0 && this.shipper) {
      this.group.brokeredLaneGroup = this.shipper.brokeredLoads;
    }
  }

  carrierChange() {
    this.carriers.sort((a, b) => this.carrierSort(a, b));
  }

  carrierSort(a: SelectItem, b: SelectItem): number {
    if (this.group && this.group.carrierIds) {
      if (this.group.carrierIds && this.group.carrierIds.length > 0) {
        const selectedA = this.group.carrierIds.indexOf(a.value) >= 0;
        const selectedB = this.group.carrierIds.indexOf(b.value) >= 0;

        if (selectedA !== selectedB) {
          return selectedA ? -1 : 1;
        }
      }

      return a.label.localeCompare(b.label);
    }
  }

  private toSelectItem(groups: Carrier[]): SelectItem[] {
    if (!groups) {
      return [];
    }
    const selectItems: SelectItem[] = [];
    groups.forEach((x) => {
      selectItems.push({
        label: x.carrierName,
        value: x.carrierId,
      });
    });

    return selectItems.sort();
  }

  private serviceTypeToSelectItem(serviceTypes: ServiceType[]): SelectItem[] {
    if (!serviceTypes) {
      return [];
    }
    const selectItems: SelectItem[] = [];
    serviceTypes.forEach((x) => {
      selectItems.push({
        label: x.name,
        value: x.serviceTypeId,
      });
    });

    return selectItems.sort();
  }

  warnGroupTypeChange() {
    if (this.group.customerLaneGroupId > 0 && this.initHasCarrierGroup) {
      this.showCarrierGroupTypeWarning = true;
    }
  }

  laneUpdated(data: any) {
    if (data && data.lane && data.index >= 0) {
      this.group.customerLanes[data.index] = { ...data.lane };
    }
  }

  deleteLane(index: number) {
    this.group.customerLanes.splice(index, 1);
  }

  addLane() {
    this.group.customerLanes.push({ ...defaultCustomerLaneData });
  }

  scheduleChanged(payload: DynamicPricingScheduleChangedData): void {
    if (!payload.valid) {
      return;
    }

    const rule = payload.pricingRule;

    // update the pricing schedule rather than overlay the entire object since we don't capture address until we save
    this.pricingRule.floorAmt = rule.floorAmt;
    this.pricingRule.floorPct = rule.floorPct;
    this.pricingRule.nbrAdjustments = rule.nbrAdjustments;
    this.pricingRule.adjustmentFrequency = rule.adjustmentFrequency;
  }

  save() {
    const lanes: CustomerLaneData[] = [];
    const laneComponents = this.lanes.toArray();

    laneComponents.forEach((laneComponent) => {
      lanes.push(laneComponent.getLane());
    });

    if (this.hasPricingRule()) {
      if (this.group.customerLaneGroupPricingRule) {
        // Create new pricing rule with values from old
        this.group.customerLaneGroupPricingRule = {
          ...this.group.customerLaneGroupPricingRule,
          ...this.pricingRule,
          customerLaneGroupPricingRuleId: 0,
        };
      } else {
        this.group.customerLaneGroupPricingRule = {
          customerLaneGroupId: this.group.customerLaneGroupId,
          ...this.pricingRule,
          customerLaneGroupPricingRuleId: 0,
        };
      }
    } else {
      this.group.customerLaneGroupPricingRule = null;
    }

    // Currently we only allow a single contact.
    // There will be a future story to allow multiple ranked contacts on a shipper
    if (this.contact.display || this.contact.email || this.contact.phone) {
      this.group.customerLaneGroupContacts = [
        { ...this.contact, customerLaneGroupId: this.group.customerLaneGroupId, customerlaneGroupContactId: 0, sortOrder: 0 },
      ];
    } else {
      this.group.customerLaneGroupContacts = [];
    }

    // set brokered flags
    this.group.brokeredLaneGroup = this.shipper.brokeredLoads;
    if (this.group.eligibleForQuote) {
      // make sure all fields are cleared out beside 'brokered' section
      this.group.specialInstructions = null;
      this.group.loadCarrierGroupTypeId = null;
      this.group.carrierIds = [];
      this.group.serviceTypeIds = [];
      this.group.customerLaneGroupContacts = [];
      this.group.customerLaneGroupPricingRule = null;
    }

    this.saveClick.emit({ ...this.group, customerLanes: lanes });
  }

  hasPricingRule(): boolean {
    if (this.pricingRule.adjustmentFrequency || this.pricingRule.floorAmt || this.pricingRule.floorPct || this.pricingRule.nbrAdjustments) {
      return true;
    }
  }

  cancel(): void {
    // Assume the form is dirty.
    // This really should be converted to a Reactive Form
    // but was copied from Load Carrier Group which was not
    this.cancelClick.emit(true);
  }

  delete(): void {
    this.deleteClick.emit(this.group);
  }

  expandAllForms() {
    this.specialInstructionsCollapsed = false;
    this.requiredServiceTypesCollapsed = false;
    this.carrierGroupCollapsed = false;
    this.rateSeekerCollapsed = false;
    this.contactsCollapsed = false;
  }

  getTotalMargin(): Number {
    if (!this.shipper && !this.group?.marginAdjustment) {
      return 0;
    }
    let margin = this.shipper?.brokeredCustomerSettings?.marginPercent;

    if (Number.isFinite(this.group.marginAdjustment) || this.group.marginAdjustment > 0) {
      margin += this.group.marginAdjustment;
    }
    return Number.parseFloat(margin.toFixed(2));
  }

  isTotalMarginNegative(): boolean {
    const margin = this.getTotalMargin();
    return margin < 0;
  }
  clear(section: string) {
    if (section === 'specialInstructions') {
      this.group.specialInstructions = null;
    } else if (section === 'requiredServiceTypes') {
      this.group.serviceTypeIds = [];
    } else if (section === 'carrierGroups') {
      this.group.loadCarrierGroupTypeId = null;
      this.group.carrierIds = [];
    } else if (section === 'shipperContacts') {
      this.group.customerLaneGroupContacts = null;
      this.contact = { ...defaultCustomerLaneGroupContactData };
    }
  }
}
