import { Action } from '@ngrx/store';
import { FuelRateEstimateByProgramData } from 'src/app/shared/models/fuel-rate-estimate-by-program-data';

export enum FuelRateActionTypes {
  GetFuelRateEstimate = '[FuelRate] GET_FUEL_RATE_ESTIMATE',
  GetFuelRateEstimateSuccess = '[FuelRate] GET_FUEL_RATE_ESTIMATE_SUCCESS',
  GetFuelRateEstimateFailure = '[FuelRate] GET_FUEL_RATE_ESTIMATE_FAILURE',
}

export class FuelRateEstimateAction implements Action {
  readonly type = FuelRateActionTypes.GetFuelRateEstimate;

  constructor(public payload: { loadId: string; customerId: string }) {}
}

export class FuelRateEstimateSuccessAction implements Action {
  readonly type = FuelRateActionTypes.GetFuelRateEstimateSuccess;

  constructor(public payload: { loadId: string; estimate: FuelRateEstimateByProgramData }) {}
}

export class FuelRateEstimateFailureAction implements Action {
  readonly type = FuelRateActionTypes.GetFuelRateEstimateFailure;

  constructor(public payload: { loadId: string; error: Error }) {}
}

export type FuelRateActions = FuelRateEstimateAction | FuelRateEstimateSuccessAction | FuelRateEstimateFailureAction;
