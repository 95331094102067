export interface LoadContact {
  loadContactId: string;
  loadId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  userId: string;
  identUserId: string;
  isEmailOverridden: boolean;
}

export const defaultLoadContact: LoadContact = {
  loadContactId: null,
  loadId: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  email: null,
  userId: null,
  identUserId: null,
  isEmailOverridden: false,
};
