export class MarginalAnalysisWorksheet {
  loadId: string;
  loadMiles: number;
  loadMarginalAnalysisId: number;
  loadClaimId: string;
  driverAddr1: string;
  driverCity: string;
  driverState: string;
  driverZip: string;
  driverCountry: string;
  driverDeadHeadMiles: number;
  nextKbxlOpportunityAddr1: string;
  nextKbxlOpportunityCity: string;
  nextKbxlOpportunityState: string;
  nextKbxlOpportunityZip: string;
  nextKbxlOpportunityCountry: string;
  nextKbxlOpportunityDeadHeadMiles: number;
  nextLoadAddr1: string;
  nextLoadCity: string;
  nextLoadState: string;
  nextLoadZip: string;
  nextLoadCountry: string;
  nextLoadDeadHeadMiles: number;
  lineHaulRate: number;
  dedicatedFuel: number;
  dedicatedBackhaulExpense: number;
  negotiatingStartingPoint: number;
  counterOffer: number;
  minimumNeededRate: number;
  canBookLoad: boolean;
  error: string;
}
