export enum TransactionType {
  PendingAdd = 'PendingAdd',
  PendingUpdate = 'PendingUpdate',
  Posted = 'Posted',
  PendingFuel = 'PendingFuel',
  PendingRates = 'PendingRates',
  New = 'New',
  Updated = 'Updated',
  PreTender = 'PreTender',
  Pending = 'Pending',
  Accepted = 'Accepted',
  SentToShipperTender = 'SentToShipperTender',
  Removed = 'Removed',
  Delivered = 'Delivered',
  PendingPod = 'PendingPod',
}
