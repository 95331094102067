import { createReducer } from '../../../shared/utilities';
import { ChatActiveLoadsActions, ChatActiveLoadsActionTypes } from '../actions';

export interface ChatActiveLoadsState {
  selectedLoadId: string;
}

const initialState: ChatActiveLoadsState = {
  selectedLoadId: null,
};

const _chatActiveLoadsReducer = createReducer(
  initialState,
  function (state: ChatActiveLoadsState = initialState, action: ChatActiveLoadsActions): ChatActiveLoadsState {
    switch (action.type) {
      case ChatActiveLoadsActionTypes.Select: {
        return { ...state, selectedLoadId: action.loadId };
      }
      default:
        return state;
    }
  }
);
/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function chatActiveLoadsReducer(state: ChatActiveLoadsState, action: ChatActiveLoadsActions): ChatActiveLoadsState {
  return _chatActiveLoadsReducer(state, action);
}
