import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  getCustomerLaneGroupFirstRecord,
  getCustomerLaneGroupSearchCrit,
  getLoadCustomerLaneGroups,
  getLoadingLoadCustomerLaneGroups,
} from 'src/app/admin/store/selectors/lane-management.selector';
import { CoreState, getBrowserIsMobile, getEquipment, getLoadingEquipment, getStates } from 'src/app/core/store';
import {
  CustomerLaneData,
  CustomerLaneGroupData,
  CustomerProfile,
  defaultLaneSearch,
  Equipment,
  LaneAttributeType,
  LaneSearch,
  State,
  User,
} from 'src/app/shared/models';
import { getUserProfileEntity, UserState } from 'src/app/user/store';
import { BaseComponent } from '../../../../shared/components';
import {
  AdminState,
  getSelectedShipper,
  LaneManagementLoadAction,
  LaneManagementPageNumberChange,
  LaneManagementSearchChange,
  ShipperProfileLoadAction,
} from '../../../store';

@Component({
  selector: 'kbxl-lane-group-container',
  templateUrl: './lane-group-container.component.html',
  styleUrls: ['./lane-group-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaneGroupContainerComponent extends BaseComponent implements OnInit, OnDestroy {
  filteredGroups$: Observable<CustomerLaneGroupData[]>;
  searchCrit$: Observable<LaneSearch>;
  states$: Observable<State[]>;
  loading$: Observable<boolean>;
  equipment$: Observable<Equipment[]>;
  first$ = new Observable<number>();
  isMobile$: Observable<boolean>;
  user$: Observable<User>;
  user: User;
  shipper$: Observable<CustomerProfile>;

  constructor(
    private adminStore: Store<AdminState>,
    private userStore: Store<UserState>,
    private coreStore: Store<CoreState>,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.user$ = this.userStore.pipe(select(getUserProfileEntity), takeUntil(this.destroyed$));

    this.user$.subscribe((user) => {
      this.loadLanes(user);
      this.user = user;

      if (this.user.isShipper) {
        this.adminStore.dispatch(new ShipperProfileLoadAction({ customerId: user.primaryCustomerId }));
      }
    });
    this.shipper$ = this.adminStore.pipe(
      select(getSelectedShipper),
      map((_) => this.deepClone(_))
    );
    this.searchCrit$ = this.adminStore.pipe(select(getCustomerLaneGroupSearchCrit));
    const groups$ = this.adminStore.pipe(select(getLoadCustomerLaneGroups));

    this.filteredGroups$ = combineLatest([this.searchCrit$, groups$]).pipe(
      select((args) => {
        const searchCrit = args[0];
        const groups = args[1];

        const g = groups.filter((group) => this.filterLaneGroup(group, searchCrit));
        return g;
      })
    );
    this.equipment$ = this.coreStore.pipe(select(getEquipment));
    this.states$ = this.coreStore.pipe(select(getStates));
    this.isMobile$ = this.coreStore.pipe(select(getBrowserIsMobile));
    this.first$ = this.adminStore.pipe(select(getCustomerLaneGroupFirstRecord));

    this.loading$ = combineLatest([
      this.adminStore.pipe(select(getLoadingLoadCustomerLaneGroups)),
      this.coreStore.pipe(select(getLoadingEquipment)),
    ]).pipe(map((args) => args[0] || args[1]));
  }

  private loadLanes(user: User) {
    if (user && user.primaryCustomerId) {
      this.adminStore.dispatch(new LaneManagementLoadAction({ customerId: user.primaryCustomerId }));
    }
  }

  displayDetail(group: CustomerLaneGroupData) {
    const path: [string | number] = ['maint/lane-management/detail'];
    if (group && group.customerLaneGroupId > 0) {
      path.push(group.customerLaneGroupId);
    }
    this.router.navigate(path);
  }

  firstChange(first: number) {
    this.adminStore.dispatch(new LaneManagementPageNumberChange(first));
  }

  private filterLaneGroup(group: CustomerLaneGroupData, search: LaneSearch): boolean {
    if (
      search.quickSearch &&
      !(
        (group.description && group.description.toLowerCase().includes(search.quickSearch.toLowerCase())) ||
        group.customerLaneGroupId.toString() === search.quickSearch.toLowerCase() ||
        (group.customerLanes && this.hasLaneMatch(group.customerLanes, search.quickSearch))
      )
    ) {
      return false;
    }

    if (search.equipmentType) {
      const equipment = JSON.parse(search.equipmentType) as string[];
      const laneGropuEquipment = group.customerLanes.map((_) => _.equipmentIds).reduce((prev, next) => prev.concat(next));

      if (!equipment.find((x) => laneGropuEquipment.includes(x))) {
        return false;
      }
    }

    if (search.destState && !search.destLat && !search.destLng) {
      const groupStates = group.customerLanes.map((_) => _.destState);

      if (!groupStates.includes(search.destState)) {
        return false;
      }
    }

    if (search.origState && !search.origLat && !search.origLng) {
      const groupStates = group.customerLanes.map((_) => _.origState);

      if (!groupStates.includes(search.origState)) {
        return false;
      }
    }
    if ((search.origLat && search.origLng) || (search.destLat && search.destLng)) {
      if (!this.hasExactLaneMatch(group.customerLanes, search)) {
        return false;
      }
    }

    if (search.laneAttributeTypes && search.laneAttributeTypes.length > 0) {
      if (search.laneAttributeTypes.includes(LaneAttributeType.ExcludeCarrierGroup)) {
        if (!group.hasExclusionCarrierGroup) {
          return false;
        }
      }
      if (search.laneAttributeTypes.includes(LaneAttributeType.IncludeCarrierGroup)) {
        if (!group.hasInclusionCarrierGroup) {
          return false;
        }
      }
      if (search.laneAttributeTypes.includes(LaneAttributeType.RateSeeker)) {
        if (!group.hasRateSeeker) {
          return false;
        }
      }
      if (search.laneAttributeTypes.includes(LaneAttributeType.ShipperContact)) {
        if (!group.hasShipperContacts) {
          return false;
        }
      }
      if (search.laneAttributeTypes.includes(LaneAttributeType.SpecialInstruction)) {
        if (!group.specialInstructions) {
          return false;
        }
      }
      if (search.laneAttributeTypes.includes(LaneAttributeType.EligibleForQuote)) {
        if (!group.eligibleForQuote) {
          return false;
        }
      }
    }

    return true;
  }

  hasExactLaneMatch(lanes: CustomerLaneData[], search: LaneSearch) {
    return (
      (!search.origCity || lanes.some((_) => _.origCity === search.origCity)) &&
      (!search.destCity || lanes.some((_) => _.destCity === search.destCity)) &&
      (!search.origState || lanes.some((_) => _.origState === search.origState)) &&
      (!search.destState || lanes.some((_) => _.destState === search.destState))
    );
  }

  hasLaneMatch(lanes: CustomerLaneData[], search: string): boolean {
    const allEquipment = lanes.map((_) => _.equipmentIds).reduce((previous, value) => previous.concat(value));

    if (allEquipment.some((_) => _.toLowerCase().includes(search.toLowerCase()))) {
      return true;
    }

    if (lanes.some((_) => (_.origCity || '').toLowerCase().includes(search.toLowerCase()))) {
      return true;
    }
    if (lanes.some((_) => (_.destCity || '').toLowerCase().includes(search.toLowerCase()))) {
      return true;
    }
    if (lanes.some((_) => (_.origState || '').toLowerCase().includes(search.toLowerCase()))) {
      return true;
    }
    if (lanes.some((_) => (_.destState || '').toLowerCase().includes(search.toLowerCase()))) {
      return true;
    }

    if (lanes.some((_) => (_.destCity + ', ' + _.destState).toLowerCase().includes(search.toLowerCase()))) {
      return true;
    }
    if (lanes.some((_) => (_.origCity + ', ' + _.origState).includes(search.toLowerCase()))) {
      return true;
    }
  }

  search(searchCrit: LaneSearch) {
    this.firstChange(0);
    this.adminStore.dispatch(new LaneManagementSearchChange(searchCrit));
  }

  clear() {
    this.adminStore.dispatch(new LaneManagementSearchChange({ ...defaultLaneSearch }));
  }

  refreshLanes() {
    this.loadLanes(this.user);
  }
}
