import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ServiceResponse, MileageRequest, LineHaulRateDto } from '../../shared/models';
import { mapResponse } from '../../shared/operators/map-response';
import { MarginalAnalysisWorksheet } from 'src/app/shared/models/marginal-analysis-worksheet';

@Injectable()
export class MarginalAnalysisService {
  constructor(private http: HttpClient) {}

  calculateWorksheet(worksheet: MarginalAnalysisWorksheet): Observable<MarginalAnalysisWorksheet> {
    return this.http
      .post<ServiceResponse<MarginalAnalysisWorksheet>>(`${environment.apiUrl}/api/MarginalAnalysis/Calculate`, worksheet)
      .pipe(mapResponse());
  }

  getHHGMiles(request: MileageRequest): Observable<number> {
    return this.http.post<ServiceResponse<number>>(`${environment.apiUrl}/api/MarginalAnalysis/hhg-miles`, request).pipe(mapResponse());
  }

  getLineHaulRate(loadId: string): Observable<LineHaulRateDto> {
    return this.http
      .get<ServiceResponse<LineHaulRateDto>>(`${environment.apiUrl}/api/MarginalAnalysis/line-haul-rate/${loadId}`)
      .pipe(mapResponse());
  }
}
