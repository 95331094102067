import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AdminState,
  CarrierProfileCancelUpdateAction,
  CarrierProfileLoadAction,
  CarrierProfileLoadAllAction,
  CarrierProfileUpdateAction,
  getAdminUsers,
  getAdminUsersLoading,
  getAllCarriers,
  getAllCarriersLoading,
  getCarrierProfile,
  getCarrierProfileLoading,
  getCarrierProfileUpdating,
  UserAdminLoadAdminUsersAction,
} from 'src/app/admin/store';
import { Carrier, CarrierProfile, UserAdminData } from 'src/app/shared/models';
import { BaseComponent } from '../../../../shared/components';

@Component({
  selector: 'kbxl-carrier-profile-container',
  templateUrl: './carrier-profile-container.component.html',
  styleUrls: ['./carrier-profile-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierProfileContainerComponent extends BaseComponent {
  carriers$: Observable<Carrier[]>;
  adminUsers$: Observable<UserAdminData[]>;
  processing$: Observable<boolean>;
  selectedCarrierProfile$: Observable<CarrierProfile>;

  constructor(private adminStore: Store<AdminState>) {
    super();
    this.adminStore.dispatch(new CarrierProfileLoadAllAction());
    this.adminStore.dispatch(new UserAdminLoadAdminUsersAction());

    this.adminUsers$ = this.adminStore.pipe(select(getAdminUsers));
    this.carriers$ = this.adminStore.pipe(select(getAllCarriers));

    this.selectedCarrierProfile$ = this.adminStore.pipe(
      select(getCarrierProfile),
      map((x) => this.deepClone(x))
    );

    this.processing$ = combineLatest([
      this.adminStore.pipe(select(getCarrierProfileLoading)),
      this.adminStore.pipe(select(getAllCarriersLoading)),
      this.adminStore.pipe(select(getAdminUsersLoading)),
      this.adminStore.pipe(select(getCarrierProfileUpdating)),
    ]).pipe(map((args) => args[0] || args[1] || args[2] || args[3]));
  }

  carrierSelected(carrier: Carrier) {
    this.adminStore.dispatch(new CarrierProfileLoadAction({ carrierId: carrier.carrierId }));
  }

  update(carrier: CarrierProfile) {
    this.adminStore.dispatch(new CarrierProfileUpdateAction(carrier));
  }

  cancelUpdateClick() {
    this.adminStore.dispatch(new CarrierProfileCancelUpdateAction());
  }
}
