import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { race, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConversationHubService } from '../../../chat/services';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'kbxl-chat-presence-indicator',
  templateUrl: './chat-presence-indicator.component.html',
  styleUrls: ['./chat-presence-indicator.component.scss'],
})
export class ChatPresenceIndicatorComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() userId: string;

  @Output() userActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  userActive = false;
  private timerSeconds = 45;
  userIdChanged$ = new Subject<boolean>();
  constructor(private conversationHub: ConversationHubService) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.userId) {
      this.userIdChanged$.next(true);
      this.fetchUserPresence();
    }
  }

  ngOnInit(): void {
    if (this.userId) {
      this.fetchUserPresence();
    }
  }

  private fetchUserPresence(): void {
    timer(0, this.timerSeconds * 1000)
      .pipe(takeUntil(race(this.destroyed$, this.userIdChanged$)))
      .subscribe((_) => {
        this.conversationHub.isUserActive(this.userId).subscribe((x) => {
          this.userActive = x;
          this.userActiveChange.emit(x);
        });
      });
  }
}
