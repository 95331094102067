import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CustomerApiEndpointData, CustomerApiTypeData } from '../../../../shared/models';
import {
  AdminState,
  getShipperAvailableApiTypes,
  getShipperEndpoints,
  getShipperEndpointsLoading,
  ShipperApiEndpointsCopyAction,
  ShipperApiEndpointsLoadAction,
} from '../../../store';

@Component({
  selector: 'kbxl-shipper-endpoints-modal',
  templateUrl: './shipper-endpoints-modal.component.html',
  styleUrls: ['./shipper-endpoints-modal.component.scss'],
})
export class ShipperEndpointsModalComponent implements OnInit, OnChanges {
  @Input() customerId: string;

  customerEndpointsLoading$: Observable<boolean>;
  customerAvailableApiTypes$: Observable<CustomerApiTypeData[]>;
  customerEndpoints$: Observable<CustomerApiEndpointData[]>;

  selectedApiTypes: string[];

  constructor(private adminStore: Store<AdminState>) {}

  ngOnInit() {
    this.customerEndpointsLoading$ = this.adminStore.pipe(select(getShipperEndpointsLoading));
    this.customerAvailableApiTypes$ = this.adminStore.pipe(select(getShipperAvailableApiTypes));
    this.customerEndpoints$ = this.adminStore.pipe(select(getShipperEndpoints));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.customerId && changes.customerId.currentValue) {
      // Dispatch
      this.adminStore.dispatch(new ShipperApiEndpointsLoadAction(this.customerId));
    }
  }

  copyEndpoints(): void {
    if (this.selectedApiTypes && this.selectedApiTypes.length > 0) {
      this.adminStore.dispatch(new ShipperApiEndpointsCopyAction(this.customerId, this.selectedApiTypes));
      this.selectedApiTypes = [];
    }
  }
}
