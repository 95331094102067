<p-panel [toggleable]="true" [collapsed]="true" styleClass=" no-padding">
  <p-header class="mr-2">
    {{ getActionHeader(carrierRequest) }}
  </p-header>
  <div class="d-flex flex-column p-2">
    <p-scrollPanel [style]="{ width: '100%', maxHeight: '200px' }">
      <div class="d-flex flex-column scroll-hack">
        <div *ngFor="let action of carrierRequest.actions" class="comment-bubble" [ngClass]="isUserComment(action, carrierRequest)">
          <div class="comment">{{ action.comment }}</div>
          <div class="text-muted mt-1">{{ action.userName | titlecase }} - {{ action.createDtTm | date: 'short' }}</div>
        </div>
      </div>
      <span class="comment-end" [id]="'comment-end-' + carrierRequest.carrierAccessorialRequestId">&nbsp;</span>
    </p-scrollPanel>
    <div class="d-flex mt-2" *ngIf="areCommentsAllowed(carrierRequest)">
      <textarea
        placeholder="Additional Comment"
        rows="1"
        cols="50"
        pInputTextarea
        name="comments"
        maxlength="4000"
        #commentInput
      ></textarea>
      <button
        pButton
        (click)="addCommentClick(commentInput, carrierRequest)"
        icon="pi pi-arrow-right"
        type="button"
        class="p-button-teal comment-button ml-2"
      ></button>
    </div>
  </div>
</p-panel>
