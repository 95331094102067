import { LoadDetail } from './load-detail';

export interface BookLoadRequest {
  loadData: LoadDetail;
  bookingType: BookLoadType;
  tenderingToOptions?: TenderToOptions;
}

export enum BookLoadType {
  CarrierBooked = 1,
  CounterOfferBooked = 2,
  TenderedTo = 3,
}
export class TenderToOptions {
  scac: string;
  carrierId: string;
  carrierUserId: string;
  lineHaulRate: number;
  fuelRate: number;
}
