<div class="progress-indicator" *ngIf="loading">
  <div class="progressspinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div *ngIf="shipperAccessorials" class="d-none d-lg-block px-4">
  <div class="row" *ngIf="error">
    <div class="col error-container">
      <p>{{ decodeProblemDetails() }}</p>
    </div>
  </div>
  <p-panel styleClass="no-padding">
    <p-header class="w-100">
      <div class="d-flex align-items-center pr-2">
        <span>{{ headerTitle }}</span>
        <button
          *ngIf="enableAdd"
          pButton
          (click)="addClick()"
          icon="pi pi-plus"
          label="Add New"
          type="button"
          class="p-button-teal ml-auto"
        ></button>
      </div>
    </p-header>
    <p-table
      [rowHover]="true"
      [value]="shipperAccessorials"
      selectionMode="single"
      styleClass="p-datatable-no-border"
      (onPage)="onPage($event)"
      ><ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="numberOfColumns text-center" style="height: 80px">
            <span *ngIf="loading">Loading...</span>
            <span *ngIf="!loading">No results</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'accessorialDescription'">
            Accessorial
            <p-sortIcon [field]="'accessorialDescription'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'accessorialUnitOfMeasureDescription'">
            Unit of Measure
            <p-sortIcon [field]="'accessorialUnitOfMeasureDescription'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'variableRate'" class="variable-rate">
            Variable Rate
            <p-sortIcon [field]="'variableRate'"></p-sortIcon>
          </th>
          <th class="rate-uom">Dollar Amount Per Unit of Measure</th>
          <th class="max-rate">Max Rate</th>
          <th class="actions-column" *ngIf="enableDelete">&nbsp;</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-accessorial let-rowIndex="rowIndex">
        <tr [ngClass]="{ 'd-none': accessorial.isRemoved }">
          <td>{{ accessorial.accessorialDescription }}</td>
          <td>
            <span>{{ accessorial.accessorialUnitOfMeasureDescription }}</span>
            <div *ngIf="shouldShowAfterInitialHours(accessorial)" class="after-initial">
              <span>After:</span>
              <p-dropdown
                [options]="afterInitialHoursValues"
                [(ngModel)]="accessorial.afterInitialHours"
                (ngModelChange)="setAfterInitialHours(rowIndex)"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
              >
              </p-dropdown>
            </div>
          </td>
          <td class="variable-rate text-center">
            <p-checkbox
              [(ngModel)]="accessorial.variableRate"
              binary="true"
              label=""
              (ngModelChange)="addChange(accessorial, rowIndex)"
            ></p-checkbox>
          </td>
          <td class="rate-uom">
            <span
              [ngClass]="{
                'd-none': !!accessorial.variableRate || accessorial.accessorialUnitOfMeasureDescription === 'Flat Charge',
                error: hasError('MaxDollarRatePerUOM', rowIndex)
              }"
            >
              <input
                type="text"
                [(ngModel)]="accessorial.maxDollarRatePerUOM"
                pInputText
                maxlength="10"
                kbxlCurrencyInput
                (ngModelChange)="addChange(accessorial, rowIndex)"
              />
            </span>
          </td>
          <td class="max-rate">
            <span [ngClass]="{ 'd-none': !!accessorial.variableRate, error: hasError('MaxRate', rowIndex) }">
              <input
                type="text"
                [(ngModel)]="accessorial.maxRate"
                pInputText
                maxlength="10"
                kbxlCurrencyInput
                (ngModelChange)="addChange(accessorial, rowIndex)"
              />
            </span>
          </td>
          <td class="actions-column" *ngIf="enableDelete">
            <button
              class="p-button-danger"
              pButton
              icon="pi pi-trash"
              (click)="removeAccessorial(accessorial, rowIndex)"
              title="Delete Accessorial"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="d-flex mt-4">
      <button pButton (click)="saveChanges()" label="Save" type="button" class="ml-auto" [disabled]="!pendingChange || loading"></button>
    </div>
  </p-panel>
</div>
<div class="d-lg-none pt-2">
  <div class="title-bar">
    <span>{{ headerTitle }}</span>
  </div>
  <div *ngIf="shipperAccessorials && shipperAccessorials.length === 0">
    <span *ngIf="!loading">No results</span>
  </div>
  <div class="accessorial-item mt-3" *ngFor="let accessorial of shipperAccessorials; index as rowIndex">
    <p-card styleClass="accessorial-grid-card">
      <p-header>
        <div>
          {{ accessorial.accessorialDescription }}
        </div>
      </p-header>
      <div class="d-flex flex-column accessorial-item-detail">
        <div class="d-flex flex-wrap align-items-center">
          <div class="flex-grow-1">
            <span>Accessorial</span>
          </div>
          <div>
            <span>{{ accessorial.accessorialDescription }}</span>
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-center">
          <div class="flex-grow-1">
            <span>Unit of Measure</span>
          </div>
          <div>
            <span>{{ accessorial.accessorialUnitOfMeasureDescription }}</span>
          </div>
          <div *ngIf="shouldShowAfterInitialHours(accessorial)" class="after-initial">
            <span>After</span>
            <p-dropdown
              [options]="afterInitialHoursValues"
              (onChange)="setAfterInitialHours($event, accessorial, rowIndex)"
              [styleClass]="'p-dropdown-primary'"
              dropdownIcon="pi pi-caret-down"
            >
            </p-dropdown>
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-center mt-2">
          <div class="flex-grow-1">
            <span>&nbsp;</span>
          </div>
          <div>
            <span>
              <p-checkbox
                [(ngModel)]="accessorial.variableRate"
                binary="true"
                label="Variable Rate"
                (ngModelChange)="addChange(accessorial, rowIndex)"
              ></p-checkbox
            ></span>
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-center mt-2">
          <div class="flex-grow-1">
            <span>Dollar Amount Per Unit of Measure</span>
          </div>
          <div>
            <span [ngClass]="{ 'd-none': !!accessorial.variableRate || accessorial.accessorialUnitOfMeasureDescription === 'Flat Charge' }">
              <input
                type="text"
                [(ngModel)]="accessorial.maxDollarRatePerUOM"
                pInputText
                maxlength="10"
                kbxlCurrencyInput
                (ngModelChange)="addChange(accessorial, rowIndex)"
              />
            </span>
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-center mt-2">
          <div class="flex-grow-1">
            <span>Max Rate</span>
          </div>
          <div>
            <span [ngClass]="{ 'd-none': !!accessorial.variableRate }">
              <input
                type="text"
                [(ngModel)]="accessorial.maxRate"
                pInputText
                maxlength="10"
                kbxlCurrencyInput
                (ngModelChange)="addChange(accessorial, rowIndex)"
              />
            </span>
          </div>
        </div>
      </div>
    </p-card>
  </div>
  <div class="mt-4 d-flex">
    <button
      pButton
      *ngIf="enableAdd"
      (click)="addClick()"
      icon="pi pi-plus"
      label="Add New"
      type="button"
      class="mobile-add-button p-button-teal"
    ></button>

    <button pButton (click)="saveChanges()" label="Save" type="button" class="ml-auto" [disabled]="!pendingChange || loading"></button>
  </div>
</div>
