import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResponseError } from '../../shared/models';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.apiUrl)) {
      // append withCredentials onto request so we send cookies to API, this is needed for impersonation
      req = req.clone({
        withCredentials: true,
      });
    }

    return next.handle(req).pipe(
      map((x) => {
        if (x instanceof HttpResponse) {
          if (x.body.errors && x.body.errors.length) {
            const errors: ResponseError[] = x.body.errors;
            console.error(errors);
            const msgs = errors.map((y) => ({
              detail: y.message,
              severity: 'error',
            }));
            this.messageService.addAll(msgs);
          }
        }
        return x;
      })
    );
  }
}
