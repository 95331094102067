import { createSelector } from '@ngrx/store';
import { getUserFeatureState, UserState } from '../reducers';
import { getEntity, getUserModel, getLoaded, getLoading, getSaving, getSavingValidationProblems } from '../reducers/user-profile.reducer';

const getUserProfileState = createSelector(getUserFeatureState, (state: UserState) => state.userProfile);

export const getUserProfileEntity = createSelector(getUserProfileState, getEntity);
export const getUserProfileModel = createSelector(getUserProfileState, getUserModel);
export const getUserProfileLoading = createSelector(getUserProfileState, getLoading);
export const getUserProfileProblemDetails = createSelector(getUserProfileState, getSavingValidationProblems);
export const getUserProfileLoaded = createSelector(getUserProfileState, getLoaded);
export const getUserProfileSaving = createSelector(getUserProfileState, getSaving);

export const getLoadStatusNotifications = createSelector(getUserProfileState, (x) => x.loadStatusNotifications);
export const getLoadStatusNotificationsProblemDetails = createSelector(
  getUserProfileState,
  (x) => x.savingLoadStatusNotificationsProblemDetails
);

export const getSavedLoadStatusNotificationsProblemDetails = createSelector(
  getUserProfileState,
  (x) => x.savedLoadStatusNotificationsProblemDetails
);
