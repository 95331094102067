import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Load, LoadDetail } from '../../models';

@Component({
  selector: 'kbxl-book-load-modal',
  templateUrl: './book-load-modal.component.html',
  styleUrls: ['./book-load-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookLoadModalComponent implements OnChanges {
  @Input() load: LoadDetail;
  @Input() processing: boolean;
  @Input() isCounterOffer = false;
  @Output() book: EventEmitter<Load> = new EventEmitter<Load>();
  @Output() cancelBook: EventEmitter<boolean> = new EventEmitter<boolean>();
  bookingAgreementTitles: MenuItem[];
  currentAgreementIndex = 0;
  message: '';
  showBookButton = false;
  displaySteps = false;

  // used to track all the applicable agreements the user must agree to
  userAgreements = [];
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.load || this.load.viewOnly) {
      return;
    }
    this.reset();

    if (!this.load.bookingAgreements) {
      this.load.bookingAgreements = [];
    }

    if (this.isCounterOffer) {
      const counterOfferAgreements = this.load.bookingAgreements.filter((x) => x.isApplicableForCounterOffer);

      this.userAgreements = counterOfferAgreements.slice();
      this.bookingAgreementTitles = counterOfferAgreements.map((x) => ({ label: x.agreementTitle }));

      if (counterOfferAgreements.length === 1) {
        this.showBookButton = true;
      } else {
        this.displaySteps = true;
      }
    } else {
      const requiredAgreements = this.load.bookingAgreements.filter((x) => x.isRequiredAgreement);
      this.userAgreements = requiredAgreements.slice();
      this.bookingAgreementTitles = requiredAgreements.map((x) => ({ label: x.agreementTitle }));

      if (this.bookingAgreementTitles.length === 1) {
        this.showBookButton = true;
      } else {
        this.displaySteps = true;
      }
    }
  }

  getContent(): string {
    if (this.load && this.load.bookingAgreements && this.userAgreements.length > 0) {
      const agreement = this.userAgreements[this.currentAgreementIndex];
      if (agreement) {
        return agreement.agreementContent;
      }
    }

    return '';
  }
  agree(): void {
    this.currentAgreementIndex++;

    if (this.currentAgreementIndex >= this.userAgreements.length - 1) {
      this.showBookButton = true;
      this.currentAgreementIndex = this.userAgreements.length - 1;
    }
  }

  bookLoad() {
    this.reset();
    this.book.emit(this.load);
  }

  cancel() {
    this.reset();
    this.cancelBook.emit(true);
  }

  reset(): void {
    this.showBookButton = false;
    this.currentAgreementIndex = 0;
  }
}
