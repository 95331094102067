import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { ILoadAuditLogData } from '../../models';

@Component({
  selector: 'kbxl-shipping-load-audit-log-grid-modal',
  templateUrl: './shipping-load-audit-log-grid-modal.component.html',
  styleUrls: ['./shipping-load-audit-log-grid-modal.component.scss'],
})
export class ShippingLoadAuditLogGridModalComponent {
  loadAuditLogs$: Observable<ILoadAuditLogData[]>;
  loadingAuditLogs$: Observable<boolean>;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.loadAuditLogs$ = config.data.loadAuditLogs;
    this.loadingAuditLogs$ = config.data.loadingAuditLogs;
  }
}
