import { Action } from '@ngrx/store';
import { StartupData } from '../../../shared/models';

export enum StartupActionTypes {
  Load = '[Startup] LOAD',
  Load_Success = '[Startup] LOAD_SUCCESS',
  Load_Failure = '[Startup] LOAD_FAILURE',
}

export class StartupLoadAction implements Action {
  readonly type = StartupActionTypes.Load;

  constructor() {}
}

export class StartupLoadSuccessAction implements Action {
  readonly type = StartupActionTypes.Load_Success;

  constructor(public payload: StartupData) {}
}

export class StartupLoadFailureAction implements Action {
  readonly type = StartupActionTypes.Load_Failure;

  constructor(public payload: Error) {}
}

export type StartupActions = StartupLoadAction | StartupLoadSuccessAction | StartupLoadFailureAction;
