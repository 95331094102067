import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  AdminState,
  getShipperMappings,
  getSourceSystemOwners,
  loadingShipperMappings,
  loadingSourceSystemOwners,
  savingShipperMapping,
  ShipperProfileCreateShipperMappingAction,
  ShipperProfileLoadShipperMappingsAction,
  ShipperProfileLoadSourceSystemOwnerAction,
  ShipperProfileUpdateShipperMappingAction,
} from 'src/app/admin/store';
import { defaultLoadshopShipperMapping, LoadshopShipperMapping } from 'src/app/shared/models/loadshop-shipper-mapping';
import { BaseComponent } from '../../../../shared/components';

@Component({
  selector: 'kbxl-shipper-mapping-modal',
  templateUrl: './shipper-mapping-modal.component.html',
  styleUrls: ['./shipper-mapping-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipperMappingModalComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public ownerId: string;
  @Input() public customerId: string;
  @Input() public visible: boolean;
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public shipperMappings$: Observable<LoadshopShipperMapping[]>;
  public sourceSystemOwners$: Observable<Map<string, string[]>>;
  public processing$: Observable<boolean>;

  public editingRow = false;
  public availableSourceSystems: SelectItem[] = [];
  private clonedMappings: LoadshopShipperMapping[] = [];

  constructor(private adminStore: Store<AdminState>) {
    super();
  }

  ngOnInit() {
    this.processing$ = combineLatest([
      this.adminStore.pipe(select(loadingSourceSystemOwners)),
      this.adminStore.pipe(select(loadingShipperMappings)),
      this.adminStore.pipe(select(savingShipperMapping)),
    ]).pipe(map((args) => args[0] || args[1] || args[2]));

    // Observables
    this.shipperMappings$ = this.adminStore.pipe(
      select(getShipperMappings),
      map((x) => this.deepClone(x))
    );
    this.sourceSystemOwners$ = this.adminStore.pipe(select(getSourceSystemOwners));

    // Subscriptions
    combineLatest([this.sourceSystemOwners$, this.shipperMappings$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([ssos, mappings]) => {
        if (ssos && mappings) {
          const sourceSystems = ssos[this.ownerId];
          this.availableSourceSystems = [];
          if (sourceSystems && sourceSystems.owners) {
            sourceSystems.owners.forEach((x: string) => {
              const r = mappings.find((y) => y.sourceSystem === x);
              if (!r) {
                this.availableSourceSystems.push({ label: x, value: x });
              }
            });
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.ownerId && changes.ownerId.currentValue !== changes.ownerId.previousValue) {
      // Dispatch
      this.adminStore.dispatch(new ShipperProfileLoadSourceSystemOwnerAction({ ownerId: this.ownerId }));
      this.adminStore.dispatch(new ShipperProfileLoadShipperMappingsAction({ ownerId: this.ownerId }));
    }
  }
  addRecord(e: any) {
    const mapping: LoadshopShipperMapping = {
      ...defaultLoadshopShipperMapping,
      ownerId: this.ownerId,
      customerId: this.customerId,
      sourceSystem: e.value,
      loadshopShipperId: this.ownerId,
    };
    this.adminStore.dispatch(new ShipperProfileCreateShipperMappingAction({ mapping: mapping }));
  }

  onRowEditInit(mapping: LoadshopShipperMapping) {
    this.clonedMappings[mapping.loadshopShipperMappingId] = { ...mapping };
    this.editingRow = true;
  }

  onRowEditSave(mapping: LoadshopShipperMapping) {
    delete this.clonedMappings[mapping.loadshopShipperMappingId];
    this.editingRow = false;
    this.adminStore.dispatch(new ShipperProfileUpdateShipperMappingAction({ mapping: mapping }));
  }

  onRowEditCancel(mapping: LoadshopShipperMapping, index: number, s: LoadshopShipperMapping[]) {
    s[index] = this.clonedMappings[mapping.loadshopShipperMappingId];
    delete this.clonedMappings[mapping.loadshopShipperMappingId];
    this.editingRow = false;
  }
}
