<div class="d-flex align-items-center">
  <a [href]="link" target="__blank">{{ linkText }}</a>
  <kbxl-copy-to-clipboard class="ml-1" [text]="link"></kbxl-copy-to-clipboard>
  <span class="share ml-1" (click)="shareClick()">
    <fa-icon [icon]="['fas', 'share']" size="md"></fa-icon>
  </span>
</div>
<p-dialog
  [(visible)]="showContactBuilder"
  appendTo="body"
  [baseZIndex]="51"
  [style]="{ width: '600px', maxHeight: '600px', overflow: 'auto' }"
  blockScroll="true"
  [header]="this.headerText"
  [modal]="true"
  [closable]="false"
>
  <kbxl-notification-contact-builder
    (sendClick)="sendLink($event)"
    (cancelClick)="cancelShare()"
    [cancelConfirmationMessage]="cancelConfirmationMessage"
    [sendConfirmationMessage]="sendConfirmationMessage"
    [processing]="processing"
  ></kbxl-notification-contact-builder>
</p-dialog>
