<link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap" rel="stylesheet">

<div class="d-flex flex-column p-1" *ngIf="load?.bookingAgreements">
  <div class="progress-indicator" *ngIf="processing">
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <p-steps class="w-100 mb-3" [model]="bookingAgreementTitles" [activeIndex]="currentAgreementIndex" *ngIf="displaySteps"></p-steps>
  <div class="overflow-auto agreement-content">
    <p [innerHTML]="getContent()"></p>
  </div>
  <p-accordion class="mt-4" *ngIf="load.loadAccessorials && load.loadAccessorials.length > 0">
    <p-accordionTab header="View Accessorials">
      <kbxl-load-accessorial-view [accessorials]="load.loadAccessorials" [height]="300"></kbxl-load-accessorial-view>
    </p-accordionTab>
  </p-accordion>
  <div class="text-right mt-4">
    <button pButton type="button" (click)="cancel()" label="No" class="p-button-outlined p-button-secondary mr-2"></button>
    <button pButton type="button" label="Yes" (click)="agree()" class="ml-2" *ngIf="!showBookButton"></button>
    <button pButton type="button" label="Yes" (click)="bookLoad()" class="ml-2" *ngIf="showBookButton"></button>
  </div>
</div>
