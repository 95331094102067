import { createSelector } from '@ngrx/store';

import { getSharedFeatureState } from '../reducers';
import { SharedState } from '../reducers';
import { getLoadBooking } from '../reducers/load-board-booked-reducer';

const getLoadBookedState = createSelector(getSharedFeatureState, (state: SharedState) => state.loadBookedState);

export const getLoadBoardBooking = createSelector(getLoadBookedState, getLoadBooking);
export const getLoadBoardBookingShowCounterOfferModal = createSelector(getLoadBookedState, (x) => x.showCounterOfferModal);
