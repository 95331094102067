import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceResponse, Caches } from '../../shared/models';
import { environment } from '../../../environments/environment';
import { mapResponse } from '../../shared/operators/map-response';

@Injectable()
export class CacheService {
  constructor(private http: HttpClient) {}

  clear(cacheFlags: Caches): Observable<null> {
    return this.http.get<ServiceResponse<null>>(`${environment.apiUrl}/api/admin/clear/${cacheFlags}`).pipe(mapResponse());
  }
}
