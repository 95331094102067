import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreState } from '../../../core/store';
import { BaseComponent } from '../../../shared/components';
import { UserModel } from '../../../shared/models';
import { loadshopApplicationReady, SharedState } from '../../../shared/store';
import { getUserProfileModel } from '../../store';

@Component({
  selector: 'kbxl-entity-inactive-indicator',
  templateUrl: './entity-inactive-indicator.component.html',
  styleUrls: ['./entity-inactive-indicator.component.scss'],
})
export class EntityInactiveIndicatorComponent extends BaseComponent implements OnInit {
  applicationReady$: Observable<boolean>;
  user: UserModel;
  constructor(
    private sharedStore: Store<SharedState>,
    private coreStore: Store<CoreState>
  ) {
    super();
  }
  ngOnInit() {
    this.applicationReady$ = this.sharedStore.pipe(select(loadshopApplicationReady));
    this.coreStore.pipe(takeUntil(this.destroyed$), select(getUserProfileModel)).subscribe((user) => {
      this.user = user;
    });
  }

  get entityName() {
    return (this.user && this.user.focusEntity) ? this.user.focusEntity.name : 'Unknown';
  }
}
