import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CoreState, BrowserSetKeepUsingInternetExplorerAction } from '../../store';
import { Router } from '@angular/router';
@Component({
  selector: 'kbxl-ie-policy',
  templateUrl: './ie-policy.component.html',
  styleUrls: ['./ie-policy.component.scss'],
})
export class IEPolicyComponent {
  constructor(private store: Store<CoreState>, private router: Router) {}

  handleClick(): void {
    this.store.dispatch(new BrowserSetKeepUsingInternetExplorerAction({ keepUsingInternetExplorer: true }));
    this.router.navigate(['/']);
  }
}
