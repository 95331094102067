import { createSelector } from '@ngrx/store';
import { AdminState, getAdminFeatureState } from '../reducers';
import { LaneManagementState, selectors } from '../reducers/lane-management.reducer';

const getLaneManagementState = createSelector(getAdminFeatureState, (state: AdminState) => state.laneManagement);

export const getLoadCustomerLaneGroups = createSelector(getLaneManagementState, selectors.selectAll);
export const getLoadingLoadCustomerLaneGroups = createSelector(
  getLaneManagementState,
  (state: LaneManagementState) => state.loadingCustomerLaneGroups
);

export const getSelectedLoadCustomerLaneGroup = createSelector(
  getLaneManagementState,
  (state: LaneManagementState) => state.selectedCustomerLaneGroup
);
export const getLoadingSelectedLoadCustomerLaneGroup = createSelector(
  getLaneManagementState,
  (state: LaneManagementState) => state.loadingSelectedCustomerLaneGroup
);

export const getSavingLoadCustomerLaneGroup = createSelector(
  getLaneManagementState,
  (state: LaneManagementState) => state.savingCustomerLaneGroup
);
export const getSaveLoadCustomerLaneGroupSucceeded = createSelector(
  getLaneManagementState,
  (state: LaneManagementState) => state.saveCustomerLaneGroupSucceeded
);

export const getSaveLoadCustomerLaneGroupProblemDetails = createSelector(
  getLaneManagementState,
  (state: LaneManagementState) => state.saveCustomerLaneGroupProblemDetails
);

export const getLoadCustomerLaneGroupTypes = createSelector(
  getLaneManagementState,
  (state: LaneManagementState) => state.loadCarrierGroupTypes
);
export const getLoadCustomerLaneGroupTypesLoading = createSelector(
  getLaneManagementState,
  (state: LaneManagementState) => state.loadCarrierGroupTypesLoading
);
export const getCustomerLaneGroupFirstRecord = createSelector(getLaneManagementState, (state: LaneManagementState) => state.first);

export const getCustomerLaneGroupSearchCrit = createSelector(getLaneManagementState, (state: LaneManagementState) => state.searchCrit);
