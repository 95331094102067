import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'messageDatePipe',
})
export class MessageDatePipe implements PipeTransform {
  transform(value: string | Date, showTime?: boolean): string {
    if (!showTime) {
      showTime = false;
    }

    if (value === '0') {
      return '';
    }
    const _value = Number(value);
    const dif = Math.floor((Date.now() - _value) / 1000 / 86400);

    const inputM = moment(value);
    const yesterday = moment().add(-1, 'day').startOf('day');

    if (inputM > yesterday && !showTime) {
      return this.convertToNiceDate(value);
    } else {
      const datePipe = new DatePipe('en-US');
      if (showTime) {
        if (dif < 1) {
          value = datePipe.transform(value, 'h:mm a');
        } else {
          value = datePipe.transform(value, 'M/d h:mm a');
        }
      } else {
        value = datePipe.transform(value, 'shortDate');
      }
      return value;
    }
  }
  convertToNiceDate(time: string | Date): string {
    const input = moment(time);

    // if (!input.isUTC()) {
    //   input = input.utc();
    // }

    const datePipe = new DatePipe('en-US');
    const date = new Date(time);
    const diff = (new Date().getTime() - date.getTime()) / 1000;

    const daydiff = Math.floor(diff / 86400);

    if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31) {
      return '';
    }

    const today = moment().endOf('day');

    if (today.diff(input, 'days') === 0) {
      return 'Today';
    } else if (today.diff(input, 'days') === 1) {
      return 'Yesterday';
    }

    return datePipe.transform(time, 'shortDate');
  }
}
