import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadAudit } from 'src/app/shared/models/load-audit';
import { RateBreakDownData } from 'src/app/shared/models/rate-break-down-data';
import { PageableQueryHelper } from 'src/app/shared/utilities';
import { environment } from '../../../environments/environment';
import {
  BookLoadRequest,
  CarrierDisplayData,
  LoadDetail,
  LoadView,
  PageableResult,
  SaveVisibilityDataResponse,
  ScacBookingEligibleRequest,
  ScacBookingEligibleResponse,
  SearchTypeData,
  ServiceResponse,
  UpdateVisibilityRequest,
  VisibilityBadge,
} from '../../shared/models';
import { mapResponse } from '../../shared/operators/map-response';
import { LoadboardAdminSearchOptions } from '../components/models';

@Injectable()
export class LoadBoardService {
  constructor(private http: HttpClient) {}

  getDashboardLoadsByUser(adminSearchOptions: LoadboardAdminSearchOptions): Observable<LoadView[]> {
    // Marketplace is not using server side paging
    return this.getLoadsBySearchTypeAndUser(SearchTypeData.UserLanes, null, adminSearchOptions).pipe(map((x) => x.data));
  }

  getLoadsBySearchTypeAndUser(
    searchType: SearchTypeData,
    queryHelper: PageableQueryHelper,
    adminSearchOptions: LoadboardAdminSearchOptions = null
  ): Observable<PageableResult<LoadView>> {
    let query = '?';
    if (queryHelper) {
      query = queryHelper.generateQuery() + '&';
    }
    if (adminSearchOptions && adminSearchOptions.showAllLoads) {
      query += 'showAllLoads=true&';
    }

    if (queryHelper && queryHelper.filter) {
      return this.http
        .post<ServiceResponse<PageableResult<LoadView>>>(
          environment.apiUrl + `/api/Loads${query}searchType=${searchType}`,
          queryHelper.filter
        )
        .pipe(
          mapResponse(),
          map((x) => {
            this.populateDisplays(x.data);
            return x;
          })
        );
    }
    return this.http.get<ServiceResponse<PageableResult<LoadView>>>(environment.apiUrl + `/api/Loads${query}searchType=${searchType}`).pipe(
      mapResponse(),
      map((x) => {
        this.populateDisplays(x.data);
        return x;
      })
    );
  }

  getBookedLoadsByUser(queryHelper: PageableQueryHelper): Observable<PageableResult<LoadView>> {
    return this.getLoadsBySearchTypeAndUser(SearchTypeData.Booked, queryHelper);
  }

  getDeliveredLoadsByUser(queryHelper: PageableQueryHelper): Observable<PageableResult<LoadView>> {
    return this.getLoadsBySearchTypeAndUser(SearchTypeData.Delivered, queryHelper);
  }

  getActiveLoads(queryHelper: PageableQueryHelper): Observable<PageableResult<LoadView>> {
    return this.getLoadsBySearchTypeAndUser(SearchTypeData.Active, queryHelper);
  }

  saveVisibilityData(updateVisibilityRequest: UpdateVisibilityRequest): Observable<SaveVisibilityDataResponse> {
    return this.http
      .put<ServiceResponse<SaveVisibilityDataResponse>>(environment.apiUrl + '/api/Loads/visibilitydata', updateVisibilityRequest)
      .pipe(mapResponse());
  }

  getLoadById(id: string, scac: string, isShowAllContext: boolean): Observable<LoadDetail> {
    let queryString = '';
    if (isShowAllContext || scac) {
      const searchParams = new URLSearchParams();
      if (isShowAllContext) {
        searchParams.append('isShowAllContext', String(isShowAllContext));
      }
      if (scac) {
        searchParams.append('scac', scac);
      }
      const params = searchParams.toString();
      if (params !== null && params !== '') {
        queryString = `?${params}`;
      }
    }

    return this.http.get<ServiceResponse<LoadDetail>>(environment.apiUrl + '/api/Loads/' + id + queryString).pipe(
      mapResponse(),
      map((x) => this.updateDetailDisplays(x))
    );
  }

  getRateBreakDowns(loadId: string, isShowAllContext: boolean): Observable<RateBreakDownData[]> {
    return this.http
      .get<ServiceResponse<RateBreakDownData[]>>(
        environment.apiUrl + '/api/Loads/GetRateBreakDowns/' + loadId + '?isShowAllContext=' + isShowAllContext
      )
      .pipe(mapResponse());
  }

  auditLoad(loadAudit: LoadAudit): Observable<number> {
    const payload = {
      adjustedRate: loadAudit.adjustedRate,
    };
    return this.http
      .post<ServiceResponse<number>>(
        environment.apiUrl + '/api/Loads/' + loadAudit.loadId + '/audit/' + loadAudit.auditType.toString(),
        payload
      )
      .pipe(mapResponse());
  }

  bookLoad(request: BookLoadRequest): Observable<LoadDetail> {
    return this.http
      .put<ServiceResponse<LoadDetail>>(environment.apiUrl + `/api/Loads/${request.loadData.loadId}/book`, request)
      .pipe(mapResponse());
  }

  getNumLoadsRequiringVisibilityInfo(): Observable<VisibilityBadge> {
    return this.http
      .get<ServiceResponse<VisibilityBadge>>(environment.apiUrl + '/api/Loads/num-requiring-visibility-info')
      .pipe(mapResponse());
  }

  getLoadCarriers(loadId: string): Observable<CarrierDisplayData[]> {
    return this.http.get<ServiceResponse<CarrierDisplayData[]>>(environment.apiUrl + `/api/Loads/${loadId}/carriers`).pipe(mapResponse());
  }
  getIsScacEligibleToBookLoad(request: ScacBookingEligibleRequest): Observable<ScacBookingEligibleResponse> {
    return this.http
      .post<ServiceResponse<ScacBookingEligibleResponse>>(
        environment.apiUrl + `/api/Loads/${request.loadId}/carriers/${request.scac}/tender-eligible`,
        request
      )
      .pipe(mapResponse());
  }

  private populateDisplays(loads: LoadView[]) {
    return loads.map((load) => {
      load.distanceFrom = this.calculateDistanceFrom(load.distanceFromOrig, load.distanceFromDest);
      load.originDisplay = `${load.originCity}, ${load.originState}`;
      load.destinationDisplay = `${load.destCity}, ${load.destState}`;
      // <!-- TODO --Remove once testing is done -->
      // load.totalRateDisplay = load.lineHaulRate + load.fuelRate;
      return load;
    });
  }

  private calculateDistanceFrom(dist1: number, dist2: number) {
    if (!dist1 && !dist2) {
      return null;
    }
    return (dist1 ? dist1 : 0) + (dist2 ? dist2 : 0);
  }

  private updateDetailDisplays(load: LoadDetail) {
    load.loadStops.sort((a, b) => {
      if (a.stopNbr < b.stopNbr) {
        return -1;
      }
      return 1;
    });
    return load;
  }
}
