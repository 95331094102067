export enum AppointmentSchedulerConfirmationTypes {
  Carrier_ConfRequired = 1,
  CSR_ConfRequired,
  CSR_NoConf,
  KBXPlanner_ConfRequired,
  KBXPlanner_NoConf,
  NoAppt_ConfRequired,
  NoAppt_FirstComeFirstServe,
  SourceSystem_ConfRequired,
  SourceSystem_NoConf,
}
