import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CanDeactivateGuard } from '../../../../core/guards/can-deactivate.guard';
import { BaseComponent } from '../../../../shared/components';
import { BulkOperationData, ShipperAccessorialData, User, ValidationProblemDetails } from '../../../../shared/models';
import { getUserProfileEntity, UserState } from '../../../../user/store';
import {
  AdminState,
  getLoadingShipperAccessorials,
  getSaveShipperAccessorialProblemDetails,
  getShipperAccessorials,
  ShipperAccessorialBulkOperationAction,
  ShipperAccessorialLoadAction,
} from '../../../store';

@Component({
  selector: 'kbxl-shipper-accessorial-container',
  templateUrl: './shipper-accessorial-container.component.html',
  styleUrls: ['./shipper-accessorial-container.component.scss'],
})
export class ShipperAccessorialContainerComponent extends BaseComponent implements OnInit, CanDeactivateGuard {
  loading$: Observable<boolean>;
  globalAccessorials$: Observable<ShipperAccessorialData[]>;
  saveErrors$: Observable<ValidationProblemDetails>;

  formDirty = false;

  user: User;
  constructor(
    private adminStore: Store<AdminState>,
    private userStore: Store<UserState>,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.loading$ = this.adminStore.pipe(select(getLoadingShipperAccessorials));
    this.globalAccessorials$ = this.adminStore.pipe(
      select(getShipperAccessorials),
      map((x) => this.deepClone(x))
    );
    this.saveErrors$ = this.adminStore.pipe(select(getSaveShipperAccessorialProblemDetails));

    // load global accessorials
    this.adminStore.dispatch(new ShipperAccessorialLoadAction(null, true));

    this.userStore.pipe(select(getUserProfileEntity), takeUntil(this.destroyed$)).subscribe((user) => {
      if (user && user.primaryCustomerId) {
        this.user = user;
        this.adminStore.dispatch(new ShipperAccessorialLoadAction(user.primaryCustomerId));
      }
    });
  }

  saveAccessorials(accessorials: BulkOperationData<ShipperAccessorialData>[]): void {
    if (this.user) {
      // ensure all accessorials have the correct customer id on them
      accessorials.forEach((x) => (x.data.customerId = this.user.primaryCustomerId));

      // global accessorials cannot be deleted or added, only updated
      this.adminStore.dispatch(new ShipperAccessorialBulkOperationAction(accessorials, this.user.primaryCustomerId));
    }
  }
  displayDetail(accessorial: ShipperAccessorialData) {
    const path: [any] = ['maint/accessorials/detail'];
    if (accessorial && accessorial.customerAccessorialId > 0) {
      path.push(accessorial.customerAccessorialId);
    }
    if (this.user) {
      this.router.navigate(path, {
        queryParams: { customerId: this.user.primaryCustomerId },
      });
    } else {
      this.router.navigate(path);
    }
  }
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.formDirty) {
      return true;
    }

    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        message: 'Changes will not be saved.  Are you sure you want to proceed?',
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        },
      });
    });
  }
}
