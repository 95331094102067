import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  defaultSmartSpotPrice,
  SmartSpotPrice,
  SmartSpotPriceRequest,
  SmartSpotQuoteCreateRequest,
  SpotPriceQuoteResponse,
  ValidationProblemDetails,
} from '../../../shared/models';
import { createReducer } from '../../../shared/utilities';
import { SmartSpotPriceActions, SmartSpotPriceActionTypes } from '../actions';

export interface SmartSpotPriceState extends EntityState<SmartSpotPrice> {
  quickQuoteValue: number;
  loadingQuote: boolean;
  quickQuoteProblemDetails: ValidationProblemDetails;
  createOrderQuoteDetails: SmartSpotQuoteCreateRequest;
}

export const adapter: EntityAdapter<SmartSpotPrice> = createEntityAdapter<SmartSpotPrice>({
  selectId: (x) => x.loadId,
});

const initialState: SmartSpotPriceState = adapter.getInitialState({
  quickQuoteValue: null,
  loadingQuote: false,
  quickQuoteProblemDetails: null,
  createOrderQuoteDetails: null,
});

const _smartSpotPriceReducer = createReducer(
  initialState,
  function (state: SmartSpotPriceState = initialState, action: SmartSpotPriceActions): SmartSpotPriceState {
    switch (action.type) {
      case SmartSpotPriceActionTypes.Load: {
        const loadingSmartSpots = action.payload.map<SmartSpotPrice>((x) => ({
          ...defaultSmartSpotPrice,
          loadId: x.loadId,
          loading: true,
        }));

        return adapter.upsertMany(loadingSmartSpots, { ...state });
      }

      case SmartSpotPriceActionTypes.LoadSuccess: {
        const defaultNewState = { ...state, loading: false };
        if (action && action.payload && Array.isArray(action.payload)) {
          // Only update positive prices, otherwise, consider them invalid and do not save them
          // If we don't save non 0 rates then the loading indicator will spin forever
          // const nonZeroPrices = action.payload.filter((x) => x && x.price && x.price > 0.0);
          const rates = action.payload;
          if (rates && rates.length) {
            const newState = adapter.upsertMany(
              rates.map((x) => ({ ...x, loading: false })),
              { ...defaultNewState }
            );
            return newState;
          } else {
            return defaultNewState;
          }
        } else {
          return defaultNewState;
        }
      }
      case SmartSpotPriceActionTypes.LoadFailure: {
        const data = action.payload.data as SmartSpotPriceRequest[];
        const loadingSmartSpots = data.map<SmartSpotPrice>((x) => ({
          ...defaultSmartSpotPrice,
          loadId: x.loadId,
          loading: false,
        }));

        return adapter.upsertMany(loadingSmartSpots, { ...state });
      }
      case SmartSpotPriceActionTypes.LoadQuote: {
        return { ...state, loadingQuote: true, quickQuoteValue: null, quickQuoteProblemDetails: null };
      }
      case SmartSpotPriceActionTypes.LoadQuoteSuccess: {
        return { ...state, loadingQuote: false, quickQuoteValue: action.payload, quickQuoteProblemDetails: null };
      }
      case SmartSpotPriceActionTypes.LoadQuoteFailure: {
        return { ...state, loadingQuote: false, quickQuoteProblemDetails: action.payload.error };
      }
      case SmartSpotPriceActionTypes.CreateOrderFromQuote: {
        return { ...state, createOrderQuoteDetails: action.payload };
      }
      case SmartSpotPriceActionTypes.ClearCreateOrderFromQuote: {
        return { ...state, createOrderQuoteDetails: null };
      }
      case SmartSpotPriceActionTypes.Reset: {
        return adapter.removeAll({ ...state, loadingQuote: false });
      }
      case SmartSpotPriceActionTypes.LoadSpotQuote: {
        const loadingSmartSpots = [
          {
            ...defaultSmartSpotPrice,
            loadId: action.payload.loadId,
            loading: true,
          } as SmartSpotPrice,
        ];

        return adapter.upsertMany(loadingSmartSpots, { ...state });
      }
      case SmartSpotPriceActionTypes.LoadSpotQuoteSuccess: {
        const defaultNewState = { ...state, loading: false };
        const smarSpotPrice = [
          {
            loadId: action.loadId,
            spotPriceQuote: action.payload,
            loading: false,
          } as SmartSpotPrice,
        ];
        return adapter.upsertMany(smarSpotPrice, defaultNewState);
      }
      case SmartSpotPriceActionTypes.LoadSpotQuoteFailure: {
        return { ...state, loadingQuote: false };
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function smartSpotPriceReducer(state: SmartSpotPriceState, action: SmartSpotPriceActions): SmartSpotPriceState {
  return _smartSpotPriceReducer(state, action);
}

const selectors = adapter.getSelectors();
export const getEntities = (state: SmartSpotPriceState) => selectors.selectEntities(state);
export const getAllSmartSpots = (state: SmartSpotPriceState) => selectors.selectAll(state);
