import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components';
import { UserModel } from '../../../shared/models';
import { ChatAttributes } from '../../constants';
import { CreateChatEntityData, CreateDirectMessageData, ExtendedConversation } from '../../models';
import { ChatService } from '../../services';
import { ChatState, CloseSelectedConversationAction, JoinDirectMessageAction } from '../../store';

@Component({
  selector: 'kbxl-select-conversation',
  templateUrl: './select-conversation.component.html',
  styleUrls: ['./select-conversation.component.scss'],
})
export class SelectConversationComponent extends BaseComponent implements OnInit {
  @Input() user: UserModel;
  @Input() conversations: ExtendedConversation[] = [];
  userResults: CreateChatEntityData[] = [];
  selectedEntity: CreateChatEntityData;

  searchQuery: Subject<string> = new Subject();
  @Output() selectConversation: EventEmitter<ExtendedConversation> = new EventEmitter<ExtendedConversation>();
  constructor(private chatService: ChatService, private store: Store<ChatState>, private changeRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.searchQuery
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((query) => this.chatService.searchDirectMessageUsers(query))
      )
      .subscribe((results) => {
        this.userResults = results;
        this.changeRef.detectChanges();
      });
  }

  searchUsers(event: any): void {
    if (!event.query || event.query.length < 3) {
      return;
    }
    this.searchQuery.next(event.query);
  }

  createChat(entity: CreateChatEntityData): void {
    if (!entity) {
      // if the user selected the row rather than the button
      entity = this.selectedEntity;
    }

    // check if conversation already exists, if so, send back that, otherwise go query to see
    // what the user has access to
    if (this.conversations && this.conversations.length > 0) {
      const exists = this.conversations.find((x) => {
        // get entity name attribute
        const shipperId = x.attributes[ChatAttributes.dmShipper][0];
        const carrierId = x.attributes[ChatAttributes.dmCarrier][0];
        const shipperUserFullName = x.attributes[ChatAttributes.dmShipperUser][0];
        const carrierUserFullName = x.attributes[ChatAttributes.dmCarrierUser][0];
        if (entity.isShipper && shipperId === entity.entityId && shipperUserFullName === entity.userDisplayName) {
          return true;
        } else if (!entity.isShipper && carrierId === entity.entityId && carrierUserFullName === entity.userDisplayName) {
          return true;
        }
        return false;
      });

      if (exists) {
        this.selectConversation.emit(exists);
        return;
      }
    }

    const dm: CreateDirectMessageData = {
      loadRefDisplay: null, // No load specified since we're searching from the chat screen
      shipperId: (this.user.isShipper) ? this.user.primaryCustomerId : (entity.isShipper) ? entity.entityId : null,
      shipperName: (this.user.isShipper) ? this.user.focusEntity.name : (entity.isShipper) ? entity.entityName : null,
      shipperUserFullName: (this.user.isShipper) ? this.user.name : (entity.isShipper) ? entity.userDisplayName : null,
      carrierScac: (this.user.isCarrier) ? this.user.carrierScac : (!entity.isShipper) ? entity.entityId : null,
      carrierName: (this.user.isCarrier) ? this.user.carrierName : (!entity.isShipper) ? entity.entityName : null,
      carrierUserFullName: (this.user.isCarrier) ? this.user.name : (!entity.isShipper) ? entity.userDisplayName : null,
      members: [
        {
          id: entity.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: entity.userDisplayName
        },
        {
          id: this.user.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.user.name,
        },
      ]
    };

    // close the current conversation if any
    this.store.dispatch(new CloseSelectedConversationAction());
    this.store.dispatch(new JoinDirectMessageAction(dm));

    this.selectConversation.emit(null); // used for mobile to ensure sidebar gets closed
  }

  getLabel(): string {
    if (!this.user) {
      return '';
    } else if (this.user.isCarrier) {
      return 'Search for shippers or users';
    }
    return 'Search for carriers or users';
  }
}
