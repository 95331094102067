import { CarrierProfileEffects } from './carrier-profile.effects';
import { LaneManagementEffects } from './lane-management.effects';
import { ShipperAccessorialEffects } from './shipper-accessorial.effects';
import { ShipperProfileEffects } from './shipper-profile.effects';
import { UserAdminEffects } from './user-admin.effects';
import { UserCommunicationEffects } from './user-communication.effects';

export const effects: any[] = [
  ShipperProfileEffects,
  UserAdminEffects,
  CarrierProfileEffects,
  UserCommunicationEffects,
  ShipperAccessorialEffects,
  LaneManagementEffects,
];

export * from './carrier-profile.effects';
export * from './lane-management.effects';
export * from './shipper-accessorial.effects';
export * from './shipper-profile.effects';
export * from './user-admin.effects';
export * from './user-communication.effects';
