import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { takeUntil } from 'rxjs/operators';
import { LoadBoardDashboardSetShowAllLoadContextAction, LoadBoardState } from 'src/app/load-board/store';
import { TransactionTypeService } from 'src/app/shared/services';
import { counterOfferRequestModalZIndex } from '../../../constants';
import {
  CounterOfferActionItemLoadView,
  CounterOfferRequestStatusEnum,
  defaultCounterOfferActionItemLoadView,
  defaultLinehaulRateData,
  LinehaulRateData,
  TransactionType,
  UserModel,
} from '../../../models';
import { PageableComponent, PageableQueryData } from '../../../utilities';
import { BaseComponent } from '../../base-component';
import { CounterOfferRequestsModalComponent } from '../../counter-offer';

@Component({
  selector: 'kbxl-counter-offer-action-item-grid',
  templateUrl: './counter-offer-action-item-grid.component.html',
  styleUrls: ['./counter-offer-action-item-grid.component.scss'],
  providers: [DialogService],
})
export class CounterOfferActionItemGridComponent extends BaseComponent implements PageableComponent, OnChanges {
  @Input() actionItemLoads: CounterOfferActionItemLoadView[];
  @Input() totalRecords: number;
  @Input() loading: boolean;
  @Input() user: UserModel;
  @Input() lazy = false;
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Output() lazyLoad: EventEmitter<PageableQueryData> = new EventEmitter<PageableQueryData>();
  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();

  first = 0;
  numberOfColumns = 12;
  @ViewChild(Table, { static: true }) table;

  linehaulRateData: LinehaulRateData;
  showLinehaulOverlay = false;
  showLinehaulOverlayEvent: any;

  get isShipper(): boolean {
    if (this.user != null && this.user.isShipper != null) {
      return this.user && this.user.isShipper;
    }
    return false;
  }

  constructor(
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<LoadBoardState>,
    private transactionTypeService: TransactionTypeService,
    private messageService: MessageService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.user && !changes.user.previousValue && changes.user.currentValue) {
        this.initModal();
      }

      if (changes.loads && !this.lazy) {
        this.first = 0;
      }
    }
  }

  initModal() {
    this.route.url.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x.length === 2) {
        const loadId = x[1].toString();
        this.executeAction({
          ...defaultCounterOfferActionItemLoadView,
          loadId: loadId,
        });
      }
    });
  }

  setFirst(first: number) {
    this.first = first;
  }

  onRowSelect(load: CounterOfferActionItemLoadView) {
    if (load.isEligibleToViewDetaislOfLoad) {
      if (this.showAllLoadContext(load)) {
        this.store.dispatch(new LoadBoardDashboardSetShowAllLoadContextAction(true));
      } else {
        this.store.dispatch(new LoadBoardDashboardSetShowAllLoadContextAction(false));
      }
      this.selected.emit(load.loadId);
    } else {
      this.messageService.add({ detail: 'Load is no longer available to view.', severity: 'warn' });
    }
  }

  private isLoadAvailableToView(load: CounterOfferActionItemLoadView): boolean {
    const marketplaceTransaction = this.transactionTypeService.getTransactionTypesForMarketplace();
    if (
      (!marketplaceTransaction.includes(TransactionType[load.latestTranasctionTypeId]) &&
        (load.actionItemStatus === CounterOfferRequestStatusEnum.Expired ||
          load.actionItemStatus === CounterOfferRequestStatusEnum.Denied)) ||
      (load.actionItemStatus === CounterOfferRequestStatusEnum.Expired ||
      load.actionItemStatus === CounterOfferRequestStatusEnum.Denied)
    ) {
      return false;
    }
    return true;
  }

  private showAllLoadContext(load: CounterOfferActionItemLoadView): boolean {
    const marketplaceTransaction = this.transactionTypeService.getTransactionTypesForMarketplace();
    if (marketplaceTransaction.includes(TransactionType[load.latestTranasctionTypeId]) && this.user.isShipper) {
      return true;
    }
    return false;
  }

  onPage($event) {
    if ($event && $event.first && !this.lazy) {
      this.first = $event.first;
    }
  }

  onLazyLoad($event: LazyLoadEvent) {
    const pageSize = $event.rows;
    const pageNumber = $event.first / $event.rows + 1;
    let sortField = $event.sortField;
    const descending = $event.sortOrder === -1;
    this.first = $event.first;

    if (this.first === 0 && !sortField) {
      if (this.user && this.user.isShipper) {
        sortField = 'actionItemStatusValue';
      } else {
        sortField = 'carrierSortOrder';
      }
    }

    this.lazyLoad.emit({ pageSize, pageNumber, filter: null, sortField: sortField, descending: descending });
  }

  getActionItemText(actionItemLoad: CounterOfferActionItemLoadView): string {
    return actionItemLoad.actionItemCount.toString();
  }

  executeAction(actionItemLoad: CounterOfferActionItemLoadView): void {
    const ref = this.dialogService.open(CounterOfferRequestsModalComponent, {
      data: {
        loadId: actionItemLoad.loadId,
        user: this.user,
        fetchLoad: true,
      },
      header: 'View Counter Offers',
      baseZIndex: counterOfferRequestModalZIndex,
      closable: false,
    });

    ref.onClose.pipe(takeUntil(this.destroyed$)).subscribe((x: { refreshGrid: boolean }) => {
      if (this.user && this.user.isShipper) {
        this.router.navigate(['shipping/action-items/counter-offers']);
      } else {
        this.router.navigate(['loads/action-items/counter-offers']);
      }

      // refresh table
      if (x && x.refreshGrid) {
        this.reload.emit(true);
      }
    });
  }

  showLinehaulRateOverlay($event, hoveredRow: CounterOfferActionItemLoadView, useCounterOfferRate: false) {
    const data = {
      ...defaultLinehaulRateData,
      linehaulRate: useCounterOfferRate ? hoveredRow.counterOfferRate : hoveredRow.lineHaulRate,
      fuelRate: hoveredRow.fuelRate,
      loadshopFee: useCounterOfferRate ? hoveredRow.counterOfferLoadshopFee : hoveredRow.loadshopFee,
      usesAllInRates: hoveredRow.usesAllInRates,
      isShipper: this.isShipper,
      isCounterOffer: useCounterOfferRate,
    };

    this.linehaulRateData = data;
    this.showLinehaulOverlayEvent = $event;
    this.showLinehaulOverlay = true;
  }

  hideLinehaulRateOverlay() {
    this.showLinehaulOverlay = false;
    this.showLinehaulOverlayEvent = null;
    this.linehaulRateData = null;
  }
}
