import { NotificationContact } from './notification-contact';

export interface SendLinkRequest {
  contacts: NotificationContact[];
  loadId: string;
  link: string;
  notificationType: NotificationTypes;
}

export enum NotificationTypes {
  ShareDvir = 7,
  ShareBol = 9,
}
