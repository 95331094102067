export interface DropdownOption {
  dropDownOptionTypeId: string;
  value: string;
  display: string;
  ordinal: number;
}

export function isDropDownOption(x: any): x is DropdownOption {
  return (
    typeof x.dropDownOptionTypeId === 'string' &&
    typeof x.value === 'string' &&
    typeof x.display === 'string' &&
    typeof x.ordinal === 'number'
  );
}
export function isDropDownOptionArray(x: any): x is DropdownOption[] {
  return x.every(isDropDownOption);
}
