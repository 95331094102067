import { CarrierAccessorialRequestActionData } from './carrier-accessorial-request-action-data';
import { CarrierAccessorialRequestStatusEnum } from './carrier-accessorial-request-status-enum';
import { LoadAccessorialDetailData } from './load-accessorial-detail-data';
import { LoadDocumentUpload } from './load-document-upload-data';
import { UserContactData } from './user-contact-data';
import { LoadDocumentMetadata } from './load-document-metadata';

export interface CarrierAccessorialRequestData {
  carrierAccessorialRequestId: number;
  loadAccessorialId: number;
  loadClaimId: string;
  stop: number;
  quantity: number;
  ratePerUom: number;
  rate: number;
  status: CarrierAccessorialRequestStatusEnum;
  statusDescription: string;
  apAuthNbr?: string;
  actions: CarrierAccessorialRequestActionData[];
  loadAccessorial: LoadAccessorialDetailData;
  // populated once the request has been saved and is viewed
  requesterUserData?: UserContactData;

  // if there are any documents, they will show up here
  documents?: LoadDocumentMetadata[];

  // ui fields
  actionTaken?: CarrierAccessorialRequestStatusEnum;
  enableApply?: boolean;
  hasError?: boolean;
  documentsToUpload?: LoadDocumentUpload[];
}

export const defaulCarrierAccessorialRequest: CarrierAccessorialRequestData = {
  carrierAccessorialRequestId: 0,
  loadAccessorialId: null,
  loadClaimId: null,
  stop: null,
  quantity: 1,
  ratePerUom: null,
  rate: null,
  actions: [],
  loadAccessorial: null,
  status: null,
  statusDescription: '',
  requesterUserData: null,
  actionTaken: null,
  enableApply: false,
};
