<div *ngIf="!isLoading">
  <p-table [rowHover]="true" [value]="laneHistory" #table styleClass="p-datatable-no-border" autoLayout="true">
    <ng-template pTemplate="header">
      <tr>
        <th [pSortableColumn]="'statusDesc'">
          Status
          <p-sortIcon [field]="'statusDesc'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'statusDtTm'">
          Date
          <p-sortIcon [field]="'statusDtTm'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'totalRate'">
          Total Rate
          <p-sortIcon [field]="'totalRate'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'lineHaulRate'">
          Line Haul
          <p-sortIcon [field]="'lineHaulRate'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'fuelRate'">
          FSC
          <p-sortIcon [field]="'fuelRate'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'scac'">
          SCAC
          <p-sortIcon [field]="'scac'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'carrierName'">
          Carrier
          <p-sortIcon [field]="'carrierName'"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ item.statusDesc }}</td>
        <td>{{ item.statusDtTm | date: 'short' }}</td>
        <td>{{ item.totalRate | currency }}</td>
        <td>{{ item.lineHaulRate | currency }}</td>
        <td>{{ item.fuelRate | currency }}</td>
        <td>{{ item.scac }}</td>
        <td>{{ item.carrierName | titlecase }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div *ngIf="isLoading" style="min-height: 100px;">
  <div class="progress-indicator" >
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
</div>
