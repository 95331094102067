import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SecurityService } from '../../../core/services';
import { CoreState } from '../../../core/store';
import { BaseComponent } from '../../../shared/components';
import { UserModel } from '../../../shared/models';
import { loadshopApplicationReady, LoadshopApplicationResetAction, SharedState } from '../../../shared/store';
import { getUserProfileModel } from '../../store';

@Component({
  selector: 'kbxl-user-impersonation-indicator',
  templateUrl: './user-impersonation-indicator.component.html',
  styleUrls: ['./user-impersonation-indicator.component.scss'],
})
export class UserImpersonationIndicatorComponent extends BaseComponent implements OnInit {
  applicationReady$: Observable<boolean>;
  user: UserModel;
  constructor(
    private sharedStore: Store<SharedState>,
    private coreStore: Store<CoreState>,
    private securityService: SecurityService,
    private router: Router,
    private messageService: MessageService
  ) {
    super();
  }
  ngOnInit() {
    this.applicationReady$ = this.sharedStore.pipe(select(loadshopApplicationReady));
    this.coreStore.pipe(takeUntil(this.destroyed$), select(getUserProfileModel)).subscribe((user) => {
      this.user = user;
    });
  }
  stopImpersonate(): void {
    this.securityService.stopImpersonating().subscribe((result) => {
      if (result) {
        // toast to user, then refresh
        this.messageService.add({
          detail: 'Impersonation session ended, page will refresh momentarily...',
          severity: 'success',
        });

        // Wipe out ngrx store and reload user profile
        this.coreStore.dispatch(new LoadshopApplicationResetAction());

        // navigate to root of site
        this.router.navigate(['/']);
      }
    });
  }
}
