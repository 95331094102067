import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { AuthLoginActionTypes } from '@tms-ng/core';
import { Observable, of, timer } from 'rxjs';
import { catchError, filter, mapTo, switchMap, takeUntil, takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import { CoreState } from '../../../core/store';
import { MenuUpdateBadgeAction } from '../../../core/store/actions';
import { getUserProfileModel } from '../../../user/store/selectors';
import { ActionItemService } from '../../services';
import {
  ActionItemActionTypes,
  ActionItemLoadCountAction,
  ActionItemLoadCountSuccessAction,
  ActionItemTogglePollingAction,
} from '../actions';

@Injectable()
export class ActionItemEffects {
  @Effect()
  $loadActionItemCount: Observable<Action> = this.actions$.pipe(
    ofType<ActionItemLoadCountAction>(ActionItemActionTypes.LoadCount),
    switchMap(() =>
      this.actionItemService.getActionItemCount().pipe(
        switchMap((data) => {
          let badgeIndicator = null;
          if (data && data.totalCount > 0) {
            badgeIndicator = data.totalCount.toString();
          }

          return [new ActionItemLoadCountSuccessAction(data), new MenuUpdateBadgeAction('Action Items', badgeIndicator)];
        }),
        // stop polling if we encounter an error
        catchError((err) => of(new ActionItemTogglePollingAction(false)))
      )
    )
  );

  @Effect()
  $polling: Observable<Action> = this.actions$.pipe(
    withLatestFrom(this.coreStore$.pipe(select(getUserProfileModel))),
    filter(([_, user]) => this.isPollingEnabled && !this.isPolling && user && (user.isCarrier || user.isShipper)),
    switchMap(() => {
      this.isPolling = true;
      return timer(0, 60 * 1000).pipe(
        takeUntil(this.actions$.pipe(ofType(AuthLoginActionTypes.Logout))),
        takeWhile((x) => this.isPollingEnabled),
        mapTo(new ActionItemLoadCountAction())
      );
    })
  );
  @Effect({ dispatch: false })
  $togglePolling: Observable<Action> = this.actions$.pipe(
    ofType<ActionItemTogglePollingAction>(ActionItemActionTypes.TogglePolling),
    tap((action: ActionItemTogglePollingAction) => {
      this.isPollingEnabled = action.enablePolling;
      if (!this.isPollingEnabled) {
        this.isPolling = false;
      }
    })
  );

  isPollingEnabled = false;
  isPolling = false;

  constructor(private actions$: Actions, private actionItemService: ActionItemService, private coreStore$: Store<CoreState>) {}
}
