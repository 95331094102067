import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { NavigationGoAction, TmsCoreState } from '@tms-ng/core';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  ApplicationService,
  Conversation,
  ConversationQuery,
  ConversationService,
  ConversationStatus,
  Message,
  MostRecentMessagesRequest,
  MostRecentMessagesResponse,
} from '../../../conversation-api';
import { UserModel } from '../../../shared/models';
import { LoadshopApplicationActionTypes, LoadshopApplicationResetAction } from '../../../shared/store';
import {
  AllMyAuthorizedEntitiesLoadSuccess,
  getUserProfileModel,
  UpdateFocusEntityAction,
  UserFocusEntitySelectorTypes,
  UserProfileActionTypes,
  UserProfileUpdateSuccessAction,
  UserState,
} from '../../../user/store';
import { ChatAttributes, ChatConstants } from '../../constants';
import {
  ChatConversationNotification,
  ChatNotification,
  ExtendedConversation,
  ExtendedMessage,
  MessageNotification,
  ReadReceipt,
  UserConversationEntity,
} from '../../models';
import { ConversationHubService } from '../../services';
import {
  AddMessageToConversationAction,
  AddMessageToConversationFailAction,
  AddMessageToConversationSuccessAction,
  CloseSelectedConversationAction,
  CloseSelectedConversationSuccessAction,
  ConversationActionTypes,
  ConversationHubActionTypes,
  ConversationHubReadyAction,
  ConversationsLoadAction,
  ConversationsLoadFailureAction,
  ConversationsLoadSuccessAction,
  ConversationsSearchAction,
  ConversationsSearchFailureAction,
  ConversationsSearchSuccessAction,
  DismissNotificationsAction,
  DismissNotificationsFailureAction,
  DismissNotificationsSuccessAction,
  GetMessagesForConversationAction,
  GetMessagesForConversationFailAction,
  GetMessagesForConversationSuccessAction,
  GetOlderMessagesForConversationAction,
  GetOlderMessagesForConversationFailAction,
  GetOlderMessagesForConversationSuccessAction,
  JoinDirectMessageAction,
  JoinDirectMessageFailureAction,
  JoinDirectMessageSuccessAction,
  LoadConversationNotificationsAction,
  LoadConversationNotificationsFailureAction,
  LoadConversationNotificationsSuccessAction,
  LoadServiceHubApplicationSuccessAction,
  ReadReceiptMessageOnConversationAction,
  ReadReceiptMessageOnConversationFailAction,
  ReadReceiptMessageOnConversationSuccessAction,
  ResetConversationsAction,
  SelectConversationAction,
  SelectConversationSuccessAction,
  UpdateNotificationCountAction,
  UpdateNotificationCountSuccessAction,
  UpdateUserConversationEntitiesAction,
} from '../actions';
import { ChatState } from '../reducers';
import {
  getNotificationsDisabled,
  getSelectedConversation,
  getSelectedConversationId,
  getServiceHubApplication,
  getUserContextNotifications,
  getUserConversationEntities,
} from '../selectors';

@Injectable()
export class ConversationEffects {
  /*
   ** Reset the chat store when the user switches contexts
   */
  @Effect()
  $resetChat: Observable<Action> = this.actions$.pipe(
    ofType<UpdateFocusEntityAction>(UserFocusEntitySelectorTypes.UpdateFocusEntity),
    withLatestFrom(this.store$.pipe(select(getUserConversationEntities))),
    withLatestFrom(this.store$.pipe(select(getSelectedConversation))),
    switchMap(([[_, currentUserChatEntities], selectedConversation]) => {
      // remove from group subscriptions
      this.conversationHubService.updateGroupSubscriptions([], currentUserChatEntities);
      if (selectedConversation && selectedConversation.id) {
        this.conversationHubService.closeConversation(selectedConversation.id);
      }

      // wipe the browser conversation state
      return of(new ResetConversationsAction());
    })
  );

  /*
   ** Registers the user for notifications
   */
  @Effect()
  $registerNotificationsForUserProfile: Observable<Action> = this.actions$.pipe(
    ofType<AllMyAuthorizedEntitiesLoadSuccess | LoadServiceHubApplicationSuccessAction | UserProfileUpdateSuccessAction>(
      UserFocusEntitySelectorTypes.LoadMyAuthorizedEntities_Success,
      ConversationActionTypes.LoadServiceHubApplication_Success,
      UserProfileActionTypes.Update_Success
    ),
    withLatestFrom(this.userStore$.pipe(select(getUserProfileModel))),
    withLatestFrom(this.store$.pipe(select(getUserConversationEntities))),
    withLatestFrom(this.store$.pipe(select(getServiceHubApplication))),
    switchMap(([[[_, userProfile], currentUserChatEntities], serviceHubApplication]) => {
      // we need the service hub application before processing
      if (!serviceHubApplication) {
        return EMPTY;
      }

      // register user for direct message notifications
      const chatEntities: UserConversationEntity[] = [
        {
          isShipper: false,
          isDirectMessage: true,
          entityId: userProfile.identUserId,
          entityName: userProfile.name,
        },
      ];

      // update group subscriptions in signalr
      this.conversationHubService.updateGroupSubscriptions(chatEntities, currentUserChatEntities);

      const dmAttrs = this.getDMAttributes(userProfile);
      const dmQuery: ConversationQuery = {
        userId: userProfile.identUserId,
        applicationId: serviceHubApplication.id,
        attributes: dmAttrs,
        startDate: moment().add(-7, 'days').toDate(),
        options: {
          includeLatestMessage: true,
        },
      };

      const dmNotificationQuery: ConversationQuery = {
        applicationId: serviceHubApplication.id,
        attributes: this.getDMNotificationAttributes(userProfile),
        userId: userProfile.identUserId,
      };

      return [
        new UpdateUserConversationEntitiesAction(chatEntities), // save user entities for notifications
        new ConversationsLoadAction([dmQuery]), // fetch DMs for this user at this entity
        new LoadConversationNotificationsAction([dmNotificationQuery]), // get DM notifications
      ];
    })
  );

  /*
   ** Removes all signalr hub connections on reset action (impersonation)
   */
  @Effect()
  $removeAllHubListenersOnReset: Observable<Action> = this.actions$.pipe(
    ofType<LoadshopApplicationResetAction>(LoadshopApplicationActionTypes.LoadshopReset),
    withLatestFrom(this.store$.pipe(select(getUserConversationEntities))),
    switchMap(([_, userEntities]) => {
      // update group subscriptions
      this.conversationHubService.updateGroupSubscriptions([], userEntities);
      return of(new UpdateUserConversationEntitiesAction([]));
    })
  );

  /*
   ** Rejoins all groups when the connection is dropped and readded
   */
  @Effect()
  $rejoinAllGroupsOnReconnect: Observable<Action> = this.actions$.pipe(
    ofType<ConversationHubReadyAction>(ConversationHubActionTypes.Ready),
    filter((action) => action.wasConnectionDropped),
    withLatestFrom(this.store$.pipe(select(getUserConversationEntities))),
    switchMap(([_, userEntities]) => {
      if (!_.wasConnectionDropped) {
        // connection wasn't dropped / wasn't a rejoin, so don't rejoin groups
        return EMPTY;
      }
      // rejoin group subscriptions with new connection ID
      this.conversationHubService.updateGroupSubscriptions(userEntities, []);
      return EMPTY;
    })
  );

  /*
   ** Loads all the conversations and will attempt to select a conversation if one is passed in
   */
  @Effect()
  $loadConversations: Observable<Action> = this.actions$.pipe(
    ofType<ConversationsLoadAction>(ConversationActionTypes.Load),
    withLatestFrom(this.store$.pipe(select(getSelectedConversationId))),
    switchMap(([action, selectedConversationId]) => {
      return this.serviceHubConversationApi.v2ConversationSearchManyPost(action.payload).pipe(
        switchMap((data) => {
          const conversations = this.reduceConversations(data);
          if (selectedConversationId) {
            const exists = conversations.find((x) => x.id === selectedConversationId);
            if (!exists) {
              // conversation doesn't exists, go back to the main chat menu
              return [new ConversationsLoadSuccessAction(conversations), new NavigationGoAction({ path: ['/chat/'] })];
            } else {
              return [new ConversationsLoadSuccessAction(conversations)];
            }
          }
          return [new ConversationsLoadSuccessAction(conversations)];
        }),
        catchError((err) => of(new ConversationsLoadFailureAction(err)))
      );
    })
  );

  /*
   ** Searches for a specific conversation given the query
   */
  @Effect()
  $searchConversations: Observable<Action> = this.actions$.pipe(
    ofType<ConversationsSearchAction>(ConversationActionTypes.Search),
    switchMap((action) =>
      this.serviceHubConversationApi.v2ConversationSearchPost(action.payload).pipe(
        map((data: Conversation[]) => {
          const a = this.reduceConversations(data, action.addRecentlyJoined);
          return new ConversationsSearchSuccessAction(a);
        }),
        catchError((err) => of(new ConversationsSearchFailureAction(err)))
      )
    )
  );

  /*
   ** Loads the init state of notifications for the user.  After this all notifications will be received from signalr
   */
  @Effect()
  $loadConversationNotifications: Observable<Action> = this.actions$.pipe(
    ofType<LoadConversationNotificationsAction>(ConversationActionTypes.LoadNotifications),
    switchMap((action) =>
      this.serviceHubConversationApi.v2ConversationSearchNotificationsManyPost(action.payload).pipe(
        map((data: MessageNotification[]) => new LoadConversationNotificationsSuccessAction(data)),
        catchError((err) => of(new LoadConversationNotificationsFailureAction(err)))
      )
    )
  );

  /*
   ** Adds a new message from the USER to the conversation, this will put the message in a pending state
   ** until that message is received from signalr and has a messageId assigned to it
   */
  @Effect()
  $addMessageToConversation: Observable<Action> = this.actions$.pipe(
    ofType<AddMessageToConversationAction>(ConversationActionTypes.AddMessage),
    switchMap((action) =>
      this.conversationHubService.addMessageToConversation(action.message).pipe(
        map((message) => new AddMessageToConversationSuccessAction(message)),
        catchError((err) => of(new AddMessageToConversationFailAction(err)))
      )
    )
  );

  /*
   ** Fetches messages for a conversation when a conversation is selected
   */
  @Effect()
  $getConversationMessages: Observable<Action> = this.actions$.pipe(
    ofType<GetMessagesForConversationAction>(ConversationActionTypes.GetMessages),
    withLatestFrom(this.userStore$.pipe(select(getUserProfileModel))),
    switchMap(([action, user]) => {
      // ensure the userId is populated on the request
      const data: MostRecentMessagesRequest = { ...action.payload, userId: user.identUserId };
      return this.serviceHubConversationApi.v2ConversationMostRecentPost(data).pipe(
        map(
          (response: MostRecentMessagesResponse) =>
            new GetMessagesForConversationSuccessAction(
              action.payload.conversationId,
              this.reduceMessages(response.messages),
              response.hasOlderMessages
            )
        ),
        catchError((err) => of(new GetMessagesForConversationFailAction(err)))
      );
    })
  );

  /*
   ** Fetches older messages for a conversation when a user wants to get older messages
   */
  @Effect()
  $getConversationOlderMessages: Observable<Action> = this.actions$.pipe(
    ofType<GetOlderMessagesForConversationAction>(ConversationActionTypes.GetOlderMessages),
    switchMap((action) =>
      this.serviceHubConversationApi.v2ConversationGetOldMessagesPost(action.payload).pipe(
        map((response: Message[]) => {
          // check if the response has more messages since we don't get a flag back
          const hasMoreMessages = response && response.length > 0 && response.length >= action.payload.nbrMessages - 1;

          return new GetOlderMessagesForConversationSuccessAction(
            action.payload.conversationId,
            this.reduceMessages(response),
            hasMoreMessages
          );
        }),
        catchError((err) => of(new GetOlderMessagesForConversationFailAction(err)))
      )
    )
  );

  /*
   * Selects a conversation, navigates to that page, and fetches the messages
   */
  @Effect()
  $selectConversation: Observable<Action> = this.actions$.pipe(
    ofType<SelectConversationAction>(ConversationActionTypes.Select_Conversation),
    withLatestFrom(this.store$.pipe(select(getSelectedConversationId))),
    switchMap(([action, selectedConversation]) => {
      if (selectedConversation === action.conversationId) {
        // if the conversation is selected, we shouldn't need to navigate to the page and fetch messages
        return EMPTY;
      }

      // enroll in signalr
      this.conversationHubService.openConversation(action.conversationId);
      // ensure we have the latest messages when the conversation is selected
      const request: MostRecentMessagesRequest = {
        conversationId: action.conversationId,
        limit: ChatConstants.nbrOfMessagesToRetrieve,
      };

      const results = [];

      results.push(new GetMessagesForConversationAction(request));
      results.push(new SelectConversationSuccessAction(action.conversationId));

      if (action.filterQuery && action.filterQuery.length > 0) {
        const query = { q: action.filterQuery, a: action.attachToMessage };
        results.push(new NavigationGoAction({ path: [`/chat/${action.conversationId}`], query: query }));
      } else {
        results.push(new NavigationGoAction({ path: [`/chat/${action.conversationId}`] }));
      }
      return results;
    })
  );

  /*
   ** Removes the selected conversation, navigates to chat page
   */
  @Effect()
  $closeSelectConversation: Observable<Action> = this.actions$.pipe(
    ofType<CloseSelectedConversationAction>(ConversationActionTypes.Close_Selected_Conversation),
    withLatestFrom(this.tmsCoreState$.pipe(select(getSelectedConversationId))),
    switchMap(([action, selectedConversationId]) => {
      if (selectedConversationId) {
        // stop signalr messages
        this.conversationHubService.closeConversation(selectedConversationId);
      }

      if (action.navigateToChat) {
        return [new NavigationGoAction({ path: ['/chat/'] }), new CloseSelectedConversationSuccessAction()];
      }
      return [new CloseSelectedConversationSuccessAction()];
    })
  );

  /*
   * Updates the notifications based on the incoming message and the current count based on entity / conversationId
   * If the user gets an incoming message on the current conversation, it will not increase the notification count
   * If a user gets a message on another context it will show in the notifications and the other context notifications
   */
  @Effect()
  $updateNotificationCount: Observable<Action> = this.actions$.pipe(
    ofType<UpdateNotificationCountAction | LoadConversationNotificationsSuccessAction>(
      ConversationActionTypes.UpdateNotificationCount,
      ConversationActionTypes.LoadNotifications_Success
    ),
    withLatestFrom(this.userStore$.pipe(select(getUserProfileModel))),
    withLatestFrom(this.store$.pipe(select(getSelectedConversationId))),
    withLatestFrom(this.store$.pipe(select(getUserContextNotifications))),
    withLatestFrom(this.store$.pipe(select(getUserConversationEntities))),
    withLatestFrom(this.store$.pipe(select(getServiceHubApplication))),
    withLatestFrom(this.store$.pipe(select(getNotificationsDisabled))),

    switchMap(
      ([
        [[[[[action, user], selectedConversationId], currentUserContextNotifications], userConvoEntities], serviceHubApplication],
        notificationsDisabled,
      ]) => {
        if (notificationsDisabled || !action.notifications || action.notifications.length === 0) {
          return EMPTY;
        }
        if (!user.primaryCustomerId && !user.carrierScac) {
          return EMPTY;
        }
        // clone the list, otherwise it will be added to the init state obj
        const userContextNotifications = cloneDeep(currentUserContextNotifications);

        let currentNotificationCount = 0;
        // get the user's current entity
        let userEntityId = user.primaryCustomerId;

        if (user.isCarrier) {
          userEntityId = user.carrierScac;
        }
        const resultActions = [];
        const fetchNewConversations = [];
        const notifications = cloneDeep(action.notifications);
        for (let index = 0; index < notifications.length; index++) {
          const notification = notifications[index];
          if (notification.conversationId === selectedConversationId) {
            // notification is for the current selected conversation
            notification.nbrNewMessages = 0;
          }
          // save the notification count for the entity
          const entity = userConvoEntities.find((x) => x.entityId === notification.groupValue);

          if (!entity) {
            console.warn(`User should have entity registered: ${notification.groupValue}`);
          }

          let entityNotification = userContextNotifications.find((x) => x.notificationEntityId === notification.groupValue);
          let existingConversation: ChatConversationNotification;
          // check if we have the conversation id
          if (entityNotification) {
            existingConversation = entityNotification.notificationsByConversation.find(
              (x) => x.conversationId === notification.conversationId
            );
          }

          let dmContextEntityId: string;
          let dmContextEntityName: string;

          // Default to Carrier-scoped notification
          let idAttrName = ChatAttributes.dmCarrier;
          let nameAttrName = ChatAttributes.carrierName;

          // Find if the dmShipperUser or dmCarrierUser matches the current user's fullname
          // and then use that to figure out if the notification is for a shipper or carrier DM scope
          // This finds the current entity ID and name, even when the user is an admin or a special
          // planner type that can switch between shipper and carrier entities.
          const isShipperScoped =
            notification.conversationAttributes[ChatAttributes.dmShipperUser][0].toLocaleUpperCase() === user.name.toLocaleUpperCase();

          if (isShipperScoped) {
            idAttrName = ChatAttributes.dmShipper;
            nameAttrName = ChatAttributes.shipperName;
          }

          if (notification.conversationAttributes) {
            dmContextEntityId = notification.conversationAttributes[idAttrName][0].toLocaleUpperCase();
            dmContextEntityName = notification.conversationAttributes[nameAttrName][0];
          }

          // is the notification for the user's current entity
          if (dmContextEntityId === userEntityId.toLocaleUpperCase()) {
            // incoming notification
            if (!entityNotification || !existingConversation) {
              // this is a new incoming conversation
              fetchNewConversations.push(notification.conversationId);
            } else if (notification.conversationId !== selectedConversationId) {
              // Note that while the signalr connection could go down which might result in a lost message,
              // we are assuming that it doesn't, because that sort of thing just wouldn't happen
              // BUT if we want to query messages every time we get a notification, regardless if we are in the conversation,
              // remove that IF check
              resultActions.push(
                new GetMessagesForConversationAction({
                  userId: user.identUserId,
                  conversationId: notification.conversationId,
                  limit: ChatConstants.nbrOfMessagesToRetrieve,
                })
              );
            }
          }

          if (!entityNotification && !existingConversation && entity) {
            entityNotification = new ChatNotification({
              notificationEntityId: entity.entityId,
              notificationEntityName: entity.entityName,
              contextEntityId: dmContextEntityId,
              contextEntityName: dmContextEntityName,
              notificationsByConversation: [],
            });
            userContextNotifications.push(entityNotification);
          }

          entityNotification.updateNotificationCount(notification.conversationId, notification.nbrNewMessages);
        }

        if (userContextNotifications && userContextNotifications.length > 0) {
          // sum notifications from all entities
          currentNotificationCount = userContextNotifications.map((x) => x.notificationCount).reduce((a, b) => a + b);
        }

        // if we need to fetch new conversations, add them in 1 search query
        if (fetchNewConversations.length > 0) {
          resultActions.push(
            new ConversationsSearchAction(
              {
                conversationIds: fetchNewConversations,
                applicationId: serviceHubApplication.id,
                options: {
                  includeLatestMessage: true,
                },
              },
              true
            )
          );
        }
        resultActions.push(new UpdateNotificationCountSuccessAction(currentNotificationCount, userContextNotifications));

        return resultActions;
      }
    )
  );

  /*
   ** Dismisses notifications for the current selected conversation but marking all as read
   */
  @Effect()
  $dismissNotifications: Observable<Action> = this.actions$.pipe(
    ofType<DismissNotificationsAction>(ConversationActionTypes.DismissNotifications),
    withLatestFrom(this.store$.pipe(select(getUserContextNotifications))),
    withLatestFrom(this.userStore$.pipe(select(getUserProfileModel))),
    switchMap(([[action, userContextNotifications], user]) => {
      const readReceipt: ReadReceipt = {
        messageId: 0, // all messages will get marked as read
        conversationId: action.conversationId,
        applicationCode: this.conversationHubService.applicationCode,
        userId: user.identUserId,
      };

      return this.serviceHubConversationApi.v2ConversationMarkAllAsReadPost(readReceipt).pipe(
        switchMap(() => {
          let currentNotificationCount = 0;
          let userEntityId = user.primaryCustomerId;

          if (user.isCarrier) {
            userEntityId = user.carrierScac;
          }
          // Cannot update readonly store properties, so copy the user context notifications
          const newUserContextNotifications = cloneDeep(userContextNotifications);

          // reset direct message notifications
          const dmEntity = newUserContextNotifications.find(
            (x) => x.notificationEntityId.toLocaleLowerCase() === user.identUserId.toLocaleLowerCase()
          );
          if (dmEntity) {
            const conversationNotification = dmEntity.notificationsByConversation.find((x) => x.conversationId === action.conversationId);
            conversationNotification.count = 0;
          }

          if (newUserContextNotifications && newUserContextNotifications.length > 0) {
            // sum notifications from all entities
            currentNotificationCount = newUserContextNotifications.map((x) => x.notificationCount).reduce((a, b) => a + b);
          }
          return [new DismissNotificationsSuccessAction(currentNotificationCount, newUserContextNotifications)];
        }),
        catchError((err) => of(new DismissNotificationsFailureAction(err)))
      );
    })
  );

  /*
   ** Creates a new DM by calling service hub and then will join (select) that conversation
   */
  @Effect()
  $joinNewDM: Observable<Action> = this.actions$.pipe(
    ofType<JoinDirectMessageAction>(ConversationActionTypes.JoinNewDM),
    withLatestFrom(this.store$.pipe(select(getServiceHubApplication))),
    switchMap(([action, serviceHubApplication]) => {
      if (!serviceHubApplication) {
        throw new Error('Unable to create / join new direct message because application is null');
      }

      const dmAttrs = action.payload;
      const members = dmAttrs.members;

      const convoAttrs = {};
      convoAttrs[ChatAttributes.dmShipper] = [dmAttrs.shipperId];
      convoAttrs[ChatAttributes.shipperName] = [dmAttrs.shipperName];
      convoAttrs[ChatAttributes.dmShipperUser] = [dmAttrs.shipperUserFullName];
      convoAttrs[ChatAttributes.dmCarrier] = [dmAttrs.carrierScac];
      convoAttrs[ChatAttributes.carrierName] = [dmAttrs.carrierName];
      convoAttrs[ChatAttributes.dmCarrierUser] = [dmAttrs.carrierUserFullName];
      convoAttrs[ChatAttributes.dmGroup] = members.map((x) => x.id);

      const data = new ExtendedConversation({
        status: ConversationStatus.NEW,
        applicationId: serviceHubApplication.id,
        applicationCode: serviceHubApplication.applicationCode,
        attributes: convoAttrs,
        members: members.map((x) => ({
          userName: x.displayName, // first + last name
          userId: x.id, // identity server id
        })),
      });

      return this.serviceHubConversationApi.v2ConversationJoinPost([data]).pipe(
        map((conversations: ExtendedConversation[]) => {
          const newConversation = this.reduceConversation(conversations[0]);
          newConversation.recentlyJoined = true;
          return new JoinDirectMessageSuccessAction(this.reduceConversation(newConversation), action.payload.loadRefDisplay);
        }),
        catchError((err) => of(new JoinDirectMessageFailureAction(err)))
      );
    })
  );

  /*
   ** Selects the new conversation after it was created
   */
  @Effect()
  $selectNewlyJoinedConversation: Observable<Action> = this.actions$.pipe(
    ofType<JoinDirectMessageSuccessAction>(ConversationActionTypes.JoinNewDM_Success),
    switchMap((action) => {
      return [new SelectConversationAction(action.payload.id, action.referenceLoadId, action.attachToMessage)];
    })
  );

  /*
   ** Sends a read receipt for a message.
   ** This is currently not used yet as we just dismiss notifications (messages) in the selected conversation
   */
  @Effect()
  $sendReadReceipt: Observable<Action> = this.actions$.pipe(
    ofType<ReadReceiptMessageOnConversationAction>(ConversationActionTypes.ReadReceipt),
    switchMap((action) =>
      this.conversationHubService.readMessageOnConversation(action.payload).pipe(
        map(() => new ReadReceiptMessageOnConversationSuccessAction(action.payload)),
        catchError((err) => of(new ReadReceiptMessageOnConversationFailAction(err)))
      )
    )
  );

  private reduceMessages(messages: Message[]): ExtendedMessage[] {
    const extended = messages.map((m: Message) => new ExtendedMessage({ ...m }));
    return extended;
  }
  private reduceConversations(conversations: Conversation[], recentlyJoined = false): ExtendedConversation[] {
    const extended = conversations.map((c: Conversation) => new ExtendedConversation({ ...c, recentlyJoined: recentlyJoined }));
    return extended;
  }
  private reduceConversation(conversation: Conversation): ExtendedConversation {
    const extended = new ExtendedConversation({ ...conversation });
    return extended;
  }

  private getDMAttributes(userProfile: UserModel): { [key: string]: Array<string> } {
    const attrs = {};

    // only set source attributes if user profile is provided
    if (userProfile) {
      if (userProfile.isShipper) {
        attrs[ChatAttributes.dmShipper] = [userProfile.primaryCustomerId.toLocaleUpperCase()];
        attrs[ChatAttributes.shipperName] = [userProfile.focusEntity.name];
        attrs[ChatAttributes.dmShipperUser] = [userProfile.name];
      } else if (userProfile.isCarrier) {
        attrs[ChatAttributes.dmCarrier] = [userProfile.carrierScac.toLocaleUpperCase()];
        attrs[ChatAttributes.carrierName] = [userProfile.scacName];
        attrs[ChatAttributes.dmCarrierUser] = [userProfile.name];
      }
    }

    attrs[ChatAttributes.dmGroup] = [userProfile.identUserId];

    return attrs;
  }

  private getDMNotificationAttributes(userProfile: UserModel): { [key: string]: Array<string> } {
    const attrs = {};

    // Look for DM notifications across ALL of a user's entities by not including any shipper/carrier
    // attributes.  Only look for conversations that have this user's IdentUserId as a value inside the
    // list of ChatAttributes.dmGroup attribute members.
    attrs[ChatAttributes.dmGroup] = [userProfile.identUserId];

    return attrs;
  }

  constructor(
    private actions$: Actions,
    private store$: Store<ChatState>,
    private userStore$: Store<UserState>,
    private serviceHubConversationApi: ConversationService,
    private serviceHubApplicationService: ApplicationService,
    private conversationHubService: ConversationHubService,
    private tmsCoreState$: Store<TmsCoreState>
  ) {}
}
