<div class="progress-indicator" *ngIf="processing">
  <div class="progressspinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div class="d-flex flex-column p-4">
  <label>Email</label>
  <div class="d-flex flex-column align-items-end">
    <ul class="list-unstyled contact-list">
      <li *ngFor="let contact of emailContacts">
        <div class="row">
          <div class="col-md-5">
            <div class="d-flex align-items-end">
              <div class="pi pi-envelope contact-icon mr-2"></div>
              <div class="flex-grow-1">
                <div class="kbxl-field-label">
                  <label class="control-label">Name</label>
                </div>
                <input type="text" pInputText [(ngModel)]="contact.name" />
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="kbxl-field-label">
              <label class="control-label">Email</label>
            </div>
            <div>
              <input type="email" pInputText [(ngModel)]="contact.to" placeholder="example@example.com" />
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div>
      <button pButton (click)="addEmailContact()" label="Add Email" icon="pi pi-envelope"></button>
    </div>
  </div>
  <label>SMS</label>
  <div class="d-flex flex-column align-items-end">
    <ul class="list-unstyled contact-list">
      <li *ngFor="let contact of smsContacts">
        <div class="row">
          <div class="col-md-5">
            <div class="d-flex align-items-end">
              <div class="pi pi-comment contact-icon mr-2"></div>
              <div class="flex-grow-1">
                <div class="kbxl-field-label">
                  <label class="control-label">Name</label>
                </div>
                <input type="text" pInputText [(ngModel)]="contact.name" />
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="kbxl-field-label">
              <label class="control-label">Phone</label>
            </div>
            <div>
              <p-inputMask mask="(999) 999-9999" [(ngModel)]="contact.to" placeholder="(999) 999-9999"></p-inputMask>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div>
      <button pButton (click)="addSmsContact()" label="Add Phone" icon="pi pi-comment"></button>
    </div>
  </div>
</div>
<div class="d-flex justify-content-end">
  <button pButton type="button" (click)="cancel()" class="p-button-outlined p-button-secondary m-2 ui-button" label="Cancel"></button>
  <button pButton type="button" (click)="send()" class="p-button-teal m-2" label="Send"></button>
</div>
