import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of, timer } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ApplicationService } from '../../../conversation-api';
import { LoadshopApplicationActionTypes, LoadshopApplicationStartAction } from '../../../shared/store';
import { getUserProfileModel, UserState } from '../../../user/store';
import { ConversationHubService } from '../../services';
import {
  ConversationActionTypes,
  ConversationHubActionTypes,
  ConversationHubPingAction,
  ConversationHubPingFailureAction,
  ConversationHubPingSuccessAction,
  ConversationHubReadyAction,
  LoadServiceHubApplicationAction,
  LoadServiceHubApplicationFailAction,
  LoadServiceHubApplicationSuccessAction,
} from '../actions';

@Injectable()
export class ConversationHubEffects {
  timerSeconds = 180;

  /*
   ** Fetch all conversations on startup
   */
  @Effect()
  $initializeSignalROnStartup: Observable<Action> = this.actions$.pipe(
    ofType<LoadshopApplicationStartAction>(LoadshopApplicationActionTypes.LoadshopStart),
    switchMap(() =>
      // initial signalr hub connection
      this.conversationHubService.initialize().pipe(
        map(() => new LoadServiceHubApplicationAction(this.conversationHubService.applicationCode)),
        catchError((err) => of(new LoadServiceHubApplicationFailAction(err)))
      )
    )
  );

  /*
   ** Starts the polling for the app to send a ping to the signalr service to track activity
   */
  @Effect()
  $startPolling: Observable<Action> = this.actions$.pipe(
    ofType<ConversationHubReadyAction>(ConversationHubActionTypes.Ready),
    filter((action) => action.payload),
    switchMap((action) =>
      timer(0, this.timerSeconds * 1000).pipe(
        takeUntil(
          this.actions$.pipe(
            ofType<ConversationHubReadyAction>(ConversationHubActionTypes.Ready),
            filter((x) => !x.payload)
          )
        ),
        mapTo(new ConversationHubPingAction())
      )
    )
  );

  /*
   ** Fetch all conversations on startup
   */
  @Effect()
  $pingHub: Observable<Action> = this.actions$.pipe(
    ofType<ConversationHubPingAction>(ConversationHubActionTypes.Ping),
    withLatestFrom(this.userStore$.pipe(select(getUserProfileModel))),
    switchMap(([action, userProfile]) =>
      // use the identity server id
      this.conversationHubService.logActivityPing(userProfile.identUserId).pipe(
        map(() => new ConversationHubPingSuccessAction()),
        catchError((err) => of(new ConversationHubPingFailureAction(err)))
      )
    )
  );

  /*
   ** Fetch service hub application id which is needed for all queries to service hub
   */
  @Effect()
  $loadServiceHubApplication: Observable<Action> = this.actions$.pipe(
    ofType<LoadServiceHubApplicationAction>(ConversationActionTypes.LoadServiceHubApplication),
    switchMap(() =>
      this.serviceHubApplicationService.v1ApplicationGet(this.conversationHubService.applicationCode).pipe(
        map((data) => new LoadServiceHubApplicationSuccessAction(data)),
        catchError((err) => of(new LoadServiceHubApplicationFailAction(err)))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userStore$: Store<UserState>,
    private serviceHubApplicationService: ApplicationService,
    private conversationHubService: ConversationHubService
  ) {}
}
