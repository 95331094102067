import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef } from '@angular/core';
import { ShareLinkService } from '../../services/share-link.service';
import { NotificationContact } from '../../models';
import { NotificationTypes } from '../../models/send-link-request';

@Component({
  selector: 'kbxl-share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareLinkComponent {
  @Input() link = '';
  @Input() notificationType: NotificationTypes;
  @Input() loadId: string;
  showContactBuilder = false;
  processing = false;

  get linkText(): string {
    let result = '';
    switch (this.notificationType) {
      case NotificationTypes.ShareBol:
        result = 'Link to eBOL';
        break;
      case NotificationTypes.ShareDvir:
        result = 'Link to eDVIR';
        break;
    }

    return result;
  }
  get headerText(): string {
    let result = '';
    switch (this.notificationType) {
      case NotificationTypes.ShareBol:
        result = 'Share BOL Link';
        break;
      case NotificationTypes.ShareDvir:
        result = 'Share DVIR Link';
        break;
    }

    return result;
  }
  get cancelConfirmationMessage(): string {
    let result = '';
    switch (this.notificationType) {
      case NotificationTypes.ShareBol:
        result = 'Are you sure you want to cancel? The BOL Link will not be sent.';
        break;
      case NotificationTypes.ShareDvir:
        result = 'Are you sure you want to cancel? The DVIR Link will not be sent.';
        break;
    }

    return result;
  }
  get sendConfirmationMessage(): string {
    let result = '';
    switch (this.notificationType) {
      case NotificationTypes.ShareBol:
        result = 'Are you sure you want to send the BOL link?';
        break;
      case NotificationTypes.ShareDvir:
        result = 'Are you sure you want to send the DVIR link?';
        break;
    }

    return result;
  }

  constructor(private shareLinkService: ShareLinkService, private changeDetectorRef: ChangeDetectorRef) {}

  shareClick() {
    this.showContactBuilder = true;
  }

  sendLink(contacts: NotificationContact[]) {
    this.processing = true;
    this.shareLinkService
      .sendLinkRequest({
        contacts: contacts,
        loadId: this.loadId,
        link: this.link,
        notificationType: this.notificationType,
      })
      .toPromise()
      .then((_) => {
        if (_) {
          this.showContactBuilder = false;
          this.processing = false;
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  cancelShare() {
    this.showContactBuilder = false;
  }
}
