<div class="d-flex flex-column">
  <div class="alert alert-danger" role="alert" *ngIf="showError">{{ errorMsg }}</div>
  <div class="col-12 kbxl-inputgroup-container">
    <div class="kbxl-field-label">
      <label class="control-label">Search Name</label>
    </div>
    <div class="p-inputgroup">
      <input pInputText type="text" [(ngModel)]="searchName" class="filter-box w-100" placeholder="Search Criteria" maxlength="200" />
    </div>
  </div>
  <div class="ml-3 kbxl-inputgroup-container">
    <div class="kbxl-field-label">
      <label id="filter-loads-default" class="control-label">Default Filter</label>
    </div>
    <div class="p-inputgroup">
      <p-inputSwitch [(ngModel)]="defaultSearch" name="defaultSearch"></p-inputSwitch>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <button pButton type="button" (click)="cancel()" class="p-button-outlined p-button-secondary mr-3 px-3" label="Cancel"></button>
    <button pButton type="button" [disabled]="isSaving && searchName.length < 1" (click)="save()" class="px-3" label="Save"></button>
  </div>
</div>
