import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ServiceResponse, UserAdminData } from '../../shared/models';
import { mapResponse } from '../../shared/operators/map-response';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  constructor(private http: HttpClient) {}

  searchUsersToImpersonate(query: string): Observable<UserAdminData[]> {
    return this.http
      .get<ServiceResponse<UserAdminData[]>>(`${environment.apiUrl}/api/security/GetUsersToImpersonate?query=${query}`)
      .pipe(mapResponse());
  }
  impersonateUser(userId: string): Observable<boolean> {
    return this.http
      .post<ServiceResponse<boolean>>(`${environment.apiUrl}/api/security/ImpersonateUser/${userId}`, null)
      .pipe(mapResponse());
  }
  stopImpersonating(): Observable<boolean> {
    return this.http.post<ServiceResponse<boolean>>(`${environment.apiUrl}/api/security/StopImpersonating`, null).pipe(mapResponse());
  }
}
