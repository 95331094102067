<div class="progress-indicator" *ngIf="customerEndpointsLoading$ | async">
  <div class="progressspinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div class="d-flex flex-column position-relative">
  <div *ngIf="(customerAvailableApiTypes$ | async) && (customerAvailableApiTypes$ | async).length">
    <div class="kbxl-inputgroup-container">
      <div class="kbxl-field-label">
        <label for="selectedApiTypes" class="control-label">Available Endpoints</label>
      </div>
      <div>
        <p-multiSelect
          [options]="customerAvailableApiTypes$ | async | toSelectItemByKey: 'customerApiTypeDescription':'customerApiTypeId'"
          placeholder="Select Endpoint(s)"
          [(ngModel)]="selectedApiTypes"
          name="selectedApiTypes"
          appendTo="body"
          [filter]="true"
          [styleClass]="'ui-dropdown-primary'"
          dropdownIcon="pi pi-caret-down"
        >
        </p-multiSelect>
      </div>
    </div>
    <div class="mb-4 pull-right">
      <button type="button" pButton label="Add Endpoints" class="ui-button-primary" (click)="copyEndpoints()"></button>
    </div>
  </div>
  <div class="d-flex flex-column mt-4 mb-4" *ngIf="(customerEndpoints$ | async)?.length === 0">
    <h5 class="text-center text-muted">No endpoints found</h5>
  </div>
  <div class="list-group">
    <div class="list-group-item list-group-item-action" *ngFor="let endpoint of customerEndpoints$ | async">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">{{ endpoint.customerApiTypeDescription }}</h6>
        <small> </small>
      </div>
      <p class="mb-1">
        {{ endpoint.customerApiUri }}
        <kbxl-copy-to-clipboard class="ml-1" [text]="endpoint.customerApiUri"></kbxl-copy-to-clipboard>
      </p>
      <div class="d-flex flex-wrap">
        <span class="badge badge-primary mr-2" *ngIf="endpoint.isTOPSEndpoint">TOPS endpoint</span>
        <span class="badge badge-info">{{ endpoint.customerApiAuthTypeDescription }}</span>
      </div>
    </div>
  </div>
</div>
