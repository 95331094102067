<div class="d-flex flex-column">
  <div *ngIf="counterOffer?.currentStatus === 'Expired'">
    <div class="alert alert-warning" role="alert">
      {{ getExpiredStatus() }}
    </div>
  </div>
  <div *ngIf="user && user.isShipper && activeOffer?.percentIncrease < 0">
    <div class="alert alert-primary" role="alert">Current rate is above counter offer.</div>
  </div>
  <table>
    <thead></thead>
  </table>
  <table class="counter-offer-grid">
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th *ngIf="shipperOffer || showShipperCounter">
          <div class="d-flex flex-column" *ngIf="shipperOffer || showShipperCounter">
            <div *ngIf="shipperOffer && shipperOffer?.status === 'Approved'">
              <span class="badge badge-success">Approved</span>
            </div>
            <span class="d-inline-block teal-text" *ngIf="showShipperCounter">
              Percent Increase
              <div class="inline-block" *ngIf="calculatingOffer; else shipperIncreaseTemplate" class="small-spinner-container">
                <p-progressSpinner></p-progressSpinner>
              </div>
              <ng-template #shipperIncreaseTemplate>
                {{ pendingCounterOffer.percentIncrease | percent }}
              </ng-template>
            </span>
            <h6 class="d-inline-block">Shipper</h6>
          </div>
        </th>
        <th *ngIf="!counterOffer.contractCarrierCounterOffer">
          <div *ngIf="carrierOffer && carrierOffer?.status === 'Approved'">
            <span class="badge badge-success">Approved</span>
          </div>
          <h6 class="d-inline-block">Carrier</h6>
        </th>
        <th>
          <h6 class="d-inline-block">Posted Rate</h6>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="totalRate">
        <td><span class="load-detail-label">Total Rate:</span></td>
        <td *ngIf="shipperOffer || showShipperCounter">
          <div *ngIf="showShipperCounter; else readonlyShipperTotalRate">
            <div class="load-detail-value" [ngClass]="getBreakEvenStylesForShipperCounter(showShipperCounter)">
              {{ pendingCounterOffer?.counterOfferTotalRate | currency }}
            </div>
          </div>
          <ng-template #readonlyShipperTotalRate>
            <div class="load-detail-value" [ngClass]="getBreakEvenStylesForShipperCounter(showShipperCounter)">
              {{ shipperOffer?.counterOfferTotalRate | currency }}
            </div>
          </ng-template>
        </td>
        <td *ngIf="!counterOffer.contractCarrierCounterOffer">
          <div *ngIf="showCarrierCounter; else readonlyCarrierTotalRate">
            <div class="load-detail-value" [ngClass]="getBreakEvenStylesForCarrierCounter(showCarrierCounter)">
              {{ pendingCounterOffer?.counterOfferTotalRate | currency }}
            </div>
          </div>
          <ng-template #readonlyCarrierTotalRate>
            <div class="load-detail-value" [ngClass]="getBreakEvenStylesForCarrierCounter(showCarrierCounter)">
              {{ carrierOffer?.counterOfferTotalRate | currency }}
            </div>
          </ng-template>
        </td>
        <td>
          <div class="load-detail-value">{{ counterOffer.totalRate | currency }}</div>
        </td>
      </tr>
      <tr>
        <td><span class="load-detail-label">Line Haul:</span></td>
        <td *ngIf="shipperOffer || showShipperCounter">
          <div *ngIf="showShipperCounter; else readonlyShipperOffer">
            <input
              type="text"
              maxlength="10"
              class="linehaul-input"
              [(ngModel)]="pendingCounterOffer.counterOfferLineHaulRate"
              (ngModelChange)="calcLoadshopFee()"
              pInputText
              kbxlCurrencyInput
            />
          </div>
          <ng-template #readonlyShipperOffer>
            <div class="load-detail-value">{{ shipperOffer?.counterOfferLineHaulRate | currency }}</div>
          </ng-template>
        </td>
        <td *ngIf="!counterOffer.contractCarrierCounterOffer">
          <div *ngIf="showCarrierCounter; else readonlyCarrierOffer">
            <input
              type="text"
              maxlength="10"
              class="linehaul-input"
              [(ngModel)]="pendingCounterOffer.counterOfferLineHaulRate"
              (ngModelChange)="calcLoadshopFee()"
              pInputText
              kbxlCurrencyInput
            />
          </div>
          <ng-template #readonlyCarrierOffer>
            <div class="load-detail-value">{{ carrierOffer?.counterOfferLineHaulRate | currency }}</div>
          </ng-template>
        </td>
        <td>
          <div *ngIf="!counterOffer.contractCarrierCounterOffer; else contractCarrierLineHaul" class="load-detail-value">
            {{ counterOffer.lineHaulRate | currency }}
          </div>
          <ng-template #contractCarrierLineHaul>
            <div class="load-detail-value">{{ counterOffer?.lineHaulRate | currency }}</div>
          </ng-template>
        </td>
      </tr>
      <tr *ngIf="counterOffer.fuelRate && counterOffer.fuelRate > 0">
        <td><span class="load-detail-label">FSC:</span></td>
        <td *ngIf="shipperOffer || showShipperCounter">
          <div class="load-detail-value">{{ counterOffer.fuelRate | currency }}</div>
        </td>
        <td *ngIf="!counterOffer.contractCarrierCounterOffer">
          <div class="load-detail-value">{{ counterOffer.fuelRate | currency }}</div>
        </td>
        <td>
          <div class="load-detail-value">{{ counterOffer.fuelRate | currency }}</div>
        </td>
      </tr>
      <tr *ngIf="counterOffer.loadshopFee && counterOffer.loadshopFee > 0 && user && user.isShipper">
        <td><span class="load-detail-label">Loadshop Fee:</span></td>
        <td *ngIf="showShipperCounter || shipperOffer">
          <div *ngIf="showShipperCounter; else readonlyShipperFee">
            <div class="load-detail-value">{{ pendingCounterOffer?.loadshopFee | currency }}</div>
          </div>
          <ng-template #readonlyShipperFee>
            <div class="load-detail-value">{{ shipperOffer?.counterOfferLoadshopFee | currency }}</div>
          </ng-template>
        </td>
        <td *ngIf="!counterOffer.contractCarrierCounterOffer">
          <div class="load-detail-value">{{ carrierOffer?.counterOfferLoadshopFee | currency }}</div>
        </td>
        <td>
          <div class="load-detail-value">{{ counterOffer.loadshopFee | currency }}</div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="action-dropdown mx-auto mt-2" *ngIf="availableActions && availableActions.length > 0">
    <div class="kbxl-inputgroup-container mb-0">
      <div class="kbxl-field-label">
        <label for="name" class="control-label">Action</label>
      </div>
      <p-dropdown
        [options]="availableActions"
        placeholder="Select Action"
        [styleClass]="'p-dropdown-primary'"
        dropdownIcon="pi pi-caret-down"
        baseZIndex="10000"
        [(ngModel)]="activeOffer.actionTaken"
        (onChange)="takeAction($event, activeOffer)"
      ></p-dropdown>
    </div>
  </div>
  <div *ngIf="availableActions.length > 0 && enableApply">
    <div class="d-flex flex-column align-items-center mt-2" *ngIf="showCarrierCounter">
      <div class="d-flex align-items-center">
        <span>Expires after</span>
        <input
          type="text"
          pattern="\d*"
          maxlength="2"
          class="time-input ml-4 mr-2"
          [(ngModel)]="pendingCounterOffer.expirationHours"
          pInputText
        />
        <span>Hour(s)</span>
        <input
          type="text"
          pattern="\d*"
          maxlength="2"
          class="time-input ml-4 mr-2"
          [(ngModel)]="pendingCounterOffer.expirationMinutes"
          pInputText
        />
        <span>Minutes</span>
      </div>
      <p class="mt-3">If the Shipper accepts your offer, the tender will be processed on the agreed upon rate.</p>
    </div>
    <p class="mt-2" *ngIf="showShipperCounter">If the Carrier accepts your offer, the tender will be processed on the agreed upon rate.</p>
    <div class="d-flex justify-content-between mt-3">
      <button
        pButton
        type="button"
        class="p-button-outlined p-button-secondary"
        label="Cancel"
        [disabled]="!enableApply"
        (click)="cancelAction()"
      ></button>
      <button
        pButton
        type="button"
        [label]="showShipperCounter || showCarrierCounter ? 'Request Counter Offer' : 'Apply'"
        [disabled]="applyDisabled"
        (click)="applyAction()"
      ></button>
    </div>
  </div>

  <div *ngIf="user && user.isShipper && carrierContactData">
    <hr />
    <h6>Carrier Contact Information</h6>
    <div class="d-flex">
      <div class="d-flex flex-column">
        <span class="load-detail-label">Carrier:</span>
        <span class="load-detail-label">SCAC:</span>
        <span class="load-detail-label" [class.chat-now]="shouldShowCarrierUserChatNow()">Name:</span>
        <span class="load-detail-label" *ngIf="carrierContactData?.email">Email: </span>
        <span class="load-detail-label" *ngFor="let phone of carrierContactData?.cellPhoneNumbers">Cell:</span>
        <span class="load-detail-label" *ngFor="let phone of carrierContactData?.phoneNumbers">Phone:</span>
      </div>
      <div class="d-flex flex-column">
        <div class="load-detail-value">{{ counterOffer.carrierData.scacName | titlecase }}</div>
        <div class="load-detail-value">{{ counterOffer.carrierData.scac | uppercase }}</div>
        <div class="load-detail-value">
          <div kbxlFeature="chat" *ngIf="shouldShowCarrierUserChatNow(); else carrierUserNameOnly">
            <kbxl-chat-user
              [userId]="carrierContactData.identUserId"
              [userName]="carrierContactData.firstName"
              (chatNow)="chatWithCarrierUserClick()"
            ></kbxl-chat-user>
          </div>
          <ng-template #carrierUserNameOnly>
            <div class="contact-name">
              {{ carrierContactData.firstName | titlecase }}
            </div>
          </ng-template>
        </div>
        <div class="booked-email d-flex" *ngIf="carrierContactData?.email">
          <a class="text-truncate" href="mailto: {{ carrierContactData?.email }}" [title]="carrierContactData?.email"
            >{{ carrierContactData?.email | lowercase }}
          </a>
          <kbxl-copy-to-clipboard class="ml-1" [text]="carrierContactData?.email | lowercase"></kbxl-copy-to-clipboard>
        </div>
        <div class="text-nowrap" *ngFor="let phone of carrierContactData?.cellPhoneNumbers">
          <a href="tel:{{ phone }}">{{ phone }}</a>
        </div>
        <div class="text-nowrap" *ngFor="let phone of carrierContactData?.phoneNumbers">
          <a href="tel:{{ phone }}">{{ phone }}</a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="user && user.isCarrier && shipperOffer && shipperOffer.requesterUserData">
    <hr />
    <h6>Shipper Contact Information</h6>
    <div class="d-flex">
      <div class="d-flex flex-column">
        <span class="load-detail-label" [class.chat-now]="shouldShowShipperUserChatNow()">Name:</span>
        <span class="load-detail-label" *ngIf="shipperOffer.requesterUserData?.email">Email: </span>
        <span class="load-detail-label" *ngFor="let phone of shipperOffer.requesterUserData?.cellPhoneNumbers">Cell:</span>
        <span class="load-detail-label" *ngFor="let phone of shipperOffer.requesterUserData?.phoneNumbers">Phone:</span>
      </div>
      <div class="d-flex flex-column">
        <div class="load-detail-value">
          <div kbxlFeature="chat" *ngIf="shouldShowShipperUserChatNow(); else shipperUserNameOnly">
            <kbxl-chat-user
              [userId]="shipperOffer.requesterUserData.identUserId"
              [userName]="shipperOffer.requesterUserData.firstName"
              (chatNow)="chatWithShipperUserClick()"
            ></kbxl-chat-user>
          </div>
          <ng-template #shipperUserNameOnly>
            <div class="contact-name">
              {{ shipperOffer.requesterUserData.firstName | titlecase }}
            </div>
          </ng-template>
        </div>
        <div class="booked-email d-flex" *ngIf="shipperOffer.requesterUserData?.email">
          <a
            class="text-truncate"
            href="mailto: {{ shipperOffer.requesterUserData?.email }}"
            [title]="shipperOffer.requesterUserData?.email"
            >{{ shipperOffer.requesterUserData?.email | lowercase }}
          </a>
          <kbxl-copy-to-clipboard class="ml-1" [text]="shipperOffer.requesterUserData?.email | lowercase"></kbxl-copy-to-clipboard>
        </div>
        <div class="text-nowrap" *ngFor="let phone of shipperOffer.requesterUserData?.cellPhoneNumbers">
          <a href="tel:{{ phone }}">{{ phone }}</a>
        </div>
        <div class="text-nowrap" *ngFor="let phone of shipperOffer.requesterUserData?.phoneNumbers">
          <a href="tel:{{ phone }}">{{ phone }}</a>
        </div>
      </div>
    </div>
  </div>

  <kbxl-counter-offer-comments [user]="user" [counterOffer]="counterOffer" [activeOffer]="activeOffer"></kbxl-counter-offer-comments>
</div>
