import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kbxl-shipping-load-home-header',
  templateUrl: './shipping-load-home-header.component.html',
  styleUrls: ['./shipping-load-home-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingLoadHomeHeaderComponent {
  @Input() allowPostLoads: boolean;
  @Input() loadingAnySmartSpotPrices: boolean;
  @Input() allowManualLoadCreation: boolean;
  @Input() loadingLatestTransaction: boolean;
  @Input() selectedLoadCount: number;

  @Output() postLoads = new EventEmitter<boolean>();
  @Output() createLoad = new EventEmitter<boolean>();

  constructor() {}

  postClicked() {
    this.postLoads.emit();
  }

  canPostLoads() {
    return !this.loadingAnySmartSpotPrices && this.allowPostLoads && !this.loadingLatestTransaction;
  }

  createClicked() {
    this.createLoad.emit();
  }
}
