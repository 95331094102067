import { BookLoadType } from '../../models';
import { createReducer } from '../../utilities';
import { LoadBoardLoadBookActions, LoadBoardLoadBookActionTypes } from '../actions/';

export interface LoadBoardBookedState {
  booking: boolean;
  showCounterOfferModal: boolean;
}
const initialState: LoadBoardBookedState = {
  booking: false,
  showCounterOfferModal: false,
};

const _LoadBoardBookedReducer = createReducer(
  initialState,
  function (state: LoadBoardBookedState = initialState, action: LoadBoardLoadBookActions): LoadBoardBookedState {
    switch (action.type) {
      case LoadBoardLoadBookActionTypes.Book: {
        let counterOfferModal = false;
        if (
          action.payload.bookingType === BookLoadType.CounterOfferBooked &&
          action.payload.loadData.counterOffer &&
          action.payload.loadData.counterOffer.request
        ) {
          counterOfferModal = true;
        }
        return { ...state, booking: true, showCounterOfferModal: counterOfferModal };
      }
      case LoadBoardLoadBookActionTypes.Book_Success: {
        return { ...state, booking: false, showCounterOfferModal: false };
      }
      case LoadBoardLoadBookActionTypes.Book_Failure: {
        return { ...state, booking: false };
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function LoadBoardBookedReducer(state: LoadBoardBookedState, action: LoadBoardLoadBookActions): LoadBoardBookedState {
  return _LoadBoardBookedReducer(state, action);
}

export const getLoadBooking = (state: LoadBoardBookedState) => state.booking;
