<div class="progress-indicator" *ngIf="loading">
  <div class="progressspinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div class="page-container mobile-padding p-lg-0" *ngIf="user">
  <p-panel styleClass=" ">
    <p-header>
      <h5 class="font-weight-bold">My Profile</h5>
    </p-header>
    <form [formGroup]="userProfileForm" (ngSubmit)="onSubmit()">
      <div class="row" *ngIf="userProfileForm.errors && submitted">
        <div class="col-12 error-container">
          <p *ngIf="userProfileForm.controls.email.invalid">Email Address Must Be Provided</p>
          <p *ngIf="userProfileForm.hasError('noPhoneContacts')">Contact Phone Number Must Be Provided</p>
        </div>
      </div>
      <div class="row" *ngIf="error">
        <div class="col-12 error-container">
          <p>{{ decodeProblemDetails() }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6 kbxl-inputgroup-container">
          <div class="kbxl-field-label">
            <label for="name" class="control-label">Name</label>
          </div>
          <input pInputText id="name" type="text" [value]="user.name | titlecase" disabled />
        </div>
        <div class="col-12 col-lg-6 kbxl-inputgroup-container" *ngIf="!user.isShipper">
          <div for="carrier" class="kbxl-field-label">
            <label for="carrier" class="control-label">Carrier</label>
          </div>
          <input id="carrier" type="text" [value]="user.carrierName | titlecase" pInputText disabled />
        </div>
        <div class="col-12 col-lg-6 kbxl-inputgroup-container" *ngIf="!user.isShipper">
          <div class="kbxl-field-label">
            <label for="scac" class="control-label">SCAC</label>
          </div>
          <input *ngIf="!allowSelectingScac" id="scac" type="text" [value]="user.carrierScac" pInputText disabled />
          <p-dropdown
            *ngIf="allowSelectingScac"
            [options]="user.availableCarrierScacsSelectItems"
            formControlName="carrierScac"
            placeholder="Select a SCAC"
            [styleClass]="'p-dropdown-primary'"
          >
          </p-dropdown>
        </div>

        <div class="col-12 col-lg-6 kbxl-inputgroup-container">
          <div class="kbxl-field-label" [ngClass]="{ error: userProfileForm.controls.email.invalid }">
            <label class="control-label">Email</label>
          </div>
          <input type="email" formControlName="email" pInputText maxlength="256" />
        </div>
        <div class="col-12 col-lg-6 kbxl-inputgroup-container" *ngIf="user.isShipper">
          <div class="kbxl-field-label">
            <label for="commodity" class="control-label">Default Commodity</label>
            <div class="small-spinner-container ml-2">
              <p-progressSpinner *ngIf="loadingCommodities"></p-progressSpinner>
            </div>
          </div>
          <div class="p-inputgroup">
            <p-dropdown
              [options]="commodities"
              placeholder="Select Commodity"
              formControlName="selectedCommodity"
              name="commodity"
              dataKey="commodityName"
              optionLabel="commodityName"
              [styleClass]="'p-dropdown-primary no-border-radius-right'"
              dropdownIcon="pi pi-caret-down"
              appendTo="body"
              kbxlCloseOnScroll
              hideTransitionOptions="0ms"
            >
              <!-- <ng-template let-item pTemplate="selectedItem">
              <span class="selected-item-label">{{ user.selectedCommodity.commodityName }}</span>
            </ng-template> -->
            </p-dropdown>

            <button
              type="button"
              pButton
              icon="pi pi-times-circle"
              class="p-button-secondary p-inputgroup-addon"
              (click)="resetCommodity()"
            ></button>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="kbxl-field-label">
            <label class="control-label">All Notifications Enabled for Email and Text Notifications</label>
          </div>
          <div class="kbxl-field-label">
            <p-checkbox class="mr-2" name="enabled" value="enabled" formControlName="notificationEnabled" binary="true"></p-checkbox>
            <label class="control-label">Notifications Enabled</label>
          </div>
          <div kbxlFeature="chat" *ngIf="allNotificationsEnabled" class="kbxl-field-label">
            <p-checkbox class="mr-2" name="enabled" value="enabled" formControlName="chatNotificationsEnabled" binary="true"></p-checkbox>
            <label class="control-label">Chat Notifications Enabled</label>
          </div>
        </div>
        <div class="col-12 col-lg-6" kbxlActionCheck="loadshop.ui.profile.loadstatusnotifications">
          <div class="kbxl-field-label">
            <label class="control-label">Load Status Notifications</label>
          </div>
          <div class="kbxl-field-label">
            <button
              type="button"
              pButton
              label="Manage Notifications"
              (click)="showManageLoadNotifications = !showManageLoadNotifications"
            ></button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-8 col-md-10">
          <label
            class="kbxl-field-label"
            [ngClass]="{ error: userProfileForm.errors && submitted && userProfileForm.errors['noPhoneContacts'] }"
          >
            Contact Numbers
          </label>
          <label class="kbxl-field-label notification-label">Notifications Enabled</label>
        </div>
      </div>
      <div
        class="row kbxl-inputgroup-container contact-numbers mb-2"
        formArrayName="contactNumbers"
        *ngFor="let itemFormGroup of contactNumbers; let i = index"
      >
        <div [formGroupName]="i" class="col-xl-6 col-lg-8 col-md-10">
          <div class="row">
            <div class="col-md-4 col-12 mb-2">
              <p-dropdown class="w-auto" [options]="messageTypes" placeholder="Contact Number" formControlName="messageTypeId">
              </p-dropdown>
            </div>
            <div class="col-md-3 col-5">
              <p-inputMask mask="(999) 999-9999" formControlName="notificationValue" placeholder="(999) 999-9999"></p-inputMask>
            </div>
            <div class="col-md-3 col-3" *ngIf="itemFormGroup?.value?.messageTypeId !== 'Phone'"></div>
            <div class="col-md-3 col-4" *ngIf="itemFormGroup?.value?.messageTypeId === 'Phone'">
              <div class="hidden" [ngClass]="{ visible: itemFormGroup?.value?.messageTypeId === 'Phone' }">
                <span class="mr-2">Ext.</span>
                <input pInputText class="phone-extension" type="tel" formControlName="additionalValue" placeholder="999999" maxlength="6" />
              </div>
            </div>
            <div class="col-md-1 col-1" *ngIf="itemFormGroup?.value?.messageTypeId === 'Phone'"></div>
            <div class="col-md-1 col-2" *ngIf="itemFormGroup?.value?.messageTypeId !== 'Phone'">
              <p-checkbox
                class="hidden mx-2 mt-2"
                [ngClass]="{ visible: itemFormGroup?.value?.messageTypeId !== 'Phone' }"
                name="enabled"
                value="enabled"
                formControlName="notificationEnabled"
                binary="true"
              ></p-checkbox>
            </div>
            <div class="col-md-1 col-2">
              <button
                class="p-button-danger"
                [ngClass]="{ hidden: i === 0 }"
                pButton
                icon="pi pi-trash"
                (click)="removeContact(i)"
                title="Delete Contact"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 kbxl-inputgroup-container">
          <button pButton type="button" class="p-button-teal" icon="pi pi-plus" label="Add New" (click)="addContact()"></button>
        </div>
      </div>
      <div *ngIf="user.carrierVisibilityTypes && user.carrierVisibilityTypes.length" class="col-12 col-lg-6 mb-1">
        <div class="kbxl-field-label">
          <label for="carrierVisibilityType" class="control-label">Visibility Type(s)</label>
        </div>
        <input
          id="carrierVisibilityType"
          type="text"
          disabled
          pInputText
          value="{{ user.carrierVisibilityTypes.join(', ') | titlecase }}"
        />
      </div>

      <div class="d-flex justify-content-end">
        <button type="button" pButton label="Log Out" (click)="logout()" class="p-button-outlined p-button-secondary m-2"></button>
        <button type="submit" pButton label="Save Profile" class="m-2"></button>
      </div>
    </form>
  </p-panel>
</div>

<p-dialog header="Load Status Notifications" [(visible)]="showManageLoadNotifications" [style]="{ width: '800px' }" [modal]="true">
  <kbxl-user-load-notifications
    [user]="user"
    [loadStatusNotifications]="loadStatusNotifications"
    [error]="loadStatusNotificationsErrors"
    (saveLoadStatusNotifications)="onSaveLoadStatusNotifications($event)"
  ></kbxl-user-load-notifications>
</p-dialog>
