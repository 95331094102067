import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/operators';
import { CreateDirectMessageData } from 'src/app/chat/models';
import { GuidEmpty } from 'src/app/core/utilities/constants';
import { getBreakEvenRateStyles } from 'src/app/shared/utilities';
import { counterOfferRequestModalZIndex } from '../../../constants';
import {
  CounterOfferRequestStatusEnum,
  Customer,
  LoadCounterOfferActionData,
  LoadCounterOfferData,
  LoadCounterOfferGroupData,
  LoadCounterOfferRequestData,
  LoadDetail,
  UserContactData,
  UserModel,
} from '../../../models';
import { SecurityAppActionType } from '../../../models/security-app-action-type';
import { LoadCounterOfferService } from '../../../services';
import { BaseComponent } from '../../base-component';
import { ConfirmActionModalComponent } from '../../confirm-action-modal';

@Component({
  selector: 'kbxl-counter-offer-group-accordion-tab',
  templateUrl: './counter-offer-group-accordion-tab.component.html',
  styleUrls: ['./counter-offer-group-accordion-tab.component.scss'],
})
export class CounterOfferGroupAccordionTabComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() loadId: string;
  @Input() load: LoadDetail;
  @Input() counterOffer: LoadCounterOfferGroupData;
  @Input() user: UserModel;
  @Input() shipper: Customer;
  @Output() pendingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() saveAction: EventEmitter<LoadCounterOfferActionData> = new EventEmitter<LoadCounterOfferActionData>();
  @Output() submitOffer: EventEmitter<LoadCounterOfferRequestData> = new EventEmitter<LoadCounterOfferRequestData>();
  @Output() chatNow = new EventEmitter<CreateDirectMessageData>();
  availableActions: SelectItem[] = [];

  // current sent carrier offer
  carrierOffer: LoadCounterOfferData;
  // current sent shipper offer
  shipperOffer: LoadCounterOfferData;
  // latest offer
  activeOffer: LoadCounterOfferData;

  pendingCounterOffer: LoadCounterOfferRequestData;
  enableApply = false;
  showShipperCounter = false;
  showCarrierCounter = false;
  applyDisabled = false;
  calculatingOffer = false;
  carrierContactData: UserContactData;

  constructor(
    private dialogService: DialogService,
    private currencyPipe: CurrencyPipe,
    private counterOfferService: LoadCounterOfferService
  ) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.counterOffer) {
      this.carrierOffer = this.counterOffer.counterOffers.find((x) => x.loadCounterOfferId === this.counterOffer.currentCarrierOfferId);
      this.shipperOffer = this.counterOffer.counterOffers.find((x) => x.loadCounterOfferId === this.counterOffer.currentShipperOfferId);

      this.updateActions();
      this.activeOffer = this.getActiveCounterOffer();

      if (this.carrierOffer) {
        this.carrierContactData = this.carrierOffer.requesterUserData;
      } else if (this.counterOffer.contractCarrierCounterOffer && this.user && this.user.isShipper) {
        this.carrierContactData = this.shipperOffer.targetUserData;
      }
    }
  }

  ngOnInit(): void {}

  getExpiredStatus(): string {
    let action: LoadCounterOfferActionData;
    if (!this.carrierOffer) {
      // for shipper initiated offers, use their offer
      action = this.shipperOffer.actions.find((x) => x.status === CounterOfferRequestStatusEnum.Expired);
    } else {
      action = this.carrierOffer.actions.find((x) => x.status === CounterOfferRequestStatusEnum.Expired);
    }
    return action ? action.comment : '';
  }

  takeAction(dropdownEvent: any, request: LoadCounterOfferData): void {
    this.cancelAction();
    request.actionTaken = dropdownEvent.value;
    this.enableApply = true;
    if (dropdownEvent.value === CounterOfferRequestStatusEnum.ShipperCounter) {
      // initialize the shipper's counter offer
      this.pendingCounterOffer = {
        loadId: this.loadId,
        counterOfferLineHaulRate: this.counterOffer.lineHaulRate,
        expirationHours: 0, // this will get populated by the server
        expirationMinutes: 0, // this will get populated by the server
        parentLoadCounterOfferId: this.activeOffer.loadCounterOfferId,
        percentIncrease: 0,
        counterOfferTotalRate: this.counterOffer.totalRate,
        loadshopFee: this.counterOffer.loadshopFee,
        shipperOriginatedRequest: false,
      };
      this.showShipperCounter = true;
      this.calcLoadshopFee();
    } else if (dropdownEvent.value === CounterOfferRequestStatusEnum.CarrierCounter) {
      // get time remaining on current offer
      const now = moment(new Date());
      // the date is stored in UTC, however it comes back from the server without the 'z'
      // the type is 'Date' however its coming as a string from api, so cast into Date first
      const currentExpiration = moment(new Date(`${this.carrierOffer.expirationDate}z`));

      const duration = moment.duration(currentExpiration.diff(now));
      const hours = Math.floor(duration.asHours());
      const minutes = Math.ceil(duration.asMinutes() - hours * 60);

      // initialize the shipper's counter offer
      this.pendingCounterOffer = {
        loadId: this.loadId,
        counterOfferLineHaulRate: 0,
        expirationHours: hours,
        expirationMinutes: minutes,
        parentLoadCounterOfferId: this.activeOffer.loadCounterOfferId,
        percentIncrease: 0,
        counterOfferTotalRate: this.counterOffer.totalRate,
        loadshopFee: 0, // do not calc loadshop fee for carrier
        shipperOriginatedRequest: false,
      };
      this.showCarrierCounter = true;
      this.calcLoadshopFee();
    }

    this.pendingChange.emit(true);
  }

  cancelAction(): void {
    if (this.showShipperCounter) {
      this.showShipperCounter = false;
      this.pendingCounterOffer = null;
      this.activeOffer.actionTaken = null;
    } else if (this.showCarrierCounter) {
      this.showCarrierCounter = false;
      this.pendingCounterOffer = null;
      this.activeOffer.actionTaken = null;
    } else {
      this.activeOffer.actionTaken = null;
    }
    this.enableApply = false;
    this.applyDisabled = false;

    this.pendingChange.emit(false);
  }

  updateActions(): void {
    this.availableActions = [];
    const request = this.getActiveCounterOffer();

    if (
      !request ||
      request.status === CounterOfferRequestStatusEnum.Denied ||
      request.status === CounterOfferRequestStatusEnum.Expired ||
      request.status === CounterOfferRequestStatusEnum.Approved
    ) {
      return;
    }

    // check if they are a shipper user or admin
    if (this.user.isShipper) {
      if (
        this.user.hasSecurityAction(SecurityAppActionType.ShipperCounterOfferRequest) &&
        request.status === CounterOfferRequestStatusEnum.PendingShipper &&
        !this.counterOffer.contractCarrierCounterOffer
      ) {
        this.availableActions.push({
          label: 'Counter',
          value: CounterOfferRequestStatusEnum.ShipperCounter,
        });
      }
      if (
        this.user.hasSecurityAction(SecurityAppActionType.ShipperCounterOfferAccept) &&
        request.status === CounterOfferRequestStatusEnum.PendingCarrier &&
        !this.counterOffer.contractCarrierCounterOffer
      ) {
        this.availableActions.push({
          label: "Approve Carrier's Counter",
          value: CounterOfferRequestStatusEnum.Approved,
        });
      }

      if (
        this.user.hasSecurityAction(SecurityAppActionType.ShipperCounterOfferAccept) &&
        request.status === CounterOfferRequestStatusEnum.PendingShipper &&
        !this.counterOffer.contractCarrierCounterOffer
      ) {
        this.availableActions.push({
          label: 'Approve ',
          value: CounterOfferRequestStatusEnum.Approved,
        });
      }
      this.availableActions.push({
        label: 'Deny',
        value: CounterOfferRequestStatusEnum.Denied,
      });
    } else if (this.user.isCarrier) {
      // user is carrier
      if (request.status === CounterOfferRequestStatusEnum.PendingCarrier) {
        this.availableActions.push({
          label: 'Approve',
          value: CounterOfferRequestStatusEnum.Approved,
        });

        if (!this.counterOffer.contractCarrierCounterOffer) {
          this.availableActions.push({
            label: 'Counter',
            value: CounterOfferRequestStatusEnum.CarrierCounter,
          });
        }

        this.availableActions.push({
          label: 'Deny',
          value: CounterOfferRequestStatusEnum.Denied,
        });
      } else {
        // there has been no shipper rebuttal, all the user can do is remove their offer
        this.availableActions = [
          {
            label: 'Remove counter offer',
            value: CounterOfferRequestStatusEnum.Expired,
          },
        ];
      }
    }
  }

  applyAction(): void {
    this.applyDisabled = true;
    let pendingActionToTake = this.activeOffer.actionTaken;
    if (this.showShipperCounter) {
      pendingActionToTake = CounterOfferRequestStatusEnum.ShipperCounter;
    } else if (this.showCarrierCounter) {
      pendingActionToTake = CounterOfferRequestStatusEnum.CarrierCounter;
    }

    // confirm the action via modal
    let commentRequired = false;
    let confirmationText = '';
    let header = 'Counter Offer Approval Confirmation';
    const breakEvenRate = '';
    if (this.user.isShipper) {
      switch (pendingActionToTake) {
        case CounterOfferRequestStatusEnum.ShipperCounter:
          commentRequired = false;
          confirmationText = `Are you sure want to request counter offer for ${this.currencyPipe.transform(
            this.pendingCounterOffer.counterOfferTotalRate
          )}?`;
          header = 'Request Counter Confirmation';
          break;
        case CounterOfferRequestStatusEnum.Approved:
          commentRequired = false;
          confirmationText = `Are you sure want to approve counter offer for ${this.currencyPipe.transform(
            this.carrierOffer.counterOfferTotalRate
          )}?`;
          header = 'Counter Offer Approval Confirmation';
          break;
        case CounterOfferRequestStatusEnum.Denied:
          commentRequired = true;
          confirmationText = `Are you sure want to deny counter offer for ${this.currencyPipe.transform(
            this.activeOffer.counterOfferTotalRate
          )}?  Comments are required for the reason for denial.`;
          header = 'Counter Offer Denied Confirmation';
          break;
      }
    } else {
      // user is carrier
      switch (this.activeOffer.actionTaken) {
        case CounterOfferRequestStatusEnum.CarrierCounter:
          commentRequired = false;
          confirmationText = `Are you sure want to request counter offer for ${this.currencyPipe.transform(
            this.pendingCounterOffer.counterOfferTotalRate
          )}?`;
          header = 'Request Counter Confirmation';
          break;
        case CounterOfferRequestStatusEnum.Expired: // this is the denied
          commentRequired = false;
          confirmationText = 'Counter offer will be expired from the shipper?';
          header = 'Counter Offer Removal Confirmation';
          break;
        case CounterOfferRequestStatusEnum.Denied:
          commentRequired = true;
          confirmationText = `Are you sure want to deny counter offer for ${this.currencyPipe.transform(
            this.activeOffer.counterOfferTotalRate
          )}?  Comments are required for the reason for denial.`;
          header = 'Counter Offer Denied Confirmation';
          break;
        case CounterOfferRequestStatusEnum.Approved:
          commentRequired = false;
          confirmationText = `Are you sure want to approve counter offer for ${this.currencyPipe.transform(
            this.activeOffer.counterOfferTotalRate
          )}?`;
          header = 'Counter Offer Approval Confirmation';
          break;
        case CounterOfferRequestStatusEnum.CarrierCounter:
          commentRequired = false;
          confirmationText = 'Counter offer will be expired from the shipper?';
          header = 'Counter Offer Removal Confirmation';
          break;
      }
    }

    // display accessorials modal
    const confirmRef = this.dialogService.open(ConfirmActionModalComponent, {
      data: {
        commentRequired: commentRequired,
        confirmationText: confirmationText,
        breakEvenRate: breakEvenRate,
      },
      header: header,
      baseZIndex: counterOfferRequestModalZIndex + 1,
    });
    // open the modal to confirm
    confirmRef.onClose.pipe(takeUntil(this.destroyed$)).subscribe((result: { confirmed: boolean; comment: string }) => {
      if (!result || !result.confirmed) {
        this.applyDisabled = false;
        return;
      } else if (
        pendingActionToTake === CounterOfferRequestStatusEnum.ShipperCounter ||
        pendingActionToTake === CounterOfferRequestStatusEnum.CarrierCounter
      ) {
        // save new counter offer
        this.submitOffer.emit(this.pendingCounterOffer);
        return;
      }

      // apply actions (Approved, denied) to active (shipper) counter offer
      let activeId = this.activeOffer.loadCounterOfferId;
      if (this.user.isCarrier) {
        // for approved requests, use the shipper offer, everything else use carrier offer
        if (
          pendingActionToTake === CounterOfferRequestStatusEnum.Approved ||
          pendingActionToTake === CounterOfferRequestStatusEnum.Denied
        ) {
          activeId = this.shipperOffer.loadCounterOfferId;
        } else if (pendingActionToTake === CounterOfferRequestStatusEnum.Expired) {
          activeId = this.carrierOffer.loadCounterOfferId;
        }
      } else {
        // for approved requests, use the shipper offer, everything else use carrier offer
        if (
          pendingActionToTake === CounterOfferRequestStatusEnum.Approved ||
          pendingActionToTake === CounterOfferRequestStatusEnum.Denied
        ) {
          if (this.counterOffer.contractCarrierCounterOffer) {
            activeId = this.shipperOffer?.loadCounterOfferId;
          } else {
            activeId = this.carrierOffer?.loadCounterOfferId;
          }
        }
      }

      const action: LoadCounterOfferActionData = {
        loadCounterOfferId: activeId,
        comment: result.comment as string,
        userName: this.user.name,
        createDtTm: new Date(),
        status: this.activeOffer.actionTaken,
        userId: this.user.userId,
        contractedCarrierOffer: this.counterOffer.contractCarrierCounterOffer,
      };
      this.saveAction.emit(action);
    });
  }

  calcLoadshopFee(): void {
    this.applyDisabled = true;
    this.calculatingOffer = true;

    if (this.showCarrierCounter) {
      this.calculatingOffer = false;
      this.pendingCounterOffer.counterOfferTotalRate = this.pendingCounterOffer.counterOfferLineHaulRate + this.counterOffer.fuelRate;

      if (this.pendingCounterOffer.counterOfferTotalRate > this.counterOffer.totalRate) {
        this.applyDisabled = false;
      }
    } else if (this.showShipperCounter) {
      // if the shipper tries to submit an offer for below posted rate, stop them
      if (this.pendingCounterOffer.counterOfferLineHaulRate <= this.counterOffer.lineHaulRate) {
        this.calculatingOffer = false;
        return;
      }
      this.pendingCounterOffer.counterOfferTotalRate = null;
      this.pendingCounterOffer.loadshopFee = null;
      this.pendingCounterOffer.percentIncrease = null;
      this.counterOfferService.calculateShipperTotalRateForCounterOffer(this.loadId, this.pendingCounterOffer).subscribe((result) => {
        this.pendingCounterOffer.counterOfferTotalRate = result.counterOfferTotalRate;
        this.pendingCounterOffer.loadshopFee = result.counterOfferLoadshopFee;
        this.pendingCounterOffer.percentIncrease = result.percentIncrease;
        this.applyDisabled = false;
        this.calculatingOffer = false;
      });
    }
  }
  getActiveCounterOffer(): LoadCounterOfferData {
    const carrierOffer = this.counterOffer.counterOffers.find((x) => x.loadCounterOfferId === this.counterOffer.currentCarrierOfferId);
    const shipperOffer = this.counterOffer.counterOffers.find((x) => x.loadCounterOfferId === this.counterOffer.currentShipperOfferId);

    // if the shipper initiated the counter offer, use that. Ex shipper sent offer to contracted carrier
    if (!carrierOffer && shipperOffer) {
      return shipperOffer;
    } else if (carrierOffer && carrierOffer.status === CounterOfferRequestStatusEnum.Approved) {
      return carrierOffer;
    } else if (shipperOffer && shipperOffer.status === CounterOfferRequestStatusEnum.Approved) {
      return shipperOffer;
    }
    return shipperOffer && new Date(shipperOffer?.created) > new Date(carrierOffer?.created) ? shipperOffer : carrierOffer;
  }

  shouldShowShipperUserChatNow(): boolean {
    const hasShipperUserId =
      this.shipperOffer &&
      this.shipperOffer.requesterUserData &&
      this.shipperOffer.requesterUserData.identUserId &&
      this.shipperOffer.requesterUserData.identUserId !== GuidEmpty
        ? true
        : false;

    // Do not allow chatting with a shipper who does not have chat enabled
    const isShipperChatEnabled = this.shipper && this.shipper.isChatEnabled ? true : false;
    return hasShipperUserId && isShipperChatEnabled;
  }
  getBreakEvenStylesForShipperCounter(showShipperCounter: boolean) {
    let totalRate: number;
    const quotePrice: number = null;
    if (showShipperCounter) {
      totalRate = this.pendingCounterOffer?.counterOfferTotalRate;
    }
    if (!showShipperCounter) {
      totalRate = this.shipperOffer?.counterOfferTotalRate;
    }
    return this.calculateRateStyles(totalRate, quotePrice, this.load?.isBrokeredLoad);
  }

  getBreakEvenStylesForCarrierCounter(showCarrierCounter: boolean) {
    let totalRate: number;
    const quotePrice: number = null;
    if (showCarrierCounter) {
      totalRate = this.pendingCounterOffer?.counterOfferTotalRate;
    }
    if (!showCarrierCounter) {
      totalRate = this.carrierOffer?.counterOfferTotalRate;
    }
    return this.calculateRateStyles(totalRate, quotePrice, this.load?.isBrokeredLoad);
  }
  getCurrentTotalRate() {
    if (this.shipperOffer || this.showShipperCounter) {
      if (this.showShipperCounter) {
        return this.pendingCounterOffer?.counterOfferTotalRate;
      } else {
        return this.shipperOffer?.counterOfferTotalRate;
      }
    } else {
      if (this.showCarrierCounter) {
        return this.pendingCounterOffer?.counterOfferTotalRate;
      } else {
        return this.carrierOffer?.counterOfferTotalRate;
      }
    }
    return 0;
  }
  chatWithShipperUserClick(): void {
    const data: CreateDirectMessageData = {
      loadRefDisplay: this.load.referenceLoadDisplay,
      shipperId: this.load.customerId,
      shipperName: this.shipper.name,
      shipperUserFullName: this.shipperOffer.requesterUserData.fullName,
      carrierName: this.user.scacName,
      carrierScac: this.user.carrierScac,
      carrierUserFullName: this.user.name,
      members: [
        {
          // Shipper User
          id: this.shipperOffer.requesterUserData.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.shipperOffer.requesterUserData.fullName,
        },
        {
          // Carrier User
          id: this.user.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.user.name,
        },
      ],
    };
    this.chatNow.emit(data);
  }

  shouldShowCarrierUserChatNow(): boolean {
    const hasCarrierUserId =
      this.carrierContactData &&
      this.carrierContactData &&
      this.carrierContactData.identUserId &&
      this.carrierContactData.identUserId !== GuidEmpty
        ? true
        : false;

    const isShipperChatEnabled = this.shipper && this.shipper.isChatEnabled ? true : false;
    // Do not allow chatting from a shipper who does not have chat enabled
    return hasCarrierUserId && isShipperChatEnabled;
  }

  chatWithCarrierUserClick(): void {
    const data: CreateDirectMessageData = {
      loadRefDisplay: this.load.referenceLoadDisplay,
      shipperId: this.shipper.customerId,
      shipperName: this.shipper.name,
      shipperUserFullName: this.user.name,
      carrierScac: this.counterOffer.carrierData.scac,
      carrierName: this.counterOffer.carrierData.scacName,
      carrierUserFullName: this.carrierContactData.fullName,
      members: [
        {
          // Shipper User
          id: this.user.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.user.name,
        },
        {
          // Carrier User
          id: this.carrierContactData.identUserId,
          identityUserName: '', // Not used when mapping to ConversationMember
          displayName: this.carrierContactData.fullName,
        },
      ],
    };
    this.chatNow.emit(data);
  }

  private calculateRateStyles(totalRate: number, quotePrice: number, isBrokeredLoad: boolean) {
    if (totalRate != null && totalRate > 0 && quotePrice != null && quotePrice > 0) {
      if (this.user.hasSecurityAction(SecurityAppActionType.BreakEvenViewAction) && this.user.isShipper && isBrokeredLoad) {
        return getBreakEvenRateStyles(totalRate, quotePrice, isBrokeredLoad, true);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
