<div class="progress-indicator" *ngIf="loading$ | async">
  <div class="progressspinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-6 kbxl-inputgroup-container" *ngIf="(favorites$ | async)?.length > 0">
      <div class="kbxl-field-label">
        <label class="control-label">Favorite</label>
      </div>
      <div class="p-inputgroup">
        <p-dropdown
          appendTo="body"
          [options]="favorites$ | async | toSelectItem"
          [(ngModel)]="localFilterCriteria.userFavoriteId"
          [autoDisplayFirst]="false"
          [filter]="true"
          [showClear]="true"
          [styleClass]="'p-dropdown-primary '"
          dropdownIcon="pi pi-caret-down"
          (onChange)="loadFavorite()"
          class="w-100"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="ml-3 kbxl-inputgroup-container">
      <div class="kbxl-field-label">
        <label id="filter-loads-default" class="control-label">Default Filter</label>
      </div>
      <div class="p-inputgroup">
        <p-inputSwitch [(ngModel)]="localFilterCriteria.defaultFavorite" name="defaultFavorite"></p-inputSwitch>
      </div>
    </div>
    <div class="col kbxl-inputgroup-container">
      <div class="kbxl-field-label">
        <label class="control-label">Favorite Name</label>
      </div>
      <div class="p-inputgroup">
        <input pInputText type="text" [(ngModel)]="localFilterCriteria.favoriteName" class="filter-box w-100" placeholder="Filter Name" />
        <button
          *ngIf="localFilterCriteria.userFavoriteId"
          pButton
          type="button"
          icon="pi pi-trash"
          title="Delete Favorite"
          class="p-button-danger p-inputgroup-addon"
          (click)="deleteFavorite()"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-save"
          title="Save Favorite"
          class="p-button-success p-inputgroup-addon"
          (click)="saveFavorite()"
        ></button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 kbxl-inputgroup-container">
      <div class="kbxl-field-label">
        <label class="control-label">Quick Filter</label>
      </div>
      <div class="p-inputgroup">
        <input
          pInputText
          type="text"
          [(ngModel)]="localFilterCriteria.quickFilter"
          class="filter-box w-100"
          placeholder="Search Criteria"
          (keyup)="trimFilter()"
        />
        <button
          pButton
          type="button"
          icon="pi pi-times-circle"
          class="p-button-secondary p-inputgroup-addon"
          (click)="clearFilter('quickFilter')"
        ></button>
      </div>
    </div>
    <div class="col-md-6 kbxl-inputgroup-container">
      <div class="kbxl-field-label">
        <label class="control-label">Equipment</label>
      </div>
      <div>
        <kbxl-tree-drop-down
          [treeNodes]="equipment"
          [(selectedNodes)]="selectedEquipment"
          placeHolder="Select Equipment Type(s)"
          class="p-dialog-tree-view"
          isInModal="true"
        >
        </kbxl-tree-drop-down>
      </div>
    </div>
    <div class="col-md-6 kbxl-inputgroup-container">
      <div class="kbxl-field-label">
        <label class="control-label">Customer Type</label>
      </div>
      <div>
        <p-multiSelect
          appendTo="body"
          [options]="customerLoadTypes$ | async"
          [(ngModel)]="localFilterCriteria.customerLoadTypes"
          defaultLabel="Select Customer Type(s)"
          [styleClass]="'p-dropdown-primary'"
          resetFilterOnHide="true"
          optionLabel="name"
          dataKey="customerLoadTypeId"
        >
        </p-multiSelect>
      </div>
    </div>
    <div class="col-md-6 kbxl-inputgroup-container">
      <div class="kbxl-field-label">
        <label class="control-label">Origin</label>
      </div>
      <kbxl-address-autocomplete
        id="origin"
        [(place)]="localFilterCriteria.origin"
        [includeAddress]="false"
        [placeholder]="'Search by City/State/Province'"
      ></kbxl-address-autocomplete>
    </div>
    <div class="col-md-6 kbxl-inputgroup-container">
      <div class="kbxl-field-label">
        <label class="control-label">Destination</label>
      </div>
      <kbxl-address-autocomplete
        [(place)]="localFilterCriteria.dest"
        [includeAddress]="false"
        [placeholder]="'Search by City/State/Province'"
      >
      </kbxl-address-autocomplete>
    </div>

    <div class="col-md-6 kbxl-inputgroup-container">
      <div class="kbxl-field-label">
        <label class="control-label">Users</label>
      </div>
      <div>
        <p-multiSelect
          appendTo="body"
          [options]="shipperUsers$ | async"
          [(ngModel)]="localFilterCriteria.shipperUsers"
          defaultLabel="Select User(s)"
          [styleClass]="'p-dropdown-primary'"
          resetFilterOnHide="true"
          optionLabel="label"
          dataKey="value"
        >
          <ng-template let-selectedItems pTemplate="selectedItems">
            <span *ngIf="selectedItems && selectedItems.length >= 3">{{ selectedItems.length }} Users Selected</span>
            <div *ngIf="selectedItems && selectedItems.length < 3">
              <span *ngFor="let item of selectedItems">{{ item?.label | titlecase }}, </span>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <span>{{ item?.label | titlecase }}</span>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>

    <div class="col-12 text-right">
      <button pButton type="button" (click)="clear()" class="p-button-outlined p-button-secondary mr-3 px-3" label="Clear"></button>
      <button pButton type="button" (click)="search()" class="px-3" label="Search"></button>
    </div>
  </div>
</div>
