import { DefaultPageableResult, PageableResult } from 'src/app/shared/models';
import { ShippingLoadView } from 'src/app/shared/models/shipping-load-view';
import { createReducer, PageableQueryHelper } from 'src/app/shared/utilities';
import { ShippingDeliveredActions, ShippingDeliveredActionTypes } from '../actions';

export interface ShippingDeliveredState {
  loading: boolean;
  deliveredLoads: PageableResult<ShippingLoadView>;
  queryHelper: PageableQueryHelper;
}
const initialState: ShippingDeliveredState = {
  loading: false,
  deliveredLoads: DefaultPageableResult.Create<ShippingLoadView>(),
  queryHelper: PageableQueryHelper.default(),
};

const _ShippingDeliveredReducer = createReducer(
  initialState,
  function (state: ShippingDeliveredState = initialState, action: ShippingDeliveredActions): ShippingDeliveredState {
    switch (action.type) {
      case ShippingDeliveredActionTypes.Load_Shipping_Delivered: {
        return { ...state, loading: true, deliveredLoads: { ...state.deliveredLoads, data: [] } };
      }
      case ShippingDeliveredActionTypes.Load_Shipping_Delivered_Success: {
        return { ...state, loading: false, deliveredLoads: action.payload };
      }
      case ShippingDeliveredActionTypes.Load_Shipping_Delivered_Failure: {
        return { ...state, loading: false };
      }
      case ShippingDeliveredActionTypes.Load_Shipping_Query_Update: {
        return { ...state, queryHelper: action.payload };
      }
      default:
        return state;
    }
  }
);
/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function ShippingDeliveredReducer(state: ShippingDeliveredState, action: ShippingDeliveredActions): ShippingDeliveredState {
  return _ShippingDeliveredReducer(state, action);
}
export const getDeliveredLoading = (state: ShippingDeliveredState) => state.loading;
export const getDeliveredLoads = (state: ShippingDeliveredState) => state.deliveredLoads;
