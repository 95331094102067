<div class="page-container">
  <div class="d-flex flex-column">
    <div class="py-lg-2 px-md-1 px-xl-4 position-relative">
      <kbxl-top-search-criteria
        [criteria]="pagingUiHelper.filter$ | async"
        [equipment]="equipment$ | async | toTreeNodes"
        [states]="states$ | async"
        [isMobile]="isMobile$ | async"
        [isMarketplace]="false"
        [serviceTypes]="serviceTypes$ | async"
        [user]="user$ | async"
        [shippers]="shippers$ | async"
        (search)="search($event)"
        (clear)="clear()"
        [showExport]="true"
        (export)="exportData()"
        (refreshLoads)="search($event)"
      >
      </kbxl-top-search-criteria>
    </div>
    <kbxl-color-bar></kbxl-color-bar>
    <div class="grid-container px-xl-4 px-1">
      <kbxl-load-grid
        [loading]="dashboardLoading$ | async"
        [loads]="loads$ | async"
        (selected)="selected($event)"
        [isBookedLoads]="false"
        [user]="user$ | async"
        (updateVisibility)="updateVisibility($event)"
        [savingLoadId]="savingLoadId$ | async"
        [errorLoadId]="errorLoadId$ | async"
        [phoneError]="phoneError$ | async"
        [truckError]="truckError$ | async"
        [visibilityBadge]="visibilityBadge$ | async"
        [serviceTypes]="serviceTypes$ | async"
        kbxlActionCheck="loadshop.ui.carrier.myloads.viewdelivereddetail"
        enforcementType="disable"
        (isDisabledChange)="setDisabled($event)"
        (updateStatus)="displayUpdateStatus($event)"
        (lazyLoad)="onLazyLoadEvent($event)"
        [lazy]="true"
        [totalRecords]="totalRecords$ | async"
        (documentChange)="pagingUiHelper.reloadData()"
      >
      </kbxl-load-grid>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
