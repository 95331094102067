<kbxl-accessorial-grid
  [loading]="loading$ | async"
  [shipperAccessorials]="globalAccessorials$ | async"
  [error]="saveErrors$ | async"
  [enableAdd]="true"
  headerTitle="Global Accessorials"
  (formDirty)="formDirty = $event"
  (save)="saveGlobal($event)"
  (displayDetail)="displayDetail($event)"
></kbxl-accessorial-grid>
