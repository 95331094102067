import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CarrierScac, UserAdminData } from 'src/app/shared/models';
import { firstBy } from 'thenby';
import { BaseComponent } from '../../../../shared/components';
import {
  AdminState,
  getAllAuthorizedCarrierScacs,
  getAllAuthorizedSecurityRoles,
  getAllAuthorizedShippers,
  getCreateMode,
  getLoadingIdentityUser,
  getLoadingSelectedUser,
  getSavingUser,
  getSelectedUser,
  getUsers,
  UserAdminCreateUserAction,
  UserAdminDeleteUserAction,
  UserAdminLoadAuthorizedEntitiesAction,
  UserAdminLoadIdentityUserAction,
  UserAdminLoadUserAction,
  UserAdminLoadUsersAction,
  UserAdminUpdateUserAction,
} from '../../../store';

@Component({
  selector: 'kbxl-users-container',
  templateUrl: './users-container.component.html',
  styleUrls: ['./users-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersContainerComponent extends BaseComponent implements OnInit {
  constructor(private adminStore: Store<AdminState>) {
    super();
  }

  public createMode$: Observable<boolean>;
  public authorizedShippers$: Observable<SelectItem[]>;
  public authorizedCarrierScacs$: Observable<CarrierScac[]>;
  public authorizedSecurityRoles$: Observable<SelectItem[]>;
  public userResults$: Observable<UserAdminData[]>;
  public updatingUser$: Observable<UserAdminData>;
  public sortedAuthorizedCarrierScacs$: Observable<CarrierScac[]>;
  public processing$: Observable<boolean>;
  public carrierScacsChangedBehavior = new BehaviorSubject<boolean>(true);
  public carrierScacsChanged$ = this.carrierScacsChangedBehavior.asObservable();

  ngOnInit(): void {
    // Populate Dropdown Data
    this.adminStore.dispatch(new UserAdminLoadAuthorizedEntitiesAction());
    this.authorizedShippers$ = this.adminStore.pipe(
      select(getAllAuthorizedShippers),
      map((items) =>
        this.toSelectItems(
          items,
          (x) => x.name,
          (x) => x.customerId
        )
      )
    );

    this.authorizedCarrierScacs$ = this.adminStore.pipe(
      select(getAllAuthorizedCarrierScacs),
      map((_) => this.deepClone(_))
    );

    this.authorizedSecurityRoles$ = this.adminStore.pipe(
      select(getAllAuthorizedSecurityRoles),
      map((items) =>
        this.toSelectItems(
          items,
          (x) => x.accessRoleName,
          (x) => x.accessRoleId
        )
      )
    );

    this.userResults$ = this.adminStore.pipe(select(getUsers));

    this.updatingUser$ = this.adminStore.pipe(
      select(getSelectedUser),
      map((x) => this.deepClone(x))
    );

    this.createMode$ = this.adminStore.pipe(select(getCreateMode));

    this.sortedAuthorizedCarrierScacs$ = combineLatest([this.authorizedCarrierScacs$, this.updatingUser$, this.carrierScacsChanged$]).pipe(
      map((args) => {
        if (args[2]) {
          return args[0].sort(firstBy((x) => x.scac));
          //  return args[0].sort((a, b) => this.carrierSort(a, b, args[1]));
        }

        return args[0];
      })
    );

    this.processing$ = combineLatest([
      this.adminStore.pipe(select(getLoadingSelectedUser)),
      this.adminStore.pipe(select(getSavingUser)),
      this.adminStore.pipe(select(getLoadingIdentityUser)),
    ]).pipe(map((args) => args[0] || args[1] || args[2]));
  }

  searchUsers(query: string) {
    this.adminStore.dispatch(new UserAdminLoadUsersAction({ query }));
  }

  userSelected(user: UserAdminData) {
    this.adminStore.dispatch(new UserAdminLoadUserAction({ userId: user.userId }));
  }

  toSelectItems<T>(items: T[], getLabel: (item: T) => any, getValue: (item: T) => any): SelectItem[] {
    return items.map((item) => ({ label: getLabel(item), value: getValue(item) }));
  }

  carrierScacSelected() {
    this.carrierScacsChangedBehavior.next(true);
  }

  saveUser(payload: { user: UserAdminData; createMode: boolean }) {
    if (payload.createMode) {
      this.adminStore.dispatch(new UserAdminCreateUserAction({ user: payload.user }));
    } else {
      this.adminStore.dispatch(new UserAdminUpdateUserAction({ user: payload.user }));
    }
  }

  loadIdentityUser(username: string) {
    this.adminStore.dispatch(new UserAdminLoadIdentityUserAction({ username }));
  }

  deleteUser(user: UserAdminData) {
    this.adminStore.dispatch(new UserAdminDeleteUserAction(user));
  }
}
