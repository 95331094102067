import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'kbxl-confirm-action-modal',
  templateUrl: './confirm-action-modal.component.html',
  styleUrls: ['./confirm-action-modal.component.scss'],
})
export class ConfirmActionModalComponent {
  commentRequired = false;
  confirmationText: string;
  comment: string;
  breakEvenRate: string;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.commentRequired = config.data.commentRequired;
    this.confirmationText = config.data.confirmationText;
    this.breakEvenRate = config.data.breakEvenRate;
  }

  no(): void {
    this.ref.close({
      confirmed: false,
    });
  }
  yes(): void {
    this.ref.close({
      confirmed: true,
      comment: this.comment,
    });
  }
}
