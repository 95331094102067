import { UserSelectItem } from '../../../shared/models';
import { createReducer } from '../../../shared/utilities';
import { ShipperUserActions, ShipperUserActionTypes } from '../actions';

export interface ShipperUserState {
  loading: boolean;
  entities: UserSelectItem[];
}

const initialState: ShipperUserState = {
  loading: false,
  entities: null,
};

const _shipperUserReducer = createReducer(
  initialState,
  function (state: ShipperUserState = initialState, action: ShipperUserActions): ShipperUserState {
    switch (action.type) {
      case ShipperUserActionTypes.Load: {
        return { ...state, entities: null, loading: true };
      }
      case ShipperUserActionTypes.Load_Success: {
        return { ...state, entities: action.payload, loading: false };
      }
      case ShipperUserActionTypes.Load_Failure: {
        return { ...state, entities: null, loading: false };
      }
      default:
        return state;
    }
  }
);
/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function shipperUserReducer(state: ShipperUserState, action: ShipperUserActions): ShipperUserState {
  return _shipperUserReducer(state, action);
}

export const getLoading = (state: ShipperUserState) => state.loading;
export const getEntities = (state: ShipperUserState) => state.entities;
