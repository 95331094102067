import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ConfirmationService, SelectItem, TreeNode } from 'primeng/api';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import {
  AdminState,
  getAdminUsers,
  getAdminUsersLoading,
  getLoadingSelectedShipper,
  getLoadingShippers,
  getSavingCustomer,
  getSelectedShipper,
  getShippers,
  ShipperProfileAddAction,
  ShipperProfileEnableShipperApiAction,
  ShipperProfileLoadAction,
  ShipperProfileLoadNewAction,
  ShipperProfileLoadShippersAction,
  ShipperProfileUpdateAction,
  UserAdminLoadAdminUsersAction,
} from 'src/app/admin/store';
import {
  CarrierCarrierScacLoadAction,
  CoreState,
  DropDownOptionTypeIds,
  getAllCarrierGroups,
  getCommodities,
  getCustomerLoadTypes,
  getDropDowns,
  getLoadingAllCarrierGroups,
  getLoadingCommodities,
  getLoadingCustomerLoadTypes,
  getLoadingServiceTypes,
  getServiceTypes,
} from 'src/app/core/store';
import {
  CarrierCarrierScacGroup,
  Commodity,
  Customer,
  CustomerLoadType,
  CustomerProfile,
  defaultCustomerProfile,
  DropdownOption,
  ServiceType,
  UserAdminData,
} from 'src/app/shared/models';
import { defaultBrokeredCustomerSettings } from 'src/app/shared/models/brokered-customer-settings';
import { defaultCustomerContact } from 'src/app/shared/models/customer-contact';
import { FuelProgramTypeEnum, FuelProgramTypeOptions, getFuelProgramTypeOptions } from 'src/app/shared/models/FuelProgramType';
import { BaseComponent } from '../../../../shared/components';

@Component({
  selector: 'kbxl-shipper-profile-container',
  templateUrl: './shipper-profile-container.component.html',
  styleUrls: ['./shipper-profile-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipperProfileContainerComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  public shippers$: Observable<Customer[]>;
  public shipper$: Observable<CustomerProfile>;
  public processing$: Observable<boolean>;
  public selectedShipper: Customer;
  public displayShipperMappingsDialog = false;
  public displayShipperEndpointsDialog = false;
  carriers$: Observable<SelectItem[]>;
  commodities$: Observable<Commodity[]>;
  customerLoadTypes$: Observable<CustomerLoadType[]>;
  adminUsers$: Observable<UserAdminData[]>;
  serviceTypes$: Observable<ServiceType[]>;

  carriers: SelectItem[] = [];
  excludedCarriers: SelectItem[] = [];

  multiSelectVisible = false;
  multiSelectOptions: TreeNode[] = [];

  percentOptions$: Observable<DropdownOption[]>;
  intervalOptions$: Observable<DropdownOption[]>;
  fuelProgramTypeOptions$: Observable<FuelProgramTypeOptions[]>;
  importedFuelProgramType = FuelProgramTypeEnum;
  constructor(
    private adminStore: Store<AdminState>,
    private coreStore: Store<CoreState>,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.fuelProgramTypeOptions$ = of(getFuelProgramTypeOptions());
    // Dispatch
    this.adminStore.dispatch(new ShipperProfileLoadShippersAction());
    this.adminStore.dispatch(new UserAdminLoadAdminUsersAction());
    this.coreStore.dispatch(new CarrierCarrierScacLoadAction());

    // Observables
    this.percentOptions$ = this.coreStore.pipe(select(getDropDowns, DropDownOptionTypeIds.DPPercentage));
    this.intervalOptions$ = this.coreStore.pipe(select(getDropDowns, DropDownOptionTypeIds.DPInterval));

    this.shippers$ = this.adminStore.pipe(select(getShippers));
    this.shipper$ = this.adminStore.pipe(
      select(getSelectedShipper),
      map((_) => this.deepClone(_))
    );
    this.carriers$ = this.coreStore.pipe(
      select(getAllCarrierGroups),
      filter((x) => x && x.length > 0),
      map((x) => this.toSelectItem(x))
    );
    this.commodities$ = this.coreStore.pipe(select(getCommodities));
    this.customerLoadTypes$ = this.coreStore.pipe(select(getCustomerLoadTypes));
    this.adminUsers$ = this.adminStore.pipe(select(getAdminUsers));
    this.serviceTypes$ = this.coreStore.pipe(select(getServiceTypes));
    this.processing$ = combineLatest([
      this.adminStore.pipe(select(getLoadingSelectedShipper)),
      this.adminStore.pipe(select(getLoadingShippers)),
      this.adminStore.pipe(select(getSavingCustomer)),
      this.adminStore.pipe(select(getAdminUsersLoading)),
      this.coreStore.pipe(select(getLoadingAllCarrierGroups)),
      this.coreStore.pipe(select(getLoadingCommodities)),
      this.coreStore.pipe(select(getLoadingCustomerLoadTypes)),
      this.coreStore.pipe(select(getLoadingServiceTypes)),
    ]).pipe(map((args) => args[0] || args[1] || args[2] || args[3] || args[4] || args[5] || args[6]));

    // Subscriptions
    combineLatest([this.carriers$, this.shipper$])
      .pipe(
        takeUntil(this.destroyed$),
        map((args) => {
          if (args[1]) {
            return {
              shipperProfile: args[1],
              carrierScacs: args[0].sort((a, b) => this.carrierSort(a, b, args[1].customerCarrierScacs)),
              excludedCarrierScacs: args[0].sort((a, b) => this.carrierSort(a, b, args[1].customerCarrierScacExcluded)),
            };
          }
          return { shipperProfile: args[1], carrierScacs: [], excludedCarrierScacs: [] };
        })
      )
      .subscribe((x) => {
        this.carriers = x.carrierScacs;
        this.excludedCarriers = x.excludedCarrierScacs;

        this.excludedCarrierChange(x.shipperProfile);
      });

    this.route.url.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x.length === 3) {
        const customerId = x[2].toString();
        if (!this.selectedShipper) {
          this.shippers$.pipe(takeUntil(this.destroyed$)).subscribe((shippers) => {
            this.selectedShipper = shippers.find((shipper) => shipper.customerId === customerId);
          });
        }

        this.adminStore.dispatch(
          new ShipperProfileLoadAction({
            customerId: customerId,
          })
        );
      } else if (x.length === 2) {
        this.adminStore.dispatch(new ShipperProfileLoadNewAction({ ...defaultCustomerProfile }));
      }
    });
  }

  loadShipper() {
    this.router.navigate(['maint/shipper-profile/detail', this.selectedShipper.customerId]);
  }

  newShipper() {
    this.router.navigate(['maint/shipper-profile/detail']);
  }

  addContact(s: CustomerProfile) {
    s.customerContacts.push({ ...defaultCustomerContact });
  }

  deleteContact(s: CustomerProfile, i: number) {
    s.customerContacts = s.customerContacts.filter((x, n) => n !== i);
  }

  saveChanges(s: CustomerProfile) {
    const clone = this.deepClone(s);

    clone.inNetworkFlatFee = clone.inNetworkFlatFee || 0;
    clone.inNetworkPercentFee = clone.inNetworkPercentFee || 0;
    clone.inNetworkFeeAdd = clone.inNetworkFeeAdd || false;
    clone.outNetworkFlatFee = clone.outNetworkFlatFee || 0;
    clone.outNetworkPercentFee = clone.outNetworkPercentFee || 0;
    clone.outNetworkFeeAdd = clone.outNetworkFeeAdd || false;
    if (clone.customerId) {
      this.updated(clone);
    } else {
      this.added(clone);
    }
  }

  onAllowZeroFuelChange(event: any, profile: CustomerProfile) {
    if (event.checked) {
      profile.allowEditingFuel = false;
      profile.fuelProgramType = null;
      profile.useFuelRerating = false;
      profile.fuelReratingNumberOfDays = 0;
      profile.fuelProgramType = FuelProgramTypeEnum.None;
    }
  }
  onFuelProgramTypeChange(event: any, profile: CustomerProfile) {
    if (event.value !== FuelProgramTypeEnum.BTF) {
      profile.useFuelRerating = false;
      profile.fuelReratingNumberOfDays = 0;
    }
  }
  brokeredLoadsOnChange(event: any, data: CustomerProfile) {
    if (!data.brokeredLoads) {
      data.brokeredCustomerSettings = this.deepClone(defaultBrokeredCustomerSettings);
    }
  }
  added(s: CustomerProfile) {
    this.adminStore.dispatch(new ShipperProfileAddAction(this.deepClone(s)));
  }

  updated(s: CustomerProfile) {
    this.adminStore.dispatch(new ShipperProfileUpdateAction(this.deepClone(s)));
  }

  cancel(s: CustomerProfile) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to cancel? New Shipper profile will not be saved.',
      accept: () => {
        this.adminStore.dispatch(new ShipperProfileLoadNewAction(null));
        this.selectedShipper = null;
      },
    });
  }

  toSelectItem(groups: CarrierCarrierScacGroup[]): SelectItem[] {
    const selectItems: SelectItem[] = [];
    groups.forEach((x) => {
      x.carrierScacs.forEach((y) => {
        selectItems.push({
          label: x.carrier.carrierName + ' - ' + y.scac,
          value: y.scac,
        });
      });
    });

    return selectItems.sort();
  }

  carrierChange(s: CustomerProfile) {
    this.carriers.sort((a, b) => this.carrierSort(a, b, s.customerCarrierScacs));
  }

  excludedCarrierChange(s: CustomerProfile) {
    if (s) {
      const excludedCarrierScacs = s.customerCarrierScacExcluded;
      this.excludedCarriers.sort((a, b) => this.carrierSort(a, b, excludedCarrierScacs));

      // remove excluded carriers
      s.customerCarrierScacs = s.customerCarrierScacs.filter((x) => !excludedCarrierScacs.includes(x));
      this.carriers = this.carriers.filter((x) => !excludedCarrierScacs.includes(x.value));

      // make sure all available carriers are included
      const availableCarriers = this.excludedCarriers.filter((x) => !excludedCarrierScacs.includes(x.value));
      const availableCarrierScacs = availableCarriers.map((x) => x.value);
      const carrierScacs = this.carriers.map((x) => x.value);

      const carrierScacsToAdd = availableCarrierScacs.filter((x) => !carrierScacs.includes(x));
      this.carriers.push(...availableCarriers.filter((x) => carrierScacsToAdd.includes(x.value)));

      // sort carriers
      this.carrierChange(s);
    }
  }

  carrierSort(a: SelectItem, b: SelectItem, carrierScacs: string[]): number {
    if (carrierScacs && carrierScacs.length > 0) {
      const selectedA = carrierScacs.indexOf(a.value) >= 0;
      const selectedB = carrierScacs.indexOf(b.value) >= 0;

      if (selectedA !== selectedB) {
        return selectedA ? -1 : 1;
      }
    }

    return a.label.localeCompare(b.label);
  }

  viewShipperMappings() {
    this.displayShipperMappingsDialog = true;
  }

  viewShipperEndpoints(): void {
    this.displayShipperEndpointsDialog = true;
  }

  isShipperMappingEligable(s: CustomerProfile): boolean {
    if (s.topsOwnerId && s.customerId && s.identUserSetup) {
      return true;
    }
    return false;
  }

  enableShipperApi(s: CustomerProfile) {
    this.adminStore.dispatch(new ShipperProfileEnableShipperApiAction(s));
  }

  isShipperApiEligable(s: CustomerProfile): boolean {
    if (s.topsOwnerId && s.customerId && !s.identUserSetup) {
      return true;
    }
    return false;
  }
}
