import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { TreeNode } from 'primeng/api';
import { SearchCriteriaService } from 'src/app/load-board/services';
import {
  CustomerProfile,
  defaultLaneAttributes,
  defaultLaneSearch,
  LaneAttribute,
  LaneAttributeType,
  LaneSearch,
  Place,
  ServiceType,
  State,
  UserLane,
} from '../../../../shared/models';
import { formatDate, groupBy } from '../../../../shared/utilities';

@Component({
  selector: 'kbxl-lane-search',
  templateUrl: './lane-search.component.html',
  styleUrls: ['./lane-search.component.scss'],
})
export class LaneSearchComponent implements OnChanges {
  @Input() equipment: TreeNode[];
  @Input() criteria: LaneSearch;
  @Input() lanes: UserLane[];
  @Input() states: State[];
  @Input() isMobile: boolean;
  @Input() serviceTypes: ServiceType[];
  @Input() shipper: CustomerProfile;
  @Output() search: EventEmitter<LaneSearch> = new EventEmitter<LaneSearch>();
  @Output() clear: EventEmitter<string> = new EventEmitter<string>();
  @Output() refreshLanes: EventEmitter<LaneSearch> = new EventEmitter<LaneSearch>();

  crit: LaneSearch = { ...defaultLaneSearch };
  origDate: Date[];
  destDate: Date[];
  origin: Place;
  dest: Place;
  collapsed = true;
  selectedEquipment: TreeNode[];
  quickSearch: string;
  selectedServiceTypes: ServiceType[];
  showAllLoads = false;
  laneAttributes = [];
  selectedLaneAttributes: LaneAttribute[] = [];

  constructor(private searchCriteriaService: SearchCriteriaService) {}

  ngOnChanges(changes: SimpleChanges) {
    // check if shipper is brokered
    if (this.shipper && this.shipper.brokeredLoads) {
      this.laneAttributes = [
        ...defaultLaneAttributes,
        {
          type: LaneAttributeType.EligibleForQuote,
          description: 'Eligible for Quote',
        },
      ];
    } else {
      this.laneAttributes = [...defaultLaneAttributes];
    }
    if (changes.criteria) {
      const crit = this.criteria || { ...defaultLaneSearch };
      this.crit = { ...crit };
      this.quickSearch = this.crit.quickSearch;
      this.origin = null;
      this.dest = null;
      this.updateSelectedEquipment();
      this.setDates(this.crit);
      this.setOriginDestination(this.crit);
      this.setSelectedLaneAttributes(this.crit.laneAttributeTypes);
    }
    if (changes.states) {
      this.setOriginDestination(this.crit);
    }
  }

  setDates(model: LaneSearch) {
    if (!model) {
      this.origDate = null;
      this.destDate = null;
      return;
    }
    if (model.origDateStart) {
      if (model.origDateEnd) {
        this.origDate = [moment(model.origDateStart).toDate(), moment(model.origDateEnd).toDate()];
      } else {
        this.origDate = [moment(model.origDateStart).toDate()];
      }
    } else {
      this.origDate = null;
    }

    if (model.destDateStart) {
      if (model.destDateEnd) {
        this.destDate = [moment(model.destDateStart).toDate(), moment(model.destDateEnd).toDate()];
      } else {
        this.destDate = [moment(model.destDateStart).toDate()];
      }
    } else {
      this.destDate = null;
    }
  }

  setOriginDestination(model: LaneSearch) {
    const originDest = this.searchCriteriaService.setOriginDestination(model, this.states);
    this.origin = originDest[0];
    this.dest = originDest[1];
  }

  runSearch(applying = false) {
    // skip the search if we are in mobile mode and we are not applying from a button
    if (this.isMobile && !applying) {
      return;
    }

    this.setSearchData().then((search) => {
      this.search.emit(search);
      this.collapsed = true;
    });
  }
  searchClick() {
    this.setSearchData().then((search) => {
      this.search.emit(search);
      this.collapsed = true;
    });
  }

  private async setSearchData(): Promise<LaneSearch> {
    // trim the quick search in case the user pastes or adds space
    if (this.quickSearch) {
      this.quickSearch = this.quickSearch.trim();
    }

    const origDate = this.origDate || [];
    const destDate = this.destDate || [];
    const search: LaneSearch = {
      origDateStart: formatDate(origDate[0]),
      origDateEnd: formatDate(origDate[1]),
      destDateStart: formatDate(destDate[0]),
      destDateEnd: formatDate(destDate[1]),
      equipmentType: this.buildSelectedEquipmentAsString(),
      quickSearch: this.quickSearch,
      showAllLoads: this.showAllLoads,
      laneAttributeTypes: this.selectedLaneAttributes.map((_) => _.type),
    };

    if (this.selectedServiceTypes) {
      search.serviceTypes = this.selectedServiceTypes.map((x) => x.serviceTypeId);
    }

    return this.searchCriteriaService
      .setOriginDestinationOnSearch(search, this.origin, this.dest, this.crit.origDH, this.crit.destDH, this.states)
      .then((x: LaneSearch) => x);
  }

  setSelectedLaneAttributes(laneAttributesTypes: LaneAttributeType[]) {
    if (laneAttributesTypes) {
      this.selectedLaneAttributes = this.laneAttributes.filter((_) => laneAttributesTypes.includes(_.type));
    }
  }

  clearClick($event) {
    this.selectedServiceTypes = null;
    this.quickSearch = null;
    this.origin = null;
    this.dest = null;
    this.clear.emit();
    this.collapsed = true;
    this.selectedLaneAttributes = [];
    $event.stopPropagation();
  }

  clearFilter(prop: any) {
    this[prop] = null;
    if (prop === 'quickSearch') {
      this.searchClick();
    } else {
      this.runSearch();
    }
  }

  applyClick($event: Event) {
    this.runSearch(true);
    $event.stopPropagation();
  }

  private updateSelectedEquipment() {
    if (this.crit && this.crit.equipmentType && this.equipment) {
      const flattenTreeNodes = this.equipment.map((_) => _.children).reduce((acc, value) => acc.concat(value));
      const equipmentTypes = JSON.parse(this.crit.equipmentType) as string[];
      const equipmentTreeNodes = equipmentTypes.map((currentEquipmentId) => {
        const equipment = flattenTreeNodes.find((_) => _.data.equipmentId === currentEquipmentId);

        if (equipment && equipment.parent) {
          equipment.parent.partialSelected = true;
        }

        return equipment;
      });

      const groupedSelections = groupBy((x) => x.data.categoryId, equipmentTreeNodes);

      groupedSelections.forEach((group) => {
        const treeViewGroup = this.equipment.find((x) => x.key === group.key);

        if (group.items.length === treeViewGroup.children.length) {
          treeViewGroup.partialSelected = false;
          equipmentTreeNodes.push(treeViewGroup);
        }
      });

      this.selectedEquipment = equipmentTreeNodes;
    } else {
      this.selectedEquipment = [];
    }
  }

  private buildSelectedEquipment() {
    return this.selectedEquipment.filter((_) => _.leaf).map((_) => _.data.equipmentId);
  }

  private buildSelectedEquipmentAsString() {
    const selectedEquipment = this.buildSelectedEquipment();
    if (selectedEquipment.length > 0) {
      return JSON.stringify(this.buildSelectedEquipment());
    }

    return null;
  }
}
