import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class TimeZoneIntercepter implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.apiUrl)) {
      if (req.method === 'POST' || req.method === 'PUT') {
        req = req.clone({
          params: (req.params ? req.params : new HttpParams()).set('timeZoneOffset', new Date().getTimezoneOffset().toLocaleString()),
        });
      }
    }

    return next.handle(req);
  }
}
