import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { mapToPayload } from '@tms-ng/shared';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CarrierProfile } from 'src/app/shared/models';
import { CarrierProfileService } from '../../services';
import {
  CarrierProfileActionTypes,
  CarrierProfileLoadAction,
  CarrierProfileLoadAllFailureAction,
  CarrierProfileLoadAllSuccessAction,
  CarrierProfileLoadFailureAction,
  CarrierProfileLoadSuccessAction,
  CarrierProfileUpdateAction,
  CarrierProfileUpdateFailureAction,
  CarrierProfileUpdateSuccessAction,
} from '../actions';

@Injectable()
export class CarrierProfileEffects {
  @Effect()
  $loadCarrierProfile: Observable<Action> = this.actions$.pipe(
    ofType<CarrierProfileLoadAction>(CarrierProfileActionTypes.Load),
    mapToPayload<{ carrierId: string }>(),
    switchMap((payload: { carrierId: string }) =>
      this.carrierProfileService.getCarrier(payload.carrierId).pipe(
        map((data) => new CarrierProfileLoadSuccessAction(data)),
        catchError((err) => of(new CarrierProfileLoadFailureAction(err)))
      )
    )
  );

  @Effect()
  $updateCarrierProfile: Observable<Action> = this.actions$.pipe(
    ofType<CarrierProfileUpdateAction>(CarrierProfileActionTypes.Update),
    mapToPayload<CarrierProfile>(),
    switchMap((payload: CarrierProfile) =>
      this.carrierProfileService.updateCarrier(payload).pipe(
        map((data) => new CarrierProfileUpdateSuccessAction(data)),
        catchError((err) => of(new CarrierProfileUpdateFailureAction(err)))
      )
    ),
    tap((action: CarrierProfileUpdateSuccessAction) =>
      this.messageService.add({ id: 0, detail: `${this.titleCasePipe.transform(action.payload.carrierName)} Updated`, severity: 'success' })
    )
  );

  @Effect()
  $loadAllCarriers: Observable<Action> = this.actions$.pipe(
    ofType<CarrierProfileLoadAllFailureAction>(CarrierProfileActionTypes.Load_All),
    switchMap(() =>
      this.carrierProfileService.getAllCarriers().pipe(
        map((data) => new CarrierProfileLoadAllSuccessAction(data)),
        catchError((err) => of(new CarrierProfileLoadAllFailureAction(err)))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private carrierProfileService: CarrierProfileService,
    private messageService: MessageService,
    private titleCasePipe: TitleCasePipe
  ) {}
}
