import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CanDeactivateGuard } from '../core/guards/can-deactivate.guard';
import { SpotPriceModalComponent } from '../shared/components/spot-price-modal/spot-price-modal.component';
import { SharedModule } from '../shared/shared.module';
import {
  LoadCreateSuccessModalComponent,
  LoadDynamicPricingContainerComponent,
  ShippingBookedContainerComponent,
  ShippingDeliveredContainerComponent,
  ShippingGridComponent,
  ShippingLaneHistoryDisplayComponent,
  ShippingLoadCardsComponent,
  ShippingLoadCreateContainerComponent,
  ShippingLoadDetailComponent,
  ShippingLoadFilterComponent,
  ShippingLoadHomeContainerComponent,
  ShippingLoadHomeHeaderComponent,
  ShippingLoadRateIncreaseConfirmActionComponent,
  ShippingPostedContainerComponent,
  ShippingSearchCriteriaComponent,
} from './components';
import { ShipperCounterOfferCarrierSelectionComponent } from './components/shipper-counter-offer-carrier-selection/shipper-counter-offer-carrier-selection.component';
import { RatingService, ShippingService } from './services';
import { ShippingRoutingModule } from './shipping-routing.module';
import { effects, reducers } from './store';

@NgModule({
  imports: [ShippingRoutingModule, SharedModule, EffectsModule.forFeature(effects), StoreModule.forFeature('shipping', reducers)],
  declarations: [
    ShippingLoadDetailComponent,
    ShippingPostedContainerComponent,
    ShippingBookedContainerComponent,
    ShippingDeliveredContainerComponent,
    ShippingGridComponent,
    ShippingLoadHomeContainerComponent,
    ShippingLoadCardsComponent,
    ShippingLoadHomeHeaderComponent,
    ShippingLoadFilterComponent,
    ShippingLoadCreateContainerComponent,
    ShippingSearchCriteriaComponent,
    LoadDynamicPricingContainerComponent,
    LoadCreateSuccessModalComponent,
    ShippingLaneHistoryDisplayComponent,
    ShippingLoadRateIncreaseConfirmActionComponent,
    ShipperCounterOfferCarrierSelectionComponent,
    SpotPriceModalComponent,
  ],
  entryComponents: [ShippingLoadRateIncreaseConfirmActionComponent, ShipperCounterOfferCarrierSelectionComponent],
  providers: [ShippingService, RatingService, CanDeactivateGuard],
})
export class ShippingModule {}
