import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionItemStatusData, CounterOfferActionItemLoadView } from '../../models';
import { PageableQueryHelper } from '../../utilities';
import { createReducer } from '../../utilities/create-reducer';
import { CounterOfferActionItemActions, CounterOfferActionItemActionTypes } from '../actions';

export interface CounterOfferActionItemState extends EntityState<CounterOfferActionItemLoadView> {
  loadingFilterCriteria: boolean;
  loading: boolean;
  actionItemStatuses: ActionItemStatusData;
  queryHelper: PageableQueryHelper;
  totalRecords: number;
}

export const adapter: EntityAdapter<CounterOfferActionItemLoadView> = createEntityAdapter<CounterOfferActionItemLoadView>({
  selectId: (x) => x.loadId,
});

const initialState: CounterOfferActionItemState = adapter.getInitialState({
  loading: false,
  loadingFilterCriteria: false,
  actionItemCriteria: null,
  actionItemStatuses: null,
  queryHelper: PageableQueryHelper.default(),
  totalRecords: 0,
});

const _actionItemReducer = createReducer(
  initialState,
  function (state: CounterOfferActionItemState = initialState, action: CounterOfferActionItemActions): CounterOfferActionItemState {
    switch (action.type) {
      case CounterOfferActionItemActionTypes.LoadFilters: {
        return { ...state, loadingFilterCriteria: true, actionItemStatuses: null };
      }
      case CounterOfferActionItemActionTypes.LoadFilters_Success: {
        return {
          ...state,
          loadingFilterCriteria: false,
          actionItemStatuses: action.payload,
        };
      }
      case CounterOfferActionItemActionTypes.LoadFilters_Failure: {
        return { ...state, loadingFilterCriteria: false };
      }
      case CounterOfferActionItemActionTypes.Load_ActionItems: {
        return { ...state, loading: true };
      }
      case CounterOfferActionItemActionTypes.Load_ActionItems_Failure: {
        return { ...state, loading: false };
      }
      case CounterOfferActionItemActionTypes.Load_ActionItems_Success: {
        return adapter.setAll(action.payload.data, { ...state, loading: false, totalRecords: action.payload.totalRecords });
      }
      case CounterOfferActionItemActionTypes.Update_ActionItems_Query: {
        return { ...state, queryHelper: action.payload };
      }
      default:
        return state;
    }
  },
  { userEntityReset: true }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function counterOfferActionItemReducer(
  state: CounterOfferActionItemState,
  action: CounterOfferActionItemActions
): CounterOfferActionItemState {
  return _actionItemReducer(state, action);
}

export const selectors = adapter.getSelectors();
