<kbxl-admin-header></kbxl-admin-header>
<div class="grid-container">
  <kbxl-accessorial-detail
    [loading]="loading$ | async"
    [shipperAccessorials]="shipperAccessorials$ | async"
    [globalAccessorials]="globalAccessorials$ | async"
    [accessorials]="accessorials$ | async"
    [accessorialUoM]="accessorialUoM$ | async"
    [accessorial]="accessorial$ | async"
    [enableUoM]="enableUoM"
    [error]="saveErrors$ | async"
    (saveAccessorial)="saveAccessorial($event)"
    (cancel)="cancel($event)"
  >
  </kbxl-accessorial-detail>
</div>
<router-outlet></router-outlet>
