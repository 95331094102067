import { ValidationProblemDetails } from 'src/app/shared/models';
import { defaultOrderEntry, OrderEntryFormDisplay } from 'src/app/shared/models/order-entry-form';
import { createReducer } from '../../../shared/utilities';
import { OrderEntryActions, OrderEntryActionTypes } from '../actions';

export interface OrderEntryState {
  loading: boolean;
  problemDetails: ValidationProblemDetails;
  orderEntryForm: OrderEntryFormDisplay;
  savedId: string;
  savedRefId: string;
  isExternalLoad: boolean;
}

const initialState: OrderEntryState = {
  loading: false,
  problemDetails: null,
  orderEntryForm: defaultOrderEntry,
  savedId: null,
  savedRefId: null,
  isExternalLoad: false,
};

const _OrderEntryReducer = createReducer(
  initialState,
  function (state: OrderEntryState = initialState, action: OrderEntryActions): OrderEntryState {
    switch (action.type) {
      case OrderEntryActionTypes.Get_Load:
      case OrderEntryActionTypes.Copy_Load: {
        return { ...state, loading: true };
      }
      case OrderEntryActionTypes.Get_Load_Success:
      case OrderEntryActionTypes.Copy_Load_Success: {
        return { ...state, orderEntryForm: action.payload, loading: false };
      }
      case OrderEntryActionTypes.Get_Load_Failure:
      case OrderEntryActionTypes.Copy_Load_Failure: {
        return { ...state, loading: false, problemDetails: action.payload.error };
      }

      case OrderEntryActionTypes.Create_Load: {
        return { ...state, loading: true };
      }
      case OrderEntryActionTypes.Create_Load_Success: {
        return {
          ...state,
          ...initialState,
          savedId: action.payload.loadId,
          savedRefId: action.payload.referenceLoadId,
          isExternalLoad: action.payload.isExternalLoad,
        };
      }
      case OrderEntryActionTypes.Create_Load_Failure: {
        return { ...state, loading: false, problemDetails: action.payload.error };
      }

      case OrderEntryActionTypes.Update_Load: {
        return { ...state, loading: true };
      }
      case OrderEntryActionTypes.Update_Load_Success: {
        if (action.resetState) {
          return {
            ...state,
            ...initialState,
            savedId: action.payload.loadId,
            savedRefId: action.payload.referenceLoadId,
            isExternalLoad: action.payload.isExternalLoad,
          };
        } else {
          return {
            ...state,
            savedId: action.payload.loadId,
            savedRefId: action.payload.referenceLoadId,
            isExternalLoad: action.payload.isExternalLoad,
          };
        }
      }
      case OrderEntryActionTypes.Update_Load_Failure: {
        return { ...state, loading: false, problemDetails: action.payload.error };
      }

      case OrderEntryActionTypes.Clear_Errors: {
        return { ...state, problemDetails: initialState.problemDetails };
      }
      case OrderEntryActionTypes.Reset_Saved: {
        return {
          ...state,
          savedId: initialState.savedId,
          savedRefId: initialState.savedRefId,
          isExternalLoad: initialState.isExternalLoad,
        };
      }
      default:
        return state;
    }
  }
);
/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function OrderEntryReducer(state: OrderEntryState, action: OrderEntryActions): OrderEntryState {
  return _OrderEntryReducer(state, action);
}
export const getLoading = (state: OrderEntryState) => state.loading;
export const getForm = (state: OrderEntryState) => state.orderEntryForm;
export const getProblemDetails = (state: OrderEntryState) => state.problemDetails;
export const getSavedId = (state: OrderEntryState) => state.savedId;
export const getSavedRefId = (state: OrderEntryState) => state.savedRefId;
export const getSavedIsExternalLoad = (state: OrderEntryState) => state.isExternalLoad;
