export interface ActionItemLoadView {
  customerId: string;
  loadId: string;
  referenceLoadDisplay: string;
  originLat: number;
  originLng: number;
  originCity: string;
  originState: string;
  originEarlyDtTm: Date;
  originLateDtTm: Date;
  destLat: number;
  destLng: number;
  destCity: string;
  destState: string;
  destEarlyDtTm: Date;
  destLateDtTm: Date;
  equipmentId: string;
  equipmentCategoryId: string;
  equipmentTypeDisplay: string;
  fuelRate: number;
  usesAllInRates: boolean;
  latestLoadPricingRuleId: number;
  isHazMat: boolean;
  bookedLineHaulRate: number;
  lineHaulRate: number;
  weight: number;
  commodity: string;
  miles: number;
  scac: string;
  billingLoadDisplay: string;
  customerLoadTypeId: number;
  currentStatus: string;
  actionItemStatus: string;
  // holds enum value, used for default sort
  actionItemStatusValue: string;
  carrierSortOrder: number;

  isBrokeredLoad: boolean;
  totalRate: number;
  latestTranasctionTypeId: string;
  isEligibleToViewDetaislOfLoad: boolean;
}

export const defaultActionItemLoadView: ActionItemLoadView = {
  customerId: null,
  loadId: null,
  referenceLoadDisplay: null,
  originLat: null,
  originLng: null,
  originCity: null,
  originState: null,
  originEarlyDtTm: null,
  originLateDtTm: null,
  destLat: null,
  destLng: null,
  destCity: null,
  destState: null,
  destEarlyDtTm: null,
  destLateDtTm: null,
  equipmentId: null,
  equipmentCategoryId: null,
  equipmentTypeDisplay: null,
  miles: null,
  scac: null,
  billingLoadDisplay: null,
  customerLoadTypeId: null,
  currentStatus: null,
  actionItemStatus: null,
  actionItemStatusValue: null,
  fuelRate: null,
  usesAllInRates: false,
  latestLoadPricingRuleId: null,
  isHazMat: null,
  bookedLineHaulRate: null,
  lineHaulRate: null,
  weight: null,
  commodity: null,
  carrierSortOrder: 0,
  isBrokeredLoad: false,
  totalRate: null,
  latestTranasctionTypeId: null,
  isEligibleToViewDetaislOfLoad: false,
};
