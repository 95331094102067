<div class="page-container">
  <div class="d-none d-lg-block">
    <p-panel styleClass="no-padding">
      <p-table
        [value]="actionItemLoads"
        #table
        styleClass="p-datatable-no-border"
        [loading]="loading"
        selectionMode="single"
        [paginator]="true"
        [rows]="50"
        [rowsPerPageOptions]="[10, 25, 50, 100]"
        [first]="first"
        (onPage)="onPage($event)"
        [lazy]="lazy"
        [totalRecords]="totalRecords"
        (onLazyLoad)="onLazyLoad($event)"
      >
        <ng-template pTemplate="header">
          <tr>
            <th [pSortableColumn]="'actionItemStatus'">
              Action Item Status
              <p-sortIcon [field]="'actionItemStatus'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'referenceLoadDisplay'">
              Shipper’s Order Number
              <p-sortIcon [field]="'referenceLoadDisplay'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'billingLoadDisplay'">
              Billing Load ID
              <p-sortIcon [field]="'billingLoadDisplay'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'originCity'">
              Origin
              <p-sortIcon [field]="'originCity'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'destCity'">
              Destination
              <p-sortIcon [field]="'destCity'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'originLateDtTm'">
              Pickup
              <p-sortIcon [field]="'originLateDtTm'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'destLateDtTm'">
              Delivery
              <p-sortIcon [field]="'destLateDtTm'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'equipmentCategoryId'">
              Equipment
              <p-sortIcon [field]="'equipmentCategoryId'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'equipmentTypeDisplay'">
              Equipment Detail
              <p-sortIcon [field]="'equipmentTypeDisplay'"></p-sortIcon>
            </th>
            <th>Action Item</th>
            <th [pSortableColumn]="'scac'" style="width: 90px">
              SCAC
              <p-sortIcon [field]="'scac'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'currentStatus'">
              Load Status
              <p-sortIcon [field]="'currentStatus'"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-actionItemLoad let-rowIndex="rowIndex">
          <tr (click)="onRowSelect(actionItemLoad)" [pSelectableRow]="actionItemLoad">
            <td>
              {{ actionItemLoad.actionItemStatus }}
            </td>
            <td>{{ actionItemLoad.referenceLoadDisplay }}</td>
            <td>{{ actionItemLoad.billingLoadDisplay }}</td>
            <td>{{ actionItemLoad.originCity | titlecase }}, {{ actionItemLoad.originState }}</td>
            <td>{{ actionItemLoad.destCity | titlecase }}, {{ actionItemLoad.destState }}</td>
            <td>
              <span *ngIf="actionItemLoad.originEarlyDtTm && actionItemLoad.originEarlyDtTm !== actionItemLoad.originLateDtTm">
                {{ actionItemLoad.originEarlyDtTm | date: 'short' }}
                <br />{{ actionItemLoad.originLateDtTm | date: 'short' }}
              </span>
              <span *ngIf="!actionItemLoad.originEarlyDtTm || actionItemLoad.originEarlyDtTm === actionItemLoad.originLateDtTm">
                {{ actionItemLoad.originLateDtTm | date: 'short' }}
              </span>
            </td>
            <td>
              <span *ngIf="actionItemLoad.destEarlyDtTm && actionItemLoad.destEarlyDtTm !== actionItemLoad.destLateDtTm">
                {{ actionItemLoad.destEarlyDtTm | date: 'short' }}
                <br />{{ actionItemLoad.destLateDtTm | date: 'short' }}
              </span>
              <span *ngIf="!actionItemLoad.destEarlyDtTm || actionItemLoad.destEarlyDtTm === actionItemLoad.destLateDtTm">
                {{ actionItemLoad.destLateDtTm | date: 'short' }}
              </span>
            </td>
            <td>
              {{ actionItemLoad.equipmentCategoryId }}
            </td>
            <td>
              {{ actionItemLoad.equipmentTypeDisplay }}
            </td>
            <td>
              <button
                pButton
                type="button"
                (click)="executeAction(actionItemLoad)"
                class="p-button-outlined p-button-secondary"
                [label]="getActionItemText(actionItemLoad)"
                kbxlStopClickPropagation
              ></button>
            </td>
            <td>
              {{ actionItemLoad.scac }}
            </td>
            <td>
              {{ actionItemLoad.currentStatus }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="numberOfColumns" style="text-align: center; height: 80px">
              <span *ngIf="loading">Loading...</span>
              <span *ngIf="!loading">No results</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-panel>
  </div>
  <div class="d-lg-none">
    <div class="loading-indicator" *ngIf="loading">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <h4 *ngIf="!loading && actionItemLoads?.length == 0" class="p-4 text-center">No results</h4>
    <div class="load-item d-flex flex-column" *ngFor="let load of actionItemLoads; let i = index">
      <a (click)="onRowSelect(load)">
        <p-card styleClass="load-grid-card">
          <p-header>
            <div class="d-flex flex-column">
              <div class="d-flex flex-wrap align-items-center">
                <div class="flex-grow-1">
                  <div class="trip-origin">
                    <div class="trip-marker-icon">
                      <fa-icon [icon]="['far', 'circle']" size="md"></fa-icon>
                    </div>
                    <span class="trip-text">{{ load.originCity | titlecase }}, {{ load.originState }}</span>
                  </div>
                  <div class="trip-ellipsis-icon">
                    <fa-icon [icon]="['fas', 'ellipsis-v']" size="md"></fa-icon>
                  </div>
                  <div class="trip-destination">
                    <div class="trip-marker-icon">
                      <fa-icon [icon]="['fas', 'map-marker-alt']" size="md"></fa-icon>
                    </div>
                    <span class="trip-text">{{ load.destCity | titlecase }}, {{ load.destState }}</span>
                  </div>
                </div>
                <div>
                  <p class="all-in-rate-value">{{ (load.bookedLineHaulRate || load.lineHaulRate) + load.fuelRate | currency }}</p>
                  <p class="all-in-rate-label text-right">Total Rate</p>
                </div>
              </div>
            </div>
          </p-header>
          <div class="d-flex flex-column load-item-detail">
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Shipper’s Order Number</span>
              </div>
              <div>
                <span>{{ load.referenceLoadDisplay }}</span>
              </div>
            </div>
            <div *ngIf="isBookedLoads || isDeliveredLoads" class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Billing Load ID</span>
              </div>
              <div>
                <span>{{ load.billingLoadDisplay }}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Pickup</span>
              </div>
              <div>
                <span *ngIf="load.originEarlyDtTm && load.originEarlyDtTm !== load.originLateDtTm">
                  {{ load.originEarlyDtTm | date: 'short' }} - {{ load.originLateDtTm | date: 'short' }}
                </span>
                <span *ngIf="!load.originEarlyDtTm || load.originEarlyDtTm === load.originLateDtTm">
                  {{ load.originLateDtTm | date: 'short' }}
                </span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Delivery</span>
              </div>
              <div>
                <span *ngIf="load.destEarlyDtTm && load.destEarlyDtTm !== load.destLateDtTm">
                  {{ load.destEarlyDtTm | date: 'short' }} - {{ load.destLateDtTm | date: 'short' }}
                </span>
                <span *ngIf="!load.destEarlyDtTm || load.destEarlyDtTm === load.destLateDtTm">
                  {{ load.destLateDtTm | date: 'short' }}
                </span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Distance</span>
              </div>
              <div>
                <span>{{ load.miles }}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Equipment</span>
              </div>
              <div>
                <span>{{ load.equipmentCategoryId }} - {{ load.equipmentTypeDisplay }}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center py-2">
              <div class="flex-grow-1">
                <span>Action</span>
              </div>
              <div>
                <button
                  pButton
                  type="button"
                  (click)="executeAction(load)"
                  class="p-button-outlined p-button-secondary"
                  [label]="getActionItemText(load)"
                  kbxlStopClickPropagation
                ></button>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>SCAC</span>
              </div>
              <div>
                <span>{{ load.scac }}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Load Status</span>
              </div>
              <div>
                <span>{{ load.currentStatus }}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="flex-grow-1">
                <span>Action Item Status</span>
              </div>
              <div>
                <span>{{ load.actionItemStatus }}</span>
              </div>
            </div>
          </div>
        </p-card>
      </a>
    </div>
  </div>
</div>
