/**
 * HubMessage.Api
 * Simplified wrapper API for connecting with the hub service.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ConversationStatus } from '../model/conversationStatus';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PublicConversationService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param userId 
     * @param filterByUserId 
     * @param conversationId 
     * @param conversationIds 
     * @param statuses 
     * @param applicationId 
     * @param attributes 
     * @param attributesAsString 
     * @param startDate 
     * @param endDate 
     * @param optionsIncludeLatestMessage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public v1PublicConversationGet(userId?: string, filterByUserId?: boolean, conversationId?: number, conversationIds?: Array<number>, statuses?: Array<ConversationStatus>, applicationId?: number, attributes?: { [key: string]: Array<string>; }, attributesAsString?: string, startDate?: Date, endDate?: Date, optionsIncludeLatestMessage?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public v1PublicConversationGet(userId?: string, filterByUserId?: boolean, conversationId?: number, conversationIds?: Array<number>, statuses?: Array<ConversationStatus>, applicationId?: number, attributes?: { [key: string]: Array<string>; }, attributesAsString?: string, startDate?: Date, endDate?: Date, optionsIncludeLatestMessage?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public v1PublicConversationGet(userId?: string, filterByUserId?: boolean, conversationId?: number, conversationIds?: Array<number>, statuses?: Array<ConversationStatus>, applicationId?: number, attributes?: { [key: string]: Array<string>; }, attributesAsString?: string, startDate?: Date, endDate?: Date, optionsIncludeLatestMessage?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public v1PublicConversationGet(userId?: string, filterByUserId?: boolean, conversationId?: number, conversationIds?: Array<number>, statuses?: Array<ConversationStatus>, applicationId?: number, attributes?: { [key: string]: Array<string>; }, attributesAsString?: string, startDate?: Date, endDate?: Date, optionsIncludeLatestMessage?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('UserId', <any>userId);
        }
        if (filterByUserId !== undefined && filterByUserId !== null) {
            queryParameters = queryParameters.set('FilterByUserId', <any>filterByUserId);
        }
        if (conversationId !== undefined && conversationId !== null) {
            queryParameters = queryParameters.set('ConversationId', <any>conversationId);
        }
        if (conversationIds) {
            conversationIds.forEach((element) => {
                queryParameters = queryParameters.append('ConversationIds', <any>element);
            })
        }
        if (statuses) {
            statuses.forEach((element) => {
                queryParameters = queryParameters.append('Statuses', <any>element);
            })
        }
        if (applicationId !== undefined && applicationId !== null) {
            queryParameters = queryParameters.set('ApplicationId', <any>applicationId);
        }
        if (attributes !== undefined && attributes !== null) {
            queryParameters = queryParameters.set('Attributes', <any>attributes);
        }
        if (attributesAsString !== undefined && attributesAsString !== null) {
            queryParameters = queryParameters.set('AttributesAsString', <any>attributesAsString);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('StartDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('EndDate', <any>endDate.toISOString());
        }
        if (optionsIncludeLatestMessage !== undefined && optionsIncludeLatestMessage !== null) {
            queryParameters = queryParameters.set('Options.IncludeLatestMessage', <any>optionsIncludeLatestMessage);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/v1/PublicConversation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
