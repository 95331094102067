<div class="row" kbxlActionCheck="loadshop.ui.myloads.documents.view">
  <div class="col-12">
    <div class="d-flex justify-content-between align-items-center">
      <label class="load-detail-header"> Documents </label>
    </div>
  </div>
  <div class="col-12">
    <ng-content></ng-content>
  </div>
  <div class="col-12" *ngIf="!isReadOnly">
    <div class="d-flex">
      <button
        pButton
        icon="pi pi-plus"
        type="button"
        class="p-button-teal ml-auto"
        label="Add Document"
        (click)="addDocument()"
        [kbxlActionCheck]="'loadshop.ui.carrier.myloads.documents.attach'"
        enforcementType="hide"
      ></button>
    </div>
  </div>
  <div class="col-12 text-center text-muted mt-3" *ngIf="!documents || documents.length === 0">
    <span>No documents</span>
  </div>

  <div class="col-12 mt-2" *ngIf="documents && documents.length > 0">
    <div class="d-flex flex-column" kbxlActionCheck="loadshop.ui.myloads.documents.view">
      <div class="mb-2" *ngFor="let doc of documents">
        <kbxl-document-list-item
          [document]="doc"
          [isReadOnly]="isDocumentReadOnly(doc)"
          (removeDocument)="deleteDocument($event)"
        ></kbxl-document-list-item>
      </div>
    </div>
  </div>
</div>
<p-dialog
  *ngIf="showUploadDocumentModal"
  [style]="{ width: '600px' }"
  [appendTo]="'body'"
  [baseZIndex]="5500"
  header="Upload Document"
  [(visible)]="showUploadDocumentModal"
  [modal]="true"
  [closeOnEscape]="true"
>
  <kbxl-upload-document-modal [documentTypes]="loadDocumentTypes$ | async" [loadId]="loadId" (close)="uploadModalClosed($event)">
  </kbxl-upload-document-modal>
</p-dialog>
