import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadStop } from '../../models';

@Component({
  selector: 'kbxl-trip-display',
  templateUrl: './trip-display.component.html',
  styleUrls: ['./trip-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripDisplayComponent {
  @Input() loadStops: LoadStop[];

  constructor() {}

  get tripOrigin(): LoadStop {
    return this.loadStops && this.loadStops.length > 0 ? this.loadStops[0] : null;
  }

  get tripDestination(): LoadStop {
    return this.loadStops.length > 1 ? this.loadStops[this.loadStops.length - 1] : null;
  }
}
