import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { cloneDeep } from 'lodash';
import { ValidationProblemDetails } from 'src/app/shared/models/validation-problem-details';
import { IShippingLoadDetail, ShippingLoadSupplementalData } from '../../../shared/models';
import { createReducer } from '../../../shared/utilities';
import { ShippingLoadDetailActions, ShippingLoadDetailActionTypes } from '../actions';

export interface ShippingLoadDetailState extends EntityState<IShippingLoadDetail> {
  loading: boolean; // TODO: Switch to a counter for in progress calls?
  loadingLatestTransaction: boolean;
  postValidationProblemDetails: ValidationProblemDetails;
  postedLoads: IShippingLoadDetail[];
  loadsSupplementalLoading: { [s: string]: boolean };
  loadsSupplementalData: { [s: string]: ShippingLoadSupplementalData };
}

export const adapter: EntityAdapter<IShippingLoadDetail> = createEntityAdapter<IShippingLoadDetail>({
  selectId: (x) => x.loadId,
});

const initialState: ShippingLoadDetailState = adapter.getInitialState({
  loading: false,
  loadingLatestTransaction: false,
  postValidationProblemDetails: null,
  postedLoads: null,
  loadsSupplementalLoading: {},
  loadsSupplementalData: {},
});

const deleteLoadId = (loadId: string, { [loadId]: _, ...state }) => state;
const _ShippingLoadDetailReducer = createReducer(
  initialState,
  function (state: ShippingLoadDetailState = initialState, action: ShippingLoadDetailActions): ShippingLoadDetailState {
    switch (action.type) {
      case ShippingLoadDetailActionTypes.Load_All: {
        return { ...state, loading: true, postValidationProblemDetails: null };
      }
      case ShippingLoadDetailActionTypes.Load_All_Success: {
        let selectedLoadIds = [];
        const selectedLoads = getLoads(state).filter((x) => x.selected);
        if (selectedLoads) {
          selectedLoadIds = selectedLoads.map((x) => x.loadId);
        }

        state = adapter.setAll(action.payload, { ...state, loading: false });
        if (selectedLoadIds) {
          selectedLoadIds.forEach((x) => {
            state = adapter.updateOne({ id: x, changes: { selected: true } }, { ...state });
          });
        }

        return state;
      }
      case ShippingLoadDetailActionTypes.Load_All_Failure: {
        return adapter.removeAll({ ...state, entity: null, loading: false });
      }
      case ShippingLoadDetailActionTypes.Load_Supplemental: {
        return {
          ...state,
          loadsSupplementalLoading: { ...state.loadsSupplementalLoading, ...{ [action.payload.loadId]: true } },
          loadsSupplementalData: deleteLoadId(action.payload.loadId, state.loadsSupplementalData),
        };
      }
      case ShippingLoadDetailActionTypes.Load_Supplemental_Success: {
        return adapter.updateOne(
          {
            id: action.payload.loadId,
            changes: {
              latestTransactionTypeId: action.payload.data.latestTransactionTypeId,
              hasPrefetchedRates: action.payload.data.carrierScacs?.length > 0,
            },
          },
          {
            ...state,
            loadingLatestTransaction: false,
            loadsSupplementalLoading: { ...state.loadsSupplementalLoading, ...{ [action.payload.loadId]: false } },
            loadsSupplementalData: { ...state.loadsSupplementalData, ...{ [action.payload.loadId]: action.payload.data } },
          }
        );
      }
      case ShippingLoadDetailActionTypes.Load_Supplemental_Failure: {
        // Failure to load details for load, clear loading indicators and unselect load
        const loadId = action.payload.data.toString();
        const supplementalData = { ...state.loadsSupplementalData[loadId], isLoadCarrierScacPrefetchProcessing: false };
        return adapter.updateOne(
          { id: loadId, changes: { selected: false } },
          {
            ...state,
            loadingLatestTransaction: false,
            loadsSupplementalData: { ...state.loadsSupplementalData, ...{ [loadId]: supplementalData } },
            loadsSupplementalLoading: { ...state.loadsSupplementalLoading, ...{ [loadId]: false } },
          }
        );
      }
      case ShippingLoadDetailActionTypes.Load_Contract_Rates_Success: {
        if (!action.payload.data.isProcessing) {
          // only set isProcessing + carrierScacs if done processing
          const supplementalData = {
            ...state.loadsSupplementalData[action.payload.loadId],
            isLoadCarrierScacPrefetchProcessing: action.payload.data.isProcessing,
            carrierScacs: action.payload.data.carrierScacs,
          };

          return adapter.updateOne(
            { id: action.payload.loadId, changes: { hasPrefetchedRates: action.payload.data.carrierScacs?.length > 0 } },
            { ...state, loadsSupplementalData: { ...state.loadsSupplementalData, ...{ [action.payload.loadId]: supplementalData } } }
          );
        } else {
          return state;
        }
      }
      case ShippingLoadDetailActionTypes.Load_Contract_Rates_Failure: {
        const supplementalData = { ...state.loadsSupplementalData[action.payload.loadId], isLoadCarrierScacPrefetchProcessing: false };
        return { ...state, loadsSupplementalData: { ...state.loadsSupplementalData, ...{ [action.payload.loadId]: supplementalData } } };
      }
      case ShippingLoadDetailActionTypes.Post_Loads: {
        return { ...state, loading: true, postedLoads: null, postValidationProblemDetails: null };
      }
      case ShippingLoadDetailActionTypes.Post_Loads_Success: {
        /**
         * Update all posted loads on the Shipping Detail screen and save any present
         * validation problem details to state, so that it can be used to display errors
         * on the individual loads.
         */
        const postedLoads = action.payload.postedLoads;
        const validationProblems = action.payload.validationProblemDetails;
        const nextState = {
          ...state,
          loading: false,
          postValidationProblemDetails: validationProblems ? validationProblems : null,
          postedLoads: postedLoads && postedLoads.length ? postedLoads : null,
        };

        if (postedLoads && postedLoads.length > 0) {
          return adapter.upsertMany(postedLoads, nextState);
        } else {
          return nextState;
        }
      }
      case ShippingLoadDetailActionTypes.Post_Loads_Failure: {
        return { ...state, loading: false };
      }
      case ShippingLoadDetailActionTypes.Remove_Load: {
        return { ...state, loading: true };
      }
      case ShippingLoadDetailActionTypes.Remove_Load_Success: {
        const data = cloneDeep(action.payload);
        delete data.loadStops;
        return adapter.updateOne({ id: data.loadId, changes: { ...data } }, { ...state, loading: false });
      }
      case ShippingLoadDetailActionTypes.Remove_Load_Failure: {
        return { ...state, loading: false };
      }
      case ShippingLoadDetailActionTypes.Delete_Load: {
        return { ...state, loading: true };
      }
      case ShippingLoadDetailActionTypes.Delete_Load_Success: {
        return adapter.removeOne(action.payload.loadId, { ...state, loading: false });
      }
      case ShippingLoadDetailActionTypes.Delete_Load_Failure: {
        return { ...state, loading: false };
      }
      case ShippingLoadDetailActionTypes.Discard_Changes: {
        if (
          !action.payload ||
          !action.payload.loadId ||
          !state.postValidationProblemDetails ||
          !state.postValidationProblemDetails.errors
        ) {
          return state;
        }

        const loadId = action.payload.loadId;
        const nextState = { ...state };
        const errors = nextState.postValidationProblemDetails.errors;
        Object.keys(errors).forEach((key) => {
          if (key.startsWith(`urn:load:${loadId}`)) {
            delete errors[key];
          }
        });
        return {
          ...nextState,
          postValidationProblemDetails: {
            ...nextState.postValidationProblemDetails,
            errors: errors,
          },
        };
      }
      case ShippingLoadDetailActionTypes.Update_Load: {
        return adapter.updateOne({ id: action.payload.loadId, changes: { ...action.payload } }, { ...state });
      }
      case ShippingLoadDetailActionTypes.Select_Loads: {
        const updates = adapter.getSelectors().selectAll(state);

        const loads = updates.filter((x) => action.loadIds.indexOf(x.loadId) > -1);

        return adapter.updateMany(
          loads.map((x) => ({
            id: x.loadId,
            changes: { ...x, selected: true },
          })),

          { ...state, loadingLatestTransaction: true }
        );
      }
      case ShippingLoadDetailActionTypes.Toggle_Rate_Seeker_Error: {
        const updates = adapter.getSelectors().selectAll(state);

        const loads = updates.filter((x) => action.loadIds.indexOf(x.loadId) > -1);

        return adapter.updateMany(
          loads.map((x) => ({
            id: x.loadId,
            changes: { ...x, hasRateSeekerError: action.hasError },
          })),

          { ...state }
        );
      }
      case ShippingLoadDetailActionTypes.Unselect_Loads: {
        const updates = adapter.getSelectors().selectAll(state);

        const loads = updates.filter((x) => action.loadIds.indexOf(x.loadId) > -1);

        const changes = [];
        const changedSupplementalData = { ...state.loadsSupplementalData };
        loads.forEach((x) => {
          const change = {
            id: x.loadId,
            changes: {
              selected: false,
            },
          };

          changedSupplementalData[x.loadId] = { ...state.loadsSupplementalData[x.loadId], isLoadCarrierScacPrefetchProcessing: false };

          changes.push(change);
        });

        return adapter.updateMany(changes, {
          ...state,
          loadsSupplementalData: changedSupplementalData,
        });
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function ShippingLoadDetailReducer(state: ShippingLoadDetailState, action: ShippingLoadDetailActions): ShippingLoadDetailState {
  return _ShippingLoadDetailReducer(state, action);
}
const selectors = adapter.getSelectors();
export const getLoads = (state: ShippingLoadDetailState) => selectors.selectAll(state);
export const getLoadEntities = (state: ShippingLoadDetailState) => selectors.selectEntities(state);
export const getLoading = (state: ShippingLoadDetailState) => state.loading;
export const getPostValidationProblemDetails = (state: ShippingLoadDetailState) => state.postValidationProblemDetails;
export const getSuccessfullyPostedLoads = (state: ShippingLoadDetailState) => state.postedLoads;
export const getLoadsSupplementalData = (state: ShippingLoadDetailState) => state.loadsSupplementalData;
export const getLoadsSupplementalLoading = (state: ShippingLoadDetailState) => state.loadsSupplementalLoading;
