import { FuelProgramTypeEnum } from './FuelProgramType';

export interface RateBreakDownData {
  lineHaulRate: number;
  fuelRate: number;
  fuelProgramType: FuelProgramTypeEnum;
  totalRate: number;
}

export const defaultRateBreakDownData: RateBreakDownData = {
  fuelProgramType: FuelProgramTypeEnum.None,
  fuelRate: null,
  lineHaulRate: null,
  totalRate: null,
};

export interface RateBreakDownOverlayInput {
  loadshopFee: number;
  usesAllInRates: boolean;
  isEstimatedFSC: boolean;
  isShipper: boolean;
  isBrokeredLoad: boolean;
  loadId: string;
}

export const defaultRateBreakDownOverlayInput: RateBreakDownOverlayInput = {
  loadshopFee: null,
  usesAllInRates: false,
  isEstimatedFSC: false,
  isShipper: false,
  isBrokeredLoad: false,
  loadId: null,
};
