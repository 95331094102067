export const GuidEmpty = '00000000-0000-0000-0000-000000000000';
export const SmartSpotBrandMarkup = 'SmartSpot'; // Eventually can add &trade; or &reg; here to have it show up everywhere
export const RateSeekerBrandMarkup = 'Rate Seeker'; // Eventually can add &trade; or &reg; here to have it show up everywhere
export const DedicatedFuelDefault = 0.313;

export class Guid {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line: no-bitwise
      const r = (Math.random() * 16) | 0,
        // tslint:disable-next-line: no-bitwise
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
