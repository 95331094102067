import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kbxl-load-create-success-modal',
  templateUrl: './load-create-success-modal.component.html',
  styleUrls: ['./load-create-success-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadCreateSuccessModalComponent {
  @Input() loadId: string;
  @Input() loadRefId: string;
  @Output() goToPostTab: EventEmitter<string> = new EventEmitter<string>();
  @Output() createAnotherLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyLoad: EventEmitter<string> = new EventEmitter<string>();

  goToPostTab_Click() {
    this.goToPostTab.emit(this.loadRefId);
  }

  createAnotherLoad_Click() {
    this.createAnotherLoad.emit();
  }

  copyLoad_Click() {
    this.copyLoad.emit(this.loadId);
  }
}
