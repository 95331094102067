import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreState, getBrowserIsMobile, getStates } from '../../../../core/store';
import { getUserProfileModel, UserState } from '../../../../user/store';
import { ActionItemLoadView, ActionItemsCriteriaData, ActionItemSearch, ActionItemStatusData, State, UserModel } from '../../../models';
import {
  AccessorialActionItemLoadFiltersAction,
  AccessorialActionItemsLoadAction,
  AccessorialActionItemsUpdateQueryAction,
  ActionItemLoadCountAction,
  getAccessorialActionItemCriteria,
  getAccessorialActionItemFilterCriteriaLoading,
  getAccessorialActionItemQueryHelper,
  getAccessorialActionItems,
  getAccessorialActionItemsLoading,
  getAccessorialActionItemStatuses,
  getAccessorialActionItemsTotalRecords,
  SharedState,
} from '../../../store';
import { PageableQueryData, PageableQueryHelper, PageableUiHelper, reloadDataOnNavigationBackToRoute } from '../../../utilities';
import { BaseComponent } from '../../base-component';
import { AccessorialActionItemGridComponent } from '../accessorial-action-item-grid';

@Component({
  selector: 'kbxl-accessorial-action-item-container',
  templateUrl: './accessorial-action-item-container.component.html',
  styleUrls: ['./accessorial-action-item-container.component.scss'],
})
export class AccessorialActionItemContainerComponent extends BaseComponent implements OnInit, OnDestroy {
  actionItems$: Observable<ActionItemLoadView[]>;
  loadingFilter$: Observable<boolean>;
  loading$: Observable<boolean>;
  actionItemCriteria$: Observable<ActionItemsCriteriaData>;
  actionItemStatuses$: Observable<ActionItemStatusData>;
  user$: Observable<UserModel>;
  states$: Observable<State[]>;
  isMobile$: Observable<boolean>;
  totalRecords$: Observable<number>;
  // Paging
  @ViewChild(AccessorialActionItemGridComponent, { static: true }) actionItemsGrid: AccessorialActionItemGridComponent;
  pagingUiHelper: PageableUiHelper<AccessorialActionItemsUpdateQueryAction, AccessorialActionItemsLoadAction, SharedState>;
  constructor(
    private store: Store<SharedState>,
    private coreState: Store<CoreState>,
    private userStore: Store<UserState>,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.actionItems$ = this.store.pipe(
      select(getAccessorialActionItems),
      map((x) => this.deepClone(x))
    );
    this.loadingFilter$ = this.store.pipe(select(getAccessorialActionItemFilterCriteriaLoading));
    this.actionItemCriteria$ = this.store.pipe(select(getAccessorialActionItemCriteria));
    this.actionItemStatuses$ = this.store.pipe(select(getAccessorialActionItemStatuses));
    this.states$ = this.coreState.pipe(select(getStates));
    this.isMobile$ = this.coreState.pipe(select(getBrowserIsMobile));
    this.loading$ = this.store.pipe(select(getAccessorialActionItemsLoading));
    this.user$ = this.userStore.pipe(select(getUserProfileModel));
    this.totalRecords$ = this.store.pipe(select(getAccessorialActionItemsTotalRecords));

    // fetch the action items and status for this customer
    this.store.dispatch(new AccessorialActionItemLoadFiltersAction());

    this.pagingUiHelper = new PageableUiHelper({
      filterBehavior: new BehaviorSubject<ActionItemSearch>(null),
      pageableQueryHelper$: this.store.pipe(
        select(getAccessorialActionItemQueryHelper),
        map((_) => this.deepClone(_))
      ),
      // pagingBehavior: new BehaviorSubject<PageableQueryData>({
      //   pageSize: 50,
      //   pageNumber: 1,
      //   filter: null,
      //   sortField: 'actionItemStatusValue',
      //   descending: false,
      // }),
      pagingBehavior: new BehaviorSubject<PageableQueryData>(null),
      store: this.store,
      pageableComponent: this.actionItemsGrid,
      getQueryUpdateAction: (currentQuery: PageableQueryHelper) => new AccessorialActionItemsUpdateQueryAction(currentQuery),
      getLoadAction: (currentQuery: PageableQueryHelper) =>
        new AccessorialActionItemsLoadAction({
          searchType: '',
          queryHelper: currentQuery,
        }),
    });

    reloadDataOnNavigationBackToRoute(this.router, '/loads/action-items/accessorials', this.destroyed$, this.pagingUiHelper);
  }

  ngOnDestroy(): void {
    this.clear();
    if (this.pagingUiHelper) {
      this.pagingUiHelper.ngOnDestroy();
    }
    super.ngOnDestroy();
  }

  search(criteria: ActionItemSearch): void {
    this.pagingUiHelper.pageableUiData.filterBehavior.next(criteria);
  }

  clear() {
    this.pagingUiHelper.pageableUiData.filterBehavior.next(null);
  }

  onLazyLoadEvent(pagingData: PageableQueryData) {
    this.pagingUiHelper.pageableUiData.pagingBehavior.next(pagingData);
  }

  refreshData(event: boolean): void {
    this.pagingUiHelper.reloadData();
    // update action item counter
    this.store.dispatch(new ActionItemLoadCountAction());
  }

  selected(loadId: string) {
    this.router.navigate(['detail', loadId], { relativeTo: this.route });
  }
}
