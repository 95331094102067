import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TmsSharedModule } from '@tms-ng/shared';
import { TimeagoModule } from 'ngx-timeago';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import {
  ActionCheckDirective,
  AutoFocusDirective,
  CloseDropdownOnScrollDirective,
  CloseMultiSelectOnScrollDirective,
  CurrencyInputDirective,
  FeatureCheckDirective,
  InputBoundDirective,
  PrintDirective,
  StopClickPropagationDirective,
} from '../shared/directives';
import {
  AccessorialActionItemContainerComponent,
  AccessorialActionItemFilterComponent,
  AccessorialActionItemGridComponent,
  AccessorialRequestCommentsComponent,
  AccessorialRequestsModalComponent,
  ActionItemsContainerComponent,
  AddAccessorialRequestComponent,
  AddCounterOfferRequestComponent,
  AddressAutocompleteComponent,
  AgreementDocumentViewOnlyComponent,
  AutoApproveWarningComponent,
  BookLoadModalComponent,
  CarrierIneligibleWarningComponent,
  ChatPresenceIndicatorComponent,
  ChatUserComponent,
  ColorBarComponent,
  ConfirmActionModalComponent,
  ContactFormComponent,
  CopyToClipboardComponent,
  CounterOfferActionItemContainerComponent,
  CounterOfferActionItemFilterComponent,
  CounterOfferActionItemGridComponent,
  CounterOfferCommentsComponent,
  CounterOfferGroupAccordionTabComponent,
  CounterOfferGroupAccordionTabHeaderComponent,
  CounterOfferRequestsModalComponent,
  CustomerTypeIconComponent,
  DaysComponent,
  DocumentListComponent,
  DocumentListItemComponent,
  DropdownComponent,
  DynamicPricingRuleFormComponent,
  ErrorListDisplayComponent,
  HoursToDaysSliderComponent,
  IncorrectRouteComponent,
  LinehaulRateOverlayComponent,
  LoadAccessorialRequestsComponent,
  LoadAccessorialViewComponent,
  LoadDetailComponent,
  LoadDetailContainerComponent,
  LoadLineItemFormComponent,
  LoadStopComponent,
  LoadStopFormComponent,
  MapComponent,
  MarginalAnalysisFormComponent,
  NotesCountComponent,
  NotesDisplayContainerComponent,
  NotesListComponent,
  NotificationContactBuilderComponent,
  QuickQuoteComponent,
  QuickQuoteModalComponent,
  RateBreakDownsOoverlayComponent,
  ShareLinkComponent,
  ShippingLoadAuditLogGridComponent,
  ShippingLoadAuditLogGridModalComponent,
  SidebarCloseComponent,
  TenderToCarrierModalComponent,
  TreeDropDownComponent,
  TripDisplayComponent,
  UploadDocumentModalComponent,
  UserCommunicationDisplayComponent,
  UserCommunicationDisplayContainerComponent,
} from './components';
import { PercentageComponent } from './components/percentage/percentage.component';
import { AppActionGuard, RootGuard } from './guards';
import {
  DayAbbreviationPipe,
  HoursToDaysPipe,
  TimeSpanToTimePipe,
  ToSelectItemByKeyPipe,
  ToSelectItemPipe,
  ToTreeNodesPipe,
} from './pipes';
import {
  AccessorialActionItemService,
  AccessorialRequestService,
  ActionItemService,
  CounterOfferActionItemService,
  LoadCounterOfferService,
  LoadDocumentService,
  MarginalAnalysisService,
  NoteService,
  ShareLinkService,
  TransactionTypeService,
  UserCommunicationDisplayService,
} from './services';
import { effects, reducers } from './store';

const primeModules = [
  AccordionModule,
  PanelModule,
  InputMaskModule,
  CheckboxModule,
  ButtonModule,
  TableModule,
  SelectButtonModule,
  DialogModule,
  AutoCompleteModule,
  CardModule,
  InputSwitchModule,
  ToggleButtonModule,
  TabViewModule,
  DropdownModule,
  MessagesModule,
  CalendarModule,
  SidebarModule,
  MultiSelectModule,
  InputTextareaModule,
  PaginatorModule,
  ToastModule,
  TabMenuModule,
  ConfirmDialogModule,
  CarouselModule,
  TooltipModule,
  SliderModule,
  SplitButtonModule,
  VirtualScrollerModule,
  BlockUIModule,
  ProgressSpinnerModule,
  ScrollPanelModule,
  EditorModule,
  OverlayPanelModule,
  FieldsetModule,
  TreeModule,
  RadioButtonModule,
  FileUploadModule,
  DataViewModule,
  ChartModule,
  StepsModule,
  DynamicDialogModule,
  ContextMenuModule,
  MenuModule,
  InputNumberModule,
];

const directives = [
  AutoFocusDirective,
  StopClickPropagationDirective,
  TripDisplayComponent,
  CurrencyInputDirective,
  CloseMultiSelectOnScrollDirective,
  CloseDropdownOnScrollDirective,
  ActionCheckDirective,
  FeatureCheckDirective,
  PrintDirective,
  InputBoundDirective,
];

@NgModule({
  declarations: [
    AddressAutocompleteComponent,
    ColorBarComponent,
    DaysComponent,
    DayAbbreviationPipe,
    ToSelectItemPipe,
    ToSelectItemByKeyPipe,
    ToTreeNodesPipe,
    HoursToDaysPipe,
    TimeSpanToTimePipe,
    MapComponent,
    SidebarCloseComponent,
    ContactFormComponent,
    CustomerTypeIconComponent,
    LoadLineItemFormComponent,
    LoadStopFormComponent,
    TreeDropDownComponent,
    DropdownComponent,
    LoadDetailComponent,
    LoadDetailContainerComponent,
    ShippingLoadAuditLogGridComponent,
    LoadStopComponent,
    UserCommunicationDisplayComponent,
    UserCommunicationDisplayContainerComponent,
    UploadDocumentModalComponent,
    PercentageComponent,
    CopyToClipboardComponent,
    IncorrectRouteComponent,
    DynamicPricingRuleFormComponent,
    DocumentListComponent,
    ...directives,
    MarginalAnalysisFormComponent,
    BookLoadModalComponent,
    AgreementDocumentViewOnlyComponent,
    LoadAccessorialViewComponent,
    AccessorialRequestsModalComponent,
    AddAccessorialRequestComponent,
    ActionItemsContainerComponent,
    ConfirmActionModalComponent,
    ErrorListDisplayComponent,
    ShareLinkComponent,
    LoadAccessorialRequestsComponent,
    QuickQuoteModalComponent,
    QuickQuoteComponent,
    NotificationContactBuilderComponent,
    DocumentListItemComponent,
    AccessorialRequestCommentsComponent,
    ShippingLoadAuditLogGridModalComponent,
    ShippingLoadAuditLogGridComponent,
    AccessorialActionItemContainerComponent,
    AccessorialActionItemFilterComponent,
    AccessorialActionItemGridComponent,
    CounterOfferActionItemContainerComponent,
    CounterOfferActionItemFilterComponent,
    CounterOfferActionItemGridComponent,
    CounterOfferRequestsModalComponent,
    CounterOfferCommentsComponent,
    AddCounterOfferRequestComponent,
    LinehaulRateOverlayComponent,
    RateBreakDownsOoverlayComponent,
    CarrierIneligibleWarningComponent,
    CounterOfferGroupAccordionTabComponent,
    CounterOfferGroupAccordionTabHeaderComponent,
    AutoApproveWarningComponent,
    HoursToDaysSliderComponent,
    ChatPresenceIndicatorComponent,
    ChatUserComponent,
    TenderToCarrierModalComponent,
    NotesDisplayContainerComponent,
    NotesListComponent,
    NotesCountComponent,
  ],
  imports: [
    TmsSharedModule,
    CommonModule,
    FormsModule,
    ...primeModules,
    FontAwesomeModule,
    ReactiveFormsModule,
    ScrollingModule,
    EffectsModule.forFeature(effects),
    StoreModule.forFeature('sharedstate', reducers),
    TimeagoModule.forRoot(),
  ],
  providers: [
    MessageService,
    ConfirmationService,
    AppActionGuard,
    RootGuard,
    UserCommunicationDisplayService,
    LoadDocumentService,
    DatePipe,
    MarginalAnalysisService,
    AccessorialRequestService,
    ActionItemService,
    AccessorialActionItemService,
    CounterOfferActionItemService,
    ShareLinkService,
    TransactionTypeService,
    LoadCounterOfferService,
    NoteService,
  ],
  exports: [
    AddressAutocompleteComponent,
    ColorBarComponent,
    DaysComponent,
    DayAbbreviationPipe,
    ToSelectItemPipe,
    ToSelectItemByKeyPipe,
    ToTreeNodesPipe,
    HoursToDaysPipe,
    TimeSpanToTimePipe,
    MapComponent,
    TmsSharedModule,
    CommonModule,
    FormsModule,
    ...primeModules,
    FontAwesomeModule,
    ReactiveFormsModule,
    ScrollingModule,
    SidebarCloseComponent,
    TripDisplayComponent,
    ContactFormComponent,
    CustomerTypeIconComponent,
    LoadLineItemFormComponent,
    LoadStopFormComponent,
    TreeDropDownComponent,
    DropdownComponent,
    LoadDetailContainerComponent,
    ShippingLoadAuditLogGridComponent,
    UserCommunicationDisplayComponent,
    UserCommunicationDisplayContainerComponent,
    UploadDocumentModalComponent,
    PercentageComponent,
    CopyToClipboardComponent,
    IncorrectRouteComponent,
    DynamicPricingRuleFormComponent,
    DocumentListComponent,
    LoadAccessorialViewComponent,
    ErrorListDisplayComponent,
    ...directives,
    ActionItemsContainerComponent,
    ShareLinkComponent,
    LoadAccessorialRequestsComponent,
    QuickQuoteModalComponent,
    QuickQuoteComponent,
    NotificationContactBuilderComponent,
    DocumentListItemComponent,
    TimeagoModule,
    LinehaulRateOverlayComponent,
    RateBreakDownsOoverlayComponent,
    AutoApproveWarningComponent,
    HoursToDaysSliderComponent,
    ChatPresenceIndicatorComponent,
    ChatUserComponent,
    NotesCountComponent
  ],
  entryComponents: [
    AgreementDocumentViewOnlyComponent,
    AccessorialRequestsModalComponent,
    ConfirmActionModalComponent,
    UploadDocumentModalComponent,
    ShippingLoadAuditLogGridModalComponent,
    ShippingLoadAuditLogGridComponent,
    CounterOfferRequestsModalComponent,
    TenderToCarrierModalComponent,
    BookLoadModalComponent,
  ],
})
export class SharedModule {
  constructor(faLibrary: FaIconLibrary) {
    const faDynamicPrice: any = {
      prefix: 'fas',
      iconName: 'dynamic-price-logo',
      icon: [
        55,
        49, // actual: 43 tall - modified to get to line up
        [],
        'e001',
        'M15 21.393h20v5H15zM30 6.393h20v5H30z M46 .515L54.485 9 46 17.485zM30 6.393h5v20h-5zM15 21.393h5v17h-5z M0 38h55v5H0z',
      ],
    };

    faLibrary.addIconPacks(fas, far, faDynamicPrice);
  }
}
