import { ActionItemsCriteriaData } from './action-item-criteria-data';
import { ActionItemStatusData } from './action-item-status-data';

export class ActionItemSearch {
  userLaneId?: string;
  origLat?: number;
  origLng?: number;
  origCity?: string;
  origState?: string;
  origCountry?: string;
  destLat?: number;
  destLng?: number;
  destCity?: string;
  destState?: string;
  destCountry?: string;
  sortName?: string;
  sortDir?: 'asc' | 'desc';
  quickSearch?: string;
  showAllLoads: boolean;
  actionItemCriteria: ActionItemsCriteriaData[];
  actionItemStatuses: ActionItemStatusData[];
  userIds?: string[];
}

export const defaultActionItemSearch: ActionItemSearch = {
  origLat: null,
  origLng: null,
  destLat: null,
  destLng: null,
  quickSearch: null,
  showAllLoads: false,
  actionItemCriteria: [],
  actionItemStatuses: [],
  userIds: [],
};
