import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CustomerLaneData, CustomerLaneGroupDetailData, CustomerProfile } from '../../../../shared/models';

@Component({
  selector: 'kbxl-lane-group-grid',
  templateUrl: './lane-group-grid.component.html',
  styleUrls: ['./lane-group-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaneGroupGridComponent {
  @Input() loading: boolean;
  @Input() groups: CustomerLaneGroupDetailData[];
  @Input() first: 0;
  @Input() shipper: CustomerProfile;
  @Output() displayDetail = new EventEmitter<CustomerLaneGroupDetailData>();
  @Output() firstChange = new EventEmitter<number>();

  selectedCustomerLaneGroupAttribute: CustomerLaneGroupDetailData;

  constructor(private changeRef: ChangeDetectorRef) {}

  public exportData() {
    // export lane data to csv
    const csvHeaders = ['Name', 'Description', 'Origin', 'Destination'];

    const rows = [];
    rows.push(csvHeaders.join(','));

    this.groups.forEach((x) => {
      x.customerLanes.forEach((lane) => {
        const row = [`"${x.name}"`];

        if (x.description && x.description.length > 0) {
          row.push(`"${x.description}"`);
        } else {
          row.push('""');
        }
        // format origin
        let originDisplay = '';
        originDisplay = this.appendDisplayProperty(originDisplay, lane.origCity);
        originDisplay = this.appendDisplayProperty(originDisplay, lane.origState);
        originDisplay = this.appendDisplayProperty(originDisplay, lane.origZip);
        originDisplay = this.appendDisplayProperty(originDisplay, lane.origCountry);

        originDisplay = originDisplay.length > 0 ? originDisplay : '[ALL]';

        row.push(`"${originDisplay}"`);

        // format destination
        let destDisplay = '';
        destDisplay = this.appendDisplayProperty(destDisplay, lane.destCity);
        destDisplay = this.appendDisplayProperty(destDisplay, lane.destState);
        destDisplay = this.appendDisplayProperty(destDisplay, lane.destZip);
        destDisplay = this.appendDisplayProperty(destDisplay, lane.destCountry);

        destDisplay = destDisplay.length > 0 ? destDisplay : '[ALL]';

        row.push(`"${destDisplay}"`);

        rows.push(row.join(','));
      });
    });

    // download the file for the user
    const csvFile = new Blob([rows.join('\n')], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.id = 'lane-management-download-link';
    downloadLink.download = 'Lane Management Export.csv';
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // clean up after the download
    setTimeout(() => {
      const el = document.getElementById('lane-management-download-link');

      el.remove();
    }, 250);
  }
  addClick() {
    this.displayDetail.emit(null);
  }

  onRowSelect(group: CustomerLaneGroupDetailData) {
    this.displayDetail.emit(group);
  }

  getLanesDisplayMarkup(laneGroup: CustomerLaneGroupDetailData) {
    let display = '';
    if (laneGroup && laneGroup.customerLanes) {
      laneGroup.customerLanes.forEach((lane) => {
        let originDisplay = '';
        originDisplay = this.appendDisplayProperty(originDisplay, lane.origCity);
        originDisplay = this.appendDisplayProperty(originDisplay, lane.origState);
        originDisplay = this.appendDisplayProperty(originDisplay, lane.origZip);
        originDisplay = this.appendDisplayProperty(originDisplay, lane.origCountry);

        let destDisplay = '';
        destDisplay = this.appendDisplayProperty(destDisplay, lane.destCity);
        destDisplay = this.appendDisplayProperty(destDisplay, lane.destState);
        destDisplay = this.appendDisplayProperty(destDisplay, lane.destZip);
        destDisplay = this.appendDisplayProperty(destDisplay, lane.destCountry);

        display += originDisplay.length > 0 ? originDisplay : '[ALL]';
        display += ' &rarr; ';
        display += destDisplay.length > 0 ? destDisplay : '[ALL]';
        display += '<br />';
      });
    }
    return display;
  }

  appendDisplayProperty(display: string, property: string): string {
    if (display && display.length > 0 && property) {
      return (display += ', ' + property);
    } else if (display && display.length) {
      return display;
    } else if (property) {
      return property;
    }
    return '';
  }

  hasOrigin(lane: CustomerLaneData) {
    return lane.origAddr1 || lane.origCity || lane.origState || lane.origCountry || lane.origZip;
  }

  hasDest(lane: CustomerLaneData) {
    return lane.destAddr1 || lane.destCity || lane.destState || lane.destCountry || lane.destZip;
  }

  getContactsDisplayMarkup(laneGroup: CustomerLaneGroupDetailData) {
    let display = '';
    if (laneGroup && laneGroup.customerLaneGroupContacts) {
      laneGroup.customerLaneGroupContacts
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : 0))
        .forEach((contact) => {
          display += `${contact.display}`;
          display += '<br />';
        });
    }
    return display;
  }

  onPage($event) {
    // if the first property is populated with the row skip, send the change up
    if ($event && $event.first > -1) {
      this.first = $event.first;
      this.firstChange.emit(this.first);
    }
  }

  showLaneAttributeOverlay(group: CustomerLaneGroupDetailData, laneAttributeOverlay: OverlayPanel, event: Event) {
    // Make sure the overlay is hidden before showing
    // so it shows up in correct spot
    this.hideLaneAttributeOverlay(laneAttributeOverlay);
    event.stopPropagation();

    setTimeout(() => {
      this.selectedCustomerLaneGroupAttribute = group;
      laneAttributeOverlay.show(event, event.target);
      this.changeRef.detectChanges();
    }, 10);
  }

  hideLaneAttributeOverlay(laneAttributeOverlay: OverlayPanel) {
    this.selectedCustomerLaneGroupAttribute = null;
    laneAttributeOverlay.hide();
  }
}
