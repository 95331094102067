<p-accordion
  *ngIf="availableAccessorials && availableAccessorials.length > 0"
  [(activeIndex)]="addRequestActiveIndex"
  (onOpen)="addRequestActiveIndex = 0"
  (onClose)="addRequestActiveIndex = -1"
>
  <p-accordionTab header="Create Accessorial Request">
    <form [formGroup]="addAccessorialRequestForm" (change)="updateAddFormValues()" (ngSubmit)="addNewAccessorial()" class="addForm">
      <div class="d-flex flex-column flex-lg-row">
        <div class="kbxl-inputgroup-container mr-2" id="accessorial-dropdown-container">
          <div for="Quantity" class="kbxl-field-label">
            <label
              *ngIf="shouldShowAfterInitialHours(addAccessorialRequestForm.value?.accessorial)"
              for="nothing"
              class="control-label d-block"
            >
              &nbsp;<!-- When After Initial Hours shows -->
            </label>
            <label for="Quantity" class="control-label">Accessorial</label>
          </div>
          <p-dropdown
            [options]="availableAccessorials"
            placeholder="Select Accessorial"
            [styleClass]="'p-dropdown-primary accessorial-dropdown'"
            dropdownIcon="pi pi-caret-down"
            formControlName="accessorial"
            baseZIndex="10000"
            (ngModelChange)="updateAddFormValues()"
          >
            <ng-template let-shipperAccessorial pTemplate="item">
              {{ shipperAccessorial.value.accessorialDescription }}
              <div class="text-muted">
                {{ shipperAccessorial.value.accessorialUnitOfMeasureDescription }}
                <span *ngIf="shipperAccessorial.value.variableRate">- Variable Rate</span>
                <span *ngIf="shipperAccessorial.value.accessorial.allocationLevel === 2">- Per Stop</span>
              </div>
            </ng-template>
            <ng-template let-shipperAccessorial pTemplate="selectedItem">
              {{ shipperAccessorial?.value?.accessorialDescription }}
              <div class="text-muted">
                {{ shipperAccessorial?.value?.accessorialUnitOfMeasureDescription }}
                <span *ngIf="shipperAccessorial?.value?.variableRate">- Variable Rate</span>
                <span *ngIf="shipperAccessorial.value.accessorial.allocationLevel === 2">- Per Stop</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="d-flex" *ngIf="addAccessorialRequestForm.value?.accessorial">
          <div class="kbxl-inputgroup-container mr-2 d-none" [ngClass]="{ 'd-inline-block': addAccessorialRequestForm.value.showStop }">
            <div for="Quantity" class="kbxl-field-label">
              <label
                *ngIf="shouldShowAfterInitialHours(addAccessorialRequestForm.value?.accessorial)"
                for="nothing"
                class="control-label d-block"
              >
                &nbsp;<!-- When After Intial Hours shows -->
              </label>
              <label for="Quantity" class="control-label">Stop</label>
            </div>
            <input type="text" maxlength="2" class="stop-input" formControlName="stop" pInputText />
          </div>
          <div class="kbxl-inputgroup-container mr-2">
            <div for="Quantity" class="kbxl-field-label">
              <label
                *ngIf="shouldShowAfterInitialHours(addAccessorialRequestForm.value?.accessorial)"
                for="nothing"
                class="control-label d-block"
              >
                &nbsp;<!-- When After Intial Hours shows -->
              </label>
              <label for="Quantity" class="control-label">Quantity</label>
            </div>
            <input type="text" maxlength="5" class="quantity-input" formControlName="quantity" pInputText />
          </div>
          <div class="kbxl-inputgroup-container mr-2">
            <div for="Quantity" class="kbxl-field-label">
              <label
                *ngIf="shouldShowAfterInitialHours(addAccessorialRequestForm.value?.accessorial)"
                for="Quantity"
                class="control-label d-block"
              >
                After {{ addAccessorialRequestForm.value?.accessorial.afterInitialHours }} hr(s)
              </label>
              <label for="Quantity" class="control-label">Rate Per Unit of Measure</label>
            </div>
            <input type="text" class="" formControlName="ratePerUom" pInputText maxlength="10" />
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-lg-row" *ngIf="addAccessorialRequestForm.value.accessorial">
        <div class="kbxl-inputgroup-container mb-0 mr-2">
          <div for="Quantity" class="kbxl-field-label">
            <label for="Quantity" class="control-label">Comment</label>
          </div>
          <textarea
            placeholder="Additional Comments"
            rows="3"
            cols="62"
            pInputTextarea
            name="comments"
            maxlength="4000"
            formControlName="comment"
          ></textarea>
        </div>
        <div
          class="d-flex flex-column justify-content-center all-in-rate-container"
          [ngClass]="{ invisible: !addAccessorialRequestForm.value.rate || addAccessorialRequestForm.value.rate === 0 }"
        >
          <div class="all-in-rate-value">
            {{ addAccessorialRequestForm.value.rate | currency }}
          </div>
          <div class="all-in-rate-text">Rate</div>
        </div>
      </div>
      <div>
        <kbxl-upload-document-modal
          [loadId]="loadId"
          (close)="handleDocumentChange($event)"
          [customDocumentUpload]="true"
          [basicFileSelect]="true"
        >
        </kbxl-upload-document-modal>
        <div class="d-flex mt-2" *ngFor="let doc of documents; let i = index">
          <span class="file-name">{{ doc.file.name }}</span>
          <button
            class="p-button-danger ml-auto"
            pButton
            icon="pi pi-trash"
            (click)="removeDocument(doc, i)"
            title="Delete Document"
            type="button"
          ></button>
        </div>
        <hr class="detail-horizontal-rule" />
      </div>
      <div class="text-right">
        <button
          pButton
          type="submit"
          icon="pi pi-plus"
          label="Add"
          class="p-button-teal mt-auto mb-1"
          [disabled]="!addAccessorialRequestForm.valid"
        ></button>
      </div>
    </form>
  </p-accordionTab>
</p-accordion>
