import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  CarrierAccessorialRequestActionData,
  CarrierAccessorialRequestByLoadData,
  CarrierAccessorialRequestData,
  ServiceResponse,
} from '../models';
import { LoadAccessorialRequestAvailabilityData } from '../models/load-accessorial-request-availability-data';
import { mapResponse } from '../operators/map-response';

@Injectable()
export class AccessorialRequestService {
  constructor(private http: HttpClient) {}

  get(loadId: string): Observable<CarrierAccessorialRequestByLoadData> {
    return this.http
      .get<ServiceResponse<CarrierAccessorialRequestByLoadData>>(`${environment.apiUrl}/api/AccessorialRequests/loads/${loadId}`)
      .pipe(mapResponse());
  }

  saveAction(
    requestId: number,
    action: CarrierAccessorialRequestActionData,
    updateRequest = false
  ): Observable<CarrierAccessorialRequestData> {
    const postFormData: FormData = new FormData();

    if (action.documentToUpload && action.documentToUpload.file) {
      postFormData.append('attachment', action.documentToUpload.file, action.documentToUpload.file.name);

      // clear out object before JSON string
      action.documentToUpload.file = null;
    }

    postFormData.append('action', JSON.stringify(action));

    return this.http
      .post<ServiceResponse<CarrierAccessorialRequestData>>(
        `${environment.apiUrl}/api/AccessorialRequests/${requestId}/actions?updateRequest=${updateRequest}`,
        postFormData
      )
      .pipe(mapResponse());
  }

  saveRequests(requests: CarrierAccessorialRequestData[], loadId: string): Observable<CarrierAccessorialRequestData[]> {
    const postFormData: FormData = new FormData();

    for (let index = 0; index < requests.length; index++) {
      const request = requests[index];

      if (request.documentsToUpload) {
        for (let j = 0; j < request.documentsToUpload.length; j++) {
          const document = request.documentsToUpload[j];

          if (document && document.file) {
            postFormData.append(`attachment_${index}_${j}`, document.file, document.file.name);
            // clear out object before JSON string
            document.file = null;
          }
        }
      }

      postFormData.append(`accessorialform_${index}`, JSON.stringify(request));
    }

    return this.http
      .post<ServiceResponse<CarrierAccessorialRequestData[]>>(`${environment.apiUrl}/api/AccessorialRequests/loads/${loadId}`, postFormData)
      .pipe(mapResponse());
  }
  deleteRequest(carrierRequestAccessorialId: number, loadId: string): Observable<Response> {
    return this.http.delete<Response>(
      `${environment.apiUrl}/api/AccessorialRequests/loads/${loadId}/carrieraccessorials/${carrierRequestAccessorialId}`
    );
  }

  deleteAccessorialDocument(carrierRequestAccessorialId: number, documentId: string): Observable<CarrierAccessorialRequestData> {
    return this.http
      .delete<ServiceResponse<CarrierAccessorialRequestData>>(
        `${environment.apiUrl}/api/AccessorialRequests/${carrierRequestAccessorialId}/documents/${documentId}`
      )
      .pipe(mapResponse());
  }
  unlockLoad(loadId: string, unlock: boolean): Observable<LoadAccessorialRequestAvailabilityData> {
    return this.http
      .post<ServiceResponse<LoadAccessorialRequestAvailabilityData>>(
        `${environment.apiUrl}/api/AccessorialRequests/loads/${loadId}/unlock?unlockLoad=${unlock}`,
        null
      )
      .pipe(mapResponse());
  }
}
