import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LoadBoardService } from 'src/app/load-board/services';
import { LoadBoardState, getLoadBoardShowAllLoadsContext } from '../../../load-board/store';
import { FuelProgramTypeEnum } from '../../models/FuelProgramType';
import { RateBreakDownData, RateBreakDownOverlayInput } from '../../models/rate-break-down-data';
import { getBreakEvenRateStyles } from '../../utilities';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'kbxl-rate-break-downs-overlay',
  templateUrl: './rate-break-downs-overlay.component.html',
  styleUrls: ['./rate-break-downs-overlay.component.scss'],
})
export class RateBreakDownsOoverlayComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() showRateBreakDownOverlay: boolean;
  @Input()
  rateBreakDownInput: RateBreakDownOverlayInput;
  @Input() event: any;
  @ViewChild(OverlayPanel) rateBreakDownOverlay: OverlayPanel;
  isShowAllLoadsContext: boolean;

  rateBreakDownTitle: string;
  loadshopFee: number;
  totalRate: number;
  isEstimatedFSC: boolean;
  showOverlayTask: any;
  isBrokeredLoad: boolean;
  totalQuotePrice: number;
  rateBreakDownsData: RateBreakDownData[];
  loading: boolean;
  showFuel: boolean;
  getBreakDownSubscription: Subscription;
  fuelProgramTypeEnum = FuelProgramTypeEnum;
  constructor(private loadBoardService: LoadBoardService, private cdr: ChangeDetectorRef, private loadBoardStore: Store<LoadBoardState>) {
    super();
  }

  ngOnInit(): void {
    this.loadBoardStore
      .pipe(
        select(getLoadBoardShowAllLoadsContext),
        takeUntil(this.destroyed$),
        map((x) => (x ? true : false))
      )
      .subscribe((x) => (this.isShowAllLoadsContext = x));
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (
        changes.showRateBreakDownOverlay &&
        changes.showRateBreakDownOverlay.currentValue &&
        changes.rateBreakDownInput &&
        changes.rateBreakDownInput.currentValue &&
        changes.event &&
        changes.event.currentValue
      ) {
        this.showOverlay();
      } else {
        this.hideOverlay();
      }
    }
  }

  showOverlay() {
    if (this.rateBreakDownOverlay && this.event) {
      if (this.rateBreakDownOverlay.target === null || this.rateBreakDownOverlay.target === undefined) {
        this.loading = true;
        this.rateBreakDownOverlay.show(this.event);
        this.getBreakDownSubscription = this.loadBoardService
          .getRateBreakDowns(this.rateBreakDownInput.loadId, this.isShowAllLoadsContext)
          .subscribe(
            (data) => {
              this.calculateValues(data);
              this.rateBreakDownsData = data;
              this.showOverlayTask = null;
              this.loading = false;
              this.cdr.detectChanges();
            },
            (err) => {
              this.loading = false;
              this.cdr.detectChanges();
            }
          );
      } else if (this.showOverlayTask === null) {
        this.showOverlayTask = setTimeout(() => this.showOverlay());
      }
    }
  }
  getCalculatedRateFontStyles(totalRate: number) {
    if (this.isBrokeredLoad) {
      return getBreakEvenRateStyles(totalRate, this.totalQuotePrice, this.isBrokeredLoad);
    } else {
      return null;
    }
  }
  calculateValues(rateBreakDownData: RateBreakDownData[]) {
    if (this.rateBreakDownInput) {
      this.rateBreakDownTitle = 'Line Haul';
      this.loadshopFee = this.rateBreakDownInput.isShipper ? this.rateBreakDownInput.loadshopFee : null;
      this.isEstimatedFSC = this.rateBreakDownInput.isEstimatedFSC;
      this.isBrokeredLoad = this.rateBreakDownInput.isBrokeredLoad;
      this.totalQuotePrice = null;

      rateBreakDownData.forEach((value) => {
        value.fuelRate = this.rateBreakDownInput.usesAllInRates ? null : value.fuelRate;
        this.totalRate = value.lineHaulRate + value.fuelRate;
        if (this.rateBreakDownInput.isShipper) {
          value.totalRate = this.totalRate + this.rateBreakDownInput.loadshopFee;
        } else {
          value.lineHaulRate -= this.rateBreakDownInput.loadshopFee;
          if (!this.rateBreakDownInput.isShipper) {
            value.totalRate = value.totalRate - this.rateBreakDownInput.loadshopFee;
          }
        }
      });
      if (rateBreakDownData.find((i) => i.fuelRate != null) && !this.rateBreakDownInput.usesAllInRates) {
        this.showFuel = true;
      }
    }
  }

  hideOverlay() {
    if (this.rateBreakDownOverlay) {
      this.showOverlayTask = null;
      this.rateBreakDownsData = null;
      this.rateBreakDownOverlay.hide();
    }
    if (this.getBreakDownSubscription) {
      this.getBreakDownSubscription.unsubscribe();
    }
  }
}
