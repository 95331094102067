/* eslint-disable @typescript-eslint/camelcase */
import { Action } from '@ngrx/store';

export enum ConversationHubActionTypes {
  Loading = '[ConversationHub] LOADING',
  Ready = '[ConversationHub] READY',
  Error = '[ConversationHub] ERROR',
  Ping = '[ConversationHub] PING',
  Ping_Failure = '[ConversationHub] PING FAILURE',
  Ping_Success = '[ConversationHub] PING SUCCESS',
}

export class ConversationHubLoadingAction implements Action {
  readonly type = ConversationHubActionTypes.Loading;
  constructor(public payload: boolean) {}
}

export class ConversationHubReadyAction implements Action {
  readonly type = ConversationHubActionTypes.Ready;
  constructor(public payload: boolean, public wasConnectionDropped: boolean = false) {}
}

export class ConversationHubErrorAction implements Action {
  readonly type = ConversationHubActionTypes.Error;
  constructor(public payload: string) {}
}
export class ConversationHubPingAction implements Action {
  readonly type = ConversationHubActionTypes.Ping;
}
export class ConversationHubPingFailureAction implements Action {
  readonly type = ConversationHubActionTypes.Ping_Failure;
  constructor(public payload: any) {}
}
export class ConversationHubPingSuccessAction implements Action {
  readonly type = ConversationHubActionTypes.Ping_Success;
}

export type ConversationHubActions =
  | ConversationHubLoadingAction
  | ConversationHubReadyAction
  | ConversationHubErrorAction
  | ConversationHubPingAction
  | ConversationHubPingFailureAction
  | ConversationHubPingSuccessAction;
