<div class="d-flex flex-column position-relative">
  <div class="progress-indicator" *ngIf="processing$ | async">
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <div *ngIf="availableSourceSystems.length > 0">
    <div class="col-md-6 kbxl-inputgroup-container">
      <div class="kbxl-field-label">
        <label for="addSourceSystem" class="control-label">Add Source System</label>
      </div>
      <div>
        <p-dropdown
          [options]="availableSourceSystems"
          placeholder="Select Source System"
          name="addSourceSystem"
          (onChange)="addRecord($event)"
          [styleClass]="'p-dropdown-primary'"
          dropdownIcon="pi pi-caret-down"
        >
        </p-dropdown>
      </div>
    </div>
  </div>
  <div *ngIf="shipperMappings$ | async as s">
    <p-table [value]="s" dataKey="loadshopShipperMappingId" editMode="row">
      <ng-template pTemplate="header">
        <tr>
          <th>Owner Id</th>
          <th>Source System</th>
          <th>Active</th>
          <th>Uses Customer Order Number</th>
          <th>Uses All In Rates</th>
          <th>Uses Smart Spot Pricing</th>
          <th>Use TOPS Load Contacts</th>
          <th>Use Customer Fuel</th>
          <th>Auto Apply Carrier Group</th>
          <th>AR SpotQuote Eligible</th>
          <th style="width: 8em"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td>
            {{ rowData.ownerId }}
          </td>
          <td>
            {{ rowData.sourceSystem }}
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-checkbox name="isActive" [(ngModel)]="rowData.isActive" binary="true"></p-checkbox>
              </ng-template>
              <ng-template pTemplate="output">
                <p-checkbox name="isActive" [(ngModel)]="rowData.isActive" binary="true" disabled="true"></p-checkbox>
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-checkbox name="isActive" [(ngModel)]="rowData.useCustomerOrderNumber" binary="true"></p-checkbox>
              </ng-template>
              <ng-template pTemplate="output">
                <p-checkbox name="isActive" [(ngModel)]="rowData.useCustomerOrderNumber" binary="true" disabled="true"> </p-checkbox>
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-checkbox name="isActive" [(ngModel)]="rowData.usesAllInRates" binary="true"></p-checkbox>
              </ng-template>
              <ng-template pTemplate="output">
                <p-checkbox name="isActive" [(ngModel)]="rowData.usesAllInRates" binary="true" disabled="true"> </p-checkbox>
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-checkbox name="isActive" [(ngModel)]="rowData.usesSmartSpotPricing" binary="true"></p-checkbox>
              </ng-template>
              <ng-template pTemplate="output">
                <p-checkbox name="isActive" [(ngModel)]="rowData.usesSmartSpotPricing" binary="true" disabled="true"> </p-checkbox>
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-checkbox name="isActive" [(ngModel)]="rowData.useTopsLoadContacts" binary="true"></p-checkbox>
              </ng-template>
              <ng-template pTemplate="output">
                <p-checkbox name="isActive" [(ngModel)]="rowData.useTopsLoadContacts" binary="true" disabled="true"> </p-checkbox>
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-checkbox name="isActive" [(ngModel)]="rowData.useCustomerFuel" binary="true"></p-checkbox>
              </ng-template>
              <ng-template pTemplate="output">
                <p-checkbox name="isActive" [(ngModel)]="rowData.useCustomerFuel" binary="true" disabled="true"> </p-checkbox>
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-checkbox name="isActive" [(ngModel)]="rowData.autoApplyCarrierGroup" binary="true"></p-checkbox>
              </ng-template>
              <ng-template pTemplate="output">
                <p-checkbox name="isActive" [(ngModel)]="rowData.autoApplyCarrierGroup" binary="true" disabled="true"> </p-checkbox>
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-checkbox name="isActive" [(ngModel)]="rowData.arSpotQuoteEligible" binary="true"></p-checkbox>
              </ng-template>
              <ng-template pTemplate="output">
                <p-checkbox name="isActive" [(ngModel)]="rowData.arSpotQuoteEligible" binary="true" disabled="true"> </p-checkbox>
              </ng-template>
            </p-cellEditor>
          </td>
          <td style="text-align: center">
            <button
              *ngIf="!editing && !editingRow"
              pButton
              type="button"
              pInitEditableRow
              icon="pi pi-pencil"
              class="p-button-info"
              (click)="onRowEditInit(rowData)"
            ></button>
            <button
              *ngIf="editing"
              pButton
              type="button"
              pSaveEditableRow
              icon="pi pi-check"
              class="p-button-success"
              style="margin-right: 0.5em"
              (click)="onRowEditSave(rowData)"
            ></button>
            <button
              *ngIf="editing"
              pButton
              type="button"
              pCancelEditableRow
              icon="pi pi-times"
              class="p-button-danger"
              (click)="onRowEditCancel(rowData, ri, s)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
