import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActionItemTotalCountData, ServiceResponse } from '../models';
import { mapResponse } from '../operators/map-response';

@Injectable()
export class ActionItemService {
  constructor(private http: HttpClient) {}

  getActionItemCount(): Observable<ActionItemTotalCountData> {
    return this.http.get<ServiceResponse<ActionItemTotalCountData>>(`${environment.apiUrl}/api/action-items/count`).pipe(mapResponse());
  }
}
