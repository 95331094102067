export interface NoteData {
  loadId: string;
  note: string;
  createBy: string;
  createDtTm: Date;
}

export const defaultNotesData: NoteData = {
  loadId: null,
  note: null,
  createBy: null,
  createDtTm: null,
};
