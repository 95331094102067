import { DefaultPageableResult, PageableResult } from 'src/app/shared/models';
import { ShippingLoadView } from 'src/app/shared/models/shipping-load-view';
import { createReducer, PageableQueryHelper } from 'src/app/shared/utilities';
import { ShippingBookedActions, ShippingBookedActionTypes } from '../actions';

export interface ShippingBookedState {
  loading: boolean;
  bookedLoads: PageableResult<ShippingLoadView>;
  queryHelper: PageableQueryHelper;
}
const initialState: ShippingBookedState = {
  loading: false,
  bookedLoads: DefaultPageableResult.Create<ShippingLoadView>(),
  queryHelper: PageableQueryHelper.default(),
};

const _ShippingBookedReducer = createReducer(
  initialState,
  function (state: ShippingBookedState = initialState, action: ShippingBookedActions): ShippingBookedState {
    switch (action.type) {
      case ShippingBookedActionTypes.Load_Shipping_Booked: {
        return { ...state, loading: true, bookedLoads: { ...state.bookedLoads, data: [] } };
      }
      case ShippingBookedActionTypes.Load_Shipping_Booked_Success: {
        return { ...state, loading: false, bookedLoads: action.payload };
      }
      case ShippingBookedActionTypes.Load_Shipping_Booked_Failure: {
        return { ...state, loading: false };
      }
      case ShippingBookedActionTypes.Update_Shipping_Booked_Query: {
        return { ...state, queryHelper: action.payload };
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function ShippingBookedReducer(state: ShippingBookedState, action: ShippingBookedActions): ShippingBookedState {
  return _ShippingBookedReducer(state, action);
}
export const getBookedLoading = (state: ShippingBookedState) => state.loading;
export const getBookedLoads = (state: ShippingBookedState) => state.bookedLoads;
