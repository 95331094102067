import { MenuItem } from 'primeng/api';
import { VisibilityBadge } from 'src/app/shared/models';
import { createReducer } from '../../../shared/utilities';
import { AdminMenuActions, AdminMenuActionTypes } from '../actions';

export interface AdminMenuState {
  loading: boolean;
  loaded: boolean;
  entities: MenuItem[];
  visibilityBadge: VisibilityBadge;
}

const initialState: AdminMenuState = {
  loading: false,
  loaded: false,
  entities: [],
  visibilityBadge: null,
};

const _adminMenuReducer = createReducer(
  initialState,
  function (state: AdminMenuState = initialState, action: AdminMenuActions): AdminMenuState {
    switch (action.type) {
      case AdminMenuActionTypes.Load: {
        return Object.assign({}, state, {
          loading: true,
        });
      }
      case AdminMenuActionTypes.Load_Success: {
        const data = action.payload;
        return Object.assign({}, state, {
          loading: false,
          loaded: true,
          entities: data,
        });
      }
      case AdminMenuActionTypes.Load_Failure: {
        return Object.assign({}, state, {
          loading: false,
          loaded: false,
        });
      }
      default:
        return state;
    }
  }
);
/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function adminMenuReducer(state: AdminMenuState, action: AdminMenuActions): AdminMenuState {
  return _adminMenuReducer(state, action);
}

export const getLoading = (state: AdminMenuState) => state.loading;
export const getLoaded = (state: AdminMenuState) => state.loaded;
export const getEntities = (state: AdminMenuState) => state.entities;
