<div class="d-flex">
  <a class="a text-primary" (click)="downloadDocument()" [ngClass]="{ disabled: loading }">
    <div>
      {{ getDocumentDescription() }}
    </div>
    <div class="text-muted">
      {{ document.fileName }}
    </div>
  </a>
  <div class="small-spinner-container ml-3" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="ml-auto" *ngIf="!this.isReadOnly">
    <button
      kbxlActionCheck="loadshop.ui.carrier.myloads.documents.remove"
      class="p-button-danger"
      pButton
      icon="pi pi-trash"
      (click)="deleteDocument()"
      title="Delete Document"
    ></button>
  </div>
</div>
