<div class="page-container" #loadContiner>
  <div class="loading-indicator" *ngIf="loading || processing">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div *ngIf="load?.loadErrorMessage && load?.loadErrorMessage.length > 0">
    <div class="alert alert-warning" role="alert">
      {{ load.loadErrorMessage }}
    </div>
  </div>
  <div *ngIf="load">
    <ul class="error-container list-unstyled" *ngIf="loadDetailErrors && loadDetailErrors.length > 0">
      <li *ngFor="let error of loadDetailErrors">{{ error.message }}</li>
    </ul>
    <div class="row" *ngIf="shouldShowCarrierIneligibleWarning()">
      <div class="col-12">
        <kbxl-carrier-ineligible-warning></kbxl-carrier-ineligible-warning>
      </div>
    </div>
    <div class="d-flex flex-column book-container">
      <div *ngIf="loadStops.length > 0 && loadStops.length > 2">
        <span class="p-tag p-tag-rounded stop-badge">Stops: {{ loadStops?.length }}</span>
      </div>
      <div class="d-flex flex-column flex-md-row mb-2 justify-content-between">
        <div class="d-flex align-items-center">
          <!-- dot and line -->
          <div class="d-flex flex-column mr-1">
            <div class="trip-marker-icon mt-2 mb-1">
              <fa-icon [icon]="['far', 'circle']" size="md"></fa-icon>
            </div>
            <div class="trip-ellipsis-icon">
              <fa-icon [icon]="['fas', 'ellipsis-v']" size="md"></fa-icon>
            </div>
            <div class="trip-ellipsis-icon">
              <fa-icon [icon]="['fas', 'ellipsis-v']" size="md"></fa-icon>
            </div>
            <div class="trip-ellipsis-icon">
              <fa-icon [icon]="['fas', 'ellipsis-v']" size="md"></fa-icon>
            </div>
            <div class="trip-ellipsis-icon">
              <fa-icon [icon]="['fas', 'ellipsis-v']" size="md"></fa-icon>
            </div>
            <div class="trip-marker-icon mt-1 mb-2">
              <fa-icon [icon]="['fas', 'map-marker-alt']" size="md"></fa-icon>
            </div>
          </div>
          <!-- trip details -->
          <div class="d-flex flex-column justify-content-between">
            <!-- origin -->
            <div>
              <div class="trip-text">{{ origin.city | titlecase }}, {{ origin.state }}{{ getPostalCodeDisplay(origin) }}</div>
              <div class="trip-stop-text time-text" *ngIf="loadStops.length > 0 && loadStops.length <= 2">
                <div class="trip-stop-text text-nowrap" *ngIf="loadStops[0].earlyDtTm && loadStops[0].earlyDtTm !== loadStops[0].lateDtTm">
                  <span> ({{ loadStops[0].apptType }}</span>
                  {{ loadStops[0].earlyDtTm | date: 'short' }}
                  <br />{{ loadStops[0].lateDtTm | date: 'short' }})
                </div>
                <div class="trip-stop-text text-nowrap" *ngIf="!loadStops[0].earlyDtTm && loadStops[0].earlyDtTm !== loadStops[0].lateDtTm">
                  <span> ({{ loadStops[0].apptType }}</span>
                  {{ loadStops[0].lateDtTm | date: 'short' }})
                </div>
                <span class="trip-stop-text text-nowrap" *ngIf="!loadStops[0] || loadStops[0].earlyDtTm === loadStops[0].lateDtTm">
                  ({{ loadStops[0].apptType }} {{ loadStops[0].lateDtTm | date: 'short' }})
                </span>
                <div class="eta-text orig-eta-text">
                  <div class="text-nowrap" *ngIf="latestOrigApptLabel">
                    {{ latestOrigApptLabel }}
                  </div>
                  <div class="text-nowrap" *ngIf="latestOrigEtaLabel">
                    {{ latestOrigEtaLabel }}
                  </div>
                </div>
              </div>
            </div>
            <div class="my-2 py-1">&nbsp;</div>
            <!-- destination -->
            <div>
              <div class="trip-text">{{ dest.city | titlecase }}, {{ dest.state }}{{ getPostalCodeDisplay(dest) }}</div>
              <div class="trip-stop-text time-text" *ngIf="loadStops.length > 0 && loadStops.length <= 2">
                <div class="trip-stop-text" *ngIf="loadStops[1].earlyDtTm && loadStops[1].earlyDtTm !== loadStops[1].lateDtTm">
                  <span> ({{ loadStops[1].apptType }}</span>
                  {{ loadStops[1].earlyDtTm | date: 'short' }}
                  <br />{{ loadStops[1].lateDtTm | date: 'short' }})
                </div>
                <div class="trip-stop-text" *ngIf="!loadStops[1].earlyDtTm && loadStops[1].earlyDtTm !== loadStops[1].lateDtTm">
                  <span> ({{ loadStops[1].apptType }}</span>
                  {{ loadStops[1].lateDtTm | date: 'short' }})
                </div>
                <span class="trip-stop-text" *ngIf="!loadStops[1] || loadStops[1].earlyDtTm === loadStops[1].lateDtTm">
                  ({{ loadStops[1].apptType }} {{ loadStops[1].lateDtTm | date: 'short' }})
                </span>
                <div class="eta-text">
                  <div class="text-nowrap" *ngIf="latestDestApptLabel">
                    {{ latestDestApptLabel }}
                  </div>
                  <div class="text-nowrap" *ngIf="latestDestEtaLabel">
                    {{ latestDestEtaLabel }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="book-load-container ml-auto ml-sm-2" *ngIf="!load?.restrictiveViewOnly">
          <div class="d-flex flex-column" *ngIf="canBook()">
            <button
              pButton
              type="button"
              label="Book Load"
              [disabled]="load?.counterOffer?.disableBookButton"
              (click)="tryBookLoad()"
              kbxlActionCheck="loadshop.ui.marketplace.loads.book"
            ></button>
            <div kbxlActionCheck="loadshop.ui.carrier.counteroffer.request">
              <button
                pButton
                *ngIf="load?.counterOffer?.allowCounterOffer"
                type="button"
                class="p-button-outlined p-button-secondary mt-4"
                [label]="load?.counterOffer?.hasPendingCounterOffer ? 'View Counter Offer' : 'Counter Offer'"
                (click)="viewCounterOffer()"
              ></button>
            </div>
          </div>

          <div
            *ngIf="load.isAccepted || load.loadTransaction.transactionType == 'Removed'"
            class="d-flex load-detail-item ml-auto mt-2 mt-md-0"
          >
            <div class="d-flex flex-column">
              <span
                class="load-detail-label mr-1 text-nowrap"
                *ngIf="load.tenderedToCarrier && load.tenderedUser?.fullName"
                kbxlActionCheck="loadshop.ui.load.tendertocarrier.viewtenderedby"
              >
                Tendered By:
              </span>
              <span class="load-detail-label mr-1 text-nowrap">Load Booked:</span>
              <span
                class="load-detail-label mr-1 text-nowrap {{ load.isShipperChatEnabled && isShippingDetail ? 'booked-chat-line' : '' }}"
                *ngIf="load.bookedUser?.firstName"
              >
                <span *ngIf="load.tenderedToCarrier">Approved By:</span>
                <span *ngIf="!load.tenderedToCarrier">Booked By:</span>
              </span>
              <span class="load-detail-label mr-1 text-nowrap" *ngIf="load.scac">SCAC:</span>
              <span class="load-detail-label mr-1 text-nowrap" *ngIf="load.bookedUserCarrierName">Carrier:</span>
              <span class="load-detail-label mr-1 text-nowrap" *ngIf="load.bookedUser?.email">Email: </span>
              <span class="load-detail-label mr-1 text-nowrap" *ngFor="let phone of load.bookedUser?.cellPhoneNumbers">Cell:</span>
            </div>
            <div class="d-flex flex-column">
              <div class="contact-container d-flex flex-column">
                <div
                  class="d-flex align-items-center justify-content-end"
                  *ngIf="load.tenderedToCarrier && load.tenderedUser?.fullName"
                  kbxlActionCheck="loadshop.ui.load.tendertocarrier.viewtenderedby"
                >
                  {{ load.tenderedUser?.fullName | titlecase }}
                </div>
                <span class="load-detail-value load-booked-value">
                  {{ load.bookedDate | date: 'short' }}
                </span>
                <div class="d-flex align-items-center justify-content-end" *ngIf="load.bookedUser && !load.isShipperChatEnabled">
                  {{ load.bookedUser?.firstName | titlecase }}
                </div>
                <div
                  kbxlFeature="chat"
                  class="d-flex align-items-center justify-content-end"
                  *ngIf="load.bookedUser && load.isShipperChatEnabled"
                >
                  <div class="chat-now" *ngIf="shouldShowBookingUserChatNow(); else bookedUserNameOnly">
                    <kbxl-chat-user
                      [userId]="load.bookedUser?.identUserId"
                      [userName]="load.bookedUser?.firstName"
                      (chatNow)="chatWithBookingUserClick()"
                    ></kbxl-chat-user>
                  </div>
                  <ng-template #bookedUserNameOnly>
                    {{ load.bookedUser?.firstName | titlecase }}
                  </ng-template>
                </div>
                <span class="text-nowrap" *ngIf="load.scac">{{ load.scac | uppercase }}</span>
                <div class="booked-carrierName">
                  <span class="text-truncate" *ngIf="load.bookedUserCarrierName" [title]="load.bookedUserCarrierName">{{
                    load.bookedUserCarrierName | titlecase
                  }}</span>
                </div>
                <div class="booked-email d-flex" *ngIf="load.bookedUser?.email">
                  <a class="text-truncate" href="mailto: {{ load.bookedUser.email }}" [title]="load.bookedUser.email"
                    >{{ load.bookedUser.email | lowercase }}
                  </a>
                  <kbxl-copy-to-clipboard class="ml-1" [text]="load.bookedUser.email | lowercase"></kbxl-copy-to-clipboard>
                </div>
                <div class="contact-phone text-nowrap" *ngFor="let phone of load.bookedUser?.cellPhoneNumbers">
                  <a href="tel:{{ phone }}">{{ phone }}</a>
                </div>
                <div class="contact-phone text-nowrap" *ngFor="let phone of load.bookedUser?.phoneNumbers">
                  <a href="tel:{{ phone }}">{{ phone }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isShippingDetail && load.isPlatformPlus && !load.restrictiveViewOnly" class="load-status-container my-2">
        <p-panel styleClass="load-status-panel">
          <p-header class="w-100">
            <div class="d-flex justify-content-between">
              <span>{{ viewingCurrentStatus ? 'Current Load Status' : 'Load Statuses' }}</span>
              <a class="text-primary status-button" *ngIf="accepted || delivered || pendingPod" (click)="toggleLoadStatusView($event)">{{
                viewingCurrentStatus ? 'View All' : 'View Current'
              }}</a>
            </div>
          </p-header>
          <ng-container *ngIf="!accepted && !delivered && !pendingPod">
            <div>
              <span class="font-weight-bold">Pending Acceptance</span>
            </div>
          </ng-container>
          <ng-container *ngIf="accepted || delivered || pendingPod">
            <ng-container
              *ngIf="viewingCurrentStatus"
              [ngTemplateOutlet]="statusDisplay"
              [ngTemplateOutletContext]="{ status: loadStatus, loading: loadingStatus }"
            ></ng-container>
            <ng-container *ngIf="!viewingCurrentStatus">
              <div *ngFor="let status of allStatuses; let i = index">
                <hr
                  *ngIf="i > 0 && status?.statusTypeDesc && status.statusTypeDesc.indexOf('LATE APPOINTMENT') < 0"
                  class="detail-horizontal-rule"
                />
                <ng-container
                  *ngIf="status?.statusTypeDesc && status.statusTypeDesc.indexOf('LATE APPOINTMENT') < 0"
                  [ngTemplateOutlet]="allStatusDisplay"
                  [ngTemplateOutletContext]="{ status: status }"
                ></ng-container>
              </div>
              <div *ngIf="allStatuses === null || allStatuses.length === 0">
                <p>No load statuses found.</p>
              </div>
            </ng-container>
          </ng-container>
        </p-panel>
      </div>
    </div>
    <div class="d-flex justify-content-end" *ngIf="!load?.restrictiveViewOnly">
      <div *ngIf="loadActionMenuItems && loadActionMenuItems.length > 0">
        <p-menu
          #menu
          [popup]="true"
          [model]="loadActionMenuItems"
          (onShow)="showMenu(true)"
          (onHide)="showMenu(false)"
          appendTo="body"
          [baseZIndex]="2000"
        ></p-menu>
        <button
          pButton
          type="button"
          icon="pi pi-bars"
          iconPos="right"
          class="p-button-teal"
          label="Load Actions"
          (click)="menu.toggle($event)"
        ></button>
      </div>
    </div>

    <hr class="detail-horizontal-rule" />

    <div class="rate-container row pb-0" *ngIf="!load?.restrictiveViewOnly; else restrictiveLoadRateContainer">
      <div class="col-4">
        <div class="d-flex flex-column justify-content-between">
          <div class="all-in-rate-container" >
            <div class="all-in-rate-value">             
              {{ load.rateBreakDownsData && !!showRateBreakDowns ? ( load.rateBreakDownsData[0].totalRate | currency) : (load.lineHaulRate + load.fuelRate | currency) }}
            </div>
            <div class="all-in-rate-text">Total Rate</div>
          </div>                    
        </div>
      </div>
      <div class="col-8 linehaul-rate-container ml-auto pl-0" *ngIf="!!showRateBreakDowns">
        <div class="d-flex justify-content-end load-detail-item">
          <div class="d-flex flex-column mr-1" *ngFor="let rate of load.rateBreakDownsData">
            <div class="d-flex">
              <span class="load-detail-label mr-1">Line Haul:</span>
              <span class="load-detail-value ml-auto">{{ rate.lineHaulRate | currency }}</span>
            </div>
            <div class="d-flex" *ngIf="!load.usesAllInRates">
              <span class="load-detail-label mr-1">
                <span *ngIf="rate.fuelProgramType === fuelProgramTypeEnum.BTF">
                  <span *ngIf="load.isEstimatedFSC">*Est</span>
                  BTF
                </span>
                <span *ngIf="rate.fuelProgramType === fuelProgramTypeEnum.DOE">DOE</span>
                FSC:</span
              >
              <span class="load-detail-value ml-auto">{{ rate.fuelRate | currency }}</span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end load-detail-item">
          <span class="load-detail-label mr-2">Shipper’s Order Number:</span>
          <span class="load-detail-value">
            <span *ngIf="isShippingDetail && origin && origin.city">{{ load.referenceLoadDisplay }}</span>
            <a *ngIf="!isShippingDetail && origin && origin.city" [routerLink]="['/loads/detail', load.loadId]">{{
              load.referenceLoadDisplay
            }}</a>
          </span>
        </div>
      </div>
      <div class="col-8 linehaul-rate-container ml-auto" *ngIf="!showRateBreakDowns">
        <div class="d-flex justify-content-end load-detail-item">
          <div class="d-flex flex-column text-right mr-2">
            <div *ngIf="!(isCarrierDetail && load.usesAllInRates)">
              <span class="load-detail-label">Line Haul:</span>
            </div>
            <div *ngIf="!load.usesAllInRates">
              <span class="load-detail-label" *ngIf="!load.isEstimatedFSC; &quot;text-right&quot;">FSC:</span>
              <span class="load-detail-label" *ngIf="load.isEstimatedFSC"><i>* Est FSC:</i></span>
            </div>
            <div>
              <span class="load-detail-label">Shipper’s Order Number:</span>
            </div>
          </div>
          <div class="d-flex flex-column text-right">
            <div *ngIf="!(isCarrierDetail && load.usesAllInRates)">
              <fa-icon
                *ngIf="isShippingDetail && load.hasDynamicPrice"
                class="dynamic-pricing pr-1"
                title="Dynamic Pricing Enabled"
                [icon]="['fas', 'binoculars']"
                size="md"
              ></fa-icon>
              <span class="load-detail-value">{{ load.lineHaulRate | currency }}</span>
            </div>
            <div *ngIf="!load.usesAllInRates">
              <span class="load-detail-value">{{ load.fuelRate | currency }}</span>
            </div>
            <div>
              <span class="load-detail-value">
                <span *ngIf="isShippingDetail && origin && origin.city">{{ load.referenceLoadDisplay }}</span>
                <a *ngIf="!isShippingDetail && origin && origin.city" [routerLink]="['/loads/detail', load.loadId]">{{
                  load.referenceLoadDisplay
                }}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #restrictiveLoadRateContainer>
      <div class="rate-container row pb-0">
        <div class="col-4 all-in-rate-container">&nbsp;</div>
        <div class="col-8 linehaul-rate-container">
          <div class="d-flex justify-content-end load-detail-item">
            <div class="d-flex flex-column text-right mr-2">
              <div>
                <span class="load-detail-label">Shipper’s Order Number:</span>
              </div>
            </div>
            <div class="d-flex flex-column text-right">
              <div>
                <span class="load-detail-value">
                  <span>{{ load.referenceLoadDisplay }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <hr class="detail-horizontal-rule" />

    <div class="contact-container row" *ngIf="load.contacts && load.contacts.length > 0 && !load.restrictiveViewOnly">
      <div class="col-8">
        <div class="for-assistance-text">For assistance, contact:</div>
        <div class="d-flex align-items-center">
          <div kbxlFeature="chat" *ngIf="shouldShowShipperContactChatNow(); else userNameOnly">
            <kbxl-chat-user
              [userId]="load.contacts[0].identUserId"
              [userName]="load.contacts[0].display"
              (chatNow)="chatWithShipperContactClick()"
            ></kbxl-chat-user>
          </div>
          <ng-template #userNameOnly>
            <div class="contact-name">
              {{ load.contacts[0].display | titlecase }}
            </div>
          </ng-template>
        </div>
        <div class="contact-phone" [ngClass]="{ 'd-none': load.contacts[0]?.phone?.length === 0 }">
          <a href="tel:{{ load.contacts[0].phone }}">{{ load.contacts[0].phone }}</a>
        </div>
        <div *ngIf="contacts.length > 1">
          <div class="contact-email">
            <a href="mailto:{{ contacts[1] }}">{{ contacts[1] }}</a>
          </div>
        </div>
        <div *ngIf="contacts.length > 0">
          <div class="contact-email">
            <a href="mailto:{{ load.contacts[0].email }}">{{ contacts[0] }}</a>
          </div>
        </div>
      </div>
      <div class="col-4 text-center" kbxlActionCheck="loadshop.ui.notes.view">
        <kbxl-notes-count [loadId]="load?.loadId" [user]="user" class="notes-lg" iconSize="2x"></kbxl-notes-count>
      </div>
    </div>
    <div *ngIf="showLoadStops" class="stops-container">
      <hr class="detail-horizontal-rule" />
      <label class="load-detail-header"> Stops </label>
      <p-carousel [value]="loadStops" [numVisible]="loadStops.length > 2 ? 3 : 2">
        <ng-template let-stop pTemplate="item">
          <div class="ml-2">
            <kbxl-load-stop [stop]="stop" [total]="loadStops.length" [options]="loadStopOptions"></kbxl-load-stop>
          </div>
        </ng-template>
      </p-carousel>
    </div>

    <hr class="detail-horizontal-rule" />

    <div class="comments-container row" *ngIf="!load?.restrictiveViewOnly">
      <div class="comments-item col-5">
        <div class="pb-2">
          <strong class="font-weight-bold">Equipment:</strong> {{ load.equipmentCategoryId || 'Other' }} - {{ load.equipmentTypeDisplay }}
        </div>
        <div class="pb-2"><strong class="font-weight-bold">Weight:</strong> {{ load.weight | number }}lbs ({{ load.commodity }})</div>
        <div class="pb-2"><strong class="font-weight-bold">Transit Distance:</strong> {{ load.miles }} miles</div>
      </div>
      <div class="comments-item col-7">
        <div class="pb-2" *ngIf="load?.serviceTypes?.length > 0">
          <div class="comments-label d-inline-block">Required Services:</div>
          <span class="ml-1">{{ getServiceTypes() }}</span>
        </div>
        <div *ngIf="hasComments">
          <div class="comments-label pb-2">Comments:</div>
          <p-scrollPanel [style]="{ width: '100%', maxHeight: '200px' }">
            <div class="special-instructions" [innerHTML]="load.comments"></div>
          </p-scrollPanel>
        </div>
      </div>
      <div *ngIf="load.carb" class="comments-item col-12 mb-2">
        The tractor used on this load must be CARB compliant with California’s Truck and Bus regulation (Title 13, California Code of
        Regulations, Section 2025).
      </div>
      <div *ngIf="load.carbAdj" class="comments-item col-12">
        The route for this load could involve driving through the state of California. You must confirm that either this load will not be
        driven through California or, if so, that the tractor used is CARB compliant with California’s Truck and Bus regulation (Title 13,
        California Code of Regulations, Section 2025).
      </div>
    </div>
    <div kbxlFeature="accessorials" *ngIf="!load?.restrictiveViewOnly">
      <hr class="detail-horizontal-rule" />
      <kbxl-load-accessorial-requests
        [load]="load"
        [isShippingDetail]="isShippingDetail"
        [isReadOnly]="isReadOnly"
        [user]="user"
      ></kbxl-load-accessorial-requests>
    </div>
    <div *ngIf="shouldDisplayDocuments() && !load.restrictiveViewOnly">
      <hr class="detail-horizontal-rule" />
      <kbxl-document-list [documents]="load.loadDocuments" [loadId]="load.loadId" [isReadOnly]="isReadOnly">
        <kbxl-share-link
          class="mb-2"
          *ngFor="let shareLink of load.shareLinks"
          [link]="shareLink.shareLink"
          [notificationType]="shareLink.notificationType"
          [loadId]="load.loadId"
        ></kbxl-share-link>
      </kbxl-document-list>
    </div>
    <div class="row" *ngIf="!isShippingDetail && !load.restrictiveViewOnly">
      <div class="col">
        <hr class="detail-horizontal-rule" />
        <span class="font-weight-bold teal-text"
          >ASSET ONLY - No Brokering or use of Partner Carriers will be allowed on this freight. Unauthorized actions can lead to removal
          from platform</span
        >
      </div>
    </div>
    <ng-container *ngIf="!isShippingDetail && !load.restrictiveViewOnly">
      <hr class="detail-horizontal-rule" />

      <div class="map-container">
        <kbxl-map [stops]="loadStops" (directions)="setDirections($event)"></kbxl-map>
      </div>
      <div class="pt-2">
        <div class="d-flex align-items-center">
          <fa-icon class="mr-2" [icon]="['fas', 'exclamation-triangle']" size="lg"></fa-icon>
          <div>
            <strong class="mr-1">The displayed route is for illustration purposes only.</strong>
            <span>This route should not be construed as the exact route to be taken.</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<p-dialog
  *ngIf="!load?.restrictiveViewOnly"
  header="Confirmation"
  [(visible)]="showRemoveLoadPopup"
  [style]="{ width: '600px' }"
  [appendTo]="'body'"
  [baseZIndex]="51"
>
  <div class="d-flex align-items-center justify-content-center my-3 font-weight-bold">
    <i class="pi pi-exclamation-triangle mr-2"></i> {{ removeText }}
  </div>
  <div *ngIf="removeMode === 1">
    This will remove the carrier from the load and send the load to the unassigned queue on the Posted Tab. It Must be manually posted
    again.
  </div>
  <div *ngIf="removeMode === 2">
    This will remove the carrier from the load. The load will be deleted from Loadshop.
    <span *ngIf="load?.isExternalLoad">Please communicate with the shipper the load has been deleted off of Loadshop.</span>
  </div>
  <div class="my-2">
    <div class="kbxl-field-label">
      <label class="control-label">Please select a reason for removal</label>
    </div>
    <p-dropdown
      [options]="ratingQuestions | toSelectItem"
      [(ngModel)]="removeRatingQuestionAnswer"
      placeholder="Select Reason"
      [styleClass]="'p-dropdown-primary'"
      dropdownIcon="pi pi-caret-down"
    >
    </p-dropdown>
  </div>
  <div>
    <textarea
      placeholder="Additional Comments"
      [rows]="5"
      [cols]="50"
      pInputTextarea
      [(ngModel)]="removeComment"
      name="comments"
      maxlength="4000"
    ></textarea>
  </div>
  <p-footer>
    <div class="d-flex">
      <button
        type="button"
        [ngClass]="{ 'p-button-warning': removeMode === 1, 'p-button-danger': removeMode === 2 }"
        pButton
        (click)="removeConfirmed()"
        [label]="removeMode === 1 ? 'Remove Carrier' : 'Remove Load'"
        [disabled]="removeRatingQuestionAnswer?.length === 0"
      ></button>
      <button type="button" class="p-button-outlined p-button-secondary" pButton (click)="cancelRemovePopup()" label="Cancel"></button>
    </div>
  </p-footer>
</p-dialog>

<ng-template #statusDisplay let-status="status" let-loading="loading">
  <div>
    <span class="font-weight-bold">{{ status?.description }}</span>
    <div class="small-spinner-container ml-2" *ngIf="status?.processingUpdates || loadingStatus">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <div *ngIf="status?.locationLabel" style="margin-top: 5px">
    <span>{{ status.locationLabel }}</span>
  </div>
  <div *ngIf="status?.dateLabel" style="margin-top: 15px">
    <span>{{ status?.dateLabel }}</span>
  </div>
</ng-template>
<ng-template #allStatusDisplay let-status="status">
  <div>
    <span class="font-weight-bold">{{ status?.statusTypeDesc }}</span>
  </div>
  <div style="margin-top: 5px">
    <span>{{ status.city }}, {{ status.state }}, {{ status.country }}</span>
  </div>
  <div *ngIf="status?.statusDate" style="margin-top: 15px">
    <span>{{ status?.statusDate | date: 'short' }}</span>
  </div>
</ng-template>
<p-dialog
  *ngIf="!load?.restrictiveViewOnly"
  [appendTo]="'body'"
  [baseZIndex]="51"
  header="Book Load Confirmation"
  [(visible)]="showBookedLoadModal"
  [modal]="true"
  [closeOnEscape]="true"
>
  <kbxl-book-load-modal
    [load]="load"
    (book)="bookLoad($event)"
    (cancelBook)="showBookedLoadModal = false"
    [processing]="processing"
  ></kbxl-book-load-modal>
</p-dialog>
