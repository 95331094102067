import { Conversation, ConversationMember, ConversationStatus } from '../../conversation-api';
import { ChatAttributes } from '../constants';
import { ExtendedMessage } from './extended-message';

export class ExtendedConversation implements Conversation {
  constructor(init?: Partial<ExtendedConversation>) {
    let messages: ExtendedMessage[];
    if (init && init.messages) {
      messages = init.messages.map((x) => new ExtendedMessage({ ...x })).slice();
    }

    Object.assign(this, { ...init, messages: messages });
  }

  hasUnreadMessages: boolean;
  id?: number;
  userId?: string;
  status?: ConversationStatus;
  applicationId?: number;
  applicationCode?: string;
  messages?: Array<ExtendedMessage>;
  attributes?: { [key: string]: string[] };
  lastChangeDate?: Date;

  members?: Array<ConversationMember>;

  // new client properties
  selected?: boolean;
  recentlyJoined?: boolean;
  hasMoreMessages?: boolean;

  getShipperName() {
    return ((this.attributes || {})[ChatAttributes.shipperName] || [''])[0];
  }

  getCarrierName() {
    const name = ((this.attributes || {})[ChatAttributes.carrierName] || [''])[0];

    const scac = this.getCarrierId();
    if (scac && scac.length > 0) {
      return `${scac} - ${name}`;
    }
    return name;
  }

  getCarrierId() {
    return ((this.attributes || {})[ChatAttributes.dmCarrier] || [''])[0];
  }

  getShipperId() {
    return ((this.attributes || {})[ChatAttributes.dmShipper] || [''])[0];
  }

  isDirectMessage(): boolean {
    return this.attributes && Object.entries(this.attributes).findIndex((x) => x.includes(ChatAttributes.dmGroup)) >= 0;
  }

  getCarrierUserFullName() {
    return ((this.attributes || {})[ChatAttributes.dmCarrierUser] || [''])[0];
  }

  getShipperUserFullName() {
    return ((this.attributes || {})[ChatAttributes.dmShipperUser] || [''])[0];
  }
  getConversationUserIds(): string[] {
    return (this.attributes || {})[ChatAttributes.dmGroup] || [''];
  }
}
