import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ConfirmationService, Message, TreeNode } from 'primeng/api';
import { SearchCriteriaService } from 'src/app/load-board/services';
import { Place, State, UserLane } from '../../../shared/models';
import { deepCopyLane, groupBy, validateUserlane } from '../../../shared/utilities';

@Component({
  selector: 'kbxl-user-lane-detail',
  templateUrl: './user-lane-detail.component.html',
  styleUrls: ['./user-lane-detail.component.scss'],
})
export class UserLaneDetailComponent implements OnChanges {
  @Input() lane: UserLane;
  @Input() states: State[];
  @Input() equipment: TreeNode[];
  @Output() updated = new EventEmitter<UserLane>();
  @Output() deleted = new EventEmitter<UserLane>();
  @Output() closed = new EventEmitter<boolean>();
  errors: Message[];
  origin: Place;
  dest: Place;
  adding = true;
  selectedEquipment: TreeNode[];

  constructor(private confirmationService: ConfirmationService, private searchCriteriaService: SearchCriteriaService) {}

  ngOnChanges() {
    this.dest = null;
    this.origin = null;
    this.adding = !this.lane || !this.lane.userLaneId;

    if (this.lane && this.states) {
      this.setOriginDestData(this.lane);
    }

    if (this.lane && this.equipment) {
      this.updateSelectedEquipment();
    }
  }

  update() {
    const lane = deepCopyLane(this.lane);
    lane.equipmentIds = this.buildSelectedEquipment();

    if (this.origin) {
      lane.origCity = this.origin.city;
      if (!this.origin.stateAbbrev && this.origin.state && this.origin.state.length > 2) {
        lane.origState = this.states.find((x) => x.name.toLowerCase() === this.origin.state.toLowerCase()).abbreviation;
      } else {
        lane.origState = this.origin.stateAbbrev;
      }
      lane.origCountry = this.origin.country;
      lane.origLat = this.origin.latitude;
      lane.origLng = this.origin.longitude;
    } else {
      lane.origCity = null;
      lane.origState = null;
      lane.origCountry = null;
      lane.origLat = null;
      lane.origLng = null;
    }
    lane.origDH = lane.origDH || 50;

    if (this.dest) {
      lane.destCity = this.dest.city;
      if (!this.dest.stateAbbrev && this.dest.state && this.dest.state.length > 2) {
        lane.destState = this.states.find((x) => x.name.toLowerCase() === this.origin.state.toLowerCase()).abbreviation;
      } else {
        lane.destState = this.dest.stateAbbrev;
      }
      lane.destCountry = this.dest.country;
      lane.destLat = this.dest.latitude;
      lane.destLng = this.dest.longitude;
    } else {
      lane.destCity = null;
      lane.destState = null;
      lane.destCountry = null;
      lane.destLat = null;
      lane.destLng = null;
    }
    lane.destDH = lane.destDH || 50;

    this.errors = validateUserlane(lane);
    if (!this.errors.length) {
      this.updated.emit(lane);
      this.clear();
    }
  }

  delete() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this favorite lane?',
      accept: () => {
        this.deleted.emit(this.lane);
        this.clear();
      },
    });
  }

  close() {
    this.closed.emit(true);
    this.clear();
  }

  setOriginDestData(model: UserLane) {
    const originDest = this.searchCriteriaService.setOriginDestination(model, this.states);
    this.origin = originDest[0];
    this.dest = originDest[1];
  }

  private updateSelectedEquipment() {
    if (this.lane && this.lane.equipmentIds && this.equipment) {
      const flattenTreeNodes = this.equipment.map((_) => _.children).reduce((acc, value) => acc.concat(value));

      const equipmentTreeNodes = this.lane.equipmentIds.map((currentEquipmentId) => {
        const equipment = flattenTreeNodes.find((_) => _.data.equipmentId === currentEquipmentId);

        if (equipment && equipment.parent) {
          equipment.parent.partialSelected = true;
        }

        return equipment;
      });

      const groupedSelections = groupBy((x) => x.data.categoryId, equipmentTreeNodes);

      groupedSelections.forEach((group) => {
        const treeViewGroup = this.equipment.find((x) => x.key === group.key);

        if (group.items.length === treeViewGroup.children.length) {
          treeViewGroup.partialSelected = false;
          equipmentTreeNodes.push(treeViewGroup);
        }
      });

      this.selectedEquipment = equipmentTreeNodes;
    } else {
      this.selectedEquipment = [];
    }
  }

  private buildSelectedEquipment() {
    return this.selectedEquipment.filter((_) => _.leaf).map((_) => _.data.equipmentId);
  }

  clear() {
    this.origin = null;
    this.dest = null;
    this.errors = [];
  }
}
