import { createSelector } from '@ngrx/store';
import { CoreState } from '../reducers';
import {
  getAppointmentSchedulerConfirmationTypeEntities,
  getCommodityEntities,
  getCustomerLoadTypeEntities,
  getDocumentTypeEntities,
  getDropDownEntities,
  getEquipmentEntities,
  getLoading,
  getLoadshopSettingsEntities,
  getServiceTypeEntities,
  getServiceTypeManualLoadEntities,
  getStateEntities,
  getTransportationModeEntities,
  getUnitOfMeasureEntities,
  getCarrierAuthorizedShipperEntities,
} from '../reducers/startup.reducer';

export const getStartupState = (state: CoreState) => state.startupState;

export const getCommodities = createSelector(getStartupState, getCommodityEntities);
export const getLoadingCommodities = createSelector(getStartupState, getLoading);

export const getAppointmentSchedulerConfirmationTypes = createSelector(getStartupState, getAppointmentSchedulerConfirmationTypeEntities);
export const getLoadingAppointmentSchedulerConfirmationTypes = createSelector(getStartupState, getLoading);

export const getCustomerLoadTypes = createSelector(getStartupState, getCustomerLoadTypeEntities);
export const getLoadingCustomerLoadTypes = createSelector(getStartupState, getLoading);

export const getUnitsOfMeasure = createSelector(getStartupState, getUnitOfMeasureEntities);
export const getLoadingUnitsOfMeasure = createSelector(getStartupState, getLoading);

export const getTransporationModes = createSelector(getStartupState, getTransportationModeEntities);
export const getLoadingTransporationModes = createSelector(getStartupState, getLoading);

export const getServiceTypes = createSelector(getStartupState, getServiceTypeEntities);
export const getManualLoadServiceTypes = createSelector(getStartupState, getServiceTypeManualLoadEntities);
export const getLoadingServiceTypes = createSelector(getStartupState, getLoading);

export const getStates = createSelector(getStartupState, getStateEntities);
export const getLoadingStates = createSelector(getStartupState, getLoading);

export const getCarrierAuthorizedShippers = createSelector(getStartupState, getCarrierAuthorizedShipperEntities);
export const getLoadingCarrierAuthorizedShippers = createSelector(getStartupState, getLoading);

export const getDropDowns = createSelector(getStartupState, (state, typeId) =>
  getDropDownEntities(state)?.filter((x) => x.dropDownOptionTypeId === typeId)
);
export const getLoadingDropDowns = createSelector(getStartupState, getLoading);

export const enum DropDownOptionTypeIds {
  DPInterval = 'DPInterval',
  DPPercentage = 'DPPercentage',
  DPFrequency = 'DPFrequency',
}

export const getEquipment = createSelector(getStartupState, getEquipmentEntities);
export const getLoadingEquipment = createSelector(getStartupState, getLoading);

export const getLoadshopSettings = createSelector(getStartupState, getLoadshopSettingsEntities);
export const getLoadingLoadshopSettings = createSelector(getStartupState, getLoading);

export const getLoadDocumentTypes = createSelector(getStartupState, getDocumentTypeEntities);
export const getLoadingDocumentTypes = createSelector(getStartupState, getLoading);
