import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { firstBy } from 'thenby';
import {
  CounterOfferRequestStatusEnum,
  LoadCounterOfferActionData,
  LoadCounterOfferData,
  LoadCounterOfferGroupData,
  UserModel,
} from '../../../models';

@Component({
  selector: 'kbxl-counter-offer-comments',
  templateUrl: './counter-offer-comments.component.html',
  styleUrls: ['./counter-offer-comments.component.scss'],
})
export class CounterOfferCommentsComponent implements OnChanges {
  @Input() counterOffer: LoadCounterOfferGroupData;
  @Input() activeOffer: LoadCounterOfferData;
  @Input() user: UserModel;
  @Input() loading: boolean;
  @Output() loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  allActions = [];

  constructor() {}
  ngOnChanges(): void {
    if (this.counterOffer) {
      this.updateAllActions();
    }
  }
  getActionHeader(): string {
    return `Actions (${this.allActions.length})`;
  }

  updateAllActions() {
    if (this.activeOffer) {
      // Only show denied or expired messages if they're for the active counter offer
      const deniedOrExpiredOnActiveOffer = this.activeOffer.actions.filter(
        (x) => x.status && (x.status === CounterOfferRequestStatusEnum.Denied || x.status === CounterOfferRequestStatusEnum.Expired)
      );

      // All other action statuses show, regardless of which counter offer they apply to
      const allNonDeniedOrExpiredActions = this.counterOffer.counterOffers
        .map((x) => x.actions)
        .reduce((x, y) => x.concat(y))
        .filter((x) => x.status && x.status !== CounterOfferRequestStatusEnum.Denied && x.status !== CounterOfferRequestStatusEnum.Expired);

      const uniqueActions = [...new Set([...deniedOrExpiredOnActiveOffer, ...allNonDeniedOrExpiredActions])];
      this.allActions = uniqueActions.sort(firstBy((x) => x.createDtTm));
    }
  }
  isUserComment(comment: LoadCounterOfferActionData) {
    if (comment && this.user) {
      if (comment.userId === this.user.userId) {
        return {
          'user-comment': true,
        };
      } else {
        return {
          'other-user-comment': true,
        };
      }
    }
    return '';
  }
}
