import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CommonService } from '../../services/common.service';
import {
  CarrierActionTypes,
  CarrierCarrierScacLoadFailureAction,
  CarrierCarrierScacLoadSuccessAction,
  CarrierGetUsersInCompanyAction,
  CarrierGetUsersInCompanyFailureAction,
  CarrierGetUsersInCompanySuccessAction,
  CarrierLoadFailureAction,
  CarrierLoadSuccessAction,
} from '../actions';

@Injectable()
export class CarrierEffects {
  @Effect()
  $load: Observable<Action> = this.actions$.pipe(
    ofType(CarrierActionTypes.Load),
    switchMap(() =>
      this.commonService.getCarriers().pipe(
        map((data) => new CarrierLoadSuccessAction(data)),
        catchError((err) => of(new CarrierLoadFailureAction(err)))
      )
    )
  );

  @Effect()
  $loadCarrierCarrierScacs: Observable<Action> = this.actions$.pipe(
    ofType(CarrierActionTypes.CarrierCarrierScacLoad),
    switchMap(() =>
      this.commonService.getAllCarrierCarrierScacs().pipe(
        map((data) => new CarrierCarrierScacLoadSuccessAction(data)),
        catchError((err) => of(new CarrierCarrierScacLoadFailureAction(err)))
      )
    )
  );
  @Effect()
  $loadCompanyUsers: Observable<Action> = this.actions$.pipe(
    ofType<CarrierGetUsersInCompanyAction>(CarrierActionTypes.GetUsersInCompany),
    switchMap((action) => {
      if (!action.payload || action.payload.length === 0) {
        return EMPTY;
      }
      return this.commonService.getCarrierCompanyUsers(action.payload).pipe(
        map((data) => new CarrierGetUsersInCompanySuccessAction(data)),
        catchError((err) => of(new CarrierGetUsersInCompanyFailureAction(err)))
      );
    })
  );

  constructor(private commonService: CommonService, private actions$: Actions) {}
}
