import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin.routing.module';
import {
  AccessorialContainerComponent,
  AccessorialDetailComponent,
  AccessorialDetailContainerComponent,
  AccessorialGridComponent,
  AdminContainerComponent,
  AdminHeaderComponent,
  CacheContainerComponent,
  CarrierAgreementsComponent,
  CarrierContainerComponent,
  CarrierProfileContainerComponent,
  CarrierProfileFormComponent,
  CarrierUsersComponent,
  ConfigurationContainerComponent,
  LaneGroupContainerComponent,
  LaneGroupDetailComponent,
  LaneGroupDetailContainerComponent,
  LaneGroupGridComponent,
  LaneSearchComponent,
  ShipperAccessorialContainerComponent,
  ShipperEndpointsModalComponent,
  ShipperMappingModalComponent,
  ShipperProfileContainerComponent,
  UserAdminComponent,
  UserCommunicationContainerComponent,
  UserCommunicationFormComponent,
  UserCommunicationFormContainerComponent,
  UserCommunicationGridComponent,
  UsersContainerComponent,
} from './components';
import { LaneDetailComponent } from './components/lane-management/lane-detail/lane-detail.component';
import { LoggingConfigContainerComponent } from './components/logging';
import {
  CarrierProfileService,
  LaneManagementService,
  ShipperAdminAccessorialService,
  ShipperProfileService,
  UserAdminService,
  UserCommunicationService,
} from './services';
import { CacheService } from './services/cache.service';
import { LoggingConfigService } from './services/logging-config.service';
import { effects, reducers } from './store';

@NgModule({
  imports: [AdminRoutingModule, SharedModule, EffectsModule.forFeature(effects), StoreModule.forFeature('admin', reducers)],
  declarations: [
    AccessorialContainerComponent,
    AccessorialGridComponent,
    AdminContainerComponent,
    UsersContainerComponent,
    UserAdminComponent,
    ShipperAccessorialContainerComponent,
    ShipperMappingModalComponent,
    ShipperProfileContainerComponent,
    CarrierProfileContainerComponent,
    CarrierProfileFormComponent,
    UserCommunicationContainerComponent,
    UserCommunicationGridComponent,
    UserCommunicationFormContainerComponent,
    UserCommunicationFormComponent,
    AccessorialDetailContainerComponent,
    AccessorialDetailComponent,
    LaneGroupContainerComponent,
    LaneGroupGridComponent,
    LaneGroupDetailContainerComponent,
    LaneGroupDetailComponent,
    LaneDetailComponent,
    LaneSearchComponent,
    LoggingConfigContainerComponent,
    AdminHeaderComponent,
    ShipperEndpointsModalComponent,
    CacheContainerComponent,
    CarrierContainerComponent,
    CarrierAgreementsComponent,
    CarrierUsersComponent,
    ConfigurationContainerComponent,
  ],
  providers: [
    ShipperProfileService,
    UserAdminService,
    CarrierProfileService,
    UserCommunicationService,
    ShipperAdminAccessorialService,
    LaneManagementService,
    CacheService,
    LoggingConfigService,
  ],
})
export class AdminModule {}
