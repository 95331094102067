<kbxl-admin-header>
  <kbxl-lane-search
    [equipment]="equipment$ | async | toTreeNodes"
    [states]="states$ | async"
    [isMobile]="isMobile$ | async"
    [criteria]="searchCrit$ | async"
    [shipper]="shipper$ | async"
    (search)="search($event)"
    (clear)="clear()"
    (refreshLanes)="refreshLanes()"
  ></kbxl-lane-search>
</kbxl-admin-header>
<div class="grid-container">
  <kbxl-lane-group-grid
    [groups]="filteredGroups$ | async"
    [loading]="loading$ | async"
    [first]="first$ | async"
    [shipper]="shipper$ | async"
    (firstChange)="firstChange($event)"
    (displayDetail)="displayDetail($event)"
  >
  </kbxl-lane-group-grid>
</div>
<router-outlet></router-outlet>
