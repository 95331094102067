export enum LaneAttributeType {
  ExcludeCarrierGroup,
  IncludeCarrierGroup,
  RateSeeker,
  ShipperContact,
  SpecialInstruction,
  EligibleForQuote
}

export interface LaneAttribute {
  type: LaneAttributeType;
  description: string;
}

export const defaultLaneAttributes: LaneAttribute[] = [
  {
    type: LaneAttributeType.ExcludeCarrierGroup,
    description: 'Exclude Carrier Group',
  },
  {
    type: LaneAttributeType.IncludeCarrierGroup,
    description: 'Include Carrier Group',
  },
  {
    type: LaneAttributeType.RateSeeker,
    description: 'Rate Seeker',
  },
  {
    type: LaneAttributeType.ShipperContact,
    description: 'Shipper Contacts',
  },
  {
    type: LaneAttributeType.SpecialInstruction,
    description: 'Special Instructions',
  },
];
