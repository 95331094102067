import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanDeactivateGuard } from '../../../../core/guards/can-deactivate.guard';
import { BaseComponent } from '../../../../shared/components';
import { BulkOperationData, ShipperAccessorialData, ValidationProblemDetails } from '../../../../shared/models';
import {
  AdminState,
  getLoadingShipperAccessorials,
  getSaveShipperAccessorialProblemDetails,
  getShipperAccessorials,
  ShipperAccessorialBulkOperationAction,
  ShipperAccessorialLoadAction,
} from '../../../store';

@Component({
  selector: 'kbxl-accessorial-container',
  templateUrl: './accessorial-container.component.html',
  styleUrls: ['./accessorial-container.component.scss'],
})
export class AccessorialContainerComponent extends BaseComponent implements OnInit, CanDeactivateGuard {
  loading$: Observable<boolean>;
  globalAccessorials$: Observable<ShipperAccessorialData[]>;
  saveErrors$: Observable<ValidationProblemDetails>;

  formDirty = false;
  constructor(private adminStore: Store<AdminState>, private confirmationService: ConfirmationService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.loading$ = this.adminStore.pipe(select(getLoadingShipperAccessorials));
    this.globalAccessorials$ = this.adminStore.pipe(
      select(getShipperAccessorials),
      map((x) => this.deepClone(x))
    );
    this.saveErrors$ = this.adminStore.pipe(select(getSaveShipperAccessorialProblemDetails));

    // load global accessorials
    this.adminStore.dispatch(new ShipperAccessorialLoadAction(null));
  }

  saveGlobal(accessorials: BulkOperationData<ShipperAccessorialData>[]): void {
    // global accessorials cannot be deleted or added, only updated
    this.adminStore.dispatch(new ShipperAccessorialBulkOperationAction(accessorials, null));
  }
  displayDetail(accessorial: ShipperAccessorialData) {
    const path: [any] = ['maint/configurations/detail'];
    if (accessorial && accessorial.customerAccessorialId > 0) {
      path.push(accessorial.customerAccessorialId);
    }
    this.router.navigate(path);
  }
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.formDirty) {
      return true;
    }

    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        message: 'Changes will not be saved.  Are you sure you want to proceed?',
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        },
      });
    });
  }
}
