import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, PageableQueryHelper } from 'src/app/shared/utilities';
import { LoadBoardBookedActions, LoadBoardBookedActionTypes } from '../../../load-board/store/actions';
import { LoadView } from '../../../shared/models';

export interface LoadBoardBookedState extends EntityState<LoadView> {
  loaded: boolean;
  loading: boolean;
  savingLoadId: string;
  errorLoadId: string;
  phoneError: boolean;
  truckError: boolean;
  totalRecords: number;
  queryHelper: PageableQueryHelper;
}

export const adapter: EntityAdapter<LoadView> = createEntityAdapter<LoadView>({
  selectId: (x) => x.loadId,
});

const initialState: LoadBoardBookedState = adapter.getInitialState({
  loaded: false,
  loading: false,
  savingLoadId: null,
  errorLoadId: null,
  phoneError: false,
  truckError: false,
  totalRecords: 0,
  queryHelper: PageableQueryHelper.default(),
});

const _LoadBoardBookedReducer = createReducer(
  initialState,
  function (state: LoadBoardBookedState = initialState, action: LoadBoardBookedActions): LoadBoardBookedState {
    switch (action.type) {
      case LoadBoardBookedActionTypes.Load: {
        return adapter.removeAll({ ...state, loading: true });
      }
      case LoadBoardBookedActionTypes.Load_Success: {
        return adapter.setAll(action.payload.data, { ...state, loading: false, totalRecords: action.payload.totalRecords });
      }
      case LoadBoardBookedActionTypes.Update_Visibility: {
        return Object.assign({}, state, { savingLoadId: action.payload.loadId });
      }
      case LoadBoardBookedActionTypes.Update_Visibility_Success: {
        return Object.assign({}, state, { errorLoadId: null }, { phoneError: false }, { truckError: false }, { savingLoadId: null });
      }
      case LoadBoardBookedActionTypes.Update_Query: {
        return { ...state, queryHelper: action.payload };
      }

      case LoadBoardBookedActionTypes.Update_Visibility_Failure: {
        return Object.assign(
          {},
          state,
          { errorLoadId: state.savingLoadId },
          { phoneError: action.payload.message.toLowerCase().indexOf('phone') > -1 },
          { truckError: action.payload.message.toLowerCase().indexOf('truck') > -1 },
          { savingLoadId: null }
        );
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function LoadBoardBookedReducer(state: LoadBoardBookedState, action: LoadBoardBookedActions): LoadBoardBookedState {
  return _LoadBoardBookedReducer(state, action);
}

export const getLoaded = (state: LoadBoardBookedState) => state.loaded;
export const getLoading = (state: LoadBoardBookedState) => state.loading;
export const getSavingLoadId = (state: LoadBoardBookedState) => state.savingLoadId;
export const getErrorLoadId = (state: LoadBoardBookedState) => state.errorLoadId;
export const getPhoneError = (state: LoadBoardBookedState) => state.phoneError;
export const getTruckError = (state: LoadBoardBookedState) => state.truckError;
export const getBookedTotalRecords = (state: LoadBoardBookedState) => state.totalRecords;
export const getBookedQueryHelper = (state: LoadBoardBookedState) => state.queryHelper;
export const selectors = adapter.getSelectors();
