<div class="d-flex flex-column align-items-center list-container">
  <div *ngFor="let note of notes" class="list-item mb-3">
    <div class="d-flex flex-column">
      <div class="d-flex font-weight-bold">
        <div>
          {{ note.createBy }}
        </div>
        <div class="ml-1">
          {{ note.createDtTm | date:'short' }}
        </div>
      </div>
      <div class="message-box">
        {{ note.note }}
      </div>
    </div>
  </div>
</div>
