import { Component, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreState, FavoriteDeleteAction, FavoriteUpdateAction, getLoadingFavorites, getUserFavorites } from '../../../../core/store';
import { BaseComponent } from '../../../../shared/components';
import { AllUserFavoriteTypes, UserFavorite } from '../../../../shared/models';

@Component({
  selector: 'kbxl-manage-user-search-modal',
  templateUrl: './manage-user-search-modal.component.html',
  styleUrls: ['./manage-user-search-modal.component.scss'],
})
export class ManageUserSearchModalComponent extends BaseComponent implements OnInit {
  isLoading = false;
  savedSearches$: Observable<UserFavorite[]>;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private store: Store<CoreState>,
    private confirmationService: ConfirmationService,
    private actions$: Actions
  ) {
    super();
  }

  ngOnInit(): void {
    this.savedSearches$ = this.store.pipe(select(getUserFavorites, AllUserFavoriteTypes.MarketplaceSavedSearch));
    this.store.pipe(select(getLoadingFavorites), takeUntil(this.destroyed$)).subscribe((x) => (this.isLoading = x));
  }
  close(): void {
    this.ref.close();
  }
  handleDefaultChange(eventArgs: any, search: UserFavorite): void {
    const payload = this.deepClone(search);
    payload.data.defaultFavorite = eventArgs.checked;

    this.store.dispatch(new FavoriteUpdateAction(payload));
  }

  deleteSearch(search: UserFavorite): void {
    this.confirmationService.confirm({
      message: ' Are you sure you want to delete this search?',
      accept: () => {
        this.store.dispatch(new FavoriteDeleteAction(search));
      },
    });
  }
  trackByFn(idx: number, item: UserFavorite) {
    return item.userFavoriteId;
  }
}
