<kbxl-admin-header></kbxl-admin-header>
<kbxl-user-admin
  (userSearchFilterUpdated)="searchUsers($event)"
  (userSelected)="userSelected($event)"
  (carrierScacChanged)="carrierScacSelected()"
  (userSaved)="saveUser($event)"
  (addUser)="loadIdentityUser($event)"
  [userResults]="userResults$ | async"
  [updatingUser]="updatingUser$ | async"
  [allAuthorizedShippers]="authorizedShippers$ | async"
  [allAuthorizedSecurityRoles]="authorizedSecurityRoles$ | async"
  [allAuthorizedCarrierScacs]="sortedAuthorizedCarrierScacs$ | async"
  [processing]="processing$ | async"
  [createMode]="createMode$ | async"
  (deleteUser)="deleteUser($event)"
>
</kbxl-user-admin>
