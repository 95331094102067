import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { defaultFuelRateEstimateByProgramData, FuelRateEstimateByProgramStateData } from '../../../shared/models';
import { createReducer } from '../../../shared/utilities';
import { FuelRateActions, FuelRateActionTypes } from '../actions';

export type FuelRateState = EntityState<FuelRateEstimateByProgramStateData>;

export const adapter: EntityAdapter<FuelRateEstimateByProgramStateData> = createEntityAdapter<FuelRateEstimateByProgramStateData>({
  selectId: (x) => x.loadId,
});

const initialState: FuelRateState = adapter.getInitialState({});

const _fuelRateReducer = createReducer(
  initialState,
  function (state: FuelRateState = initialState, action: FuelRateActions): FuelRateState {
    switch (action.type) {
      case FuelRateActionTypes.GetFuelRateEstimate: {
        return adapter.upsertOne(
          {
            fuelProgramData: { ...defaultFuelRateEstimateByProgramData, fuelError: false },
            loadId: action.payload.loadId,
            loading: true,
            fuelError: false,
          },
          { ...state }
        );
      }
      case FuelRateActionTypes.GetFuelRateEstimateSuccess: {
        const defaultNewState = { ...state, loading: false };
        if (action && action.payload && action.payload.estimate) {
          return adapter.upsertOne(
            {
              fuelProgramData: { ...action.payload.estimate, fuelError: false },
              loadId: action.payload.loadId,
              loading: false,
              fuelError: false,
            },
            { ...state }
          );
        } else {
          return adapter.upsertOne(
            {
              fuelProgramData: { ...defaultFuelRateEstimateByProgramData, fuelError: true },
              loadId: action.payload.loadId,
              loading: false,
              fuelError: true,
            },
            { ...state }
          );
        }
      }
      case FuelRateActionTypes.GetFuelRateEstimateFailure: {
        return adapter.upsertOne(
          {
            fuelProgramData: { ...defaultFuelRateEstimateByProgramData, fuelError: true },
            loadId: action.payload.loadId,
            loading: false,
            fuelError: true,
          },
          { ...state }
        );
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function fuelRateReducer(state: FuelRateState, action: FuelRateActions): FuelRateState {
  return _fuelRateReducer(state, action);
}

const selectors = adapter.getSelectors();
export const getEntities = (state: FuelRateState) => selectors.selectEntities(state);
export const getAllFuelRates = (state: FuelRateState) => selectors.selectAll(state);
