import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserCommunication } from '../../../../shared/models';
import { SharedState, UserCommunicationDisplayAcknowledgeAction } from '../../../store';

@Component({
  selector: 'kbxl-user-communication-display',
  templateUrl: './user-communication-display.component.html',
  styleUrls: ['./user-communication-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UserCommunicationDisplayComponent {
  @Input() userCommunications: UserCommunication[];
  @Input() processing: boolean;
  @Input() isMobile: boolean;

  constructor(private sharedStore: Store<SharedState>) {}

  onAcknowledge(communication: UserCommunication) {
    this.sharedStore.dispatch(new UserCommunicationDisplayAcknowledgeAction(communication));
    this.userCommunications = null;
  }
}
