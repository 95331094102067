import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Confirmation, MenuItem } from 'primeng/api';
import { TabMenu } from 'primeng/tabmenu';
import { Observable } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CoreState, getMenuEntities } from './core/store';
import { BaseComponent } from './shared/components';
import { User } from './shared/models';
import { getUserProfileEntity } from './user/store';

@Component({
  selector: 'kbxl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(TabMenu) menu: TabMenu;

  public user$: Observable<User>;
  public menu$: Observable<MenuItem[]>;
  public activeItem: MenuItem = {};
  user: User = null;

  constructor(private store: Store<CoreState>, private router: Router) {
    super();
  }

  ngOnInit() {
    this.user$ = this.store.pipe(select(getUserProfileEntity), takeUntil(this.destroyed$));
    this.user$.subscribe((user) => {
      this.user = user;
    });

    this.menu$ = this.store.pipe(select(getMenuEntities));

    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
        withLatestFrom(this.menu$)
      )
      .subscribe(([event, menuItems]) => {
        // reset the active tab so pages like Maintenance don't highlight tab
        this.activeItem = {};
        // for non-menu triggered navigation such as Create Order from Quick Quote
        const urlAfterRedirects = (event as NavigationEnd).urlAfterRedirects;
        if (menuItems) {
          const routesItem = menuItems.filter((_) => _.routerLink).find((_) => urlAfterRedirects.startsWith(_.routerLink[0]));
          if (routesItem) {
            this.activeItem = routesItem;
          }
        }
      });
  }

  accept(confirmationDialog: Confirmation) {
    confirmationDialog.accept();
    this.resetDefaults(confirmationDialog);
  }

  reject(confirmationDialog: Confirmation) {
    confirmationDialog.reject();
    this.resetDefaults(confirmationDialog);
  }

  private resetDefaults(confirmationDialog: Confirmation) {
    confirmationDialog.acceptLabel = 'YES';
    confirmationDialog.acceptVisible = true;
    confirmationDialog.rejectLabel = 'NO';
    confirmationDialog.rejectVisible = true;
  }
}
