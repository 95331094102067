import { FuelRateEstimateData } from './fuel-rate-estimate-data';

export interface FuelRateEstimateByProgramData {
  btf: FuelRateEstimateData;
  doe: FuelRateEstimateData;
  overrideFuelAmount?: number;
  // client side fields
  fuelError: boolean;
}

export const defaultFuelRateEstimateByProgramData: FuelRateEstimateByProgramData = {
  btf: null,
  doe: null,
  overrideFuelAmount: null,
  fuelError: null,
};
