export interface UpdateVisibilityRequest {
  loadId: string;
  visibilityPhoneNumber: string;
  visibilityTruckNumber: string;
}

export const defaultUpdateVisibilityRequest: UpdateVisibilityRequest = {
  loadId: null,
  visibilityPhoneNumber: null,
  visibilityTruckNumber: null,
};
