import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { ChatRoutingModule } from './chat-routing.module';
import {
  ActiveLoadsListComponent,
  ChatViewContainerComponent,
  ConversationListComponent,
  ConversationWindowComponent,
  SelectConversationComponent,
} from './components';
import { MessageDatePipe } from './pipes';
import { ChatService, ConversationHubService } from './services';
import { effects } from './store/effects';
import { reducers } from './store/reducers';
@NgModule({
  declarations: [
    ChatViewContainerComponent,
    ConversationListComponent,
    ConversationWindowComponent,
    SelectConversationComponent,
    MessageDatePipe,
    ActiveLoadsListComponent,
  ],
  imports: [ChatRoutingModule, SharedModule, EffectsModule.forFeature(effects), StoreModule.forFeature('chat', reducers)],
  providers: [ConversationHubService, ChatService, MessageDatePipe],
})
export class ChatModule {}
