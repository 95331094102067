export interface CustomerApiEndpointData {
  customerApiId: number;
  customerId: string;
  customerApiTypeId: string;
  customerApiTypeDescription: string;
  customerApiUri: string;
  customerApiAuthTypeId: string;
  customerApiAuthTypeDescription: string;
  customerApiAuthUri: string;
  isTOPSEndpoint: boolean;
}

export const defaultCustomerApiEndpoint: CustomerApiEndpointData = {
  customerApiId: null,
  customerId: null,
  customerApiTypeId: null,
  customerApiTypeDescription: null,
  customerApiUri: null,
  customerApiAuthTypeId: null,
  customerApiAuthTypeDescription: null,
  customerApiAuthUri: null,
  isTOPSEndpoint: null,
};
