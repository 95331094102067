import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionItemCountData } from '../../models';
import { createReducer } from '../../utilities/create-reducer';
import { ActionItemActions, ActionItemActionTypes } from '../actions';

export interface ActionItemState extends EntityState<ActionItemCountData> {
  actionItemNotificationCount: number;
  actionItemMenuCounts: ActionItemCountData[];
}

export const adapter: EntityAdapter<ActionItemCountData> = createEntityAdapter<ActionItemCountData>({
  selectId: (x) => x.actionItemName,
});

const initialState: ActionItemState = adapter.getInitialState({
  actionItemNotificationCount: 0,
  actionItemMenuCounts: [],
});

const _actionItemReducer = createReducer(
  initialState,
  function (state: ActionItemState = initialState, action: ActionItemActions): ActionItemState {
    switch (action.type) {
      case ActionItemActionTypes.LoadCount_Failure: {
        return { ...state, actionItemNotificationCount: 0 };
      }
      case ActionItemActionTypes.LoadCount_Success: {
        return { ...state, actionItemNotificationCount: action.payload.totalCount, actionItemMenuCounts: action.payload.items };
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function actionItemReducer(state: ActionItemState, action: ActionItemActions): ActionItemState {
  return _actionItemReducer(state, action);
}

export const selectors = adapter.getSelectors();
