import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoadshopApplicationActionTypes } from '../../../shared/store';
import { UpdateFocusEntitySuccessAction, UserFocusEntitySelectorTypes } from '../../../user/store';
import { CommonService } from '../../services';
import { StartupActionTypes, StartupLoadAction, StartupLoadFailureAction, StartupLoadSuccessAction } from '../actions';

@Injectable()
export class StartupEffects {
  @Effect()
  $startup: Observable<Action> = this.actions$.pipe(
    ofType(LoadshopApplicationActionTypes.LoadshopStart),
    map(() => new StartupLoadAction())
  );

  @Effect()
  $load: Observable<Action> = this.actions$.pipe(
    ofType(StartupActionTypes.Load),
    switchMap(() =>
      this.commonService.getStartupData().pipe(
        map((data) => new StartupLoadSuccessAction(data)),
        catchError((err) => of(new StartupLoadFailureAction(err)))
      )
    )
  );

  @Effect()
  $refreshData: Observable<Action> = this.actions$.pipe(
    ofType<UpdateFocusEntitySuccessAction>(UserFocusEntitySelectorTypes.UpdateFocusEntity),
    map(() => new StartupLoadAction())
  );

  constructor(private commonService: CommonService, private actions$: Actions) {}
}
