import { Message } from 'primeng/api';
import { UserLane } from '../models';

export function validateUserlane(lane: UserLane): Message[] {
  const errors = [];
  const validOrigin = validPoint(lane.origCity, lane.origState, lane.origCountry, lane.origLat, lane.origLng);
  const validDest = validPoint(lane.destCity, lane.destState, lane.destCountry, lane.destLat, lane.destLng);
  if (!validOrigin && !validDest) {
    errors.push({ severity: 'error', detail: 'Origin or destination needs to be set' });
  }

  if (!lane.equipmentIds || lane.equipmentIds.length === 0) {
    errors.push({ severity: 'error', detail: 'At least one equipment type must be selected' });
  }
  return errors;
}

function validPoint(city: string, state: string, country: string, lat: number, lng: number): boolean {
  const hasState = state ? true : false;

  // Lat/Lng check
  if (!lat || !lng) {
    // A state without coordinates is still valid
    if (hasState) {
      return true;
    }
    return false;
  }

  if (!city || !state || !country) {
    // A state without city or country is also valid
    if (hasState) {
      return true;
    }
    return false;
  }

  return true;
}
