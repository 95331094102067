import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { LinehaulRateData } from '../../models';
import { getBreakEvenRateStyles } from '../../utilities';

@Component({
  selector: 'kbxl-linehaul-rate-overlay',
  templateUrl: './linehaul-rate-overlay.component.html',
  styleUrls: ['./linehaul-rate-overlay.component.scss'],
})
export class LinehaulRateOverlayComponent implements OnChanges {
  @Input() showLinehaulOverlay: boolean;
  @Input() linehaulRateData: LinehaulRateData;
  @Input() event: any;
  @ViewChild(OverlayPanel) linehaulOverlay: OverlayPanel;

  linehaulRateTitle: string;
  linehaulRate: number;
  fuelRate: number;
  loadshopFee: number;
  totalRate: number;
  isEstimatedFSC: boolean;
  showOverlayTask: any;
  isBrokeredLoad: boolean;
  totalQuotePrice: number;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (
        changes.showLinehaulOverlay &&
        changes.showLinehaulOverlay.currentValue &&
        changes.linehaulRateData &&
        changes.linehaulRateData.currentValue &&
        changes.event &&
        changes.event.currentValue
      ) {
        this.showOverlay();
      } else {
        this.hideOverlay();
      }
    }
  }

  showOverlay() {
    if (this.linehaulOverlay && this.event) {
      if (this.linehaulOverlay.target === null || this.linehaulOverlay.target === undefined) {
        this.calculateValues();
        this.linehaulOverlay.show(this.event);
        this.showOverlayTask = null;
      } else if (this.showOverlayTask === null) {
        this.showOverlayTask = setTimeout(() => this.showOverlay());
      }
    }
  }
  getCalculatedRateFontStyles() {
    if (this.isBrokeredLoad && !this.linehaulRateData?.isCounterOffer) {
      return getBreakEvenRateStyles(this.totalRate, this.totalQuotePrice, this.isBrokeredLoad);
    } else {
      return null;
    }
  }
  calculateValues() {
    if (this.linehaulRateData) {
      this.linehaulRateTitle = this.linehaulRateData.isCounterOffer ? 'Counter Offer' : 'Line Haul';
      this.fuelRate = this.linehaulRateData.usesAllInRates ? null : this.linehaulRateData.fuelRate;
      this.loadshopFee = this.linehaulRateData.isShipper ? this.linehaulRateData.loadshopFee : null;
      this.isEstimatedFSC = this.linehaulRateData.isEstimatedFSC;
      this.isBrokeredLoad = this.linehaulRateData.isBrokeredLoad;
      this.totalQuotePrice = null;

      this.linehaulRate = this.linehaulRateData.linehaulRate;
      this.totalRate = this.linehaulRate + this.fuelRate;
      if (this.linehaulRateData.isCounterOffer) {
        if (this.linehaulRateData.isShipper) {
          this.totalRate += this.linehaulRateData.loadshopFee;
        }
      } else {
        this.linehaulRate -= this.linehaulRateData.loadshopFee;
        if (!this.linehaulRateData.isShipper) {
          this.totalRate -= this.linehaulRateData.loadshopFee;
        }
      }
    }
  }

  hideOverlay() {
    if (this.linehaulOverlay) {
      this.linehaulOverlay.hide();
    }
  }
}
