export interface FuelRateEstimateData {
  loadId: string;
  rateType: string;
  rateName: string;
  rateValue: number;
  estimatedFuelRate: number;
  milesPerGallonEstimate: number;
  ratePerMile: number;
}

export const defaultFuelRateEstimateData: FuelRateEstimateData = {
  loadId: null,
  rateType: null,
  rateName: null,
  rateValue: 0.0,
  estimatedFuelRate: 0.0,
  milesPerGallonEstimate: 0.0,
  ratePerMile: 0.0
};
