import { ServiceType } from '.';
import { LoadContact, defaultLoadContact } from './load-contact';
import { LoadLineItem, defaultLoadLineItem } from './load-line-item';
import { OrderEntryFormBookedEditEnum } from './order-entry-form-booked-edit-enum';
import { OrderEntryLoadStop, defaultOrderEntryDeliveryStop, defaultOrderEntryPickupStop } from './order-entry-load-stop';

export interface OrderEntryForm {
  loadId: string;
  referenceLoadDisplay: string;
  referenceLoadId: string;
  commodity: string;
  equipment: string;
  shipperPickupNumber: string;
  transportationMode: string;
  specialInstructions: string;
  miles: number;
  lineHaulRate: number;
  fuelRate: number;
  weight: number;
  cube: number;
  isBooked: boolean;
  loadEditStatus: OrderEntryFormBookedEditEnum;
  isExternalLoad: boolean;
  services: ServiceType[];
  loadStops: OrderEntryLoadStop[];
  contacts: LoadContact[];
  lineItems: LoadLineItem[];
  smartSpotQuoteId: string;
}

export interface OrderEntryFormDisplay extends OrderEntryForm {
  equipmentDesc: string;
  categoryEquipmentDesc: string;
  onLoadshop: boolean;
}

export const defaultOrderEntry: OrderEntryFormDisplay = {
  loadId: null,
  referenceLoadDisplay: null,
  referenceLoadId: null,
  commodity: null,
  equipment: null,
  equipmentDesc: null,
  categoryEquipmentDesc: null,
  shipperPickupNumber: null,
  transportationMode: null,
  specialInstructions: null,
  onLoadshop: false,
  miles: 0,
  lineHaulRate: 0,
  fuelRate: 0,
  weight: 0,
  cube: 0,
  isBooked: false,
  loadEditStatus: OrderEntryFormBookedEditEnum.AllowChanges,
  isExternalLoad: false,

  services: [],
  loadStops: [{ ...defaultOrderEntryPickupStop }, { ...defaultOrderEntryDeliveryStop }],
  contacts: [defaultLoadContact],
  lineItems: [{ ...defaultLoadLineItem }],
  smartSpotQuoteId: null,
};

export interface OrderEntryFormEditStatus {
  isBooked: boolean;
  loadEditRestrictions: OrderEntryFormBookedEditEnum;
}
