import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { mapToPayload } from '@tms-ng/shared';
import { MessageService } from 'primeng/api';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, mapTo, mergeMap, switchMap, takeUntil, takeWhile, withLatestFrom } from 'rxjs/operators';
import { IShippingLoadDetail, ShippingLoadDetail } from 'src/app/shared/models';
import { IPostingLoad } from 'src/app/shared/models/posting-load';
import { UpdateFocusEntityAction, UserFocusEntitySelectorTypes } from 'src/app/user/store';
import { ShippingService } from '../../services';
import {
  ShippingLoadDetailActionTypes,
  ShippingLoadDetailContractRatesCancelAllPollingAction,
  ShippingLoadDetailContractRatesPollingAction,
  ShippingLoadDetailDeleteLoadAction,
  ShippingLoadDetailDeleteLoadFailureAction,
  ShippingLoadDetailDeleteLoadSuccessAction,
  ShippingLoadDetailLoadAllAction,
  ShippingLoadDetailLoadAllFailureAction,
  ShippingLoadDetailLoadAllSuccessAction,
  ShippingLoadDetailLoadContractRatesAction,
  ShippingLoadDetailLoadContractRatesFailureAction,
  ShippingLoadDetailLoadContractRatesSuccessAction,
  ShippingLoadDetailLoadSupplementalAction,
  ShippingLoadDetailLoadSupplementalFailureAction,
  ShippingLoadDetailLoadSupplementalSuccessAction,
  ShippingLoadDetailPostLoadsAction,
  ShippingLoadDetailPostLoadsFailureAction,
  ShippingLoadDetailPostLoadsSuccessAction,
  ShippingLoadDetailRemoveLoadAction,
  ShippingLoadDetailRemoveLoadFailureAction,
  ShippingLoadDetailRemoveLoadSuccessAction,
} from '../actions';
import { ShippingState } from '../reducers';
import { getShippingLoadsLoadingContractRatesForLoadId } from '../selectors/shipping-load-detail.selectors';

@Injectable()
export class ShippingLoadDetailEffects {
  timerSeconds = 10;

  @Effect()
  $loadAll: Observable<Action> = this.actions$.pipe(
    ofType<ShippingLoadDetailLoadAllAction>(ShippingLoadDetailActionTypes.Load_All),
    switchMap(() =>
      this.shippingService.getLoadsForHomeTab().pipe(
        map((data) => new ShippingLoadDetailLoadAllSuccessAction(data)),
        catchError((err) => of(new ShippingLoadDetailLoadAllFailureAction(err)))
      )
    )
  );

  @Effect()
  $loadSupplementalData: Observable<Action> = this.actions$.pipe(
    ofType<ShippingLoadDetailLoadSupplementalAction>(ShippingLoadDetailActionTypes.Load_Supplemental),
    mapToPayload<{ loadId: string }>(),
    mergeMap((payload: { loadId: string }) =>
      this.shippingService.getSupplementalData(payload.loadId).pipe(
        map((response) => {
          if (response && response.success) {
            const data = response.data;
            if (data && data.isLoadCarrierScacPrefetchProcessing) {
              this.shippingStore.dispatch(new ShippingLoadDetailContractRatesPollingAction(payload.loadId));
            }
            return new ShippingLoadDetailLoadSupplementalSuccessAction({ loadId: payload.loadId, data });
          } else if (response && response.errors && response.errors.length > 0) {
            const error = response.errors[0];
            return new ShippingLoadDetailLoadSupplementalFailureAction(error);
          }
        })
      )
    )
  );

  @Effect()
  $pollingPrefetchContractRateData: Observable<Action> = this.actions$.pipe(
    ofType<ShippingLoadDetailContractRatesPollingAction>(ShippingLoadDetailActionTypes.Start_Contract_Rates_Polling),
    mapToPayload<string>(),
    switchMap((loadId) =>
      timer(0, this.timerSeconds * 1000).pipe(
        takeUntil(this.actions$.pipe(ofType(ShippingLoadDetailActionTypes.Cancel_Contract_Rates_All_Polling))),
        withLatestFrom(this.shippingStore.pipe(select(getShippingLoadsLoadingContractRatesForLoadId, { loadId }))),
        takeWhile((args) => args[1]),
        mapTo(new ShippingLoadDetailLoadContractRatesAction(loadId))
      )
    )
  );

  @Effect()
  $loadPrefetchContractRateData: Observable<Action> = this.actions$.pipe(
    ofType<ShippingLoadDetailLoadContractRatesAction>(ShippingLoadDetailActionTypes.Load_Contract_Rates),
    mapToPayload<string>(),
    mergeMap((loadId: string) =>
      this.shippingService.getPrefetchContractRateData(loadId).pipe(
        map((data) => new ShippingLoadDetailLoadContractRatesSuccessAction({ loadId, data })),
        catchError((err) => of(new ShippingLoadDetailLoadContractRatesFailureAction(err)))
      )
    )
  );

  @Effect()
  $clearAll: Observable<Action> = this.actions$.pipe(
    ofType<UpdateFocusEntityAction>(UserFocusEntitySelectorTypes.UpdateFocusEntity),
    switchMap(() => of(new ShippingLoadDetailLoadAllSuccessAction([])))
  );

  @Effect()
  $postLoads = this.actions$.pipe(
    ofType<ShippingLoadDetailPostLoadsAction>(ShippingLoadDetailActionTypes.Post_Loads),
    map((_) => _.payload.map(this.createPostingLoad)),
    switchMap((loads: IPostingLoad[]) => {
      this.shippingStore.dispatch(new ShippingLoadDetailContractRatesCancelAllPollingAction());

      return this.shippingService.postLoads(loads).pipe(
        map((data) => {
          if (data && data.validationProblemDetails && data.validationProblemDetails.errors) {
            // Load-specific errors will show on each load, but popup a notification error with the title and details as a summary message
            this.messageService.add({
              summary: data.validationProblemDetails.title,
              detail: data.validationProblemDetails.detail,
              severity: 'error',
            });
          }
          return new ShippingLoadDetailPostLoadsSuccessAction(data);
        }),
        catchError((err) => of(new ShippingLoadDetailPostLoadsFailureAction(err)))
      );
    })
  );

  @Effect()
  $removeLoad: Observable<Action> = this.actions$.pipe(
    ofType<ShippingLoadDetailRemoveLoadAction>(ShippingLoadDetailActionTypes.Remove_Load),
    mapToPayload<string>(),
    switchMap((loadId: string) =>
      this.shippingService.removeLoad(loadId).pipe(
        map((data: IShippingLoadDetail) => new ShippingLoadDetailRemoveLoadSuccessAction(data)),
        catchError((err) => of(new ShippingLoadDetailRemoveLoadFailureAction(err)))
      )
    )
  );

  @Effect()
  $deleteLoad: Observable<Action> = this.actions$.pipe(
    ofType<ShippingLoadDetailDeleteLoadAction>(ShippingLoadDetailActionTypes.Delete_Load),
    mapToPayload<string>(),
    switchMap((loadId: string) =>
      this.shippingService.deleteLoad(loadId).pipe(
        map((data: IShippingLoadDetail) => new ShippingLoadDetailDeleteLoadSuccessAction(data)),
        catchError((err) => of(new ShippingLoadDetailDeleteLoadFailureAction(err)))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private shippingService: ShippingService,
    private messageService: MessageService,
    private shippingStore: Store<ShippingState>
  ) {}

  createPostingLoad(load: ShippingLoadDetail): IPostingLoad {
    return {
      loadId: load.loadId,
      // shippersFSC: load.shippersFSC ? load.shippersFSC : 0,
      // lineHaulRate: load.lineHaulRate,
      totalRate: load.totalRate,
      comments: load.comments,
      commodity: load.commodity,
      carrierIds: (load.selectedGroupCarriers || []).map((_) => _.carrierId).concat((load.selectedCarriers || []).map((_) => _.carrierId)),
      smartSpotRate: load.smartSpotRate,
      datGuardRate: load.datGuardRate,
      machineLearningRate: load.machineLearningRate,
      altXCapRate: load.altXCapRate,
      carrierGroupIds: load.carrierGroupIds,
      allCarriersPosted: load.allCarriersPosted,
      serviceTypeIds: (load.serviceTypes || []).map((_) => _.serviceTypeId),
      isDynamicPricingEnabled: load.isDynamicPricingEnabled,
      loadPricingRule: load.latestLoadPricingRule,
      allowCounterOffer: load.allowCounterOffer,
      loadContact: load.loadContact,
      fuelRateByProgram: load.fuelRateByProgram,
      spotPriceQuote: load.spotPriceQuote
    };
  }
}
