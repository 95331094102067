import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AccessorialData, AccessorialUnitOfMeasureData, ShipperAccessorialData, ValidationProblemDetails } from '../../../shared/models';
import { createReducer } from '../../../shared/utilities';
import { ShipperAccessorialActions, ShipperAccessorialActionTypes } from '../actions';

export interface ShipperAccessorialState extends EntityState<ShipperAccessorialData> {
  loading: boolean;
  savingShipperAccessorial: boolean;
  savingShipperAccessorialSucceeded: boolean;
  savingShipperAccessorialProblemDetails: ValidationProblemDetails;
  accessorialUnitOfMeasure: AccessorialUnitOfMeasureData[];
  accessorials: AccessorialData[];
  globalAccessorials: ShipperAccessorialData[];
}

export const adapter: EntityAdapter<ShipperAccessorialData> = createEntityAdapter<ShipperAccessorialData>({
  selectId: (x) => x.customerAccessorialId,
});

const initialState: ShipperAccessorialState = adapter.getInitialState({
  loading: false,
  globalAccessorials: [],
  accessorials: [],
  accessorialUnitOfMeasure: [],
  savingShipperAccessorial: false,
  savingShipperAccessorialSucceeded: false,
  savingShipperAccessorialProblemDetails: null,
});

const _shipperAccessorialReducer = createReducer(
  initialState,
  function (state: ShipperAccessorialState = initialState, action: ShipperAccessorialActions): ShipperAccessorialState {
    switch (action.type) {
      case ShipperAccessorialActionTypes.Load: {
        if (action.saveToGlobal) {
          return {
            ...state,
            loading: true,
            globalAccessorials: [],
            savingShipperAccessorialProblemDetails: null,
          };
        } else {
          return adapter.removeAll({
            ...state,
            loading: true,

            savingShipperAccessorialProblemDetails: null,
          });
        }
      }
      case ShipperAccessorialActionTypes.Load_Success: {
        if (action.saveToGlobal) {
          return { ...state, loading: false, globalAccessorials: action.payload };
        } else {
          return adapter.setAll(action.payload, { ...state, loading: false });
        }
      }
      case ShipperAccessorialActionTypes.Load_Failure: {
        return { ...state, loading: false };
      }
      case ShipperAccessorialActionTypes.Load_Accessorials: {
        return {
          ...state,
          loading: true,
          accessorials: [],
        };
      }
      case ShipperAccessorialActionTypes.Load_Accessorials_Success: {
        return { ...state, loading: false, accessorials: action.payload };
      }
      case ShipperAccessorialActionTypes.Load_Accessorials_Failure: {
        return { ...state, loading: false, accessorials: [] };
      }
      case ShipperAccessorialActionTypes.Load_AccessorialsUoM: {
        return {
          ...state,
          loading: true,
          accessorialUnitOfMeasure: [],
        };
      }
      case ShipperAccessorialActionTypes.Load_AccessorialsUoM_Success: {
        return { ...state, loading: false, accessorialUnitOfMeasure: action.payload };
      }
      case ShipperAccessorialActionTypes.Load_AccessorialsUoM_Failure: {
        return { ...state, loading: false, accessorialUnitOfMeasure: [] };
      }
      case ShipperAccessorialActionTypes.BulkOperation:
      case ShipperAccessorialActionTypes.Add: {
        return {
          ...state,
          loading: true,
          savingShipperAccessorial: true,
          savingShipperAccessorialSucceeded: false,
          savingShipperAccessorialProblemDetails: null,
        };
      }
      case ShipperAccessorialActionTypes.BulkOperation_Failure:
      case ShipperAccessorialActionTypes.Add_Failure: {
        return {
          ...state,
          loading: false,
          savingShipperAccessorial: false,
          savingShipperAccessorialSucceeded: false,
          savingShipperAccessorialProblemDetails: action.payload.error,
        };
      }
      case ShipperAccessorialActionTypes.Add_Success: {
        return adapter.addOne(action.payload, {
          ...state,
          savingShipperAccessorial: false,
          savingShipperAccessorialSucceeded: true,
          saveCarrierGroupError: null,
        });
      }

      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function shipperAccessorialReducer(state: ShipperAccessorialState, action: ShipperAccessorialActions): ShipperAccessorialState {
  return _shipperAccessorialReducer(state, action);
}
export const selectors = adapter.getSelectors();
