import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Carrier, CarrierAgreementData, CarrierScac, Customer, ISecurityAccessRoleData, UserAdminData } from '../../../shared/models';
import { createReducer } from '../../../shared/utilities';
import { UserAdminActions, UserAdminActionTypes } from '../actions';

export interface UserAdminState extends EntityState<UserAdminData> {
  loadingUsers: boolean;
  loadingUser: boolean;
  loadingEntities: boolean;
  loadingAdminUsers: boolean;
  loadingIdentityUser: boolean;
  savingUser: boolean;
  createMode: boolean;
  selectedUser: UserAdminData;
  allAuthorizedShippers: Customer[];
  allAuthorizedCarrierScacs: CarrierScac[];
  allAuthorizedSecurityRoles: ISecurityAccessRoleData[];
  adminUsers: UserAdminData[];
  allAuthorizedCarriers: Carrier[];
  loadingCarrierUsers: boolean;
  carrierUsers: UserAdminData[];
  carrierAgreements: CarrierAgreementData[];
}

export const adapter: EntityAdapter<UserAdminData> = createEntityAdapter<UserAdminData>({
  selectId: (x) => x.userId,
});

const initialState: UserAdminState = adapter.getInitialState({
  loadingUsers: false,
  loadingUser: false,
  loadingEntities: false,
  loadingAdminUsers: false,
  loadingIdentityUser: false,
  savingUser: false,
  createMode: false,
  selectedUser: null,
  allAuthorizedShippers: [],
  allAuthorizedCarrierScacs: [],
  allAuthorizedSecurityRoles: [],
  adminUsers: [],
  allAuthorizedCarriers: [],
  loadingCarrierUsers: false,
  carrierUsers: [],
  carrierAgreements: [],
});

const _UserAdminReducer = createReducer(
  initialState,
  function (state: UserAdminState = initialState, action: UserAdminActions): UserAdminState {
    switch (action.type) {
      case UserAdminActionTypes.Load_Users: {
        return { ...state, loadingUsers: true };
      }
      case UserAdminActionTypes.Load_Users_Failure: {
        return adapter.removeAll({ ...state, loadingUsers: false });
      }
      case UserAdminActionTypes.Load_Users_Success: {
        return adapter.setAll(action.payload, { ...state, loadingUsers: false });
      }
      case UserAdminActionTypes.Load_AdminUsers: {
        return { ...state, loadingAdminUsers: true };
      }
      case UserAdminActionTypes.Load_AdminUsers_Failure: {
        return { ...state, loadingAdminUsers: false };
      }
      case UserAdminActionTypes.Load_AdminUsers_Success: {
        return { ...state, adminUsers: action.payload, loadingAdminUsers: false };
      }
      case UserAdminActionTypes.Load_User: {
        return { ...state, loadingUser: true };
      }
      case UserAdminActionTypes.Load_User_Failure: {
        return adapter.removeAll({ ...state, loadingUser: false });
      }
      case UserAdminActionTypes.Load_User_Success: {
        return adapter.upsertOne(action.payload, { ...state, selectedUser: action.payload, loadingUser: false, createMode: false });
      }
      case UserAdminActionTypes.Clear_Selected_User: {
        return { ...state, selectedUser: null, loadingUser: false, createMode: false };
      }
      case UserAdminActionTypes.Load_Authorized_Entities: {
        return { ...state, loadingEntities: true };
      }
      case UserAdminActionTypes.Load_Authorized_Entities_Success: {
        return {
          ...state,
          loadingEntities: false,
          allAuthorizedShippers: action.payload.customers,
          allAuthorizedCarriers: action.payload.carriers,
          allAuthorizedCarrierScacs: action.payload.carrierScacs,
          allAuthorizedSecurityRoles: action.payload.availableSecurityRoles,
        };
      }
      case UserAdminActionTypes.Load_Authorized_Entities_Failure: {
        return {
          ...state,
          loadingEntities: false,
          allAuthorizedShippers: [],
          allAuthorizedCarriers: [],
          allAuthorizedCarrierScacs: [],
          allAuthorizedSecurityRoles: [],
        };
      }
      case UserAdminActionTypes.Create_User:
      case UserAdminActionTypes.Update_User:
      case UserAdminActionTypes.Delete_User: {
        return { ...state, savingUser: true };
      }
      case UserAdminActionTypes.Create_User_Success:
      case UserAdminActionTypes.Update_User_Success:
      case UserAdminActionTypes.Delete_User_Success: {
        return { ...state, selectedUser: null, savingUser: false, createMode: false };
      }
      case UserAdminActionTypes.Create_User_Failure:
      case UserAdminActionTypes.Update_User_Failure:
      case UserAdminActionTypes.Delete_User_Failure: {
        return { ...state, savingUser: false };
      }
      case UserAdminActionTypes.Load_Identity_User:
        return { ...state, loadingIdentityUser: true };
      case UserAdminActionTypes.Load_Identity_User_Success: {
        return { ...state, selectedUser: action.payload, loadingIdentityUser: false, createMode: true };
      }
      case UserAdminActionTypes.Load_Identity_User_Failure:
      case UserAdminActionTypes.Load_Identity_User_Not_Found:
        return { ...state, selectedUser: null, loadingIdentityUser: false, createMode: false };
      case UserAdminActionTypes.Load_Carrier_Users_Data: {
        return { ...state, loadingCarrierUsers: true };
      }
      case UserAdminActionTypes.Load_Carrier_Users_Data_Failure: {
        return { ...state, carrierUsers: [], loadingCarrierUsers: false };
      }
      case UserAdminActionTypes.Load_Carrier_Users_Data_Success: {
        return {
          ...state,
          carrierUsers: action.payload.users,
          carrierAgreements: action.payload.carrierAgreements,
          loadingCarrierUsers: false,
        };
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function UserAdminReducer(state: UserAdminState, action: UserAdminActions): UserAdminState {
  return _UserAdminReducer(state, action);
}
export const selectors = adapter.getSelectors();
