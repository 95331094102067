/* eslint-disable @typescript-eslint/camelcase */
import { Action } from '@ngrx/store';
import { LoadAudit } from 'src/app/shared/models/load-audit';
import { LoadDetail, ResponseError } from '../../models';

export enum LoadBoardLoadDetailActionTypes {
  Load = '[LoadBoardLoadDetail] LOAD',
  Load_Success = '[LoadBoardLoadDetail] LOAD_SUCCESS',
  Load_Booked_Failure = '[LoadBoardLoadDetail] LOAD_BOOKED_FAILURE',
  Load_Failure = '[LoadBoardLoadDetail] LOAD_FAILURE',
  Load_Audit = '[LoadBoardLoadDetail] LOAD_AUDIT',
  Load_Audit_Success = '[LoadBoardLoadDetail] LOAD_AUDIT_SUCCESS',
  Load_Audit_Failure = '[LoadBoardLoadDetail] LOAD_AUDIT_FAILURE',
  Load_Detail_Closed = '[LoadBoardLoadDetail] LOAD_DETAIL_CLOSED',
}

export class LoadBoardLoadDetailLoadAction implements Action {
  readonly type = LoadBoardLoadDetailActionTypes.Load;

  constructor(public id: string, public scac?: string) {}
}

export class LoadBoardLoadDetailLoadSuccessAction implements Action {
  readonly type = LoadBoardLoadDetailActionTypes.Load_Success;

  constructor(public payload: LoadDetail) {}
}

export class LoadBoardLoadDetailLoadBookedFailureAction implements Action {
  readonly type = LoadBoardLoadDetailActionTypes.Load_Booked_Failure;

  constructor(public payload: { load: LoadDetail; errors: ResponseError[] }) {}
}

export class LoadBoardLoadDetailLoadFailureAction implements Action {
  readonly type = LoadBoardLoadDetailActionTypes.Load_Failure;

  constructor(public payload: Error) {}
}

export class LoadBoardLoadAuditAction implements Action {
  readonly type = LoadBoardLoadDetailActionTypes.Load_Audit;

  constructor(public payload: LoadAudit) {}
}

export class LoadBoardLoadAuditSuccessAction implements Action {
  readonly type = LoadBoardLoadDetailActionTypes.Load_Audit_Success;

  constructor(public payload: number) {}
}

export class LoadBoardLoadAuditFailureAction implements Action {
  readonly type = LoadBoardLoadDetailActionTypes.Load_Audit_Failure;

  constructor(public payload: Error) {}
}

export class LoadBoardLoadDetailClosed implements Action {
  readonly type = LoadBoardLoadDetailActionTypes.Load_Detail_Closed;
  constructor(public loadId: string) {}
}

export type LoadBoardLoadDetailActions =
  | LoadBoardLoadDetailLoadAction
  | LoadBoardLoadDetailLoadSuccessAction
  | LoadBoardLoadDetailLoadFailureAction
  | LoadBoardLoadAuditAction
  | LoadBoardLoadAuditSuccessAction
  | LoadBoardLoadAuditFailureAction
  | LoadBoardLoadDetailLoadBookedFailureAction
  | LoadBoardLoadDetailClosed;
