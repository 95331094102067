import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';
import { LazyLoadEvent } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Table } from 'primeng/table';
import { map, takeUntil } from 'rxjs/operators';
import { LoadStopStatus } from 'src/app/shared/models/load-stop-status';
import { defaultRateBreakDownOverlayInput, RateBreakDownOverlayInput } from 'src/app/shared/models/rate-break-down-data';
import { SecurityAppActionType } from 'src/app/shared/models/security-app-action-type';
import { PageableQueryData } from 'src/app/shared/utilities';
import { BaseComponent } from '../../../shared/components';
import {
  defaultLinehaulRateData,
  defaultUpdateVisibilityRequest,
  LinehaulRateData,
  LoadView,
  ServiceType,
  TransactionType,
  UpdateVisibilityRequest,
  UserLane,
  UserModel,
  VisibilityBadge,
} from '../../../shared/models';
import { getLoadBoardShowAllLoadsContext, LoadBoardState } from '../../store';

@Component({
  selector: 'kbxl-load-grid',
  templateUrl: './load-grid.component.html',
  styleUrls: ['./load-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadGridComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() loads: LoadView[];
  @Input() lanes: UserLane[];
  @Input() loading: boolean;
  @Input() isMarketplace: boolean;
  @Input() serviceTypes: ServiceType[];
  @Output() selected: EventEmitter<LoadView> = new EventEmitter<LoadView>();
  @Output() updateStatus: EventEmitter<string> = new EventEmitter<string>();
  @Output() updateVisibility: EventEmitter<UpdateVisibilityRequest> = new EventEmitter<UpdateVisibilityRequest>();
  @Output() lazyLoad: EventEmitter<PageableQueryData> = new EventEmitter<PageableQueryData>();
  @Output() documentChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  sortName: string;
  sortOrder: number;
  first = 0;
  @Input() isBookedLoads = false;
  @Input() user: UserModel = null;
  @Input() savingLoadId: string = null;
  @Input() errorLoadId: string = null;
  @Input() phoneError = false;
  @Input() truckError = false;
  @Input() lazy = false;
  @Input() visibilityBadge: VisibilityBadge = null;
  @Input() totalRecords: number;

  columns: any[];

  @ViewChild(Table, { static: true }) table;

  editingTruckNumberLoadId: string;
  editingPhoneNumberLoadId: string;
  originalLoadViews: { [s: string]: LoadView } = {};
  selectedLoadDocumentsLoad: LoadView;
  documentDialogVisible = false;

  hoveredPricingRow: LoadView;
  isDisabled = false;

  linehaulRateData: LinehaulRateData;
  showLinehaulOverlay = false;
  showLinehaulOverlayEvent: any;

  rateBreakDownInput: RateBreakDownOverlayInput;
  showrateBreakDownOverlay = false;
  showrateBreakDownOverlayEvent: any;
  showRateBreakDowns: boolean;

  get displayStatusColumn() {
    return this.isBookedLoads && this.user && this.user.hasSecurityAction(SecurityAppActionType.CarrierViewStatus);
  }

  constructor(private changeDetector: ChangeDetectorRef, private loadBoardStore: Store<LoadBoardState>) {
    super();
  }

  ngOnInit() {
    this.columns = [
      { field: 'referenceLoadDisplay', header: "Shipper's Order Number" },
      { field: 'billingLoadDisplay', header: 'Billing Load ID' },
      { field: 'originDisplay', header: 'Origin' },
      { field: 'destinationDisplay', header: 'Destination' },
      { field: 'distanceFrom', header: 'Origin Radius' },
    ];

    if (this.isBookedLoads) {
      if (this.showPhoneNumberColumn()) {
        this.columns.push({ field: 'visibilityPhoneNumber', header: 'Phone #' });
      }
      if (this.showTruckNumberColumn()) {
        this.columns.push({ field: 'visibilityTruckNumber', header: 'Truck #' });
      }
    }

    this.columns.push(
      { field: 'originLateDtTm', header: 'Pickup' },
      { field: 'destLateDtTm', header: 'Delivery' },
      { field: 'equipmentType', header: 'Equipment' },
      { field: 'stops', header: 'Stops' },
      { field: 'miles', header: 'Distance (mi)' },
      { field: 'lineHaulRate', header: 'Line Haul Rate' },
      { field: 'fuelRate', header: 'FSC Rate' },
      { field: 'totalRateDisplay', header: 'All-in Rate' },
      { field: 'scac', header: 'SCAC' }
    );

    this.loadBoardStore
      .pipe(
        select(getLoadBoardShowAllLoadsContext),
        takeUntil(this.destroyed$),
        map((x) => (x ? true : false))
      )
      .subscribe((x) => (this.showRateBreakDowns = x));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.loads && !this.lazy) {
        this.first = 0;
      }
    }
  }

  onRowSelect(load: LoadView) {
    if (!this.isDisabled) {
      this.selected.emit(load);
    }
  }

  onStatusClicked(load: LoadView, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.isDisabled) {
      this.updateStatus.emit(load.loadId);
    }
  }

  onPage($event) {
    if ($event && $event.first && !this.lazy) {
      this.first = $event.first;
    }
  }

  showVisibilityWarning(load: LoadView): boolean {
    const preReqs = this.isBookedLoads && load && this.visibilityBadge && this.user && this.visibilityBadge.applicableDate;
    if (!preReqs) {
      return false;
    }

    const pickup = new Date(load.originLateDtTm);
    const badgeDate = new Date(this.visibilityBadge.applicableDate);
    const showBasedOnDate = pickup > new Date() && pickup <= badgeDate;

    if (showBasedOnDate) {
      const delivery = load.destLateDtTm.toString();
      const isMissingTruckNumber =
        !this.showEditTruckNumber(delivery) || (this.showEditTruckNumber(delivery) && !load.visibilityTruckNumber);
      const isMissingPhoneNumber =
        !this.showEditPhoneNumber(delivery) ||
        (this.showEditPhoneNumber(delivery) && !load.visibilityPhoneNumber && !load.mobileExternallyEntered);

      return isMissingTruckNumber && isMissingPhoneNumber;
    }

    return false;
  }

  showTruckNumberColumn(): boolean {
    return this.isBookedLoads && this.user && this.user.carrierVisibilityTypes && this.user.carrierVisibilityTypes.includes('Project 44');
  }

  showEditTruckNumber(deliveryDate: string): boolean {
    return this.showTruckNumberColumn();
  }

  showPhoneNumberColumn(): boolean {
    return this.isBookedLoads && this.user && this.user.carrierVisibilityTypes && this.user.carrierVisibilityTypes.includes('TOPS TO GO');
  }

  showEditPhoneNumber(deliveryDate: string): boolean {
    return this.showPhoneNumberColumn();
  }

  onEditTruckNumberInit(event: Event, load: LoadView) {
    this.editingTruckNumberLoadId = load.loadId;
    this.onEditInit(event, load);
  }

  onEditPhoneNumberInit(event: Event, load: LoadView) {
    this.editingPhoneNumberLoadId = load.loadId;
    this.onEditInit(event, load);
  }

  onEditInit(event: Event, load: LoadView) {
    event.stopPropagation();
    this.originalLoadViews[load.loadId] = cloneDeep(load);
  }

  onEditComplete(field: string, load: LoadView, index: number, table: Table, event: FocusEvent | any) {
    event.stopPropagation();
    const orig = this.originalLoadViews[load.loadId];
    const origValue = orig[field];
    let newValue = event.currentTarget.value;
    if (newValue === '(___) ___-____') {
      // blank value submitted via input mask on phone number
      newValue = null;
    }

    if (newValue && origValue !== newValue) {
      // Only update visibility fields if the value has changed
      load[field] = newValue;
      const updateVisibilityRequest = { ...defaultUpdateVisibilityRequest, loadId: load.loadId };
      updateVisibilityRequest[field] = newValue;
      this.updateVisibility.emit(updateVisibilityRequest);
    }

    if (!newValue) {
      // If the user has cleared out the field, set it back to the original value before deleting the original
      load[field] = origValue;
    }

    // Always delete the clone when editing is complete, whether we've saved a new
    // value or cancelled by not changing the value
    delete this.originalLoadViews[load.loadId];
    this.editingTruckNumberLoadId = null;
    this.editingPhoneNumberLoadId = null;

    if (table) {
      // https://github.com/primefaces/primeng/issues/5368
      table.onEditComplete.emit({ field: null, data: null });
      table.editingCell = null;
    }

    if (!newValue && origValue) {
      // Throw an error if attempting to clear out an existing value
      const fld = this.columns.find((x) => x.field === field);

      if (fld) {
        const fieldLabel = this.columns.find((x) => x.field === field).header;
        throw new Error(`${fieldLabel} is required`);
      }
    }
  }

  setFirst(first: number) {
    this.first = first;
  }

  onLazyLoad($event: LazyLoadEvent) {
    const pageSize = $event.rows;
    const pageNumber = $event.first / $event.rows + 1;
    const sortField = $event.sortField;
    const descending = $event.sortOrder === -1;
    this.first = $event.first;

    this.lazyLoad.emit({ pageSize, pageNumber, filter: null, sortField: sortField, descending: descending });
  }

  serviceTypeMouseHover(load: LoadView, serviceTypesOverlay: OverlayPanel, event: Event): void {
    if (!load.serviceTypeIds || load.serviceTypeIds.length < 2) {
      return;
    }
    if (serviceTypesOverlay.overlayVisible === false && serviceTypesOverlay.render === false) {
      this.hoveredPricingRow = load;
      event.stopPropagation();
      serviceTypesOverlay.show(event, event.target);
      this.changeDetector.detectChanges();
    }
  }
  serviceTypeMouseHoverOut(serviceTypesOverlay: OverlayPanel): void {
    if (serviceTypesOverlay.overlayVisible && serviceTypesOverlay.render) {
      serviceTypesOverlay.hide();
    }
  }

  showDocumentOverlay(load: LoadView, loadDocumentOverlay: OverlayPanel, event: Event) {
    // Make sure the overlay is hidden before showing
    // so it shows up in correct spot
    this.hideDocumentOverlay(loadDocumentOverlay);
    event.stopPropagation();

    setTimeout(() => {
      this.selectedLoadDocumentsLoad = load;
      loadDocumentOverlay.show(event, event.target);
      this.changeDetector.detectChanges();
    }, 10);
  }

  showDocumentDialog(load: LoadView, event: Event) {
    this.selectedLoadDocumentsLoad = load;
    this.documentDialogVisible = true;
    event.stopPropagation();
  }

  hideDocumentOverlay(loadDocumentOverlay: OverlayPanel) {
    this.selectedLoadDocumentsLoad = null;
    loadDocumentOverlay.hide();
  }

  handleDocumentChange() {
    this.documentChange.emit(true);
  }

  highlightProofOfDelivery(loadview: LoadView): boolean {
    return loadview.requiresPod === true && loadview.hasPod === false && loadview.transactionTypeId === TransactionType.PendingPod;
  }

  getServiceTypes(load: LoadView): any[] {
    let elementClasses: string[] = [];
    if (!load.serviceTypeIds || load.serviceTypeIds.length === 0 || !this.serviceTypes) {
      return [];
    }
    const serviceTypeObjs = [];
    for (let i = 0; i < 2; i++) {
      elementClasses = [];
      const type = this.serviceTypes.find((x) => x.serviceTypeId === load.serviceTypeIds[i]);
      // POD Upload Required highlight -- Ensure highlight occurs even with load.serviceTypeIds.length > 2
      if (type && this.highlightProofOfDelivery(load) === true && type.name === 'POD Upload Required') {
        elementClasses.push('podhighlight');
        serviceTypeObjs.push({ name: (i > 0 ? ', ' : '') + type.name, elementClasses: elementClasses });
        continue;
      }
      if (type) {
        serviceTypeObjs.push({ name: (i > 0 ? ', ' : '') + type.name, elementClasses: elementClasses });
      }
    }
    if (load.serviceTypeIds.length > 2) {
      serviceTypeObjs.push({ name: ' + ' + (load.serviceTypeIds.length - 2).toString(), elementClasses: elementClasses });
    }
    return serviceTypeObjs;
  }
  getServiceTypesHover(load: LoadView): string {
    if (!load.serviceTypeIds || !this.serviceTypes) {
      return '';
    }

    return this.getServiceTypeNames(load);
  }

  getServiceTypeNames(load: LoadView): string {
    const names = [];
    load.serviceTypeIds.forEach((element) => {
      const serviceType = this.serviceTypes ? this.serviceTypes.find((x) => x.serviceTypeId === element) : null;
      if (serviceType) {
        names.push(serviceType.name);
      }
    });

    return names.join(', ');
  }

  getAttachedPodDisplay(load: LoadView) {
    if (load.hasPod) {
      return 'Yes';
    }

    if (load.requiresPod) {
      return 'No';
    }

    return 'N/A';
  }

  public getApptEstLabel(load: LoadView, origin: boolean, appt: boolean): string {
    let result: string = null;

    if (load.loadStops) {
      let statuses: LoadStopStatus[];
      if (origin) {
        const stop = load.loadStops.filter((x) => x.stopNbr === 1);
        if (stop && stop.length === 1) {
          statuses = stop[0].loadStopStatuses;
        }
      } else {
        const stop = load.loadStops.filter((x) => x.stopNbr === load.stops);
        if (stop && stop.length === 1) {
          statuses = stop[0].loadStopStatuses;
        }
      }

      if (appt) {
        const latestAppt = statuses
          .filter((x) => x.statusType.indexOf('APPOINTMENT') >= 0)
          .sort((a, b) => (a.statusDateUtc >= b.statusDateUtc ? 1 : -1))[0];
        if (latestAppt) {
          result = `(APPT ${this.formatUtcDate(latestAppt.statusDateUtc, latestAppt.statusDateTimeZone)})`;
        }
      } else {
        const latestEta = statuses
          .filter((x) => x.statusTypeDesc.indexOf('ESTIMATED') >= 0)
          .sort((a, b) => (a.statusDateUtc >= b.statusDateUtc ? 1 : -1))[0];
        if (latestEta) {
          result = `(ETA ${this.formatUtcDate(latestEta.statusDateUtc, latestEta.statusDateTimeZone)})`;
        }
      }
    }

    return result;
  }

  public exportData() {
    this.table.exportCSV();
  }

  private formatUtcDate(date: Date, timeZone: string) {
    return moment.utc(date).tz(timeZone).format('M/D/YY h:mm A');
  }

  showLinehaulRateOverlay($event, hoveredRow: LoadView) {
    if (this.isMarketplace && this.showRateBreakDowns) {
      const data = {
        ...defaultRateBreakDownOverlayInput,
        usesAllInRates: hoveredRow.usesAllInRates,
        isShipper: true,
        isEstimatedFSC: hoveredRow.isEstimatedFSC,
        loadId: hoveredRow.loadId,
      };

      this.rateBreakDownInput = data;
      this.showrateBreakDownOverlayEvent = $event;
      this.showrateBreakDownOverlay = true;
    } else {
      const data = {
        ...defaultLinehaulRateData,
        linehaulRate: hoveredRow.lineHaulRate,
        fuelRate: hoveredRow.fuelRate,
        usesAllInRates: hoveredRow.usesAllInRates,
        isEstimatedFSC: hoveredRow.isEstimatedFSC,
      };

      this.linehaulRateData = data;
      this.showLinehaulOverlayEvent = $event;
      this.showLinehaulOverlay = true;
    }
  }

  hideLinehaulRateOverlay() {
    this.showLinehaulOverlay = false;
    this.showLinehaulOverlayEvent = null;
    this.linehaulRateData = null;
    this.rateBreakDownInput = null;
    this.showrateBreakDownOverlayEvent = null;
    this.showrateBreakDownOverlay = false;
  }
}
