import { createSelector } from '@ngrx/store';
import { AdminState, getAdminFeatureState } from '../reducers';
import { selectors, ShipperAccessorialState } from '../reducers/shipper-accessorials.reducer';

const getShipperAccessorialsState = createSelector(getAdminFeatureState, (state: AdminState) => state.shipperAccessorial);

export const getShipperAccessorials = createSelector(getShipperAccessorialsState, (state: ShipperAccessorialState) =>
  selectors.selectAll(state)
);
export const getGlobalAccessorials = createSelector(
  getShipperAccessorialsState,
  (state: ShipperAccessorialState) => state.globalAccessorials
);
export const getLoadingShipperAccessorials = createSelector(getShipperAccessorialsState, (state: ShipperAccessorialState) => state.loading);

export const getAccessorials = createSelector(getShipperAccessorialsState, (state: ShipperAccessorialState) => state.accessorials);
export const getAccessorialUnitOfMeasure = createSelector(
  getShipperAccessorialsState,
  (state: ShipperAccessorialState) => state.accessorialUnitOfMeasure
);

export const getSavingShipperAccessorial = createSelector(
  getShipperAccessorialsState,
  (state: ShipperAccessorialState) => state.savingShipperAccessorial
);
export const getSaveShipperAccessorialSucceeded = createSelector(
  getShipperAccessorialsState,
  (state: ShipperAccessorialState) => state.savingShipperAccessorialSucceeded
);

export const getSaveShipperAccessorialProblemDetails = createSelector(
  getShipperAccessorialsState,
  (state: ShipperAccessorialState) => state.savingShipperAccessorialProblemDetails
);
