<div class="page-container BrowserUpgrade">
  <div class="BrowserUpgrade__Content">
    <h1 class="BrowserUpgrade__Title">We're sorry, but this browser is no longer supported by Loadshop</h1>
    <div class="BrowserUpgrade__Message">
      You will experience limited functionality and unusable pages with your current browser. We recommend upgrading to one of the latest
      versions of the following browsers.
    </div>
    <div class="BrowserUpgrade__BrowserList">
      <a class="BrowserUpgrade__Browser" href="https://www.google.com/chrome/" target="_blank">
        <img class="BrowserUpgrade__Browser__Logo" src="../../../../assets/chrome-logo.png" />
        <span class="BrowserUpgrade__Browser__Label">Chrome</span>
      </a>
      <a class="BrowserUpgrade__Browser" href="https://www.microsoft.com/edge" target="_blank">
        <img class="BrowserUpgrade__Browser__Logo" src="../../../../assets/ms-edge-logo.png" />
        <span class="BrowserUpgrade__Browser__Label">Edge</span>
      </a>
      <a class="BrowserUpgrade__Browser" href="https://www.mozilla.org/firefox/" target="_blank">
        <img class="BrowserUpgrade__Browser__Logo" src="../../../../assets/firefox-logo.png" />
        <span class="BrowserUpgrade__Browser__Label">Firefox</span>
      </a>
    </div>
    <button
      class="BrowserUpgrade__ContinueBtn"
      role="button"
      aria-selected="false"
      aria-label="I understand, take me to the site anyway."
      (click)="handleClick()"
    >
      I understand, take me to the site anyway.
    </button>
  </div>
</div>
