/**
 * HubMessage.Api
 * Simplified wrapper API for connecting with the hub service.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ConversationMember { 
    id?: number;
    conversationId?: number;
    userName?: string;
    userId?: string;
}