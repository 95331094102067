<div>
  <div class="my-3">
    <p>Are you sure you want to post to the marketplace with the following rate(s)? 
      Clicking Yes will post all selected loads from the Post screen.   
      Clicking No will not post any selected loads from the Post screen.</p>
  </div>
  
    <p-table [value]="loads"  autoLayout="true" styleClass="p-datatable-no-border" >
      <ng-template pTemplate="header">
        <tr>
          <th>Order Number</th>
          <th>SmartSpot</th>
          <th>Total Rate</th>
          <th *ngIf="showBreakEven" kbxlActionCheck action="loadshop.ui.breakeven.view">Break-even</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-load>
        <tr>
          <td>
            {{ load.referenceLoadDisplay }}
          </td>
          <td>
              {{load.smartSpotRate | currency}}
          </td>
          <td>
              {{ load.lineHaulRate + load.shippersFSC | currency}}
          </td>
        </tr>
      </ng-template>
    </p-table>
 
  <div class="d-flex justify-content-between mt-3">
    <button pButton type="button" class="p-button-outlined p-button-secondary" label="No" (click)="no()"></button>
    <button pButton type="button" label="Yes" (click)="yes()"></button>
  </div>
</div>
