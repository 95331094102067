<div id="filter-header" class="d-lg-none" (click)="collapsed = !collapsed">
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center">
      <button
        *ngIf="!collapsed"
        pButton
        type="button"
        (click)="clearClick($event)"
        class="p-button-outlined p-button-secondary w-100"
        label="Clear"
      ></button>
      <div class="flex-grow-1 pl-2 pr-2 text-center">
        <fa-icon *ngIf="!collapsed" [icon]="['fas', 'caret-up']" size="2x"></fa-icon>
        <fa-icon *ngIf="collapsed" [icon]="['fas', 'caret-down']" size="2x"></fa-icon>
        <span class="kbxl-field-label">Filter Loads</span>
      </div>
      <button *ngIf="!collapsed" pButton type="button" (click)="applyClick($event)" class="w-100" label="Search"></button>
    </div>
  </div>
</div>
<div class="mobile-overlay">
  <div class="page-container py-2 px-lg-3 d-lg-block background" [class.d-none]="collapsed">
    <div class="d-flex justify-content-end">
      <div class="kbxl-inputgroup-container mb-0 mt-2">
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="filter-box p-inputgroup-addon"
            placeholder="Quick Search Criteria"
            [(ngModel)]="quickSearch"
            (keyup.enter)="runSearch()"
          />
          <button
            pButton
            type="button"
            icon="pi pi-times-circle"
            (click)="clearFilter('quickSearch')"
            class="clearQuickFilter p-inputgroup-addon"
          ></button>
          <button pButton type="button" icon="pi pi-filter" class="p-button-secondary p-inputgroup-addon" (click)="runSearch()"></button>
        </div>
      </div>
    </div>
    <p-panel [toggleable]="true" [collapsed]="!isMobile" styleClass="advanced-search-panel">
      <p-header class="p-0 marketplace-panel-header">
        <span>Advanced Search</span>
      </p-header>
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Origin</label>
              </div>
              <div class="p-inputgroup">
                <p-autoComplete
                  id="origCity"
                  [placeholder]="'Search by City/State/Province'"
                  [(ngModel)]="origin"
                  [suggestions]="suggestions"
                  (completeMethod)="citySearch($event)"
                  [forceSelection]="true"
                  field="description"
                ></p-autoComplete>
                <button
                  pButton
                  type="button"
                  class="p-button-secondary p-inputgroup-addon"
                  icon="pi pi-times-circle"
                  (click)="clearFilter('origin')"
                ></button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Destination</label>
              </div>
              <div class="p-inputgroup">
                <p-autoComplete
                  id="origCity"
                  [placeholder]="'Search by City/State/Province'"
                  [(ngModel)]="dest"
                  [suggestions]="suggestions"
                  (completeMethod)="citySearch($event)"
                  [forceSelection]="true"
                  field="description"
                ></p-autoComplete>
                <button
                  pButton
                  type="button"
                  class="p-button-secondary p-inputgroup-addon"
                  icon="pi pi-times-circle"
                  (click)="clearFilter('dest')"
                ></button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Action Item Status</label>
              </div>
              <p-multiSelect
                [options]="actionItemStatuses"
                placeholder="Select All Services"
                [(ngModel)]="selectedActionItemStatuses"
                optionLabel="description"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                appendTo="body"
                maxSelectedLabels="10"
                kbxlCloseOnScroll
                hideTransitionOptions="0ms"
              >
              </p-multiSelect>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">View Counter Offers</label>
              </div>
              <p-multiSelect
                [options]="companyUsers"
                optionLabel="fullName"
                [(ngModel)]="selectedCompanyUsers"
                placeholder="Select User(s)"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                [filter]="true"
                filterBy="label"
                dataKey="userId"
                appendTo="body"
                kbxlCloseOnScroll
              >
                <ng-template let-selectedItems pTemplate="selectedItems">
                  <span *ngIf="!selectedItems || selectedItems.length == 0">Select User(s)</span>
                  <span *ngIf="selectedItems && selectedItems.length >= 3">{{ selectedItems.length }} Users Selected</span>
                  <div *ngIf="selectedItems && selectedItems.length < 3">
                    <span *ngFor="let item of selectedItems"> {{ item?.fullName | titlecase }}, </span>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <span>{{ item?.label | titlecase }}</span>
                </ng-template>
              </p-multiSelect>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">&nbsp;</div>
        <div class="col-12 col-lg-4">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">&nbsp;</label>
              </div>

              <div class="d-flex flex-column">
                <div id="filter-footer" class="text-right">
                  <button
                    pButton
                    type="button"
                    (click)="clearClick($event)"
                    class="p-button-outlined p-button-secondary"
                    label="Clear"
                  ></button>
                  <button pButton type="button" (click)="runSearch()" class="ml-3" [label]="'Search'"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
</div>
