import { CustomerLaneGroupData, defaultCustomerLaneGroupData } from './customer-lane-group-data';
import { Carrier } from './carrier';

export interface CustomerLaneGroupShipperData extends CustomerLaneGroupData {
  shippingDetailDisplay: string;
  carriers: Carrier[];
}

export const defaultShippingLoadCarrierGroup: CustomerLaneGroupShipperData = {
  ...defaultCustomerLaneGroupData,
  shippingDetailDisplay: null,
  carriers: [],
};
