import { Search, defaultSearch } from './search';
import { LaneAttributeType } from './lane-attributes';

export interface LaneSearch extends Search {
  laneAttributeTypes: LaneAttributeType[];
}

export const defaultLaneSearch: LaneSearch = {
  ...defaultSearch,
  laneAttributeTypes: null,
};
