<div *ngIf="!conversation">
  <h6 class="text-muted text-center my-4">Please select a conversation or start a new.</h6>
</div>

<div [ngClass]="{ 'd-none': !conversation }">
  <div class="d-flex flex-column">
    <div *ngIf="targetUserActive === false" class="alert alert-primary d-flex align-items-center" role="alert">
      <fa-icon [icon]="['fas', 'exclamation-circle']" size="2x" class="avatar"> </fa-icon>
      <div class="ml-2">
        <strong>{{ otherChatUserName }}</strong>
        <span *ngIf="otherChatUserChatNotificationsEnabled; else noNotificationsEnabled">
          is offline and may not respond to this chat. You may start the chat and they will be emailed notification a chat has been started.
        </span>
        <ng-template #noNotificationsEnabled>
          is offline and may not respond to this chat. Please contact user by phone or email if needed
        </ng-template>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <kbxl-chat-presence-indicator
        [userId]="otherChatUserId"
        (userActiveChange)="targetUserActive = $event"
        class="mr-1"
      ></kbxl-chat-presence-indicator>
      <div class="direct-message-header">
        <h6 class="text-white">{{ otherChatUserName }}</h6>
        <h6 class="text-white">{{ otherChatEntityName }}</h6>
      </div>
      <button
        pButton
        icon="pi pi-chevron-left"
        type="button"
        class="d-none d-md-inline-block p-button-outlined p-button-secondary ml-auto"
        label="Close Chat"
        (click)="closeConversation()"
        [disabled]="loading$ | async"
      ></button>
    </div>
    <div class="d-flex flex-column position-relative">
      <!-- message pane -->
      <div class="mt-2 p-1 p-md-2 message-panel">
        <div class="progress-indicator" *ngIf="loading$ | async">
          <div class="progressspinner-container">
            <p-progressSpinner></p-progressSpinner>
          </div>
        </div>
        <div
          #scrollContainer
          id="scrollContainer"
          [ngClass]="{ 'empty-messages': !conversation || !conversation?.messages || conversation.messages.length === 0 }"
        >
          <div class="text-center" *ngIf="conversation?.hasMoreMessages">
            <button
              pButton
              icon="pi pi-clock"
              type="button"
              class="p-button-outlined p-button-secondary ml-auto"
              label="Get More Messages"
              (click)="getMoreMessages()"
            ></button>
          </div>
          <!-- messages -->
          <div class="d-flex flex-column scroll-hack" #chatMessageList id="chatMessageList">
            <div
              [attr.messageId]="message.id"
              class="d-flex flex-column mb-1"
              [ngClass]="{ 'last-message': isLast && message.isUserMessage }"
              *ngFor="let message of conversation?.messages; last as isLast; index as index"
            >
              <div *ngIf="showDateBoundary(index)" class="d-flex align-items-center my-3">
                <div class="horizontal-line flex-fill ml-4"></div>
                <div class="mx-4">{{ message.createDate | messageDatePipe }}</div>
                <div class="horizontal-line flex-fill"></div>
              </div>
              <div class="d-flex">
                <div class="flex-fill d-flex flex-column">
                  <div
                    class="comment-bubble"
                    [ngClass]="{ 'user-comment': message.isUserMessage, 'other-user-comment': !message.isUserMessage }"
                  >
                    <div class="comment-user-info mt-1">
                      <strong> {{ message.entityName }}</strong>
                      <div>{{ message.userName | titlecase }} {{ message.createDate | messageDatePipe: { showTime: true } }}</div>
                    </div>
                    <div class="comment">{{ message.value }}</div>
                  </div>
                  <div [ngClass]="{ 'ml-auto': message.isUserMessage }" *ngIf="message.hasLoadId">
                    <p-button
                      icon="pi pi-external-link"
                      iconPos="right"
                      [label]="message.loadId"
                      styleClass="comment-load-button p-button-text"
                      (click)="viewLoad(message)"
                    ></p-button>
                  </div>
                </div>
                <div *ngIf="isLast && message.isUserMessage" class="message-send-status-container align-self-end">
                  <div *ngIf="!message.id || message.id.length === 0; else messageSent">
                    <p-progressSpinner></p-progressSpinner>
                  </div>
                  <ng-template #messageSent>
                    <div class="message-sent">
                      <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="no-messages d-flex flex-column justify-content-center"
          *ngIf="!conversation || !conversation.messages || conversation.messages.length === 0"
        >
          <div class="text-center text-muted">No messages</div>
        </div>
      </div>
      <div class="d-flex mt-2" *ngIf="user && !user.isImpersonating">
        <textarea
          pInputTextarea
          placeholder="Type a new message"
          rows="1"
          autoResize="true"
          name="comments"
          class="comment-input"
          maxlength="4000"
          (keydown)="onKeyDown($event)"
          [(ngModel)]="currentMessageText"
        ></textarea>
        <button
          pButton
          (click)="addMessageClick()"
          icon="pi pi-arrow-right"
          type="button"
          class="p-button-teal comment-button ml-2"
        ></button>
      </div>
    </div>
  </div>
</div>
