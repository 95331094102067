import { Component, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/operators';
import {
  CoreState,
  FavoriteActionTypes,
  FavoriteAddAction,
  FavoriteAddFailureAction,
  FavoriteAddSuccessAction,
  getUserFavorites,
} from '../../../../core/store';
import { BaseComponent } from '../../../../shared/components';
import { AllUserFavoriteTypes, UserFavorite } from '../../../../shared/models';

@Component({
  selector: 'kbxl-add-user-search-modal',
  templateUrl: './add-user-search-modal.component.html',
  styleUrls: ['./add-user-search-modal.component.scss'],
})
export class AddUserSearchModalComponent extends BaseComponent implements OnInit {
  defaultSearch: false;
  searchName = '';

  savedSearches: UserFavorite[] = [];
  favorite: UserFavorite;
  showError = false;
  isSaving = false;
  errorMsg = 'An error occurred while saving your search, please try again.';
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private coreStore: Store<CoreState>,
    private actions$: Actions
  ) {
    super();
    this.favorite = config.data.favorite;

    actions$
      .pipe(ofType(FavoriteActionTypes.Add_Favorite_Success, FavoriteActionTypes.Add_Favorite_Failure), takeUntil(this.destroyed$))
      .subscribe((action: FavoriteAddSuccessAction | FavoriteAddFailureAction) => {
        if (action.type === FavoriteActionTypes.Add_Favorite_Success) {
          this.closeModal(true, action.addedUserFavoriteId);
        } else if (action.type === FavoriteActionTypes.Add_Favorite_Failure) {
          this.showError = true;
          this.errorMsg = 'An error occurred while saving your search, please try again.';
          this.isSaving = false;
        }
      });
  }

  ngOnInit(): void {
    this.coreStore
      .pipe(select(getUserFavorites, AllUserFavoriteTypes.MarketplaceSavedSearch), takeUntil(this.destroyed$))
      .subscribe((x) => (this.savedSearches = x));
  }

  closeModal(continueSearch: boolean, userFavoriteId: number = null): void {
    this.ref.close({
      continueSearch,
      userFavoriteId,
    });
  }
  cancel(): void {
    this.closeModal(false);
  }

  save(): void {
    // check if duplicate name exists
    if (this.savedSearches.find((x) => x.data.name.trim() === this.searchName.trim())) {
      this.showError = true;
      this.errorMsg = `${this.searchName.trim()} already exists, please remove or use a different name to save this search`;
      return;
    }

    this.isSaving = true;
    this.favorite.data = {
      ...this.favorite.data,
      name: this.searchName.trim(),
      defaultFavorite: this.defaultSearch,
    };
    // call api
    this.coreStore.dispatch(new FavoriteAddAction(this.favorite));
  }
}
