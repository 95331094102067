<div class="home-header-container d-flex justify-content-end px-1 px-xl-4 py-2 py-xl-3">
  <button
    pButton
    type="button"
    *ngIf="allowManualLoadCreation"
    kbxlActionCheck
    action="loadshop.ui.shopit.load.manual.create"
    (click)="createClicked()"
    class="p-button-outlined p-button-secondary create-button mr-3"
    label="Create"
    icon="pi pi-plus-circle"
  ></button>
  <button
    pButton
    type="button"
    (click)="postClicked()"
    class="p-button-teal post-button"
    kbxlActionCheck="loadshop.ui.shopit.load.post"
    [disabled]="!canPostLoads()"
    [label]="'Post ' + (selectedLoadCount > 0 ? '(' + selectedLoadCount + ')' : '')"
  ></button>
</div>
