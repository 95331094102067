<div class="d-flex flex-column wrapper">
  <div class="progress-indicator" *ngIf="isLoading">
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <p>Manage your default search as well removing existing saved searches</p>
  <div
    class="mx-3 pb-2 mb-2"
    [class.border-bottom]="!isLast"
    *ngFor="let search of savedSearches$ | async; trackBy: trackByFn; let isLast = last"
  >
    <div class="d-flex justify-content-end align-items-center">
      <div class="text-wrap mr-auto">{{ search.data.name }}</div>
      <div class="d-flex align-items-center mx-3">
        <small *ngIf="search.data.defaultFavorite" class="text-primary">Default</small>
        <p-inputSwitch
          class="ml-2"
          [ngModel]="search.data.defaultFavorite"
          (onChange)="handleDefaultChange($event, search)"
        ></p-inputSwitch>
      </div>
      <p-button icon="pi pi-trash" styleClass="p-button-danger" (click)="deleteSearch(search)"></p-button>
    </div>
  </div>
  <div class="m-4" *ngIf="(savedSearches$ | async)?.length === 0">
    <h6 class="text-muted">You have no saved searches</h6>
  </div>
  <div class="d-flex mt-3">
    <button pButton type="button" (click)="close()" class="p-button-outlined p-button-secondary ml-auto" label="Close"></button>
  </div>
</div>
