import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  CustomerLaneGroupData,
  CustomerLaneGroupDetailData,
  defaultLaneSearch,
  LaneSearch,
  LoadCarrierGroupType,
  ResponseError,
} from '../../../shared/models';
import { createReducer } from '../../../shared/utilities';
import { LaneManagementActions, LaneManagementActionTypes } from '../actions';

export interface LaneManagementState extends EntityState<CustomerLaneGroupData> {
  loadingCustomerLaneGroups: boolean;
  selectedCustomerLaneGroup: CustomerLaneGroupDetailData;
  loadingSelectedCustomerLaneGroup: boolean;
  savingCustomerLaneGroup: boolean;
  saveCustomerLaneGroupSucceeded: boolean;
  saveCustomerLaneGroupProblemDetails: ResponseError[];
  loadCarrierGroupTypes: LoadCarrierGroupType[];
  loadCarrierGroupTypesLoading: boolean;
  first: number;
  searchCrit: LaneSearch;
}

export const adapter: EntityAdapter<CustomerLaneGroupData> = createEntityAdapter<CustomerLaneGroupData>({
  selectId: (x) => x.customerLaneGroupId,
});

const initialState: LaneManagementState = adapter.getInitialState({
  loadingCustomerLaneGroups: false,
  selectedCustomerLaneGroup: null,
  loadingSelectedCustomerLaneGroup: false,
  savingCustomerLaneGroup: false,
  saveCustomerLaneGroupSucceeded: false,
  saveCustomerLaneGroupProblemDetails: null,
  loadCarrierGroupTypes: [],
  loadCarrierGroupTypesLoading: false,
  first: 0,
  searchCrit: { ...defaultLaneSearch },
});

const _laneManagementReducer = createReducer(
  initialState,
  function (state: LaneManagementState = initialState, action: LaneManagementActions): LaneManagementState {
    switch (action.type) {
      case LaneManagementActionTypes.Load: {
        return { ...state, loadingCustomerLaneGroups: true, saveCustomerLaneGroupProblemDetails: null, selectedCustomerLaneGroup: null };
      }
      case LaneManagementActionTypes.Load_Success: {
        return adapter.setAll(action.payload, { ...state, loadingCustomerLaneGroups: false });
      }
      case LaneManagementActionTypes.Load_Failure: {
        return { ...state, loadingCustomerLaneGroups: false, saveCustomerLaneGroupProblemDetails: null };
      }

      case LaneManagementActionTypes.Add:
      case LaneManagementActionTypes.Update:
      case LaneManagementActionTypes.Delete: {
        return {
          ...state,
          savingCustomerLaneGroup: true,
          saveCustomerLaneGroupSucceeded: false,
          saveCustomerLaneGroupProblemDetails: null,
        };
      }
      case LaneManagementActionTypes.Add_Failure:
      case LaneManagementActionTypes.Update_Failure: {
        return {
          ...state,
          savingCustomerLaneGroup: false,
          saveCustomerLaneGroupSucceeded: false,
          saveCustomerLaneGroupProblemDetails: action.payload.error.errors,
        };
      }
      case LaneManagementActionTypes.Delete_Failure:
      case LaneManagementActionTypes.Clear_Save_Succeeded: {
        return {
          ...state,
          savingCustomerLaneGroup: false,
          saveCustomerLaneGroupSucceeded: false,
          saveCustomerLaneGroupProblemDetails: null,
        };
      }

      case LaneManagementActionTypes.Add_Success: {
        return adapter.addOne(action.payload, {
          ...state,
          savingCustomerLaneGroup: false,
          saveCustomerLaneGroupSucceeded: true,
          saveCustomerLaneGroupProblemDetails: null,
        });
      }
      case LaneManagementActionTypes.Update_Success: {
        return adapter.updateOne(
          { id: action.payload.customerLaneGroupId, changes: action.payload },
          { ...state, savingCustomerLaneGroup: false, saveCustomerLaneGroupSucceeded: true, saveCustomerLaneGroupProblemDetails: null }
        );
      }
      case LaneManagementActionTypes.Delete_Success: {
        return adapter.removeOne(action.payload.customerLaneGroupId, {
          ...state,
          savingCustomerLaneGroup: false,
          saveCustomerLaneGroupSucceeded: true,
          saveCustomerLaneGroupProblemDetails: null,
        });
      }

      case LaneManagementActionTypes.Load_Lane: {
        return { ...state, loadingSelectedCustomerLaneGroup: true, saveCustomerLaneGroupProblemDetails: null };
      }
      case LaneManagementActionTypes.Load_Lane_Success: {
        if (action.payload && action.payload.customerLaneGroupId > 0) {
          return adapter.upsertOne(action.payload, {
            ...state,
            selectedCustomerLaneGroup: action.payload,
            loadingSelectedCustomerLaneGroup: false,
            saveCustomerLaneGroupProblemDetails: null,
          });
        }
        return {
          ...state,
          selectedCustomerLaneGroup: action.payload,
          loadingSelectedCustomerLaneGroup: false,
          saveCustomerLaneGroupProblemDetails: null,
        };
      }
      case LaneManagementActionTypes.Load_Lane_Failure: {
        return { ...state, loadingSelectedCustomerLaneGroup: false, saveCustomerLaneGroupProblemDetails: null };
      }
      case LaneManagementActionTypes.Load_Carrier_Group_Types: {
        return { ...state, loadCarrierGroupTypesLoading: true, loadCarrierGroupTypes: [] };
      }
      case LaneManagementActionTypes.Load_Carrier_Group_Types_Success: {
        return { ...state, loadCarrierGroupTypesLoading: false, loadCarrierGroupTypes: action.payload };
      }
      case LaneManagementActionTypes.Load_Carrier_Group_Types_Failure: {
        return { ...state, loadCarrierGroupTypesLoading: false, loadCarrierGroupTypes: [] };
      }
      case LaneManagementActionTypes.Page_Change: {
        return { ...state, first: action.payload };
      }
      case LaneManagementActionTypes.Search_Change: {
        return { ...state, searchCrit: action.payload };
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function laneManagementReducer(state: LaneManagementState, action: LaneManagementActions): LaneManagementState {
  return _laneManagementReducer(state, action);
}
export const selectors = adapter.getSelectors();
