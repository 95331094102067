export interface ShipperAccessorialData {
  customerAccessorialId: number;
  accessorialId: number;
  accessorialUnitOfMeasureId: number;
  variableRate: boolean;
  maxDollarRatePerUOM: number;
  maxRate: number;
  customerId?: string;
  customerName: string;
  accessorialDescription: string;
  accessorialUnitOfMeasureDescription: string;
  afterInitialHours?: number;

  // client properties
  isRemoved?: boolean;
}

export const defaulShipperAccessorial: ShipperAccessorialData = {
  customerAccessorialId: 0,
  accessorialId: null,
  accessorialUnitOfMeasureId: null,
  variableRate: false,
  maxDollarRatePerUOM: null,
  maxRate: null,
  accessorialDescription: null,
  customerName: null,
  customerId: null,
  accessorialUnitOfMeasureDescription: null,
  afterInitialHours: null,
};
