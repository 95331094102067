import { createSelector } from '@ngrx/store';
import { getLoadingEquipment } from 'src/app/core/store';
import { ShippingLoadDetail, ShippingLoadSupplementalData } from 'src/app/shared/models';
import { getShippingFeatureState, ShippingState } from '../reducers';
import {
  getLoading,
  getLoads,
  getLoadsSupplementalData,
  getLoadsSupplementalLoading,
  getPostValidationProblemDetails,
  getSuccessfullyPostedLoads,
  ShippingLoadDetailState,
} from '../reducers/shipping-load-detail.reducer';

const getShippingLoadDetailState = createSelector(getShippingFeatureState, (state: ShippingState) => state.loadDetail);

const getLoadingDetails = createSelector(getShippingLoadDetailState, (state: ShippingLoadDetailState) => getLoading(state));

export const getLatestLoadTransactionLoading = createSelector(
  getShippingLoadDetailState,
  (state: ShippingLoadDetailState) => state.loadingLatestTransaction
);

export const getLoadingShippingLoadDetails = createSelector(
  getLoadingDetails,
  getLoadingEquipment,
  getLatestLoadTransactionLoading,
  (b1: boolean, b2: boolean, b3: boolean) => b1 || b2 || b3
);

const getShippingLoads = createSelector(getShippingLoadDetailState, (state: ShippingLoadDetailState) => getLoads(state));
export const getShippingHomeLoads = createSelector(getShippingLoads, (loads: ShippingLoadDetail[]) => {
  if (!loads) {
    return null;
  }
  return loads;
});

export const getShippingLoadsLoading = createSelector(getShippingLoadDetailState, (state: ShippingLoadDetailState) => {
  return state.loading;
});

export const getShippingHomeSelectedLoads = createSelector(getShippingLoadDetailState, (state: ShippingLoadDetailState) => {
  const a = getLoads(state).filter((x) => x.selected);
  return a;
});

export const getShippingPostValidationProblemDetails = createSelector(getShippingLoadDetailState, (state: ShippingLoadDetailState) =>
  getPostValidationProblemDetails(state)
);
export const getShippingSuccessfullyPostedLoads = createSelector(getShippingLoadDetailState, (state: ShippingLoadDetailState) =>
  getSuccessfullyPostedLoads(state)
);

const getShippingLoadsSupplementalData = createSelector(getShippingLoadDetailState, getLoadsSupplementalData);
export const getShippingLoadsSupplementalDataForLoadId = createSelector(
  getShippingLoadsSupplementalData,
  (state: { [s: string]: ShippingLoadSupplementalData }, props) => state[props.loadId]
);

const getShippingLoadsSupplementalLoading = createSelector(getShippingLoadDetailState, getLoadsSupplementalLoading);
export const getShippingLoadsSupplementalLoadingForLoadId = createSelector(
  getShippingLoadsSupplementalLoading,
  (state: { [s: string]: boolean }, props) => state[props.loadId] || false
);

export const getShippingLoadsLoadingContractRatesForLoadId = createSelector(
  getShippingLoadsSupplementalLoadingForLoadId,
  getShippingLoadsSupplementalDataForLoadId,
  (loading: boolean, data: ShippingLoadSupplementalData) => {
    let result = loading;
    if (!result && data) {
      result = data.isLoadCarrierScacPrefetchProcessing;
    }

    return result;
  }
);
