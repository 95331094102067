import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'timeSpanToTime' })
export class TimeSpanToTimePipe implements PipeTransform {
  transform(value: string) {
    if (value) {
      const totalMinutes = moment.duration(value).asMinutes();
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.floor(totalMinutes % 60);
      if (hours > 0 && minutes > 0) {
        return hours + ' Hours' + minutes + ' Minutes';
      } else if (hours > 0) {
        return hours + ' Hours';
      } else if (minutes > 0) {
        return minutes + ' Minutes';
      } else {
        return 0;
      }
    }

    return value;
  }
}
