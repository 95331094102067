<div class="grid-container">
  <kbxl-accessorial-grid
    [loading]="loading$ | async"
    [shipperAccessorials]="globalAccessorials$ | async"
    [error]="saveErrors$ | async"
    [enableDelete]="true"
    [enableAdd]="true"
    (formDirty)="formDirty = $event"
    (save)="saveAccessorials($event)"
    (displayDetail)="displayDetail($event)"
  ></kbxl-accessorial-grid>
</div>
<router-outlet></router-outlet>
