import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  CustomerLaneGroupData,
  CustomerLaneGroupDetailData,
  CustomerLaneMatchData,
  LoadCarrierGroupType,
  SearchLaneFilterRequest,
  ServiceResponse,
} from '../../shared/models';
import { mapResponse } from '../../shared/operators/map-response';

@Injectable()
export class LaneManagementService {
  constructor(private http: HttpClient) {}

  getGroups(customerId: string): Observable<CustomerLaneGroupData[]> {
    return this.http
      .get<ServiceResponse<CustomerLaneGroupData[]>>(`${environment.apiUrl}/api/CustomerLaneGroup?customerId=${customerId}`)
      .pipe(mapResponse());
  }

  getGroup(customerlaneGroupId: number): Observable<CustomerLaneGroupDetailData> {
    return this.http
      .get<ServiceResponse<CustomerLaneGroupDetailData>>(`${environment.apiUrl}/api/CustomerLaneGroup/${customerlaneGroupId}`)
      .pipe(mapResponse());
  }

  updateGroup(group: CustomerLaneGroupDetailData): Observable<CustomerLaneGroupDetailData> {
    // const update = this.createGroup(group);
    return this.http
      .put<ServiceResponse<CustomerLaneGroupDetailData>>(`${environment.apiUrl}/api/CustomerLaneGroup/`, group)
      .pipe(mapResponse());
  }

  addGroup(group: CustomerLaneGroupDetailData): Observable<CustomerLaneGroupDetailData> {
    // const update = this.createGroup(group);
    return this.http
      .post<ServiceResponse<CustomerLaneGroupDetailData>>(`${environment.apiUrl}/api/CustomerLaneGroup/`, group)
      .pipe(mapResponse());
  }

  deleteGroup(group: CustomerLaneGroupDetailData): Observable<object> {
    return this.http
      .delete<ServiceResponse<object>>(`${environment.apiUrl}/api/CustomerLaneGroup/${group.customerLaneGroupId}`)
      .pipe(mapResponse());
  }

  getLoadCarrierGroupTypes(): Observable<LoadCarrierGroupType[]> {
    return this.http
      .get<ServiceResponse<LoadCarrierGroupType[]>>(`${environment.apiUrl}/api/CustomerLaneGroup/GetLoadCarrierGroupTypes`)
      .pipe(mapResponse());
  }
  getServiceTypeDetails(filter: SearchLaneFilterRequest, queryType: string ): Observable<CustomerLaneMatchData> {
    return this.http
      .post<ServiceResponse<CustomerLaneMatchData>>(
        `${environment.apiUrl}/api/CustomerLaneGroup/ServiceTypeDetails?queryType=${queryType}`, filter)
      .pipe(mapResponse());
  }

  //   private createGroup(group: CustomerLaneGroupDetailData): CustomerLaneGroupDetailData {
  //     const update = Object.assign({}, group);
  //     // TODO: delete update.customer;
  //     // delete update.equipment;
  //     return update;
  //   }
}
