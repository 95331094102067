export interface LoadAccessorialData {
  loadAccessorialId: number;
  accessorialId: number;
  accessorialUnitOfMeasureId: number;
  variableRate: boolean;
  maxDollarRatePerUOM: number;
  maxRate: number;
  LoadId: string;
  customerName: string;
  accessorialDescription: string;
  accessorialUnitOfMeasureDescription: string;
  afterInitialHours?: number;
}

export const defaulLoadAccessorial: LoadAccessorialData = {
  loadAccessorialId: 0,
  accessorialId: null,
  accessorialUnitOfMeasureId: null,
  variableRate: false,
  maxDollarRatePerUOM: null,
  maxRate: null,
  accessorialDescription: null,
  customerName: null,
  LoadId: null,
  accessorialUnitOfMeasureDescription: null,
  afterInitialHours: null,
};
