<p-panel>
  <p-header>Carrier Users</p-header>
  <div class="small-spinner-container" *ngIf="loadingCarrierUsers">
    <p-progressSpinner></p-progressSpinner>
    <div>Loading Carriers</div>
  </div>
  <div class="d-flex flex-wrap" *ngIf="!loadingCarrierUsers">
    <div *ngFor="let carrierUser of carrierUsers" class="mr-3 mb-3">
      <p-card styleClass="carrier-card active">
        <p-header class="p-3"> {{ carrierUser.firstName | titlecase }} {{ carrierUser.lastName | titlecase }} </p-header>
        <div class="mt-2 d-flex flex-column">
          <span class="badge bg-primary mb-2" *ngFor="let role of carrierUser.securityRoles; let isLast = last">{{
            role.accessRoleName
          }}</span>
        </div>
        <div class="mt-2" *ngFor="let notification of carrierUser.userNotifications">
          <span *ngIf="notification.messageTypeId === 'Phone' || notification.messageTypeId === 'Cell_Phone'">{{
            notification.notificationValue
          }}</span>
        </div>
      </p-card>
    </div>
  </div>
</p-panel>
