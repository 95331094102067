<ul class="error-container list-unstyled" *ngIf="loadDetailErrors && loadDetailErrors.length > 0">
  <li *ngFor="let error of loadDetailErrors">{{ error.message }}</li>
</ul>
<form [formGroup]="counterOfferForm" (ngSubmit)="submitCounterOffer()" class="addForm">
  <div class="d-flex flex-column">
    <div class="d-flex flex-column section pb-3 mb-3">
      <h6 class="text-center">{{ getRateLabel() }}</h6>
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="d-flex flex-column linehaul-container">
          <div class="rate-text">Line Haul</div>
          <div class="rate-value">
            {{ getLineHaul() | currency }}
          </div>
        </div>
        <div class="d-flex flex-column mx-4" *ngIf="getFuelRate() > 0">
          <fa-icon [icon]="['fas', 'plus']" size="lg"></fa-icon>
        </div>
        <div class="d-flex flex-column" *ngIf="getFuelRate() > 0">
          <div class="rate-text">FSC</div>
          <div class="rate-value">
            {{ load.fuelRate | currency }}
          </div>
        </div>
        <div class="d-flex flex-column mx-4">
          <fa-icon [icon]="['fas', 'equals']" size="lg"></fa-icon>
        </div>
        <div class="d-flex flex-column flex-fill text-center">
          <div class="all-in-rate-container">
            <div class="all-in-rate-text">Total Rate</div>
            <div class="all-in-rate-value">
              {{ getLineHaul() + load.fuelRate | currency }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column section pb-3 mb-3">
      <h6 class="text-center">Enter Counter Offer</h6>
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="d-flex flex-column linehaul-container">
          <div class="rate-text">Line Haul</div>
          <div>
            <input type="text" maxlength="10" class="linehaul-input" formControlName="linehaul" pInputText kbxlCurrencyInput />
          </div>
        </div>
        <div class="d-flex flex-column mx-4" *ngIf="load.fuelRate && load.fuelRate > 0">
          <fa-icon [icon]="['fas', 'plus']" size="lg"></fa-icon>
        </div>
        <div class="d-flex flex-column" *ngIf="load.fuelRate && load.fuelRate > 0">
          <div class="rate-text">FSC</div>
          <div class="rate-value">
            {{ getFuelRate() | currency }}
          </div>
        </div>
        <div class="d-flex flex-column mx-4">
          <fa-icon [icon]="['fas', 'equals']" size="lg"></fa-icon>
        </div>
        <div class="d-flex flex-column flex-fill text-center">
          <div class="all-in-rate-container">
            <div class="all-in-rate-text">Total Rate</div>
            <div class="all-in-rate-value">
              {{ calcCounterOffer() | currency }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column section pb-3 mb-3 align-items-center">
      <div class="d-flex align-items-center">
        <span>Expires after</span>
        <input type="text" pattern="\d*" maxlength="2" class="time-input ml-4 mr-2" formControlName="hours" pInputText />
        <span>Hour(s)</span>
        <input type="text" pattern="\d*" maxlength="2" class="time-input ml-4 mr-2" formControlName="minutes" pInputText />
        <span>Minutes</span>
      </div>
      <p class="mt-3" *ngIf="!shipperOfferOptions">
        If the Shipper accepts your offer, the tender will be processed on the agreed upon rate.
      </p>
    </div>

    <!-- Shipper contact info -->
    <div class="d-flex flex-column section pb-3" *ngIf="user && user.isCarrier && shipperUserData">
      <h6>Shipper Contact Information</h6>
      <div class="d-flex">
        <div class="d-flex flex-column">
          <span class="load-detail-label" [class.chat-on]="shouldShowShipperUserChatNow()">Name:</span>
          <span class="load-detail-label" *ngIf="shipperUserData?.email">Email: </span>
          <span class="load-detail-label" *ngFor="let phone of shipperUserData?.cellPhoneNumbers">Cell:</span>
          <span class="load-detail-label" *ngFor="let phone of shipperUserData?.phoneNumbers">Phone:</span>
        </div>
        <div class="d-flex flex-column">
          <div class="load-detail-value">
            <div kbxlFeature="chat" class="chat-now" *ngIf="shouldShowShipperUserChatNow(); else carrierUserNameOnly">
              <kbxl-chat-user
                [userId]="shipperUserData.identUserId"
                [userName]="shipperUserData.firstName"
                (chatNow)="chatWithShipperUserClick()"
              ></kbxl-chat-user>
            </div>
            <ng-template #carrierUserNameOnly>
              <div class="contact-name">
                {{ shipperUserData.firstName | titlecase }}
              </div>
            </ng-template>
          </div>
          <div class="booked-email d-flex" *ngIf="shipperUserData?.email">
            <a class="text-truncate" href="mailto: {{ shipperUserData?.email }}" [title]="shipperUserData?.email"
              >{{ shipperUserData?.email | lowercase }}
            </a>
            <kbxl-copy-to-clipboard class="ml-1" [text]="shipperUserData?.email | lowercase"></kbxl-copy-to-clipboard>
          </div>
          <div class="text-nowrap" *ngFor="let phone of shipperUserData?.cellPhoneNumbers">
            <a href="tel:{{ phone }}">{{ phone }}</a>
          </div>
          <div class="text-nowrap" *ngFor="let phone of shipperUserData?.phoneNumbers">
            <a href="tel:{{ phone }}">{{ phone }}</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Carrier contact info -->
    <div class="d-flex flex-column section pb-3" *ngIf="user && user.isShipper && shipperOfferOptions && shipperOfferOptions.carrierUser">
      <h6>Carrier Contact Information</h6>
      <div class="d-flex">
        <div class="d-flex flex-column">
          <span class="load-detail-label" [class.chat-on]="shouldShowCarrierUserChatNow()">Name:</span>
          <span class="load-detail-label" *ngIf="shipperOfferOptions.carrierUser?.email">Email: </span>
          <span class="load-detail-label" *ngFor="let phone of shipperOfferOptions.carrierUser?.cellPhoneNumbers">Cell:</span>
          <span class="load-detail-label" *ngFor="let phone of shipperOfferOptions.carrierUser?.phoneNumbers">Phone:</span>
        </div>
        <div class="d-flex flex-column">
          <div class="load-detail-value">
            <div kbxlFeature="chat" class="chat-now" *ngIf="shouldShowCarrierUserChatNow(); else carrierUserNameOnly">
              <kbxl-chat-user
                [userId]="shipperOfferOptions.carrierUser.identUserId"
                [userName]="shipperOfferOptions.carrierUser.firstName"
                (chatNow)="chatWithCarrierUserClick()"
              ></kbxl-chat-user>
            </div>
            <ng-template #carrierUserNameOnly>
              <div class="contact-name">
                {{ shipperOfferOptions.carrierUser.firstName | titlecase }}
              </div>
            </ng-template>
          </div>
          <div class="booked-email d-flex" *ngIf="shipperOfferOptions.carrierUser?.email">
            <a
              class="text-truncate"
              href="mailto: {{ shipperOfferOptions.carrierUser?.email }}"
              [title]="shipperOfferOptions.carrierUser?.email"
              >{{ shipperOfferOptions.carrierUser?.email | lowercase }}
            </a>
            <kbxl-copy-to-clipboard class="ml-1" [text]="shipperOfferOptions.carrierUser?.email | lowercase"></kbxl-copy-to-clipboard>
          </div>
          <div class="text-nowrap" *ngFor="let phone of shipperOfferOptions.carrierUser?.cellPhoneNumbers">
            <a href="tel:{{ phone }}">{{ phone }}</a>
          </div>
          <div class="text-nowrap" *ngFor="let phone of shipperOfferOptions.carrierUser?.phoneNumbers">
            <a href="tel:{{ phone }}">{{ phone }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex mt-3 mb-2">
      <button pButton type="submit" class="ml-auto" label="Request Counter Offer" [disabled]="!counterOfferForm.valid"></button>
    </div>
  </div>
</form>
