import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { MessageService, SelectItem, TreeNode } from 'primeng/api';
import { GoogleMapService } from '../../../core/services';
import {
  defaultLane,
  defaultSearch,
  Place,
  PlaceTypeEnum,
  Search,
  SearchTypeData,
  ServiceType,
  State,
  UserLane,
} from '../../../shared/models';
import { formatDate, groupBy, validateUserlane } from '../../../shared/utilities';
import { createPlace } from '../../../shared/utilities/create-place';

@Component({
  selector: 'kbxl-shipping-search-criteria',
  templateUrl: './shipping-search-criteria.component.html',
  styleUrls: ['./shipping-search-criteria.component.scss'],
})
export class ShippingSearchCriteriaComponent implements OnChanges {
  @Input() equipment: TreeNode[];
  @Input() criteria: Search;
  @Input() lanes: UserLane[];
  @Input() states: State[];
  @Input() isMobile: boolean;
  @Input() serviceTypes: ServiceType[];
  @Input() showExport = false;

  @Output() search: EventEmitter<Search> = new EventEmitter<Search>();
  @Output() add: EventEmitter<UserLane> = new EventEmitter<UserLane>();
  @Output() update: EventEmitter<UserLane> = new EventEmitter<UserLane>();
  @Output() clear: EventEmitter<string> = new EventEmitter<string>();
  @Output() export: EventEmitter<boolean> = new EventEmitter<boolean>();

  crit: Search = { ...defaultSearch };
  origDate: Date[];
  destDate: Date[];
  selectedLane: UserLane;
  selectedLaneId: string;
  selectLanes: SelectItem[];
  origin: Place;
  dest: Place;
  userLaneId: string;
  collapsed = true;
  selectedEquipment: TreeNode[] = [];
  quickSearch: string;
  selectedServiceTypes: ServiceType[];

  constructor(private googleService: GoogleMapService, private messageService: MessageService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lanes && this.lanes) {
      this.selectLanes = this.lanes.map((x) => ({ label: this.createLaneLabel(x), value: x.userLaneId }));
    }

    if (changes.criteria) {
      const crit = this.criteria || { ...defaultSearch };
      this.crit = { ...crit };

      this.updateSelectedEquipment();
      this.setDates(this.crit);
    }
  }

  setDates(model: Search) {
    if (!model) {
      this.origDate = null;
      this.destDate = null;
      return;
    }
    if (model.origDateStart) {
      if (model.origDateEnd) {
        this.origDate = [moment(model.origDateStart).toDate(), moment(model.origDateEnd).toDate()];
      } else {
        this.origDate = [moment(model.origDateStart).toDate()];
      }
    } else {
      this.origDate = null;
    }

    if (model.destDateStart) {
      if (model.destDateEnd) {
        this.destDate = [moment(model.destDateStart).toDate(), moment(model.destDateEnd).toDate()];
      } else {
        this.destDate = [moment(model.destDateStart).toDate()];
      }
    } else {
      this.destDate = null;
    }
  }

  setOriginDestination(model: UserLane | Search) {
    if (model.origLat && model.origLng) {
      if (model.origCity) {
        this.origin = createPlace(null, model.origCity, null, model.origState, null, model.origCountry);
      } else {
        this.googleService.reverseGeocode(model.origLat, model.origLng).then((x) => {
          this.origin = x;
        });
      }
    } else if (model.origState) {
      const state = this.states.find((x) => x.abbreviation.toLowerCase() === model.origState.toLowerCase());
      if (state) {
        this.origin = createPlace(null, null, state.name, model.origState, null, model.origCountry);
      }
    }
    if (model.destLat && model.destLng) {
      if (model.destCity) {
        this.dest = createPlace(null, model.destCity, null, model.destState, null, model.destCountry);
      } else {
        this.googleService.reverseGeocode(model.destLat, model.destLng).then((x) => {
          this.dest = x;
        });
      }
    } else if (model.destState) {
      const state = this.states.find((x) => x.abbreviation.toLowerCase() === model.destState.toLowerCase());
      if (state) {
        this.dest = createPlace(null, null, state.name, model.destState, null, model.destCountry);
      }
    }
  }

  runSearch(applying = false) {
    // skip the search if we are in mobile mode and we are not applying from a button
    if (this.isMobile && !applying) {
      return;
    }

    this.setSearchData().then((search) => {
      this.search.emit(search);
      this.collapsed = true;
    });
  }

  setSearchData(): Promise<Search> {
    // trim the quick search in case the user pastes or adds space
    if (this.quickSearch) {
      this.quickSearch = this.quickSearch.trim();
    }

    const origDate = this.origDate || [];
    const destDate = this.destDate || [];
    const search: Search = {
      origDateStart: formatDate(origDate[0]),
      origDateEnd: formatDate(origDate[1]),
      destDateStart: formatDate(destDate[0]),
      destDateEnd: formatDate(destDate[1]),
      equipmentType: this.buildSelectedEquipmentAsString(),
      quickSearch: this.quickSearch,
      showAllLoads: false,
    };

    if (this.selectedServiceTypes) {
      search.serviceTypes = this.selectedServiceTypes.map((x) => x.serviceTypeId);
    }
    if (this.origin) {
      search.origCity = this.origin.city;
      if (!this.origin.stateAbbrev && this.origin.state && this.origin.state.length > 2) {
        search.origState = this.states.find((x) => x.name.toLowerCase() === this.origin.state.toLowerCase()).abbreviation;
      } else {
        search.origState = this.origin.stateAbbrev;
      }
      search.origCountry = this.origin.country;

      if (this.origin.placeType !== PlaceTypeEnum.State && this.origin.placeType !== PlaceTypeEnum.Country) {
        search.origLat = this.origin.latitude;
        search.origLng = this.origin.longitude;
        if (!isNaN(this.crit.origDH) && this.crit.origDH > -1) {
          search.origDH = this.crit.origDH;
        } else {
          search.origDH = 50;
        }
      }
    }

    if (this.dest) {
      search.destCity = this.dest.city;
      if (!this.dest.stateAbbrev && this.dest.state && this.dest.state.length > 2) {
        search.destState = this.states.find((x) => x.name.toLowerCase() === this.dest.state.toLowerCase()).abbreviation;
      } else {
        search.destState = this.dest.stateAbbrev;
      }
      search.destCountry = this.dest.country;
      if (this.dest.placeType !== PlaceTypeEnum.State && this.dest.placeType !== PlaceTypeEnum.Country) {
        search.destLat = this.dest.latitude;
        search.destLng = this.dest.longitude;
        if (!isNaN(this.crit.destDH) && this.crit.destDH > -1) {
          search.destDH = this.crit.destDH;
        } else {
          search.destDH = 50;
        }
      }
    }

    return Promise.resolve(search);
  }

  loadFavorite(laneId: string) {
    this.crit = { ...defaultSearch };
    this.userLaneId = laneId;
    this.selectedLane = this.lanes.find((x) => x.userLaneId === laneId);
    if (this.selectedLane) {
      this.origin = null;
      this.dest = null;
      this.setOriginDestination(this.selectedLane);
      this.crit.origDH = this.selectedLane.origDH;
      this.crit.destDH = this.selectedLane.destDH;
      this.crit.equipmentType = JSON.stringify(this.selectedLane.equipmentIds);
      this.setDates(null);
      // Set Selected Equipment Tree Nodes
      this.updateSelectedEquipment();
    }
    this.runSearch(true);
  }

  clearClick($event) {
    this.userLaneId = null;
    this.selectedLaneId = null;
    this.selectedServiceTypes = null;
    this.quickSearch = null;
    this.dest = null;
    this.destDate = null;
    this.origDate = null;
    this.origin = null;
    this.clear.emit();
    this.collapsed = true;
    $event.stopPropagation();
  }

  clearFilter(prop: any) {
    this[prop] = null;
    this.runSearch();
  }

  saveClick() {
    this.setSearchData().then((search) => {
      const lane: UserLane = Object.assign({}, defaultLane, {
        equipmentIds: this.buildSelectedEquipment(),
        origLat: search.origLat,
        origLng: search.origLng,
        origDH: search.origDH,
        origState: search.origState,
        destLat: search.destLat,
        destLng: search.destLng,
        destDH: search.destDH,
        destState: search.destState,
        searchType: SearchTypeData.OriginDest,
      });
      if (this.origin) {
        lane.origCity = this.origin.city;
        lane.origCountry = this.origin.country;
      }
      if (this.dest) {
        lane.destCity = this.dest.city;
        lane.destCountry = this.dest.country;
      }
      const errors = validateUserlane(lane);
      if (errors.length) {
        this.messageService.addAll(errors);
      } else {
        if (this.userLaneId) {
          lane.userLaneId = this.userLaneId;
          this.update.emit(lane);
        } else {
          this.add.emit(lane);
        }
      }
    });
  }

  applyClick($event: Event) {
    this.runSearch(true);
    $event.stopPropagation();
  }

  exportData() {
    this.export.emit();
  }

  private updateSelectedEquipment() {
    if (this.crit && this.crit.equipmentType && this.equipment) {
      const flattenTreeNodes = this.equipment.map((_) => _.children).reduce((acc, value) => acc.concat(value));
      const equipmentTypes = JSON.parse(this.crit.equipmentType) as string[];
      const equipmentTreeNodes = equipmentTypes.map((currentEquipmentId) => {
        const equipment = flattenTreeNodes.find((_) => _.data.equipmentId === currentEquipmentId);

        if (equipment && equipment.parent) {
          equipment.parent.partialSelected = true;
        }

        return equipment;
      });

      const groupedSelections = groupBy((x) => x.data.categoryId, equipmentTreeNodes);

      groupedSelections.forEach((group) => {
        const treeViewGroup = this.equipment.find((x) => x.key === group.key);

        if (group.items.length === treeViewGroup.children.length) {
          treeViewGroup.partialSelected = false;
          equipmentTreeNodes.push(treeViewGroup);
        }
      });

      this.selectedEquipment = equipmentTreeNodes;
    } else {
      this.selectedEquipment = [];
    }
  }

  private buildSelectedEquipment() {
    return this.selectedEquipment.filter((_) => _.leaf).map((_) => _.data.equipmentId);
  }

  private buildSelectedEquipmentAsString() {
    const selectedEquipment = this.buildSelectedEquipment();
    if (selectedEquipment.length > 0) {
      return JSON.stringify(this.buildSelectedEquipment());
    }

    return null;
  }

  private createLaneLabel(lane: UserLane): string {
    let orig = '<div>';
    if (lane.origCity) {
      orig += `<span>${lane.origCity}, ${lane.origState}`;
      if (lane.origDH) {
        orig += ` (${lane.origDH} mi)`;
      }
      orig += '</span>';
    }
    if (!lane.origCity && lane.origState) {
      orig = `<span>${lane.origState}</span>`;
    }
    if (!lane.origCity && !lane.origState) {
      orig += '<span>Anywhere</span>';
    }
    orig += '</div>';

    let dest = '<div>';
    if (lane.destCity) {
      dest += `<span>${lane.destCity}, ${lane.destState}`;
      if (lane.origDH) {
        dest += ` (${lane.destDH} mi)`;
      }
      dest += '</span>';
    }
    if (!lane.destCity && lane.destState) {
      dest += `<span>${lane.destState}</span>`;
    }
    if (!lane.destCity && !lane.destState) {
      dest += '<span>Anywhere</span>';
    }
    dest += '</div>';
    return orig + '<div>&darr;</div>' + dest;
  }
}
