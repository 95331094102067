import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { CoreState, DropDownOptionTypeIds, getDropDowns } from '../../../../core/store';
import { BaseComponent, DynamicPricingRuleFormComponent } from '../../../../shared/components';
import {
  DropdownOption,
  DynamicPricingRuleData,
  DynamicPricingScheduleChangedData,
  DynamicPricingScheduleData,
  ShippingLoadDetail,
  defaultDynamicPricing,
} from '../../../../shared/models';
@Component({
  selector: 'kbxl-load-dynamic-pricing-container',
  templateUrl: './load-dynamic-pricing-container.component.html',
  styleUrls: ['./load-dynamic-pricing-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadDynamicPricingContainerComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChild(DynamicPricingRuleFormComponent) rateSeekerForm: DynamicPricingRuleFormComponent;
  private _defaultPricingRule: DynamicPricingRuleData;
  isValid: boolean;
  @Input() set defaultPricingRule(value: DynamicPricingRuleData) {
    this._defaultPricingRule = value;
    // set the current revision to input rule
    this.currentPricingRuleRevision = value;
  }
  get defaultPricingRule() {
    return this._defaultPricingRule;
  }

  @Input() visible: boolean;
  @Input() load: ShippingLoadDetail;
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updatePricingRule: EventEmitter<DynamicPricingScheduleChangedData> = new EventEmitter<DynamicPricingScheduleChangedData>();
  private currentPricingRuleRevision: DynamicPricingRuleData;
  private currentPricingRuleDataRevision: DynamicPricingScheduleChangedData;
  private latestAppliedPricingRuleData: DynamicPricingScheduleChangedData;

  percentOptions: DropdownOption[];
  frequencyOptions: DropdownOption[];
  intervalOptions: DropdownOption[];
  loadPricingSchedule: DynamicPricingScheduleData;
  pricingRule: DynamicPricingRuleData;
  maxLineHaulForBrokeredLoads: number;
  useReservedMargin: boolean;
  pickUpDate: Date;

  firstLoadEmitSchedule = false;
  constructor(private coreStore: Store<CoreState>) {
    super();
  }
  /*
   ** Called from parent component when load is posted
   */
  public rebuildRateSeekerSchedule(): void {
    this.pricingRule = null;
    this.loadPricingSchedule = null;
    this.latestAppliedPricingRuleData = null;
  }
  ngOnInit() {
    this.setLoadDefaults();
    this.isValid = true;

    this.coreStore
      .pipe(select(getDropDowns, DropDownOptionTypeIds.DPPercentage), takeUntil(this.destroyed$))
      .subscribe((x) => (this.percentOptions = x));
    this.coreStore
      .pipe(select(getDropDowns, DropDownOptionTypeIds.DPFrequency), takeUntil(this.destroyed$))
      .subscribe((x) => (this.frequencyOptions = x));
    this.coreStore
      .pipe(select(getDropDowns, DropDownOptionTypeIds.DPInterval), takeUntil(this.destroyed$))
      .subscribe((x) => (this.intervalOptions = x));
  }
  ngOnChanges(changes: SimpleChanges): void {
    // if a load is removed from the marketplace, we clear the pricing rule, schedule and latestApplied rule
    // if the load is refreshed from the server, use that pricing schedule
    if (
      this.load &&
      this.load.latestLoadPricingRule &&
      !this.pricingRule &&
      !this.latestAppliedPricingRuleData &&
      !this.loadPricingSchedule
    ) {
      this.setLoadDefaults();
    }
  }
  setLoadDefaults(): void {
    if (this.load.latestLoadPricingRule && !this.defaultPricingRule) {
      this.setPricingRuleFromLoadPricingRule();
    } else if (this.defaultPricingRule && this.load.isBrokeredLoad && !this.load.latestLoadPricingRule) {
      this.applyBrokeredCalculations();
    }
  }

  setPricingRuleFromLoadPricingRule() {
    // if the load is populated and a dynamic price schedule was saved (i.e. overridden from default or posted to marketplace)
    if (this.load && this.load.latestLoadPricingRule) {
      if (this.load.latestLoadPricingRule.dynamicPricingSchedule) {
        this.loadPricingSchedule = this.load.latestLoadPricingRule.dynamicPricingSchedule;
      }

      // this will override the default with what the load was saved with
      this.pricingRule = {
        ...defaultDynamicPricing,
        customerLaneGroupPricingRuleId: this.load.latestLoadPricingRule.pricingRuleId,
        floorAmt: this.load.latestLoadPricingRule.floorAmt,
        floorPct: this.load.latestLoadPricingRule.floorPct,
        nbrAdjustments: this.load.latestLoadPricingRule.nbrAdjustments,
        adjustmentFrequency: this.load.latestLoadPricingRule.adjustmentFrequency,
        reservedMargin: this.load.latestLoadPricingRule.reservedMargin,
        startBeforePickUpDateHours: this.load.latestLoadPricingRule.startBeforePickUpDateHours,
        stopBeforePickUpDateHours: this.load.latestLoadPricingRule.stopBeforePickUpDateHours,
      };

      this.applyBrokeredCalculations();
    }
  }

  private applyBrokeredCalculations() {
    // if (this.load.isBrokeredLoad) {
    //   let calulatedLineHaul = 0;
    //   const totalRate = Number(this.load.lineHaulRate.toFixed(2)) + Number(this.load.shippersFSC.toFixed(2));
    //   if (totalRate === Number(this.load.brokeredQuoteData?.totalPrice.toFixed(2))) {
    //     const reservedMargin = this.load.latestLoadPricingRule?.reservedMargin == null ?
    // 0 : this.load.latestLoadPricingRule.reservedMargin;
    //     calulatedLineHaul = this.load.lineHaulRate - reservedMargin;
    //     this.useReservedMargin = true;
    //   } else {
    //     calulatedLineHaul = this.load.lineHaulRate;
    //     this.useReservedMargin = false;
    //   }
    //   this.maxLineHaulForBrokeredLoads = Number(calulatedLineHaul.toFixed(2));
    //   this.pickUpDate = this.load.loadStops && this.load.loadStops.length > 0 ? this.load.loadStops[0].earlyDtTm : null;
    // }
  }

  scheduleChanged(payload: DynamicPricingScheduleChangedData): void {
    this.currentPricingRuleDataRevision = payload;
    this.currentPricingRuleRevision = payload.pricingRule;

    if ((this.load.latestLoadPricingRule && !this.latestAppliedPricingRuleData) || !this.visible) {
      // capture the first event change which is populated from the load schedule
      // OR if the component is not visible, let the result pass through
      this.latestAppliedPricingRuleData = this.currentPricingRuleDataRevision;
    }

    // only bubble up the schedule change when this component is hidden
    if (!this.visible) {
      this.updatePricingRule.emit(this.currentPricingRuleDataRevision);
    }
  }
  formValid(valid: boolean) {
    this.isValid = valid;
  }
  applyRateSeekerChanges() {
    this.latestAppliedPricingRuleData = this.currentPricingRuleDataRevision;
    this.updatePricingRule.emit(this.currentPricingRuleDataRevision);

    this.closeRateSeekerDialog();
  }
  cancelRateSeekerChanges() {
    if (this.latestAppliedPricingRuleData) {
      // if we had a rule we started with (error or not) apply it back to the load
      if (this.latestAppliedPricingRuleData.pricingRule) {
        this.pricingRule = this.latestAppliedPricingRuleData.pricingRule;
      }
      this.updatePricingRule.emit(this.latestAppliedPricingRuleData);

      if (this.latestAppliedPricingRuleData.pricingRule) {
        this.loadPricingSchedule = this.latestAppliedPricingRuleData.pricingRule.pricingSchedule;
      }

      this.rateSeekerForm.resetForm(this.latestAppliedPricingRuleData.pricingRule);
    } else {
      this.setPricingRuleFromLoadPricingRule();
    }
    this.closeRateSeekerDialog();
  }

  closeRateSeekerDialog() {
    this.cancel.emit(true);
  }
}
