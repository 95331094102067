import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { defaultEmailContact, defaultSmsContact, NotificationContact } from '../../models';

@Component({
  selector: 'kbxl-notification-contact-builder',
  templateUrl: './notification-contact-builder.component.html',
  styleUrls: ['./notification-contact-builder.component.scss'],
})
export class NotificationContactBuilderComponent implements OnDestroy {
  @Input() emailContacts: NotificationContact[] = [{ ...defaultEmailContact }];
  @Input() smsContacts: NotificationContact[] = [{ ...defaultSmsContact }];
  @Input() cancelConfirmationMessage: string;
  @Input() sendConfirmationMessage: string;
  @Input() processing: boolean;
  @Output() sendClick = new EventEmitter<NotificationContact[]>();
  @Output() cancelClick = new EventEmitter<boolean>();

  constructor(private confirmationService: ConfirmationService) {}

  addSmsContact() {
    this.smsContacts.push({ ...defaultSmsContact });
  }

  addEmailContact() {
    this.emailContacts.push({ ...defaultEmailContact });
  }

  cancel() {
    this.confirmationService.confirm({
      message: this.cancelConfirmationMessage,
      accept: () => {
        this.resetContacts();
        this.cancelClick.emit(true);
      },
    });
  }

  send() {
    this.confirmationService.confirm({
      message: this.sendConfirmationMessage,
      accept: () => {
        // Only Send to contacts that have an email or phone number
        const contacts = this.emailContacts.concat(this.smsContacts).filter((_) => _.to);
        this.sendClick.emit(contacts);
        this.resetContacts();
      },
    });
  }

  resetContacts() {
    this.emailContacts = [{ ...defaultEmailContact }];
    this.smsContacts = [{ ...defaultSmsContact }];
  }

  ngOnDestroy(): void {
    this.emailContacts = [];
    this.smsContacts = [];
  }
}
