import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MenuItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FuelRateEstimateByProgramData } from 'src/app/shared/models/fuel-rate-estimate-by-program-data';
import { SecurityAppActionType } from 'src/app/shared/models/security-app-action-type';
import { environment } from '../../../environments/environment';
import {
  CarrierCarrierScacGroup,
  CarrierProfile,
  Customer,
  CustomerFavorite,
  LoadshopSetting,
  RecaptchaRequest,
  ServiceResponse,
  SmartSpotPrice,
  SmartSpotPriceRequest,
  SmartSpotQuoteRequest,
  SpotPriceQuickQuoteRequest,
  SpotPriceQuoteRequest,
  SpotPriceQuoteResponse,
  StartupData,
  UserContactData,
  UserFavorite,
  UserFavoriteAddUpdateResponseData,
  UserModel,
  UserSelectItem,
} from '../../shared/models';
import { mapResponse } from '../../shared/operators/map-response';

@Injectable()
export class CommonService {
  featurePrefix = 'feature_';
  constructor(private http: HttpClient, private recatpchaService: ReCaptchaV3Service) {}

  getStartupData(): Observable<StartupData> {
    return this.http.get<ServiceResponse<StartupData>>(`${environment.apiUrl}/api/startup/data`).pipe(mapResponse());
  }

  getMenuItems(user: UserModel): Observable<MenuItem[]> {
    const items: MenuItem[] = [];

    if (!user) {
      return of(items);
    }

    if (!user.hasAgreedToTerms) {
      items.push({ label: 'Terms', routerLink: ['/user-agreement'] });
      return of(items);
    }

    if (user.isCarrier) {
      if (user.hasSecurityAction(SecurityAppActionType.CarrierMarketPlaceView)) {
        items.push({ label: 'Marketplace', routerLink: ['/loads/search'] });
      }
      if (user.hasSecurityAction(SecurityAppActionType.CarrierMyLoadsView)) {
        items.push({ label: 'Booked', routerLink: ['/loads/booked'], badgeStyleClass: 'badge badge-danger' });
      }
      if (user.hasSecurityAction(SecurityAppActionType.CarrierViewDelivered)) {
        items.push({ label: 'Delivered', routerLink: ['/loads/delivered'], badgeStyleClass: 'badge badge-danger' });
      }
    }

    if (user.isShipper) {
      if (user.hasSecurityAction(SecurityAppActionType.ShipperViewActiveLoads)) {
        items.push({ label: 'Post', routerLink: ['/shipping/home'] });
      }
      if (user.hasSecurityAction(SecurityAppActionType.ShipperViewPostedLoads)) {
        items.push({ label: 'Marketplace', routerLink: ['/shipping/marketplace'] });
      }
      if (user.hasSecurityAction(SecurityAppActionType.ShipperViewBookedLoads)) {
        items.push({ label: 'Booked', routerLink: ['/shipping/booked'] });
      }
      if (user.hasSecurityAction(SecurityAppActionType.ShipperViewDeliveredLoads)) {
        items.push({ label: 'Delivered', routerLink: ['/shipping/delivered'] });
      }
    }

    return of(items);
  }

  getAdminMenuItems(user: UserModel, loadshopSettings: LoadshopSetting[]): Observable<MenuItem[]> {
    const items: MenuItem[] = [];

    if (
      user &&
      (user.hasSecurityAction(SecurityAppActionType.ShipperUserAddEdit) || user.hasSecurityAction(SecurityAppActionType.CarrierUserAddEdit))
    ) {
      items.push({ label: 'Users', routerLink: ['users'] });
    }

    if (user && user.hasSecurityAction(SecurityAppActionType.ShipperAddEdit)) {
      items.push({ label: 'Shipper Profile', routerLink: ['shipper-profile'] });
    }

    if (user && user.hasSecurityAction(SecurityAppActionType.CarrierAddEdit)) {
      items.push({ label: 'Carrier Profile', routerLink: ['carrier-profile'] });
    }
    if (user && user.hasSecurityAction(SecurityAppActionType.CarrierViewUsers)) {
      items.push({ label: 'Carrier', routerLink: ['carrier'] });
    }
    if (user && user.hasSecurityAction(SecurityAppActionType.UserCommunicationAddEdit)) {
      items.push({ label: 'User Communications', routerLink: ['user-communications'] });
    }
    if (user && user.hasSecurityAction(SecurityAppActionType.ShipperCustomerLaneGroupsAddEdit) && user.isShipper) {
      items.push({ label: 'Lane Management', routerLink: ['lane-management'] });
    }

    let accessorialsEnabled = false;
    if (loadshopSettings) {
      const accessorialsfeatureFlag = loadshopSettings.find(
        (setting) => setting.key.toLocaleLowerCase() === this.featurePrefix + 'accessorials'
      );
      if (accessorialsfeatureFlag && accessorialsfeatureFlag.value.toLowerCase() === 'true') {
        accessorialsEnabled = true;
      }
    }

    if (accessorialsEnabled && user && user.hasSecurityAction(SecurityAppActionType.ConfigurationsAddEdit)) {
      items.push({ label: 'Configurations', routerLink: ['configurations'] });
    }

    if (accessorialsEnabled && user && user.hasSecurityAction(SecurityAppActionType.ShipperAccessorialsAddEdit)) {
      // check if user's shipper has access
      if (user.authorizedShippers && user.authorizedShippers.length > 0) {
        const shipper = user.authorizedShippers.find((x) => x.customerId === user.primaryCustomerId);

        if (shipper && shipper.useLoadshopAccessorials) {
          items.push({ label: 'Accessorials', routerLink: ['accessorials'] });
        }
      }
    }

    if (user && user.hasSecurityAction(SecurityAppActionType.AdminClearCache)) {
      items.push({ label: 'Admin', routerLink: ['admin'] });
    }

    return of(items);
  }

  getFavorites(): Observable<UserFavorite[]> {
    return this.http.get<ServiceResponse<UserFavorite[]>>(environment.apiUrl + '/api/Favorite/').pipe(mapResponse());
  }

  addFavorite(data: UserFavorite): Observable<UserFavoriteAddUpdateResponseData> {
    // format the add / update requests to pass a string instead of obj for userFavorite data
    const payload = { ...data, data: JSON.stringify(data.data) };

    return this.http
      .post<ServiceResponse<UserFavoriteAddUpdateResponseData>>(environment.apiUrl + '/api/Favorite/', payload)
      .pipe(mapResponse());
  }

  updateFavorite(data: UserFavorite): Observable<UserFavoriteAddUpdateResponseData> {
    // format the add / update requests to pass a string instead of obj for userFavorite data
    const payload = { ...data, data: JSON.stringify(data.data) };

    return this.http
      .put<ServiceResponse<UserFavoriteAddUpdateResponseData>>(environment.apiUrl + `/api/Favorite/${data.userFavoriteId}`, payload)
      .pipe(mapResponse());
  }

  deleteFavorite(data: UserFavorite): Observable<boolean> {
    return this.http.delete<ServiceResponse<boolean>>(environment.apiUrl + `/api/Favorite/${data.userFavoriteId}`).pipe(
      map((x) => {
        if (x) {
          return x;
        }
        throw new Error('Unable to delete favorite');
      }),
      mapResponse()
    );
  }

  getCustomerFavorites(): Observable<CustomerFavorite[]> {
    return this.http.get<ServiceResponse<CustomerFavorite[]>>(environment.apiUrl + '/api/CustomerFavorite/').pipe(mapResponse());
  }

  addCustomerFavorite(data: CustomerFavorite): Observable<CustomerFavorite> {
    return this.http.post<ServiceResponse<CustomerFavorite>>(environment.apiUrl + '/api/CustomerFavorite/', data).pipe(mapResponse());
  }

  updateCustomerFavorite(data: CustomerFavorite): Observable<CustomerFavorite> {
    return this.http
      .put<ServiceResponse<CustomerFavorite>>(environment.apiUrl + `/api/CustomerFavorite/${data.customerFavoriteId}`, data)
      .pipe(mapResponse());
  }

  deleteCustomerFavorite(data: CustomerFavorite): Observable<boolean> {
    return this.http.delete<ServiceResponse<boolean>>(environment.apiUrl + `/api/CustomerFavorite/${data.customerFavoriteId}`).pipe(
      map((x) => {
        if (x) {
          return x;
        }
        throw new Error('Unable to delete favorite');
      }),
      mapResponse()
    );
  }

  getCarriers(): Observable<CarrierProfile[]> {
    return this.http.get<ServiceResponse<CarrierProfile[]>>(environment.apiUrl + '/api/Carrier/').pipe(mapResponse());
  }

  getAllCarrierCarrierScacs(): Observable<CarrierCarrierScacGroup[]> {
    return this.http.get<ServiceResponse<CarrierCarrierScacGroup[]>>(environment.apiUrl + '/api/Carrier/All').pipe(mapResponse());
  }
  getCarrierCompanyUsers(scac: string, includeUserNotifications = false): Observable<UserContactData[]> {
    return this.http
      .get<ServiceResponse<UserContactData[]>>(
        environment.apiUrl + `/api/Carrier/company-users?scac=${scac}&includeUserNotifications=${includeUserNotifications}`
      )
      .pipe(mapResponse());
  }

  getCustomer(customerId: string): Observable<Customer> {
    return this.http.get<ServiceResponse<Customer>>(environment.apiUrl + `/api/Customer/${customerId}`).pipe(mapResponse());
  }

  getSmartSpotPrice(request: SmartSpotPriceRequest[]): Observable<ServiceResponse<SmartSpotPrice[]>> {
    return this.http.post<ServiceResponse<SmartSpotPrice[]>>(environment.apiUrl + '/api/SmartSpotPrice/', request);
  }

  getSpotQuote(request: SpotPriceQuoteRequest): Observable<ServiceResponse<SpotPriceQuoteResponse>> {
    return this.http.post<ServiceResponse<SpotPriceQuoteResponse>>(environment.apiUrl + '/api/SmartSpotPrice/spot-quote', request);
  }

  getSmartSpotQuote(request: SmartSpotQuoteRequest) {
    // If there is an exception with the resolving the promise, such as Error: Uncaught (in promise): [object Null]
    // check the site key to make sure it's correct for the server
    return this.recatpchaService.execute('SmartSpotPriceQuote').pipe(
      switchMap((token: string) =>
        this.http.post<ServiceResponse<number>>(environment.apiUrl + '/api/SmartSpotPrice/quote', {
          token: token,
          data: request,
        } as RecaptchaRequest<SmartSpotQuoteRequest>)
      ),
      mapResponse()
    );
  }

  getQuickSpotQuote(request: SpotPriceQuickQuoteRequest) {
    // If there is an exception with the resolving the promise, such as Error: Uncaught (in promise): [object Null]
    // check the site key to make sure it's correct for the server
    return this.recatpchaService.execute('SmartSpotPriceQuote').pipe(
      switchMap((token: string) =>
        this.http.post<ServiceResponse<SpotPriceQuoteResponse>>(environment.apiUrl + '/api/SmartSpotPrice/quick-spot-quote', {
          token: token,
          data: request,
        } as RecaptchaRequest<SpotPriceQuickQuoteRequest>)
      ),
      mapResponse()
    );
  }

  getFuelRateEstimate(loadId: string, customerId: string): Observable<FuelRateEstimateByProgramData> {
    const url = `${environment.apiUrl}/api/FuelRates/GetFuelRateEstimate/${loadId}/${customerId}`;
    return this.http.get<ServiceResponse<FuelRateEstimateByProgramData>>(url).pipe(mapResponse());
  }

  getShipperUsers(customerId: string): Observable<UserSelectItem[]> {
    return this.http.get<ServiceResponse<UserSelectItem[]>>(environment.apiUrl + `/api/Customer/${customerId}/Users`).pipe(mapResponse());
  }
  getShipperCompanyUsers(customerId: string): Observable<UserContactData[]> {
    return this.http
      .get<ServiceResponse<UserContactData[]>>(environment.apiUrl + `/api/Customer/${customerId}/company-users`)
      .pipe(mapResponse());
  }

  handleFileDownload(file: Blob, fileName: string): void {
    // only download the document if the request matches this components id
    if (!file) {
      return;
    }

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, fileName);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  getCountries() {
    return [
      { id: 'USA', name: 'USA' },
      { id: 'CAN', name: 'Canada' },
    ];
  }
}
