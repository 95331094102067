import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/operators';
import { CoreState, getBrowserIsMobile } from '../../../core/store';
import { AgreementDocumentData } from '../../models';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'kbxl-agreement-document-view-only',
  templateUrl: './agreement-document-view-only.component.html',
  styleUrls: ['./agreement-document-view-only.component.scss'],
})
export class AgreementDocumentViewOnlyComponent extends BaseComponent implements OnInit {
  agreement: AgreementDocumentData;
  isMobile: boolean;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private browserStore: Store<CoreState>) {
    super();
    this.agreement = config.data as AgreementDocumentData;
  }
  ngOnInit(): void {
    this.browserStore.pipe(select(getBrowserIsMobile), takeUntil(this.destroyed$)).subscribe((x) => {
      this.isMobile = x;
    });
  }

  close(): void {
    this.ref.close();
  }
}
