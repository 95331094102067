<p-panel>
  <p-header>Agreements</p-header>
  <div class="small-spinner-container" *ngIf="loadingCarrierUsers">
    <p-progressSpinner></p-progressSpinner>
    <div>Loading</div>
  </div>
  <div class="d-flex" *ngIf="!loadingCarrierUsers && carrierAgreements && carrierAgreements.length > 0">
    <p-table [value]="carrierAgreements">
      <ng-template pTemplate="header">
        <tr>
          <th>Type</th>
          <th>Effective</th>
          <th>User</th>
          <th>Date</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-i="rowIndex" let-carrierAgreement>
        <tr>
          <td>
            <a class="text-primary link" (click)="showAgreement(carrierAgreement)"> {{ carrierAgreement.agreement.agreementTitle }}</a>
          </td>
          <td>{{ carrierAgreement.agreement.agreementActiveDtTm | date: 'shortDate' }}</td>
          <td>
            {{ carrierAgreement.user.firstName | titlecase }} {{ carrierAgreement.user.lastName | titlecase }} ({{
              carrierAgreement.user.username
            }})
          </td>
          <td>
            {{ carrierAgreement.createDtTm | date: 'shortDate' }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-panel>
