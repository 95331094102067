import { ActionItemLoadView, defaultActionItemLoadView } from './action-item-load-view';

export interface CounterOfferActionItemLoadView extends ActionItemLoadView {
  actionItemCount: number;
  loadshopFee: number;
  counterOfferRate: number;
  counterOfferLoadshopFee: number;
  counterOfferExpirationDate: Date;
  mostRecentOfferUserName: string;
}

export const defaultCounterOfferActionItemLoadView: CounterOfferActionItemLoadView = {
  ...defaultActionItemLoadView,
  actionItemCount: 0,
  loadshopFee: null,
  counterOfferRate: 0,
  counterOfferLoadshopFee: null,
  counterOfferExpirationDate: null,
  mostRecentOfferUserName: null,
};
