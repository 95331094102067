export function getBreakEvenRateStyles(totalRate: number, quotePrice: number, isBrokeredLoad: boolean, includeMargin: boolean = false) {
  if (includeMargin) {
    const margin = quotePrice - totalRate;
    if (margin < 0) {
      return {
        'breakEven-success': false,
        'breakEven-warning': true,
      };
    } else {
      return {
        'breakEven-success': true,
        'breakEven-warning': false,
      };
    }
  } else if (isBrokeredLoad && quotePrice > 0) {
    if (totalRate > quotePrice) {
      return {
        'breakEven-success': false,
        'breakEven-warning': true,
      };
    } else {
      return {
        'breakEven-success': true,
        'breakEven-warning': false,
      };
    }
  } else {
    return {
      'breakEven-success': false,
      'breakEven-warning': false,
    };
  }
}
