import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SmartSpotBrandMarkup } from '../../../core/utilities/constants';
import { SpotPriceQuoteResponse } from '../../models';

@Component({
  selector: 'kbxl-spot-price-modal',
  templateUrl: './spot-price-modal.component.html',
  styleUrls: ['./spot-price-modal.component.scss'],
})
export class SpotPriceModalComponent {
  loading = false;
  spotPriceQuote: SpotPriceQuoteResponse;

  fuel = 0;
  platformDiff = 0;
  i360LowDiff = 0;
  i360PointDiff = 0;
  i360HighDiff = 0;
  smartSpotLabel = SmartSpotBrandMarkup;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.spotPriceQuote = config.data.spotPriceQuote;
    if (config.data.fuel) {
      this.fuel = config.data.fuel;
    }

    if (this.spotPriceQuote && this.spotPriceQuote.spotPrice) {
      this.platformDiff = this.spotPriceQuote.platformDatGuardRate - this.spotPriceQuote.loadshopDatGuardRate;
      this.i360LowDiff = this.spotPriceQuote.spotPrice.estimate.low + this.fuel - this.spotPriceQuote.loadshopDatGuardRate;
      this.i360PointDiff = this.spotPriceQuote.spotPrice.estimate.point + this.fuel - this.spotPriceQuote.loadshopDatGuardRate;
      this.i360HighDiff = this.spotPriceQuote.spotPrice.estimate.high + this.fuel - this.spotPriceQuote.loadshopDatGuardRate;
    }
  }

  close(selectedPrice?: number): void {
    this.ref.close({
      selectedPrice: selectedPrice,
    });
  }
  getVariableImportanceKeys(): any {
    return Object.keys(this.spotPriceQuote.spotPrice.variableImportance);
  }

  getVariableImportanceValue(key: string): string {
    return this.spotPriceQuote.spotPrice.variableImportance[key];
  }
}
