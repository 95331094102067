import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import {
  AddUserSearchModalComponent,
  BookedContainerComponent,
  ButtonToggleComponent,
  DeliveredContainerComponent,
  LoadBoardComponent,
  LoadGridComponent,
  LoadStatusComponent,
  LoadStatusContainerComponent,
  ManageUserSearchModalComponent,
  SearchContainerComponent,
  TopSearchCriteriaComponent,
} from './components';
import { LoadBoardRoutingModule } from './load-board.routing.module';
import { LoadBoardService, SearchCriteriaService } from './services';
import { effects, reducers } from './store';

@NgModule({
  imports: [LoadBoardRoutingModule, SharedModule, EffectsModule.forFeature(effects), StoreModule.forFeature('loadboard', reducers)],
  declarations: [
    LoadBoardComponent,
    LoadGridComponent,
    TopSearchCriteriaComponent,
    ButtonToggleComponent,
    SearchContainerComponent,
    BookedContainerComponent,
    LoadStatusContainerComponent,
    LoadStatusComponent,
    DeliveredContainerComponent,
    ManageUserSearchModalComponent,
    AddUserSearchModalComponent,
  ],
  providers: [LoadBoardService, SearchCriteriaService],
  entryComponents: [ManageUserSearchModalComponent, AddUserSearchModalComponent],
})
export class LoadBoardModule {}
