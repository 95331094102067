import { createSelector } from '@ngrx/store';
import { getLoadBoardFeatureState, LoadBoardState } from '../reducers';
import {
  getBookedQueryHelper,
  getBookedTotalRecords,
  getErrorLoadId,
  getLoaded,
  getLoading,
  getPhoneError,
  getSavingLoadId,
  getTruckError,
  selectors,
} from '../reducers/load-board-booked.reducer';

const getLoadBoardBookedState = createSelector(getLoadBoardFeatureState, (state: LoadBoardState) => state.booked);

export const getLoadBoardBookedLoads = createSelector(getLoadBoardBookedState, selectors.selectAll);
export const getLoadBoardBookedLoading = createSelector(getLoadBoardBookedState, getLoading);
export const getLoadBoardBookedLoaded = createSelector(getLoadBoardBookedState, getLoaded);
export const getLoadBoardBookedSavingLoadId = createSelector(getLoadBoardBookedState, getSavingLoadId);
export const getLoadBoardBookedErrorLoadId = createSelector(getLoadBoardBookedState, getErrorLoadId);
export const getLoadBoardBookedPhoneError = createSelector(getLoadBoardBookedState, getPhoneError);
export const getLoadBoardBookedTruckError = createSelector(getLoadBoardBookedState, getTruckError);
export const getLoadBoardBookedTotalRecords = createSelector(getLoadBoardBookedState, getBookedTotalRecords);
export const getLoadBoardBookedQueryHelper = createSelector(getLoadBoardBookedState, getBookedQueryHelper);
