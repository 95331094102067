<div class="modal-wrapper">
  <kbxl-dynamic-pricing-rule-form
    #rateSeekerForm
    [pricingRule]="pricingRule"
    [percentOptions]="percentOptions"
    [frequencyOptions]="frequencyOptions"
    [intervalOptions]="intervalOptions"
    [showSchedule]="true"
    [validate]="true"
    [marketplaceView]="true"
    [demolineHaul]="load.isBrokeredLoad ? maxLineHaulForBrokeredLoads : load.lineHaulRate"
    [loadPricingSchedule]="loadPricingSchedule"
    [brokeredLoad]="load.isBrokeredLoad"
    [pickUpDate]="pickUpDate"
    [useReservedMargin]="useReservedMargin"
    [actualLineHaul]="load.lineHaulRate"
    (scheduleChanged)="scheduleChanged($event)"
    (formValid)="formValid($event)"
  >
  </kbxl-dynamic-pricing-rule-form>

  <div class="d-flex justify-content-end mt-3">
    <button type="button" class="p-button-outlined p-button-secondary" pButton (click)="cancelRateSeekerChanges()" label="Cancel"></button>
    <button type="button" class="ml-2" [disabled]="!isValid" pButton (click)="applyRateSeekerChanges()" label="Apply"></button>
  </div>
</div>
