import { Action } from '@ngrx/store';

export enum BrowserActionTypes {
  SetIsMobile = '[Browser] SET IS MOBILE',
  SetIsInternetExplorer = '[Browser] SET IS INTERNET EXPLORER',
  SetKeepUsingInternetExplorer = '[Browser] KEEP USING INTERNET EXPLORER',
}

export class BrowserSetIsMobileAction implements Action {
  readonly type = BrowserActionTypes.SetIsMobile;

  constructor(public payload: { isMobile: boolean }) {}
}

export class BrowserSetIsInternetExplorerAction implements Action {
  readonly type = BrowserActionTypes.SetIsInternetExplorer;

  constructor(public payload: { isInternetExplorer: boolean }) {}
}

export class BrowserSetKeepUsingInternetExplorerAction implements Action {
  readonly type = BrowserActionTypes.SetKeepUsingInternetExplorer;

  constructor(public payload: { keepUsingInternetExplorer: boolean }) {}
}

export type BrowserActions = BrowserSetIsMobileAction | BrowserSetIsInternetExplorerAction | BrowserSetKeepUsingInternetExplorerAction;
