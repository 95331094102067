import { HttpHeaders } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { Configuration, Message } from '../../conversation-api';
import { CreateChatEntityData, ExtendedMessage, MessageNotification, UserConversationEntity } from '../models';
import {
  AddIncomingMessagesOnConversationAction,
  ConversationHubErrorAction,
  ConversationHubLoadingAction,
  ConversationHubReadyAction,
  UpdateNotificationCountAction,
} from '../store/actions';
import { ChatState } from '../store/reducers';

export const CONVERSATION_WEBSOCKET_PATH = new InjectionToken<string>('basePath');

@Injectable({
  providedIn: 'root',
})
export class ConversationHubService {
  connectionEstablished = new EventEmitter<boolean>();
  configuration = new Configuration();
  defaultHeaders = new HttpHeaders();

  // List of registered conversations for this user
  registeredConversations: number[] = [];
  // List of registered entities for this user used for notifications
  registeredNotifications: CreateChatEntityData[] = [];

  private hubConnection: signalR.HubConnection;
  private hubEndpoint = 'ChatHub';

  public applicationCode = 'Loadshop';
  public lastActiveTimeInMinutes = 5;

  // if the user doesn't want to receive notifications (or is an admin)
  public notificationsDisabled = false;

  constructor(
    @Optional() configuration: Configuration,
    @Optional() @Inject(CONVERSATION_WEBSOCKET_PATH) private basePath: string,
    private store: Store<ChatState>
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  initialize(): Observable<boolean> {
    if (this.hubConnection) {
      this.store.dispatch(new ConversationHubReadyAction(true));
      return of(true);
    }

    this.store.dispatch(new ConversationHubLoadingAction(true));
    const signalRConnection$ = new Subject<boolean>();

    let url = `${this.basePath}/${this.hubEndpoint}`;
    if (this.basePath.endsWith('/')) {
      url = `${this.basePath}${this.hubEndpoint}`;
    }

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => {
          // the tms_ng infrastructure project stores the access token in session storage, retrieve it from there
          const token = JSON.parse(sessionStorage.getItem('topsapis_authnResult')).access_token;

          return token;
        },
      })
      .withAutomaticReconnect([0, 5000, 20000, 60000, 120000]) // reconnect at 0, 5, 20, 50 and 120 seconds
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.store.dispatch(new ConversationHubReadyAction(true));
        signalRConnection$.next(true);
        signalRConnection$.complete();
      })
      .catch((err: string) => {
        signalRConnection$.error(err);
        signalRConnection$.complete();
        this.store.dispatch(new ConversationHubReadyAction(false));
        this.store.dispatch(new ConversationHubErrorAction(err));
        console.error('Error while establishing connection.');
      });

    this.hubConnection.onclose((error?: Error) => {
      this.store.dispatch(new ConversationHubReadyAction(false));
      if (error) {
        this.store.dispatch(new ConversationHubErrorAction(error.message));
        console.warn(`Hub connection closed.  ${error.message}`);
        console.error(error);
      } else {
        this.store.dispatch(new ConversationHubErrorAction('Hub connection closed.'));
        console.warn('Hub connection closed.');
      }
    });

    this.hubConnection.onreconnected((connectionId?: string) => {
      this.store.dispatch(new ConversationHubReadyAction(true, true));
      if (connectionId) {
        console.log(`Hub connection reconnected with connectionID:  ${connectionId}`);
      } else {
        console.log('Hub connection reconnected.');
      }
    });

    this.hubConnection.onreconnecting((error?: Error) => {
      this.store.dispatch(new ConversationHubLoadingAction(true));
      this.store.dispatch(new ConversationHubReadyAction(false));
      if (error) {
        console.log(`Hub connection reconnecting. Reconnecting due to: ${error.message}`);
        console.error(error);
      } else {
        console.log('Hub connection reconnecting.');
      }
    });

    this.registerOnServerEvents();
    return signalRConnection$.asObservable();
  }

  private registerOnServerEvents(): void {
    this.hubConnection.on('NewMessage', this.onNewMessageReceived.bind(this));
    this.hubConnection.on('MessageNotification', this.onMessageNotificationReceived.bind(this));
    // This is not implemented yet...
    // this.hubConnection.on(`ReadReceipt`, this.onReadReceiptReceived.bind(this));
  }

  private onNewMessageReceived(payload: Message): void {
    this.store.dispatch(new AddIncomingMessagesOnConversationAction(payload.conversationId, [new ExtendedMessage({ ...payload })]));
  }
  private onMessageNotificationReceived(payload: MessageNotification): void {
    if (this.notificationsDisabled) {
      return;
    }
    this.store.dispatch(new UpdateNotificationCountAction([payload]));
  }
  addMessageToConversation(message: Message): Observable<ExtendedMessage> {
    if (!this.checkHubConnection()) {
      return EMPTY;
    }
    const messageAddResult$ = new Subject<Message>();
    // call signalr hub to save message, generate ID and send down to clients
    this.hubConnection
      .invoke('CreateMessage', { ...message, applicationCode: this.applicationCode })
      .then((messageId) => {
        // update message from correlation id
        messageAddResult$.next(new ExtendedMessage({ ...message, applicationCode: this.applicationCode, id: messageId }));
        messageAddResult$.complete();
      })
      .catch((err) => {
        messageAddResult$.error(err);
        messageAddResult$.complete();
        console.log(err);
        throw err;
      });
    return messageAddResult$.asObservable();
  }
  readMessageOnConversation(message: Message): Observable<boolean> {
    if (!this.checkHubConnection()) {
      return EMPTY;
    }
    // populate application code
    // call signalr hub to save read receipt
    this.hubConnection
      .invoke('ReadMessage', { ...message, applicationCode: this.applicationCode })
      .then()
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return of(true);
  }

  logActivityPing(userId: string): Observable<boolean> {
    if (!this.checkHubConnection()) {
      return EMPTY;
    }
    // call signalr hub to log activity
    this.hubConnection
      .invoke('LogActivityPing', {
        applicationCode: this.applicationCode,
        userId: userId,
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return of(true);
  }

  isUserActive(userId: string): Observable<boolean> {
    if (!this.checkHubConnection()) {
      return EMPTY;
    }

    const result = new Subject<boolean>();
    // call signalr hub check if user is active within the specified time diff
    this.hubConnection
      .invoke('IsUserActive', {
        applicationCode: this.applicationCode,
        userId: userId,
        lastActiveTimeInMinutes: this.lastActiveTimeInMinutes,
      })
      .then((isActive: boolean) => {
        result.next(isActive);
        result.complete();
      })
      .catch((err) => {
        result.error(err);
        console.log(err);
        throw err;
      });
    return result.asObservable();
  }

  openConversation(conversationId: number): Observable<boolean> {
    if (!this.checkHubConnection()) {
      return EMPTY;
    }
    // call signalr hub to save message, generate ID and send down to clients
    this.hubConnection
      .invoke('OpenConversation', {
        applicationCode: this.applicationCode,
        conversationId: conversationId,
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return of(true);
  }
  closeConversation(conversationId: number): Observable<boolean> {
    if (!this.checkHubConnection()) {
      return EMPTY;
    }
    // call signalr hub to save message, generate ID and send down to clients
    this.hubConnection
      .invoke('CloseConversation', {
        applicationCode: this.applicationCode,
        conversationId: conversationId,
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return of(true);
  }

  updateGroupSubscriptions(newUserFocusEntites: UserConversationEntity[], oldUserFocusEntites: UserConversationEntity[]): void {
    if (!newUserFocusEntites) {
      newUserFocusEntites = [];
    }
    if (!oldUserFocusEntites) {
      oldUserFocusEntites = [];
    }
    if (!this.checkHubConnection()) {
      return;
    }
    // call signalr hub to unsubscribe from old groups and add new groups
    this.hubConnection
      .invoke('UpdateGroupSubscriptions', {
        applicationCode: this.applicationCode,
        newGroups: newUserFocusEntites.map((x) => x.entityId),
        oldGroups: oldUserFocusEntites.map((x) => x.entityId),
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  private checkHubConnection(): boolean {
    if (!this.hubConnection || this.hubConnection.state !== signalR.HubConnectionState.Connected) {
      console.warn('SignalR hub not connected.');
      return false;
    }
    return true;
  }
}
