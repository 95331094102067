import { Action } from '@ngrx/store';

export enum ChatActiveLoadsActionTypes {
  Select = '[ChatActiveLoads] SELECT',
}

export class ChatActiveLoadsSelectAction implements Action {
  readonly type = ChatActiveLoadsActionTypes.Select;
  constructor(public loadId: string) {}
}

export type ChatActiveLoadsActions = ChatActiveLoadsSelectAction;
