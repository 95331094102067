/**
 * HubMessage.Api
 * Simplified wrapper API for connecting with the hub service.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ConversationStatus = 'ACTIVE' | 'INACTIVE' | 'NEW';

export const ConversationStatus = {
    ACTIVE: 'ACTIVE' as ConversationStatus,
    INACTIVE: 'INACTIVE' as ConversationStatus,
    NEW: 'NEW' as ConversationStatus
};