import { Component, Input, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { getUserProfileEntity, UserState } from 'src/app/user/store';
import { CustomerLoadTypes, User } from '../../models';
import { BaseComponent } from '../base-component/base.component';

@Component({
  selector: 'kbxl-customer-type-icon',
  templateUrl: './customer-type-icon.component.html',
  styleUrls: ['./customer-type-icon.component.scss'],
})
export class CustomerTypeIconComponent extends BaseComponent implements OnDestroy {
  @Input() referenceLoadDisplay: string;
  @Input() customerLoadTypeId: number;
  @Input() isBrokeredLoad: boolean;

  showIcon = false;
  userProfile: User;
  CustomerLoadTypes = CustomerLoadTypes; // Enable use of global enum in template by duplicating it as a property of this component

  constructor(userState: Store<UserState>) {
    super();
    userState.pipe(select(getUserProfileEntity), takeUntil(this.destroyed$)).subscribe((userProfile) => {
      this.userProfile = userProfile;
      if (this.userProfile && this.userProfile.securityAccessRoles) {
        const securityRoles = ['System Admin', 'LS Admin'];
        this.showIcon = this.userProfile.securityAccessRoles.filter((x) => securityRoles.includes(x.accessRoleName)).length > 0;
      }
    });
  }
}
