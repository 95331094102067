export interface SpotPriceQuoteResponse {
  requestId: string;
  spotPrice: SpotPriceQuote;
  platformDatGuardRate: number;
  loadshopDatGuardRate: number;
  loadSmartSpotPriceType: LoadSmartSpotPriceType;
  version: string;
}

export enum LoadSmartSpotPriceType {
  None = 'None',
  SpotPrice = 'SpotPrice',
}
export interface SpotPriceQuote {
  scoreId: number;
  estimate: SpotPriceQuoteEstimate;
  variableImportance: any;
}
export interface SpotPriceQuoteEstimate {
  point: number;
  high: number;
  low: number;
}

export const defaultSpotPriceQuote: SpotPriceQuoteResponse = {
  requestId: null,
  spotPrice: null,
  platformDatGuardRate: 0.0,
  loadshopDatGuardRate: 0.0,
  loadSmartSpotPriceType: LoadSmartSpotPriceType.None,
  version: null,
};

