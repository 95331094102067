import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { LaneHistoryData } from 'src/app/shared/models';
import { ShippingService } from 'src/app/shipping/services';

@Component({
  selector: 'kbxl-shipping-lane-history-display',
  templateUrl: './shipping-lane-history-display.component.html',
  styleUrls: ['./shipping-lane-history-display.component.scss']
})
export class ShippingLaneHistoryDisplayComponent implements OnInit {
  @Input() laneHistory: LaneHistoryData[];
  @Input() loadId: string;
  isLoading = true;

  constructor (public ref: DynamicDialogRef, public config: DynamicDialogConfig, private shippingService: ShippingService) {
    this.laneHistory = config.data.laneHistory;
    this.loadId = config.data.loadId;
  }
  ngOnInit(): void {
    if (this.laneHistory && this.laneHistory.length > 0) {
      this.isLoading = false;
    } else if (this.loadId) {
      this.shippingService.getLaneHistory(this.loadId).subscribe( x => {
        this.laneHistory = x;
        this.isLoading = false;
      });
    }
  }
}
