<kbxl-error-list [errors]="errors" [urnPath]="'urn:root:CustomerLaneGroup:CustomerLanes:' + index"></kbxl-error-list>

<div class="d-flex">
  <div class="d-flex flex-fill flex-column">
    <div class="">
      <div class="kbxl-field-label">
        <label class="control-label">Origin</label>
      </div>
      <div class="kbxl-inputgroup-container">
        <p-checkbox [ngModel]="lane.allOrigins" binary="true" label="All Origins" (onChange)="originChanged($event)"></p-checkbox>
      </div>
      <div>
        <kbxl-address-autocomplete [(place)]="origin" [hidden]="lane.allOrigins"></kbxl-address-autocomplete>
      </div>
      <div class="mt-2" *ngIf="quoteOnly && !allOrigins && origin && origin.city && origin.latitude && origin.longitude">
        <div class="kbxl-field-label">
          <label id="origin-deadhead-miles-label" class="control-label">Max Origin Radius Miles</label>
        </div>
        <div class="p-inputgroup">
          <p-slider [(ngModel)]="lane.origRadius" [min]="0" [max]="200" [step]="10"></p-slider>
          <input
            pInputText
            type="text"
            [(ngModel)]="lane.origRadius"
            class="p-inputgroup-addon sliderInput"
            min="0"
            max="200"
            kbxlInputBound
          />
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-fill mx-3 flex-column">
    <div class="">
      <div class="kbxl-field-label">
        <label class="control-label">Destination</label>
      </div>
      <div class="kbxl-inputgroup-container">
        <p-checkbox
          [ngModel]="lane.allDestinations"
          binary="true"
          label="All Destinations"
          (onChange)="destinationChanged($event)"
        ></p-checkbox>
      </div>

      <div>
        <kbxl-address-autocomplete [(place)]="destination" [hidden]="lane.allDestinations"></kbxl-address-autocomplete>
      </div>
      <div
        class="mt-2"
        *ngIf="quoteOnly && !allDestinations && destination && destination.city && destination.latitude && destination.longitude"
      >
        <div class="kbxl-field-label">
          <label id="destination-deadhead-miles-label" class="control-label">Max Destination Radius Miles</label>
        </div>
        <div class="p-inputgroup">
          <p-slider [(ngModel)]="lane.destRadius" [min]="0" [max]="200" [step]="10"></p-slider>
          <input
            pInputText
            type="text"
            [(ngModel)]="lane.destRadius"
            class="p-inputgroup-addon sliderInput"
            min="0"
            max="200"
            kbxlInputBound
          />
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-fill flex-column">
    <div class="">
      <div class="kbxl-field-label">
        <label class="control-label">Equipment</label>
      </div>
      <div class="kbxl-inputgroup-container">&nbsp;</div>
      <div>
        <kbxl-tree-drop-down
          [treeNodes]="groupedEquipment"
          [(selectedNodes)]="selectedEquipment"
          placeHolder="Select Equipment Type(s)"
        ></kbxl-tree-drop-down>
      </div>
    </div>
  </div>
</div>
