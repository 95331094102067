<div *ngFor="let load of loads; trackBy: trackByFn">
  <p-card [styleClass]="load.selected ? 'load-card active' : 'load-card'" class="ui-pointer" (click)="onRowSelect(load)">
    <p-header>
      <div class="d-flex justify-content-between">
        <span>{{ load.referenceLoadDisplay }}</span>
        <span *ngIf="load.onLoadshop" class="onLoadshop">On Marketplace</span>
        <span class="selected-text">
          <a *ngIf="load.manuallyCreated" [kbxlActionCheck]="'loadshop.ui.shopit.load.manual.copy'" class="edit-link" (click)="copy(load)">
            <span>Copy</span>
          </a>
          <a *ngIf="load.manuallyCreated || load.isExternalLoad" class="edit-link" (click)="edit(load)">
            <span>Edit</span>
          </a>
          {{ load.selected ? 'Close' : 'View' }}
        </span>
      </div>
    </p-header>
    <div class="d-flex flex-column group-item-detail">
      <kbxl-trip-display [loadStops]="load.loadStops"></kbxl-trip-display>
      <div class="seperator"></div>
      <div class="d-flex flex-wrap align-items-center">
        <div class="flex-grow-1">{{ load.equipmentCategoryId || 'Other' }} - {{ load.equipmentTypeDisplay }}</div>
        <div>
          <span class="total-rate">{{ load.totalRate | currency }}</span>
          <fa-icon
            *ngIf="!!load.isDynamicPricingEnabled"
            class="dynamic-pricing"
            title="Dynamic Pricing Enabled"
            [icon]="['fas', 'binoculars']"
            size="md"
          ></fa-icon>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-1" kbxlStopClickPropagation>
        <div>
          <a
            *ngIf="load.onLoadshop"
            class="detail-link"
            (click)="removeClicked(load)"
            pTooltip="Remove load from Marketplace"
            kbxlActionCheck="loadshop.ui.shopit.load.manual.remove"
          >
            <span>Remove</span>
          </a>
        </div>
        <div>
          <a
            class="detail-link"
            (click)="deleteClicked(load)"
            pTooltip="Remove from Marketplace and Delete from Loadshop"
            kbxlActionCheck="loadshop.ui.shopit.load.manual.delete"
          >
            <span>Delete</span>
          </a>
        </div>
      </div>
    </div>
  </p-card>
</div>
