<kbxl-quick-quote
  [states]="states$ | async"
  [groupedEquipment]="equipment$ | async | toTreeNodes: true"
  [loading]="loading$ | async"
  [smartSpotQuote]="quoteValue$ | async"
  [smartSpotErrors]="quoteErrors$ | async"
  [allowManualLoadCreation]="(userProfile$ | async)?.features?.allowManualLoadCreation"
  [commodities]="commodities$ | async"
  [serviceTypes]="serviceTypes$ | async"
  [schedulerConfirmationTypes]="schedulerConfirmationTypes$ | async"
  [showSpotQuoteModal]="showSpotQuoteModal"
  (getQuote)="getQuote($event)"
  (createOrder)="createOrder($event)"
  (clear)="clear()"
></kbxl-quick-quote>
