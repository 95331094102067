import { createSelector } from '@ngrx/store';
import { getSharedFeatureState, SharedState } from '../reducers';
import { selectors } from '../reducers/accessorial-action-item.reducer';

const getAccessorialActionItemState = createSelector(getSharedFeatureState, (state: SharedState) => state.accessorialActionItemState);

export const getAccessorialActionItemFilterCriteriaLoading = createSelector(
  getAccessorialActionItemState,
  (state) => state.loadingFilterCriteria
);
export const getAccessorialActionItemCriteria = createSelector(getAccessorialActionItemState, (state) => state.actionItemCriteria);
export const getAccessorialActionItemStatuses = createSelector(getAccessorialActionItemState, (state) => state.actionItemStatuses);
export const getAccessorialActionItemQueryHelper = createSelector(getAccessorialActionItemState, (state) => state.queryHelper);
export const getAccessorialActionItems = createSelector(getAccessorialActionItemState, (state) => selectors.selectAll(state));
export const getAccessorialActionItemsLoading = createSelector(getAccessorialActionItemState, (state) => state.loading);
export const getAccessorialActionItemsTotalRecords = createSelector(getAccessorialActionItemState, (state) => state.totalRecords);
