<div class="page-container">
  <div class="loading-indicator" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <p-panel *ngIf="accessorial" styleClass=" p-4">
    <p-header>Accessorials</p-header>
    <div class="row" *ngIf="error">
      <div class="col-12 error-container">
        <p>{{ decodeProblemDetails() }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 kbxl-inputgroup-container">
        <div class="kbxl-field-label">
          <label for="name" class="control-label">Accessorial</label>
        </div>
        <div *ngIf="enableUoM; else accessorialDropDown">
          <p-dropdown
            [options]="accessorials | toSelectItem"
            placeholder="Select Accessorial"
            [(ngModel)]="accessorial.accessorialId"
            [styleClass]="'p-dropdown-primary'"
            dropdownIcon="pi pi-caret-down"
          >
          </p-dropdown>
        </div>
        <ng-template #accessorialDropDown>
          <p-dropdown
            [options]="availableAccessorials"
            placeholder="Select Accessorial"
            [styleClass]="'p-dropdown-primary'"
            dropdownIcon="pi pi-caret-down"
            (onChange)="updateSelectedAccessorial($event)"
          >
            <ng-template let-shipperAccessorial pTemplate="item">
              {{ shipperAccessorial.value.accessorialDescription }}
              <div class="text-muted">{{ shipperAccessorial.value.accessorialUnitOfMeasureDescription }}</div>
            </ng-template>
          </p-dropdown>
        </ng-template>
      </div>
      <div *ngIf="!shouldShowAfterInitialHours()" class="col-12 col-lg-6 kbxl-inputgroup-container">
        <div for="description" class="kbxl-field-label">
          <label for="description" class="control-label">Unit of Measure</label>
        </div>
        <div *ngIf="enableUoM; else readOnlyUoM">
          <p-dropdown
            [options]="accessorialUoM | toSelectItem"
            placeholder="Select Unit of Measure"
            [(ngModel)]="accessorial.accessorialUnitOfMeasureId"
            (onChange)="setAccessorialDescription()"
            [styleClass]="'p-dropdown-primary'"
            dropdownIcon="pi pi-caret-down"
          >
          </p-dropdown>
        </div>
        <ng-template #readOnlyUoM>
          {{ accessorial.accessorialUnitOfMeasureDescription }}
        </ng-template>
      </div>
      <div *ngIf="shouldShowAfterInitialHours()" class="col-12 col-lg-3 kbxl-inputgroup-container">
        <div for="description" class="kbxl-field-label">
          <label for="description" class="control-label">Unit of Measure</label>
        </div>
        <div *ngIf="enableUoM; else readOnlyUoM">
          <p-dropdown
            [options]="accessorialUoM | toSelectItem"
            placeholder="Select Unit of Measure"
            [(ngModel)]="accessorial.accessorialUnitOfMeasureId"
            (onChange)="setAccessorialDescription()"
            [styleClass]="'p-dropdown-primary'"
            dropdownIcon="pi pi-caret-down"
          >
          </p-dropdown>
        </div>
        <ng-template #readOnlyUoM>
          {{ accessorial.accessorialUnitOfMeasureDescription }}
        </ng-template>
      </div>
      <div *ngIf="shouldShowAfterInitialHours()" class="col-12 col-lg-3 kbxl-inputgroup-container">
        <div for="description" class="kbxl-field-label">
          <label for="description" class="control-label">After Initial Hours</label>
        </div>
        <p-dropdown
          [options]="afterInitialHoursValues"
          [(ngModel)]="accessorial.afterInitialHours"
          [styleClass]="'p-dropdown-primary'"
          dropdownIcon="pi pi-caret-down"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-2 kbxl-inputgroup-container">
        <div class="kbxl-field-label">
          <label for="name" class="control-label">Variable Rate</label>
        </div>
        <p-checkbox [(ngModel)]="accessorial.variableRate" binary="true" label=""></p-checkbox>
      </div>
      <div class="col-12 col-lg-4 kbxl-inputgroup-container">
        <div [ngClass]="{ invisible: !!accessorial.variableRate || accessorial.accessorialUnitOfMeasureDescription === 'Flat Charge' }">
          <div for="description" class="kbxl-field-label">
            <label for="description" class="control-label">Dollar Amount Per Unit of Measure</label>
          </div>
          <input type="text" [(ngModel)]="accessorial.maxDollarRatePerUOM" pInputText maxlength="10" kbxlCurrencyInput />
        </div>
      </div>
      <div class="col-12 col-lg-6 kbxl-inputgroup-container">
        <div [ngClass]="{ invisible: !!accessorial.variableRate }">
          <div for="description" class="kbxl-field-label">
            <label for="description" class="control-label">Max Rate</label>
          </div>
          <input type="text" [(ngModel)]="accessorial.maxRate" pInputText maxlength="10" kbxlCurrencyInput />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button pButton type="button" (click)="cancelAction()" class="p-button-outlined p-button-secondary" label="Cancel"></button>
      <button pButton label="Save" (click)="saveClick()" class="ml-2" [disabled]="loading"></button>
    </div>
  </p-panel>
</div>
