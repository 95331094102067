import { LoadDocumentDownload } from '../../models/load-document-download';
import { createReducer } from '../../utilities';
import { LoadDocumentActions, LoadDocumentActionTypes } from '../actions/load-document.actions';

export interface LoadDocumentState {
  processedDocument: boolean;
  loaded: boolean;
  loading: boolean;
  documentDownload: LoadDocumentDownload;
}
const initialState: LoadDocumentState = {
  processedDocument: false,
  loaded: false,
  loading: false,
  documentDownload: null,
};

const _LoadDocumentReducer = createReducer(
  initialState,
  function (state: LoadDocumentState = initialState, action: LoadDocumentActions): LoadDocumentState {
    switch (action.type) {
      case LoadDocumentActionTypes.AddDocument:
      case LoadDocumentActionTypes.DeleteDocument: {
        return { ...state, loading: true, loaded: false, processedDocument: false };
      }
      case LoadDocumentActionTypes.AddDocument_Success:
      case LoadDocumentActionTypes.DeleteDocument_Success: {
        return { ...state, loading: false, processedDocument: true };
      }
      case LoadDocumentActionTypes.AddDocumentProcessed_Clear: {
        return { ...state, processedDocument: false };
      }
      case LoadDocumentActionTypes.AddDocument_Failure:
      case LoadDocumentActionTypes.DeleteDocument_Failure: {
        return { ...state, loading: false, loaded: true };
      }
      case LoadDocumentActionTypes.DownloadDocument: {
        return { ...state, loading: true, documentDownload: null };
      }
      case LoadDocumentActionTypes.DownloadDocument_Success: {
        return { ...state, loading: false, documentDownload: action.payload };
      }
      case LoadDocumentActionTypes.DownloadDocument_Clear: {
        return { ...state, loading: false, documentDownload: null };
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function LoadDocumentReducer(state: LoadDocumentState, action: LoadDocumentActions): LoadDocumentState {
  return _LoadDocumentReducer(state, action);
}

export const getLoaded = (state: LoadDocumentState) => state.loaded;
export const getLoading = (state: LoadDocumentState) => state.loading;
export const getDocumentDownload = (state: LoadDocumentState) => state.documentDownload;
export const getProcessedDocument = (state: LoadDocumentState) => state.processedDocument;
