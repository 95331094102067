<p-overlayPanel #rateBreakDownOverlay showTransitionOptions="0ms" hideTransitionOptions="0ms"> 
  <div class="pricing-container">
    <div class="small-spinner-container" *ngIf="loading">
      <p-progressSpinner></p-progressSpinner>
      <div>Loading</div>
    </div>  
    <div class="container" *ngIf="rateBreakDownsData?.length>0">    
        <div class="row" *ngFor="let rate of rateBreakDownsData">
          <div class="col-sm-12">            
          <div class="row justify-content-end pricing-header" >
            <div class="col-sm-3">
              Line Haul
            </div>
            <div class="col-sm-1" *ngIf="showFuel">
              <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
            </div>
            <div class="col-sm-3" *ngIf="showFuel">
              <span *ngIf="rate.fuelProgramType === fuelProgramTypeEnum.BTF">
                <span *ngIf="isEstimatedFSC">*Est</span>
                BTF
              </span>
              <span *ngIf="rate.fuelProgramType === fuelProgramTypeEnum.DOE">DOE</span>
              FSC
            </div>
            <div class="col-sm-1" *ngIf="loadshopFee">
              <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
            </div>
            <div class="col-sm-1" *ngIf="loadshopFee">
              Fee
            </div>
            <div class="col-sm-1">
              <fa-icon [icon]="['fas', 'equals']" size="md"></fa-icon>
            </div>
            <div class="col-sm-4">             
              Total Rate
            </div>    
          </div>    
          <div class="row justify-content-end">
            <div class="col-sm-3">
              {{rate.lineHaulRate | currency }}
            </div>
            <div class="col-sm-1" *ngIf="showFuel">
              <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
            </div>
            <div class="col-sm-3" *ngIf="showFuel">            
              {{rate.fuelRate | currency }}
            </div>
            <div class="col-sm-1" *ngIf="loadshopFee">
              <fa-icon [icon]="['fas', 'plus']" size="md"></fa-icon>
            </div>
            <div class="col-sm-1" *ngIf="loadshopFee">
              {{ loadshopFee | currency }}
            </div>
            <div class="col-sm-1">
              <fa-icon [icon]="['fas', 'equals']" size="md"></fa-icon>
            </div>
            <div class="col-sm-4" [ngClass]="getCalculatedRateFontStyles(rate.totalRate)">
              {{rate.totalRate | currency }}
            </div>
          </div>
        </div>
        </div>    
        <div class="row justify-content-end" kbxlActionCheck action="loadshop.ui.breakeven.view" *ngIf="isBrokeredLoad">
          <div class="col-sm-4">
            <label class="pricing-header"> Break-even </label>
            <div  [ngClass]="getCalculatedRateFontStyles(rateBreakDownsData[0].totalRate)">
              {{ totalQuotePrice | currency }}
            </div>
          </div>
        </div>  
    </div>
   
  </div> 
    
</p-overlayPanel>
