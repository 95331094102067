export interface BrokeredCustomerSettings {
  customerLaneGroupId?: number;
  customerId?: string;
  useRateSeeker: boolean;
  marginPercent?: number;
  floorPercent?: number;
  postedPercent?: number;
  brokeredLoadQuoteMaxExpiration?: number;
  interval?: number;
  reservedPercent?: number;
  startBeforePickUpDateHours?: number;
  stopBeforePickUpDateHours?: number;
  brokeredLoadMaxExpirationHours?: number;
  brokeredLoadMaxExpirationMinutes?: number;
  loadAcceptanceExpiration?: number;
  loadAcceptanceExpirationHours?: number;
  loadAcceptanceExpirationMinutes?: number;
  quoteMaxRejection?: number;
  quoteMaxRejectionHours?: number;
  quoteMaxRejectionMinutes?: number;
  stopAdjustmentAmount?: number;
}

export const defaultBrokeredCustomerSettings: BrokeredCustomerSettings = {
  customerLaneGroupId: null,
  customerId: null,
  useRateSeeker: false,
  marginPercent: null,
  floorPercent: null,
  postedPercent: null,
  brokeredLoadQuoteMaxExpiration: null,
  interval: null,
  reservedPercent: null,
  startBeforePickUpDateHours: null,
  stopBeforePickUpDateHours: null,
  brokeredLoadMaxExpirationHours: null,
  brokeredLoadMaxExpirationMinutes: null,
  loadAcceptanceExpiration: null,
  loadAcceptanceExpirationHours: null,
  loadAcceptanceExpirationMinutes: null,
  stopAdjustmentAmount: null
};
