import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'kbxl-tree-drop-down',
  templateUrl: './tree-drop-down.component.html',
  styleUrls: ['./tree-drop-down.component.scss'],
})
export class TreeDropDownComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() treeNodes: TreeNode[];
  @Output() selectedNodesChange = new EventEmitter<TreeNode[]>();
  @Output() selectedNodeChange = new EventEmitter<TreeNode>();
  @Input() selectedNodes: TreeNode[];
  @Input() selectedNode: TreeNode;
  @Input() placeHolder: string;
  @Input() singleSelection = false;
  @Input() disable = false;
  @Input() isInModal = false;

  show = false;
  private scrollListerAdded = false;

  constructor(private _el: ElementRef, private cdr: ChangeDetectorRef) {
    super();
  }
  ngOnInit(): void {
    // Loadshop uses the page-body div to act as the scroll body instead of the window, so grab this element and listen for scroll
    const pageBody = document.getElementsByClassName('page-body')[0];

    if (pageBody && !this.scrollListerAdded) {
      this.scrollListerAdded = true;
      pageBody.addEventListener('scroll', this.onScrollListener);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.scrollListerAdded) {
      const pageBody = document.getElementsByClassName('page-body')[0];
      pageBody.removeEventListener('scroll', this.onScrollListener);
    }
  }

  hidePlaceholder(): boolean {
    if (this.singleSelection) {
      return this.selectedNode != null;
    } else {
      return this.selectedNodes ? this.selectedNodes.length > 0 : false;
    }
  }

  toggle() {
    if (!this.disable) {
      if (!this.show) {
        this.setWidth();
      }
      this.show = !this.show;
    } else {
      return false;
    }
  }

  setWidth() {
    if (this._el.nativeElement && this._el.nativeElement.children[0]) {
      const children = this._el.nativeElement.children[0].children;
      children[1].style.width = 'auto';
      const inputWidth = children[0].clientWidth;
      const childWidth = children[1].clientWidth;
      if (inputWidth > childWidth) {
        children[1].style.width = inputWidth + 'px';
      }

      // in case the user has scroll or the input button has moved,
      // get the current y position and set the tree top to the bottom of the input
      const bottomPosition = window.pageYOffset + children[0].getBoundingClientRect().bottom;
      if (!this.isInModal && bottomPosition > 0) {
        // set the top of the container
        children[1].style.top = bottomPosition + 'px';
      }
    }
  }

  getSelectedNodesDisplay(): string {
    if (this.singleSelection) {
      if (this.selectedNode) {
        return this.selectedNode.label;
      }
    } else {
      if (this.selectedNodes && this.selectedNodes.length < 3) {
        return this.selectedNodes.map((node) => node.label).join(', ');
      } else if (this.selectedNodes) {
        return this.selectedNodes.filter((x) => x.leaf).length + ' items selected';
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (!this._el.nativeElement.contains(event.target)) {
      // similar checks
      this.show = false;
    } else {
      this.setWidth();
    }
  }

  selectionChanged() {
    if (this.singleSelection) {
      this.selectedNodeChange.emit(this.selectedNode);
      this.show = false;
    } else {
      this.selectedNodesChange.emit(this.selectedNodes);
    }
  }

  private onScrollListener = () => {
    if (this.show) {
      this.toggle();
      this.cdr.detectChanges();
    }
  }
}
