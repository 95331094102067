import { DefaultPageableResult, PageableResult } from 'src/app/shared/models';
import { ShippingLoadView } from 'src/app/shared/models/shipping-load-view';
import { createReducer, PageableQueryHelper } from 'src/app/shared/utilities';
import { ShippingLoadFilter } from '../../../shared/models/shipping-load-filter';
import { ShippingPostedActionTypes } from '../actions';
import { ShippingPostedActions } from '../actions/shipping-posted.actions';

export interface ShippingPostedState {
  loading: boolean;
  postedLoads: PageableResult<ShippingLoadView>;
  queryHelper: PageableQueryHelper;
  filterCriteria: ShippingLoadFilter;
}
const initialState: ShippingPostedState = {
  loading: false,
  postedLoads: DefaultPageableResult.Create<ShippingLoadView>(),
  queryHelper: PageableQueryHelper.default(),
  filterCriteria: null,
};

const _ShippingPostedReducer = createReducer(
  initialState,
  function (state: ShippingPostedState = initialState, action: ShippingPostedActions): ShippingPostedState {
    switch (action.type) {
      case ShippingPostedActionTypes.Load_Shipping_Posted: {
        return { ...state, loading: true, postedLoads: { ...state.postedLoads, data: [] } };
      }
      case ShippingPostedActionTypes.Load_Shipping_Posted_Success: {
        return { ...state, loading: false, postedLoads: action.payload };
      }
      case ShippingPostedActionTypes.Load_Shipping_Posted_Failure: {
        return { ...state, loading: false };
      }

      case ShippingPostedActionTypes.Update_Shipping_Posted_Query: {
        return { ...state, queryHelper: action.payload };
      }

      case ShippingPostedActionTypes.Update_Filter_Criteria: {
        return { ...state, filterCriteria: { ...action.payload } };
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function ShippingPostedReducer(state: ShippingPostedState, action: ShippingPostedActions): ShippingPostedState {
  return _ShippingPostedReducer(state, action);
}
export const getPostedLoading = (state: ShippingPostedState) => state.loading;
export const getPostedLoads = (state: ShippingPostedState) => state.postedLoads;

export const getPostedFilterCriteria = (state: ShippingPostedState) => state.filterCriteria;
