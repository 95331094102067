import { Action } from '@ngrx/store';

export enum LoadshopApplicationActionTypes {
  LoadshopStart = '[Loadshop] LOADSHOP_START',
  LoadshopReset = '[Loadshop] LOADSHOP_RESET',
  User_Entity_Reset = '[Loadshop] USER_ENTITY_RESET',
}

export class LoadshopApplicationStartAction implements Action {
  readonly type = LoadshopApplicationActionTypes.LoadshopStart;
}
export class LoadshopApplicationResetAction implements Action {
  readonly type = LoadshopApplicationActionTypes.LoadshopReset;
}
/*
 ** Action to trigger a soft reset of Loadshop
 **
 ** Currently gets fired when the user update their focus entity
 */
export class LoadshopApplicationUserEntityResetAction implements Action {
  readonly type = LoadshopApplicationActionTypes.User_Entity_Reset;
}

export type LoadshopApplicationActions =
  | LoadshopApplicationStartAction
  | LoadshopApplicationResetAction
  | LoadshopApplicationUserEntityResetAction;
