<kbxl-color-bar></kbxl-color-bar>
<div class="page-container">
  <div class="d-flex flex-column">
    <div class="p-1 position-relative">
      <kbxl-accessorial-action-item-filter
        [loading]="loadingFilter$ | async"
        [actionItemCriteria]="actionItemCriteria$ | async"
        [actionItemStatuses]="actionItemStatuses$ | async"
        [states]="states$ | async"
        [isMobile]="isMobile$ | async"
        (search)="search($event)"
        (clear)="clear()"
      ></kbxl-accessorial-action-item-filter>
    </div>
    <div class="grid-container px-1">
      <kbxl-accessorial-action-item-grid
        [loading]="loading$ | async"
        [actionItemLoads]="actionItems$ | async"
        [user]="user$ | async"
        (selected)="selected($event)"
        (lazyLoad)="onLazyLoadEvent($event)"
        [lazy]="true"
        [totalRecords]="totalRecords$ | async"
        (reload)="refreshData($event)"
      ></kbxl-accessorial-action-item-grid>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
