import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { firstBy } from 'thenby';
import { Message, OldMessagesRequest } from '../../../conversation-api';
import { Guid } from '../../../core/utilities/constants';
import { BaseComponent } from '../../../shared/components';
import { UserModel } from '../../../shared/models';
import { getUserProfileModel, UserState } from '../../../user/store';
import { ChatAttributes, ChatConstants } from '../../constants';
import { ConversationWindowScrollPosition, ExtendedConversation, ExtendedMessage } from '../../models';
import { MessageDatePipe } from '../../pipes';
import { ChatService } from '../../services';
import {
  AddMessageToConversationAction,
  CloseSelectedConversationAction,
  DismissNotificationsAction,
  GetOlderMessagesForConversationAction,
  getSelectedConversation,
  getSelectedConversationLoading,
} from '../../store';
import { ConversationState } from '../../store/reducers/conversation.reducer';

@Component({
  selector: 'kbxl-conversation-window',
  templateUrl: './conversation-window.component.html',
  styleUrls: ['./conversation-window.component.scss'],
})
export class ConversationWindowComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollContainer', { static: true, read: ElementRef }) scrollContainer: ElementRef;
  @ViewChild('chatMessageList', { static: true }) chatMessageList: ElementRef;
  @Output() filterApplied: EventEmitter<boolean> = new EventEmitter<boolean>();
  conversation: ExtendedConversation;
  user: UserModel;

  otherChatUserName: string;
  otherChatUserId: string;
  otherChatEntityName: string;
  otherChatUserChatNotificationsEnabled: boolean;

  loading$: Observable<boolean>;
  private changes: MutationObserver;
  private closed = false;

  private scrollLock: ConversationWindowScrollPosition;

  currentMessageText = '';
  targetUserActive: boolean;
  constructor(
    private store: Store<ConversationState>,
    private userStore: Store<UserState>,
    private messageDatePipe: MessageDatePipe,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private chatService: ChatService
  ) {
    super();

    this.userStore.pipe(select(getUserProfileModel), takeUntil(this.destroyed$)).subscribe((x) => (this.user = x));
  }
  ngAfterViewInit(): void {
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      if (this.scrollLock) {
        const scrollElement = document.querySelector(`div[messageid='${this.scrollLock.messageId}']`);
        if (scrollElement) {
          scrollElement.scrollIntoView();
        }

        // check to see if all the mutations are done. we should get a mutation for and ADD and REMOVE since
        // angular will rebuild the DOM because the list has changed
        if (this.scrollLock.messageCount * 2 <= mutations.length) {
          this.scrollLock = null;
        }
        return;
      }
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    });

    this.changes.observe(this.chatMessageList.nativeElement, {
      childList: true,
    });
  }
  ngOnInit() {
    this.loading$ = this.store.pipe(select(getSelectedConversationLoading));

    this.store.pipe(select(getSelectedConversation), takeUntil(this.destroyed$)).subscribe((selectedConversation) => {
      if (selectedConversation && this.conversation && selectedConversation.id !== this.conversation.id) {
        // if the user changes conversation, wipe the scroll lock
        this.scrollLock = null;
      }

      if (selectedConversation && selectedConversation.messages && selectedConversation.messages.find((x) => !x.isRead)) {
        // dismiss on notifications for this context (might still have notifications for other entities)
        this.store.dispatch(new DismissNotificationsAction(selectedConversation.id));
      }
      // order messages
      selectedConversation = this.updateMessages(selectedConversation);
      this.conversation = selectedConversation;

      this.closed = false;

      this.setOtherChatUserVariables();

      if (selectedConversation) {
        // ensure all the messages are on the screen
        this.changeDetectorRef.detectChanges();
      }
    });
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params: Params) => {
      if (params && params.q && params.q.length > 0 && params.a === 'true') {
        // append loadId to message
        this.currentMessageText = `#${params.q} ${this.currentMessageText}`;
      }
    });
  }

  closeConversation(): void {
    this.store.dispatch(new CloseSelectedConversationAction(true));
    this.closed = true;
  }

  addMessageClick(): void {
    const message = this.currentMessageText;

    if (!message || message.length < 1) {
      return;
    }

    if (this.user && this.user.isImpersonating) {
      console.warn('Unable to chat while impersonating');
      return;
    }

    const attributes = {};
    if (this.user.isShipper) {
      attributes[ChatAttributes.userMessageEntityId] = this.user.primaryCustomerId;
    } else {
      attributes[ChatAttributes.userMessageEntityId] = this.user.carrierScac;
    }

    const newMessage: ExtendedMessage = new ExtendedMessage({
      userId: this.user.identUserId,
      userName: this.user.name,
      conversationId: this.conversation.id,
      value: message,
      isRead: true, // api will mark as read
      createDate: new Date(Date.now()),
      correlationId: Guid.newGuid(),
      isUserMessage: true,
      attributes: attributes,
    });
    this.store.dispatch(new AddMessageToConversationAction(newMessage));
    // reset text area
    this.currentMessageText = '';
  }

  private setOtherChatUserVariables(): void {
    if (!this.conversation) {
      return;
    }
    const conversationUserIds = this.conversation.getConversationUserIds();

    if (conversationUserIds && conversationUserIds.length > 0) {
      this.otherChatUserId = conversationUserIds.filter((x) => x !== this.user.identUserId)[0];
      // get chat notification preferences

      if (this.otherChatUserId && this.otherChatUserId.length > 0) {
        this.chatService.getUserChatNotificationPreferences(this.otherChatUserId).subscribe((x) => {
          this.otherChatUserChatNotificationsEnabled = x;
        });
      }
    }
    if (this.user.isShipper) {
      this.otherChatUserName = this.conversation.getCarrierUserFullName();
      this.otherChatEntityName = this.conversation.getCarrierName();
    } else {
      this.otherChatUserName = this.conversation.getShipperUserFullName();
      this.otherChatEntityName = this.conversation.getShipperName();
    }
  }

  getMoreMessages(): void {
    // get message history if its a new selected conversation
    const request: OldMessagesRequest = {
      conversationId: this.conversation.id,
      nbrMessages: ChatConstants.nbrOfMessagesToRetrieve,
      startDate: this.conversation.messages[0].createDate, // this should be the earliest message
    };

    // lock the scrollbar when the new messages come in
    this.scrollLock = {
      messageId: this.conversation.messages[0].id,
      messageCount: this.conversation.messages.length + request.nbrMessages,
    };

    this.store.dispatch(new GetOlderMessagesForConversationAction(request));
  }
  showDateBoundary(index: number): boolean {
    if (index === 0) {
      return true;
    }
    // check the previous message
    const prev = this.conversation.messages[index - 1];
    const current = this.conversation.messages[index];

    return this.messageDatePipe.convertToNiceDate(prev.createDate) !== this.messageDatePipe.convertToNiceDate(current.createDate);
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && (event.ctrlKey || event.altKey)) {
      // user did an ALT+ ENTER or CTRL + ENTER
      this.currentMessageText += '\n';
    } else if (event.key === 'Enter' && !event.ctrlKey && !event.altKey) {
      this.addMessageClick();
      this.currentMessageText = '';
      event.preventDefault();
    }
  }

  viewLoad(message: ExtendedMessage): void {
    this.router.navigate([], {
      queryParams: {
        q: message.loadId, // query
        a: null, // attach to message
        s: true, // select load if the filter brings the list result to 1
      },
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });

    this.filterApplied.emit(true);
  }
  private updateMessages(selectedConversation: ExtendedConversation): ExtendedConversation {
    // order messages
    if (selectedConversation && selectedConversation.messages) {
      selectedConversation.messages.forEach((x) => {
        x.isUserMessage = this.isUserComment(x);
        x.isRead = true;

        if (x.value && x.value.indexOf('#') > -1) {
          x.hasLoadId = true;
          const start = x.value.indexOf('#');
          let end = x.value.indexOf(' ', start);
          if (end < 0) {
            // there is no space, i.e. loadId is the last value in the message
            end = x.value.length;
          }
          x.loadId = x.value.substring(start + 1, end);
        }

        if (x.attributes[ChatAttributes.userMessageEntityId] && x.attributes[ChatAttributes.userMessageEntityId].length > 0) {
          const entityId = x.attributes[ChatAttributes.userMessageEntityId];

          if (selectedConversation.getCarrierId().toLocaleLowerCase() === entityId.toLocaleLowerCase()) {
            x.entityName = selectedConversation.getCarrierName();
          } else if (selectedConversation.getShipperId().toLocaleLowerCase() === entityId.toLocaleLowerCase()) {
            x.entityName = selectedConversation.getShipperName();
          }
        }
      });
      // order by created
      selectedConversation.messages = selectedConversation.messages.slice().sort(firstBy((x) => x.createDate));
    }

    return selectedConversation;
  }

  private isUserComment(comment: Message) {
    if (comment && this.user) {
      if (comment.userId === this.user.identUserId) {
        return true;
      }
    }
    return false;
  }
}
