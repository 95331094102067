<div class="d-flex flex-column">
  <div class="row" *ngIf="error">
    <div class="col-12 error-container">
      <p>{{ error }}</p>
    </div>
  </div>
  <div class="kbxl-inputgroup-container" *ngIf="documentTypes && documentTypes.length > 0 && !basicFileSelect">
    <div class="kbxl-field-label">
      <label class="control-label">Document Type</label>
    </div>
    <div class="p-inputgroup">
      <p-dropdown
        [options]="documentTypes"
        optionLabel="description"
        placeholder="Document Type"
        [(ngModel)]="selectedDocumentType"
        (onChange)="documentTypeChange()"
        [styleClass]="'p-dropdown-primary'"
        dropdownIcon="pi pi-caret-down"
      >
      </p-dropdown>
    </div>
  </div>
  <div class="kbxl-inputgroup-container" [ngClass]="{ 'd-none': !showComment }" *ngIf="!basicFileSelect">
    <div class="kbxl-field-label">
      <label class="control-label">Document Description</label>
    </div>
    <div class="p-inputgroup">
      <textarea [rows]="3" [cols]="300" pInputTextarea [(ngModel)]="comment" (ngModelChange)="commentChanged($event)"></textarea>
    </div>
  </div>
  <div class="text-right mb-4">
    <button
      pButton
      icon="pi pi-paperclip"
      class="p-button-teal"
      label="Attach Document"
      (click)="file.click()"
      [disabled]="uploadDisabled"
      type="button"
    ></button>
    <input #file type="file" accept=".jpg,.png,.gif,.jpeg,.pdf,.doc,.docx" class="d-none" (change)="uploadDocument($event)" value="init" />
  </div>
</div>
