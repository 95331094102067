import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { delay, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { GoogleMapService } from 'src/app/core/services';
import { LoadshopApplicationActionTypes, LoadshopApplicationResetAction } from 'src/app/shared/store';
import { firstBy } from 'thenby';
import {
  CoreState,
  getBrowserIsMobile,
  getCarrierAuthorizedShippers,
  getEquipment,
  getServiceTypes,
  getStates,
  getUserFavorites
} from '../../../core/store';
import { BaseComponent, CarrierIneligibleWarningComponent } from '../../../shared/components';
import {
  AllUserFavoriteTypes,
  AuditType,
  Customer,
  Equipment,
  LoadDetail,
  LoadView,
  Search,
  ServiceType,
  State,
  UserFavorite,
  UserLane,
  UserModel
} from '../../../shared/models';
import { convertObjectToParams } from '../../../shared/utilities';
import {
  getUserLaneEntities,
  getUserProfileModel,
  UserLaneAddAction,
  UserLaneLoadAction,
  UserLaneUpdateAction,
  UserState
} from '../../../user/store';
import { SearchCriteriaService } from '../../services';
import {
  getLoadBoardDashboardCurrentSearch,
  getLoadBoardDashboardLoading,
  getLoadBoardDashboardLoads,
  getLoadBoardSelectedLoad,
  LoadBoardDashboardCancelLoadPollingAction,
  LoadBoardDashboardSearchAddAction,
  LoadBoardDashboardSearchClearAction,
  LoadBoardDashboardStartLoadPollingAction,
  LoadBoardState
} from '../../store';

@Component({
  templateUrl: './search-container.component.html',
  styleUrls: ['./search-container.component.css'],
  providers: [DialogService],
})
export class SearchContainerComponent extends BaseComponent implements OnInit, OnDestroy {
  loads$: Observable<LoadView[]>;
  filteredLoads$: Observable<LoadView[]>;
  equipment$: Observable<Equipment[]>;
  states$: Observable<State[]>;
  loading$: Observable<boolean>;
  loadDetail$: Observable<LoadDetail>;
  recent$: Observable<Search[]>;
  criteria$: Observable<Search>;
  lanes$: Observable<UserLane[]>;
  savedSearches$: Observable<UserFavorite[]>;
  isMobile$: Observable<boolean>;
  serviceTypes$: Observable<ServiceType[]>;
  totalRecords$: Observable<number>;
  user$: Observable<UserModel>;
  shippers$: Observable<Customer[]>;
  isDisabled = false;
  states: State[];
  radius = 3956;
  pidiv180 = 0.017453293;
  user: UserModel;
  private processingFavoriteLoads: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private loadboardStore: Store<LoadBoardState>,
    private store: Store<CoreState>,
    private userStore: Store<UserState>,
    private router: Router,
    private route: ActivatedRoute,
    private googleService: GoogleMapService,
    private actions$: Actions,
    private searchCriteriaService: SearchCriteriaService,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit() {
    /**
     *  https://kbxltrans.visualstudio.com/Suite/_workitems/edit/44681
     *  Stop filtering loads on init.  When a user comes to the marketplace
     *  tab, clear all filters and let the list refresh as normal
     */
    this.loadboardStore.dispatch(new LoadBoardDashboardSearchClearAction());

    this.loads$ = this.loadboardStore.pipe(
      select(getLoadBoardDashboardLoads),
      map((_) => this.deepClone(_))
    );

    // criteria comes from store, anything pass via query will route to store
    this.criteria$ = this.loadboardStore.pipe(select(getLoadBoardDashboardCurrentSearch));
    this.lanes$ = this.userStore.pipe(select(getUserLaneEntities));
    this.savedSearches$ = this.store.pipe(select(getUserFavorites, AllUserFavoriteTypes.MarketplaceSavedSearch));
    this.lanes$ = this.userStore.pipe(select(getUserLaneEntities));
    // TODO figure out if we need ability for deeplinking within the search page, if so we need to uncomment below,
    // and fix how the search obj gets populated to ensure all fields are populated the same via search / favorites
    // this.route.queryParams.pipe(
    //   map(x => convertParamsToObject<Search>(x, Search)),
    //   takeUntil(this.destroyed$)
    // ).subscribe(x => {
    //   if ('at' in x) {
    //     return;
    //   }
    //   // since we write out the search in the URL, check to make sure its different and not default from clearing search
    //   if (x && JSON.stringify(x) !== JSON.stringify(new Search())) {
    //     if (!this._currentSearch || JSON.stringify(x) !== JSON.stringify(this._currentSearch)) {
    //        this.loadboardStore.dispatch(new LoadBoardDashboardSearchAddAction(x));
    //     }
    //   }
    // });
    this.states$ = this.store.pipe(
      select(getStates),
      tap((x) => (this.states = x))
    );

    const filterLoads$ = combineLatest([this.loads$, this.criteria$, this.lanes$]).pipe(
      select(([loads, search, lanes]) => ({
        loads: loads.filter((x) => this.filterLoad(x, search)),
        search,
        lanes,
      }))
    );

    this.totalRecords$ = filterLoads$.pipe(map((loads) => loads?.loads.length ?? 0));

    this.filteredLoads$ = combineLatest([this.states$, filterLoads$]).pipe(
      mergeMap(([states, loads]) => {
        if (!states || states.length === 0) {
          // the startup data call hasn't completed yet;
          return EMPTY;
        }
        return this.setIsFavoriteLoad(loads.loads, loads.search, loads.lanes);
      })
    );

    this.equipment$ = this.store.pipe(select(getEquipment));

    this.loadDetail$ = this.store.pipe(select(getLoadBoardSelectedLoad));
    this.loading$ = combineLatest([
      this.store.pipe(select(getLoadBoardDashboardLoading)),
      this.processingFavoriteLoads.asObservable().pipe(delay(0)),
    ]).pipe(map((args) => args[0] || args[1]));
    this.isMobile$ = this.store.pipe(select(getBrowserIsMobile));
    this.serviceTypes$ = this.store.pipe(select(getServiceTypes));

    this.actions$
      .pipe(takeUntil(this.destroyed$), ofType<LoadshopApplicationResetAction>(LoadshopApplicationActionTypes.LoadshopReset))
      .subscribe(() => {
        this.loadboardStore.dispatch(new LoadBoardDashboardCancelLoadPollingAction());
        this.loadboardStore.dispatch(new LoadBoardDashboardStartLoadPollingAction(null));
      });

    this.user$ = this.userStore.pipe(select(getUserProfileModel));
    this.shippers$ = this.store.pipe(select(getCarrierAuthorizedShippers));
    this.user$.pipe(takeUntil(this.destroyed$)).subscribe((val: UserModel) => {
      this.user = val;
      if (this.user && (!this.user.isCarrier || this.user.isBookingEligible)) {
        this.loadboardStore.dispatch(new UserLaneLoadAction());
        this.loadboardStore.dispatch(new LoadBoardDashboardStartLoadPollingAction(null));
      }
    });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.loadboardStore.dispatch(new LoadBoardDashboardCancelLoadPollingAction());
  }

  search(search: Search, refreshLoads = false) {
    // save the search in the store so regardless of what page the user ends up on their search is saved
    this.loadboardStore.dispatch(new LoadBoardDashboardSearchAddAction(search));

    // if the show all loads is click or loads from a saved search, then force the loads to refresh
    if (search && search.showAllLoads) {
      refreshLoads = true;
    }

    if (refreshLoads) {
      this.loadboardStore.dispatch(new LoadBoardDashboardCancelLoadPollingAction());
      this.loadboardStore.dispatch(
        new LoadBoardDashboardStartLoadPollingAction({
          showAllLoads: search.showAllLoads,
        })
      );
    }

    this.router.navigate(['/loads/search/'], {
      queryParams: convertObjectToParams(search),
    });
  }

  refreshLoads(search: Search) {
    this.search(search, true);
  }

  add(lane: UserLane) {
    this.userStore.dispatch(new UserLaneAddAction(lane));
  }

  update(lane: UserLane) {
    this.userStore.dispatch(new UserLaneUpdateAction(lane));
  }

  selected(load: LoadView) {
    /**
     * Because the detail container is now routeable and loaded via URL, we have to pass in query params
     * for all views that we want to track.
     */
    if (!this.isDisabled) {
      if (this.user.isCarrier && !this.user.isBookingEligible) {
        const ref = this.dialogService.open(CarrierIneligibleWarningComponent, {
          closeOnEscape: true,
          dismissableMask: true,
          showHeader: true,
          closable: true,
        });
      } else {
        this.router.navigate(['detail', load.loadId], {
          relativeTo: this.route,
          queryParams: {
            at: load.isFavorite ? AuditType.MarketplaceFavoriteView : AuditType.MarketplaceView,
          },
        });
      }
    }
  }

  clear() {
    // save the search in the store so regardless of what page the user ends up on their search is saved
    this.loadboardStore.dispatch(new LoadBoardDashboardSearchClearAction());
    this.router.navigate(['/loads/search/']);
  }

  setDisabled(isDisabled) {
    this.isDisabled = isDisabled;
  }

  private setIsFavoriteLoad(loads: LoadView[], advanceSearch: Search, userLanes: UserLane[]): Observable<LoadView[]> {
    if (!loads || loads.length === 0) {
      this.processingFavoriteLoads.next(false);
      return of([]);
    }
    this.processingFavoriteLoads.next(true);
    for (let index = 0; index < userLanes.length; index++) {
      const userLane = userLanes[index];
      const origDest = this.searchCriteriaService.setOriginDestination(userLane, this.states);
      // since we have lat / long on the lane, populate it so we don't need to call the Google Geocode service
      const origin = origDest[0];
      if (origin) {
        origin.latitude = userLane.origLat;
        origin.longitude = userLane.origLng;
      }

      const dest = origDest[1];
      if (dest) {
        dest.latitude = userLane.destLat;
        dest.longitude = userLane.destLng;
      }

      // convert lane to search obj
      const searchCrit: Search = {
        showAllLoads: false,
        origDH: userLane.origDH,
        destDH: userLane.destDH,
      };
      if (userLane.equipmentIds && userLane.equipmentIds.length > 0) {
        searchCrit.equipmentType = JSON.stringify(userLane.equipmentIds);
      }
      // map the orig / dest to their geocode points for radius using gmaps
      const search = this.searchCriteriaService.setOriginDestinationOnSearchNoPromise(
        searchCrit,
        origin,
        dest,
        userLane.origDH,
        userLane.destDH,
        this.states
      );
      for (let j = 0; j < loads.length; j++) {
        const load = loads[j];
        // load is already marked as being a favorite, skip until future requirement is to list all this favorites that matched :)
        if (!load.isFavorite) {
          load.isFavorite = this.filterLoad(load, search);
        }
      }
    }
    let sorted: LoadView[];
    if (
      advanceSearch &&
      ((advanceSearch.origCountry && advanceSearch.origCountry.length > 0) ||
        (advanceSearch.destCountry && advanceSearch.destCountry.length > 0) ||
        (advanceSearch.quickSearch && advanceSearch.quickSearch.length > 0) ||
        (advanceSearch.serviceTypes && advanceSearch.serviceTypes.length > 0) ||
        (advanceSearch.equipmentType && advanceSearch.equipmentType.length > 0))
    ) {
      // IF a search was used, sort originLateDtTm unless the user sorted on the grid, then this is ignored
      sorted = loads.sort(firstBy('originLateDtTm'));
    } else {
      // on first load or if no search is defined then sort favorites to the top, followed by originLateDtTm
      sorted = loads.sort(firstBy('isFavorite', 'desc').thenBy('originLateDtTm'));
    }

    // push the output to the observable
    this.processingFavoriteLoads.next(false);

    return of(sorted);
  }

  private filterLoad(load: LoadView, search: Search) {
    load.distanceFrom = 0;

    if (
      search.quickSearch &&
      !(
        (load.referenceLoadDisplay && load.referenceLoadDisplay.toLowerCase().includes(search.quickSearch.toLowerCase())) ||
        (load.referenceLoadId && load.referenceLoadId.toLowerCase().includes(search.quickSearch.toLowerCase())) ||
        (load.billingLoadDisplay && load.billingLoadDisplay.toLowerCase().includes(search.quickSearch.toLowerCase())) ||
        load.loadId.toLowerCase() === search.quickSearch.toLowerCase() ||
        (load.equipmentCategoryDesc && load.equipmentCategoryDesc.toLowerCase().includes(search.quickSearch.toLowerCase())) ||
        (load.equipmentCategoryId && load.equipmentCategoryId.toLowerCase().includes(search.quickSearch.toLowerCase())) ||
        (load.equipmentId && load.equipmentId.toLowerCase().includes(search.quickSearch.toLowerCase())) ||
        (load.equipmentType && load.equipmentType.toLowerCase().includes(search.quickSearch.toLowerCase())) ||
        (load.originCity && load.originCity.toLowerCase().includes(search.quickSearch.toLowerCase())) ||
        (load.destCity && load.destCity.toLowerCase().includes(search.quickSearch.toLowerCase())) ||
        (load.originState && load.originState.toLowerCase().includes(search.quickSearch.toLowerCase())) ||
        (load.destState && load.destState.includes(search.quickSearch.toLowerCase())) ||
        (load.destCity + ', ' + load.destState).toLowerCase().includes(search.quickSearch.toLowerCase()) ||
        (load.originCity + ', ' + load.originState).toLowerCase().includes(search.quickSearch.toLowerCase()) ||
        (load.scac && load.scac.toLowerCase().includes(search.quickSearch.toLowerCase())) ||
        (load.customerName && load.customerName.toLowerCase().includes(search.quickSearch.toLowerCase()))
      )
    ) {
      return false;
    }

    if (search.shipperIds && search.shipperIds.length > 0) {
      if (!search.shipperIds.some((_) => _ === load.customerId)) {
        return false;
      }
    }

    if (search.origDateStart) {
      const searchStartDt = moment(search.origDateStart);
      const searchEndDt = search.origDateEnd ? moment(search.origDateEnd).add(1, 'days') : moment(search.origDateStart).add(1, 'days');
      const endDt = moment(load.originLateDtTm);
      const startDt = load.originEarlyDtTm ? moment(load.originEarlyDtTm) : endDt;
      if (!startDt.isBetween(searchStartDt, searchEndDt) || !endDt.isBetween(searchStartDt, searchEndDt)) {
        return false;
      }
    }

    if (search.destDateStart) {
      const searchStartDt = moment(search.destDateStart);
      const searchEndDt = search.destDateEnd ? moment(search.destDateEnd).add(1, 'days') : moment(search.destDateStart).add(1, 'days');
      const endDt = moment(load.destLateDtTm);
      const startDt = load.destEarlyDtTm ? moment(load.destEarlyDtTm) : endDt;
      if (!startDt.isBetween(searchStartDt, searchEndDt) || !endDt.isBetween(searchStartDt, searchEndDt)) {
        return false;
      }
    }

    if (search.equipmentType) {
      const equipment = JSON.parse(search.equipmentType) as string[];
      if (!equipment.find((x) => x === load.equipmentId)) {
        return false;
      }
    }

    if (search.destCountry && !search.destLat && !search.destLng) {
      if (search.destCountry !== load.destCountry) {
        return false;
      }
    }

    if (search.origCountry && !search.origLat && !search.origLng) {
      if (search.origCountry !== load.originCountry) {
        return false;
      }
    }

    if (search.destState && !search.destLat && !search.destLng) {
      if (search.destState !== load.destState) {
        return false;
      }
    }

    if (search.origState && !search.origLat && !search.origLng) {
      if (search.origState !== load.originState) {
        return false;
      }
    }

    if (search.destLat && search.destLng) {
      const dist = this.googleService.calculateDistance(load.destLat, load.destLng, search.destLat, search.destLng);
      load.distanceFrom += dist;
      if (dist > search.destDH) {
        return false;
      }
    }

    if (search.origLat && search.origLng) {
      const dist = this.googleService.calculateDistance(load.originLat, load.originLng, search.origLat, search.origLng);
      load.distanceFrom += dist;
      if (dist > search.origDH) {
        return false;
      }
    }

    if (search.serviceTypes && search.serviceTypes.length > 0) {
      if (!load.serviceTypeIds) {
        return false;
      }
      const intersect = load.serviceTypeIds.filter((x) => -1 !== search.serviceTypes.indexOf(x));
      if (!intersect || intersect.length === 0) {
        return false;
      }
    }
    return true;
  }
}
