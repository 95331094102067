import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { CarrierProfileReducer, CarrierProfileState } from './carrier-profile.reducer';
import { LaneManagementState, laneManagementReducer } from './lane-management.reducer';
import { ShipperAccessorialState, shipperAccessorialReducer } from './shipper-accessorials.reducer';
import { ShipperProfileReducer, ShipperProfileState } from './shipper-profile.reducer';
import { UserAdminReducer, UserAdminState } from './user-admin.reducer';
import { UserCommunicationReducer, UserCommunicationState } from './user-communication.reducer';

export interface AdminState {
  shipperProfile: ShipperProfileState;
  userAdmin: UserAdminState;
  carrierProfile: CarrierProfileState;
  userComunnication: UserCommunicationState;
  shipperAccessorial: ShipperAccessorialState;
  laneManagement: LaneManagementState;
}

export const reducers: ActionReducerMap<AdminState> = {
  shipperProfile: ShipperProfileReducer,
  userAdmin: UserAdminReducer,
  carrierProfile: CarrierProfileReducer,
  userComunnication: UserCommunicationReducer,
  shipperAccessorial: shipperAccessorialReducer,
  laneManagement: laneManagementReducer,
};

export const getAdminFeatureState = createFeatureSelector<AdminState>('admin');
