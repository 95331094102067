<div class="progress-indicator" *ngIf="loading">
  <div class="progressspinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div class="d-flex flex-column">
  <div class="alert alert-dark">Changing the selection below will change the Company/Scac that you are viewing.</div>
  <div class="kbxl-inputgroup-container">
    <div for="CompanyScac" class="kbxl-field-label">
      <label for="CompanyScac" class="control-label">Company/Scac</label>
    </div>
    <kbxl-user-focus-entity-selector-container (updated)="close()"> </kbxl-user-focus-entity-selector-container>
  </div>
  <div class="d-flex mb-2">
    <button pButton type="button" class="p-button-outlined p-button-secondary ml-auto" label="Close" (click)="close()"></button>
  </div>
</div>
