import { createSelector } from '@ngrx/store';
import { CoreState } from '../reducers';
import { LoadStatusState, getLoading, getSaving } from '../reducers/load-status.reducer';
import { LoadStatusDetail } from 'src/app/shared/models/load-status-detail';
import { LoadStatusTypes, T2GLoadStatus, LoadStatusTransaction, StopEventTypes } from 'src/app/shared/models';

export const getLoadStatusState = (state: CoreState) => state.loadStatus;
export const getLoadStatusLoading = createSelector(getLoadStatusState, (state: LoadStatusState) => getLoading(state));
export const getLoadStatusSaving = createSelector(getLoadStatusState, (state: LoadStatusState) => getSaving(state));
export const getLoadStatusErrors = createSelector(getLoadStatusState, (state: LoadStatusState) => (state ? state.problemDetails : null));

function getStatus(status: T2GLoadStatus) {
  const codeId = (status ? status.codeId : null) || '';
  switch (codeId.toUpperCase()) {
    case 'X3':
    case 'X1':
      return LoadStatusTypes.Arrived;
    case 'AF':
    case 'CD':
      return LoadStatusTypes.Departed;
    case 'D1':
      return LoadStatusTypes.Delivered;
    case 'X6':
    case 'ZZ':
      return LoadStatusTypes.InTransit;
    default:
      return null;
  }
}

function mergeStatuses(loading: boolean, tops2GoStatus: T2GLoadStatus, loadshopStatus: LoadStatusTransaction) {
  // Estimates are never returned from tops2Go, so we'll never stop processing unless we skip over estimates
  // and appointments and set processingUpdates to false to prevent retriggering the loading of updates forever.
  const loadshopStatusIsEstimateOrAppt =
    loadshopStatus &&
    (loadshopStatus.eventType === StopEventTypes[StopEventTypes.Estimate] ||
      loadshopStatus.eventType === StopEventTypes[StopEventTypes.Appointment]);
  const processing =
    loadshopStatus && !loadshopStatusIsEstimateOrAppt && (!tops2GoStatus || tops2GoStatus.lastChgDtTm < loadshopStatus.messageTime);
  const noStatus = !tops2GoStatus;
  return {
    processingUpdates: processing,
    stopNumber: tops2GoStatus ? tops2GoStatus.stopNbr : '',
    description: loading
      ? 'Loading Status'
      : processing
      ? 'Processing Status Updates'
      : noStatus
      ? 'No Status Available'
      : tops2GoStatus.descriptionLong,
    status: getStatus(tops2GoStatus),
    dateLabel: tops2GoStatus && !processing && !loading ? tops2GoStatus.dateLabel : null,
    locationLabel: tops2GoStatus && !processing && !loading ? tops2GoStatus.locationLabel : null,
  } as LoadStatusDetail;
}

const getTops2GoStatus = createSelector(getLoadStatusState, (state) => state.tops2GoStatus);
const getLoashopStatus = createSelector(getLoadStatusState, (state) => state.loadshopStatus);
export const getLoadStatusDetail = createSelector(
  getLoadStatusLoading,
  getTops2GoStatus,
  getLoashopStatus,
  (loading, tops2GoStatus, loadshopStatus) => mergeStatuses(loading, tops2GoStatus, loadshopStatus)
);
