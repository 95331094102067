import { NotificationTypes } from './send-link-request';

export interface LoadShareLink {
  shareLink: string;
  notificationType: NotificationTypes;
}

export const defaultLoadShareLink: LoadShareLink = {
  shareLink: null,
  notificationType: null,
};
