<div kbxlFeature="chat" class="wrapper">
  <div class="progress-indicator" *ngIf="!(hubReady$ | async)">
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <div class="d-flex flex-column">
    <kbxl-color-bar></kbxl-color-bar>
    <div class="px-1 px-md-4 mt-2" *ngIf="otherUserContextNotifications?.length > 0">
      <!-- other context notifications -->
      <div class="alert alert-dark alert-dismissible fade show">
        <div class="d-flex align-items-center">
          <strong> You have unread messages on other contexts:</strong>
          <button type="button" class="ml-auto close" data-dismiss="alert" aria-label="Close" (click)="dismissAllNotifications()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ul class="flex-wrap list-group list-group list-group-horizontal-md mt-2">
          <li
            (click)="openSwitchEntityModal()"
            class="list-group-item d-flex justify-content-between align-items-center"
            *ngFor="let notification of otherUserContextNotifications"
          >
            <span class="mr-1"> {{ notification.contextEntityName }}</span>
            <span class="badge badge-danger badge-pill ml-auto">{{ notification.notificationCount }}</span>
            <div></div>
          </li>
        </ul>
      </div>
    </div>
    <div class="d-flex px-1 px-md-4 mt-2 chat-wrapper" *ngIf="isChatEnabled && !isMobile; else mobileLayout">
      <kbxl-conversation-list
        [conversations]="conversations$ | async"
        [user]="user"
        [loading]="loading$ | async"
        [loaded]="conversationFirstLoaded$ | async"
        [urlConversationId]="urlConversationId"
        (conversationSelected)="conversationSelected($event)"
      ></kbxl-conversation-list>
      <div class="ml-2 flex-grow-1">
        <kbxl-conversation-window></kbxl-conversation-window>
      </div>
      <div class="">
        <!-- load detail / action pane -->
        <kbxl-active-loads-list></kbxl-active-loads-list>
      </div>
    </div>
    <ng-template #mobileLayout class="mt-2">
      <div *ngIf="isChatEnabled">
        <p-sidebar [(visible)]="displaySidebar" [styleClass]="'chat-side-bar'">
          <kbxl-conversation-list
            [conversations]="conversations$ | async"
            [isMobile]="true"
            [user]="user"
            [loading]="loading$ | async"
            [loaded]="conversationFirstLoaded$ | async"
            [urlConversationId]="urlConversationId"
            (conversationSelected)="conversationSelected($event)"
            (closeSidebar)="displaySidebar = false"
            class="pt-3"
          ></kbxl-conversation-list>
        </p-sidebar>
        <div class="d-flex flex-column">
          <div class="m-2">
            <button
              id="view-conversation-button"
              pButton
              icon="pi pi-bars"
              type="button"
              class="p-button-outlined p-button-secondary"
              label="View Conversations"
              (click)="displaySidebar = true"
            ></button>
            <span id="current-notification-count" class="badge badge-danger badge-pill ml-auto" *ngIf="currentUserContextNotifications">{{
              currentUserContextNotifications
            }}</span>
          </div>
        </div>
        <div class="mx-2">
          <p-tabView [(activeIndex)]="mobileSelectedTabIndex">
            <p-tabPanel header="Conversation">
              <kbxl-conversation-window (filterApplied)="mobileSelectedTabIndex = 1"></kbxl-conversation-window>
            </p-tabPanel>
            <p-tabPanel header="View Active Loads">
              <kbxl-active-loads-list [active]="mobileSelectedTabIndex === 1"></kbxl-active-loads-list>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    </ng-template>

    <div class="mx-2 mx-md-4 py-4">
      <small class="text-italics">All conversations are recorded for safety and security of our users and their businesses</small>
    </div>
  </div>
</div>
<!-- Outlet for sidebar -->
<router-outlet></router-outlet>
