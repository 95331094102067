<div class="page-container">
  <div class="d-flex flex-column">
    <div class="py-lg-2 px-md-1 px-xl-4 position-relative">
      <kbxl-shipping-search-criteria
        [criteria]="pagingUiHelper.filter$ | async"
        [equipment]="equipment$ | async | toTreeNodes"
        [states]="states$ | async"
        [isMobile]="isMobile$ | async"
        [serviceTypes]="serviceTypes$ | async"
        (search)="search($event)"
        (clear)="clear()"
        [showExport]="true"
        (export)="exportData()"
      ></kbxl-shipping-search-criteria>
    </div>
    <kbxl-color-bar></kbxl-color-bar>
    <div class="grid-container px-xl-4 px-1">
      <kbxl-shipping-grid
        [loading]="loading$ | async"
        [gridData]="loads$ | async"
        [user]="user$ | async"
        [isBookedLoads]="true"
        [serviceTypes]="serviceTypes$ | async"
        (selected)="selected($event)"
        (lazyLoad)="onLazyLoadEvent($event)"
        [lazy]="true"
        (documentChange)="pagingUiHelper.reloadData()"
      ></kbxl-shipping-grid>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
