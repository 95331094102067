import { GuidEmpty } from 'src/app/core/utilities/constants';
import { DynamicPricingScheduleData } from '.';

export interface LoadPricingRuleData {
  loadPricingRuleId: number;
  loadId: string;
  loadTransactionId: string;
  isRuleOverride: boolean;
  pricingRuleId: number;
  equipmentId: string;
  origAddr1: string;
  origCity: string;
  origState: string;
  origZip: string;
  origCountry: string;
  destAddr1: string;
  destCity: string;
  destState: string;
  destZip: string;
  destCountry: string;
  floorAmt: number;
  floorPct: number;
  nbrAdjustments: number;
  adjustmentFrequency: string;
  dynamicPricingSchedule: DynamicPricingScheduleData;
  reservedMargin: number;
  startBeforePickUpDateHours: number;
  stopBeforePickUpDateHours: number;
}

export const defaultLoadPricingRule: LoadPricingRuleData = {
  loadPricingRuleId: 0,
  loadId: null,
  loadTransactionId: GuidEmpty,
  isRuleOverride: false,
  pricingRuleId: null,
  equipmentId: null,
  origAddr1: null,
  origCity: null,
  origState: null,
  origZip: null,
  origCountry: null,
  destAddr1: null,
  destCity: null,
  destState: null,
  destZip: null,
  destCountry: null,
  floorAmt: null,
  floorPct: null,
  nbrAdjustments: null,
  adjustmentFrequency: null,
  dynamicPricingSchedule: null,
  reservedMargin: null,
  startBeforePickUpDateHours: null,
  stopBeforePickUpDateHours: null
};
