import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
  CoreState,
  FavoriteActionTypes,
  FavoriteAddAction,
  FavoriteAddSuccessAction,
  FavoriteDeleteAction,
  FavoriteLoadSuccessAction,
  FavoriteUpdateAction,
  getCustomerLoadTypes,
  getLoadingCustomerLoadTypes,
  getLoadingFavorites,
  getShipperUsers,
  getUserFavorites,
  ShipperUserLoadAction,
} from 'src/app/core/store';
import { GuidEmpty } from 'src/app/core/utilities/constants';
import {
  AllUserFavoriteTypes,
  CustomerLoadType,
  defaultEquipment,
  DefaultUserPostTabFavorite,
  Equipment,
  User,
  UserFavorite,
  UserPostTabFavorite,
  UserSelectItem,
} from 'src/app/shared/models';
import { ShippingLoadFilter } from 'src/app/shared/models/shipping-load-filter';
import { groupBy } from 'src/app/shared/utilities';
import { createPlace } from 'src/app/shared/utilities/create-place';
import { getUserProfileEntity, getUserProfileLoading } from 'src/app/user/store';
import { BaseComponent } from '../../../../shared/components';
import { ShippingPostedFilterCriteria, ShippingState } from '../../../store';

@Component({
  selector: 'kbxl-shipping-load-filter',
  templateUrl: './shipping-load-filter.component.html',
  styleUrls: ['./shipping-load-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingLoadFilterComponent extends BaseComponent implements OnChanges, OnInit, OnDestroy {
  @Input() filterCriteria: ShippingLoadFilter;
  @Input() visible: boolean;
  @Input() equipment: TreeNode[];
  @Output() filterCriteriaChange: EventEmitter<ShippingLoadFilter> = new EventEmitter<ShippingLoadFilter>();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading$: Observable<boolean>;
  favorites$: Observable<UserFavorite[]>;
  customerLoadTypes$: Observable<CustomerLoadType[]>;
  userProfile$: Observable<User>;
  shipperUsers$: Observable<UserSelectItem[]>;

  selectedEquipment: TreeNode[];

  localFilterCriteria: ShippingLoadFilter;

  private user: User;
  private favorites: UserFavorite[];

  constructor(private coreStore: Store<CoreState>, private actions$: Actions, private shippingStore: Store<ShippingState>) {
    super();
  }

  ngOnInit() {
    this.favorites$ = this.coreStore.pipe(
      select(getUserFavorites, AllUserFavoriteTypes.UserPostTabFavorite),
      tap((userFavorites: UserFavorite[]) => {
        if (!userFavorites) {
          return;
        }
        this.favorites = this.deepClone(userFavorites);
        const defaultFavorite = this.favorites.filter((x) => (x.data as UserPostTabFavorite).defaultFavorite);
        if (this.localFilterCriteria && this.localFilterCriteria.userFavoriteId) {
          this.loadFavorite(this.localFilterCriteria.userFavoriteId);
        } else if (defaultFavorite && defaultFavorite.length > 0) {
          // kick off action and use the first default. Note there should only be one default
          if (!this.localFilterCriteria) {
            this.localFilterCriteria = new ShippingLoadFilter();
          }
          this.loadFavorite(defaultFavorite[0].userFavoriteId); // maps to ShippingLoadFilter
          this.shippingStore.dispatch(new ShippingPostedFilterCriteria(this.localFilterCriteria));
        }
      })
    );

    this.customerLoadTypes$ = this.coreStore.pipe(select(getCustomerLoadTypes));
    this.userProfile$ = this.coreStore.pipe(select(getUserProfileEntity));
    this.shipperUsers$ = this.coreStore.pipe(select(getShipperUsers));

    this.loading$ = combineLatest([
      this.coreStore.pipe(select(getLoadingFavorites)),
      this.coreStore.pipe(select(getLoadingCustomerLoadTypes)),
      this.coreStore.pipe(select(getUserProfileLoading)),
    ]).pipe(map((args) => args[0] || args[1] || args[2]));

    this.userProfile$.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      this.user = x;
      if (x && x.isShipper && x.primaryCustomerId && x.primaryCustomerId !== GuidEmpty) {
        this.coreStore.dispatch(new ShipperUserLoadAction(x.primaryCustomerId));
      }
    });

    this.actions$
      .pipe(takeUntil(this.destroyed$), ofType<FavoriteAddSuccessAction>(FavoriteActionTypes.Add_Favorite_Success))
      .subscribe((x) => {
        this.loadFavorite(x.addedUserFavoriteId);
      });
  }

  ngOnChanges() {
    this.localFilterCriteria = this.deepClone(this.filterCriteria);

    this.updateSelectedEquipment();
  }

  clearFilter(prop: any) {
    this.localFilterCriteria[prop] = null;
  }

  clear() {
    this.selectedEquipment = [];
    this.localFilterCriteria = new ShippingLoadFilter();
  }

  search() {
    this.localFilterCriteria.equipment = this.buildEquipment();

    this.filterCriteriaChange.emit(this.localFilterCriteria);
    this.visibleChange.emit(false);
  }

  private buildEquipment(): Equipment[] {
    return this.selectedEquipment.filter((x) => x.leaf).map((x) => x.data as Equipment);
  }

  private updateSelectedEquipment() {
    if (this.localFilterCriteria && this.localFilterCriteria.equipment && this.equipment) {
      const flattenTreeNodes = this.equipment.map((_) => _.children).reduce((acc, value) => acc.concat(value));

      const equipmentTreeNodes = this.localFilterCriteria.equipment.map((currentEquipment) => {
        const equipment = flattenTreeNodes.find((_) => _.data.equipmentId === currentEquipment.equipmentId);

        if (equipment && equipment.parent) {
          equipment.parent.partialSelected = true;
        }

        return equipment;
      });

      const groupedSelections = groupBy((x) => x.data.categoryId, equipmentTreeNodes);

      groupedSelections.forEach((group) => {
        // get the first item's parent as they should all be the same;
        const treeViewGroup = group.items[0].parent;

        if (treeViewGroup && group.items.length === treeViewGroup.children.length) {
          treeViewGroup.partialSelected = false;
          equipmentTreeNodes.push(treeViewGroup);
        }
      });

      this.selectedEquipment = equipmentTreeNodes;
    } else {
      this.selectedEquipment = [];
    }
  }

  private buildFavorite(): UserFavorite {
    if (this.localFilterCriteria.quickFilter) {
      this.localFilterCriteria.quickFilter = this.localFilterCriteria.quickFilter.trim();
    }
    const favorite: UserFavorite = {
      userFavoriteId: this.localFilterCriteria.userFavoriteId,
      userId: this.user.userId,
      type: AllUserFavoriteTypes.UserPostTabFavorite,
      version: '1',
      data: {
        ...DefaultUserPostTabFavorite,
        quickFilter: this.localFilterCriteria.quickFilter,
        name: this.localFilterCriteria.favoriteName,
        defaultFavorite: this.localFilterCriteria.defaultFavorite,
      },
    };

    if (this.localFilterCriteria.origin) {
      favorite.data.origCity = this.localFilterCriteria.origin.city;
      favorite.data.origCountry = this.localFilterCriteria.origin.country;
      favorite.data.origState = this.localFilterCriteria.origin.stateAbbrev;
    }

    if (this.localFilterCriteria.dest) {
      favorite.data.destCity = this.localFilterCriteria.dest.city;
      favorite.data.destCountry = this.localFilterCriteria.dest.country;
      favorite.data.destState = this.localFilterCriteria.dest.stateAbbrev;
    }

    const selectedequip = this.buildEquipment();
    if (selectedequip) {
      favorite.data.equipmentTypes = selectedequip.map((x) => x.equipmentId);
    }

    if (this.localFilterCriteria.customerLoadTypes) {
      this.localFilterCriteria.customerLoadTypes.forEach((x) => {
        favorite.data.customerLoadTypes.push(x.customerLoadTypeId);
      });
    }

    if (this.localFilterCriteria.shipperUsers && this.localFilterCriteria.shipperUsers.length > 0) {
      this.localFilterCriteria.shipperUsers.forEach((x) => {
        favorite.data.shipperUsers.push(x);
      });
    }

    // check if new default is present
    if (this.localFilterCriteria.defaultFavorite) {
      this.favorites
        .filter((x) => x.type === AllUserFavoriteTypes.UserPostTabFavorite && x.userFavoriteId !== this.localFilterCriteria.userFavoriteId)
        .forEach((x) => (x.data.defaultFavorite = false));
      this.coreStore.dispatch(new FavoriteLoadSuccessAction(this.favorites));
    }

    return favorite;
  }

  public saveFavorite() {
    const favorite = this.buildFavorite();
    if (favorite.userFavoriteId) {
      this.coreStore.dispatch(new FavoriteUpdateAction(favorite));
    } else {
      this.coreStore.dispatch(new FavoriteAddAction(favorite));
    }
  }

  public deleteFavorite() {
    const favorite = this.buildFavorite();
    this.clear();
    this.coreStore.dispatch(new FavoriteDeleteAction(favorite));
  }

  loadFavorite(userFavoriteId: number) {
    if (!userFavoriteId) {
      userFavoriteId = this.localFilterCriteria.userFavoriteId;
    }
    const selectedFav = this.deepClone(this.favorites.find((x) => x.userFavoriteId === userFavoriteId));

    if (selectedFav) {
      this.clear();
      this.localFilterCriteria.userFavoriteId = selectedFav.userFavoriteId;
      this.localFilterCriteria.quickFilter = selectedFav.data.quickFilter;
      this.localFilterCriteria.favoriteName = selectedFav.data.name;
      this.localFilterCriteria.defaultFavorite = selectedFav.data.defaultFavorite === true;

      if (selectedFav.data.origCity || selectedFav.data.origState) {
        this.localFilterCriteria.origin = createPlace(
          null,
          selectedFav.data.origCity,
          null,
          selectedFav.data.origState,
          null,
          selectedFav.data.origCountry
        );
      }

      if (selectedFav.data.destCity || selectedFav.data.destState) {
        this.localFilterCriteria.dest = createPlace(
          null,
          selectedFav.data.destCity,
          null,
          selectedFav.data.destState,
          null,
          selectedFav.data.destCountry
        );
      }

      this.localFilterCriteria.equipment = selectedFav.data.equipmentTypes.map((x) => ({ ...defaultEquipment, equipmentId: x }));

      if (selectedFav.data.shipperUsers && selectedFav.data.shipperUsers.length > 0) {
        this.localFilterCriteria.shipperUsers = selectedFav.data.shipperUsers.map((x) => x);
      }

      this.updateSelectedEquipment();
    }
  }

  trimFilter(): void {
    if (this.localFilterCriteria && this.localFilterCriteria.quickFilter) {
      this.localFilterCriteria.quickFilter = this.localFilterCriteria.quickFilter.trim();
    }
  }
}
