import { ILoadAuditLogData } from 'src/app/shared/models/load-audit-log-data';
import { createReducer } from '../../../shared/utilities';
import {
  ShippingLoadAuditLogActions,
  ShippingLoadAuditLogActionTypes,
  ShippingLoadDetailActions,
  ShippingLoadDetailActionTypes,
} from '../actions';

export interface ShippingLoadAuditLogState {
  loadsLoading: { [s: string]: boolean };
  loadAuditLogs: { [s: string]: ILoadAuditLogData[] };
}

const initialState: ShippingLoadAuditLogState = {
  loadsLoading: {},
  loadAuditLogs: {},
};

const deleteLoadId = (loadId: string, { [loadId]: _, ...state }) => state;
const _ShippingLoadAuditLogReducer = createReducer(
  initialState,
  function (
    state: ShippingLoadAuditLogState = initialState,
    action: ShippingLoadAuditLogActions | ShippingLoadDetailActions
  ): ShippingLoadAuditLogState {
    switch (action.type) {
      case ShippingLoadAuditLogActionTypes.Load_Audit_Logs_Load: {
        return {
          ...state,
          loadAuditLogs: deleteLoadId(action.payload.loadId, state.loadAuditLogs),
          loadsLoading: { ...state.loadsLoading, ...{ [action.payload.loadId]: true } },
        };
      }
      case ShippingLoadAuditLogActionTypes.Load_Audit_Logs_Load_Success: {
        return {
          ...state,
          loadAuditLogs: { ...state.loadAuditLogs, ...{ [action.payload.loadId]: action.payload.logs } },
          loadsLoading: { ...state.loadsLoading, ...{ [action.payload.loadId]: false } },
        };
      }
      case ShippingLoadAuditLogActionTypes.Load_Audit_Logs_Load_Failure: {
        return { ...state, loadsLoading: { ...state.loadsLoading, ...{ [action.payload.loadId]: false } } };
      }
      case ShippingLoadDetailActionTypes.Delete_Load_Success: {
        return {
          ...state,
          loadAuditLogs: deleteLoadId(action.payload.loadId, state.loadAuditLogs),
          loadsLoading: { ...state.loadsLoading, ...{ [action.payload.loadId]: false } },
        };
      }
      default:
        return state;
    }
  }
);
/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function ShippingLoadAuditLogReducer(
  state: ShippingLoadAuditLogState,
  action: ShippingLoadAuditLogActions
): ShippingLoadAuditLogState {
  return _ShippingLoadAuditLogReducer(state, action);
}

export const getLoadAuditLogs = (state: ShippingLoadAuditLogState) => state.loadAuditLogs;
export const getLoadsLoading = (state: ShippingLoadAuditLogState) => state.loadsLoading;
