<div class="mx-1 mx-md-4 d-flex justify-content-center align-items-center" *ngIf="applicationReady$">
  <div class="user-profile-header user-name-company mr-3 d-none d-sm-block" *ngIf="userDisplayText">
    <a *ngIf="displayChangeEntity" (click)="showEntitySelectorModal()">{{ userDisplayText }}</a>
    <span *ngIf="!displayChangeEntity">{{ userDisplayText }}</span>
  </div>
  <div class="mr-2">
    <a class="user-profile-header d-inline-block" *ngIf="showActionItems" [routerLink]="actionItemLink" [routerLinkActive]="'active'">
      <fa-icon class="user-profile-icon user-profile-action-icon" [icon]="['far', 'bell']" title="Action Items" size="lg"></fa-icon>
      <span class="badge badge-danger" [ngClass]="{ 'badge-2': actionItemCount > 9 }" *ngIf="actionItemCount > 0">{{
        actionItemCount
      }}</span>
    </a>
  </div>
  <div class="mr-2" *ngIf="enableChat" kbxlFeature="chat">
    <a class="user-profile-header d-inline-block" [routerLink]="'/chat'" [routerLinkActive]="'active'">
      <fa-icon class="user-profile-icon" [icon]="['far', 'comments']" size="lg" title="Chat"></fa-icon>
      <span class="badge badge-danger" [ngClass]="{ 'badge-2': chatNotificationCount > 9 }" *ngIf="chatNotificationCount > 0">{{
        chatNotificationCount
      }}</span>
    </a>
  </div>
  <div class="mr-2 quick-quote" *ngIf="showQuickQuote">
    <a class="user-profile-header d-inline-block" (click)="loadQuickQuote($event)">
      <fa-icon class="user-profile-icon" [icon]="['fas', 'calculator']" size="lg" title="Quick Quote"></fa-icon>
    </a>
  </div>
  <div>
    <i class="pi pi-ellipsis-v menu-button" [ngClass]="{ active: menuActive }" (click)="menu.toggle($event)" #menuButton></i>
  </div>
  <p-menu #menu [popup]="true" [model]="items" (onShow)="showMenu(true)" (onHide)="showMenu(false)"></p-menu>
</div>
<p-dialog
  header="Quick Quote"
  [(visible)]="displayQuickQuoteDialog"
  [style]="{ 'max-width': 'calc(100vw - 200px)', 'min-width': 'fit-content', 'max-height': '70%' }"
  [modal]="true"
  [closeOnEscape]="true"
>
  <kbxl-quick-quote-modal [(visible)]="displayQuickQuoteDialog"></kbxl-quick-quote-modal>
</p-dialog>
