import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionItemLoadView, ActionItemsCriteriaData, ActionItemStatusData } from '../../models';
import { PageableQueryHelper } from '../../utilities';
import { createReducer } from '../../utilities/create-reducer';
import { AccessorialActionItemActions, AccessorialActionItemActionTypes } from '../actions';

export interface AccessorialActionItemState extends EntityState<ActionItemLoadView> {
  loadingFilterCriteria: boolean;
  loading: boolean;
  actionItemCriteria: ActionItemsCriteriaData;
  actionItemStatuses: ActionItemStatusData;
  queryHelper: PageableQueryHelper;
  totalRecords: number;
}

export const adapter: EntityAdapter<ActionItemLoadView> = createEntityAdapter<ActionItemLoadView>({
  selectId: (x) => x.loadId,
});

const initialState: AccessorialActionItemState = adapter.getInitialState({
  loading: false,
  loadingFilterCriteria: false,
  actionItemCriteria: null,
  actionItemStatuses: null,
  queryHelper: PageableQueryHelper.default(),
  totalRecords: 0,
});

const _actionItemReducer = createReducer(
  initialState,
  function (state: AccessorialActionItemState = initialState, action: AccessorialActionItemActions): AccessorialActionItemState {
    switch (action.type) {
      case AccessorialActionItemActionTypes.LoadFilters: {
        return { ...state, loadingFilterCriteria: true, actionItemCriteria: null, actionItemStatuses: null };
      }
      case AccessorialActionItemActionTypes.LoadFilters_Success: {
        return {
          ...state,
          loadingFilterCriteria: false,
          actionItemCriteria: action.payload.actionItemCriteria,
          actionItemStatuses: action.payload.actionItemStatuses,
        };
      }
      case AccessorialActionItemActionTypes.LoadFilters_Failure: {
        return { ...state, loadingFilterCriteria: false };
      }
      case AccessorialActionItemActionTypes.Load_ActionItems: {
        return { ...state, loading: true };
      }
      case AccessorialActionItemActionTypes.Load_ActionItems_Failure: {
        return { ...state, loading: false };
      }
      case AccessorialActionItemActionTypes.Load_ActionItems_Success: {
        return adapter.setAll(action.payload.data, { ...state, loading: false, totalRecords: action.payload.totalRecords });
      }
      case AccessorialActionItemActionTypes.Update_ActionItems_Query: {
        return { ...state, queryHelper: action.payload };
      }
      default:
        return state;
    }
  },
  { userEntityReset: true }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function accessorialActionItemReducer(
  state: AccessorialActionItemState,
  action: AccessorialActionItemActions
): AccessorialActionItemState {
  return _actionItemReducer(state, action);
}

export const selectors = adapter.getSelectors();
