import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from '../../../core/services';
import { LoadBoardService } from '../../../load-board/services';
import {
  CarrierDisplayData,
  Load,
  LoadDetail,
  ScacBookingEligibleRequest,
  TenderToOptions,
  UserContactData,
  UserModel,
} from '../../models';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'kbxl-tender-to-carrier-modal',
  templateUrl: './tender-to-carrier-modal.component.html',
  styleUrls: ['./tender-to-carrier-modal.component.scss'],
})
export class TenderToCarrierModalComponent extends BaseComponent implements OnInit {
  loadCarrierScacs: SelectItem[];
  selectedLoadCarrierScac: CarrierDisplayData;
  load: LoadDetail;
  user: UserModel;
  loading = true;
  enableTenderButton = false;
  showIneligibleError = false;
  ineligibleErrors: string[];
  lineHaulRate: number;
  fuelRate: number;
  loadshopFee: number;
  breakevenRate: number;
  carrierUsers: UserContactData[];
  selectedCarrierUser: UserContactData;
  showBookedLoadModal = false;
  totalRateStyleClass = null;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private loadService: LoadBoardService,
    private commonService: CommonService,
    private confirmationService: ConfirmationService,
    private currencyPipe: CurrencyPipe
  ) {
    super();
    this.load = config.data.load;
    this.user = config.data.user;
  }

  ngOnInit(): void {
    this.loadService.getLoadCarriers(this.load.loadId).subscribe((x) => {
      this.loadCarrierScacs = x.map((carrierScac) => {
        return {
          label: `${carrierScac?.scac} - ${carrierScac?.scacName}`,
          value: carrierScac,
        };
      });
      this.loading = false;
    });
  }

  closeModal(bookLoad: boolean, tenderingToOptions: TenderToOptions): void {
    this.ref.close({
      bookLoad: bookLoad,
      tenderToOptions: tenderingToOptions,
    });
  }
  selectCarrierScacChange(carrier: CarrierDisplayData): void {
    this.showIneligibleError = false;
    this.ineligibleErrors = [];
    this.carrierUsers = [];
    this.selectedCarrierUser = null;
    this.breakevenRate = null;
    this.loadshopFee = null;
    this.loading = true;

    // fetch if this scac is eligible to book the load
    const request: ScacBookingEligibleRequest = {
      loadId: this.load.loadId,
      scac: this.selectedLoadCarrierScac.scac,
    };

    this.loadService.getIsScacEligibleToBookLoad(request).subscribe((response) => {
      if (response.eligibleToBookLoad) {
        this.breakevenRate = response.breakevenRate;
        this.loadshopFee = response.loadshopFee;
        this.fuelRate = response.fuelRate;
        if (response.contractedRate && response.contractedRate > 0) {
          this.lineHaulRate = response.contractedRate;
        } else {
          this.lineHaulRate = response.lineHaulRate - response.loadshopFee;
        }

        this.calculatedRateFontStyles();

        this.loadCarrierUsers();
      } else {
        this.showIneligibleError = true;
        this.ineligibleErrors = response.errors;
        this.loading = false;
      }
    });
  }
  selectCarrierUser(user: UserContactData): void {
    if (this.selectedCarrierUser) {
      this.enableTenderButton = true;
    }
  }
  cancelClick(): void {
    this.closeModal(false, null);
  }

  tenderToCarrierClick(): void {
    const totalRate = this.lineHaulRate + this.fuelRate;
    this.confirmationService.confirm({
      message: `Are you sure you want to Tender Load to (${this.selectedLoadCarrierScac.scac}) ${
        this.selectedLoadCarrierScac.scacName
      } for a total rate of ${this.currencyPipe.transform(totalRate)}?`,
      accept: () => {
        // show the booking confirmation
        this.showBookedLoadModal = true;
      },
    });
  }

  calculatedRateFontStyles() {
    this.totalRateStyleClass = null;
  }

  bookLoad(load: Load) {
    const opts: TenderToOptions = {
      scac: this.selectedLoadCarrierScac.scac,
      carrierId: this.selectedLoadCarrierScac.carrierId,
      carrierUserId: this.selectedCarrierUser.userId,
      lineHaulRate: this.lineHaulRate,
      fuelRate: this.fuelRate,
    };

    this.closeModal(true, opts);
  }
  private loadCarrierUsers(): void {
    this.commonService.getCarrierCompanyUsers(this.selectedLoadCarrierScac.scac, true).subscribe((x) => {
      this.carrierUsers = x;
      this.loading = false;
    });
  }
}
