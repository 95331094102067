<kbxl-admin-header></kbxl-admin-header>
<kbxl-user-communication-form
  [userCommunication]="userCommunication$ | async"
  [allCarriers]="allCarriers$ | async"
  [allShippers]="allShippers$ | async"
  [allUsers]="allUsers$ | async"
  [allSecurityRoles]="allSecurityRoles$ | async"
  [processing]="processing$ | async"
  [createMode]="createMode$ | async"
  (userCommunicationUpdate)="userCommunicationUpdate($event)"
  (userCommunicationCreate)="userCommunicationCreate($event)"
  (userCommunicationCancel)="userCommunicationCancel()"
>
</kbxl-user-communication-form>
