import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment-timezone';
import { LazyLoadEvent } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Table } from 'primeng/table';
import { LoadStopStatus } from 'src/app/shared/models/load-stop-status';
import { RateBreakDownOverlayInput, defaultRateBreakDownOverlayInput } from 'src/app/shared/models/rate-break-down-data';
import { ShippingLoadView } from 'src/app/shared/models/shipping-load-view';
import { PageableComponent, PageableQueryData } from 'src/app/shared/utilities/';
import { LinehaulRateData, PageableResult, ServiceType, User, defaultLinehaulRateData } from '../../../shared/models';

@Component({
  selector: 'kbxl-shipping-grid',
  templateUrl: './shipping-grid.component.html',
  styleUrls: ['./shipping-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingGridComponent implements OnInit, OnChanges, PageableComponent {
  @Input() gridData: PageableResult<ShippingLoadView>;
  @Input() loading: boolean;
  @Input() user: User;
  @Input() isBookedLoads = false;
  @Input() isDeliveredLoads = false;
  @Input() isMarketplace = false;
  @Input() lazy = false;
  @Input() serviceTypes: ServiceType[];
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Output() lazyLoad: EventEmitter<PageableQueryData> = new EventEmitter<PageableQueryData>();
  @Output() documentChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  sortName: string;
  sortOrder: number;
  first = 0;
  numberOfColumns = 12;
  columns: any[];
  loads: ShippingLoadView[];
  totalRecords: number;

  @ViewChild(Table, { static: true }) table;

  hoveredPricingRow: ShippingLoadView;
  selectedLoadDocumentsLoad: ShippingLoadView;
  documentDialogVisible = false;

  linehaulRateData: LinehaulRateData;
  rateBreakDownInput: RateBreakDownOverlayInput;
  showrateBreakDownOverlay = false;
  showrateBreakDownOverlayEvent: any;
  showLinehaulOverlay = false;
  showLinehaulOverlayEvent: any;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    this.columns = [
      { field: 'referenceLoadDisplay', header: "Shipper's Order Number" },
      { field: 'billingLoadDisplay', header: 'Billing Load ID' },
      { field: 'originDisplay', header: 'Origin' },
      { field: 'destinationDisplay', header: 'Destination' },
      { field: 'originLateDtTm', header: 'Pickup' },
      { field: 'destLateDtTm', header: 'Delivery' },
      { field: 'equipmentTypeDisplay', header: 'Equipment' },
      { field: 'serviceTypeDisplay', header: 'Required Services' },
      { field: 'hasPod', header: 'Received POD' },
      { field: 'miles', header: 'Distance (mi)' },
      { field: 'totalRateDisplay', header: 'Total Rate' },
      { field: 'scac', header: 'SCAC' },
      { field: 'currentStatus', header: 'Status' },
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.gridData) {
        this.loads = this.gridData.data;
        this.totalRecords = this.gridData.totalRecords;
        if (!this.lazy) {
          this.first = 0;
        }
      }
    }
  }

  setFirst(first: number) {
    this.first = first;
  }

  onRowSelect(load: ShippingLoadView) {
    this.selected.emit(load.loadId);
  }

  onPage($event) {
    if ($event && $event.first && !this.lazy) {
      this.first = $event.first;
    }
  }

  onLazyLoad($event: LazyLoadEvent) {
    const pageSize = $event.rows;
    const pageNumber = $event.first / $event.rows + 1;
    const sortField = $event.sortField;
    const descending = $event.sortOrder === -1;
    this.first = $event.first;

    this.lazyLoad.emit({ pageSize, pageNumber, filter: null, sortField: sortField, descending: descending });
  }

  serviceTypeMouseHover(load: ShippingLoadView, serviceTypesOverlay: any, event: Event): void {
    if (!load.serviceTypeIds || load.serviceTypeIds.length < 2) {
      return;
    }
    if (serviceTypesOverlay.overlayVisible === false && serviceTypesOverlay.render === false) {
      this.hoveredPricingRow = load;
      event.stopPropagation();
      serviceTypesOverlay.show(event, event.target);
      this.changeDetector.detectChanges();
    }
  }
  serviceTypeMouseHoverOut(serviceTypesOverlay: OverlayPanel): void {
    if (serviceTypesOverlay.overlayVisible && serviceTypesOverlay.render) {
      serviceTypesOverlay.hide();
    }
  }

  highlightProofOfDelivery(loadview: ShippingLoadView): boolean {
    return loadview.requiresPod === true && loadview.hasPod === false && this.isDeliveredLoads === true;
  }

  getServiceTypes(load: ShippingLoadView): any[] {
    let elementClasses: string[] = [];
    if (!load.serviceTypeIds || load.serviceTypeIds.length === 0 || !this.serviceTypes) {
      return [];
    }
    const serviceTypeObjs = [];
    for (let i = 0; i < 2; i++) {
      elementClasses = [];
      const type = this.serviceTypes.find((x) => x.serviceTypeId === load.serviceTypeIds[i]);
      // POD Upload Required highlight -- Ensure highlight occurs even with load.serviceTypeIds.length > 2
      if (type && this.highlightProofOfDelivery(load) === true && type.name === 'POD Upload Required') {
        elementClasses.push('podhighlight');
        serviceTypeObjs.push({ name: (i > 0 ? ', ' : '') + type.name, elementClasses: elementClasses });
        continue;
      }
      if (type) {
        serviceTypeObjs.push({ name: (i > 0 ? ', ' : '') + type.name, elementClasses: elementClasses });
      }
    }
    if (load.serviceTypeIds.length > 2) {
      serviceTypeObjs.push({ name: ' + ' + (load.serviceTypeIds.length - 2).toString(), elementClasses: elementClasses });
    }
    return serviceTypeObjs;
  }

  getServiceTypesHover(load: ShippingLoadView): string {
    if (!load.serviceTypeIds || !this.serviceTypes) {
      return '';
    }

    return this.getServiceTypeNames(load);
  }

  getServiceTypeNames(load: ShippingLoadView): string {
    const names = [];
    load.serviceTypeIds.forEach((element) => {
      const serviceType = this.serviceTypes.find((x) => x.serviceTypeId === element);
      if (serviceType) {
        names.push(serviceType.name);
      }
    });

    return names.join(', ');
  }

  showDocumentOverlay(load: ShippingLoadView, loadDocumentOverlay: OverlayPanel, event: Event) {
    if (load.hasPod) {
      // Make sure the overlay is hidden before showing
      // so it shows up in correct spot
      this.hideDocumentOverlay(loadDocumentOverlay);
      event.stopPropagation();

      setTimeout(() => {
        this.selectedLoadDocumentsLoad = load;
        loadDocumentOverlay.show(event, event.target);
        this.changeDetector.detectChanges();
      }, 10);
    }
  }

  showDocumentDialog(load: ShippingLoadView, event: Event) {
    this.selectedLoadDocumentsLoad = load;
    this.documentDialogVisible = true;
    event.stopPropagation();
  }

  hideDocumentOverlay(loadDocumentOverlay: OverlayPanel) {
    this.selectedLoadDocumentsLoad = null;
    loadDocumentOverlay.hide();
  }

  handleDocumentChange() {
    this.documentChange.emit(true);
  }

  getPodRecievedDisplay(load: ShippingLoadView) {
    if (load.hasPod) {
      return 'Yes';
    }

    if (load.requiresPod) {
      return 'No';
    }

    return 'N/A';
  }

  public getApptEstLabel(load: ShippingLoadView, origin: boolean, appt: boolean): string {
    let result: string = null;

    if (load.loadStops) {
      let statuses: LoadStopStatus[];
      if (origin) {
        const stop = load.loadStops.filter((x) => x.stopNbr === 1);
        if (stop && stop.length === 1) {
          statuses = stop[0].loadStopStatuses;
        }
      } else {
        const stop = load.loadStops.filter((x) => x.stopNbr === load.stops);
        if (stop && stop.length === 1) {
          statuses = stop[0].loadStopStatuses;
        }
      }

      if (appt) {
        const latestAppt = statuses
          .filter((x) => x.statusType.indexOf('APPOINTMENT') >= 0)
          .sort((a, b) => (a.statusDateUtc >= b.statusDateUtc ? 1 : -1))[0];
        if (latestAppt) {
          result = `(APPT ${this.formatUtcDate(latestAppt.statusDateUtc, latestAppt.statusDateTimeZone)})`;
        }
      } else {
        const latestEta = statuses
          .filter((x) => x.statusTypeDesc.indexOf('ESTIMATED') >= 0)
          .sort((a, b) => (a.statusDateUtc >= b.statusDateUtc ? 1 : -1))[0];
        if (latestEta) {
          result = `(ETA ${this.formatUtcDate(latestEta.statusDateUtc, latestEta.statusDateTimeZone)})`;
        }
      }
    }

    return result;
  }

  private formatUtcDate(date: Date, timeZone: string) {
    return moment.utc(date).tz(timeZone).format('M/D/YY h:mm A');
  }

  showLinehaulRateOverlay($event, hoveredRow: ShippingLoadView) {
    if (this.isMarketplace) {
      const data = {
        ...defaultRateBreakDownOverlayInput,
        usesAllInRates: hoveredRow.usesAllInRates,
        isShipper: true,
        isBrokeredLoad: hoveredRow.isBrokeredLoad,
        isEstimatedFSC: hoveredRow.isEstimatedFSC,
        loadId: hoveredRow.loadId,
      };

      this.rateBreakDownInput = data;
      this.showrateBreakDownOverlayEvent = $event;
      this.showrateBreakDownOverlay = true;
    } else {
      const data = {
        ...defaultLinehaulRateData,
        linehaulRate: hoveredRow.lineHaulRate,
        fuelRate: hoveredRow.fuelRate,
        usesAllInRates: hoveredRow.usesAllInRates,
        isShipper: true,
        isBrokeredLoad: hoveredRow.isBrokeredLoad,
        isEstimatedFSC: hoveredRow.isEstimatedFSC,
      };

      this.linehaulRateData = data;
      this.showLinehaulOverlayEvent = $event;
      this.showLinehaulOverlay = true;
    }
  }

  hideLinehaulRateOverlay() {
    this.showLinehaulOverlay = false;
    this.showLinehaulOverlayEvent = null;
    this.linehaulRateData = null;
    this.rateBreakDownInput = null;
    this.showrateBreakDownOverlayEvent = null;
    this.showrateBreakDownOverlay = false;
  }

  public exportData() {
    this.table.exportCSV();
  }
}
