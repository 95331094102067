/* tslint:disable:whitespace */
export class DynamicPricingRuleBuildScheduleRequest {
  floorAmt: number;
  floorPct: number;
  nbrAdjustments: number;
  adjustmentFrequency: string;
  maxLineHaul: number;
  scheduleStartTime: Date;
  priceIncreasePrecision? = 2;
  brokeredLoad: boolean;
  pickUpDate: Date;
  reservedMargin: number;
  startBeforePickUpDateHours: number;
  stopBeforePickUpDateHours: number;
  totalPrice: number;
}
