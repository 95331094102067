import { AdminMenuEffects } from './admin-menu.effects';
import { AgreementDocumentEffects } from './agreement-document.effects';
import { AnalyticsEffects } from './analytics.effects';
import { CarrierEffects } from './carrier.effects';
import { CustomerFavoriteEffects } from './customer-favorite.effects';
import { CustomerEffects } from './customer.effects';
import { FavoriteEffects } from './favorite.effects';
import { FuelRateEffects } from './fuel-rate.effects';
import { GoogleMapEffects } from './google-map.effects';
import { LoadStatusEffects } from './load-status.effects';
import { MenuEffects } from './menu.effects';
import { ShipperUserEffects } from './shipper-user.effects';
import { SmartSpotPriceEffects } from './smart-spot-price.effects';
import { StartupEffects } from './startup.effects';

export const effects: any[] = [
  AdminMenuEffects,
  MenuEffects,
  GoogleMapEffects,
  FavoriteEffects,
  CustomerFavoriteEffects,
  AnalyticsEffects,
  CarrierEffects,
  CustomerEffects,
  SmartSpotPriceEffects,
  LoadStatusEffects,
  AgreementDocumentEffects,
  FuelRateEffects,
  ShipperUserEffects,
  StartupEffects,
];
