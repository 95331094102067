import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CustomerFavorite } from 'src/app/shared/models';
import { createReducer } from '../../../shared/utilities';
import { CustomerFavoriteActions, CustomerFavoriteActionTypes } from '../actions/customer-favorite.actions';

export interface CustomerFavoriteState extends EntityState<CustomerFavorite> {
  loading: boolean;
}

export const adapter: EntityAdapter<CustomerFavorite> = createEntityAdapter<CustomerFavorite>({
  selectId: (x) => x.customerFavoriteId,
});

const initialState: CustomerFavoriteState = adapter.getInitialState({
  loading: false,
});

const _customerFavoriteReducer = createReducer(
  initialState,
  function (state: CustomerFavoriteState = initialState, action: CustomerFavoriteActions): CustomerFavoriteState {
    switch (action.type) {
      case CustomerFavoriteActionTypes.Add_Favorite:
      case CustomerFavoriteActionTypes.Update_Favorite:
      case CustomerFavoriteActionTypes.Delete_Favorite:
      case CustomerFavoriteActionTypes.Load: {
        return { ...state, loading: true };
      }

      case CustomerFavoriteActionTypes.Add_Favorite_Failure:
      case CustomerFavoriteActionTypes.Update_Favorite_Failure:
      case CustomerFavoriteActionTypes.Delete_Favorite_Failure:
      case CustomerFavoriteActionTypes.Load_Failure: {
        return { ...state, loading: false };
      }

      case CustomerFavoriteActionTypes.Load_Success: {
        return adapter.setAll(action.payload, { ...state, loading: false });
      }
      case CustomerFavoriteActionTypes.Add_Favorite_Success: {
        return adapter.addOne(action.payload, { ...state, loading: false });
      }
      case CustomerFavoriteActionTypes.Update_Favorite_Success: {
        return adapter.updateOne({ id: action.payload.customerFavoriteId, changes: action.payload }, { ...state, loading: false });
      }
      case CustomerFavoriteActionTypes.Delete_Favorite_Success: {
        return adapter.removeOne(action.payload.customerFavoriteId, { ...state, loading: false });
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function customerFavoriteReducer(state: CustomerFavoriteState, action: CustomerFavoriteActions): CustomerFavoriteState {
  return _customerFavoriteReducer(state, action);
}

export const getLoading = (state: CustomerFavoriteState) => state.loading;
export const getEntities = (state: CustomerFavoriteState) => state.entities;
export const selectAll = adapter.getSelectors().selectAll;
