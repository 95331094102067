import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { mapToPayload } from '@tms-ng/shared';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CounterOfferActionItemService } from '../../services';
import { PageableQueryHelper } from '../../utilities';
import {
  CounterOfferActionItemActionTypes,
  CounterOfferActionItemLoadFiltersAction,
  CounterOfferActionItemLoadFiltersFailureAction,
  CounterOfferActionItemLoadFiltersSuccessAction,
  CounterOfferActionItemsLoadAction,
  CounterOfferActionItemsLoadFailureAction,
  CounterOfferActionItemsLoadSuccessAction,
} from '../actions';

@Injectable()
export class CounterOfferActionItemEffects {
  @Effect()
  $loadCriteria: Observable<Action> = this.actions$.pipe(
    ofType<CounterOfferActionItemLoadFiltersAction>(CounterOfferActionItemActionTypes.LoadFilters),
    switchMap(() =>
      this.counterOfferActionItemService.getFilterCriteria().pipe(
        map((data) => new CounterOfferActionItemLoadFiltersSuccessAction(data)),
        catchError((err) => of(new CounterOfferActionItemLoadFiltersFailureAction(err)))
      )
    )
  );

  @Effect()
  $loadActionItems: Observable<Action> = this.actions$.pipe(
    ofType<CounterOfferActionItemsLoadAction>(CounterOfferActionItemActionTypes.Load_ActionItems),
    mapToPayload<{ searchType: string; queryHelper: PageableQueryHelper }>(),
    switchMap((payload) =>
      this.counterOfferActionItemService.getPageableLoadsBySearchType('', payload.queryHelper).pipe(
        map((data) => new CounterOfferActionItemsLoadSuccessAction(data)),
        catchError((err) => of(new CounterOfferActionItemsLoadFailureAction(err)))
      )
    )
  );

  constructor(private actions$: Actions, private counterOfferActionItemService: CounterOfferActionItemService) {}
}
