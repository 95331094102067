/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserSelectItem } from './user-select-item';

export interface UserFavorite {
  userFavoriteId: number;
  userId: string;
  version: string;
  type: string;
  data: UserPostTabFavorite | UserFavoriteData | MarketplaceSavedSearchFavorite | any;
}

export interface UserFavoriteData {
  type: string;
  name: string;
  defaultFavorite: boolean;
}

export interface UserPostTabFavorite extends UserFavoriteData {
  quickFilter: string;
  origCity: string;
  origState: string;
  origCountry: string;
  origDH: number;
  destCity: string;
  destState: string;
  destCountry: string;
  destDH: number;
  equipmentTypes: string[];
  customerLoadTypes: number[];
  shipperUsers: UserSelectItem[];
}

export interface MarketplaceSavedSearchFavorite extends UserFavoriteData {
  quickSearch: string;
  origCity: string;
  origState: string;
  origCountry: string;
  origDH: number;
  origLat: number;
  origLng: number;
  destCity: string;
  destState: string;
  destCountry: string;
  destDH: number;
  destLat: number;
  destLng: number;
  equipmentType: string[];
  serviceTypes: number[];
  shipperIds: string[];
  showAllLoads: boolean;
}

export enum AllUserFavoriteTypes {
  UserPostTabFavorite = 'UserPostTabFavorite',
  MarketplaceSavedSearch = 'MarketplaceSavedSearch',
}

export const DefaultUserPostTabFavorite: UserPostTabFavorite = {
  type: AllUserFavoriteTypes.UserPostTabFavorite,
  name: null,
  quickFilter: null,
  origCity: null,
  origState: null,
  origCountry: null,
  origDH: null,
  destCity: null,
  destState: null,
  destCountry: null,
  destDH: null,
  equipmentTypes: [],
  customerLoadTypes: [],
  shipperUsers: [],
  defaultFavorite: null,
};

export const DefaultMarketplaceSavedSearchFavorite: MarketplaceSavedSearchFavorite = {
  type: AllUserFavoriteTypes.MarketplaceSavedSearch,
  name: null,
  quickSearch: null,
  origCity: null,
  origState: null,
  origCountry: null,
  origDH: null,
  origLat: null,
  origLng: null,
  destCity: null,
  destState: null,
  destCountry: null,
  destDH: null,
  destLat: null,
  destLng: null,
  equipmentType: [],
  shipperIds: [],
  serviceTypes: [],
  showAllLoads: false,
  defaultFavorite: false,
};

export function isUserFavorite(x: any): x is UserFavorite {
  return typeof x.userFavoriteId === 'number';
}

export function isUserFavoriteArray(x: any): x is UserFavorite[] {
  return x.every(isUserFavorite);
}
