<link rel="preconnect" href="https://fonts.gstatic.com" />
<link href="https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap" rel="stylesheet" />

<div class="d-flex flex-column">
  <div class="d-flex mb-2">
    <p-button
      *ngIf="!isMobile"
      [kbxlPrint]="agreementContent"
      label="Print"
      class="ml-auto mr-2"
      styleClass="p-button-outlined p-button-secondary"
      icon="pi pi-print"
    ></p-button>
  </div>
  <div class="overflow-auto agreement-content">
    <p [innerHTML]="agreement.agreementContent" #agreementContent></p>
  </div>
  <div class="mt-2 text-right">
    <button pButton type="button" label="Close" (click)="close()"></button>
  </div>
</div>
