import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'kbxl-user-focus-entity-selector-modal',
  templateUrl: './user-focus-entity-selector-modal.component.html',
  styleUrls: ['./user-focus-entity-selector-modal.component.scss'],
})
export class UserFocusEntitySelectorModalComponent {
  loading = false;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  close(): void {
    this.ref.close();
  }
}
