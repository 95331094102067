<div class="stops-container">
  <div class="stop-header" *ngIf="stop.stopNbr === 1">Pickup</div>
  <div class="stop-header" *ngIf="stop.stopNbr === total">Destination</div>

  <div class="stop-details">
    <div class="stop-header number-of-stops" *ngIf="stop.stopNbr !== 1 && stop.stopNbr !== total">
      Stop {{ stop.stopNbr }} of {{ total }}
    </div>
    <div *ngIf="options?.showCompleteAddress">
      {{ stop.locationName | titlecase }}
    </div>
    <div *ngIf="options?.showCompleteAddress">
      {{ stop.address1 | titlecase }} {{ stop.address2 | titlecase }} {{ stop.address3 | titlecase }}
    </div>
    <div class="stop-city-state">{{ stop.city | titlecase }}, {{ stop.state }}, {{ stop.postalCode }}</div>
    <div *ngIf="stop.earlyDtTm && stop.earlyDtTm !== stop.lateDtTm">
      {{ stop.earlyDtTm | date: 'short' }}&nbsp;<fa-icon
        [icon]="'question-circle'"
        pTooltip="All times are in the local time of the stop"
        tooltipZIndex="10501010"
      ></fa-icon
      ><br />
      {{ stop.lateDtTm | date: 'short' }}
    </div>
    <div *ngIf="!stop.earlyDtTm || stop.earlyDtTm === stop.lateDtTm">
      {{ stop.lateDtTm | date: 'short' }}&nbsp;<fa-icon
        [icon]="'question-circle'"
        pTooltip="All times are in the local time of the stop"
        tooltipZIndex="10501010"
      ></fa-icon>
    </div>
    <div>Type: {{ stop.apptType }}</div>
    <div *ngIf="stop.instructions">Instruction: {{ stop.instructions }}</div>
    <div *ngIf="options?.showReferenceTypes" class="mt-3">
      <div *ngFor="let item of stop.metadata[loadStopRefs]">{{ item.description }}: {{ item.value }}</div>
    </div>
    <div *ngIf="options?.showContacts && stop.contacts?.length > 0" class="d-flex flex-column mt-4">
      <div>Contact: {{ stop.contacts[0].firstName }} {{ stop.contacts[0].lastName }}</div>
      <div>Phone: {{ stop.contacts[0].phoneNumber }}</div>
      <div>Email: {{ stop.contacts[0].email }}</div>
    </div>
  </div>
</div>
