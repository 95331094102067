import { Action } from '@ngrx/store';
import { Customer, UserContactData } from '../../../shared/models';

export enum CustomerActionTypes {
  Load = '[Customer] LOAD',
  Load_Success = '[Customer] LOAD_SUCCESS',
  Load_Failure = '[Customer] LOAD_FAILURE',
  GetUsersInCompany = '[Customer] GET_USERS_IN_COMPANY',
  GetUsersInCompany_Success = '[Customer] GET_USERS_IN_COMPANY SUCCESS',
  GetUsersInCompany_Failure = '[Customer] GET_USERS_IN_COMPANY FAILURE',
}

export class CustomerLoadAction implements Action {
  readonly type = CustomerActionTypes.Load;

  constructor(public payload: { customerId: string }) {}
}

export class CustomerLoadSuccessAction implements Action {
  readonly type = CustomerActionTypes.Load_Success;

  constructor(public payload: Customer) {}
}

export class CustomerLoadFailureAction implements Action {
  readonly type = CustomerActionTypes.Load_Failure;

  constructor(public payload: Error) {}
}

export class CustomerGetUsersInCompanyAction implements Action {
  readonly type = CustomerActionTypes.GetUsersInCompany;
  constructor(public payload: string) {}
}
export class CustomerGetUsersInCompanySuccessAction implements Action {
  readonly type = CustomerActionTypes.GetUsersInCompany_Success;
  constructor(public payload: UserContactData[]) {}
}
export class CustomerGetUsersInCompanyFailureAction implements Action {
  readonly type = CustomerActionTypes.GetUsersInCompany_Failure;
  constructor(public payload: Error) {}
}
export type CustomerActions =
  | CustomerLoadAction
  | CustomerLoadSuccessAction
  | CustomerLoadFailureAction
  | CustomerGetUsersInCompanyAction
  | CustomerGetUsersInCompanySuccessAction
  | CustomerGetUsersInCompanyFailureAction;
