import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { LoadStop } from '../../../shared/models';
import { LoadStopRefs } from '../../constants';
import { LoadStopOptions } from '../../models/load-stop-options';

@Component({
  selector: 'kbxl-load-stop',
  templateUrl: './load-stop.component.html',
  styleUrls: ['./load-stop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadStopComponent {
  loadStopRefs: string = LoadStopRefs;
  @Input() stop: LoadStop;
  @Input() total: number;
  @Input() options: LoadStopOptions;
}
