import { Injectable } from '@angular/core';
import { TransactionType } from '../models';

@Injectable()
export class TransactionTypeService {
  getTransactionTypesForPost(): TransactionType[] {
    return [TransactionType.PendingAdd, TransactionType.PendingUpdate, TransactionType.New, TransactionType.Updated];
  }

  getTransactionTypesForMarketplace(): TransactionType[] {
    return [TransactionType.New, TransactionType.Updated];
  }

  getTransactionTypesForBooked(): TransactionType[] {
    return [
      TransactionType.Accepted,
      TransactionType.PreTender,
      TransactionType.Pending,
      TransactionType.SentToShipperTender,
      TransactionType.PendingPod,
    ];
  }

  getTransactionTypesForDelivered(): TransactionType[] {
    return [TransactionType.Delivered];
  }
}
