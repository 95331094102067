import { FuelProgramTypeEnum } from './FuelProgramType';

export interface LoadCarrierScacData {
  scac: string;
  contractRate: number;
  ratePerUnitOfDistance?: number;
  isContracted?: boolean;
  lineHaulRate?: number;
  fuelRate?: number;
  fuelProgramType?: FuelProgramTypeEnum;
  totalRate?: number;
}

export const defaultLoadCarrierScacData: LoadCarrierScacData = {
  scac: null,
  contractRate: null,
  ratePerUnitOfDistance: null,
  isContracted: null,
  lineHaulRate: null,
  fuelRate: null,
  fuelProgramType: null,
  totalRate: null
};
