<div id="filter-header" class="d-lg-none mt-1" (click)="collapsed = !collapsed">
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center">
      <button
        *ngIf="!collapsed"
        pButton
        type="button"
        (click)="clearClick($event)"
        class="p-button-outlined p-button-secondary w-100"
        label="Clear"
      ></button>
      <div class="flex-grow-1 pl-2 pr-2 text-center">
        <fa-icon *ngIf="!collapsed" [icon]="['fas', 'caret-up']" size="2x"></fa-icon>
        <fa-icon *ngIf="collapsed" [icon]="['fas', 'caret-down']" size="2x"></fa-icon>
        <span class="kbxl-field-label">Filter Loads</span>
      </div>
      <button *ngIf="!collapsed" pButton type="button" (click)="applyClick($event)" class="w-100" label="Apply"></button>
    </div>
  </div>
</div>
<div class="mobile-overlay pb-1">
  <div class="page-container pt-3 p-lg-0 d-lg-block background" [class.d-none]="collapsed">
    <div class="d-flex justify-content-end">
      <div class="kbxl-inputgroup-container mb-0">
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            class="filter-box p-inputgroup-addon"
            placeholder="Quick Search Criteria"
            [(ngModel)]="quickSearch"
            (keyup.enter)="searchClick()"
            id="quickSearch"
          />
          <button
            pButton
            type="button"
            icon="pi pi-times-circle"
            (click)="clearFilter('quickSearch')"
            class="clearQuickFilter p-inputgroup-addon"
          ></button>
          <button pButton type="button" icon="pi pi-filter" class="p-button-secondary p-inputgroup-addon" (click)="searchClick()"></button>
        </div>
      </div>
    </div>
    <p-panel [toggleable]="true" collapsed="true" styleClass="advanced-search-panel">
      <p-header class="p-panel-title mr-2 p-0">
        <span>Advanced Search</span>
      </p-header>
      <div class="row pt-lg-2">
        <div class="col-12 col-lg-3">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Origin</label>
              </div>
              <kbxl-address-autocomplete
                [(place)]="origin"
                [includeAddress]="false"
                (placeChange)="runSearch()"
                [placeholder]="'Search by City/State/Province'"
              >
              </kbxl-address-autocomplete>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Destination</label>
              </div>
              <kbxl-address-autocomplete
                [(place)]="dest"
                [includeAddress]="false"
                (placeChange)="runSearch()"
                [placeholder]="'Search by City/State/Province'"
              >
              </kbxl-address-autocomplete>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Equipment Type</label>
              </div>
              <div class="p-inputgroup">
                <kbxl-tree-drop-down
                  [treeNodes]="equipment"
                  [(selectedNodes)]="selectedEquipment"
                  placeHolder="Select Equipment Type(s)"
                  (selectedNodesChange)="runSearch()"
                ></kbxl-tree-drop-down>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Lane Attribute</label>
              </div>
              <div class="p-inputgroup">
                <p-multiSelect
                  [options]="laneAttributes"
                  optionLabel="description"
                  [styleClass]="'p-dropdown-primary'"
                  placeholder="Filter by Lane Attributes"
                  [(ngModel)]="selectedLaneAttributes"
                  class="w-100"
                >
                </p-multiSelect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column pb-2">
        <div class="text-right">
          <button
            pButton
            type="button"
            (click)="clearClick($event)"
            class="p-button-outlined p-button-secondary"
            label="Clear Filters"
          ></button>

          <button pButton type="button" (click)="searchClick()" class="ml-3" [label]="'Search'"></button>
        </div>
      </div>
    </p-panel>
  </div>
</div>
