import { ErrorHandler, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable()
export class ErrorService implements ErrorHandler {
  constructor(private messageService: MessageService) {}

  handleError(error: any): any {
    this.messageService.add({ severity: 'error', detail: error });
    console.error(error);
    return error;
  }
}
