import { TitleCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SecurityService } from '../../../core/services';
import { CoreState } from '../../../core/store';
import { UserAdminData } from '../../../shared/models';
import { LoadshopApplicationResetAction } from '../../../shared/store';

@Component({
  selector: 'kbxl-user-impersonation-modal',
  templateUrl: './user-impersonation-modal.component.html',
  styleUrls: ['./user-impersonation-modal.component.scss'],
})
export class UserImpersonationModalComponent {
  selectedUser: UserAdminData;
  userResults: UserAdminData[];
  loading = false;

  constructor(
    private coreStore: Store<CoreState>,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private securityService: SecurityService,
    private titleCasePipe: TitleCasePipe,
    private router: Router
  ) {}

  close(): void {
    this.ref.close();
  }
  searchUsers(event: any): void {
    this.securityService.searchUsersToImpersonate(event.query).subscribe((results) => {
      this.userResults = results;
    });
  }

  impersonate(): void {
    if (!this.selectedUser) {
      return;
    }

    const fName = this.titleCasePipe.transform(this.selectedUser.firstName);
    const lName = this.titleCasePipe.transform(this.selectedUser.lastName);

    this.confirmationService.confirm({
      message: `Are you sure you want to impersonate ${fName} ${lName}?`,
      accept: () => {
        this.loading = true;
        this.securityService.impersonateUser(this.selectedUser.userId).subscribe((result) => {
          if (result) {
            // toast to user, then refresh
            this.messageService.add({
              detail: `You are now impersonating ${fName} ${lName} (${this.selectedUser.username}), page will refresh momentarily...`,
              severity: 'success',
            });

            // Wipe out ngrx store and reload user profile
            this.coreStore.dispatch(new LoadshopApplicationResetAction());
            // navigate to root of site
            this.router.navigate(['/']);

            // close modal
            this.close();
          }
        });
      },
    });
  }
}
