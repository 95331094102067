import { CustomerApiEndpointData } from './customer-api-endpoint-data';
import { CustomerApiTypeData } from './customer-api-type-data';

export interface CustomerApiData {
  availableApiTypes: CustomerApiTypeData[];
  endpoints: CustomerApiEndpointData[];
}

export const defaultCustomerApiData: CustomerApiData = {
  availableApiTypes: [],
  endpoints: [],
};
