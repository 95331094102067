import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[kbxlPrint]',
})
export class PrintDirective {
  @Input() kbxlPrint: HTMLElement;

  constructor() {
    window.onafterprint = (ev: Event) => {
      const printablePlacehold = document.getElementById('printable-placeholder') as HTMLDivElement;

      // clear out print section after user is done printing or cancels
      if (printablePlacehold) {
        printablePlacehold.innerHTML = '';
      }
    };
  }

  @HostListener('click', ['$event'])
  clickEvent(event): void {
    if (!this.kbxlPrint) {
      return;
    }

    const clone = this.kbxlPrint.cloneNode(true) as HTMLElement;
    clone.classList.add('printable');

    const printablePlacehold = document.getElementById('printable-placeholder') as HTMLDivElement;

    // remove any nodes if there is something left over from a prior print
    if (printablePlacehold) {
      printablePlacehold.innerHTML = '';
    }

    printablePlacehold.appendChild(clone);

    window.print();
  }
}
