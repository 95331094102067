import { Action } from '@ngrx/store';
import { CarrierUsersData, MyAuthorizedEntities, UserAdminData } from '../../../shared/models';

export enum UserAdminActionTypes {
  Load_Users = '[UserAdmin] LOAD_USERS',
  Load_Users_Success = '[UserAdmin] LOAD_USERS_SUCCESS',
  Load_Users_Failure = '[UserAdmin] LOAD_USERS_FAILURE',
  Load_AdminUsers = '[UserAdmin] LOAD_ADMINUSERS',
  Load_AdminUsers_Success = '[UserAdmin] LOAD_ADMINUSERS_SUCCESS',
  Load_AdminUsers_Failure = '[UserAdmin] LOAD_ADMINUSERS_FAILURE',
  Load_User = '[UserAdmin] LOAD_USER',
  Load_User_Success = '[UserAdmin] LOAD_USER_SUCCESS',
  Load_User_Failure = '[UserAdmin] LOAD_USER_FAILURE',
  Load_Identity_User = '[UserAdmin] LOAD_IDENTITY_USER',
  Load_Identity_User_Success = '[UserAdmin] LOAD_IDENTITY_USER_SUCCESS',
  Load_Identity_User_Not_Found = '[UserAdmin] LOAD_IDENTITY_USER_NOT_FOUND',
  Load_Identity_User_Failure = '[UserAdmin] LOAD_IDENTITY_USER_Failure',
  Load_Authorized_Entities = '[UserAdmin] LOAD_AUTHORIZED_ENTITIES',
  Load_Authorized_Entities_Success = '[UserAdmin] LOAD_AUTHORIZED_ENTITIES_SUCCESS',
  Load_Authorized_Entities_Failure = '[UserAdmin] LOAD_AUTHORIZED_ENTITIES_FAILURE',
  Update_User = '[UserAdmin] UPDATE_USER',
  Update_User_Success = '[UserAdmin] UPDATE_USER_SUCCESS',
  Update_User_Failure = '[UserAdmin] UPDATE_USER_FAILURE',
  Create_User = '[UserAdmin] CREATE_USER',
  Create_User_Success = '[UserAdmin] CREATE_USER_SUCCESS',
  Create_User_Failure = '[UserAdmin] CREATE_USER_FAILURE',
  Load_Carrier_Users_Data = '[UserAdmin] LOAD_CARRIER_USERS_DATA',
  Load_Carrier_Users_Data_Success = '[UserAdmin] LOAD_CARRIER_USERS_DATA_SUCCESS',
  Load_Carrier_Users_Data_Failure = '[UserAdmin] LOAD_CARRIER_USERS_DATA_FAILURE',
  Clear_Selected_User = '[UserAdmin] CLEAR_SELECTED_USER',
  Delete_User = '[UserAdmin] DELETE_USER',
  Delete_User_Success = '[UserAdmin] DELETE_USER_SUCCESS',
  Delete_User_Failure = '[UserAdmin] DELETE_USER_FAILURE',
}

export class UserAdminLoadUsersAction implements Action {
  readonly type = UserAdminActionTypes.Load_Users;
  constructor(public payload: { query: string }) {}
}

export class UserAdminLoadUsersSuccessAction implements Action {
  readonly type = UserAdminActionTypes.Load_Users_Success;

  constructor(public payload: UserAdminData[]) {}
}

export class UserAdminLoadUsersFailureAction implements Action {
  readonly type = UserAdminActionTypes.Load_Users_Failure;

  constructor(public payload: Error) {}
}

export class UserAdminLoadAdminUsersAction implements Action {
  readonly type = UserAdminActionTypes.Load_AdminUsers;
}

export class UserAdminLoadAdminUsersSuccessAction implements Action {
  readonly type = UserAdminActionTypes.Load_AdminUsers_Success;

  constructor(public payload: UserAdminData[]) {}
}

export class UserAdminLoadAdminUsersFailureAction implements Action {
  readonly type = UserAdminActionTypes.Load_AdminUsers_Failure;

  constructor(public payload: Error) {}
}

export class UserAdminLoadUserAction implements Action {
  readonly type = UserAdminActionTypes.Load_User;
  constructor(public payload: { userId: string }) {}
}

export class UserAdminLoadUserSuccessAction implements Action {
  readonly type = UserAdminActionTypes.Load_User_Success;

  constructor(public payload: UserAdminData) {}
}

export class UserAdminClearSelectedUserAction implements Action {
  readonly type = UserAdminActionTypes.Clear_Selected_User;
  constructor() {}
}

export class UserAdminLoadUserFailureAction implements Action {
  readonly type = UserAdminActionTypes.Load_User_Failure;

  constructor(public payload: Error) {}
}

export class UserAdminLoadIdentityUserAction implements Action {
  readonly type = UserAdminActionTypes.Load_Identity_User;
  constructor(public payload: { username: string }) {}
}

export class UserAdminLoadIdentityUserSuccessAction implements Action {
  readonly type = UserAdminActionTypes.Load_Identity_User_Success;

  constructor(public payload: UserAdminData) {}
}

export class UserAdminLoadIdentityUserNotFoundAction implements Action {
  readonly type = UserAdminActionTypes.Load_Identity_User_Not_Found;
}

export class UserAdminLoadIdentityUserFailureAction implements Action {
  readonly type = UserAdminActionTypes.Load_Identity_User_Failure;

  constructor(public payload: Error) {}
}

export class UserAdminLoadAuthorizedEntitiesAction implements Action {
  readonly type = UserAdminActionTypes.Load_Authorized_Entities;
}

export class UserAdminLoadAuthorizedEntitiesSuccessAction implements Action {
  readonly type = UserAdminActionTypes.Load_Authorized_Entities_Success;

  constructor(public payload: MyAuthorizedEntities) {}
}

export class UserAdminLoadAuthorizedEntitiesFailureAction implements Action {
  readonly type = UserAdminActionTypes.Load_Authorized_Entities_Failure;

  constructor(public payload: Error) {}
}

export class UserAdminUpdateUserAction implements Action {
  readonly type = UserAdminActionTypes.Update_User;
  constructor(public payload: { user: UserAdminData }) {}
}

export class UserAdminUpdateUserSuccessAction implements Action {
  readonly type = UserAdminActionTypes.Update_User_Success;

  constructor(public payload: UserAdminData) {}
}

export class UserAdminUpdateUserFailureAction implements Action {
  readonly type = UserAdminActionTypes.Update_User_Failure;

  constructor(public payload: Error) {}
}

export class UserAdminCreateUserAction implements Action {
  readonly type = UserAdminActionTypes.Create_User;
  constructor(public payload: { user: UserAdminData }) {}
}

export class UserAdminCreateUserSuccessAction implements Action {
  readonly type = UserAdminActionTypes.Create_User_Success;

  constructor(public payload: UserAdminData) {}
}

export class UserAdminCreateUserFailureAction implements Action {
  readonly type = UserAdminActionTypes.Create_User_Failure;

  constructor(public payload: Error) {}
}

export class UserAdminLoadCarrierUsersDataAction implements Action {
  readonly type = UserAdminActionTypes.Load_Carrier_Users_Data;
  constructor(public payload: string) {}
}

export class UserAdminLoadCarrierUsersDataSuccessAction implements Action {
  readonly type = UserAdminActionTypes.Load_Carrier_Users_Data_Success;

  constructor(public payload: CarrierUsersData) {}
}

export class UserAdminLoadCarrierUsersDataFailureAction implements Action {
  readonly type = UserAdminActionTypes.Load_Carrier_Users_Data_Failure;

  constructor(public payload: Error) {}
}

export class UserAdminDeleteUserAction implements Action {
  readonly type = UserAdminActionTypes.Delete_User;
  constructor(public payload: UserAdminData) {}
}

export class UserAdminDeleteUserSuccessAction implements Action {
  readonly type = UserAdminActionTypes.Delete_User_Success;

  constructor(public payload: UserAdminData) {}
}

export class UserAdminDeleteUserFailureAction implements Action {
  readonly type = UserAdminActionTypes.Delete_User_Failure;

  constructor(public payload: Error) {}
}

export type UserAdminActions =
  | UserAdminLoadUsersAction
  | UserAdminLoadUsersSuccessAction
  | UserAdminLoadUsersFailureAction
  | UserAdminLoadAdminUsersAction
  | UserAdminLoadAdminUsersSuccessAction
  | UserAdminLoadAdminUsersFailureAction
  | UserAdminLoadUserAction
  | UserAdminLoadUserSuccessAction
  | UserAdminLoadUserFailureAction
  | UserAdminLoadIdentityUserAction
  | UserAdminLoadIdentityUserSuccessAction
  | UserAdminLoadIdentityUserNotFoundAction
  | UserAdminLoadIdentityUserFailureAction
  | UserAdminLoadAuthorizedEntitiesAction
  | UserAdminLoadAuthorizedEntitiesSuccessAction
  | UserAdminLoadAuthorizedEntitiesFailureAction
  | UserAdminUpdateUserAction
  | UserAdminUpdateUserSuccessAction
  | UserAdminUpdateUserFailureAction
  | UserAdminCreateUserAction
  | UserAdminCreateUserSuccessAction
  | UserAdminCreateUserFailureAction
  | UserAdminLoadCarrierUsersDataAction
  | UserAdminLoadCarrierUsersDataSuccessAction
  | UserAdminLoadCarrierUsersDataFailureAction
  | UserAdminClearSelectedUserAction
  | UserAdminDeleteUserAction
  | UserAdminDeleteUserSuccessAction
  | UserAdminDeleteUserFailureAction;
