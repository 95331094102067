import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { CacheService } from 'src/app/admin/services/cache.service';
import { BaseComponent } from '../../../../shared/components';
import { LoggingConfigService } from '../../../services/logging-config.service';

@Component({
  selector: 'kbxl-logging-config-container',
  templateUrl: './logging-config-container.component.html',
  styleUrls: ['./logging-config-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoggingConfigContainerComponent extends BaseComponent implements OnInit {
  public loggingLevels: LoggingLevel[];
  public selectedLoggingLevel: LoggingLevel;
  private _loggingLevelsInit = false;

  constructor(private cacheService: CacheService,
              private messageService: MessageService,
              private cdr: ChangeDetectorRef,
              private logService: LoggingConfigService ) {
    super();
  }
  ngOnInit() {

    if (!this._loggingLevelsInit) {
      this.initLoggingLevels();
    }
    this.logService.getLogLevel().subscribe(response => {
      this.selectedLoggingLevel = this.loggingLevels.find((ll) => ll.Id.toString() === response.toString());
      this.cdr.detectChanges();
    });
  }

  initLoggingLevels() {
    this.loggingLevels = [
      { Id: 0, Value: 'verbose', Label: 'Verbose'},
      { Id: 1, Value: 'debug', Label: 'Debug'},
      { Id: 2, Value: 'information', Label: 'Information'},
      { Id: 3, Value: 'warning', Label: 'Warning'},
      { Id: 4, Value: 'error', Label: 'Error'},
      { Id: 5, Value: 'fatal', Label: 'Fatal'}];

    this._loggingLevelsInit = true;
  }

  updateLoggingLevel(): void {
    this.logService.setLogLevel(this.selectedLoggingLevel.Value).subscribe((_) => {
      this.messageService.add({ id: 0, detail: 'Logging level updated', severity: 'success' });
      this.cdr.detectChanges();
    });
  }

}

class LoggingLevel {
  public Id: number;
  public Label: string;
  public Value: string;
}
