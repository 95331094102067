import { Component, Input } from '@angular/core';
import { LoadAccessorialData } from '../../models';

@Component({
  selector: 'kbxl-load-accessorial-view',
  templateUrl: './load-accessorial-view.component.html',
  styleUrls: ['./load-accessorial-view.component.scss'],
})
export class LoadAccessorialViewComponent {
  @Input() accessorials: LoadAccessorialData[];
  @Input() height: number;

  getScrollHeight(): string {
    if (this.height) {
      return this.height + 'px';
    }

    return null;
  }

  shouldShowAfterInitialHours(accessorial: LoadAccessorialData): boolean {
    return accessorial && Number.isInteger(accessorial.afterInitialHours) && accessorial.afterInitialHours > 0;
  }
}
