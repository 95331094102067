import { BrowserActions, BrowserActionTypes } from '../actions';

export interface BrowserState {
  isMobile: boolean;
  isInternetExplorer: boolean;
  keepUsingInternetExplorer: boolean;
}

const initialState: BrowserState = {
  isMobile: false,
  isInternetExplorer: false,
  keepUsingInternetExplorer: false,
};

export function browserReducer(state: BrowserState = initialState, action: BrowserActions): BrowserState {
  switch (action.type) {
    case BrowserActionTypes.SetIsMobile: {
      return { ...state, isMobile: action.payload.isMobile };
    }
    case BrowserActionTypes.SetIsInternetExplorer: {
      return { ...state, isInternetExplorer: action.payload.isInternetExplorer };
    }
    case BrowserActionTypes.SetKeepUsingInternetExplorer: {
      return { ...state, keepUsingInternetExplorer: action.payload.keepUsingInternetExplorer };
    }
    default:
      return state;
  }
}

export const getIsMobile = (state: BrowserState) => state.isMobile;
export const getIsInternetExplorer = (state: BrowserState) => state.isInternetExplorer;
export const getKeepUsingInternetExplorer = (state: BrowserState) => state.keepUsingInternetExplorer;
