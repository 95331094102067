import { CustomerLaneData } from './customer-lane-data';
import { CustomerLaneGroupContactData } from './customer-lane-group-contact-data';
import { CustomerLaneGroupData } from './customer-lane-group-data';
import { CustomerLaneGroupPricingRuleData } from './customer-lane-group-pricing-rule-data';

export interface CustomerLaneGroupDetailData extends CustomerLaneGroupData {
  carrierIds: string[];
  customerLaneGroupContacts: CustomerLaneGroupContactData[];
  customerLaneGroupPricingRule: CustomerLaneGroupPricingRuleData;
}

export class CustomerLaneGroup implements CustomerLaneGroupDetailData {
  customerLaneGroupId: number;
  customerId: string;
  name: string;
  description: string;
  loadCarrierGroupTypeId: string;
  carrierIds: string[];
  specialInstructions: string;
  customerLaneGroupContacts: CustomerLaneGroupContactData[];
  customerLanes: CustomerLaneData[];
  customerLaneGroupPricingRule: CustomerLaneGroupPricingRuleData;
  serviceTypeIds: number[];
  quoteRequiredServiceTypeIds: number[];
  brokeredLaneGroup: boolean;
  eligibleForQuote: boolean;
  marginAdjustment?: number;

  rank: null;

  hasRateSeeker: boolean;
  hasSpecialInstructions: boolean;
  hasExclusionCarrierGroup: boolean;
  hasInclusionCarrierGroup: boolean;
  hasShipperContacts: boolean;
  hasRequiredServiceTypes: boolean;
  allowCounterOffer: boolean;

  public constructor(init?: Partial<CustomerLaneGroup>) {
    Object.assign(this, init);
  }

  // get originDescription(): string {
  //   return this.formatLocation(this.originAddress1, this.originCity, this.originState, this.originPostalCode, this.originCountry);
  // }

  // get destinationDescription(): string {
  //   return this.formatLocation(
  //     this.destinationAddress1,
  //     this.destinationCity,
  //     this.destinationState,
  //     this.destinationPostalCode,
  //     this.destinationCountry
  //   );
  // }

  // private formatLocation(address: string, city: string, state: string, postalCode: string, country: string) {
  //   let description = '';
  //   if (address) {
  //     description = `${address}, `;
  //   }
  //   if (city) {
  //     description += `${city}, `;
  //   }
  //   if (state) {
  //     description += `${state}, `;
  //   }
  //   if (postalCode) {
  //     description += `${postalCode}, `;
  //   }
  //   if (country) {
  //     description += `${country}`;
  //   }

  //   return description;
  // }
}
