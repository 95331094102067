import { createSelector } from '@ngrx/store';
import { getSharedFeatureState, SharedState } from '../reducers';
import { selectors } from '../reducers/counter-offer-action-item.reducer';

const getCounterOfferActionItemState = createSelector(getSharedFeatureState, (state: SharedState) => state.counterOfferActionItemState);

export const getCounterOfferActionItemFilterCriteriaLoading = createSelector(
  getCounterOfferActionItemState,
  (state) => state.loadingFilterCriteria
);
export const getCounterOfferActionItemStatuses = createSelector(getCounterOfferActionItemState, (state) => state.actionItemStatuses);
export const getCounterOfferActionItemQueryHelper = createSelector(getCounterOfferActionItemState, (state) => state.queryHelper);
export const getCounterOfferActionItems = createSelector(getCounterOfferActionItemState, (state) => selectors.selectAll(state));
export const getCounterOfferActionItemsLoading = createSelector(getCounterOfferActionItemState, (state) => state.loading);
export const getCounterOfferActionItemsTotalRecords = createSelector(getCounterOfferActionItemState, (state) => state.totalRecords);
