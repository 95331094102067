import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceResponse, Caches } from '../../shared/models';
import { environment } from '../../../environments/environment';
import { mapResponse } from '../../shared/operators/map-response';
import { LogEventLevel } from 'src/app/shared/models/log-event-level';

@Injectable()
export class LoggingConfigService {
  constructor(private http: HttpClient) {}

  setLogLevel(logEventLevel: string): Observable<null> {
    return this.http.get<ServiceResponse<null>>(`${environment.apiUrl}/api/admin/set-log-level/${logEventLevel}`).pipe(mapResponse());
  }

  getLogLevel(): Observable<string> {
    return this.http.get<ServiceResponse<null>>(`${environment.apiUrl}/api/admin/get-log-level`).pipe(mapResponse());
  }
}
