<div *ngIf="counterOffer && allActions && allActions.length > 0">
  <hr />
  <p-panel [toggleable]="true" [collapsed]="true" styleClass=" no-padding">
    <p-header>
      <div class="d-flex align-items-center">
        <span class="mr-2">
          {{ getActionHeader() }}
        </span>
        <div class="mr-2" *ngIf="user.isShipper">
          <fa-icon
            [icon]="['fas', 'info-circle']"
            size="lg"
            pTooltip="Counter offer price below does not include Loadshop Fee."
            tooltipZIndex="100000"
          ></fa-icon>
        </div>
      </div>
    </p-header>
    <div class="d-flex flex-column p-2">
      <p-scrollPanel [style]="{ width: '100%', maxHeight: '200px' }">
        <div class="d-flex flex-column scroll-hack p-2">
          <div *ngFor="let action of allActions" class="comment-bubble" [ngClass]="isUserComment(action)">
            <div class="comment">{{ action.comment }}</div>
            <div class="text-muted mt-1">
              <span *ngIf="action.userId">{{ action.userName | titlecase }} -</span> {{ action.createDtTm | date: 'short' }}
            </div>
          </div>
        </div>
      </p-scrollPanel>
    </div>
  </p-panel>
</div>
