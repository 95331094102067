import { createReducer } from '../../utilities';
import { AccessorialRequestActions, AccessorialRequestActionTypes } from '../actions/';

export interface AccessorialRequestState {
  saving: boolean;
}
const initialState: AccessorialRequestState = {
  saving: false,
};

const _AccessorialRequestReducer = createReducer(
  initialState,
  function (state: AccessorialRequestState = initialState, action: AccessorialRequestActions): AccessorialRequestState {
    switch (action.type) {
      case AccessorialRequestActionTypes.UnlockLoad: {
        return { ...state, saving: true };
      }
      case AccessorialRequestActionTypes.UnlockLoad_Success: {
        return { ...state, saving: false };
      }
      case AccessorialRequestActionTypes.UnlockLoad_Failure: {
        return { ...state, saving: false };
      }
      default:
        return state;
    }
  }
);

/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function AccessorialRequestReducer(state: AccessorialRequestState, action: AccessorialRequestActions): AccessorialRequestState {
  return _AccessorialRequestReducer(state, action);
}
