<p-table [value]="accessorials" [scrollable]="height" [scrollHeight]="getScrollHeight()">
  <ng-template pTemplate="header">
    <tr>
      <th>Accessorial</th>
      <!-- <th>Unit Of Measure</th> -->
      <th>Rate / Unit Of Measure</th>
      <th>Maximum Rate</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-accessorial>
    <tr>
      <td>{{ accessorial.accessorialDescription }}</td>
      <!-- <td>{{ accessorial.accessorialUnitOfMeasureDescription }}</td> -->
      <td>
        <span *ngIf="shouldShowAfterInitialHours(accessorial)">After {{ accessorial.afterInitialHours }} hr(s)</span><br />
        <span *ngIf="accessorial.maxDollarRatePerUOM"
          >{{ accessorial.maxDollarRatePerUOM | currency }} / {{ accessorial.accessorialUnitOfMeasureDescription }}</span
        >
        <span *ngIf="!accessorial.maxDollarRatePerUOM">{{ accessorial.accessorialUnitOfMeasureDescription }}</span>
      </td>
      <td>
        <span *ngIf="!accessorial.variableRate">{{ accessorial.maxRate | currency }}</span>
        <span *ngIf="accessorial.variableRate">Variable</span>
      </td>
    </tr>
  </ng-template>
</p-table>
