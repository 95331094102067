import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { DefaultRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
//
import { ENVIRONMENTSETTINGS, TmsCoreModule } from '@tms-ng/core';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment, extModules } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { IEDetectionComponent } from './components/ie-detection';
import { IEPolicyComponent } from './components/ie-policy';
import { MobileBrowserDetectionComponent } from './components/mobile-browser-detection';
import { NoCarrierContainerComponent } from './components/no-carrier';
import { AgreementsComponent } from './components/user-agreement';
import { UserAgreementComponent } from './components/user-agreement/user-agreement';
import {
  AgreementDocumentService,
  CommonService,
  DynamicPricingScheduleService,
  ErrorService,
  LoadStatusService,
  SecurityService,
} from './services';
import { ResponseInterceptor } from './services/response-intercepter';
import { TimeZoneIntercepter } from './services/timezone-intercepter';
import { effects, metaReducers, reducers } from './store';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    SharedModule,
    StoreModule.forRoot(reducers, { metaReducers: metaReducers }),
    extModules, // environment specific modules
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer, stateKey: 'router' }),
    TmsCoreModule.forRoot(environment),
    RecaptchaV3Module,
  ],
  declarations: [
    NoCarrierContainerComponent,
    MobileBrowserDetectionComponent,
    IEDetectionComponent,
    IEPolicyComponent,
    AgreementsComponent,
    UserAgreementComponent,
  ],
  exports: [NoCarrierContainerComponent, MobileBrowserDetectionComponent, IEDetectionComponent],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeZoneIntercepter,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    {
      provide: ENVIRONMENTSETTINGS,
      useValue: environment,
    },
    CommonService,
    LoadStatusService,
    TitleCasePipe,
    CurrencyPipe,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    AgreementDocumentService,
    DynamicPricingScheduleService,
    SecurityService,
  ],
})
export class CoreModule {}
