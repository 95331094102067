import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  GetLoadCounterOfferResponseData,
  LoadCounterOfferActionData,
  LoadCounterOfferData,
  LoadCounterOfferGroupData,
  LoadCounterOfferRequestData,
  ServiceResponse,
} from '../models';
import { mapResponse } from '../operators/map-response';

@Injectable()
export class LoadCounterOfferService {
  constructor(private http: HttpClient) {}

  get(loadId: string, scac: string): Observable<GetLoadCounterOfferResponseData> {
    let queryString = '';
    if (scac) {
      queryString = `?scac=${scac}`;
    }

    return this.http
      .get<ServiceResponse<GetLoadCounterOfferResponseData>>(`${environment.apiUrl}/api/loads/${loadId}/counteroffers${queryString}`)
      .pipe(mapResponse());
  }

  saveAction(loadId: string, loadCounterOfferId: number, action: LoadCounterOfferActionData): Observable<LoadCounterOfferGroupData> {
    return this.http
      .post<ServiceResponse<LoadCounterOfferGroupData>>(
        `${environment.apiUrl}/api/loads/${loadId}/counteroffers/${loadCounterOfferId}/actions`,
        action
      )
      .pipe(mapResponse());
  }

  saveNewCounterOffer(loadId: string, counterOffer: LoadCounterOfferRequestData): Observable<LoadCounterOfferGroupData> {
    return this.http
      .post<ServiceResponse<LoadCounterOfferGroupData>>(`${environment.apiUrl}/api/loads/${loadId}/counteroffers`, counterOffer)
      .pipe(mapResponse());
  }
  calculateShipperTotalRateForCounterOffer(loadId: string, counterOffer: LoadCounterOfferRequestData): Observable<LoadCounterOfferData> {
    return this.http
      .post<ServiceResponse<LoadCounterOfferData>>(
        `${environment.apiUrl}/api/loads/${loadId}/counteroffers/calculate-total-rate`,
        counterOffer
      )
      .pipe(mapResponse());
  }
}
