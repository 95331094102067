import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { chatActiveLoadsReducer, ChatActiveLoadsState } from './chat-active-loads.reducer';
import { conversationHubReducer, ConversationHubState } from './conversation-hub.reducer';
import { conversationReducer, ConversationState } from './conversation.reducer';

export interface ChatState {
  conversation: ConversationState;
  conversationHub: ConversationHubState;
  activeLoads: ChatActiveLoadsState;
}

export const reducers: ActionReducerMap<ChatState> = {
  conversation: conversationReducer,
  conversationHub: conversationHubReducer,
  activeLoads: chatActiveLoadsReducer,
};

export const getChatFeatureState = createFeatureSelector<ChatState>('chat');

export * from './chat-active-loads.reducer';
export * from './conversation-hub.reducer';
export * from './conversation.reducer';
