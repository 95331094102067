import { ServiceType, LoadStop, LoadLineItem } from '.';

export interface CustomerFavorite {
  customerFavoriteId: number;
  customerId: string;
  version: string;
  type: string;
  data: OrderEntryTemplateLoadData | CustomerFavoriteData | any;
}

export interface CustomerFavoriteData {
  type: string;
  name: string;
}

export interface OrderEntryTemplateLoadData extends CustomerFavoriteData {
  commodity: string;
  equipmentType: string;
  equipmentDesc: string;
  categoryEquipmentDesc: string;
  transportationMode: string;
  comments: string;

  serviceTypes: ServiceType[];
  loadStops: LoadStop[];
  lineItems: LoadLineItem[];
}

export enum AllCustomerFavoriteTypes {
  OrderEntryTemplate = 'OrderEntryTemplate',
}

export const DefaultOrderEntryTemplateLoadData: OrderEntryTemplateLoadData = {
  type: AllCustomerFavoriteTypes.OrderEntryTemplate,
  name: null,
  commodity: null,
  equipmentType: null,
  equipmentDesc: null,
  categoryEquipmentDesc: null,
  transportationMode: null,
  comments: null,

  serviceTypes: [],
  loadStops: [],
  lineItems: [],
};

export function isCustomerFavorite(x: any): x is CustomerFavorite {
  return typeof x.customerFavoriteId === 'number';
}

export function isCustomerFavoriteArray(x: any): x is CustomerFavorite[] {
  return x.every(isCustomerFavorite);
}
