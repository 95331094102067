<div id="filter-header" class="d-lg-none" (click)="collapsed = !collapsed">
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center">
      <button
        *ngIf="!collapsed"
        pButton
        type="button"
        (click)="clearClick($event)"
        class="p-button-outlined p-button-secondary w-100"
        label="Clear"
      ></button>
      <div class="flex-grow-1 pl-2 pr-2 text-center">
        <fa-icon *ngIf="!collapsed" [icon]="['fas', 'caret-up']" size="2x"></fa-icon>
        <fa-icon *ngIf="collapsed" [icon]="['fas', 'caret-down']" size="2x"></fa-icon>
        <span class="kbxl-field-label">Filter Loads</span>
      </div>
      <button *ngIf="!collapsed" pButton type="button" (click)="applyClick($event)" class="w-100" label="Search"></button>
    </div>
  </div>
</div>
<div class="mobile-overlay">
  <div class="page-container pt-3 p-lg-0 d-lg-block background" [class.d-none]="collapsed">
    <div class="row p-0 d-lg-none">
      <div class="col-12 kbxl-inputgroup-container">
        <div class="kbxl-field-label">
          <label class="control-label">
            <fa-icon class="favorite-icon mr-1" [icon]="['far', 'star']" title="Favorite"></fa-icon>Favorites</label
          >
        </div>
        <p-dropdown
          [options]="selectLanes"
          [placeholder]="'Select a saved favorite'"
          [(ngModel)]="selectedLaneId"
          (onChange)="loadFavorite($event.value)"
          [styleClass]="'p-dropdown-primary'"
          dropdownIcon="pi pi-caret-down"
          [style]="{ width: '100%' }"
          kbxlActionCheck="loadshop.ui.profile.favorites.view"
        >
          <ng-template let-lane pTemplate="selectedItem">
            <div>Select a saved favorite</div>
          </ng-template>
          <ng-template let-lane pTemplate="item">
            <div class="d-flex align-items-center">
              <fa-icon class="favorite-icon mr-1" [icon]="['far', 'star']" title="Favorite"></fa-icon>
              <div class="ui-helper-clearfix">
                <div [innerHTML]="lane.label"></div>
              </div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="separator mb-4 d-lg-none"></div>

    <div
      *ngIf="(selectLanes && selectLanes.length > 0) || (savedSearches && savedSearches.length > 0)"
      id="favorites"
      class="d-none d-lg-block"
    >
      <div class="d-flex">
        <div class="d-flex align-items-center" *ngIf="savedSearches?.length > 0">
          <p-dropdown
            [style]="{ width: '135px' }"
            class="mr-3"
            scrollHeight="300px"
            [options]="savedSearchesSelectItems"
            placeholder="Saved Filters"
            [(ngModel)]="selectedUserFavorite"
            (onChange)="selectSavedSearch($event.value)"
            [styleClass]="'favorites-dropdown'"
            [dropdownIcon]="'pi pi-caret-down'"
          >
            <ng-template let-lane pTemplate="selectedItem">
              <div>Saved Filters</div>
            </ng-template>
            <ng-template let-lane pTemplate="item">
              <div class="d-flex align-items-center py-2 search-item-dropdown-item">
                <fa-icon class="favorite-icon mr-1" [icon]="['fas', 'search']" title="Saved Search"></fa-icon>
                <span class="text-wrap">{{ lane.value.data.name }}</span>
                <span *ngIf="lane.value.data.defaultFavorite" class="ml-2 text-primary">(Default)</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="d-flex align-items-center" *ngIf="selectLanes && selectLanes.length > 0">
          <fa-icon class="favorite-icon" [icon]="['far', 'star']" title="Favorite"></fa-icon>
          <p-dropdown
            [style]="{ width: '100px' }"
            [options]="selectLanes"
            [placeholder]="'Favorites'"
            [(ngModel)]="selectedLaneId"
            (onChange)="loadFavorite($event.value)"
            [styleClass]="'favorites-dropdown'"
            [dropdownIcon]="'pi pi-caret-down'"
          >
            <ng-template let-lane pTemplate="selectedItem">
              <div>Favorites</div>
            </ng-template>
            <ng-template let-lane pTemplate="item">
              <div class="d-flex align-items-center">
                <fa-icon class="favorite-icon mr-1" [icon]="['far', 'star']" title="Favorite"></fa-icon>
                <div class="ui-helper-clearfix" style="position: relative; width: 340px">
                  <div [innerHTML]="lane.label"></div>
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div class="kbxl-inputgroup-container mb-0">
        <div class="p-inputgroup">
          <input
            pInputText
            type="text"
            class="filter-box p-inputgroup-addon"
            placeholder="Quick Search Criteria"
            [(ngModel)]="crit.quickSearch"
            (keyup.enter)="searchClick()"
          />
          <button
            pButton
            type="button"
            icon="pi pi-times-circle"
            (click)="clearFilter('quickSearch')"
            class="clearQuickFilter p-inputgroup-addon"
          ></button>
          <button pButton type="button" icon="pi pi-filter" class="p-button-secondary p-inputgroup-addon" (click)="searchClick()"></button>
        </div>
      </div>
      <button class="icon-button" (click)="exportData()" *ngIf="showExport" kbxlActionCheck action="loadshop.ui.myloads.exportbooked">
        <fa-icon class="file-csv-icon" [icon]="['fas', 'file-csv']" pTooltip="Export Data" size="2x"></fa-icon>
      </button>
    </div>
    <p-panel [toggleable]="true" [collapsed]="!isMarketplace && !isMobile" styleClass="advanced-search-panel">
      <p-header class="p-0 marketplace-panel-header">
        <span>Advanced Search</span>
      </p-header>
      <div class="row pt-lg-2 pb-0">
        <div class="col-12 col-lg-4">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Origin</label>
              </div>
              <kbxl-address-autocomplete
                [(place)]="origin"
                [includeAddress]="false"
                (placeChange)="runSearch()"
                [placeholder]="'Search by City/State/Province'"
              >
              </kbxl-address-autocomplete>
            </div>
            <div class="col-12 col-lg-6 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label id="origin-deadhead-miles-label" class="control-label">Max Origin Radius Miles</label>
              </div>
              <div class="p-inputgroup">
                <p-slider [(ngModel)]="crit.origDH" [min]="10" [max]="200" [step]="10" (onSlideEnd)="runSearch()"></p-slider>
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="crit.origDH"
                  class="p-inputgroup-addon sliderInput"
                  min="0"
                  max="200"
                  kbxlInputBound
                  (ngModelChange)="runSearch()"
                />
              </div>
            </div>
            <div class="col-12 col-lg-6 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Pickup Date Range</label>
              </div>
              <div class="p-inputgroup">
                <p-calendar
                  #pickupDate
                  [showIcon]="true"
                  selectionMode="range"
                  showButtonBar="true"
                  [placeholder]="'Select a Date'"
                  [readonlyInput]="true"
                  [selectOtherMonths]="true"
                  todayButtonStyleClass="d-none"
                  panelStyleClass="range-calendar"
                  [(ngModel)]="origDate"
                  (onClose)="runSearch()"
                >
                  <p-footer>
                    <button
                      style="width: 75px"
                      pButton
                      type="button"
                      (click)="pickupDate.toggle()"
                      class="p-button-outlined p-button-secondary"
                      label="OK"
                    ></button> </p-footer
                ></p-calendar>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Destination</label>
              </div>
              <kbxl-address-autocomplete
                [(place)]="dest"
                [includeAddress]="false"
                (placeChange)="runSearch()"
                [placeholder]="'Search by City/State/Province'"
              >
              </kbxl-address-autocomplete>
            </div>
            <div class="col-12 col-lg-6 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label id="destination-deadhead-miles-label" class="control-label">Max Destination Radius Miles</label>
              </div>
              <div class="p-inputgroup">
                <p-slider [(ngModel)]="crit.destDH" [min]="10" [max]="200" [step]="10" (onSlideEnd)="runSearch()"></p-slider>
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="crit.destDH"
                  class="p-inputgroup-addon sliderInput"
                  min="0"
                  max="200"
                  kbxlInputBound
                  (ngModelChange)="runSearch()"
                />
              </div>
            </div>
            <div class="col-12 col-lg-6 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Delivery Date Range</label>
              </div>
              <div class="p-inputgroup">
                <p-calendar
                  #deliveryDate
                  [showIcon]="true"
                  selectionMode="range"
                  showButtonBar="true"
                  [placeholder]="'Select a Date'"
                  [readonlyInput]="true"
                  [selectOtherMonths]="true"
                  [(ngModel)]="destDate"
                  (onClose)="runSearch()"
                  todayButtonStyleClass="d-none"
                  panelStyleClass="range-calendar"
                >
                  <p-footer>
                    <button
                      style="width: 75px"
                      pButton
                      type="button"
                      (click)="deliveryDate.toggle()"
                      class="p-button-outlined p-button-secondary"
                      label="OK"
                    ></button>
                  </p-footer>
                </p-calendar>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="row pb-0">
            <div class="col-12 kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Equipment Type</label>
              </div>
              <div class="p-inputgroup">
                <kbxl-tree-drop-down
                  [treeNodes]="equipment"
                  [(selectedNodes)]="selectedEquipment"
                  placeHolder="Select Equipment Type(s)"
                  (selectedNodesChange)="runSearch()"
                ></kbxl-tree-drop-down>
              </div>
            </div>
            <div class="col-12 kbxl-inputgroup-container" [ngClass]="{ 'col-lg-6': hasShipperViewAppAction }">
              <div class="kbxl-field-label">
                <label class="control-label">Required Services</label>
              </div>
              <p-multiSelect
                [options]="serviceTypes"
                placeholder="Select All Services"
                [(ngModel)]="selectedServiceTypes"
                (onChange)="runSearch()"
                name="services"
                dataKey="serviceTypeId"
                optionLabel="name"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                appendTo="body"
                maxSelectedLabels="10"
                kbxlCloseOnScroll
                hideTransitionOptions="0ms"
              >
              </p-multiSelect>
            </div>
            <div class="col-12 col-lg-6 kbxl-inputgroup-container" *ngIf="hasShipperViewAppAction">
              <div class="kbxl-field-label">
                <label class="control-label">Shippers</label>
              </div>
              <p-multiSelect
                [options]="shippers"
                placeholder="Select All Shippers"
                [(ngModel)]="selectedShippers"
                (onChange)="runSearch()"
                name="shippers"
                dataKey="customerId"
                optionLabel="name"
                [styleClass]="'p-dropdown-primary'"
                dropdownIcon="pi pi-caret-down"
                appendTo="body"
                maxSelectedLabels="10"
                kbxlCloseOnScroll
                hideTransitionOptions="0ms"
              >
              </p-multiSelect>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="ml-auto d-flex flex-column flex-md-row align-items-end align-items-md-center">          
          <p-checkbox
            *ngIf="isMarketplace"
            class="mr-3"
            label="Show All Loads"
            [(ngModel)]="crit.showAllLoads"
            binary="true"
            (onChange)="runRefreshLoads()"
            kbxlActionCheck="loadshop.ui.marketplace.showallloads"
          >
          </p-checkbox>
          <div class="d-flex my-2 my-md-0">
            <button pButton type="button" (click)="clearClick($event)" class="p-button-outlined p-button-secondary" label="Clear"></button>

            <p-splitButton
              *ngIf="isMarketplace && showManageSearch"
              class="ml-2"
              [label]="selectedUserFavorite ? 'Update Filter' : 'Save New Filter'"
              icon="fas fa-search-plus"
              (onClick)="saveSearchClick()"
              [model]="saveActionItems"
            ></p-splitButton>
            <button
              pButton
              type="button"
              (click)="saveFavoriteClick()"
              class="ml-2"
              *ngIf="isMarketplace"
              icon="pi pi-star-o"
              [label]="!userLaneId ? 'Add To Favorites' : 'Update Favorites'"
              kbxlActionCheck="loadshop.ui.profile.favorites.addedit"
            ></button>
            <button
              pButton
              type="button"
              (click)="searchClick()"
              class="ml-2"
              *ngIf="!isMarketplace"
              [label]="'Search'"
              kbxlActionCheck="loadshop.ui.profile.favorites.addedit"
            ></button>
          </div>
          <div *ngIf="!collapsed && isMarketplace" class="d-lg-none ml-2">
            <button pButton type="button" (click)="applyClick($event)" label="Search"></button>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
</div>
