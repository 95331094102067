import { DynamicPricingScheduleData } from './dynamic-pricing-schedule-data';

export interface DynamicPricingRuleData {
  customerLaneGroupPricingRuleId: number;
  floorAmt: number;
  floorPct: number;
  nbrAdjustments: number;
  adjustmentFrequency: string;
  reservedMargin: number;
  startBeforePickUpDateHours: number;
  stopBeforePickUpDateHours: number;

  pricingSchedule?: DynamicPricingScheduleData;
}

export const defaultDynamicPricing: DynamicPricingRuleData = {
  customerLaneGroupPricingRuleId: null,
  floorAmt: null,
  floorPct: null,
  nbrAdjustments: null,
  adjustmentFrequency: null,
  reservedMargin: null,
  startBeforePickUpDateHours: null,
  stopBeforePickUpDateHours: null,
  pricingSchedule: null,
};
