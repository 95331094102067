import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { takeUntil } from 'rxjs/operators';
import { accessorialRequestModalZIndex } from '../../../constants';
import { ActionItemLoadView, UserModel } from '../../../models';
import { PageableComponent, PageableQueryData } from '../../../utilities';
import { AccessorialRequestsModalComponent } from '../../accessorials';
import { BaseComponent } from '../../base-component';

@Component({
  selector: 'kbxl-accessorial-action-item-grid',
  templateUrl: './accessorial-action-item-grid.component.html',
  styleUrls: ['./accessorial-action-item-grid.component.scss'],
  providers: [DialogService],
})
export class AccessorialActionItemGridComponent extends BaseComponent implements PageableComponent, OnChanges {
  @Input() actionItemLoads: ActionItemLoadView[];
  @Input() totalRecords: number;
  @Input() loading: boolean;
  @Input() user: UserModel;
  @Input() lazy = false;
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Output() lazyLoad: EventEmitter<PageableQueryData> = new EventEmitter<PageableQueryData>();
  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();

  first = 0;
  numberOfColumns = 12;
  @ViewChild(Table, { static: true }) table;

  constructor(private dialogService: DialogService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.loads && !this.lazy) {
        this.first = 0;
      }
    }
  }

  setFirst(first: number) {
    this.first = first;
  }

  onRowSelect(load: ActionItemLoadView) {
    this.selected.emit(load.loadId);
  }

  onPage($event) {
    if ($event && $event.first && !this.lazy) {
      this.first = $event.first;
    }
  }

  onLazyLoad($event: LazyLoadEvent) {
    const pageSize = $event.rows;
    const pageNumber = $event.first / $event.rows + 1;
    let sortField = $event.sortField;
    const descending = $event.sortOrder === -1;
    this.first = $event.first;

    if (this.first === 0 && !sortField) {
      if (this.user && this.user.isShipper) {
        sortField = 'actionItemStatusValue';
      } else {
        sortField = 'carrierSortOrder';
      }
    }

    this.lazyLoad.emit({ pageSize, pageNumber, filter: null, sortField: sortField, descending: descending });
  }

  getActionItemText(actionItemLoad: ActionItemLoadView): string {
    return 'Accessorial';
  }

  executeAction(actionItemLoad: ActionItemLoadView): void {
    const ref = this.dialogService.open(AccessorialRequestsModalComponent, {
      data: {
        loadId: actionItemLoad.loadId,
        user: this.user,
      },
      header: 'View Accessorials',
      baseZIndex: accessorialRequestModalZIndex,
      closable: false,
    });

    ref.onClose.pipe(takeUntil(this.destroyed$)).subscribe((x: { refreshGrid: boolean }) => {
      // refresh table
      if (x && x.refreshGrid) {
        this.reload.emit(true);
      }
    });
  }
}
