import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { TabMenu } from 'primeng/tabmenu';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreState, getAdminMenuEntities } from 'src/app/core/store';
import { BaseComponent } from '../../../shared/components';

@Component({
  selector: 'kbxl-admin-container',
  templateUrl: './admin-container.component.html',
  styleUrls: ['./admin-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminContainerComponent extends BaseComponent implements OnInit {
  @ViewChild(TabMenu, { static: true }) menu: TabMenu;
  lastActiveItem: MenuItem;
  menu$: Observable<MenuItem[]>;
  activeRoute$: Observable<string>;

  constructor(private store: Store<CoreState>, private router: Router) {
    super();
    //
    // HACK: https://github.com/primefaces/primeng/issues/4197
    // Because the guard on any route can cancel the navigation, the active nav item might show the current
    // and selected one, even though the guard issued a cancel
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.lastActiveItem = this.menu.activeItem;
      }
      if (e instanceof NavigationCancel) {
        this.menu.activeItem = this.lastActiveItem;
      }
    });
  }
  ngOnInit(): void {
    this.menu$ = this.store.pipe(select(getAdminMenuEntities));
  }
}
