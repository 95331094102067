import { createSelector } from '@ngrx/store';
import { CoreState } from '../reducers';
import { FavoriteState, selectAll } from '../reducers/favorite.reducer';
import { AllUserFavoriteTypes } from 'src/app/shared/models';

export const getFavoriteState = (state: CoreState) => state.favorite;
export const getFavorites = createSelector(getFavoriteState, selectAll);
export const getUserFavorites = createSelector(getFavoriteState, (state: FavoriteState, type: AllUserFavoriteTypes) =>
  selectAll(state).filter((x) => x.type === type)
);
export const getLoadingFavorites = createSelector(getFavoriteState, (x: FavoriteState) => x.loading);
