<div class="page-container">
  <div class="progress-indicator" *ngIf="loadingCalculation || loadingHHGMiles || processing || loadingLineHaulRate">
    <div class="progressspinner-container">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <div class="d-flex flex-column" *ngIf="load">
    <div class="error-container" *ngIf="errorMsg && errorMsg.length > 0">
      <p>{{ errorMsg }}</p>
    </div>
    <ul class="error-container list-unstyled" *ngIf="loadDetailErrors && loadDetailErrors.length > 0">
      <li *ngFor="let error of loadDetailErrors">{{ error.message }}</li>
    </ul>
    <div class="warning-container" *ngIf="noLineHaulRate">
      <p>Line Haul Rate could not be determined. Please enter one manually</p>
    </div>
    <div class="warning-container" *ngIf="isFlatRate">
      <p>{{ userProfile.carrierScac }} is a flat rate dedicated SCAC. The Line Haul Rate was calculated.</p>
    </div>
    <form [formGroup]="worksheetForm">
      <!-- User info -->
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column text-center mr-4">
          <div class="kbxl-field-label">
            <label class="control-label">SCAC</label>
          </div>
          <div>
            {{ userProfile.carrierScac }}
          </div>
        </div>
        <div class="d-flex flex-column text-center">
          <div class="kbxl-field-label">
            <label class="control-label">Carrier</label>
          </div>
          <div>
            {{ userProfile.carrierName }}
          </div>
        </div>
      </div>
      <!-- Load info -->
      <p-panel styleClass="">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column text-center mr-4">
            <div class="kbxl-field-label">
              <label class="control-label">Shipper's Order Number</label>
            </div>
            <div class="mt-2">{{ load.referenceLoadDisplay }}</div>
          </div>
          <div class="d-flex flex-column text-center mr-4">
            <div class="kbxl-field-label">
              <label class="control-label">Origin</label>
            </div>
            <div class="mt-2">{{ origin.city | titlecase }}, {{ origin.state }}</div>
          </div>
          <div class="d-flex flex-column text-center mr-4">
            <div class="kbxl-field-label">
              <label class="control-label">Destination</label>
            </div>
            <div class="mt-2">{{ dest.city | titlecase }}, {{ dest.state }}</div>
          </div>
          <div class="d-flex flex-column text-center mr-4">
            <div class="kbxl-field-label">
              <label class="control-label">Distance (MI)</label>
            </div>
            <div class="mt-2">{{ worksheet.loadMiles }}</div>
          </div>
          <div class="d-flex flex-column text-center mr-4">
            <div class="kbxl-field-label">
              <label class="control-label">SmartSpot</label>
            </div>
            <div class="mt-2">{{ load.smartSpot | currency }}</div>
          </div>
          <div class="d-flex flex-column text-center">
            <div class="kbxl-field-label">
              <label class="control-label">Total Rate</label>
            </div>
            <div class="mt-2">
              {{ load.lineHaulRate + load.fuelRate | currency }}
            </div>
          </div>
        </div>
      </p-panel>
      <!-- Marginal Analysis -->
      <p-panel styleClass="">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column mr-4 location-input flex-grow-1">
            <div class="kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Drivers Location</label>
              </div>
              <kbxl-address-autocomplete formControlName="driverLocation"></kbxl-address-autocomplete>
            </div>
            <div class="kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Location of the next GP / KBX Opportunity</label>
              </div>
              <kbxl-address-autocomplete formControlName="nextKbxlOpportunityLocation"></kbxl-address-autocomplete>
            </div>
            <div class="kbxl-inputgroup-container">
              <div class="kbxl-field-label">
                <label class="control-label">Next Load Location (DH after collab to next load) Location</label>
              </div>
              <kbxl-address-autocomplete formControlName="nextLoadLocation"></kbxl-address-autocomplete>
            </div>
          </div>
          <div class="d-flex flex-column miles-input text-center">
            <div class="kbxl-inputgroup-container">
              <div for="description" class="kbxl-field-label">
                <label for="description" class="control-label">Deadhead Miles</label>
              </div>
              <div class="mt-2 mb-3">{{ worksheet.driverDeadHeadMiles }}</div>
            </div>
            <div class="kbxl-inputgroup-container">
              <div for="description" class="kbxl-field-label">
                <label for="description" class="control-label">&nbsp;</label>
              </div>
              <div class="mt-1 mb-3">{{ worksheet.nextKbxlOpportunityDeadHeadMiles }}</div>
            </div>
            <div class="kbxl-inputgroup-container">
              <div for="description" class="kbxl-field-label">
                <label for="description" class="control-label">&nbsp;</label>
              </div>
              <div class="mt-1 mb-3">{{ worksheet.nextLoadDeadHeadMiles }}</div>
            </div>
          </div>
        </div>
        <div class="d-flex rate-input">
          <div class="kbxl-inputgroup-container mr-4 text-center">
            <div for="description" class="kbxl-field-label">
              <label for="description" class="control-label">Line Haul Rate</label>
            </div>
            <input type="text" formControlName="lineHaulRate" pInputText maxlength="20" *ngIf="noLineHaulRate" />
            <div class="mt-2" *ngIf="!noLineHaulRate">{{ worksheet?.lineHaulRate | currency }}</div>
          </div>
          <div class="kbxl-inputgroup-container text-center">
            <div for="description" class="kbxl-field-label">
              <label for="description" class="control-label">Dedicated Fuel</label>
            </div>
            <div class="mt-2">{{ worksheet?.dedicatedFuel }}</div>
          </div>
        </div>
      </p-panel>
      <!-- Marginal Analysis Results -->
      <p-panel styleClass="">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column text-center mr-4">
            <div class="kbxl-field-label">
              <label class="control-label">DBE</label>
            </div>
            <div>{{ worksheet?.dedicatedBackhaulExpense | currency }}</div>
          </div>
          <div class="d-flex flex-column text-center mr-4">
            <div class="kbxl-field-label">
              <label class="control-label">Negotiation Starting Point (All In)</label>
            </div>
            <div>{{ worksheet?.negotiatingStartingPoint | currency }}</div>
          </div>
          <div class="d-flex flex-column text-center mr-4">
            <div class="kbxl-field-label">
              <label class="control-label">Counter Off (All In)</label>
            </div>
            <div>{{ worksheet?.counterOffer | currency }}</div>
          </div>
          <div class="d-flex flex-column text-center">
            <div class="kbxl-field-label">
              <label class="control-label">Minimum Needed Rate (All In)</label>
            </div>
            <div>{{ worksheet?.minimumNeededRate | currency }}</div>
          </div>
        </div>
      </p-panel>
      <div class="d-flex my-3">
        <button pButton type="button" label="Cancel" (click)="cancel()" class="p-button-outlined p-button-secondary ml-auto"></button>
        <button
          pButton
          label="Book Load"
          type="button"
          (click)="bookLoad()"
          [disabled]="!canBookLoad || loadingCalculation"
          class="ml-2"
        ></button>
      </div>
    </form>
  </div>
</div>
