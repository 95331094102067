import { createSelector } from '@ngrx/store';
import { getShippingFeatureState, ShippingState } from '../reducers';
import {
  getForm,
  getLoading,
  getProblemDetails,
  getSavedId,
  getSavedIsExternalLoad,
  getSavedRefId,
  OrderEntryState,
} from '../reducers/order-entry.reducer';

const getOrderEntryState = createSelector(getShippingFeatureState, (state: ShippingState) => state.orderEntry);

export const getOrderEntryLoadingDetails = createSelector(getOrderEntryState, (state: OrderEntryState) => getLoading(state));
export const getOrderEntryProblemDetails = createSelector(getOrderEntryState, (state: OrderEntryState) => getProblemDetails(state));
export const getOrderEntryFormDisplay = createSelector(getOrderEntryState, (state: OrderEntryState) => getForm(state));
export const getOrderEntrySavedId = createSelector(getOrderEntryState, (state: OrderEntryState) => getSavedId(state));
export const getOrderEntrySavedRefId = createSelector(getOrderEntryState, (state: OrderEntryState) => getSavedRefId(state));
export const getOrderEntrySavedIsExternalLoad = createSelector(getOrderEntryState, (state: OrderEntryState) =>
  getSavedIsExternalLoad(state)
);
