import { LoadDetail, ResponseError } from '../../models';
import { createReducer } from '../../utilities';
import {
  AccessorialRequestActions,
  AccessorialRequestActionTypes,
  LoadBoardLoadDetailActions,
  LoadBoardLoadDetailActionTypes,
} from '../actions/';

export interface LoadBoardLoadDetailState {
  loaded: boolean;
  loading: boolean;
  selectedEntity: LoadDetail;
  errors: ResponseError[];
}
const initialState: LoadBoardLoadDetailState = {
  loaded: false,
  loading: false,
  selectedEntity: null,
  errors: null,
};

const _LoadBoardLoadDetailReducer = createReducer(
  initialState,
  function (
    state: LoadBoardLoadDetailState = initialState,
    action: LoadBoardLoadDetailActions | AccessorialRequestActions
  ): LoadBoardLoadDetailState {
    switch (action.type) {
      case LoadBoardLoadDetailActionTypes.Load_Success: {
        return Object.assign({}, state, {
          selectedEntity: Object.assign({}, action.payload, {
            loadTransaction: Object.assign({}, action.payload.loadTransaction),
          }),
        });
      }
      case LoadBoardLoadDetailActionTypes.Load: {
        return Object.assign({}, state, {
          selectedEntity: null,
          errors: [],
        });
      }
      case LoadBoardLoadDetailActionTypes.Load_Booked_Failure: {
        return { ...state, selectedEntity: action.payload.load, errors: action.payload.errors };
      }
      case LoadBoardLoadDetailActionTypes.Load_Failure: {
        const err: ResponseError = {
          data: action.payload,
          display: false,
          message: action.payload.message,
          stackTrace: action.payload.stack,
          code: -1,
        };

        return { ...state, selectedEntity: null, errors: [err] };
      }
      case AccessorialRequestActionTypes.UnlockLoad_Success: {
        return {
          ...state,
          selectedEntity: {
            ...state.selectedEntity,
            loadAccessorialRequests: action.payload,
          },
        };
      }
      default:
        return state;
    }
  }
);
/// Wrapper is necessary because ngrx AOT does not support a const representing a function
export function LoadBoardLoadDetailReducer(state: LoadBoardLoadDetailState, action: LoadBoardLoadDetailActions): LoadBoardLoadDetailState {
  return _LoadBoardLoadDetailReducer(state, action);
}

export const getLoaded = (state: LoadBoardLoadDetailState) => state.loaded;
export const getLoading = (state: LoadBoardLoadDetailState) => state.loading;
export const getSelectedEntity = (state: LoadBoardLoadDetailState) => state.selectedEntity;
export const getErrors = (state: LoadBoardLoadDetailState) => state.errors;
