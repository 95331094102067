import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActionItemStatusData, CounterOfferActionItemLoadView, PageableResult, ServiceResponse } from '../models';
import { mapResponse } from '../operators/map-response';
import { PageableQueryHelper } from '../utilities';

@Injectable()
export class CounterOfferActionItemService {
  constructor(private http: HttpClient) {}

  getFilterCriteria(): Observable<ActionItemStatusData> {
    return this.http
      .get<ServiceResponse<ActionItemStatusData>>(`${environment.apiUrl}/api/action-items/counter-offers/filters`)
      .pipe(mapResponse());
  }

  getPageableLoadsBySearchType(
    searchType: string,
    queryHelper: PageableQueryHelper = null
  ): Observable<PageableResult<CounterOfferActionItemLoadView>> {
    let query = '';
    if (queryHelper) {
      query = queryHelper.generateQuery();
    }

    if (queryHelper && queryHelper.filter) {
      return this.http
        .post<ServiceResponse<PageableResult<CounterOfferActionItemLoadView>>>(
          environment.apiUrl + '/api/action-items/counter-offers/search' + query,
          queryHelper.filter
        )
        .pipe(mapResponse());
    } else {
      return this.http
        .get<ServiceResponse<PageableResult<CounterOfferActionItemLoadView>>>(
          environment.apiUrl + '/api/action-items/counter-offers/search' + query
        )
        .pipe(mapResponse());
    }
  }
}
