import { LoadCarrierScacData } from './load-carrier-scac-data';

export interface ShippingLoadContractRateData {
  isProcessing: boolean;
  carrierScacs: LoadCarrierScacData[];
}

export const defaultShippingLoadContractRateData: ShippingLoadContractRateData = {
  isProcessing: false,
  carrierScacs: [],
};
