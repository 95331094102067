import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  AccessorialData,
  AccessorialUnitOfMeasureData,
  BulkOperationData,
  ServiceResponse,
  ShipperAccessorialData,
} from '../../shared/models';
import { mapResponse } from '../../shared/operators/map-response';

@Injectable()
export class ShipperAdminAccessorialService {
  constructor(private http: HttpClient) {}

  getAll(customerId: string): Observable<ShipperAccessorialData[]> {
    let url = 'api/CustomerAccessorial';

    if (customerId && customerId.length > 0) {
      url += '/GetCustomerAccessorialByPrimaryCustomer';
    }

    return this.http.get<ServiceResponse<ShipperAccessorialData[]>>(`${environment.apiUrl}/${url}`).pipe(mapResponse());
  }

  getAllAccessorials(): Observable<AccessorialData[]> {
    return this.http
      .get<ServiceResponse<AccessorialData[]>>(`${environment.apiUrl}/api/CustomerAccessorial/GetAccessorials`)
      .pipe(mapResponse());
  }
  getAllAccessorialUnitOfMeasure(): Observable<AccessorialUnitOfMeasureData[]> {
    return this.http
      .get<ServiceResponse<AccessorialUnitOfMeasureData[]>>(`${environment.apiUrl}/api/CustomerAccessorial/GetAccessorialUnitOfMeasures`)
      .pipe(mapResponse());
  }
  bulkOperation(bulkOperation: BulkOperationData<ShipperAccessorialData>[]): Observable<ShipperAccessorialData[]> {
    return this.http
      .put<ServiceResponse<ShipperAccessorialData[]>>(`${environment.apiUrl}/api/CustomerAccessorial/BulkOperations`, bulkOperation)
      .pipe(mapResponse());
  }

  add(accessorial: ShipperAccessorialData): Observable<ShipperAccessorialData> {
    return this.http
      .post<ServiceResponse<ShipperAccessorialData>>(`${environment.apiUrl}/api/CustomerAccessorial/`, accessorial)
      .pipe(mapResponse());
  }
}
