import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GoogleMapService } from '../../../../core/services';
import {
  ActionItemsCriteriaData,
  ActionItemSearch,
  ActionItemStatusData,
  defaultActionItemSearch,
  GeoLocation,
  Place,
  Search,
  State,
  UserLane,
} from '../../../models';
import { createPlace } from '../../../utilities/create-place';

@Component({
  selector: 'kbxl-accessorial-action-item-filter',
  templateUrl: './accessorial-action-item-filter.component.html',
  styleUrls: ['./accessorial-action-item-filter.component.scss'],
})
export class AccessorialActionItemFilterComponent implements OnChanges {
  @Input() loading = false;
  @Input() actionItemCriteria: ActionItemsCriteriaData[];
  @Input() actionItemStatuses: ActionItemStatusData[];
  @Input() criteria: ActionItemSearch;
  @Input() states: State[];
  @Input() isMobile: boolean;

  @Output() search: EventEmitter<ActionItemSearch> = new EventEmitter<ActionItemSearch>();
  @Output() clear: EventEmitter<string> = new EventEmitter<string>();

  crit: ActionItemSearch = { ...defaultActionItemSearch };
  suggestions: Place[];
  origin: Place;
  dest: Place;
  userLaneId: string;
  collapsed = true;
  quickSearch: string;

  selectedActionItemCriteria: ActionItemsCriteriaData[];
  selectedActionItemStatuses: ActionItemStatusData[];
  constructor(private googleService: GoogleMapService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.criteria) {
      const crit = this.criteria || { ...defaultActionItemSearch };
      this.crit = { ...crit };
      this.origin = null;
      this.dest = null;

      this.setOriginDestination(this.crit);
    }
    if (changes.states) {
      this.setOriginDestination(this.crit);
    }
  }

  setOriginDestination(model: UserLane | Search) {
    if (model.origLat && model.origLng) {
      if (model.origCity) {
        this.origin = createPlace(null, model.origCity, null, model.origState, null, model.origCountry);
      } else {
        this.googleService.reverseGeocode(model.origLat, model.origLng).then((x) => {
          this.origin = x;
        });
      }
    } else if (model.origState) {
      const state = this.states.find((x) => x.abbreviation.toLowerCase() === model.origState.toLowerCase());
      if (state) {
        this.origin = createPlace(null, null, state.name, model.origState, null, model.origCountry);
      }
    }
    if (model.destLat && model.destLng) {
      if (model.destCity) {
        this.dest = createPlace(null, model.destCity, null, model.destState, null, model.destCountry);
      } else {
        this.googleService.reverseGeocode(model.destLat, model.destLng).then((x) => {
          this.dest = x;
        });
      }
    } else if (model.destState) {
      const state = this.states.find((x) => x.abbreviation.toLowerCase() === model.destState.toLowerCase());
      if (state) {
        this.dest = createPlace(null, null, state.name, model.destState, null, model.destCountry);
      }
    }
  }

  runSearch(applying = false) {
    this.setSearchData().then((search) => {
      this.search.emit(search);
      this.collapsed = true;
    });
  }

  citySearch(event: any) {
    this.googleService.autoComplete(event.query).then((x) => {
      this.suggestions = x;
    });
  }

  setSearchData(): Promise<ActionItemSearch> {
    // trim the quick search in case the user pastes or adds space
    if (this.quickSearch) {
      this.quickSearch = this.quickSearch.trim();
    }

    const search: ActionItemSearch = {
      quickSearch: this.quickSearch,
      showAllLoads: false,
      actionItemCriteria: this.selectedActionItemCriteria,
      actionItemStatuses: this.selectedActionItemStatuses,
    };

    return Promise.all([this.geocode(this.origin), this.geocode(this.dest)]).then((results) => {
      if (this.origin) {
        search.origCity = this.origin.city;
        if (!this.origin.stateAbbrev && this.origin.state && this.origin.state.length > 2) {
          search.origState = this.states.find((x) => x.name.toLowerCase() === this.origin.state.toLowerCase()).abbreviation;
        } else {
          search.origState = this.origin.stateAbbrev;
        }
        search.origCountry = this.origin.country;
        if (results[0]) {
          search.origLat = results[0].latitude;
          search.origLng = results[0].longitude;
        }
      }

      if (this.dest) {
        search.destCity = this.dest.city;
        if (!this.dest.stateAbbrev && this.dest.state && this.dest.state.length > 2) {
          search.destState = this.states.find((x) => x.name.toLowerCase() === this.dest.state.toLowerCase()).abbreviation;
        } else {
          search.destState = this.dest.stateAbbrev;
        }
        search.destCountry = this.dest.country;
        if (results[1]) {
          search.destLat = results[1].latitude;
          search.destLng = results[1].longitude;
        }
      }

      return search;
    });
  }

  geocode(location: Place): Promise<GeoLocation> {
    if (location && location.description && location.city) {
      return this.googleService.geocode(location.description);
    }
    return new Promise((resolve) => resolve(null));
  }
  clearClick($event) {
    this.userLaneId = null;
    this.quickSearch = null;
    this.dest = null;
    this.origin = null;
    this.selectedActionItemCriteria = [];
    this.selectedActionItemStatuses = [];
    this.clear.emit();
    this.collapsed = true;
    $event.stopPropagation();
  }

  clearFilter(prop: any) {
    this[prop] = null;
    this.runSearch();
  }

  applyClick($event: Event) {
    this.runSearch(true);
    $event.stopPropagation();
  }
}
