import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { GoogleMapService } from '../../core/services';
import { GeoLocation, Place, Search, State, UserLane } from '../../shared/models';
import { createPlace } from '../../shared/utilities/create-place';

@Injectable()
export class SearchCriteriaService {
  constructor(private googleService: GoogleMapService) {}

  setOriginDestination(model: UserLane | Search, states: State[]): [Place, Place] {
    let origin: Place;
    let dest: Place;
    if ((model.origLat && model.origLng) || model.origCity) {
      if (model.origCity) {
        origin = createPlace(null, model.origCity, null, model.origState, null, model.origCountry);
      } else {
        const state = states.find((x) => x.abbreviation.toLowerCase() === model.origState.toLowerCase());
        if (state) {
          origin = createPlace(null, null, state.name, model.origState, null, model.origCountry);
        } else {
          this.googleService.reverseGeocode(model.origLat, model.origLng).then((x) => {
            origin = x;
          });
        }
      }
    } else if (model.origState) {
      const state = states.find((x) => x.abbreviation.toLowerCase() === model.origState.toLowerCase());
      if (state) {
        origin = createPlace(null, null, state.name, model.origState, null, model.origCountry);
      }
    }
    if ((model.destLat && model.destLng) || model.destCity) {
      if (model.destCity) {
        dest = createPlace(null, model.destCity, null, model.destState, null, model.destCountry);
      } else {
        const state = states.find((x) => x.abbreviation.toLowerCase() === model.destState.toLowerCase());
        if (state) {
          dest = createPlace(null, null, state.name, model.destState, null, model.destCountry);
        } else {
          this.googleService.reverseGeocode(model.destLat, model.destLng).then((x) => {
            dest = x;
          });
        }
      }
    } else if (model.destState) {
      const state = states.find((x) => x.abbreviation.toLowerCase() === model.destState.toLowerCase());
      if (state) {
        dest = createPlace(null, null, state.name, model.destState, null, model.destCountry);
      }
    }

    return [origin, dest];
  }

  setOriginDestinationOnSearch(search: Search, origin: Place, dest: Place, origDH: number, destDH: number, states: State[]) {
    return Promise.all([this.geocode(origin), this.geocode(dest)]).then((results) => {
      if (origin) {
        search.origCity = origin.city;
        if (!origin.stateAbbrev && origin.state && origin.state.length > 2) {
          search.origState = states.find((x) => x.name.toLowerCase() === origin.state.toLowerCase()).abbreviation;
        } else {
          search.origState = origin.stateAbbrev;
        }
        search.origCountry = origin.country;
        if (results[0]) {
          search.origLat = results[0].latitude;
          search.origLng = results[0].longitude;
        }
      }

      if (!isNaN(origDH) && origDH > -1) {
        search.origDH = origDH;
      } else {
        search.origDH = 50;
      }

      if (dest) {
        search.destCity = dest.city;
        if (!dest.stateAbbrev && dest.state && dest.state.length > 2) {
          search.destState = states.find((x) => x.name.toLowerCase() === dest.state.toLowerCase()).abbreviation;
        } else {
          search.destState = dest.stateAbbrev;
        }
        search.destCountry = dest.country;
        if (results[1]) {
          search.destLat = results[1].latitude;
          search.destLng = results[1].longitude;
        }
      }

      if (!isNaN(destDH) && destDH > -1) {
        search.destDH = destDH;
      } else {
        search.destDH = 50;
      }
      return search;
    });
  }

  setOriginDestinationOnSearchNoPromise(search: Search, origin: Place, dest: Place, origDH: number, destDH: number, states: State[]) {
    if (origin) {
      search.origCity = origin.city;
      if (!origin.stateAbbrev && origin.state && origin.state.length > 2) {
        search.origState = states.find((x) => x.name.toLowerCase() === origin.state.toLowerCase()).abbreviation;
      } else {
        search.origState = origin.stateAbbrev;
      }
      search.origCountry = origin.country;
      search.origLat = origin.latitude;
      search.origLng = origin.longitude;
    }

    if (!isNaN(origDH) && origDH > -1) {
      search.origDH = origDH;
    } else {
      search.origDH = 50;
    }

    if (dest) {
      search.destCity = dest.city;
      if (!dest.stateAbbrev && dest.state && dest.state.length > 2) {
        search.destState = states.find((x) => x.name.toLowerCase() === dest.state.toLowerCase()).abbreviation;
      } else {
        search.destState = dest.stateAbbrev;
      }
      search.destCountry = dest.country;
      search.destLat = dest.latitude;
      search.destLng = dest.longitude;
    }
    if (!isNaN(destDH) && destDH > -1) {
      search.destDH = destDH;
    } else {
      search.destDH = 50;
    }
    return search;
  }

  geocode(location: Place): Promise<GeoLocation> {
    if (location && location.description && location.city) {
      return this.googleService.geocode(location.description);
    }
    return new Promise((resolve) => resolve(null));
  }

  buildSelectedEquipment(selectedEquipment: TreeNode[]) {
    return selectedEquipment.filter((_) => _.leaf).map((_) => _.data.equipmentId);
  }

  buildSelectedEquipmentAsString(selectedEquipment: TreeNode[]) {
    const equip = this.buildSelectedEquipment(selectedEquipment);
    if (equip.length > 0) {
      return JSON.stringify(this.buildSelectedEquipment(selectedEquipment));
    }

    return null;
  }
}
