import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { map, takeUntil } from 'rxjs/operators';
import { groupBy } from 'src/app/shared/utilities';
import { createPlace } from 'src/app/shared/utilities/create-place';
import { BaseComponent } from '../../../../shared/components';
import { CustomerLaneData, CustomerProfile, Place, State } from '../../../../shared/models';
import { AdminState, getSelectedShipper } from '../../../store';

@Component({
  selector: 'kbxl-lane-detail',
  templateUrl: './lane-detail.component.html',
  styleUrls: ['./lane-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaneDetailComponent extends BaseComponent implements OnInit {
  @Output() updated: EventEmitter<any> = new EventEmitter<any>();

  @Input() index: number;
  @Input() set lane(value: CustomerLaneData) {
    this._lane = this.deepClone(value);
  }
  @Input() set groupedEquipment(value: TreeNode[]) {
    this._groupedEquipment = value;
    this.updateSelectedEquipment();
  }
  @Input() set states(value: State[]) {
    this._states = value;
    this.updateDetails();
  }
  @Input() errors: Error[];
  @Input() quoteOnly = false;

  private _lane: CustomerLaneData;
  private _groupedEquipment: TreeNode[];
  private _states: State[];
  get lane(): CustomerLaneData {
    return this._lane;
  }
  get groupedEquipment(): TreeNode[] {
    return this._groupedEquipment;
  }
  get states(): State[] {
    return this._states;
  }

  selectedCarriers: string[];
  selectedEquipment: TreeNode[];
  origin: Place;
  destination: Place;
  allOrigins = false;
  allDestinations = false;
  shipper: CustomerProfile;

  constructor(private adminStore: Store<AdminState>) {
    super();
  }
  ngOnInit(): void {
    this.adminStore
      .pipe(
        select(getSelectedShipper),
        takeUntil(this.destroyed$),
        map((_) => this.deepClone(_))
      )
      .subscribe((x) => {
        this.shipper = x;
      });
  }

  updateDetails() {
    this.updateSelectedEquipment();
    this.updateOrigin();
    this.updateDestination();
  }

  private updateSelectedEquipment() {
    if (this._groupedEquipment) {
      const flattenTreeNodes = this._groupedEquipment.map((_) => _.children).reduce((acc, value) => acc.concat(value));

      const equipmentTreeNodes = this.lane.equipmentIds
        .map((equipmentId) => {
          const equipment = flattenTreeNodes.find((_) => _.data.equipmentId === equipmentId);

          if (equipment && equipment.parent) {
            equipment.parent.partialSelected = true;
          }

          return equipment;
        })
        // Make sure to remove equipment that was deleted
        .filter((_) => _);

      const groupedSelections = groupBy((x) => x.data.categoryId, equipmentTreeNodes);

      groupedSelections.forEach((group) => {
        // get the first item's parent as they should all be the same;
        const treeViewGroup = group.items[0].parent;

        if (treeViewGroup && group.items.length === treeViewGroup.children.length) {
          treeViewGroup.partialSelected = false;
          equipmentTreeNodes.push(treeViewGroup);
        }
      });

      this.selectedEquipment = equipmentTreeNodes;
    } else {
      this.selectedEquipment = [];
    }
  }

  private updateOrigin() {
    this.origin =
      this.lane && this.states
        ? this.createPlace(
            this.lane.origAddr1,
            this.lane.origCity,
            this.lane.origState,
            this.lane.origZip,
            this.lane.origCountry,
            this.lane.origLat,
            this.lane.origLng
          )
        : null;
    if (this.lane && this.lane.allOrigins && !this.origin) {
      this.allOrigins = true;
    }
  }

  private updateDestination() {
    this.destination =
      this.lane && this.states
        ? this.createPlace(
            this.lane.destAddr1,
            this.lane.destCity,
            this.lane.destState,
            this.lane.destZip,
            this.lane.destCountry,
            this.lane.destLat,
            this.lane.destLng
          )
        : null;
    if (this.lane && this.lane.allDestinations && !this.destination) {
      this.allDestinations = true;
    }
  }

  private createPlace(
    address: string,
    city: string,
    stateAbbreviation: string,
    postalCode: string,
    country: string,
    lat: number = null,
    long: number = null
  ): Place {
    if (!city && !stateAbbreviation && !country) {
      return null;
    }

    const state = stateAbbreviation && this.states ? this.states.find((_) => _.abbreviation === stateAbbreviation) : null;
    return createPlace(address, city, state ? state.name : null, stateAbbreviation, postalCode, country, lat, long);
  }

  getStateAbbrev(stateName: string): string {
    if (!stateName) {
      return '';
    }
    if (!this.states) {
      return stateName;
    }
    const state = this.states.find((x) => x.name.toLowerCase() === stateName.toLowerCase());
    return state ? state.abbreviation : '';
  }

  getState(stateAbbrev: string): string {
    if (!stateAbbrev) {
      return '';
    }
    if (!this.states) {
      return stateAbbrev;
    }
    const state = this.states.find((x) => x.abbreviation.toLowerCase() === stateAbbrev.toLowerCase());
    return state ? state.name : '';
  }

  updateLane(emit: boolean = true) {
    const lane: CustomerLaneData = this.deepClone(this.lane);
    lane.origAddr1 = this.origin ? this.origin.address : null;
    lane.origCity = this.origin ? this.origin.city : null;
    lane.origState = this.origin ? this.origin.stateAbbrev : null;
    lane.origZip = this.origin ? this.origin.postalCode : null;
    lane.origCountry = this.origin ? this.origin.country : null;
    lane.origLat = this.origin ? this.origin.latitude : null;
    lane.origLng = this.origin ? this.origin.longitude : null;
    lane.destAddr1 = this.destination ? this.destination.address : null;
    lane.destCity = this.destination ? this.destination.city : null;
    lane.destState = this.destination ? this.destination.stateAbbrev : null;
    lane.destZip = this.destination ? this.destination.postalCode : null;
    lane.destCountry = this.destination ? this.destination.country : null;
    lane.destLat = this.destination ? this.destination.latitude : null;
    lane.destLng = this.destination ? this.destination.longitude : null;
    lane.equipmentIds = this.getSelectedEquipmentIds();
    lane.allOrigins = this.allOrigins;
    lane.allDestinations = this.allDestinations;

    // make sure if this is a non quote lane to set the radius back to 0
    if (!this.quoteOnly) {
      lane.origRadius = 0;
      lane.destRadius = 0;
    }

    this.lane = lane;
    if (emit) {
      this.updated.emit({ index: this.index, lane: lane });
    }
  }

  getSelectedEquipmentIds(): string[] {
    if (this.selectedEquipment) {
      return this.selectedEquipment.filter((x) => x.leaf).map((x) => x.key);
    }
  }

  public getLane() {
    this.updateLane(false);
    return this.lane;
  }

  public originChanged(changeEvent: { checked: boolean; originalEvent: MouseEvent }) {
    this.allOrigins = changeEvent.checked;

    if (this.allOrigins) {
      this.lane.origRadius = 0;
    }

    if (changeEvent.checked === true) {
      this.origin = null;
    }
    this.updateLane();
  }

  public destinationChanged(changeEvent: { checked: boolean; originalEvent: MouseEvent }) {
    this.allDestinations = changeEvent.checked;

    if (this.allDestinations) {
      this.lane.destRadius = 0;
    }
    if (changeEvent.checked === true) {
      this.destination = null;
    }
    this.updateLane();
  }
}
